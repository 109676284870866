/**
 * Logout page container
 */
// Core
import React, { useEffect, useState } from 'react';
import { parse } from 'tiny-querystring';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
// Components
import FullPageLoader from '../../components/FullPageLoader';
// utils
import { cleanUpAndLogout } from './Logout.utils';
import { reasons } from './Logout.data';

const Logout = () => {
  const [logoutReason, setLogoutReason] = useState(reasons.default);
  const pushNotificationToken = useSelector((state) =>
    get(state, 'pushNotificationData.token')
  );

  useEffect(() => {
    let reason = reasons.default;
    let urlParams = {};
    if (window.location.search) {
      urlParams = parse(window.location.search.slice(1));
      const reasonType = urlParams.reason;
      if (reasons[reasonType]) {
        reason = reasons[reasonType];
      }
    }

    setLogoutReason(reason);
    cleanUpAndLogout({ reason, urlParams, reasons, pushNotificationToken });
  }, [pushNotificationToken]);

  return <FullPageLoader loaderText={logoutReason.desc} />;
};

export default Logout;
