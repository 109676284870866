import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import classNamesInstance from 'classnames/bind';

import { OutlineButton, UnderlineButton } from 'components/ui/Buttons';
import Lang from 'components/Lang';
import styles from './Footer.module.scss';

const classNames = classNamesInstance.bind(styles);

const Footer = ({ handleBack, handleStartOver }) => {
  const { isOpen: isKeyboardOpen } = useSelector((state) =>
    get(state, 'keyboard')
  );

  return (
    <div
      className={classNames({
        footerContainer: true,
        keyboardOpenStyle: isKeyboardOpen
      })}
    >
      <UnderlineButton onClick={handleBack}>
        <Lang path="workLifeRequest.footer.backButton" />
      </UnderlineButton>
      {handleStartOver && (
        <OutlineButton onClick={handleStartOver}>
          <Lang path="workLifeRequest.footer.restartButton" />
        </OutlineButton>
      )}
    </div>
  );
};

export default Footer;
