import featureService from 'utils/feature-service';

export const therapyCardText = () => {
  if (featureService._hasTelevideoTherapyFeature()) {
    return 'therapyCardChatTelevideoText';
  }
  if (featureService._hasChatTherapyFeature()) {
    return 'therapyCardChatText';
  }
  return '';
};
