import _ from 'lodash';
import validation from '../../utils/validation';
import fieldTypes from './fieldTypes';
import genderOptions from './profile/genderOptions';
import countryOptions from './profile/countryOptions';
import languageOptions from './profile/languageOptions';
import { years, getMonths, days } from './profile/dateOptions';
import i18n from '../../assets/lang'

const countryData = countryOptions.map(element =>
  ({ value: element.country, label: element.country }));

export default () => [
  {
    name: 'email',
    fieldType: fieldTypes.text,
    props: {
      id: 'email',
      labelId: 'email-label',
      name: 'email',
      type: 'email',
      label: i18n.t('registerEmail'),
      validate: [validation.email]
    }
  },
  {
    name: 'locale',
    required: true,
    fieldType: fieldTypes.select,
    props: {
      id: 'locale',
      labelId: 'locale-label',
      name: 'locale',
      label: i18n.t('registerLocale'),
      validate: [validation.required_locale],
      options: languageOptions,
      onChange: (e, selectedLocale) => {
        i18n.changeLanguage(selectedLocale);
      }
    }
  },
  {
    name: 'name',
    fieldType: fieldTypes.text,
    props: {
      id: 'name',
      labelId: 'name-label',
      name: 'name',
      type: 'text',
      label: i18n.t('registerFullName'),
      validate: [],
      validateIcon: true
    }
  },
  {
    fieldType: fieldTypes.array,
    name: 'date_of_birth',
    label: i18n.t('formCommon.dateOfBirth'),
    fields: [
      {
        fieldType: fieldTypes.select,
        colSize: 7,
        name: 'birth_year',
        props: {
          id: 'birth_year',
          labelId: 'birth-year-label',
          name: 'birth_year',
          label: i18n.t('formCommon.birthYearText'),
          options: years,
          validate: [validation.birth_year],
          validateIcon: true
        }
      },
      {
        fieldType: fieldTypes.select,
        colSize: 7,
        colOffset: 1,
        name: 'birth_month',
        props: {
          id: 'birth_month',
          labelId: 'birth-month-label',
          name: 'birth_month',
          label: i18n.t('formCommon.birthMonthText'),
          type: 'text',
          options: getMonths(),
          validate: [validation.birth_month],
          validateIcon: true
        }
      },
      {
        name: 'birth_day',
        fieldType: fieldTypes.select,
        colSize: 7,
        colOffset: 1,
        props: {
          id: 'birth_day',
          labelId: 'birth-day-label',
          name: 'birth_day',
          min: 0,
          max: 31,
          label: i18n.t('formCommon.birthDayText'),
          type: 'text',
          options: days,
          validate: [validation.birth_day],
          validateIcon: true
        }
      }
    ]
  },
  {
    name: 'gender',
    fieldType: fieldTypes.select,
    props: {
      id: 'gender',
      labelId: 'gender-label',
      name: 'gender',
      label: i18n.t('registerGender'),
      options: genderOptions,
      validate: []
    }
  },
  {
    name: 'country_code',
    fieldType: fieldTypes.select,
    colSize: 10,
    props: {
      id: 'country_code',
      labelId: 'country-code-label',
      name: 'country_code',
      label: i18n.t('registerCountryCode'),
      options: countryOptions,
      validate: []
    }
  },
  {
    name: 'phone',
    fieldType: fieldTypes.text,
    colSize: 13,
    colOffset: 1,
    props: {
      id: 'phone',
      labelId: 'phone-label',
      name: 'phone',
      type: 'text',
      label: i18n.t('registerPhoneNumber'),
      validate: [validation.phone],
      validateIcon: true
    }
  },
  {
    name: 'country',
    fieldType: fieldTypes.select,
    props: {
      id: 'country',
      labelId: 'country-label',
      name: 'country',
      label: i18n.t('registerCountry'),
      options: countryData,
      validate: []
    }
  },
  {
    name: 'employee_id',
    fieldType: fieldTypes.text,
    props: {
      id: 'employee_id',
      labelId: 'employee-id-label',
      name: 'employee_id',
      label: i18n.t('registerEmployeeId'),
      type: 'text',
      validate: [validation.employee_id],
      validateIcon: true
    }
  }
];
