/**
 * Wrapper for auto complete for redux-form
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import propTypes from 'prop-types';
import { AutoComplete } from 'antd';
import { Keyboard } from '@capacitor/keyboard';
import Lang from '../Lang';
import { TextFieldBlack } from '../ui/Inputs';
import styles from './AutoCompleteField.module.scss';

const { container, autocompleteStyle } = styles;

const AutoCompleteField = ({
  input,
  className,
  dataSource,
  filterOption,
  disabled,
  isLoading,
  ...rest
}) => {
  const { isOpen: isKeyboardOpen } = useSelector((state) =>
    get(state, 'keyboard')
  );

  const onSelectHandler = () => {
    if (isKeyboardOpen) {
      // Timeout to mitigate field focus effect which causes keyboard to open again.
      setTimeout(() => Keyboard.hide(), 100);
    }
  };

  return (
    <div className={[className, container].join(' ')}>
      <AutoComplete
        notFoundContent={
          isLoading ? (
            <span data-testid="autocomplete-loading">
              <Lang path="loadingTextEllipsis" />
            </span>
          ) : (
            ''
          )
        }
        className={autocompleteStyle}
        value={input.value}
        onChange={(val) => input.onChange(val)}
        dataSource={dataSource}
        filterOption={filterOption}
        disabled={disabled}
        onSelect={() => onSelectHandler()}
      >
        <TextFieldBlack input={{ ...input }} {...rest} />
      </AutoComplete>
    </div>
  );
};

AutoCompleteField.propTypes = {
  input: propTypes.shape({ value: propTypes.string, onChange: propTypes.func })
    .isRequired,
  className: propTypes.string.isRequired,
  dataSource: propTypes.arrayOf(propTypes.string).isRequired,
  filterOption: propTypes.func.isRequired,
  disabled: propTypes.bool,
  isLoading: propTypes.bool
};

AutoCompleteField.defaultProps = {
  disabled: false,
  isLoading: false
};

export default AutoCompleteField;
