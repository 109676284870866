/*

*/

// Core
import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './HistoryLoader.module.scss';

const { skeletonWrapper } = styles;
// components
const History = () => (
  <ContentLoader height="490" width="560">
    {/* Only SVG shapes */}
    <rect x="0" y="54" rx="3" ry="3" width="560" height="1" />
    <rect x="0" y="108" rx="3" ry="3" width="560" height="1" />
    <rect x="0" y="162" rx="3" ry="3" width="560" height="1" />
    <rect x="0" y="216" rx="3" ry="3" width="560" height="1" />
    <rect x="0" y="270" rx="3" ry="3" width="560" height="1" />
    <rect x="0" y="324" rx="3" ry="3" width="560" height="1" />
    <rect x="0" y="378" rx="3" ry="3" width="560" height="1" />
    <rect x="0" y="432" rx="3" ry="3" width="560" height="1" />

    <rect x="22" y="15" rx="4" ry="4" width="510" height="8" />
    <rect x="22" y="33" rx="4" ry="4" width="300" height="5" />
    <rect x="22" y="70" rx="4" ry="4" width="510" height="8" />
    <rect x="22" y="90" rx="4" ry="4" width="350" height="5" />
    <rect x="22" y="125" rx="4" ry="4" width="510" height="8" />
    <rect x="22" y="145" rx="4" ry="4" width="300" height="5" />
    <rect x="22" y="180" rx="4" ry="4" width="510" height="8" />
    <rect x="22" y="200" rx="4" ry="4" width="350" height="5" />
    <rect x="22" y="235" rx="4" ry="4" width="510" height="8" />
    <rect x="22" y="255" rx="4" ry="4" width="300" height="5" />
    <rect x="22" y="285" rx="4" ry="4" width="510" height="8" />
    <rect x="22" y="305" rx="4" ry="4" width="350" height="5" />
    <rect x="22" y="340" rx="4" ry="4" width="510" height="8" />
    <rect x="22" y="360" rx="4" ry="4" width="300" height="5" />
    <rect x="22" y="390" rx="4" ry="4" width="510" height="8" />
    <rect x="22" y="410" rx="4" ry="4" width="350" height="5" />
    <rect x="22" y="450" rx="4" ry="4" width="510" height="8" />
    <rect x="22" y="470" rx="4" ry="4" width="300" height="5" />
  </ContentLoader>
);
const Heading = () => (
  <ContentLoader height="45" width="560">
    {/* Only SVG shapes */}
    <rect x="0" y="5" rx="10" ry="10" width="560" height="40" />
  </ContentLoader>
);

const HistoryLoader = (props) => (
  <div className={skeletonWrapper}>
    {props.type === 'list' ? <History /> : <Heading />}
  </div>
);

export default HistoryLoader;
