import React from 'react';
import {
  colorGoalSeverityHigh,
  colorGoalSeverityModerate,
  colorGoalSeverityLow,
  colorGoalSeverityBonus
} from 'styles/config.scss';
import styles from './ItemHeader.module.scss';

import goalIconList, { goalIcon } from '../../../../assets/data/goalIconList';

const colors = {
  high: colorGoalSeverityHigh,
  moderate: colorGoalSeverityModerate,
  low: colorGoalSeverityLow,
  bonus: colorGoalSeverityBonus
};
const ItemHeader = ({ categoryName, level, name }) => {
  const { container, indicator, goalImage, categoryText } = styles;
  return (
    <div data-testid="goal-container" className={container}>
      <span className={indicator} style={{ backgroundColor: colors[level] }} />
      <img
        className={goalImage}
        src={goalIconList[name] || goalIcon}
        alt=""
        height="55"
        width="52.5"
      />
      <span
        data-testid="goal-name"
        className={[categoryText, 'goal-text-locator'].join(' ')}
      >
        {categoryName}
      </span>
    </div>
  );
};

export default ItemHeader;
