import React from 'react';
import classNamesInstance from 'classnames/bind';
import Linkify from '../../../../components/Linkify';

import styles from './TextMessage.module.scss';

const classNames = classNamesInstance.bind(styles);

const TextMessage = ({ children, messageId, isUser }) => (
  <span
    className={classNames({
      textStyle: true,
      userMessageStyle: isUser
    })}
    data-testid="text-message"
  >
    <Linkify messageId={messageId} properties={{ target: '_blank' }}>
      {children}
    </Linkify>
  </span>
);

export default TextMessage;
