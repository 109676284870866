// core
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { get } from 'lodash';

// components
import TabMenu from 'components/TabMenu';
import tabMenuRoutes from 'components/TabMenu/tab-menu-routes';

// containers
import Home from 'containers/Home';
import Profile from 'containers/Profile';
import Intro from 'containers/Intro';
import Assessment from 'containers/Assessment';
import ConversationalAssessment from 'containers/Assessment/ConversationalAssessment/ConversationalAssessmentV2';
import Callback from 'containers/Callback';
import Terms from 'containers/Terms';
import Privacy from 'containers/Privacy';
import History from 'containers/History';
import CompanySelect from 'containers/CompanySelect';
import OnBoard from 'containers/OnBoard';
import Logout from 'containers/Logout';
import WorkLifeRequest from 'containers/Resources/WorkLifeRequest';
import BreathingExercise from 'containers/SelfUse/BreathingExercise';
import Session from 'containers/SelfUse/Session';
import Password from 'containers/Password';
import Accessibility from 'containers/Accessibility/Accessibility';

import helpers from 'utils/helpers';

// hocs
import wrapperHoc from 'hoc/wrapperHoc';
import assessmentModule from 'hoc/assessmentModule';
import checkAssessmentCompleted from 'hoc/checkAssessmentCompleted';
import logger from 'utils/logger';

import config from 'config';

const { environment, brand, appId } = config;

const HomeComponent = wrapperHoc(Home);
const ProfileComponent = wrapperHoc(Profile, { setBackground: true });

const Wrapper = helpers.platformInfo.isDesktop
  ? Switch
  : ({ children }) => children;

const Routes = () => (
  <>
    <Wrapper>
      {helpers.platformInfo.isDesktop ? tabMenuRoutes : <></>}
      <Route path="/onboard" component={OnBoard} />
      <Route path="/intro" component={assessmentModule(wrapperHoc(Intro))} />
      <Route
        exact
        path="/assessment"
        component={assessmentModule(wrapperHoc(Assessment))}
      />
      <Route
        exact
        path="/assessment/conversational"
        component={wrapperHoc(ConversationalAssessment)}
      />
      <Route path="/callback" render={(props) => <Callback {...props} />} />
      <Route path="/account/terms" component={wrapperHoc(Terms)} />
      <Route path="/accessibility" component={wrapperHoc(Accessibility)} />
      <Route path="/account/privacy" component={wrapperHoc(Privacy)} />
      <Route
        path="/history"
        component={assessmentModule(wrapperHoc(History))}
      />
      <Route path="/logout" component={Logout} />
      <Route path="/" exact component={HomeComponent} />
      <Route
        path="/diagnostic"
        render={(props) => <HomeComponent {...props} hideVideo />}
      />
      <Route
        path="/select_company"
        component={wrapperHoc(CompanySelect, {
          setBackground: true
        })}
      />
      <Route
        path="/self-use/goal/:goalId/session/:sessionId/screen/:screenId"
        component={checkAssessmentCompleted(wrapperHoc(Session))}
      />
      <Route
        path="/self-use/breathing-exercise/:id/:exerciseName?"
        component={wrapperHoc(BreathingExercise)}
      />
      <Route
        path="/resources/work-life-request"
        component={checkAssessmentCompleted(wrapperHoc(WorkLifeRequest))}
      />
      <Route
        path="/account/profile"
        render={(props) => <ProfileComponent {...props} showMenu />}
      />
      <Route
        exact
        path="/account/password"
        component={wrapperHoc(Password, { setBackground: true })}
      />
      {helpers.platformInfo.isDesktop ? (
        <Route
          render={(props) => {
            const requestedRoute = get(props, 'location.pathname', '');
            logger.warn('Unknown route - 404', 'Routes.jsx', {
              requestedRoute
            });
            return <Redirect to="/" />;
          }}
        />
      ) : (
        <Route
          path={`*/${environment === 'prod' ? brand : appId}://`}
          render={(props) => {
            const requestedRoute = get(props, 'location.pathname', '');
            logger.info('Redirecting from deep link', 'Routes.jsx', {
              requestedRoute
            });
            return <Redirect to="/" />;
          }}
        />
      )}
    </Wrapper>
    <TabMenu />
  </>
);

export default Routes;
