/**
 * Media API Actions
 */
import _ from 'lodash';
import api from '../../../api/content';
import logger from '../../../utils/logger';
import handleUnexpectedError from '../../helper';
import {
  FETCH_MEDIA_LIST_START,
  FETCH_MEDIA_LIST_DONE,
  FETCH_MEDIA_LIST_ERROR
} from '../types';

const fetchMediaListStart = () => ({
  type: FETCH_MEDIA_LIST_START
});

const fetchMediaListDone = (mediaList) => ({
  type: FETCH_MEDIA_LIST_DONE,
  mediaList
});

const fetchMediaListError = () => ({
  type: FETCH_MEDIA_LIST_ERROR
});

const fetchMediaList = () => (dispatch) => {
  dispatch(fetchMediaListStart());
  return api
    .getMediaList()
    .then((resp) => {
      if (resp.status === 200) {
        const mediaList = _.get(resp, 'data', []);
        dispatch(fetchMediaListDone(mediaList));
      } else {
        dispatch(fetchMediaListError());
        logger.error(
          'An error occurred while fetching the media content',
          'MediaAction.fetchMedia'
        );
      }
    })
    .catch((error) => {
      dispatch(fetchMediaListError());
      handleUnexpectedError(
        'An unexpected error occurred while fetching the media content',
        'MediaAction.fetchMedia',
        { error }
      );
    });
};

export default fetchMediaList;
