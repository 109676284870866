import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { ActionButton } from 'components/ui/Buttons';
import checkCircleIcon from 'assets/icons/check-circle.svg';
import history from 'utils/history';
import Lang from 'components/Lang';
// config
import config from 'config';
import styles from './RequestComplete.module.scss';

const { appName } = config;
const { container, iconStyle, buttonStyle } = styles;

const RequestComplete = () => {
  const { work_life_request_phone: workLifeRequestPhone } = useSelector(
    (state) => get(state, 'companyDetails.companyDetails.partner')
  );

  return (
    <div data-testid="request-success-container" className={container}>
      <img
        data-testid="success-icon"
        className={iconStyle}
        src={checkCircleIcon}
        alt=""
      />
      <Lang path="workLifeRequest.submitResponse.header" values={{ appName }} />
      <br />
      <Lang
        path="workLifeRequest.submitResponse.body"
        values={{ workLifeRequestPhone }}
      />
      <ActionButton
        testId="my-plan-button"
        className={buttonStyle}
        onClick={() => {
          history.push('/my-plan');
        }}
      >
        <Lang path="workLifeRequest.submitResponse.buttonText" />
      </ActionButton>
    </div>
  );
};

export default RequestComplete;
