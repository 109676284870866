// Core
import React from 'react';
// Components
import { Row, Col } from 'antd';
import featureService from 'utils/feature-service';
import { ActionButton, OutlineButton } from '../../ui/Buttons';
import RenderHtml from '../../RenderHtml';
// styles
import styles from './Alert.module.scss';
// utils
// assets
import Lang from '../../Lang';

const {
  container,
  topContainer,
  bottomContainer,
  buttonStyle,
  helpTextStyle,
  confirmButtonContainer,
  hrStyle,
  footerTextStyle,
  confirmButton,
  userAlertText,
  headerTextStyle
} = styles;

const _renderModalBody = (body) =>
  body.map(({ text, phone }, index) => (
    <Row key={text}>
      <Col xs={{ span: 20, offset: 2 }} lg={{ span: 10, offset: 2 }}>
        <p className={helpTextStyle} data-testid={`alert-body-text-${index}`}>
          {text}
        </p>
      </Col>
      <Col xs={{ span: 20, offset: 2 }} lg={{ span: 10, offset: 2 }}>
        {phone && (
          <ActionButton
            onClick={() => {
              window.location.href = `tel:${phone}`;
            }}
            className={buttonStyle}
            icon="phone"
            testId={`alert-body-button-${index}`}
          >
            {phone}
          </ActionButton>
        )}
      </Col>
      <Col xs={1} />
    </Row>
  ));

const Alert = ({
  content: { body, header, footer, contactDisclaimer },
  onClose
}) => (
  <div className={container}>
    <div className={topContainer}>
      <h2 data-testid="alert-title">
        <Lang path="alert.title" />
      </h2>
      {header && (
        <p className={headerTextStyle} data-testid="alert-header-text">
          <RenderHtml>{header}</RenderHtml>
        </p>
      )}
    </div>
    <hr className={hrStyle} />
    <div className={bottomContainer}>
      {body && _renderModalBody(body)}
      <Row>
        <Col xs={24}>
          <hr className={hrStyle} />
          {featureService._hasSuicidalAlertDisclaimerFeature() && (
            <p className={userAlertText}>{contactDisclaimer}</p>
          )}
          {footer && (
            <p data-testid="alert-footer-text" className={footerTextStyle}>
              {footer}
            </p>
          )}
          <div className={confirmButtonContainer}>
            <OutlineButton
              testId="alert-btn-confirm"
              className={confirmButton}
              onClick={onClose}
            >
              <Lang path="alert.buttonText" />
            </OutlineButton>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default Alert;
