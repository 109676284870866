import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { ActionButton } from 'components/ui/Buttons';
import { TextFieldBlack } from 'components/ui/Inputs';
import Lang from 'components/Lang';
import i18n from 'assets/lang';
import validation from 'utils/validation';
import styles from './PasswordForm.module.scss';

const { fullWidth, fieldStyle, submitButtonStyle } = styles;

const PasswordForm = ({ handleSubmit, isLoading }) => (
  <form data-testid="password-form" onSubmit={handleSubmit}>
    <div data-testid="current-password-field" className={fieldStyle}>
      <Field
        className={fullWidth}
        name="currentPassword"
        component={TextFieldBlack}
        type="password"
        label={i18n.t('account.fieldsLabel.currentPassword')}
        validate={[validation.required_password]}
        testId="current-password"
      />
    </div>
    <div data-testid="new-password-field" className={fieldStyle}>
      <Field
        className={fullWidth}
        name="newPassword"
        testId="new-password"
        component={TextFieldBlack}
        type="password"
        label={i18n.t('account.fieldsLabel.newPassword')}
        validate={[validation.required_password, validation.password]}
      />
    </div>
    <div data-testid="confirmed-password-field">
      <Field
        className={fullWidth}
        name="confirmedPassword"
        testId="confirmed-password"
        component={TextFieldBlack}
        type="password"
        label={i18n.t('account.fieldsLabel.confirmPassword')}
        validate={[validation.required_password, validation.password]}
      />
    </div>
    <div>
      <ActionButton
        isLoading={isLoading}
        className={submitButtonStyle}
        action="submit"
        testId="submit-button"
      >
        <Lang path="account.changePasswordButtonText" />
      </ActionButton>
    </div>
  </form>
);

export default reduxForm({
  form: 'password'
})(withTranslation()(PasswordForm));
