import React from 'react';
import PropTypes from 'prop-types';
// components
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
// assets
import conciergeIcon from 'assets/icons/livechat/conciegre.svg';
// styles
import styles from './Avatar.module.scss';

const { container, iconStyle } = styles;

const AvatarComponent = ({ sender, showAvatar }) =>
  showAvatar ? (
    <>
      {sender === 'system' && (
        <Grid container className={container}>
          <Avatar alt="" src={conciergeIcon} className={iconStyle} />
        </Grid>
      )}
    </>
  ) : null;

AvatarComponent.propTypes = {
  sender: PropTypes.string.isRequired,
  showAvatar: PropTypes.bool
};

AvatarComponent.defaultProps = {
  showAvatar: false
};

export default AvatarComponent;
