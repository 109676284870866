/**
 * Contains the list of our avatars along with the hard codes images and values.
 * Could be made to fetch from server at a later point.
 */

import Bear from '../img/Bear.png';
import Husky from '../img/Husky.png';
import Koala from '../img/Koala.png';
import Monkey from '../img/Monkey.png';
import Panda from '../img/Panda.png';
import Racoon from '../img/Racoon.png';

export default [
  {
    id: 1,
    path: Bear,
    value: 'bear'
  },
  {
    id: 2,
    path: Husky,
    value: 'husky'
  },
  {
    id: 3,
    path: Koala,
    value: 'koala'
  },
  {
    id: 4,
    path: Monkey,
    value: 'monkey'
  },
  {
    id: 5,
    path: Panda,
    value: 'panda'
  },
  {
    id: 6,
    path: Racoon,
    value: 'racoon'
  }
];
