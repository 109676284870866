import api from '../../api/partner';
import logger from '../../utils/logger';
import handleUnexpectedError from '../helper';
import {
  GET_COMPANY_LIST_START,
  GET_COMPANY_LIST_DONE,
  GET_COMPANY_LIST_ERROR
} from './types';

const getCompanyList = () => (dispatch) => {
  dispatch({ type: GET_COMPANY_LIST_START });
  return api
    .getCompanyList()
    .then((resp) => {
      if (resp.status === 200) {
        logger.info(
          'Successfully fetched the companyList',
          'CompanyListAction.getCompanyList'
        );
        dispatch({ type: GET_COMPANY_LIST_DONE, data: resp.data });
      } else {
        dispatch({ type: GET_COMPANY_LIST_ERROR });
        logger.error(
          'An error occurred while fetching the company list',
          'CompanyListAction.getCompanyList',
          { resp }
        );
      }
    })
    .catch((err) => {
      dispatch({ type: GET_COMPANY_LIST_ERROR });
      handleUnexpectedError(
        'An unexpected error occurred while fetching the company list',
        'CompanyListAction.getCompanyList',
        err
      );
    });
};

export default getCompanyList;
