import React, { useCallback, useState } from 'react';
import { OutlineButton } from 'components/ui/Buttons';

import styles from './OptionsGroup.module.scss';

const { buttonStyle, selectedStyle, container } = styles;

const OptionsGroup = ({ name, vals, optionsSelected, setOptionsSelected }) => {
  const [selected, setSelected] = useState();

  const handleClick = useCallback(
    (val) => {
      setOptionsSelected({ ...optionsSelected, [name]: val });
      setSelected(val);
    },
    [name, optionsSelected, setOptionsSelected]
  );

  return (
    <div className={container}>
      {vals.map((val) => (
        <OutlineButton
          key={val}
          className={`${buttonStyle} ${selected === val ? selectedStyle : ''}`}
          onClick={() => handleClick(val)}
        >
          {val}
        </OutlineButton>
      ))}
    </div>
  );
};

export default OptionsGroup;
