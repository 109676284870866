// core
import React from 'react';
// Components
import Lang from '../../../../../components/Lang';
// styles
import styles from './Error.module.scss';

const {
  retryButton,
  containerErrorText,
  errorText,
  containerNetworkError,
  networkErrorText
} = styles;

export const ChatClientUsersError = ({ retry }) => (
  <div className={containerErrorText} data-testid="chat-client-users-error">
    <span className={errorText}>
      <Lang path="chatClientErrorText" />
      <button type="button" className={retryButton} onClick={retry}>
        <Lang path="chatRetryButtonText" />
      </button>
    </span>
  </div>
);

export const ChatClientNotAssignedError = () => (
  <div className={containerErrorText}>
    <span className={errorText}>
      <Lang path="chatClientNotAssignedText" />
      &nbsp;
    </span>
  </div>
);
export const ChatMessagesError = () => (
  <div className={containerErrorText}>
    <span className={errorText}>
      <Lang path="chatPaginationErrorText" />
    </span>
  </div>
);

export const ChatSubscriptionError = ({ retry }) => (
  <div className={containerErrorText}>
    <span className={errorText}>
      <Lang path="fetchErrorText" />
      <button type="button" className={retryButton} onClick={retry}>
        <Lang path="chatRetryButtonText" />
      </button>
    </span>
  </div>
);

export const ChatPaginationError = ({ retry }) => (
  <div className={containerErrorText}>
    <span className={errorText}>
      <Lang path="chatPaginationErrorText" />
      <button type="button" className={retryButton} onClick={retry}>
        <Lang path="chatRetryButtonText" />
      </button>
    </span>
  </div>
);

export const NetworkError = ({ error }) => (
  <div className={containerNetworkError}>
    <span data-testid="network-error-text" className={networkErrorText}>
      {error}
    </span>
  </div>
);

export const ChatMediaError = ({ retry }) => (
  <div className={containerErrorText} data-testid="chat-media-error">
    <span className={errorText}>
      <Lang path="fetchMediaError" />
      <button type="button" className={retryButton} onClick={retry}>
        <Lang path="chatRetryButtonText" />
      </button>
    </span>
  </div>
);
