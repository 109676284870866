// assets
import profileActiveImg from 'assets/img/account/profile-active.svg';
import profileInactiveImg from 'assets/img/account/profile-inactive.svg';
import passwordActiveImg from 'assets/img/account/password-active.svg';
import passwordInactiveImg from 'assets/img/account/password-inactive.svg';
import settingsActiveImg from 'assets/img/account/settings-active.svg';
import settingsInactiveImg from 'assets/img/account/settings-inactive.svg';
// data
import { getUserDetails } from 'utils/auth';
// assets
import i18n from 'assets/lang';

export default [
  {
    id: 'profile',
    text: i18n.t('navigation.profile'),
    active: profileActiveImg,
    inactive: profileInactiveImg,
    route: '/account/profile',
    isVisible: () => true
  },
  {
    id: 'password',
    text: i18n.t('navigation.password'),
    active: passwordActiveImg,
    inactive: passwordInactiveImg,
    route: '/account/password',
    isVisible: () => {
      const { connection } = getUserDetails();
      if (
        connection &&
        (connection.toLowerCase().includes('saml') ||
          connection.toLowerCase().includes('sms'))
      ) {
        return false;
      }
      return true;
    }
  },
  {
    id: 'settings',
    text: i18n.t('navigation.settings'),
    active: settingsActiveImg,
    inactive: settingsInactiveImg,
    route: '/account/settings',
    isVisible: () => false
  }
];
