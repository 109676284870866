import { KEYBOARD_SHOWN, KEYBOARD_CLOSED } from '../actions/types';

const INITIAL_STATE = {
  isOpen: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case KEYBOARD_SHOWN:
      return {
        isOpen: true
      };
    case KEYBOARD_CLOSED:
      return INITIAL_STATE;
    default:
      return state;
  }
};
