import React, { useState, useEffect, useMemo } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import { NetworkStatus } from '@apollo/client';
// components
// hocs
import withAuth from 'hoc/withAuth';
import wrapWithApolloClient from 'hoc/wrapWithApolloClient';
// utils
import logger from 'utils/logger';
import helpers from 'utils/helpers';
// assets
import Lang from '../../../../../components/Lang';
import Slider from '../../../../../components/Slider';
import ErrorRetry from '../../../../../components/ErrorRetry';
import { LoaderSmall, LoaderLarge } from './Carousel.skeleton';
import Card from '../Card';
import { GET_SESSION_LIST, GET_SELECTED_GOAL_INFO } from '../../../queries';

const { getSupportedScreenTypes } = helpers;
const supportedScreenTypes = getSupportedScreenTypes();

const Carousel = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [goalId, setGoalId] = useState('');

  const isSmallScreen = useMediaQuery('(max-width: 767px)');

  const {
    loading,
    error,
    data: currentGoalData
  } = useQuery(GET_SELECTED_GOAL_INFO);

  const currentGoal = _.get(currentGoalData, 'getSelectedGoalInfo.goalId', '');
  const version = _.get(currentGoalData, 'getSelectedGoalInfo.version', '');
  const {
    error: graphQLError,
    data: fetchedSessions,
    loading: isFetching,
    refetch,
    networkStatus
  } = useQuery(GET_SESSION_LIST, {
    variables: { goalId, version },
    skip: !goalId || !version
  });

  const sessions = _.get(fetchedSessions, 'getSession.sessions', []);

  useEffect(() => {
    if (currentGoal) {
      setGoalId(currentGoal);
    }
  }, [setGoalId, currentGoal]);

  useEffect(() => {
    if (isFetching || loading || networkStatus === NetworkStatus.refetch) {
      setIsLoading(true);
    } else if (
      ((error || graphQLError) &&
        !isFetching &&
        networkStatus !== NetworkStatus.refetch) ||
      (_.isEmpty(sessions) && !isFetching && goalId)
    ) {
      logger.error('Failed to fetch sessions.', 'Carousel.useEffect', {
        error: graphQLError || error,
        goalId
      });
      setIsLoading(false);
      setIsError(true);
    } else {
      setIsLoading(false);
      setIsError(false);
    }
  }, [
    graphQLError,
    isFetching,
    networkStatus,
    loading,
    error,
    fetchedSessions,
    sessions,
    goalId
  ]);

  const completedScreens = useMemo(
    () => _.get(fetchedSessions, 'getSession.completedScreens', []),
    [fetchedSessions]
  );

  const screens = useMemo(
    () =>
      _.flattenDeep(
        sessions.map((session, index) =>
          session.screens
            .filter(
              (screen) =>
                screen.type !== 'completion' &&
                supportedScreenTypes.includes(screen.type)
            )
            .map((screen) => ({
              ...screen,
              sessionId: session.id,
              sessionNumber: index + 1
            }))
        )
      ),
    [sessions]
  );

  const startButtonCardIndex = useMemo(
    () =>
      _.findIndex(
        screens,
        (screen) =>
          _.isEmpty(completedScreens) || !completedScreens.includes(screen.id)
      ),
    [completedScreens, screens]
  );

  const LoaderComponent = useMemo(
    () => (isSmallScreen ? LoaderSmall : LoaderLarge),
    [isSmallScreen]
  );

  const getButtonType = (index, screenId) => {
    if (index === startButtonCardIndex) {
      return 'start';
    }
    if (index < startButtonCardIndex || completedScreens.includes(screenId)) {
      return 'restart';
    }
    return 'skip';
  };

  if (isLoading) {
    return <LoaderComponent />;
  }

  if (isError) {
    return (
      <ErrorRetry
        isFetching={false}
        onClick={() => refetch()}
        title={<Lang path="selfUse.session.error.errorText" />}
      />
    );
  }

  return (
    <div data-testid="session-carousel">
      <Slider
        loop={false}
        start={startButtonCardIndex}
        nextArrowId="session-carousel-next-arrow-button"
        prevArrowId="session-carousel-prev-arrow-button"
      >
        {screens.map((screen, index) => (
          <Card
            key={index}
            cardIndex={index + 1}
            screenData={screen}
            goalId={goalId}
            buttonType={getButtonType(index, screen.id)}
            sessionId={screen.sessionId}
            screenId={screen.id}
            sessionNumber={screen.sessionNumber}
          />
        ))}
      </Slider>
    </div>
  );
};

export default withAuth(wrapWithApolloClient(Carousel));
