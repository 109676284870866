import React from 'react';
import { Paper } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';
import history from 'utils/history';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import config from 'config';
import playIcon from 'assets/icons/play-2.svg';
import Lang from '../../../../../components/Lang';
// styles
import styles from './Card.module.scss';

const {
  playAudioContainer,
  cardContainer,
  leftPanel,
  headingContainer,
  heading,
  subHeading,
  rightPanel,
  playButtonStyle,
  playIconStyle,
  durationStyle,
  imageStyle
} = styles;

const { cdnBaseUrl } = config;

const Card = ({ elementId, breathingExerciseData }) => {
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const description = _.get(breathingExerciseData, 'description', '');
  const subheader = _.get(breathingExerciseData, 'subheader', '');
  const duration = _.get(breathingExerciseData, 'audio.duration', '');
  const imageUrl = _.get(breathingExerciseData, 'audio.imageUrl', '');
  const imageUrlMobile = _.get(
    breathingExerciseData,
    'audio.imageUrlMobile',
    ''
  );
  const audioId = _.get(breathingExerciseData, 'audio.audioId', '');
  const sliderClass = 'keen-slider__slide';
  return (
    <div data-testid={elementId}>
      <Paper className={`${cardContainer} ${sliderClass}`}>
        <div className={leftPanel}>
          <img
            data-testid={`img-${elementId}`}
            className={imageStyle}
            src={`${cdnBaseUrl}${isSmallScreen ? imageUrlMobile : imageUrl}`}
            alt={subheader}
          />
        </div>
        <div className={rightPanel}>
          <div className={headingContainer}>
            <h2 data-testid={`header-${elementId}`} className={heading}>
              {subheader}
            </h2>
            <h3 data-testid={`sub-header-${elementId}`} className={subHeading}>
              {description}
            </h3>
          </div>
          <div className={playAudioContainer}>
            <button
              data-testid={`play-button-${elementId}`}
              type="button"
              className={playButtonStyle}
              onClick={() => {
                analytics.track(
                  eventCategories.TOOLS,
                  'clicked on play exercise',
                  {
                    subcategory: 'breathing exercise',
                    audioId
                  }
                );
                history.push(`/self-use/breathing-exercise/${audioId}`, {
                  autoplay: true
                });
              }}
            >
              <img className={playIconStyle} src={playIcon} alt="play" />
            </button>
            <h3 data-testid={`duration-${elementId}`} className={durationStyle}>
              <Lang
                path="exerciseDuration"
                values={{
                  // FIXME(?): update duration in CMS
                  duration: Number(duration.split(':')[0])
                }}
              />
            </h3>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default Card;
