/**
 * Main app component
 */

import React, { useEffect } from 'react';
import { PushNotifications } from '@capacitor/push-notifications';
import { App as CapacitorApp } from '@capacitor/app';
import { ThemeProvider } from '@livechat/ui-kit';
import { Provider } from 'react-redux';
import { useIonToast } from '@ionic/react';
import { useIdleTimer } from 'react-idle-timer';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store, { persistor } from './store';
import Router from './router/Router';
import ErrorBoundary from './ErrorBoundary';
import NoConnectionErrorPage from './components/NoConnectionError';
import auth, { isLoggedIn } from './utils/auth';
import analytics from './utils/analytics';
import eventCategories from './utils/analytics/categories';
import logger from './utils/logger';
import config from './config';
import styles from './App.module.scss';
import {
  hardwareBackButtonHandler,
  trackActivity,
  resetBadgeCount,
  rootedDeviceHandler
} from './App.utils';
import { isNativePlatform } from './utils/capacitor';
import helpers from './utils/helpers';

const { idleSessionTimeout } = config;
const { toastContainer } = styles;

const App = () => {
  const [present, dismiss] = useIonToast();
  const _handleOnIdle = () => {
    if (helpers.platformInfo.isBrowser && isLoggedIn()) {
      logger.info(
        'User idle timeout, logging out user. Session Expired Case 1',
        'App._handleOnIdle'
      );
      auth.logout('expired', window.location.pathname);
    }
  };

  useEffect(() => {
    rootedDeviceHandler();
  }, []);

  // Handle setInterval for token updation on refresh
  useEffect(() => {
    if (isLoggedIn()) {
      auth.startTokenInspection();
      trackActivity();
    }

    if (isNativePlatform) {
      // remove all notifications on initial app launch
      // 1 second timeout because the plugin is not initialized properly in some cases
      // we don't have an event to check if it's ready
      setTimeout(() => {
        resetBadgeCount();
      }, 1000);

      // remove all notifications on app resume
      CapacitorApp.addListener('appStateChange', ({ isActive }) => {
        if (isActive) {
          resetBadgeCount();
        }
        trackActivity();
        analytics.info(eventCategories.APP, `App state changed: ${isActive}`);
      });
      // Register user for push notifications
      PushNotifications.requestPermissions().then(({ receive }) => {
        if (receive === 'granted') {
          PushNotifications.register();
        } else {
          logger.warn('Push Notification permission denied', 'App.useEffect');
        }
      });
    }
  }, []);

  // Handles hardware back button
  useEffect(() => {
    hardwareBackButtonHandler({
      present,
      dismiss
    });
  }, [present, dismiss]);

  useIdleTimer({
    timeout: idleSessionTimeout,
    onIdle: _handleOnIdle,
    debounce: 500
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <ErrorBoundary>
            {/* since we need to prefetch assets, NoConnectionErrorPage should be
            rendered but it won't be displayed unless internet is disconnected */}
            <NoConnectionErrorPage />
            <Router />
          </ErrorBoundary>
        </ThemeProvider>
      </PersistGate>
      <ToastContainer className={toastContainer} newestOnTop limit={3} />
    </Provider>
  );
};

export default App;
