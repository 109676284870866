/**
 * Module that handles our api network requests
 */

// TODO: Fix cyclic dependencies
/* eslint-disable import/no-cycle */
import axios from 'axios';
import retryPlugin from 'axios-retry';
import featureService from 'utils/feature-service';
import config from '../config';
import auth, { authService, isLoggedIn } from '../utils/auth';
import logger from '../utils/logger';

const { CancelToken } = axios;

const axiosDefault = axios.create({});
const axiosWithRetry = axios.create({});

retryPlugin(axiosWithRetry, {
  retries: 3,
  retryDelay: retryPlugin.exponentialDelay,
  retryCondition: (err) => {
    if (!err.response) {
      return true;
    }
    // Only these codes are meant to be retried
    return !!(
      err.response &&
      (err.response.status === 429 || err.response.status >= 500)
    );
  }
});

const setAuthHeader = async (request) => {
  const accessToken = await authService.getAccessToken();
  request.headers.Authorization = `Bearer ${accessToken}`;
  return request;
};

const unauthorizedInterceptor = (axiosInstance) => async (error) => {
  const originalRequest = error.config;

  if (
    error.response &&
    error.response.status === 401 &&
    !originalRequest._retry
  ) {
    logger.info('Intercepted 401', 'api.unauthorizedInterceptor', { error });

    originalRequest._retry = true;

    if (!isLoggedIn()) {
      logger.info('User not logged in', 'api.unauthorizedInterceptor');
      auth.logout('noLogin');
      return Promise.reject(new Error('User not logged in'));
    }

    try {
      await auth.renewAccessTokenIfExpired();
      return axiosInstance(error.config);
    } catch (err) {
      logger.error(
        'Error while renewing access token',
        'api.unauthorizedInterceptor',
        { err }
      );
    }
  }

  return Promise.reject(error);
};

axiosDefault.interceptors.request.use(setAuthHeader);
axiosWithRetry.interceptors.request.use(setAuthHeader);
axiosDefault.interceptors.response.use(
  (response) => response,
  unauthorizedInterceptor(axiosDefault)
);
axiosWithRetry.interceptors.response.use(
  (response) => response,
  unauthorizedInterceptor(axiosWithRetry)
);

const REQUEST_TIMEOUT = config.api.timeout; // in ms
// TODO made from config
export const CHAT_ASSESSMENT_NAME = 'conversational';

export const httpMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH'
};

const handleApiRequestError = (err) => {
  // Log the error and throw it again
  try {
    if (err.response && err.response.status === 401) {
      // Log the error and clear local storage of any authentication data and redirect to login
      logger.error(
        'Got a 401, User was not authorized' +
          `Error message is ${err.toString()}`,
        'api.handleApiRequestError',
        { err }
      );
    } else if (!err.response) {
      // if there's no response object then the user wasn't able to connect to the api so either they don't
      // have an active internet connection or our api is down
      if (err.toString() === 'Cancel') {
        logger.info(
          'Request exceeded the front-end timeout',
          'api.handleApiRequestError',
          { err }
        );
      } else if (err.toString() === 'Error: Network Error') {
        logger.info(
          'Network error, request timed out or response was not received',
          'api.handleApiRequestError',
          { err }
        );
      } else {
        logger.error(
          "A network error occurred. User isn't connected to internet or our api is down, " +
            `second one is most likely since we got this log. Error message is ${err.toString()}`,
          'api.handleApiRequestError',
          { err }
        );
      }
    } else {
      // If it's any other error then log it so we can handle that too in future versions
      logger.error(
        `An error occurred while processing the network request. Error message is ${err.toString()}`,
        'api.handleApiRequestError',
        { err }
      );
    }
  } catch (e) {
    // Well...
    logger.error(
      'An error occurred while determining the error',
      'api.handleApiRequestError',
      { e }
    );
  }
  // Throw the error again so it can be handled by action creators and we can updates our
  // reducer state accordingly
  throw err;
};

// Method that takes an endpoint, method and data and performs the api request and returns the response
export const initializeApi =
  (apiUrl) =>
  async ({
    endpoint,
    method,
    data = null,
    authRequired = true,
    retryRequired = false,
    apiKey = null,
    productExpireCheck = false
  }) => {
    const headers = { 'Content-Type': 'application/json' };
    if (apiKey) {
      headers['X-API-KEY'] = apiKey;
    }

    if (!isLoggedIn() && authRequired) {
      logger.info('User not logged in', 'api.initializeApi');
      auth.logout('noLogin');
      return Promise.reject(new Error('User not logged in'));
    }

    if (authRequired) {
      try {
        await auth.renewAccessTokenIfExpired();
      } catch (error) {
        logger.error('Error while renewing access token', 'api.initializeApi', {
          error
        });
      }
    }

    if (productExpireCheck && !featureService._isProductActive()) {
      logger.info(
        'User product expired, skipping api call',
        'api.initializeApi'
      );
      return Promise.reject(new Error('Product expired'));
    }

    // Take the request method passed from function call, uses get by default
    const reqMethod = method || httpMethod.GET;

    // Use the cancel token to stop the request after timeout expires
    const source = CancelToken.source();

    // Request will be cancelled if it doesn't gets completed in the time frame
    // used in our timeout config
    setTimeout(() => {
      source.cancel();
    }, REQUEST_TIMEOUT);

    // We pass the cancel token along with the request to allow the functionality to cancel the request if it doesn't
    // complete in the timeout period
    if (retryRequired) {
      return axiosWithRetry({
        method: reqMethod,
        data,
        url: `${apiUrl}/${endpoint}`,
        cancelToken: source.token,
        headers
      })
        .then((res) => res)
        .catch(handleApiRequestError);
    }
    return axiosDefault({
      method: reqMethod,
      data,
      url: `${apiUrl}/${endpoint}`,
      cancelToken: source.token,
      headers
    })
      .then((res) => res)
      .catch(handleApiRequestError);
  };
