import { makeStyles } from '@material-ui/styles';
import { fontSizeSubtextDefault } from 'styles/config.scss';

export default makeStyles({
  mark: {
    color: 'rgba(109, 114, 120, 0.3)',
    marginTop: '10px'
  },
  markLabel: {
    color: 'rgba(0, 0, 0, 0.87)',
    top: '35px',
    fontSize: fontSizeSubtextDefault,
    whiteSpace: 'pre-wrap',
    textAlign: 'center'
  },
  rail: {
    color: 'rgba(109, 114, 120, 0.3)',
    backgroundColor: 'rgba(109, 114, 120, 0.3)'
  },
  track: {
    color: 'rgba(109, 114, 120, 0.3)',
    height: 0
  }
});
