import React, { useState, useCallback, useEffect } from 'react';
import { Backdrop } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import ReactPlayer from 'react-player';
import { connect, useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import {
  closeVideoModal,
  openVideoModal
} from 'store/actions/media/VideoPlayerModalAction';

import logger from 'utils/logger';
import analytics from 'utils/analytics';
import { useNetworkListener } from 'utils/hooks';
import {
  lockScreenOrientation,
  unlockScreenOrientation
} from '../../containers/SelfUse/components/Session/VideoScreen/lockOrientation';
import styles from './VideoPlayerModal.module.scss';
import Lang from '../Lang';
import ErrorRetry from '../ErrorRetry';

const {
  container,
  closeButtonContainer,
  closeButton,
  playerStyle,
  playerContainer,
  retryTextStyle,
  retryButtonContainer,
  backDropContainer
} = styles;

const videoConfig = {
  file: {
    attributes: {
      controlsList: 'nodownload',
      disablePictureInPicture: true
    }
  }
};

const VideoPlayerModal = ({
  url,
  isOpen,
  videoId,
  analyticsMessage,
  autoPlay = false
}) => {
  const dispatch = useDispatch();
  const [retry, setRetry] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const { isFullScreen } = useSelector((state) => get(state, 'videoMode'));
  const [isOnline] = useNetworkListener();

  const _trackAnalytics = useCallback(
    (event, time) => {
      if (isEmpty(analyticsMessage) || isEmpty(event)) {
        logger.error(
          'Got empty category/event for analytics',
          'VideoPlayerModal._trackAnalytics'
        );
      } else {
        analytics.track(analyticsMessage, event, {
          videoId,
          currentTime: time
        });
      }
    },
    [videoId, analyticsMessage]
  );

  useEffect(() => {
    // Added setTimeout to support the autoplay functionality in safari browser
    if (isOpen && autoPlay) {
      setTimeout(() => {
        setIsPlaying(true);
      }, 0);
    }
  }, [isOpen, autoPlay]);

  useEffect(() => {
    if (isFullScreen) {
      lockScreenOrientation('landscape');
    } else {
      unlockScreenOrientation();
    }
  }, [isFullScreen]);

  const _closeModal = useCallback(() => {
    _trackAnalytics('close', currentTime);
    setIsPlaying(false);
    setCurrentTime(0);
    dispatch(closeVideoModal());
  }, [dispatch, _trackAnalytics, currentTime]);

  const _initiateVideo = useCallback(() => {
    dispatch(
      openVideoModal({ url, videoId, analyticsMessage, autoPlay: true })
    );
  }, [dispatch, url, videoId, analyticsMessage]);

  const _errorHandler = useCallback((e) => {
    logger.error(
      'Error occurred while playing the video',
      'VideoPlayerModal._errorHandler',
      { e }
    );
    setRetry(true);
  }, []);

  const _onProgress = useCallback(
    (progress) => {
      const secondsElapsed = progress.playedSeconds;
      if (secondsElapsed !== currentTime) {
        setCurrentTime(secondsElapsed);
      }
    },
    [currentTime]
  );

  const _onPause = useCallback(() => {
    setIsPlaying(false);
    _trackAnalytics('pause', currentTime);
  }, [_trackAnalytics, currentTime]);

  const _onPlay = useCallback(() => {
    setIsPlaying(true);
    _trackAnalytics('play', currentTime);
  }, [_trackAnalytics, currentTime]);

  const _retryMedia = useCallback(() => {
    setRetry(false);
    dispatch(closeVideoModal());
    setTimeout(() => {
      _initiateVideo();
    }, 100);
  }, [_initiateVideo, dispatch]);

  return (
    isOpen && (
      <div className={backDropContainer}>
        <Backdrop open>
          <div className={container}>
            <div className={playerContainer}>
              <div data-testid="close-button" className={closeButtonContainer}>
                <Icon
                  onClick={() => {
                    _closeModal();
                  }}
                  className={closeButton}
                >
                  close
                </Icon>
              </div>
              <div>
                <ReactPlayer
                  className={playerStyle}
                  url={url}
                  controls
                  playing={isPlaying && isOnline}
                  pip={false}
                  config={videoConfig}
                  onError={_errorHandler}
                  onPlay={_onPlay}
                  onPause={_onPause}
                  onProgress={_onProgress}
                  onSeek={(seekTime) => _trackAnalytics('seek', seekTime)}
                  onEnded={() => _trackAnalytics('finished', currentTime)}
                />
                {retry && (
                  <div className={retryButtonContainer}>
                    <ErrorRetry
                      textStyle={retryTextStyle}
                      isFetching={false}
                      onClick={_retryMedia}
                      title={<Lang path="videoPlayerLoadingErrorText" />}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Backdrop>
      </div>
    )
  );
};

const mapStateToProps = (state) => {
  const {
    media: {
      videoPlayerModal: { isOpen, url, videoId, analyticsMessage, autoPlay }
    }
  } = state;
  return {
    isOpen,
    url,
    videoId,
    analyticsMessage,
    autoPlay
  };
};

export default connect(mapStateToProps)(VideoPlayerModal);
