/**
 * Input wrapped with our stying
 */
import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { InputError } from '../../Validation';
import { inputStyles, labelStyles } from './SelectFieldBlack.styles';

const SelectFieldBlack = ({ className, id, labelId, label, ...rest }) => {
  const inputClasses = inputStyles();
  const labelClasses = labelStyles();
  const _getMenuOptionProps = (option) => {
    const menuOptions = { key: option.value, value: option.value };
    if (option.id) {
      menuOptions.id = option.id;
    }
    return menuOptions;
  };
  const inputParams = { ...rest.input };
  if (rest.disabled) {
    inputParams.disabled = true;
  }
  return (
    <FormControl className={className || null}>
      <InputLabel
        data-testid={labelId}
        htmlFor={id}
        classes={{ root: labelClasses.root, focused: labelClasses.focused }}
      >
        {label}
      </InputLabel>
      <Select
        inputProps={{ id }}
        className={inputClasses.underline}
        {...inputParams}
      >
        {rest.options.map((option) => {
          const menuItemProps = _getMenuOptionProps(option);
          return (
            <MenuItem className={inputClasses.menuItem} {...menuItemProps}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
      {rest.meta.touched && rest.meta.error && (
        <InputError classes={inputClasses.errorclass} error={rest.meta.error} />
      )}
    </FormControl>
  );
};

export default SelectFieldBlack;
