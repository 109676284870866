import { SHOW_MODAL, CLOSE_MODAL } from '../actions/types';

const INITIAL_STATE = {
  isOpen: false,
  component: null,
  maxWidth: 522,
  hideCloseButton: false,
  source: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_MODAL: {
      const { component, maxWidth, onClose, hideCloseButton, props, source } =
        action.payload;
      return {
        isOpen: true,
        component,
        maxWidth: maxWidth || 522,
        onClose,
        props,
        hideCloseButton,
        source
      };
    }
    case CLOSE_MODAL:
      return INITIAL_STATE;
    default:
      return state;
  }
};
