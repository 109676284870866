import api from '../../../api/request';

import {
  REQUEST_VIDEO_THERAPY,
  REQUEST_VIDEO_THERAPY_DONE,
  REQUEST_VIDEO_THERAPY_ERROR,
  GET_VIDEO_THERAPY_STATUS,
  GET_VIDEO_THERAPY_STATUS_DONE,
  GET_VIDEO_THERAPY_STATUS_ERROR,
  CLEAR_VIDEO_THERAPY_STATUS_ERROR
} from '../types';

import logger from '../../../utils/logger';
import handleUnexpectedError from '../../helper';

const videoTherapyRequestStart = () => ({ type: REQUEST_VIDEO_THERAPY });

const videoTherapyRequestDone = () => ({
  type: REQUEST_VIDEO_THERAPY_DONE
});

const videoTherapyRequestError = () => ({ type: REQUEST_VIDEO_THERAPY_ERROR });

const getVideoTherapyStatusStart = () => ({ type: GET_VIDEO_THERAPY_STATUS });

const getVideoTherapyStatusDone = (status) => ({
  type: GET_VIDEO_THERAPY_STATUS_DONE,
  status
});

const getVideoTherapyStatusError = () => ({
  type: GET_VIDEO_THERAPY_STATUS_ERROR
});

export const clearVideoTherapyStatusError = () => ({
  type: CLEAR_VIDEO_THERAPY_STATUS_ERROR
});

export const getVideoTherapyStatus = () => (dispatch) => {
  dispatch(getVideoTherapyStatusStart());
  api
    .getVideoTherapyStatus()
    .then((resp) => {
      if (resp.status === 200) {
        if (resp.data.teletherapyStatus === true) {
          dispatch(getVideoTherapyStatusDone(true));
        } else if (resp.data.teletherapyStatus === false) {
          dispatch(getVideoTherapyStatusDone(false));
        } else {
          logger.error(
            'Unknown value for videoTherapy status',
            'VideoTherapyRequestAction.getVideoTherapyStatus',
            { resp }
          );
          dispatch(getVideoTherapyStatusError());
        }
      } else {
        logger.error(
          'An error occurred while requesting the video therapy status',
          'VideoTherapyRequestAction.default',
          { resp }
        );
        dispatch(getVideoTherapyStatusError());
      }
    })
    .catch((err) => {
      handleUnexpectedError(
        'An unexpected error occurred while requesting the video therapy status',
        'VideoTherapyRequestAction.getVideoTherapyStatus',
        err
      );
      dispatch(getVideoTherapyStatusError());
    });
};

export default (params) => (dispatch) => {
  dispatch(videoTherapyRequestStart());
  api
    .requestVideoTherapy(params)
    .then((resp) => {
      if (resp.status === 200) {
        dispatch(videoTherapyRequestDone());
      } else {
        logger.error(
          'An error occurred while requesting the video therapy',
          'VideoTherapyRequestAction.default',
          { resp }
        );
        dispatch(videoTherapyRequestError());
      }
    })
    .catch((err) => {
      handleUnexpectedError(
        'An unexpected error occurred while requesting the video therapy',
        'VideoTherapyRequestAction.default',
        err
      );
      dispatch(videoTherapyRequestError());
    });
};
