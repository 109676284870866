import React, { useState } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import styles from './TextInputAccordionCard.module.scss';
import moment from '../../../../../../../utils/moment';

const {
  container,
  header,
  dateStyle,
  expandButton,
  textStyle,
  textExpandedStyle
} = styles;

const TextInputAccordionCard = ({ text, createdAt, index }) => {
  const formattedDate = moment(createdAt).format('MMM DD, YYYY');
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={container}
      data-testid={`text-input-accordion-container-${index}`}
    >
      <div
        className={header}
        data-testid={`text-input-accordion-header-${index}`}
      >
        <div
          className={dateStyle}
          data-testid={`text-input-accordion-date-${index}`}
        >
          {formattedDate}
        </div>
        <button
          type="button"
          onClick={toggleAccordion}
          className={expandButton}
          data-testid={`text-input-accordion-expand-${index}`}
        >
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </button>
      </div>
      <div
        className={`${textStyle} ${isExpanded ? textExpandedStyle : ''}`}
        data-testid={`text-input-accordion-text-${index}`}
      >
        {text}
      </div>
    </div>
  );
};
export default TextInputAccordionCard;
