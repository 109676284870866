import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import propTypes from 'prop-types';
import Situation from './partials/Situation';
import styles from './SituationScreen.module.scss';
import {
  getSituationRating,
  onChangeHandler,
  validateScreenData
} from './SituationScreen.utils';

const {
  wrapper,
  container,
  subheaderStyle,
  descriptionStyle,
  situationListContainer
} = styles;

const SituationScreen = ({
  screenData,
  currentProgress,
  renderError,
  setScreenProgress,
  scrollToTopRef
}) => {
  const { sliderScreenId, subheader, description, situations } = screenData;
  const urlParams = useParams();
  const [progress, setProgress] = useState([]);

  useEffect(() => {
    setScreenProgress({
      id: sliderScreenId,
      situations: progress
    });
  }, [progress, setScreenProgress, sliderScreenId]);

  useEffect(() => {
    if (isEmpty(progress) && !isEmpty(currentProgress)) {
      setProgress(currentProgress);
    }
  }, [currentProgress, progress]);

  const isValid = validateScreenData({
    screenData,
    id: sliderScreenId
  });

  if (!isValid) return renderError('selfUse.session.error.screenErrorMessage');

  return (
    <div
      data-testid="situation-screen"
      className={wrapper}
      ref={scrollToTopRef}
    >
      <div className={container}>
        <Row>
          <Col md={12} lg={12} xs={24}>
            <h2 data-testid="situation-subheader" className={subheaderStyle}>
              {subheader}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={24} xs={24}>
            <p data-testid="situation-description" className={descriptionStyle}>
              {description}
            </p>
          </Col>
        </Row>
      </div>
      <div
        data-testid="situation-list-container"
        className={situationListContainer}
      >
        <Row>
          <Col md={24} lg={24} xs={24}>
            {situations.map((situation, index) => (
              <Situation
                id={situation.id}
                key={situation.id}
                order={index}
                value={getSituationRating({
                  id: situation.id,
                  progress,
                  ratings: situation.ratings
                })}
                title={situation.title}
                ratings={situation.ratings}
                onChange={({ id, ratingId, ratingValue }) =>
                  onChangeHandler({
                    id,
                    ratingId,
                    ratingValue,
                    progress,
                    ...urlParams,
                    setProgress
                  })
                }
              />
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );
};

SituationScreen.propTypes = {
  screenData: propTypes.shape({
    situations: propTypes.arrayOf(propTypes.shape({})),
    subheader: propTypes.string,
    description: propTypes.string,
    sliderScreenId: propTypes.string
  }),
  renderError: propTypes.func,
  setScreenProgress: propTypes.func,
  currentProgress: propTypes.arrayOf(propTypes.shape({}))
};

SituationScreen.defaultProps = {
  screenData: {},
  renderError: () => null,
  setScreenProgress: () => null,
  currentProgress: []
};

export default SituationScreen;
