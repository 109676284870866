import React from 'react';
import FontAwesomeIcon from 'react-fontawesome';
import IconMap from './IconMap';

import styles from './Icon.module.scss';

const { container, iconStyle } = styles;

const Icon = ({ icon }) => (
  <div
    className={container}
    style={{ visibility: icon ? 'visible' : 'hidden' }}
  >
    <FontAwesomeIcon
      name={IconMap[icon] ?? icon ?? ''}
      className={iconStyle}
      size="3x"
      fixedWidth
    />
  </div>
);

export default Icon;
