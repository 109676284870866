// Core
import React from 'react';
// Components
import featureService from 'utils/feature-service';
import ContactEAP from '../DashBoard/components/ContactEAP';
import Lang from '../../components/Lang';
import FullWidthContainer from '../../components/FullWidthContainer';

// styles
import styles from './Resources.module.scss';
// utils
// components
import WorkLifeRequestCarousel from '../DashBoard/components/WorkLifeRequestCarousel';
import WorkLifeServices from './WorkLifeServices';
import Counselling from './Counselling';

// hoc
import withAuth from '../../hoc/withAuth';

const { containerStyle } = styles;

const {
  _hasWorkLifeRequestFeature,
  _hasContactEAPCardFeature,
  _hasWorkLifeBenefitsResourceFeature,
  _hasCounsellingResourceFeature
} = featureService;

const Resources = () => (
  <div className={containerStyle}>
    <FullWidthContainer
      titleText={<Lang path="resources.workLifeServices.header" />}
      Component={WorkLifeServices}
      checkFeature={_hasWorkLifeBenefitsResourceFeature}
      titleId="work-life-eap-benefit"
    />
    <FullWidthContainer
      titleText={<Lang path="resources.counsellingServices.header" />}
      Component={Counselling}
      checkFeature={_hasCounsellingResourceFeature}
      titleId="mental-well-being"
    />
    <FullWidthContainer
      Component={WorkLifeRequestCarousel}
      checkFeature={_hasWorkLifeRequestFeature}
    />
    <FullWidthContainer
      titleText={<Lang path="resources.contactEapText" />}
      titleId="contact-eap-header"
      Component={ContactEAP}
      checkFeature={_hasContactEAPCardFeature}
    />
  </div>
);

export default withAuth(Resources);
