import React from 'react';
import styles from './ContentCard.module.scss';

const { cardStyle } = styles;

const ContentCard = ({ children }) => (
  <div data-testid="content-card" className={cardStyle}>
    {children}
  </div>
);

export default ContentCard;
