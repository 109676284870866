import React from 'react';
import { ApolloProvider } from '@apollo/react-common';
import apolloClient from 'utils/graphql/apollo-client';
import CoachChat from './CoachChat';

const wrapWithApolloClient = () => (
  <ApolloProvider client={apolloClient}>
    <CoachChat />
  </ApolloProvider>
);

export default wrapWithApolloClient;
