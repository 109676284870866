import React from 'react';
import _ from 'lodash';
// Styles
// assets
import videoIcon from 'assets/icons/session/video-play.svg';
// components
import RenderHtml from '../../../../../components/RenderHtml';
import Lang from '../../../../../components/Lang';
import styles from './Transcript.module.scss';

const {
  container,
  contentStyle,
  topPanelStyle,
  headingStyle,
  durationStyle,
  toggleToVideoButtonStyle,
  toggleButtonTextStyle
} = styles;

const Transcript = ({ screenData, toggleToVideoScreen }) => {
  const subheader = _.get(screenData, 'subheader', '');
  const duration = _.get(screenData, 'video.duration', '');
  const html = _.get(screenData, 'transcript.html', '');

  return (
    <div data-testid="transcript-container" className={container}>
      <div className={topPanelStyle}>
        <div>
          <h3 data-testid="transcript-subheader" className={headingStyle}>
            {subheader}
          </h3>
          <p
            data-testid="transcript-duration"
            className={durationStyle}
          >{`Duration - ${duration}`}</p>
        </div>
        <div>
          <button
            type="button"
            className={toggleToVideoButtonStyle}
            onClick={toggleToVideoScreen}
            data-testid="toggle-to-video-button"
          >
            <img src={videoIcon} alt="" />
            <span className={toggleButtonTextStyle}>
              <Lang path="selfUse.session.toggleToVideoButtonText" />
            </span>
          </button>
        </div>
      </div>
      <div data-testid="transcript-content" className={contentStyle}>
        <RenderHtml>{html}</RenderHtml>
      </div>
    </div>
  );
};

export default Transcript;
