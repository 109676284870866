import React from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import classNamesInstance from 'classnames/bind';
import Lang from 'components/Lang';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import featureService from 'utils/feature-service';
import bigCardImg from 'assets/img/dashboard/actionplan/video.jpg';
import TherapyModal from '../../popups/Therapy';
import ActionPlanCardBig from '../ActionPlanCardBig';
import ActionPlanCardSmall from '../ActionPlanCardSmall';
import styles from './TherapyCard.module.scss';
import { therapyCardText } from './Therapy.utils';

const { textStyle, headerStyle } = styles;

const classNames = classNamesInstance.bind(styles);

const onClickHandler = (showModal) => {
  analytics.track(eventCategories.DASHBOARD, 'clicked therapy button');
  showModal(TherapyModal, { maxWidth: 680, source: 'therapy' });
};

const Header = ({ children }) => (
  <h4 data-testid="therapy-card-header" className={headerStyle}>
    {children}
  </h4>
);

const TherapyCard = ({ type, index, showModal, videoSessionCount }) => {
  const componentProps = {
    header: (
      <Lang path="therapyCard.headerText" values={{ index: index + 1 }} />
    ),
    categoryCount: videoSessionCount,
    categoryUnit: <Lang path="therapyCard.unitText" />,
    showButton: true,
    onClick: () => onClickHandler(showModal),
    cardId: 'therapy',
    buttonId: 'therapy-get-started-btn',
    buttonText: (
      <Lang
        path={
          featureService._hasTherapyAccessWithEAPFeature()
            ? 'hrTherapyCard.buttonText'
            : 'therapyCardButtonText'
        }
      />
    )
  };

  const chatContentClass = classNames({
    bigCardChatContentStyle: type === 'large'
  });

  const content = (
    <div data-testid="therapy-card-content">
      <Lang
        path={
          featureService._hasTherapyAccessWithEAPFeature()
            ? 'hrTherapyCard.title'
            : 'therapyCardCounselorText'
        }
        components={{ Heading: <Header /> }}
      />
      <span className={chatContentClass}>
        <Lang path={therapyCardText()} components={{ Heading: <Header /> }} />
      </span>
    </div>
  );

  return type === 'large' ? (
    <ActionPlanCardBig
      content={content}
      imgSrc={bigCardImg}
      {...componentProps}
    />
  ) : (
    <ActionPlanCardSmall imgSrc="" {...componentProps}>
      <p id="therapy-description-text" className={textStyle}>
        {content}
      </p>
    </ActionPlanCardSmall>
  );
};

const mapStateToProps = (state) => {
  const {
    dashBoard: { severity, sessionCount }
  } = state;
  return {
    videoSessionCount: sessionCount.video[severity]
  };
};

TherapyCard.propTypes = {
  showModal: propTypes.func,
  type: propTypes.string,
  index: propTypes.number,
  videoSessionCount: propTypes.number
};

TherapyCard.defaultProps = {
  showModal: () => null,
  type: '',
  index: 0,
  videoSessionCount: 0
};

export default connect(mapStateToProps)(TherapyCard);
