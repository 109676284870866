// core
import React, { useState, useRef, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import propTypes from 'prop-types';
// components
import EditableField from 'components/EditableField';
// styles
import styles from './CustomInput.module.scss';

const {
  addInputContainer,
  customInputContainer,
  addIcon,
  customInput,
  circle
} = styles;

const AddInput = ({ onAddInput, customOptionPlaceholder, testId }) => (
  <div
    data-testid={`${testId}-input-container`}
    className={addInputContainer}
    tabIndex={0}
    role="button"
    onClick={() => onAddInput()}
    onKeyDown={() => onAddInput()}
  >
    <span className={circle}>
      <AddIcon data-testid={`${testId}-add-icon`} className={addIcon} />
    </span>
    <p data-testid={`${testId}-placeholder-text`}>{customOptionPlaceholder}</p>
  </div>
);

const CustomInput = ({
  id,
  customOptionPlaceholder,
  onClickAdd,
  onClickSave,
  onClickCancel,
  customValidator,
  testId,
  screenType,
  setCurrentTextBoxInput
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const autoScrollRef = useRef(null);

  useEffect(() => {
    // Reset to default state for different id/screen
    setIsEditable(false);
  }, [id]);

  const onAddInputHandler = () => {
    setIsEditable(true);
    onClickAdd();
  };

  const onKeyUpHandler = () => {
    setErrorMessage('');
  };
  const onSaveHandler = (text) => {
    const { isValid, errorText } = customValidator(text);
    const filteredText = text && text.trim();
    if (isValid) {
      setIsEditable(false);
      onClickSave(filteredText);
      setErrorMessage('');
      setCurrentTextBoxInput(null);
    } else {
      setErrorMessage(errorText);
    }
  };

  const onCancelHandler = () => {
    setIsEditable(false);
    onClickCancel();
    setErrorMessage('');
    setCurrentTextBoxInput(null);
  };

  return (
    <div
      data-testid="custom-input-container"
      className={customInputContainer}
      ref={autoScrollRef}
    >
      <div className={customInput}>
        {isEditable ? (
          <EditableField
            screenType={screenType}
            testId={testId}
            showControls
            isEditing={isEditable}
            onSave={onSaveHandler}
            onCancel={onCancelHandler}
            errorMessage={errorMessage}
            onKeyUpHandler={onKeyUpHandler}
            setCurrentTextBoxInput={setCurrentTextBoxInput}
          />
        ) : (
          <AddInput
            testId={testId}
            customOptionPlaceholder={customOptionPlaceholder}
            onAddInput={onAddInputHandler}
          />
        )}
      </div>
    </div>
  );
};

CustomInput.propTypes = {
  customOptionPlaceholder: propTypes.string,
  onClickAdd: propTypes.func,
  onClickSave: propTypes.func,
  onClickCancel: propTypes.func,
  customValidator: propTypes.func,
  testId: propTypes.string,
  screenType: propTypes.string,
  setCurrentTextBoxInput: propTypes.func
};

CustomInput.defaultProps = {
  customOptionPlaceholder: null,
  onClickAdd: null,
  onClickSave: null,
  onClickCancel: null,
  customValidator: () => true,
  testId: null,
  screenType: '',
  setCurrentTextBoxInput: () => null
};

export default CustomInput;
