/**
 * main index file that renders the app
 */
import React from 'react';
import { Col, Row } from 'antd';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ErrorImg from 'assets/img/Error_img.png';
import styles from './Error.module.scss';

// assets
const {
  errorOuter,
  errorMessage,
  contentStyle,
  errorImg,
  errorWidth,
  descContainer,
  buttonContainer
} = styles;

const Error = ({ message, messageTestId, content, footer }) => {
  const { t } = useTranslation();
  return (
    <Row className={[errorOuter, errorWidth].join(' ')}>
      <Row>
        <Col md={12} lg={12} xs={24}>
          <img
            src={ErrorImg}
            className={errorImg}
            alt={t('alternateText.image.error')}
          />
        </Col>
        <Col md={12} lg={12} xs={24} className={descContainer}>
          <div data-testid={messageTestId} className={errorMessage}>
            <h2> {message} </h2>
          </div>
          <p className={contentStyle}>{content}</p>
          <div className={buttonContainer}>
            {footer.map((ele) => (
              <Row>
                <Col md={12} lg={12} xs={24}>
                  {ele}
                </Col>
              </Row>
            ))}
          </div>
        </Col>
      </Row>
    </Row>
  );
};
Error.propTypes = {
  message: propTypes.shape({
    checklist: propTypes.arrayOf(propTypes.shape({})),
    subheader: propTypes.string,
    descriptionV2: propTypes.shape({
      html: propTypes.string
    })
  }),
  content: propTypes.shape({}),
  messageTestId: propTypes.string,
  footer: propTypes.arrayOf(propTypes.shape({}))
};

Error.defaultProps = {
  message: {},
  messageTestId: '',
  content: {},
  footer: []
};
export default Error;
