/**
 *
 * Request received
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// components
import requestReceivedImg from 'assets/img/dashboard/coach_requested.png';
import { showModal } from 'store/actions/ModalAction';
import analytics from 'utils/analytics/analytics';
import config from 'config';
import SmartAppLink from '../../../../../components/Popups/SmartAppLink';
import { ActionButton } from '../../../../../components/ui/Buttons';
import Lang from '../../../../../components/Lang';
// styles
import style from './VideoTherapyRequestReceived.module.scss';
// assets
// actions
// utils
// config

const { appName } = config;

function VideoTherapyRequestReceived({ actions }) {
  const requestReceivedHandler = () => {
    analytics.track(
      'modal video therapy request received',
      'clicked start here'
    );
    actions.showModal(SmartAppLink, {
      source: 'smart-app-link',
      props: {
        headerText: <Lang path="therapyRequest.headerText" />
      }
    });
  };
  return (
    <div>
      <div className={style.topSection}>
        <img data-testid="success-icon" src={requestReceivedImg} alt="" />
        <h2 data-testid="success-message">
          <Lang path="popups.requestReceivedHeader" />
        </h2>
        <p>
          <Lang
            path="popups.requestReceivedMessage"
            values={{
              appName
            }}
          />
        </p>
      </div>
      <div className={style.btmSection}>
        <ActionButton
          testId="start-here"
          onClick={requestReceivedHandler}
          className={style.requestButton}
        >
          <Lang path="popups.startHere" />
        </ActionButton>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    showModal: bindActionCreators(showModal, dispatch)
  }
});

export default connect(null, mapDispatchToProps)(VideoTherapyRequestReceived);
