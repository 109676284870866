/* Component to show list of textInputs */

import React from 'react';
import propTypes from 'prop-types';

import {
  textInputHeaderStyle,
  historyEmptyContainer,
  textInputLogStyle
} from './TextInputLog.module.scss';
import TextInputAccordionCard from './TextInputAccordionCard/TextInputAccordionCard';

const TextInputLog = ({ header, textInputData, noProgressPlaceHolder }) => (
  <div data-testid="text-input-log-container" className={textInputLogStyle}>
    <h2 data-testid="text-input-log-header" className={textInputHeaderStyle}>
      {header}
    </h2>
    <div data-testid="item-list">
      {textInputData && textInputData.length > 0 ? (
        textInputData.map(({ text, createdAt }, index) => (
          <TextInputAccordionCard
            text={text}
            createdAt={createdAt}
            key={index.toString()}
            index={index}
          />
        ))
      ) : (
        <div
          data-testid="no-progress-container"
          className={historyEmptyContainer}
        >
          {noProgressPlaceHolder}
        </div>
      )}
    </div>
  </div>
);

TextInputLog.propTypes = {
  header: propTypes.string,
  textInputData: propTypes.arrayOf(
    propTypes.shape({
      createdAt: propTypes.string,
      text: propTypes.string
    })
  ),
  noProgressPlaceHolder: propTypes.string
};

TextInputLog.defaultProps = {
  header: '',
  textInputData: [],
  noProgressPlaceHolder: ''
};

export default TextInputLog;
