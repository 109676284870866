import { makeStyles } from '@material-ui/core';
import { brandColorForegroundPrimary } from 'styles/config.scss';

const borderColor = '#898b8e';

export const inputStyles = makeStyles({
  root: {
    color: brandColorForegroundPrimary,
    '&:hover $notchedOutline': {
      borderColor
    },
    '@media (hover: none)': {
      '&:hover $notchedOutline': {
        borderColor
      }
    },
    '&$focused $notchedOutline': {
      borderColor
    }
  },
  focused: {
    borderColor
  },
  notchedOutline: {
    borderColor
  },
  inputMultiline: {
    padding: '10px 0'
  }
});

export const labelStyles = makeStyles({
  root: {
    color: brandColorForegroundPrimary,
    '&$focused': {
      // increase the specificity for the pseudo class
      color: '#898b8e',
      lineHeight: '1.5rem',
      paddingRight: 0
    }
  },
  focused: {
    color: '#898b8e'
  }
});
