/**
 * Reducer that manages the state of selecting goal for a report id
 */

import {
  SELECT_GOAL,
  SELECT_GOAL_DONE,
  SELECT_GOAL_ERROR
} from '../actions/types';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_GOAL:
      return {
        ...state,
        isFetching: true
      };
    case SELECT_GOAL_DONE:
      return {
        ...state,
        isFetching: false,
        hasError: false
      };
    case SELECT_GOAL_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true
      };
    default:
      return state;
  }
};
