/**
 * main index file that renders the app
 */

import React from 'react';
import { getPlatforms } from '@ionic/react';
import ReactDOM from 'react-dom';
import { SplashScreen } from '@capacitor/splash-screen';
import appboy from '@braze/web-sdk';
import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import 'react-app-polyfill/ie11';
import 'resize-observer-polyfill/dist/ResizeObserver.global';
import 'react-app-polyfill/stable';
import './styles/app.global.scss';
import App from './App';
import registerServiceWorker from './register-service-worker';
import {
  getTokenAndSetAuth0Id,
  getAndSetUserDetailsFromToken
} from './utils/auth';
import { initDeviceId, addAppEventListeners } from './utils/capacitor';
import logger from './utils/logger';
import helpers from './utils/helpers';
import config from './config';

// Only to be used in development to check for performance issues

// Uncomment while debugging performance issues
// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

const {
  braze: { apiKey, endpoint, enabled },
  environment
} = config;
if (helpers.platformInfo.isBrowser && enabled === 'true') {
  try {
    logger.info('Initializing appboy', 'index.jsx', {
      environment,
      platformTypes: getPlatforms().join(','),
      isBrowser: helpers.platformInfo.isBrowser
    });
    appboy.initialize(apiKey, {
      baseUrl: endpoint,
      enableLogging: environment !== 'prod'
    });
  } catch (error) {
    logger.error('Error occurred while initailizing the appboy', 'index.jsx', {
      error,
      platformTypes: getPlatforms().join(','),
      isBrowser: helpers.platformInfo.isBrowser
    });
  }
} else {
  logger.info(
    'Either the platform is not web or braze is disabled for this brand',
    'index.jsx',
    {
      enabled,
      platformTypes: getPlatforms().join(','),
      isBrowser: helpers.platformInfo.isBrowser
    }
  );
}

registerServiceWorker(() => {
  caches.keys().then((names) => {
    for (let i = 0; i < names.length; i += 1) {
      caches.delete(names[i]);
    }
  });
});

Promise.all([
  getAndSetUserDetailsFromToken(),
  getTokenAndSetAuth0Id(),
  initDeviceId(),
  addAppEventListeners()
])
  .catch((error) => {
    logger.error('Error while initializing app', 'index.jsx', { error });
  })
  .finally(() => {
    ReactDOM.render(<App />, document.getElementById('root'));
    SplashScreen.hide()
      .then(() => {
        logger.info('Splash screen is hidden', 'index.jsx');
      })
      .catch((error) => {
        logger.error('Unable to hide splash screen', 'index.jsx', { error });
      });
  });
