import React from 'react';
import classNamesInstance from 'classnames/bind';
import styles from './Arrows.module.scss';

const classNames = classNamesInstance.bind(styles);

const PrevArrow = ({ onClick, testId }) => (
  <button
    type="button"
    className={classNames({
      prevButton: true,
      buttonWrapper: true
    })}
    onClick={onClick}
    data-testid={testId}
  >
    <i className="fa fa-arrow-circle-left fa-lg" />
  </button>
);

export default PrevArrow;
