import React from 'react';
import { brandColorForegroundPrimary } from 'styles/config.scss';
import Lang from '../Lang';

import styles from './LevelSeparator.module.scss';

const { levelSeparator, levelLabel } = styles;

const levelTextMap = {
  low: 'levelTexts.lowText',
  moderate: 'levelTexts.moderateText',
  high: 'levelTexts.highText',
  bonus: 'levelTexts.bonusText'
};

const LevelSeparatorComponent = ({ text, bgClass }) => (
  <div data-testid={`severity-${text}`} className={levelSeparator}>
    <svg height="1px" width="100%">
      {/* 0.5 alpha = 80 in hex */}
      <line x1="100%" stroke={`${brandColorForegroundPrimary}80`} />
    </svg>
    <span className={[levelLabel, bgClass].join(' ')}>
      <Lang path={levelTextMap[text]} />
    </span>
  </div>
);

export default LevelSeparatorComponent;
