import React from 'react';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';
import mailIcon from 'assets/icons/eap/eap_mail.svg';
import webIcon from 'assets/icons/eap/eap_web.svg';
import analytics from 'utils/analytics';
import styles from './EAPInfo.module.scss';

const EAPInfo = ({ eapInfo }) => {
  const {
    container,
    eapContent,
    bigAvatar,
    eapDetailText,
    imageStyle,
    eapPhoneStyle,
    textContainer,
    eapPhoneContent,
    eapPhoneIcon,
    eapInfoStyle
  } = styles;

  const { displayName, icon, eap_phone: eapPhone, url, email, info } = eapInfo;

  const handleClick = (source) => {
    analytics.track('eap', `clicked ${source}`);
  };

  return (
    <div data-testid="eap-info-container" className={container}>
      <div className={styles.middleDiv}>
        <div className={styles.middleContainer}>
          <div className={styles.detailEapTextContainer}>
            <p data-testid="eap-detail-text" className={eapDetailText}>
              {displayName}
            </p>
          </div>
        </div>
        {icon && (
          <div className={styles.imageLogoContainer}>
            <img
              data-testid="logo-image"
              alt={displayName}
              src={`/eap/${icon}`}
              className={bigAvatar}
            />
          </div>
        )}
      </div>
      <div className={styles.bottomDivContainer}>
        <div className={styles.bottomContainContainer}>
          {url && (
            <div className={textContainer}>
              <img
                alt={url}
                src={webIcon}
                className={imageStyle}
                data-testid="eap-web-icon"
              />
              <a
                href={url}
                onClick={() => handleClick('url')}
                className={eapContent}
                data-testid="eap-website-url"
                target="_blank"
                rel="noopener noreferrer"
              >
                {url}
              </a>
            </div>
          )}
          {info && <span className={eapInfoStyle}>{info}</span>}
          {email && (
            <div className={textContainer}>
              <img
                alt={email}
                src={mailIcon}
                className={imageStyle}
                data-testid="eap-email-icon"
              />
              <a
                href={`mailto:${email}`}
                onClick={() => handleClick('email')}
                className={eapContent}
                data-testid="eap-email"
              >
                {email}
              </a>
            </div>
          )}
          {eapPhone && (
            <div className={[textContainer, eapPhoneStyle].join(' ')}>
              <Icon className={eapPhoneIcon} data-testid="eap-phone-icon">
                call
              </Icon>
              <a
                data-testid="eap-contact-number"
                href={`tel:${eapPhone}`}
                onClick={() => handleClick('phone')}
                className={eapPhoneContent}
              >
                {eapPhone}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { partner } = state.companyDetails.companyDetails;
  return { eapInfo: partner };
};

export default connect(mapStateToProps)(EAPInfo);
