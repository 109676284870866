import React, { useCallback, useEffect, useState } from 'react';
import EventIcon from '@material-ui/icons/Event';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { MuiThemeProvider } from '@material-ui/core';
import { throttle, isEmpty, get } from 'lodash';
import classNamesInstance from 'classnames/bind';

import { useScrollToField } from 'utils/hooks';

import theme from './Fields.theme';

import styles from './Fields.module.scss';

const classNames = classNamesInstance.bind(styles);
const Fields = ({ fields, fieldsValue, setFieldsValue }) => {
  // state for checking if input is in view
  const [inputBoxInView, setInputBoxInView] = useState(true);
  const { changeFocusField } = useScrollToField();
  const footerHeight = 72;

  // function for scrollInto view
  const throttledScrollIntoView = useCallback(
    throttle((event) => {
      const inputFieldName = get(event, 'target.name', '');
      if (isEmpty(inputFieldName)) {
        return;
      }
      const [currentInputField] = document.getElementsByName(inputFieldName);

      if (!isEmpty(currentInputField)) {
        currentInputField.scrollIntoView();
      }
    }, 200),
    []
  );

  const _handleChange = (event) => {
    throttledScrollIntoView(event);
    setFieldsValue({ ...fieldsValue, [event.target.name]: event.target.value });
  };

  // checking if element is in view.
  const isElementInViewport = () => {
    const { activeElement } = document;
    const rectangle = activeElement.getBoundingClientRect();
    return (
      rectangle.top >= 0 &&
      rectangle.bottom <=
        (document.documentElement.clientHeight || window.innerHeight) -
          footerHeight
    );
  };

  // setting inputBoxInView state according to isElementInViewport
  const hideCaretIfInputNotInView = useCallback(() => {
    if (isElementInViewport()) {
      setInputBoxInView(true);
    } else {
      setInputBoxInView(false);
    }
  }, []);

  // for changing caret-color when element is not in viewport while scrolling
  useEffect(() => {
    // using wrapperHOC component to check scrolling
    const wrapper = document.getElementById('wrapper');
    if (wrapper) {
      wrapper.addEventListener('scroll', hideCaretIfInputNotInView);
    }
    return () => {
      if (wrapper) {
        wrapper.removeEventListener('scroll', hideCaretIfInputNotInView);
      }
    };
  }, [hideCaretIfInputNotInView]);
  const inputFieldClass = classNames({
    container: true,
    caretTransparentStyle: !inputBoxInView
  });
  return !isEmpty(fields) ? (
    <div className={inputFieldClass}>
      <MuiThemeProvider theme={theme}>
        {fields.map((field, index) => (
          <TextField
            key={index}
            id={`text-field-${index}`}
            name={field.name}
            rows={field.rows}
            placeholder={field.ph}
            label={field.label === '' ? undefined : field.label}
            variant={field.rows > 1 ? 'outlined' : 'standard'}
            value={fieldsValue[field.name] ?? ''}
            onChange={_handleChange}
            fullWidth
            multiline
            onClick={changeFocusField}
            InputProps={{
              endAdornment:
                field.ph === 'MM/DD/YYYY' ? (
                  <InputAdornment position="end">
                    <EventIcon />
                  </InputAdornment>
                ) : null
            }}
          />
        ))}
      </MuiThemeProvider>
    </div>
  ) : null;
};

export default Fields;
