import React from 'react';
import PasswordValidator from '../../components/ui/Validation/PasswordValidator/PasswordValidator';
import moment from '../moment';
import i18n from '../../assets/lang';
// Component
import Lang from '../../components/Lang';

const fieldTypes = {
  text: 'text',
  select: 'select',
  check: 'check'
};

const required = (value, field, fieldType) => {
  if (value || typeof value === 'number') {
    if (!(typeof value === 'string' && !value.trim())) {
      return undefined;
    }
  }

  if (fieldType === fieldTypes.check) {
    return (
      <Lang
        path="validations.checkField"
        values={{ field: field.toLowerCase() }}
      />
    );
  }
  return (
    <Lang
      path="validations.validationError"
      values={{
        fieldType:
          fieldType === fieldTypes.text
            ? i18n.t('validations.enterText')
            : i18n.t('validations.selectText'),
        field: field.toLowerCase()
      }}
    />
  );
};

const validationLogic = {
  email: (value) => {
    /* eslint-disable-next-line max-len */
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return value && !re.test(value)
      ? i18n.t('validations.invalidEmailText')
      : undefined;
  },
  password: (value) => {
    /* eslint-disable no-useless-escape */
    const passwordRules = {
      lowercase: new RegExp('[a-z]'),
      uppercase: new RegExp('[A-Z]'),
      numbers: new RegExp('[0-9]'),
      specialCharacters: new RegExp('[!@#$%^&*]')
    };

    let lengthRule = false;
    let hasUpperCase = false;
    let hasLowerCase = false;
    let hasNumber = false;
    let hasSpecialChar = false;
    let rulesMatched = 0;
    let isRuleCountValid = false;

    /* eslint-enable no-useless-escape */
    if (value) {
      if (value.length >= 8) {
        lengthRule = true;
      }
      if (passwordRules.lowercase.test(value)) {
        hasLowerCase = true;
        rulesMatched += 1;
      }
      if (passwordRules.uppercase.test(value)) {
        hasUpperCase = true;
        rulesMatched += 1;
      }
      if (passwordRules.numbers.test(value)) {
        hasNumber = true;
        rulesMatched += 1;
      }
      if (passwordRules.specialCharacters.test(value)) {
        hasSpecialChar = true;
        rulesMatched += 1;
      }
      isRuleCountValid = rulesMatched >= 3;

      if (isRuleCountValid && lengthRule) {
        return false;
      }
    }

    return (
      <PasswordValidator
        isLengthValid={lengthRule}
        isRuleCountValid={isRuleCountValid}
        hasLowerCase={hasLowerCase}
        hasUpperCase={hasUpperCase}
        hasNumber={hasNumber}
        hasSpecialChar={hasSpecialChar}
      />
    );
  },
  access_code: (companyList) => (value) => {
    for (let i = 0; i < companyList.length; i += 1) {
      if (companyList[i].alias.toLowerCase() === value.toLowerCase()) {
        return undefined;
      }
    }
    return i18n.t('validations.invalidAccessCodeText');
  },
  birth_month: (value) => {
    const re = /^[0-9]*$/;
    const intValue = parseInt(value, 10);
    return value && (intValue > 12 || intValue < 1 || !re.test(value))
      ? i18n.t('validations.invalidMonthText')
      : undefined;
  },
  birth_day: (value, values) => {
    if (value && !values.birth_month) {
      return i18n.t('validations.emptyDobText');
    }
    const re = /^[0-9]*$/;
    const daysInMonth = moment(
      `${values.birth_year}-${values.birth_month}`,
      'YYYY-MM'
    ).daysInMonth();
    const intValue = parseInt(value, 10);
    return value && (intValue < 1 || value > daysInMonth || !re.test(value))
      ? i18n.t('validations.invalidDayText')
      : undefined;
  },

  birth_year: (value, values) => {
    const re = /^[0-9]*$/;
    if (!re.test(value)) {
      return i18n.t('validations.invalidYearText');
    }
    const birthMonth = values.birth_month || 1;
    const birthDay = values.birth_day || 1;
    const currentAge = moment().diff(
      `${value}/${birthMonth}/${birthDay}`,
      'years'
    );
    return value && !(currentAge <= 80 && currentAge >= 18)
      ? i18n.t('validations.invalidAgeText')
      : undefined;
  },

  phone: (value) => {
    const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return value && !re.test(value)
      ? i18n.t('validations.invalidPhoneText')
      : undefined;
  },
  first_name: (name) =>
    /^[a-zA-Z ]+$/.test(name) ? undefined : (
      <Lang
        path="validations.invalidField"
        values={{ field: i18n.t('registerFirstName') }}
      />
    ),
  last_name: (name) =>
    /^[a-zA-Z ]+$/.test(name) ? undefined : (
      <Lang
        path="validations.invalidField"
        values={{ field: i18n.t('registerLastName') }}
      />
    ),
  employee_id: (id) => {
    const re = /\b^[a-zA-Z0-9-]{15,18}$\b/;
    return !id || !re.test(id)
      ? i18n.t('validations.invalidEmployeeIdText')
      : undefined;
  },
  required_locale: (val) =>
    required(val, i18n.t('registerLocale'), fieldTypes.select),
  required_country_code: (val) =>
    required(val, i18n.t('registerCountryCode'), fieldTypes.select),
  required_email: (val) =>
    required(val, i18n.t('registerEmail'), fieldTypes.text),
  required_gender: (val) =>
    required(val, i18n.t('registerGender'), fieldTypes.select),
  required_country: (val) =>
    required(val, i18n.t('registerCountry'), fieldTypes.select),
  required_access_code: (val) =>
    required(val, i18n.t('companySelectInput'), fieldTypes.text),
  required_birth_year: (val) =>
    required(val, i18n.t('registerBirthYear'), fieldTypes.select),
  required_birth_month: (val) =>
    required(val, i18n.t('registerBirthMonth'), fieldTypes.select),
  required_birth_day: (val) =>
    required(val, i18n.t('registerBirthDay'), fieldTypes.select),
  required_first_name: (val) =>
    required(val, i18n.t('registerFirstName'), fieldTypes.text),
  required_last_name: (val) =>
    required(val, i18n.t('registerLastName'), fieldTypes.text),
  required_name: (val) =>
    required(val, i18n.t('registerFullName'), fieldTypes.text),
  required_password: (val) =>
    required(val, i18n.t('registerPassword'), fieldTypes.text),
  required_phone: (val) =>
    required(val, i18n.t('registerPhoneNumber'), fieldTypes.text),
  required_geo_state: (val) =>
    required(val, i18n.t('formCommon.stateText'), fieldTypes.select),
  required_permission_check_box: (val) =>
    required(val, i18n.t('validations.permissionText'), fieldTypes.check),
  required_day1: (val) =>
    val || typeof value === 'number'
      ? undefined
      : i18n.t('validations.emptyAppointmentText'),
  required_employee_id: (val) =>
    required(val, <Lang path="registerEmployeeId" />, fieldTypes.text)
};

export default validationLogic;
