/**
 * Reducer for managing the state when processing a report
 */

import {
  PROCESS_REPORT,
  PROCESS_REPORT_DONE,
  PROCESS_REPORT_ERROR
} from '../actions/types';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROCESS_REPORT:
      return {
        ...state,
        isFetching: true
      };
    case PROCESS_REPORT_DONE:
      return {
        ...state,
        isFetching: false,
        hasError: false
      };
    case PROCESS_REPORT_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true
      };
    default:
      return state;
  }
};
