import ClientJs from 'clientjs';
import logger from 'utils/logger';
import browserType from 'assets/data/self-use/browserTypes';

const getVideoFullScreenDetails = () => {
  const clientJs = new ClientJs();
  let { name } = clientJs.getBrowser();
  name = name.toLowerCase();
  let requestFullScreen = null;
  let exitFullScreen = null;
  const isfullScreenSupported = !!(
    document.fullscreenEnabled || document.webkitFullscreenEnabled
  );
  let fullscreenchangeEventName = null;
  const fullScreenChangeEvents = {
    ie: 'onmsfullscreenchange',
    webkit: 'webkitfullscreenchange',
    mozilla: 'mozfullscreenchange'
  };
  try {
    switch (name) {
      case browserType.IE:
        requestFullScreen = (ref) => ref.msRequestFullscreen();
        exitFullScreen = () => document.msExitFullscreen();
        fullscreenchangeEventName = fullScreenChangeEvents.ie;
        break;
      case browserType.SAFARI:
      case browserType.OPERA:
      case browserType.EDGE:
      case browserType.CHROME:
        requestFullScreen = (ref) => ref.webkitRequestFullscreen();
        exitFullScreen = () => document.webkitExitFullscreen();
        fullscreenchangeEventName = fullScreenChangeEvents.webkit;
        break;
      case browserType.FIREFOX:
        requestFullScreen = (ref) => ref.mozRequestFullScreen();
        exitFullScreen = () => document.exitFullscreen();
        fullscreenchangeEventName = fullScreenChangeEvents.mozilla;
        break;
      default:
        logger.warn(
          'browser does not support full screen api',
          'videoscreen.utils.getFullScreenApi',
          { browsername: name }
        );
    }
  } catch {
    logger.error(
      'Unable to fetch fullscreen api',
      'videoscreen.utils.getFullScreenApi'
    );
  }
  return {
    requestFullScreen,
    exitFullScreen,
    isfullScreenSupported,
    browserName: name,
    fullscreenchangeEventName
  };
};

export default getVideoFullScreenDetails;
