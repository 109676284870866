import React from 'react';
import styles from './Slider.module.scss';

const { nextButton, prevButton } = styles;
const NextArrow = (props) => (
  <button
    type="button"
    style={{ ...props.style }}
    className={nextButton}
    onClick={props.onClick}
  >
    <i className="fa fa-arrow-circle-right fa-lg" />
  </button>
);

const PrevArrow = (props) => (
  <button
    type="button"
    style={{ ...props.style }}
    className={prevButton}
    onClick={props.onClick}
  >
    <i className="fa fa-arrow-circle-left fa-lg" />
  </button>
);
export default {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
};
