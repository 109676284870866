/**
 * Report history container
 */

// Core
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
// Components
import { List } from 'antd';
import { withTranslation } from 'react-i18next';
import ContentContainer from '../../components/layout/ContentContainer';
import BackButton from '../../components/wrapped/BackButton';
import ErrorRetry from '../../components/ErrorRetry';
import HistoryLoader from '../../components/SkeletonLoading/HistoryLoader';
import { ReportTimePicker } from '../Report/components';
import Lang from '../../components/Lang';
// Actions
import getReportList from '../../store/actions/ReportListAction';
// Styles
import styles from './History.module.scss';
// hocs
import withAuth from '../../hoc/withAuth';
// utils
import analytics from '../../utils/analytics';
import api from '../../api/assessment';
import moment from '../../utils/moment';

class History extends Component {
  _handleItemClick = (id) => {
    analytics.track('assessment history', 'selected report', {
      reportId: id
    });
    this.props.history.push(`/report/${id}`);
  };

  _handleRetry = () => {
    analytics.track('assessment history', 'clicked retry');
    this.props.actions.getReportList();
  };

  _renderResultItem = () => {
    if (this.props.reports) {
      return this.props.reports.map((item) => (
        <List.Item
          key={item._id}
          onClick={() => this._handleItemClick(item._id)}
        >
          {item.assessmentName === 'CCA'
            ? this.props.t('surveyName')
            : item.assessmentNameLocalized || item.assessmentName}
          &nbsp;
          {item.timestamp ? (
            <span>
              -{' '}
              <Lang
                path="assessmentHistory"
                values={{
                  completedSince: moment(item.timestamp).fromNow()
                }}
              />
            </span>
          ) : null}
        </List.Item>
      ));
    }
    return null;
  };

  _handleBack = () => {
    analytics.track('assessment history', 'clicked back');
    this.props.history.goBack();
  };

  render() {
    const { container } = styles;
    const { hasError, isFetching } = this.props;
    return (
      <ContentContainer className={container}>
        {hasError ? (
          <ErrorRetry
            title={<Lang path="assessmentHistoryError" />}
            isFetching={isFetching}
            onClick={this._handleRetry}
          />
        ) : (
          <List
            size="large"
            header={
              <h3>
                {isFetching ? (
                  <HistoryLoader type="heading" />
                ) : (
                  <p>
                    <Lang path="historyHeader" />
                  </p>
                )}
              </h3>
            }
            bordered
          >
            {isFetching ? (
              <HistoryLoader type="list" />
            ) : (
              <div data-testid="survey-result-container">
                {this._renderResultItem()}
              </div>
            )}
          </List>
        )}

        <BackButton onClick={this._handleBack} />
        {!_.isEmpty(this.props.reports) && (
          <ReportTimePicker updateFunction={api.updateMultipleReportsTime} />
        )}
      </ContentContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { reports, hasError, isFetching } = state.reportList;
  return {
    reports,
    hasError,
    isFetching
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getReportList: bindActionCreators(getReportList, dispatch)
  }
});
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(History))
);
