import { SET_REDIRECT_URL, RESET_REDIRECT_URL } from '../actions/types';

const INITIAL_STATE = null;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_REDIRECT_URL: {
      return action.url;
    }
    case RESET_REDIRECT_URL: {
      return null;
    }
    default: {
      return state;
    }
  }
};
