import _ from 'lodash';
import i18n from '../assets/lang';

function Locale() {
  this.getActiveLanguage = () => {
    const languages = _.get(i18n, 'languages', []);
    const availableLanguages = Object.keys(_.get(i18n, 'store.data', {}));
    const intersection = _.intersection(languages, availableLanguages);
    return intersection[0];
  };
}

export default new Locale();
