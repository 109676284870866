import { isEmpty, cloneDeep, debounce } from 'lodash';
import { toast, Zoom } from 'react-toastify';
import i18n from 'assets/lang';
import moment from 'utils/moment';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import logger from 'utils/logger';

export const onAddHandler = ({ urlParams, type }) => {
  analytics.track(eventCategories.SELF_USE, `clicked on add ${type} button`, {
    ...urlParams,
    subcategory: 'thoughtRecorder'
  });
  logger.info(
    `User has clicked on add ${type} button.`,
    'ChecklistContainer.utils.onAddHandler',
    { ...urlParams }
  );
};

export const onCancelHandler = ({ urlParams, type }) => {
  analytics.track(
    eventCategories.SELF_USE,
    `clicked on cancel add ${type} button`,
    {
      ...urlParams,
      subcategory: 'thoughtRecorder'
    }
  );
  logger.info(
    `User has clicked on cancel add ${type} button.`,
    'ChecklistContainer.utils.onCancelHandler',
    { urlParams }
  );
};

const getOptionsLimitText = ({ maxSelectableOptions }) =>
  i18n.t('selfUse.session.optionsLimitReachedText', {
    count: maxSelectableOptions
  });

const showOptionsLimitMessage = debounce(({ maxSelectableOptions }) => {
  toast.dismiss();
  const message = getOptionsLimitText({ maxSelectableOptions });
  toast.error(message, {
    toastId: 'thought-recorder-options-limit-message',
    autoClose: 2000,
    transition: Zoom,
    position: 'bottom-center',
    hideProgressBar: true,
    pauseOnHover: true,
    closeButton: true,
    draggable: false
  });
}, 200);

const hasReachedOptionsLimit = ({ options = [], maxSelectableOptions }) => {
  if (maxSelectableOptions) {
    const selectedOptions = options.filter(
      (option) => option.isSelected === true
    );
    if (isEmpty(selectedOptions)) {
      return false;
    }
    if (selectedOptions.length >= maxSelectableOptions) {
      return true;
    }
  }
  return false;
};

export const onSelectHandler = ({
  urlParams,
  stepId,
  isSelected,
  option,
  type,
  currentProgress = {},
  setCurrentProgress,
  maxSelectableOptions
}) => {
  const eventAction = isSelected ? 'selected' : 'unselected';
  analytics.track(eventCategories.SELF_USE, `${eventAction} a ${type}`, {
    ...urlParams,
    customId: option.optionId,
    subcategory: 'thoughtRecorder'
  });
  logger.info(
    `User has selected a ${type}.`,
    'ChecklistContainer.utils.onSelectHandler',
    {
      ...urlParams,
      stepId,
      optionId: option.optionId,
      isSelected
    }
  );

  const progress = cloneDeep(currentProgress) || {};
  const date = moment().locale('en').utc().format();
  if (progress[type]) {
    if (
      isSelected &&
      hasReachedOptionsLimit({ options: progress[type], maxSelectableOptions })
    ) {
      logger.info(
        'User has reached max selectable options limit.',
        'ChecklistContainer.utils.onSelectHandler',
        {
          ...urlParams,
          stepId,
          type,
          maxSelectableOptions
        }
      );
      showOptionsLimitMessage({ maxSelectableOptions });
      return;
    }
    const optionIndex = progress[type].findIndex(
      (currentOption) => currentOption.optionId === option.optionId
    );
    if (optionIndex === -1) {
      progress[type].push({
        ...option,
        isSelected,
        stepId,
        isCustom: false,
        updatedAt: date,
        createdAt: date
      });
    } else {
      progress[type][optionIndex] = {
        ...progress[type][optionIndex],
        isSelected,
        updatedAt: date
      };
    }
  } else {
    progress[type] = [
      {
        ...option,
        isSelected,
        stepId,
        isCustom: false,
        updatedAt: date,
        createdAt: date
      }
    ];
  }
  setCurrentProgress(progress);
};

export const validateCustomInput = ({
  text,
  options,
  maxSelectableOptions,
  type,
  currentProgress = {}
}) => {
  let errorText = '';
  const filteredText = text.replace(/\s+/g, ' ').trim();
  if (isEmpty(filteredText)) {
    errorText = i18n.t('selfUse.session.validations.emptyField', {
      field: i18n.t(`selfUse.session.thoughtRecorder.${type}`)
    });
    return { isValid: false, errorText };
  }

  const optionIndex = options.findIndex(
    (currentOption) =>
      currentOption.text.toLowerCase() === filteredText.toLowerCase()
  );

  if (optionIndex !== -1) {
    errorText = i18n.t('selfUse.session.validations.duplicateEntry', {
      field: i18n.t(`selfUse.session.thoughtRecorder.${type}`)
    });
    return { isValid: false, errorText };
  }

  // validate options limit
  if (
    hasReachedOptionsLimit({
      options: currentProgress[type],
      maxSelectableOptions
    })
  ) {
    showOptionsLimitMessage({ maxSelectableOptions });
    return { isValid: false, errorText: '' };
  }

  return { isValid: true };
};

export const onSaveCustomOptionHandler = ({
  urlParams,
  stepId,
  option,
  currentProgress = {},
  stepsData,
  setCurrentProgress,
  setStepsData,
  type,
  maxSelectableOptions
}) => {
  analytics.track(eventCategories.SELF_USE, `clicked on save custom ${type}`, {
    ...urlParams,
    customId: option.optionId,
    subcategory: 'thoughtRecorder'
  });
  logger.info(
    `User has added a custom ${type}.`,
    'ChecklistContainer.utils.onSaveCustomOptionHandler',
    {
      ...urlParams,
      optionId: option.optionId,
      stepId
    }
  );

  const progress = cloneDeep(currentProgress) || {};
  const date = moment().locale('en').utc().format();
  if (progress[type]) {
    if (
      hasReachedOptionsLimit({ options: progress[type], maxSelectableOptions })
    ) {
      logger.info(
        'User has reached max selectable options limit.',
        'ChecklistContainer.utils.onSaveCustomOptionHandler',
        {
          ...urlParams,
          stepId,
          maxSelectableOptions
        }
      );
      showOptionsLimitMessage({ maxSelectableOptions });
      return;
    }
    progress[type].push({
      ...option,
      isCustom: true,
      stepId,
      isSelected: true,
      updatedAt: date,
      createdAt: date
    });
  } else {
    progress[type] = [
      {
        ...option,
        isCustom: true,
        stepId,
        isSelected: true,
        updatedAt: date,
        createdAt: date
      }
    ];
  }

  // update current steps data
  const stepsDataCopy = cloneDeep(stepsData);
  const { data } = stepsDataCopy[type];
  data.options = [...data.options, option];
  stepsDataCopy[type].data = data;

  setStepsData(stepsDataCopy);
  setCurrentProgress(progress);
};
