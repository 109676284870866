// TODO: Fix cyclic dependencies
/* eslint-disable import/no-cycle */
import axios from 'axios';
import retryPlugin from 'axios-retry';
import qs from 'querystring';
import { get, isEmpty } from 'lodash';

import { httpMethod } from '.';
import config from '../config';
import logger from '../utils/logger';
import helpers from '../utils/helpers';
import auth, { authService } from '../utils/auth';
import storage from '../utils/storage';

const { CancelToken } = axios;

const axiosAuth = axios.create({});

retryPlugin(axiosAuth, {
  retries: 3,
  retryDelay: retryPlugin.exponentialDelay,
  shouldResetTimeout: true,
  retryCondition: (error) => {
    logger.error('Error in auth api call', 'axiosAuth.retryPlugin', {
      error,
      status: get(error, 'response.status', '')
    });
    if (
      !error.response ||
      (!!error.response &&
        (error.response.status >= 500 || error.response.status === 429))
    ) {
      logger.info('Retrying the auth api', 'auth.retryPlugin');
      return true;
    }
    return false;
  }
});

const AUTH0_API_BASE_URL = `https://${config.auth0.domain}`;
const {
  api: { timeout: REQUEST_TIMEOUT },
  auth0: {
    clientID: AUTH0_CLIENT_ID,
    scope: AUTH0_SCOPE,
    audience: AUTH0_AUDIENCE,
    redirectUri: AUTH0_REDIRECT_URI
  }
} = config;

const handleAuthApiRequestError = (error) => {
  let reason = 'error';
  const typeOfError = typeof error;
  if (!isEmpty(error)) {
    if (error.response && [401, 403].includes(error.response.status)) {
      reason = 'expired';
      logger.error(
        'Got 401/403 on requesting Auth0 api. Session Expired Case 2',
        'auth.handleAuthApiRequestError',
        { error }
      );
    } else {
      logger.error(
        'User is not connected to internet or auth0 api is down.' +
          'The latter is more likely since we got this log.',
        'auth.handleAuthApiRequestError',
        { error, typeOfError }
      );
    }
    logger.error(
      'Force logging out user due to auth api error',
      'auth.handleAuthApiRequestError'
    );
    auth.logout(reason);
  } else {
    // check if we missed any case. Should we also handle empty cases?
    logger.error(
      'Error from auth0 api is empty',
      'auth.handleAuthApiRequestError',
      {
        typeOfError
      }
    );
  }
  throw error;
};

const fetchFromAuth0Api = ({ endpoint, method, headers, data }) => {
  logger.info('Auth0 API function invoked', 'auth.fetchFromAuth0Api', {
    endpoint,
    method
  });
  const reqMethod = method || httpMethod.GET;

  const source = CancelToken.source();

  setTimeout(() => {
    logger.info(
      'Cancelling axios request due to request timeout',
      'auth.fetchFromAuth0Api',
      {
        endpoint,
        method
      }
    );
    source.cancel();
  }, REQUEST_TIMEOUT);

  return axiosAuth({
    method: reqMethod,
    url: `${AUTH0_API_BASE_URL}/${endpoint}`,
    headers,
    data,
    cancelToken: source.token
  })
    .then((response) => response)
    .catch(handleAuthApiRequestError);
};

class AuthApi {
  fetchRefreshTokenFromCode = async (code) => {
    logger.info(
      'Fetching refresh token from code',
      'auth.fetchRefreshTokenFromCode',
      { code: helpers.getMaskedData(code) }
    );
    return fetchFromAuth0Api({
      method: httpMethod.POST,
      endpoint: 'oauth/token',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      data: qs.stringify({
        grant_type: 'authorization_code',
        client_id: AUTH0_CLIENT_ID,
        code_verifier: await storage.getItem('verifier'),
        audience: AUTH0_AUDIENCE,
        redirect_uri: AUTH0_REDIRECT_URI,
        scope: AUTH0_SCOPE,
        code
      })
    })
      .then((response) => {
        logger.info(
          'Successfully fetched token data',
          'auth.fetchRefreshTokenFromCode'
        );
        return response.data;
      })
      .catch((error) => {
        logger.error(
          'Error fetching refresh token from code',
          'auth.fetchRefreshTokenFromCode',
          { error }
        );
        throw error;
      })
      .finally(async () => {
        await authService.clearVerifier();
      });
  };

  fetchNewAccessToken = (refreshToken) => {
    logger.info(
      'Fetching new access token from refresh token',
      'auth.fetchNewAccessToken',
      { refreshToken: helpers.getMaskedData(refreshToken) }
    );
    return fetchFromAuth0Api({
      method: httpMethod.POST,
      endpoint: 'oauth/token',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      data: qs.stringify({
        grant_type: 'refresh_token',
        client_id: AUTH0_CLIENT_ID,
        scope: AUTH0_SCOPE,
        refresh_token: refreshToken
      })
    })
      .then((response) => {
        logger.info(
          'Successfully fetched new access token',
          'auth.fetchNewAccessToken'
        );
        return response.data;
      })
      .catch((error) => {
        if (isEmpty(error)) {
          logger.warn('Empty error from Auth API', 'auth.fetchNewAccessToken');
          return { status: 204 };
        }
        logger.error(
          'Error fetching new access token',
          'auth.fetchNewAccessToken',
          {
            error
          }
        );
        throw error;
      });
  };

  revokeRefreshToken = (refreshToken) => {
    logger.info('Calling revoke refresh token api', 'auth.revokeRefreshToken', {
      refreshToken: helpers.getMaskedData(refreshToken)
    });
    fetchFromAuth0Api({
      method: httpMethod.POST,
      endpoint: 'oauth/revoke',
      headers: { 'content-type': 'application/json' },
      data: {
        client_id: AUTH0_CLIENT_ID,
        token: refreshToken
      }
    }).catch((error) => {
      logger.error('Error revoking refresh token', 'auth.revokeRefreshToken', {
        error
      });
    });
  };
}

export default new AuthApi();
