/**
 * Reducer to hold the list of current responses added by the user
 */

import _ from 'lodash';
import {
  ADD_RESPONSE,
  REMOVE_RESPONSE,
  RESET_ASSESSMENT
} from '../actions/types';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_RESPONSE:
      return {
        ...state,
        [action.questionId]: {
          response: action.response,
          score: action.score,
          category: action.category
        }
      };
    case REMOVE_RESPONSE: {
      return _.omit(state, [action.questionId]);
    }
    case RESET_ASSESSMENT: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};
