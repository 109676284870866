export default {
  text: ['intentId', 'intentScore', 'message', 'order'],
  video: [
    'id',
    'intentId',
    'intentScore',
    'heading',
    'duration',
    'url',
    'urlHlsv3',
    'urlCommon',
    'thumbnailUrl',
    'order'
  ]
};
