import React, { useCallback, useEffect, useState } from 'react';
import { get, isInteger, cloneDeep, isEmpty } from 'lodash';
import logger from 'utils/logger';
import Graph from './Graph';
import TrackerSlider from './TrackerSlider';
import styles from './Tracker.module.scss';

const {
  container,
  sliderHeaderStyle,
  sliderContextStyle,
  sliderActionDescriptionStyle,
  outerContainer
} = styles;

const Tracker = ({
  trackerValue,
  screenData,
  trackerProgress = { timestamp: [], trackerValue: [] },
  setScreenProgress,
  renderError,
  scrollToTopRef
}) => {
  const emoticon = get(screenData, 'emoticon', []);
  const rating = get(screenData, 'ratings', []).map((value) => value.label);
  const trackerType = get(screenData, 'trackerType', '');
  const id = get(screenData, 'trackerId', '');
  const subheader = get(screenData, 'subheader', '');
  const title = get(screenData, 'title', '');
  const actionDescription = get(screenData, 'actionDescription', '');
  const description = get(screenData, 'description', '');
  const context = get(screenData, 'context', '');
  const noProgressText = get(screenData, 'noProgressText', '');
  const [progress, setProgress] = useState(trackerProgress);

  useEffect(() => {
    if (trackerType && id && isInteger(trackerValue)) {
      setScreenProgress({
        id,
        trackerType,
        trackerValue
      });
    }
  }, [setScreenProgress, trackerType, id, trackerValue]);
  const onSliderChange = useCallback(
    (value, timestamp) => {
      const graphProgress = cloneDeep(trackerProgress);
      graphProgress.trackerValue.push(value);
      graphProgress.timestamp.push(timestamp);
      setProgress(graphProgress);
    },
    [trackerProgress]
  );

  // Error handling for missing data
  if (
    isEmpty(screenData) ||
    isEmpty(trackerType) ||
    isEmpty(emoticon) ||
    isEmpty(rating) ||
    isEmpty(id)
  ) {
    logger.error('Found empty tracker screen data', 'Tracker', {
      trackerType,
      id
    });
    return renderError('selfUse.session.error.screenErrorMessage');
  }
  return (
    <div className={outerContainer}>
      <div
        data-testid="tracker-container"
        className={container}
        ref={scrollToTopRef}
      >
        <div data-testid="tracker-header" className={sliderHeaderStyle}>
          {title}
        </div>
        {context && (
          <div data-testid="tracker-context" className={sliderContextStyle}>
            {context}
          </div>
        )}
        {actionDescription && (
          <div
            data-testid="tracker-action-description"
            className={sliderActionDescriptionStyle}
          >
            {actionDescription}
          </div>
        )}
        <TrackerSlider
          id={id}
          trackerValue={trackerValue}
          trackerType={trackerType}
          rating={rating}
          emoticon={emoticon}
          onSliderChange={onSliderChange}
          setScreenProgress={setScreenProgress}
        />
        <Graph
          description={description}
          subheader={subheader}
          emoticon={emoticon}
          trackerProgress={progress}
          noProgressText={noProgressText}
        />
      </div>
    </div>
  );
};

export default Tracker;
