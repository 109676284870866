import { pick, get, isEmpty, cloneDeep } from 'lodash';
// assets
import { stepTypesMap, stepTypes } from 'assets/data/self-use/thoughtRecorder';
// utils
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import logger from 'utils/logger';

export const getScreenData = ({ screenData }) => {
  const screenContent = pick(screenData, [
    'subheader',
    'description',
    'thoughtRecorderActiveSteps',
    'thoughtRecorder'
  ]);
  const thoughtRecorderId = get(screenData, 'thoughtRecorder.id', '');
  return {
    ...screenContent,
    id: thoughtRecorderId
  };
};

const getConsolidatedList = ({ customOptions = [], staticOptions = [] }) => {
  if (isEmpty(customOptions)) {
    return staticOptions;
  }
  const filteredCustomOptions = [];
  for (let i = 0; i < customOptions.length; i += 1) {
    const option = staticOptions.find(
      (currentOption) => currentOption.optionId === customOptions[i].optionId
    );
    if (isEmpty(option)) filteredCustomOptions.push(customOptions[i]);
  }

  return [...staticOptions, ...filteredCustomOptions];
};

export const validateScreenData = ({ id, subheader, steps, screenData }) => {
  const isScreenContentEmpty = isEmpty(screenData);
  const isStepsEmpty = isEmpty(steps);
  if (
    isEmpty(subheader) ||
    isStepsEmpty ||
    isScreenContentEmpty ||
    isEmpty(id)
  ) {
    logger.error(
      'Data missing to render thought recorder screen.',
      'ThoughtRecorder.utils.validateScreenData',
      {
        id,
        subheader,
        isScreenContentEmpty,
        isStepsEmpty
      }
    );
    return false;
  }
  return true;
};

const getLastResponse = ({ progress = {}, type }) => {
  let response = '';
  const responses = progress[type];
  if (!isEmpty(responses)) {
    response = responses[responses.length - 1].text;
  }
  return response.trim();
};

const prepareStepsSummary = ({ data, type, progress = {} }) => {
  if (isEmpty(progress[type])) {
    return '';
  }

  if (stepTypesMap[type] === stepTypes.INPUT) {
    return getLastResponse({ progress, type });
  }

  const dataCopy = cloneDeep(data);
  const selectedOptions = progress[type].map((progressOption) => {
    if (progressOption.isSelected) {
      if (progressOption.isCustom && progressOption.isSelected)
        return progressOption.text;
      const index = dataCopy.options.findIndex(
        (step) => step.optionId === progressOption.optionId
      );
      if (dataCopy.options[index]) return dataCopy.options[index].text;
    }
    return '';
  });

  if (!isEmpty(selectedOptions)) {
    const filteredSelectedOptions = selectedOptions.filter(
      (option) => isEmpty(option) === false
    );
    return filteredSelectedOptions.join(', ').trim();
  }
  return '';
};

const getStepsData = ({ steps, activeSteps, progress = {} }) => {
  const stepsData = {};
  const activeStep = activeSteps[0];
  for (let i = 0; i < steps.length; i += 1) {
    let isCompleted = false;
    let isDisabled = true;
    let isActive = false;

    const { id, type } = steps[i];
    if (activeStep === id) {
      isActive = true;
    }
    if (activeSteps.includes(id)) {
      isDisabled = false;
    }
    if (!isEmpty(progress[type])) {
      isCompleted = true;
    }

    stepsData[type] = {
      id,
      header: steps[i].title,
      progressSummary: prepareStepsSummary({
        data: steps[i][type],
        type,
        progress
      }),
      isCompleted,
      isDisabled,
      isActive
    };
    if (stepTypesMap[type] === stepTypes.CHECKLIST) {
      stepsData[type].data = {
        ...steps[i][type],
        options: getConsolidatedList({
          customOptions: progress[type],
          staticOptions: steps[i][type].options
        })
      };
    } else {
      stepsData[type].data = steps[i][type];
    }
  }
  return stepsData;
};

const hasValidPrevSteps = ({
  stepsData,
  activeSteps = [],
  currentProgress = {}
}) => {
  const stepsDataCopy = cloneDeep(stepsData);
  const types = Object.keys(stepsDataCopy);
  for (let i = 0; i < types.length; i += 1) {
    // Return if we encounter any active step since we have already checked all the previous steps.
    if (activeSteps.includes(stepsDataCopy[types[i]].id)) {
      return true;
    }
    // Return if any previous (inactive step) is empty
    if (isEmpty(currentProgress[types[i]])) {
      return false;
    }
  }
  return false;
};

export const checkPreviousStepsData = ({
  goalId,
  sessionId,
  screenId,
  stepsData,
  activeSteps,
  currentProgress = {},
  setHasValidPreviousSteps,
  setIsNextDisabled
}) => {
  const isValid = hasValidPrevSteps({
    stepsData,
    activeSteps,
    currentProgress
  });
  if (isValid) {
    logger.info(
      'Valid previous steps progress.',
      'ThoughtRecorder.utils.checkPreviousStepsData',
      {
        goalId,
        sessionId,
        screenId
      }
    );
  } else {
    logger.info(
      'Invalid previous steps progress.',
      'ThoughtRecorder.utils.checkPreviousStepsData',
      {
        goalId,
        sessionId,
        screenId
      }
    );
  }
  setIsNextDisabled(!isValid);
  setHasValidPreviousSteps(isValid);
};

export const getCurrentProgress = ({ progress = [], id }) => {
  const currentProgress = progress.find((data) => data.id === id);
  if (isEmpty(currentProgress) || isEmpty(currentProgress.stepProgress))
    return {};
  const steps = Object.keys(currentProgress.stepProgress);
  const filteredProgress = {};
  for (let i = 0; i < steps.length; i += 1) {
    if (!isEmpty(currentProgress.stepProgress[steps[i]])) {
      filteredProgress[steps[i]] = currentProgress.stepProgress[steps[i]];
    }
  }
  return filteredProgress;
};

export const stepsDataHandler = ({
  id,
  steps,
  setStepsData,
  progress,
  activeSteps
}) => {
  const currentProgress = getCurrentProgress({
    progress,
    id
  });
  const data = getStepsData({
    steps,
    activeSteps,
    progress: currentProgress
  });
  setStepsData(data);
};

const hasValidCurrentSteps = ({ stepsData, currentProgress = {} }) => {
  const stepsDataCopy = cloneDeep(stepsData);
  const types = Object.keys(stepsDataCopy);
  for (let i = 0; i < types.length; i += 1) {
    if (stepsDataCopy[types[i]].isDisabled === false) {
      if (isEmpty(currentProgress[types[i]])) {
        return false;
      }
      if (stepTypesMap[types[i]] === stepTypes.CHECKLIST) {
        const {
          data: { minOptions }
        } = stepsDataCopy[types[i]];
        const filteredOptions = currentProgress[types[i]].filter(
          (option) => option.isSelected === true
        );
        if (filteredOptions.length < minOptions) return false;
      } else if (stepTypesMap[types[i]] === stepTypes.INPUT) {
        const response = getLastResponse({
          progress: currentProgress,
          type: types[i]
        });
        if (!response) return false;
      }
    }
  }
  return true;
};

export const validateCurrentSteps = ({
  stepsData,
  currentProgress = {},
  setIsNextDisabled
}) => {
  const hasValidSteps = hasValidCurrentSteps({ stepsData, currentProgress });
  if (hasValidSteps) {
    setIsNextDisabled(false);
  } else {
    setIsNextDisabled(true);
  }
};

export const onStepChange = ({
  urlParams,
  expanded,
  type,
  stepsData,
  setStepsData
}) => {
  if (expanded) {
    logger.info(
      `User has clicked on the ${type} step.`,
      'ThoughtRecorder.utils.onStepChange',
      {
        ...urlParams,
        expanded
      }
    );
    analytics.track(
      eventCategories.SELF_USE,
      `User has clicked on the ${type} step.`,
      {
        ...urlParams,
        subcategory: 'thoughtRecorder'
      }
    );
    const stepsDataCopy = cloneDeep(stepsData);
    const types = Object.keys(stepsDataCopy);
    for (let i = 0; i < types.length; i += 1) {
      if (type === types[i]) {
        stepsDataCopy[types[i]].isActive = true;
      } else {
        stepsDataCopy[types[i]].isActive = false;
      }
    }
    setStepsData(stepsDataCopy);
  } else {
    logger.info(
      `User has clicked on the ${type} already active step.`,
      'ThoughtRecorder.utils.onStepChange',
      {
        ...urlParams,
        expanded
      }
    );
  }
};

export const getActiveStepsProgress = ({
  currentProgress = {},
  stepsData = {}
}) => {
  const steps = Object.keys(stepsData);
  const filteredProgress = {};
  for (let i = 0; i < steps.length; i += 1) {
    if (stepsData[steps[i]].isDisabled === false) {
      filteredProgress[steps[i]] = currentProgress[steps[i]];
    }
  }
  return filteredProgress;
};
