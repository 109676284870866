import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'utils/moment';
import styles from './DateMarker.module.scss';

const { container } = styles;

const DateMarker = ({ timeStamp }) => {
  const { t } = useTranslation();
  const time = moment(timeStamp).calendar(
    null,
    t('dateMarkerCalendarFormat', { returnObjects: true })
  );
  return (
    <div className={container} data-testid="date-marker-container">
      <span data-testid="message-time">{time}</span>
    </div>
  );
};

export default DateMarker;
