/**
 * Wrapper for Radio field for redux-form
 */

import React from 'react';
import { Radio } from 'antd';

const RadioGroup = Radio.Group;

const RadioField = (props) => (
  <RadioGroup
    value={props.input.value}
    onChange={(val) => props.input.onChange(val)}
    {...props}
  >
    {props.children}
  </RadioGroup>
);

export default RadioField;
