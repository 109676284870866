import { get, cloneDeep, isEmpty, pick, debounce, kebabCase } from 'lodash';
import { toast, Zoom } from 'react-toastify';
import i18n from 'assets/lang';
import moment from 'utils/moment';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import logger from '../../../../../utils/logger';

const getScreenData = (screenData) => {
  const screenContent = pick(screenData, [
    'subheader',
    'descriptionV2',
    'checklistScreenId'
  ]);
  const checklistObj = get(screenData, 'checklist[0]', {});
  const checklist = pick(checklistObj, [
    'options',
    'customOptionPlaceholder',
    'type',
    'allowCustom',
    'maxOptions'
  ]);
  return {
    id: screenContent.checklistScreenId,
    optionsData: checklist.options,
    ...screenContent,
    ...checklist,
    maxOptions: checklist.maxOptions
  };
};

const getOptionsLimitText = ({ maxSelectableOptions }) =>
  i18n.t('selfUse.session.optionsLimitReachedText', {
    count: maxSelectableOptions
  });

const showOptionsLimitMessage = debounce(
  ({ maxSelectableOptions, contentType }) => {
    toast.dismiss();
    const message = getOptionsLimitText({ maxSelectableOptions });
    toast.error(message, {
      toastId: `${kebabCase(contentType)}-screen-options-limit-message`,
      autoClose: 2000,
      transition: Zoom,
      position: 'bottom-center',
      hideProgressBar: true,
      pauseOnHover: true,
      closeButton: true,
      draggable: false
    });
  },
  200
);

const hasReachedOptionsLimit = ({ options = [], maxSelectableOptions }) => {
  if (maxSelectableOptions) {
    const selectedOptions = options.filter(
      (option) => option.isSelected === true
    );
    if (isEmpty(selectedOptions)) {
      return false;
    }
    if (selectedOptions.length >= maxSelectableOptions) {
      return true;
    }
  }
  return false;
};

const onCancelHandler = ({ goalId, sessionId, screenId, contentType }) => {
  analytics.track(
    eventCategories.SELF_USE,
    'clicked on cancel add checklist option button',
    {
      goalId,
      sessionId,
      screenId,
      subcategory: contentType
    }
  );
  logger.info(
    'User has clicked on cancel add checklist option button.',
    'ChecklistScreen.utils.onCancelHandler',
    {
      goalId,
      sessionId,
      screenId,
      contentType
    }
  );
};

const onAddHandler = ({ goalId, sessionId, screenId, contentType }) => {
  analytics.track(
    eventCategories.SELF_USE,
    'clicked on add checklist option button',
    {
      goalId,
      sessionId,
      screenId,
      subcategory: contentType
    }
  );
  logger.info(
    'User has clicked on add checklist option button.',
    'ChecklistScreen.utils.onAddHandler',
    {
      goalId,
      sessionId,
      screenId,
      contentType
    }
  );
};

const onSelectHandler = ({
  goalId,
  sessionId,
  screenId,
  isSelected,
  option,
  currentProgress,
  setCurrentProgress,
  contentType,
  maxSelectableOptions
}) => {
  const eventAction = isSelected ? 'selected' : 'unselected';
  analytics.track(
    eventCategories.SELF_USE,
    `${eventAction} a checklist option`,
    {
      goalId,
      sessionId,
      screenId,
      customId: option.optionId,
      subcategory: contentType
    }
  );
  logger.info(
    'User has selected a checklist option.',
    'ChecklistScreen.utils.onSelectHandler',
    {
      goalId,
      sessionId,
      screenId,
      optionId: option.optionId,
      isSelected,
      contentType
    }
  );

  const progress = cloneDeep(currentProgress) || [];
  if (
    isSelected &&
    hasReachedOptionsLimit({ options: progress, maxSelectableOptions })
  ) {
    logger.info(
      'User has reached max selectable options limit.',
      'ChecklistScreen.utils.onSelectHandler',
      {
        goalId,
        sessionId,
        screenId,
        maxSelectableOptions,
        contentType
      }
    );
    showOptionsLimitMessage({ maxSelectableOptions, contentType });
    return;
  }
  const optionIndex = progress.findIndex(
    (currentOption) => currentOption.optionId === option.optionId
  );
  const date = moment().locale('en').utc().format();
  if (optionIndex === -1) {
    progress.push({
      ...option,
      isCustom: false,
      isSelected,
      updatedAt: date,
      createdAt: date,
      type: contentType
    });
  } else {
    progress[optionIndex] = {
      ...progress[optionIndex],
      isCustom: progress[optionIndex].isCustom || false,
      isSelected,
      updatedAt: date,
      type: contentType
    };
  }
  setCurrentProgress(progress);
};

const onSaveCustomOptionHandler = ({
  goalId,
  sessionId,
  screenId,
  option,
  currentProgress,
  currentOptions,
  setCurrentProgress,
  setCurrentOptions,
  contentType,
  maxSelectableOptions
}) => {
  analytics.track(eventCategories.SELF_USE, 'clicked on save custom option', {
    goalId,
    sessionId,
    screenId,
    customId: option.optionId,
    subcategory: contentType
  });
  logger.info(
    'User has added a custom checklist option.',
    'ChecklistScreen.utils.onSaveCustomOptionHandler',
    {
      goalId,
      sessionId,
      screenId,
      optionId: option.optionId,
      contentType
    }
  );
  setCurrentOptions([...currentOptions, option]);

  const progress = cloneDeep(currentProgress) || [];
  const date = moment().locale('en').utc().format();

  if (hasReachedOptionsLimit({ options: progress, maxSelectableOptions })) {
    logger.info(
      'User has reached max selectable options limit.',
      'ChecklistScreen.utils.onSaveCustomOptionHandler',
      {
        goalId,
        sessionId,
        screenId,
        maxSelectableOptions,
        contentType
      }
    );
    showOptionsLimitMessage({ maxSelectableOptions, contentType });
    return;
  }

  progress.push({
    ...option,
    isCustom: true,
    isSelected: true,
    updatedAt: date,
    createdAt: date,
    type: contentType
  });
  setCurrentProgress(progress);
};

const getConsolidatedList = ({ customOptions = [], staticOptions = [] }) => {
  if (isEmpty(customOptions)) {
    return staticOptions;
  }

  const filteredCustomOptions = [];
  for (let i = 0; i < customOptions.length; i += 1) {
    const option = staticOptions.find(
      (currentOption) => currentOption.optionId === customOptions[i].optionId
    );
    if (isEmpty(option)) filteredCustomOptions.push(customOptions[i]);
  }

  return [...staticOptions, ...filteredCustomOptions];
};

const optionsListHandler = ({
  optionsData = [],
  currentSelectedOptions = [],
  setCurrentOptions
}) => {
  const consolidatedList = getConsolidatedList({
    customOptions: currentSelectedOptions,
    staticOptions: optionsData
  });
  setCurrentOptions(consolidatedList);
};

const validateScreenData = ({ screenContent, id, subheader }) => {
  if (isEmpty(screenContent) || isEmpty(id) || isEmpty(subheader)) {
    logger.error(
      'Data missing to render checklist screen.',
      'ChecklistScreen.utils.validateScreenData',
      {
        id,
        subheader,
        isScreenContentEmpty: isEmpty(screenContent)
      }
    );
    return false;
  }
  return true;
};

const validateCustomInput = ({
  text,
  currentOptions,
  maxSelectableOptions,
  currentProgress,
  contentType
}) => {
  let errorText = '';
  const filteredText = text.replace(/\s+/g, ' ').trim();
  if (isEmpty(filteredText)) {
    errorText = i18n.t('selfUse.session.validations.emptyField', {
      field: i18n.t(`selfUse.session.fields.${contentType}`)
    });
    return { isValid: false, errorText };
  }
  const optionIndex = currentOptions.findIndex(
    (currentOption) =>
      currentOption.text.toLowerCase() === filteredText.toLowerCase()
  );

  if (optionIndex !== -1) {
    errorText = i18n.t('selfUse.session.validations.duplicateEntry', {
      field: i18n.t(`selfUse.session.fields.${contentType}`)
    });
    return { isValid: false, errorText };
  }

  // validate options limit
  const progress = cloneDeep(currentProgress) || [];
  if (
    hasReachedOptionsLimit({
      options: progress,
      maxSelectableOptions
    })
  ) {
    showOptionsLimitMessage({ maxSelectableOptions, contentType });
    return { isValid: false, errorText };
  }

  return { isValid: true };
};

export {
  getScreenData,
  onCancelHandler,
  onAddHandler,
  onSelectHandler,
  onSaveCustomOptionHandler,
  getConsolidatedList,
  optionsListHandler,
  validateScreenData,
  validateCustomInput
};
