import i18n from 'assets/lang';
import mentalWellbeingImg from 'assets/img/dashboard/resources/mental-wellbeing.png';

export default [
  {
    id: 'mental-wellbeing-counseling',
    name: 'Mental wellbeing service',
    header: i18n.t(
      'resources.counsellingServices.services.mentalWellbeing.header'
    ),
    description: i18n.t(
      'resources.counsellingServices.services.mentalWellbeing.description'
    ),
    buttonText: i18n.t(
      'resources.counsellingServices.services.mentalWellbeing.buttonText'
    ),
    url: 'https://www.resourcesforliving.com/home/services/emotional-wellbeing-support?txtUserName=wfbstaff&txtPassword=staff',
    img: mentalWellbeingImg
  }
];
