/**
 * Reducer for managing the state of access token to access it globally in case session expires
 */

import { SET_SESSION_EXPIRED } from '../actions/types';

const INITIAL_STATE = {
  isSessionExpired: true
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SESSION_EXPIRED:
      return {
        ...state,
        isSessionExpired: action.status
      };
    default:
      return state;
  }
};
