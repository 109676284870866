import { makeStyles } from '@material-ui/core';
import {
  brandColorForegroundPrimary,
  brandColorForegroundSecondary,
  fontWeightSemibold,
  fontSizeTextLarge,
  fontSizeTextDefault
} from 'styles/config.scss';

export const inputStyles = makeStyles({
  root: {
    color: brandColorForegroundPrimary,
    fontWeight: fontWeightSemibold,
    fontSize: fontSizeTextLarge
  },
  underline: {
    '&:before': {
      borderBottom: `1px solid ${brandColorForegroundSecondary} !important`
    },
    '&:after': {
      borderBottom: `2px solid ${brandColorForegroundPrimary}`
    },
    '&:hover:not($disabled):before': {
      borderBottom: `2px solid ${brandColorForegroundPrimary} !important`,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        borderBottom: `1px solid ${brandColorForegroundSecondary}`
      }
    }
  }
});

export const labelStyles = makeStyles({
  root: {
    color: brandColorForegroundSecondary,
    fontSize: fontSizeTextLarge,
    letterSpacing: '-0.2px',
    '&$focused': {
      // increase the specificity for the pseudo class
      color: brandColorForegroundSecondary,
      fontSize: fontSizeTextDefault,
      letterSpacing: '-0.4px',
      lineHeight: '22px'
    }
  },
  focused: {
    color: brandColorForegroundSecondary
  },
  disabled: {
    color: '#fff'
  }
});
