import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: 'inherit'
  },
  palette: {
    primary: {
      main: '#ffffff',
      contrastText: '#ffffff',
      light: '#ffffff',
      dark: '#ffffff'
    },
    secondary: {
      main: '#fff',
      contrastText: 'rgba(0,0,0,0)',
      light: 'rgba(0,0,0,0)',
      dark: 'rgba(0,0,0,0)'
    }
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: '#bbf7ff'
      }
    },
    MuiInput: {
      root: {
        color: '#fff',
        fontFamily: 'inherit'
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #bbf7ff'
        },
        '&:after': {
          borderBottom: '2px solid #fff'
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: '2px solid #bbf7ff'
        },
        '&:hover:not($disabled):before': {
          borderBottom: '1px solid #bbf7ff',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            borderBottom: '1px solid #bbf7ff'
          }
        }
      }
    },
    MuiSelectIcon: {
      root: {
        color: '#fff !important'
      }
    },
    MuiFormControl: {
      root: {
        marginTop: '20px'
      }
    },
    MuiMenu: {
      paper: {
        backgroundColor: '#ffffff'
      }
    },
    MuiCheckbox: {
      root: {
        color: '#ffffff'
      }
    }
  }
});
