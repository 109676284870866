import React from 'react';
import featureService from 'utils/feature-service';
// Component
import Lang from '../../components/Lang';
// config
import i18n from '../lang';
import config from '../../config';

const { appName } = config;

const showScreen = () => true;
const getSliderAssets = () => [
  {
    title: <Lang path="onboarding.slide1.title" values={{ appName }} />,
    text: <Lang path="onboarding.slide1.text" components={{ bold: <b /> }} />,
    list: i18n.t('onboarding.slide1.list', { returnObjects: true }),
    slide: 1,
    dots: 'yellow',
    show: showScreen
  },
  {
    title: <Lang path="onboarding.slide2.title" />,
    text: (
      <Lang
        path="onboarding.slide2.text"
        components={{ bold: <b />, br: <br /> }}
      />
    ),
    slide: 2,
    dots: 'blue',
    show: showScreen
  },
  {
    title: <Lang path="onboarding.slide3.title" />,
    text: (
      <Lang
        path="onboarding.slide3.text"
        components={{ bold: <b />, br: <br /> }}
      />
    ),
    slide: 3,
    dots: 'blue',
    show: showScreen
  },
  {
    title: <Lang path="onboarding.slide4.title" />,
    text: (
      <Lang
        path="onboarding.slide4.text"
        components={{ bold: <b />, br: <br /> }}
      />
    ),
    slide: 4,
    dots: 'red',
    show: showScreen
  },
  {
    title: <Lang path="onboarding.slide5.title" />,
    text: <Lang path="onboarding.slide5.text" components={{ bold: <b /> }} />,
    slide: 5,
    dots: 'red',
    show: showScreen
  },
  {
    title: <Lang path="onboarding.slide6.title" />,
    text: <Lang path="onboarding.slide6.text" values={{ appName }} />,
    list: i18n.t('onboarding.slide6.list', { returnObjects: true }),
    slide: 6,
    dots: 'blueDotsCoach',
    show: featureService._hasCoachAccessFeature
  },
  {
    title: '',
    text: [''],
    slide: 7,
    dots: 'white',
    show: showScreen
  }
];

export default getSliderAssets;
