export default {
  audio: 'audio',
  video: 'video',
  text: 'text',
  tracker: 'tracker',
  multiChecklist: 'multiChecklist',
  completion: 'completion',
  thoughtRecorder: 'thoughtRecorder',
  textInput: 'textInput',
  situation: 'situation',
  checklist: 'checklist'
};
