import angerIcon from '../icons/goal/anger.svg';
import anxietyIcon from '../icons/goal/manageAnxiety.svg';
import depressionIcon from '../icons/goal/health_and_depression.svg';
import ptsIcon from '../icons/goal/depression_icon.svg';
import relationshipConflictIcon from '../icons/goal/conflict_with_coworkers.svg';
import datingIcon from '../icons/goal/dating_icon.svg';
import sleepIcon from '../icons/goal/sleep.svg';
import stressIcon from '../icons/goal/stress_icon.svg';
import substanceUseAbuseIcon from '../icons/goal/substance_use.svg';
import workEngagementIcon from '../icons/goal/work_icon.svg';
import interviewIcon from '../icons/goal/interview_icon.svg';
import marriageIcon from '../icons/goal/marriage_icon.svg';
import publicSpeakingIcon from '../icons/goal/public_speaking_icon.svg';
import productivityIcon from '../icons/goal/productivity_icon.svg';
import socialAnxietyIcon from '../icons/goal/relationship_icon.svg';
import workLifeBalanceIcon from '../icons/goal/work-life balance.svg';
import parentingIcon from '../icons/goal/family_icon.svg';
import highStressWorkIcon from '../icons/goal/hswe.svg';
import covid19Icon from '../icons/goal/covid19.svg';

export default {
  anger: angerIcon,
  anxiety: anxietyIcon,
  depression: depressionIcon,
  pts: ptsIcon,
  relationshipConflict: relationshipConflictIcon,
  sleep: sleepIcon,
  stress: stressIcon,
  substanceUse: substanceUseAbuseIcon,
  substanceUseAndAbuse: substanceUseAbuseIcon,
  workEngagement: workEngagementIcon,
  conflictAtWork: relationshipConflictIcon,
  datingAnxiety: datingIcon,
  interviewAnxiety: interviewIcon,
  generalAnxiety: anxietyIcon,
  marriage: marriageIcon,
  presentationAnxiety: publicSpeakingIcon,
  productivity: productivityIcon,
  socialAnxiety: socialAnxietyIcon,
  workLifeBalance: workLifeBalanceIcon,
  parentingChildren: parentingIcon,
  highStressWork: highStressWorkIcon,
  covid19: covid19Icon
};

export const goalIcon = publicSpeakingIcon;
