/**
 *
 * Request Coach
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import InPersonTherapyRequestStart from './components/InPersonTherapyRequestStart';
import InPersonTherapyRequestReceived from './components/InPersonTherapyRequestReceived';

const InPersonTherapyRequest = (props) => {
  const { inPersonTherapyRequestDone } = props;
  return inPersonTherapyRequestDone ? (
    <InPersonTherapyRequestReceived />
  ) : (
    <InPersonTherapyRequestStart />
  );
};

const mapStateToProps = (state) => {
  const {
    inPersonTherapy: { isFetching, inPersonTherapyRequestDone }
  } = state;
  return {
    inPersonTherapyRequestDone,
    isFetching
  };
};

export default connect(mapStateToProps)(InPersonTherapyRequest);
