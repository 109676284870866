import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';

export const onClickHandler = ({ url, service }) => {
  analytics.track(
    eventCategories.DASHBOARD,
    `click on ${service} service button`
  );
  window.open(url, '__blank');
};
