/**
 * Actions and action creators that are used to fetch list of reports for
 * the currently logged in user
 */

import api from '../../api/assessment';

import {
  FETCH_REPORT_LIST,
  FETCH_REPORT_LIST_DONE,
  FETCH_REPORT_LIST_ERROR
} from './types';

import logger from '../../utils/logger';
import handleUnexpectedError from '../helper';

const fetchReportListStart = () => ({ type: FETCH_REPORT_LIST });

const fetchReportListDone = (data) => ({
  type: FETCH_REPORT_LIST_DONE,
  data
});

const fetchReportListError = () => ({ type: FETCH_REPORT_LIST_ERROR });

export default () => (dispatch) => {
  dispatch(fetchReportListStart());
  return api
    .getReportList()
    .then((resp) => {
      if (resp.status === 200) {
        dispatch(fetchReportListDone(resp.data));
      } else {
        logger.error(
          'An error occurred while fetching the report list',
          'ReportListAction.default',
          { resp }
        );
        dispatch(fetchReportListError());
      }
    })
    .catch((err) => {
      handleUnexpectedError(
        'An unexpected error occurred while fetching the report list',
        'ReportListAction.default',
        err
      );
      dispatch(fetchReportListError());
    });
};
