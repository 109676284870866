/* eslint-disable import/no-cycle */
import moment from 'moment-timezone';
import { isPlatform } from '@ionic/react';
import { android, ios, formfactor, mouse, browser } from 'platform-detect';
import _ from 'lodash';
import platformType from 'assets/data/platform-types';
import featureService from './feature-service';
import coachRequestStatusTypes from '../assets/data/dashboard/coachRequestStatusTypes';
import store, { dispatch } from '../store';
import history from './history';
import { getUserDetails, isLoggedIn } from './auth';
import logger from './logger';
import config from '../config';
import screenTypes from '../assets/data/self-use/screenTypes';
import storage from './storage';
import { getDurationOfLastAssessmentAndToday } from '../containers/Callback/Callback.utils';

const {
  logoPath: defaultLogoPath,
  cdnBaseUrl,
  retakeAssessmentForEligibilityDuration
} = config;

/**
 * helper methods, hopefully many more to come
 */

const levels = ['low', 'moderate', 'high'];

/*
rootPaths handles back button navigation and check if current path is home/root
- "relative" paths contains path parameters or subpath and extraction is required
- "absolute" paths do not have any path parameters and no extraction is required
*/
const rootPaths = {
  relative: ['report', 'coach', 'my-plan'],
  absolute: ['self-use', 'resources', 'account']
};

const surveyPaths = ['assessment', 'diagnostic'];

class Helpers {
  levelSortFunc = (a, b) => levels.indexOf(b.level) - levels.indexOf(a.level);

  getMaskedData = (data) => {
    if (!_.isEmpty(data)) {
      return `****${data.slice(data.length - 3, data.length)}`;
    }
    logger.info('Data to be masked is empty', 'helpers.getMaskedData');
    return data;
  };

  unreadMessageSortFunc = (a, b) => {
    if (a.unread > b.unread) {
      return -1;
    }
    if (b.unread > a.unread) {
      return 1;
    }
    return 0;
  };

  getLastReportId = () => {
    const { reports } = store.getState().reportList;
    let lastReportId;
    const hasReports = reports.length > 0;
    if (hasReports) {
      lastReportId = reports[0]._id;
    }
    return lastReportId;
  };

  getCurrentGoalAndSeverity = () => {
    const { goalId, severity } = store.getState().dashBoard;
    return {
      goalId,
      severity
    };
  };

  isCoach = () => {
    const { roles } = getUserDetails();
    return !roles || roles.indexOf('coach') !== -1;
  };

  redirectAfterLogin = () => {
    const { loginRedirect, requestCoach } = store.getState();
    const { coachRequestStatus } = requestCoach;
    if (loginRedirect) {
      history.replace(loginRedirect);
    } else if (this.isCoach() && featureService._hasCoachChatOnWebFeature()) {
      history.replace('/coach/chat');
    } else if (this.canShowCoachChat(coachRequestStatus)) {
      history.replace('/coach');
    } else if (coachRequestStatus === coachRequestStatusTypes.inProgress) {
      history.replace('/my-plan');
    } else {
      history.replace(this.homeUrl());
    }
  };

  homeUrl = () => {
    let homeUrl = featureService._hasIntelliPlatformFeature()
      ? '/assessment/conversational'
      : '/intro';

    const lastReportId = this.getLastReportId();
    // Check if there is any last report Id and set the redirect url
    // to the report details page
    if (this.isCoach()) {
      homeUrl = '/coach/chat';
    } else if (lastReportId) {
      logger.info(
        'User has already taken the assessment redirecting to my-plan or report page',
        'helpers.homeUrl',
        { lastReportId }
      );
      if (featureService._hasIntelliPlatformFeature()) {
        homeUrl = '/my-plan';
      } else {
        homeUrl = `/report/${lastReportId}`;
      }
    }
    return homeUrl;
  };

  sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  createInitials = (name) => {
    const nameInitials = name.match(/\b(\w)/g);
    if (!_.isEmpty(nameInitials)) {
      return nameInitials.join('').substring(0, 3);
    }
    return '';
  };

  transformBySeverity = (goals) => {
    logger.info(
      'Transforming goals by severity',
      'helpers.transformBySeverity'
    );
    const goalsSeverityMap = {};
    Object.keys(goals).forEach((key) => {
      const index = goals[key].level;
      if (goalsSeverityMap[index]) goalsSeverityMap[index].push(key);
      else goalsSeverityMap[index] = [key];
    });
    return goalsSeverityMap;
  };

  canShowCoachChat = (coachRequestStatus) => {
    const isCoachAssigned =
      coachRequestStatus === coachRequestStatusTypes.assigned;
    return !!(featureService._hasCoachAccessOnWebFeature() && isCoachAssigned);
  };

  getActivePath = () => {
    const {
      location: { pathname }
    } = history;
    return pathname.trim().toLowerCase();
  };

  isActivePath = (path) => {
    const activePath = this.getActivePath();
    return activePath.split('/')[1] === path;
  };

  getLocalTime = (date = null) =>
    moment(date || new Date())
      .tz(moment.tz.guess())
      .format('MM/DD/YYYY HH:mm:ss z Z');

  getCompanyLogo = (companyDetails) => {
    const logoPath = _.get(companyDetails, 'logoPath', defaultLogoPath);
    return `${cdnBaseUrl}${logoPath}`;
  };

  getSupportedScreenTypes = () => Object.values(screenTypes);

  platformInfo = {
    isDesktop:
      !(android || ios) && (formfactor === platformType.DESKTOP || mouse),
    isTab:
      formfactor === platformType.TABLET &&
      (isPlatform(platformType.CAPACITOR) || ios || android),
    isMobile:
      formfactor === platformType.PHONE &&
      (isPlatform(platformType.CAPACITOR) || ios || android) &&
      formfactor !== platformType.TABLET,
    isIos: ios,
    isBrowser: browser,
    isAndroid: android,
    isMobileWeb:
      (formfactor === platformType.PHONE ||
        formfactor === platformType.TABLET) &&
      !isPlatform(platformType.CAPACITOR) &&
      (ios || android)
  };

  isRootPath = () => {
    const { absolute, relative } = rootPaths;
    const currentPath = this.getActivePath();
    const pathName = currentPath.split('/')[1];
    // We need report route check due to multiple home tabs i.e Goal and My plan
    if (pathName === 'report' && featureService._hasIntelliPlatformFeature()) {
      return false;
    }
    return (
      absolute.includes(currentPath.substring(1)) || relative.includes(pathName)
    );
  };

  isFirstAssessmentScreen = () => {
    const currentPath = this.getActivePath();
    const { reports } = store.getState().reportList;
    const pathName = currentPath.split('/')[1];
    return surveyPaths.includes(pathName) && _.isEmpty(reports);
  };

  canShowTabs = () =>
    isLoggedIn() && !this.platformInfo.isDesktop && this.isRootPath();

  canShowBackButton = () =>
    isLoggedIn() &&
    !this.platformInfo.isDesktop &&
    !this.isRootPath() &&
    (this.getLastReportId() || this.isFirstAssessmentScreen());

  getUserCompanyDetail = async () => {
    let accessCode = '';
    let companyDetail = {};

    try {
      const storeData = store.getState();

      accessCode = await storage.getItem('accessCode');
      if (accessCode) {
        const { companyList } = storeData.companyList;
        companyDetail = companyList.find(
          (company) => company.alias === accessCode
        );
      } else {
        logger.warn(
          'Unable to get access code',
          'helpers.getUserCompanyDetail'
        );
      }
    } catch (error) {
      logger.warn('Unable to get access code', 'helpers.getUserCompanyDetail', {
        error,
        accessCode,
        companyDetail
      });
    }
    return {
      companyDetail,
      accessCode
    };
  };

  getParsedValue = (value) => {
    let parsedValue;
    try {
      parsedValue = JSON.parse(value);
    } catch (error) {
      parsedValue = value;
    }
    return parsedValue;
  };

  checkAndAssignAutoCoachForEligibility = (
    reports,
    partner,
    coachRequestStatus,
    startAutoCoachAssign
  ) => {
    if (
      featureService._hasCoachAccessFeature() &&
      featureService._hasEligibilityAssessmentFeature() &&
      !_.isEmpty(reports) &&
      coachRequestStatus === coachRequestStatusTypes.none
    ) {
      const diff = getDurationOfLastAssessmentAndToday('day', reports);
      const autoAssignCoachForEligibility =
        diff < parseInt(retakeAssessmentForEligibilityDuration, 10);
      if (autoAssignCoachForEligibility) {
        dispatch(
          startAutoCoachAssign({
            mode: 'auto',
            eapName: partner.name,
            goals: this.transformBySeverity(reports)
          })
        );
      }
    }
  };
}
export default new Helpers();
