import history from 'utils/history';
import auth from 'utils/auth';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import logger from '../../utils/logger';

export const redirectHandler = ({ url, name }) => {
  logger.info(
    `Clicked on ${name.toLowerCase()} button`,
    'Account.utils.redirectHandler',
    {
      buttonName: name
    }
  );
  analytics.track(
    eventCategories.ACCOUNT,
    `clicked on ${name.toLowerCase()} button`
  );
  history.push(url, { source: 'navigation' });
};

export const logoutHandler = () => {
  analytics.track(eventCategories.ACCOUNT, 'clicked on logout');
  logger.info('Clicked on Logout', 'Account.utils.logoutHandler');
  auth.logout();
};
