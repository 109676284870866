import React, { useState, Fragment } from 'react';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
// Wizard forms
import analytics from 'utils/analytics/analytics';
import { getSelectStateList } from 'store/selectors/state-list.selector';
import VideoTherapyFormFirstPage from './Wizard/VideoTherapyFirstPage';
import VideoTherapySecondPage from './Wizard/VideoTherapySecondPage';

import styles from './VideoTherapyRequest.module.scss';
// selectors
// Components
import Lang from '../../../../components/Lang';

const { headerContainer, formContainer } = styles;

const VideoTherapyRequest = ({ onSubmit, isFetching, hasError }) => {
  const [page, setPage] = useState(1);
  const stateList = useSelector((state) => getSelectStateList(state));
  const onSubmitHandler = () => {
    analytics.track('modal request appointment', 'clicked next');
    setPage(page + 1);
  };

  const onPreviousHandler = () => {
    analytics.track('modal request appointment', 'clicked back');
    setPage(page - 1);
  };

  return (
    <Fragment>
      <div className={headerContainer}>
        <h2>
          <Lang path="therapyRequest.header" />
        </h2>
      </div>
      <div className={formContainer}>
        {page === 1 && (
          <VideoTherapyFormFirstPage
            stateList={stateList}
            onSubmit={onSubmitHandler}
          />
        )}
        {page === 2 && (
          <VideoTherapySecondPage
            isFetching={isFetching}
            hasError={hasError}
            onPrevious={onPreviousHandler}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </Fragment>
  );
};

export default reduxForm({
  form: 'videoTherapyRequest'
})(VideoTherapyRequest);
