import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
// components
// utils
import logger from 'utils/logger';
// Styles
import config from 'config';
import RenderHtml from '../../../../../components/RenderHtml';
import styles from './TextScreen.module.scss';
// config

const {
  container,
  contentStyle,
  topPanelStyle,
  headingStyle,
  imageStyle,
  descriptionStyle
} = styles;

const { cdnBaseUrl } = config;

const TextScreen = ({ screenData, renderError, scrollToTopRef }) => {
  const subheader = _.get(screenData, 'subheader', '');
  const description = _.get(screenData, 'description', '');
  const textScreenContent = _.get(screenData, 'textScreen', []);
  const { goalId, sessionId, screenId } = useParams();

  const renderTextScreenContent = useCallback(
    () =>
      textScreenContent.map((content, index) => {
        let renderedContent = null;
        switch (content.type) {
          case 'text':
            renderedContent = (
              <div
                key={index}
                data-testid={`text-screen-text-${index}`}
                className={contentStyle}
              >
                <RenderHtml>{content.text.html}</RenderHtml>
              </div>
            );
            break;
          case 'image':
            renderedContent = (
              <img
                key={index}
                data-testid={`${index}-text-screen-image`}
                className={imageStyle}
                src={`${cdnBaseUrl}${content.imageUrl}`}
                alt=""
              />
            );
            break;
          default:
            logger.error(
              'Encountered unsupported screen type in textScreen',
              'TextScreen.renderTextScreenContent',
              { content, goalId, sessionId, screenId }
            );
            break;
        }
        return renderedContent;
      }),
    [goalId, screenId, sessionId, textScreenContent]
  );

  const isTextScreenDataEmpty = useMemo(
    () =>
      textScreenContent.some((content) => {
        const html = _.get(content, 'text.html');
        const imageUrl = _.get(content, 'imageUrl');
        const type = _.get(content, 'type');

        return _.isEmpty(type) || (_.isEmpty(html) && _.isEmpty(imageUrl));
      }),
    [textScreenContent]
  );

  if (isTextScreenDataEmpty) {
    logger.error('Found empty textScreen data', 'TextScreen', {
      goalId,
      sessionId,
      screenId
    });
    return renderError('selfUse.session.error.screenErrorMessage');
  }

  return (
    <div
      data-testid="text-screen-container"
      className={container}
      ref={scrollToTopRef}
    >
      <div className={topPanelStyle}>
        <h3 data-testid="text-screen-subheader" className={headingStyle}>
          {subheader}
        </h3>
      </div>
      <div data-testid="text-screen-description" className={descriptionStyle}>
        {description}
      </div>
      {renderTextScreenContent()}
    </div>
  );
};

export default TextScreen;
