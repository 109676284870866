export const CHAT_POLLING_TIMEOUT = 30000;
export const COACH_POLLING_TIMEOUT = 30000;
export const MAX_RETRY_ATTEMPTS = 7;
export const INITIAL_DELAY = 500;
export const AUTHENTICATION_TYPE = 'OPENID_CONNECT';
export const FETCH_POLICIES = {
  cacheAndNetwork: 'cache-and-network',
  cacheFirst: 'cache-first',
  noCache: 'no-cache',
  cacheOnly: 'cache-only',
  networkOnly: 'network-only'
};
