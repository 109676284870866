import React, { useState } from 'react';
import { get } from 'lodash';
import propTypes from 'prop-types';
import { Paper, Tab, Tabs } from '@material-ui/core';
import useStyles from './Tabs.styles';
import styles from './Tabs.module.scss';

const { tabContainer, tabStyle, tabTextStyle } = styles;

const TabPanel = (props) => {
  const { children, value, index, ...rest } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      data-testid={`tabpanel-${index}`}
      {...rest}
    >
      {value === index && children}
    </div>
  );
};

const TabScreen = ({ data, onChange }) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, tabIndex) => {
    setSelectedTab(tabIndex);
    onChange(get(data, `[${tabIndex}].tabs.text`));
  };
  return (
    <div className={tabContainer} data-testid="tabs-container">
      <Paper className={classes.paper}>
        <Tabs
          classes={{
            indicator: classes.indicator,
            flexContainer: classes.flexContainer
          }}
          value={selectedTab}
          onChange={handleChange}
          TabIndicatorProps={{ children: <span /> }}
        >
          {data &&
            data.map(
              (item, index) =>
                item && (
                  <Tab
                    data-testid={`tab-${index}`}
                    disableRipple
                    key={index}
                    classes={{ root: classes.tab }}
                    label={
                      <div className={tabStyle}>
                        {selectedTab === index
                          ? item.tabs.activeIcon
                          : item.tabs.inactiveIcon}
                        <p className={tabTextStyle}>{item.tabs.text}</p>
                      </div>
                    }
                  />
                )
            )}
        </Tabs>
      </Paper>
      {data &&
        data.map(
          (item, index) =>
            item && (
              <TabPanel key={index} index={index} value={selectedTab}>
                {item.content}
              </TabPanel>
            )
        )}
    </div>
  );
};

TabScreen.propTypes = {
  data: propTypes.arrayOf(propTypes.shape({})),
  onChange: propTypes.func
};

TabScreen.defaultProps = {
  data: [],
  onChange: () => null
};

export default TabScreen;
