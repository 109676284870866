import PromiseQueue from 'p-queue';
// TODO: Fix cyclic dependency
// eslint-disable-next-line import/no-cycle
import logger from './logger';

const promiseQueue = new PromiseQueue({ concurrency: 1 });

const handleQueueError = (error) => {
  logger.error(
    'Error occurred in promise queue',
    'promiseQueue.handleQueueError',
    {
      queueInfo: {
        size: promiseQueue.size,
        pendingTasks: promiseQueue.pending
      },
      error
    }
  );
};

const handleQueueOnCompleted = () => {
  logger.info(
    `Queue Task Completed.  Size: ${promiseQueue.size}  Pending: ${promiseQueue.pending}`,
    'promiseQueue.handleQueueOnCompleted'
  );
};

// Emitted if an item throws an error.
promiseQueue.on('error', handleQueueError);

// Emitted when an item completes without error.
promiseQueue.on('completed', handleQueueOnCompleted);

// Creating a new object from PromiseQueue class will result in a new promiseQueue.
export { promiseQueue, PromiseQueue };
