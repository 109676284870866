import React, { useState, useEffect } from 'react';
import { ArrowBack } from '@material-ui/icons';
import Lang from 'components/Lang';
import toast from 'components/Toast';
import withAuth from 'hoc/withAuth';
import { getUserDetails } from 'utils/auth';
import history from 'utils/history';
import styles from './Password.module.scss';
import PasswordForm from './partials/PasswordForm';

import { changePasswordHandler, backButtonHandler } from './Password.utils';

const { container, headerStyle, errorTextStyle, backButtonStyle } = styles;

const Password = () => {
  const { connection } = getUserDetails();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (connection && connection.toLowerCase().includes('saml')) {
      history.push('/account');
    }
  }, [connection]);

  // Prevent navigation while change password in progress.
  useEffect(() => {
    const preventNavigationHandler = history.block(() => {
      if (isLoading) {
        toast.dismiss();
        toast.info(
          <Lang path="account.changePasswordPreventNavigation" />,
          null,
          {
            autoClose: 4000
          }
        );
        return false;
      }
      return true;
    });
    return () => {
      preventNavigationHandler();
    };
  }, [isLoading]);

  const submitHandler = ({
    currentPassword,
    newPassword,
    confirmedPassword
  }) => {
    changePasswordHandler({
      currentPassword,
      newPassword,
      confirmedPassword,
      setError,
      setIsLoading
    });
  };
  return (
    <div data-testid="password-container" className={container}>
      <button
        data-testid="back-button"
        className={backButtonStyle}
        onClick={() => backButtonHandler()}
        type="button"
      >
        <ArrowBack />
      </button>
      <h2 data-testid="change-password-header" className={headerStyle}>
        <Lang path="account.changePasswordHeader" />
      </h2>
      <PasswordForm onSubmit={submitHandler} isLoading={isLoading} />
      <p data-testid="password-error-text" className={errorTextStyle}>
        {error}
      </p>
    </div>
  );
};

export default withAuth(Password);
