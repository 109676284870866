/**
 * Country Options for the registration form
 */

const countries = [
  {
    value: 'AF +93',
    label: 'AF +93',
    code: 'AF',
    phoneCode: '+93',
    country: 'Afghanistan'
  },
  {
    value: 'AX +358-18',
    label: 'AX +358-18',
    code: 'AX',
    phoneCode: '+358-18',
    country: 'Aland Islands'
  },
  {
    value: 'AL +355',
    label: 'AL +355',
    code: 'AL',
    phoneCode: '+355',
    country: 'Albania'
  },
  {
    value: 'DZ +213',
    label: 'DZ +213',
    code: 'DZ',
    phoneCode: '+213',
    country: 'Algeria'
  },
  {
    value: 'AS +1-684',
    label: 'AS +1-684',
    code: 'AS',
    phoneCode: '+1-684',
    country: 'American Samoa'
  },
  {
    value: 'AD +376',
    label: 'AD +376',
    code: 'AD',
    phoneCode: '+376',
    country: 'Andorra'
  },
  {
    value: 'AO +244',
    label: 'AO +244',
    code: 'AO',
    phoneCode: '+244',
    country: 'Angola'
  },
  {
    value: 'AI +1-264',
    label: 'AI +1-264',
    code: 'AI',
    phoneCode: '+1-264',
    country: 'Anguilla'
  },
  {
    value: 'AQ +672',
    label: 'AQ +672',
    code: 'AQ',
    phoneCode: '+672',
    country: 'Antarctica'
  },
  {
    value: 'AG +1-268',
    label: 'AG +1-268',
    code: 'AG',
    phoneCode: '+1-268',
    country: 'Antigua And Barbuda'
  },
  {
    value: 'AR +54',
    label: 'AR +54',
    code: 'AR',
    phoneCode: '+54',
    country: 'Argentina'
  },
  {
    value: 'AM +374',
    label: 'AM +374',
    code: 'AM',
    phoneCode: '+374',
    country: 'Armenia'
  },
  {
    value: 'AW +297',
    label: 'AW +297',
    code: 'AW',
    phoneCode: '+297',
    country: 'Aruba'
  },
  {
    value: 'AU +61',
    label: 'AU +61',
    code: 'AU',
    phoneCode: '+61',
    country: 'Australia'
  },
  {
    value: 'AT +43',
    label: 'AT +43',
    code: 'AT',
    phoneCode: '+43',
    country: 'Austria'
  },
  {
    value: 'AZ +994',
    label: 'AZ +994',
    code: 'AZ',
    phoneCode: '+994',
    country: 'Azerbaijan'
  },
  {
    value: 'BS +1-242',
    label: 'BS +1-242',
    code: 'BS',
    phoneCode: '+1-242',
    country: 'Bahamas'
  },
  {
    value: 'BH +973',
    label: 'BH +973',
    code: 'BH',
    phoneCode: '+973',
    country: 'Bahrain'
  },
  {
    value: 'BD +880',
    label: 'BD +880',
    code: 'BD',
    phoneCode: '+880',
    country: 'Bangladesh'
  },
  {
    value: 'BB +1-246',
    label: 'BB +1-246',
    code: 'BB',
    phoneCode: '+1-246',
    country: 'Barbados'
  },
  {
    value: 'BY +375',
    label: 'BY +375',
    code: 'BY',
    phoneCode: '+375',
    country: 'Belarus'
  },
  {
    value: 'BE +32',
    label: 'BE +32',
    code: 'BE',
    phoneCode: '+32',
    country: 'Belgium'
  },
  {
    value: 'BZ +501',
    label: 'BZ +501',
    code: 'BZ',
    phoneCode: '+501',
    country: 'Belize'
  },
  {
    value: 'BJ +229',
    label: 'BJ +229',
    code: 'BJ',
    phoneCode: '+229',
    country: 'Benin'
  },
  {
    value: 'BM +1-441',
    label: 'BM +1-441',
    code: 'BM',
    phoneCode: '+1-441',
    country: 'Bermuda'
  },
  {
    value: 'BT +975',
    label: 'BT +975',
    code: 'BT',
    phoneCode: '+975',
    country: 'Bhutan'
  },
  {
    value: 'BO +591',
    label: 'BO +591',
    code: 'BO',
    phoneCode: '+591',
    country: 'Bolivia'
  },
  {
    value: 'BQ +599',
    label: 'BQ +599',
    code: 'BQ',
    phoneCode: '+599',
    country: 'Bonaire, Sint Eustatius and Saba'
  },
  {
    value: 'BA +387',
    label: 'BA +387',
    code: 'BA',
    phoneCode: '+387',
    country: 'Bosnia and Herzegovina'
  },
  {
    value: 'BW +267',
    label: 'BW +267',
    code: 'BW',
    phoneCode: '+267',
    country: 'Botswana'
  },
  {
    value: 'BV +0055',
    label: 'BV +0055',
    code: 'BV',
    phoneCode: '+0055',
    country: 'Bouvet Island'
  },
  {
    value: 'BR +55',
    label: 'BR +55',
    code: 'BR',
    phoneCode: '+55',
    country: 'Brazil'
  },
  {
    value: 'IO +246',
    label: 'IO +246',
    code: 'IO',
    phoneCode: '+246',
    country: 'British Indian Ocean Territory'
  },
  {
    value: 'BN +673',
    label: 'BN +673',
    code: 'BN',
    phoneCode: '+673',
    country: 'Brunei'
  },
  {
    value: 'BG +359',
    label: 'BG +359',
    code: 'BG',
    phoneCode: '+359',
    country: 'Bulgaria'
  },
  {
    value: 'BF +226',
    label: 'BF +226',
    code: 'BF',
    phoneCode: '+226',
    country: 'Burkina Faso'
  },
  {
    value: 'BI +257',
    label: 'BI +257',
    code: 'BI',
    phoneCode: '+257',
    country: 'Burundi'
  },
  {
    value: 'KH +855',
    label: 'KH +855',
    code: 'KH',
    phoneCode: '+855',
    country: 'Cambodia'
  },
  {
    value: 'CM +237',
    label: 'CM +237',
    code: 'CM',
    phoneCode: '+237',
    country: 'Cameroon'
  },
  {
    value: 'CA +1',
    label: 'CA +1',
    code: 'CA',
    phoneCode: '+1',
    country: 'Canada'
  },
  {
    value: 'CV +238',
    label: 'CV +238',
    code: 'CV',
    phoneCode: '+238',
    country: 'Cape Verde'
  },
  {
    value: 'KY +1-345',
    label: 'KY +1-345',
    code: 'KY',
    phoneCode: '+1-345',
    country: 'Cayman Islands'
  },
  {
    value: 'CF +236',
    label: 'CF +236',
    code: 'CF',
    phoneCode: '+236',
    country: 'Central African Republic'
  },
  {
    value: 'TD +235',
    label: 'TD +235',
    code: 'TD',
    phoneCode: '+235',
    country: 'Chad'
  },
  {
    value: 'CL +56',
    label: 'CL +56',
    code: 'CL',
    phoneCode: '+56',
    country: 'Chile'
  },
  {
    value: 'CN +86',
    label: 'CN +86',
    code: 'CN',
    phoneCode: '+86',
    country: 'China'
  },
  {
    value: 'CX +61',
    label: 'CX +61',
    code: 'CX',
    phoneCode: '+61',
    country: 'Christmas Island'
  },
  {
    value: 'CC +61',
    label: 'CC +61',
    code: 'CC',
    phoneCode: '+61',
    country: 'Cocos (Keeling) Islands'
  },
  {
    value: 'CO +57',
    label: 'CO +57',
    code: 'CO',
    phoneCode: '+57',
    country: 'Colombia'
  },
  {
    value: 'KM +269',
    label: 'KM +269',
    code: 'KM',
    phoneCode: '+269',
    country: 'Comoros'
  },
  {
    value: 'CG +242',
    label: 'CG +242',
    code: 'CG',
    phoneCode: '+242',
    country: 'Congo'
  },
  {
    value: 'CD +243',
    label: 'CD +243',
    code: 'CD',
    phoneCode: '+243',
    country: 'Congo The Democratic Republic Of The'
  },
  {
    value: 'CK +682',
    label: 'CK +682',
    code: 'CK',
    phoneCode: '+682',
    country: 'Cook Islands'
  },
  {
    value: 'CR +506',
    label: 'CR +506',
    code: 'CR',
    phoneCode: '+506',
    country: 'Costa Rica'
  },
  {
    value: 'CI +225',
    label: 'CI +225',
    code: 'CI',
    phoneCode: '+225',
    country: "Cote D'Ivoire (Ivory Coast)"
  },
  {
    value: 'HR +385',
    label: 'HR +385',
    code: 'HR',
    phoneCode: '+385',
    country: 'Croatia (Hrvatska)'
  },
  {
    value: 'CU +53',
    label: 'CU +53',
    code: 'CU',
    phoneCode: '+53',
    country: 'Cuba'
  },
  {
    value: 'CW +599',
    label: 'CW +599',
    code: 'CW',
    phoneCode: '+599',
    country: 'Curaçao'
  },
  {
    value: 'CY +357',
    label: 'CY +357',
    code: 'CY',
    phoneCode: '+357',
    country: 'Cyprus'
  },
  {
    value: 'CZ +420',
    label: 'CZ +420',
    code: 'CZ',
    phoneCode: '+420',
    country: 'Czech Republic'
  },
  {
    value: 'DK +45',
    label: 'DK +45',
    code: 'DK',
    phoneCode: '+45',
    country: 'Denmark'
  },
  {
    value: 'DJ +253',
    label: 'DJ +253',
    code: 'DJ',
    phoneCode: '+253',
    country: 'Djibouti'
  },
  {
    value: 'DM +1-767',
    label: 'DM +1-767',
    code: 'DM',
    phoneCode: '+1-767',
    country: 'Dominica'
  },
  {
    value: 'DO +1-809 and 1-829',
    label: 'DO +1-809 and 1-829',
    code: 'DO',
    phoneCode: '+1-809 and 1-829',
    country: 'Dominican Republic'
  },
  {
    value: 'TL +670',
    label: 'TL +670',
    code: 'TL',
    phoneCode: '+670',
    country: 'East Timor'
  },
  {
    value: 'EC +593',
    label: 'EC +593',
    code: 'EC',
    phoneCode: '+593',
    country: 'Ecuador'
  },
  {
    value: 'EG +20',
    label: 'EG +20',
    code: 'EG',
    phoneCode: '+20',
    country: 'Egypt'
  },
  {
    value: 'SV +503',
    label: 'SV +503',
    code: 'SV',
    phoneCode: '+503',
    country: 'El Salvador'
  },
  {
    value: 'GQ +240',
    label: 'GQ +240',
    code: 'GQ',
    phoneCode: '+240',
    country: 'Equatorial Guinea'
  },
  {
    value: 'ER +291',
    label: 'ER +291',
    code: 'ER',
    phoneCode: '+291',
    country: 'Eritrea'
  },
  {
    value: 'EE +372',
    label: 'EE +372',
    code: 'EE',
    phoneCode: '+372',
    country: 'Estonia'
  },
  {
    value: 'ET +251',
    label: 'ET +251',
    code: 'ET',
    phoneCode: '+251',
    country: 'Ethiopia'
  },
  {
    value: 'FK +500',
    label: 'FK +500',
    code: 'FK',
    phoneCode: '+500',
    country: 'Falkland Islands'
  },
  {
    value: 'FO +298',
    label: 'FO +298',
    code: 'FO',
    phoneCode: '+298',
    country: 'Faroe Islands'
  },
  {
    value: 'FJ +679',
    label: 'FJ +679',
    code: 'FJ',
    phoneCode: '+679',
    country: 'Fiji Islands'
  },
  {
    value: 'FI +358',
    label: 'FI +358',
    code: 'FI',
    phoneCode: '+358',
    country: 'Finland'
  },
  {
    value: 'FR +33',
    label: 'FR +33',
    code: 'FR',
    phoneCode: '+33',
    country: 'France'
  },
  {
    value: 'GF +594',
    label: 'GF +594',
    code: 'GF',
    phoneCode: '+594',
    country: 'French Guiana'
  },
  {
    value: 'PF +689',
    label: 'PF +689',
    code: 'PF',
    phoneCode: '+689',
    country: 'French Polynesia'
  },
  {
    value: 'TF',
    label: 'TF',
    code: 'TF',
    phoneCode: '+',
    country: 'French Southern Territories'
  },
  {
    value: 'GA +241',
    label: 'GA +241',
    code: 'GA',
    phoneCode: '+241',
    country: 'Gabon'
  },
  {
    value: 'GM +220',
    label: 'GM +220',
    code: 'GM',
    phoneCode: '+220',
    country: 'Gambia'
  },
  {
    value: 'GE +995',
    label: 'GE +995',
    code: 'GE',
    phoneCode: '+995',
    country: 'Georgia'
  },
  {
    value: 'DE +49',
    label: 'DE +49',
    code: 'DE',
    phoneCode: '+49',
    country: 'Germany'
  },
  {
    value: 'GH +233',
    label: 'GH +233',
    code: 'GH',
    phoneCode: '+233',
    country: 'Ghana'
  },
  {
    value: 'GI +350',
    label: 'GI +350',
    code: 'GI',
    phoneCode: '+350',
    country: 'Gibraltar'
  },
  {
    value: 'GR +30',
    label: 'GR +30',
    code: 'GR',
    phoneCode: '+30',
    country: 'Greece'
  },
  {
    value: 'GL +299',
    label: 'GL +299',
    code: 'GL',
    phoneCode: '+299',
    country: 'Greenland'
  },
  {
    value: 'GD +1-473',
    label: 'GD +1-473',
    code: 'GD',
    phoneCode: '+1-473',
    country: 'Grenada'
  },
  {
    value: 'GP +590',
    label: 'GP +590',
    code: 'GP',
    phoneCode: '+590',
    country: 'Guadeloupe'
  },
  {
    value: 'GU +1-671',
    label: 'GU +1-671',
    code: 'GU',
    phoneCode: '+1-671',
    country: 'Guam'
  },
  {
    value: 'GT +502',
    label: 'GT +502',
    code: 'GT',
    phoneCode: '+502',
    country: 'Guatemala'
  },
  {
    value: 'GG +44-1481',
    label: 'GG +44-1481',
    code: 'GG',
    phoneCode: '+44-1481',
    country: 'Guernsey and Alderney'
  },
  {
    value: 'GN +224',
    label: 'GN +224',
    code: 'GN',
    phoneCode: '+224',
    country: 'Guinea'
  },
  {
    value: 'GW +245',
    label: 'GW +245',
    code: 'GW',
    phoneCode: '+245',
    country: 'Guinea-Bissau'
  },
  {
    value: 'GY +592',
    label: 'GY +592',
    code: 'GY',
    phoneCode: '+592',
    country: 'Guyana'
  },
  {
    value: 'HT +509',
    label: 'HT +509',
    code: 'HT',
    phoneCode: '+509',
    country: 'Haiti'
  },
  {
    value: 'HM',
    label: 'HM',
    code: 'HM',
    phoneCode: '+',
    country: 'Heard Island and McDonald Islands'
  },
  {
    value: 'HN +504',
    label: 'HN +504',
    code: 'HN',
    phoneCode: '+504',
    country: 'Honduras'
  },
  {
    value: 'HK +852',
    label: 'HK +852',
    code: 'HK',
    phoneCode: '+852',
    country: 'Hong Kong'
  },
  {
    value: 'HU +36',
    label: 'HU +36',
    code: 'HU',
    phoneCode: '+36',
    country: 'Hungary'
  },
  {
    value: 'IS +354',
    label: 'IS +354',
    code: 'IS',
    phoneCode: '+354',
    country: 'Iceland'
  },
  {
    value: 'IN +91',
    label: 'IN +91',
    code: 'IN',
    phoneCode: '+91',
    country: 'India'
  },
  {
    value: 'ID +62',
    label: 'ID +62',
    code: 'ID',
    phoneCode: '+62',
    country: 'Indonesia'
  },
  {
    value: 'IR +98',
    label: 'IR +98',
    code: 'IR',
    phoneCode: '+98',
    country: 'Iran'
  },
  {
    value: 'IQ +964',
    label: 'IQ +964',
    code: 'IQ',
    phoneCode: '+964',
    country: 'Iraq'
  },
  {
    value: 'IE +353',
    label: 'IE +353',
    code: 'IE',
    phoneCode: '+353',
    country: 'Ireland'
  },
  {
    value: 'IL +972',
    label: 'IL +972',
    code: 'IL',
    phoneCode: '+972',
    country: 'Israel'
  },
  {
    value: 'IT +39',
    label: 'IT +39',
    code: 'IT',
    phoneCode: '+39',
    country: 'Italy'
  },
  {
    value: 'JM +1-876',
    label: 'JM +1-876',
    code: 'JM',
    phoneCode: '+1-876',
    country: 'Jamaica'
  },
  {
    value: 'JP +81',
    label: 'JP +81',
    code: 'JP',
    phoneCode: '+81',
    country: 'Japan'
  },
  {
    value: 'JE +44-1534',
    label: 'JE +44-1534',
    code: 'JE',
    phoneCode: '+44-1534',
    country: 'Jersey'
  },
  {
    value: 'JO +962',
    label: 'JO +962',
    code: 'JO',
    phoneCode: '+962',
    country: 'Jordan'
  },
  {
    value: 'KZ +7',
    label: 'KZ +7',
    code: 'KZ',
    phoneCode: '+7',
    country: 'Kazakhstan'
  },
  {
    value: 'KE +254',
    label: 'KE +254',
    code: 'KE',
    phoneCode: '+254',
    country: 'Kenya'
  },
  {
    value: 'KI +686',
    label: 'KI +686',
    code: 'KI',
    phoneCode: '+686',
    country: 'Kiribati'
  },
  {
    value: 'KP +850',
    label: 'KP +850',
    code: 'KP',
    phoneCode: '+850',
    country: 'Korea North'
  },
  {
    value: 'KR +82',
    label: 'KR +82',
    code: 'KR',
    phoneCode: '+82',
    country: 'Korea South'
  },
  {
    value: 'XK +383',
    label: 'XK +383',
    code: 'XK',
    phoneCode: '+383',
    country: 'Kosovo'
  },
  {
    value: 'KW +965',
    label: 'KW +965',
    code: 'KW',
    phoneCode: '+965',
    country: 'Kuwait'
  },
  {
    value: 'KG +996',
    label: 'KG +996',
    code: 'KG',
    phoneCode: '+996',
    country: 'Kyrgyzstan'
  },
  {
    value: 'LA +856',
    label: 'LA +856',
    code: 'LA',
    phoneCode: '+856',
    country: 'Laos'
  },
  {
    value: 'LV +371',
    label: 'LV +371',
    code: 'LV',
    phoneCode: '+371',
    country: 'Latvia'
  },
  {
    value: 'LB +961',
    label: 'LB +961',
    code: 'LB',
    phoneCode: '+961',
    country: 'Lebanon'
  },
  {
    value: 'LS +266',
    label: 'LS +266',
    code: 'LS',
    phoneCode: '+266',
    country: 'Lesotho'
  },
  {
    value: 'LR +231',
    label: 'LR +231',
    code: 'LR',
    phoneCode: '+231',
    country: 'Liberia'
  },
  {
    value: 'LY +218',
    label: 'LY +218',
    code: 'LY',
    phoneCode: '+218',
    country: 'Libya'
  },
  {
    value: 'LI +423',
    label: 'LI +423',
    code: 'LI',
    phoneCode: '+423',
    country: 'Liechtenstein'
  },
  {
    value: 'LT +370',
    label: 'LT +370',
    code: 'LT',
    phoneCode: '+370',
    country: 'Lithuania'
  },
  {
    value: 'LU +352',
    label: 'LU +352',
    code: 'LU',
    phoneCode: '+352',
    country: 'Luxembourg'
  },
  {
    value: 'MO +853',
    label: 'MO +853',
    code: 'MO',
    phoneCode: '+853',
    country: 'Macau S.A.R.'
  },
  {
    value: 'MG +261',
    label: 'MG +261',
    code: 'MG',
    phoneCode: '+261',
    country: 'Madagascar'
  },
  {
    value: 'MW +265',
    label: 'MW +265',
    code: 'MW',
    phoneCode: '+265',
    country: 'Malawi'
  },
  {
    value: 'MY +60',
    label: 'MY +60',
    code: 'MY',
    phoneCode: '+60',
    country: 'Malaysia'
  },
  {
    value: 'MV +960',
    label: 'MV +960',
    code: 'MV',
    phoneCode: '+960',
    country: 'Maldives'
  },
  {
    value: 'ML +223',
    label: 'ML +223',
    code: 'ML',
    phoneCode: '+223',
    country: 'Mali'
  },
  {
    value: 'MT +356',
    label: 'MT +356',
    code: 'MT',
    phoneCode: '+356',
    country: 'Malta'
  },
  {
    value: 'IM +44-1624',
    label: 'IM +44-1624',
    code: 'IM',
    phoneCode: '+44-1624',
    country: 'Man (Isle of)'
  },
  {
    value: 'MH +692',
    label: 'MH +692',
    code: 'MH',
    phoneCode: '+692',
    country: 'Marshall Islands'
  },
  {
    value: 'MQ +596',
    label: 'MQ +596',
    code: 'MQ',
    phoneCode: '+596',
    country: 'Martinique'
  },
  {
    value: 'MR +222',
    label: 'MR +222',
    code: 'MR',
    phoneCode: '+222',
    country: 'Mauritania'
  },
  {
    value: 'MU +230',
    label: 'MU +230',
    code: 'MU',
    phoneCode: '+230',
    country: 'Mauritius'
  },
  {
    value: 'YT +262',
    label: 'YT +262',
    code: 'YT',
    phoneCode: '+262',
    country: 'Mayotte'
  },
  {
    value: 'MX +52',
    label: 'MX +52',
    code: 'MX',
    phoneCode: '+52',
    country: 'Mexico'
  },
  {
    value: 'FM +691',
    label: 'FM +691',
    code: 'FM',
    phoneCode: '+691',
    country: 'Micronesia'
  },
  {
    value: 'MD +373',
    label: 'MD +373',
    code: 'MD',
    phoneCode: '+373',
    country: 'Moldova'
  },
  {
    value: 'MC +377',
    label: 'MC +377',
    code: 'MC',
    phoneCode: '+377',
    country: 'Monaco'
  },
  {
    value: 'MN +976',
    label: 'MN +976',
    code: 'MN',
    phoneCode: '+976',
    country: 'Mongolia'
  },
  {
    value: 'ME +382',
    label: 'ME +382',
    code: 'ME',
    phoneCode: '+382',
    country: 'Montenegro'
  },
  {
    value: 'MS +1-664',
    label: 'MS +1-664',
    code: 'MS',
    phoneCode: '+1-664',
    country: 'Montserrat'
  },
  {
    value: 'MA +212',
    label: 'MA +212',
    code: 'MA',
    phoneCode: '+212',
    country: 'Morocco'
  },
  {
    value: 'MZ +258',
    label: 'MZ +258',
    code: 'MZ',
    phoneCode: '+258',
    country: 'Mozambique'
  },
  {
    value: 'MM +95',
    label: 'MM +95',
    code: 'MM',
    phoneCode: '+95',
    country: 'Myanmar'
  },
  {
    value: 'NA +264',
    label: 'NA +264',
    code: 'NA',
    phoneCode: '+264',
    country: 'Namibia'
  },
  {
    value: 'NR +674',
    label: 'NR +674',
    code: 'NR',
    phoneCode: '+674',
    country: 'Nauru'
  },
  {
    value: 'NP +977',
    label: 'NP +977',
    code: 'NP',
    phoneCode: '+977',
    country: 'Nepal'
  },
  {
    value: 'NL +31',
    label: 'NL +31',
    code: 'NL',
    phoneCode: '+31',
    country: 'Netherlands'
  },
  {
    value: 'NC +687',
    label: 'NC +687',
    code: 'NC',
    phoneCode: '+687',
    country: 'New Caledonia'
  },
  {
    value: 'NZ +64',
    label: 'NZ +64',
    code: 'NZ',
    phoneCode: '+64',
    country: 'New Zealand'
  },
  {
    value: 'NI +505',
    label: 'NI +505',
    code: 'NI',
    phoneCode: '+505',
    country: 'Nicaragua'
  },
  {
    value: 'NE +227',
    label: 'NE +227',
    code: 'NE',
    phoneCode: '+227',
    country: 'Niger'
  },
  {
    value: 'NG +234',
    label: 'NG +234',
    code: 'NG',
    phoneCode: '+234',
    country: 'Nigeria'
  },
  {
    value: 'NU +683',
    label: 'NU +683',
    code: 'NU',
    phoneCode: '+683',
    country: 'Niue'
  },
  {
    value: 'NF +672',
    label: 'NF +672',
    code: 'NF',
    phoneCode: '+672',
    country: 'Norfolk Island'
  },
  {
    value: 'MP +1-670',
    label: 'MP +1-670',
    code: 'MP',
    phoneCode: '+1-670',
    country: 'Northern Mariana Islands'
  },
  {
    value: 'MK +389',
    label: 'MK +389',
    code: 'MK',
    phoneCode: '+389',
    country: 'North Macedonia'
  },
  {
    value: 'NO +47',
    label: 'NO +47',
    code: 'NO',
    phoneCode: '+47',
    country: 'Norway'
  },
  {
    value: 'OM +968',
    label: 'OM +968',
    code: 'OM',
    phoneCode: '+968',
    country: 'Oman'
  },
  {
    value: 'PK +92',
    label: 'PK +92',
    code: 'PK',
    phoneCode: '+92',
    country: 'Pakistan'
  },
  {
    value: 'PW +680',
    label: 'PW +680',
    code: 'PW',
    phoneCode: '+680',
    country: 'Palau'
  },
  {
    value: 'PS +970',
    label: 'PS +970',
    code: 'PS',
    phoneCode: '+970',
    country: 'Palestinian Territory Occupied'
  },
  {
    value: 'PA +507',
    label: 'PA +507',
    code: 'PA',
    phoneCode: '+507',
    country: 'Panama'
  },
  {
    value: 'PG +675',
    label: 'PG +675',
    code: 'PG',
    phoneCode: '+675',
    country: 'Papua new Guinea'
  },
  {
    value: 'PY +595',
    label: 'PY +595',
    code: 'PY',
    phoneCode: '+595',
    country: 'Paraguay'
  },
  {
    value: 'PE +51',
    label: 'PE +51',
    code: 'PE',
    phoneCode: '+51',
    country: 'Peru'
  },
  {
    value: 'PH +63',
    label: 'PH +63',
    code: 'PH',
    phoneCode: '+63',
    country: 'Philippines'
  },
  {
    value: 'PN +870',
    label: 'PN +870',
    code: 'PN',
    phoneCode: '+870',
    country: 'Pitcairn Island'
  },
  {
    value: 'PL +48',
    label: 'PL +48',
    code: 'PL',
    phoneCode: '+48',
    country: 'Poland'
  },
  {
    value: 'PT +351',
    label: 'PT +351',
    code: 'PT',
    phoneCode: '+351',
    country: 'Portugal'
  },
  {
    value: 'PR +1-787 and 1-939',
    label: 'PR +1-787 and 1-939',
    code: 'PR',
    phoneCode: '+1-787 and 1-939',
    country: 'Puerto Rico'
  },
  {
    value: 'QA +974',
    label: 'QA +974',
    code: 'QA',
    phoneCode: '+974',
    country: 'Qatar'
  },
  {
    value: 'RE +262',
    label: 'RE +262',
    code: 'RE',
    phoneCode: '+262',
    country: 'Reunion'
  },
  {
    value: 'RO +40',
    label: 'RO +40',
    code: 'RO',
    phoneCode: '+40',
    country: 'Romania'
  },
  {
    value: 'RU +7',
    label: 'RU +7',
    code: 'RU',
    phoneCode: '+7',
    country: 'Russia'
  },
  {
    value: 'RW +250',
    label: 'RW +250',
    code: 'RW',
    phoneCode: '+250',
    country: 'Rwanda'
  },
  {
    value: 'SH +290',
    label: 'SH +290',
    code: 'SH',
    phoneCode: '+290',
    country: 'Saint Helena'
  },
  {
    value: 'KN +1-869',
    label: 'KN +1-869',
    code: 'KN',
    phoneCode: '+1-869',
    country: 'Saint Kitts And Nevis'
  },
  {
    value: 'LC +1-758',
    label: 'LC +1-758',
    code: 'LC',
    phoneCode: '+1-758',
    country: 'Saint Lucia'
  },
  {
    value: 'PM +508',
    label: 'PM +508',
    code: 'PM',
    phoneCode: '+508',
    country: 'Saint Pierre and Miquelon'
  },
  {
    value: 'VC +1-784',
    label: 'VC +1-784',
    code: 'VC',
    phoneCode: '+1-784',
    country: 'Saint Vincent And The Grenadines'
  },
  {
    value: 'BL +590',
    label: 'BL +590',
    code: 'BL',
    phoneCode: '+590',
    country: 'Saint-Barthelemy'
  },
  {
    value: 'MF +590',
    label: 'MF +590',
    code: 'MF',
    phoneCode: '+590',
    country: 'Saint-Martin (French part)'
  },
  {
    value: 'WS +685',
    label: 'WS +685',
    code: 'WS',
    phoneCode: '+685',
    country: 'Samoa'
  },
  {
    value: 'SM +378',
    label: 'SM +378',
    code: 'SM',
    phoneCode: '+378',
    country: 'San Marino'
  },
  {
    value: 'ST +239',
    label: 'ST +239',
    code: 'ST',
    phoneCode: '+239',
    country: 'Sao Tome and Principe'
  },
  {
    value: 'SA +966',
    label: 'SA +966',
    code: 'SA',
    phoneCode: '+966',
    country: 'Saudi Arabia'
  },
  {
    value: 'SN +221',
    label: 'SN +221',
    code: 'SN',
    phoneCode: '+221',
    country: 'Senegal'
  },
  {
    value: 'RS +381',
    label: 'RS +381',
    code: 'RS',
    phoneCode: '+381',
    country: 'Serbia'
  },
  {
    value: 'SC +248',
    label: 'SC +248',
    code: 'SC',
    phoneCode: '+248',
    country: 'Seychelles'
  },
  {
    value: 'SL +232',
    label: 'SL +232',
    code: 'SL',
    phoneCode: '+232',
    country: 'Sierra Leone'
  },
  {
    value: 'SG +65',
    label: 'SG +65',
    code: 'SG',
    phoneCode: '+65',
    country: 'Singapore'
  },
  {
    value: 'SX +1721',
    label: 'SX +1721',
    code: 'SX',
    phoneCode: '+1721',
    country: 'Sint Maarten (Dutch part)'
  },
  {
    value: 'SK +421',
    label: 'SK +421',
    code: 'SK',
    phoneCode: '+421',
    country: 'Slovakia'
  },
  {
    value: 'SI +386',
    label: 'SI +386',
    code: 'SI',
    phoneCode: '+386',
    country: 'Slovenia'
  },
  {
    value: 'SB +677',
    label: 'SB +677',
    code: 'SB',
    phoneCode: '+677',
    country: 'Solomon Islands'
  },
  {
    value: 'SO +252',
    label: 'SO +252',
    code: 'SO',
    phoneCode: '+252',
    country: 'Somalia'
  },
  {
    value: 'ZA +27',
    label: 'ZA +27',
    code: 'ZA',
    phoneCode: '+27',
    country: 'South Africa'
  },
  {
    value: 'GS +500',
    label: 'GS +500',
    code: 'GS',
    phoneCode: '+500',
    country: 'South Georgia'
  },
  {
    value: 'SS +211',
    label: 'SS +211',
    code: 'SS',
    phoneCode: '+211',
    country: 'South Sudan'
  },
  {
    value: 'ES +34',
    label: 'ES +34',
    code: 'ES',
    phoneCode: '+34',
    country: 'Spain'
  },
  {
    value: 'LK +94',
    label: 'LK +94',
    code: 'LK',
    phoneCode: '+94',
    country: 'Sri Lanka'
  },
  {
    value: 'SD +249',
    label: 'SD +249',
    code: 'SD',
    phoneCode: '+249',
    country: 'Sudan'
  },
  {
    value: 'SR +597',
    label: 'SR +597',
    code: 'SR',
    phoneCode: '+597',
    country: 'Suriname'
  },
  {
    value: 'SJ +47',
    label: 'SJ +47',
    code: 'SJ',
    phoneCode: '+47',
    country: 'Svalbard And Jan Mayen Islands'
  },
  {
    value: 'SZ +268',
    label: 'SZ +268',
    code: 'SZ',
    phoneCode: '+268',
    country: 'Swaziland'
  },
  {
    value: 'SE +46',
    label: 'SE +46',
    code: 'SE',
    phoneCode: '+46',
    country: 'Sweden'
  },
  {
    value: 'CH +41',
    label: 'CH +41',
    code: 'CH',
    phoneCode: '+41',
    country: 'Switzerland'
  },
  {
    value: 'SY +963',
    label: 'SY +963',
    code: 'SY',
    phoneCode: '+963',
    country: 'Syria'
  },
  {
    value: 'TW +886',
    label: 'TW +886',
    code: 'TW',
    phoneCode: '+886',
    country: 'Taiwan'
  },
  {
    value: 'TJ +992',
    label: 'TJ +992',
    code: 'TJ',
    phoneCode: '+992',
    country: 'Tajikistan'
  },
  {
    value: 'TZ +255',
    label: 'TZ +255',
    code: 'TZ',
    phoneCode: '+255',
    country: 'Tanzania'
  },
  {
    value: 'TH +66',
    label: 'TH +66',
    code: 'TH',
    phoneCode: '+66',
    country: 'Thailand'
  },
  {
    value: 'TG +228',
    label: 'TG +228',
    code: 'TG',
    phoneCode: '+228',
    country: 'Togo'
  },
  {
    value: 'TK +690',
    label: 'TK +690',
    code: 'TK',
    phoneCode: '+690',
    country: 'Tokelau'
  },
  {
    value: 'TO +676',
    label: 'TO +676',
    code: 'TO',
    phoneCode: '+676',
    country: 'Tonga'
  },
  {
    value: 'TT +1-868',
    label: 'TT +1-868',
    code: 'TT',
    phoneCode: '+1-868',
    country: 'Trinidad And Tobago'
  },
  {
    value: 'TN +216',
    label: 'TN +216',
    code: 'TN',
    phoneCode: '+216',
    country: 'Tunisia'
  },
  {
    value: 'TR +90',
    label: 'TR +90',
    code: 'TR',
    phoneCode: '+90',
    country: 'Turkey'
  },
  {
    value: 'TM +993',
    label: 'TM +993',
    code: 'TM',
    phoneCode: '+993',
    country: 'Turkmenistan'
  },
  {
    value: 'TC +1-649',
    label: 'TC +1-649',
    code: 'TC',
    phoneCode: '+1-649',
    country: 'Turks And Caicos Islands'
  },
  {
    value: 'TV +688',
    label: 'TV +688',
    code: 'TV',
    phoneCode: '+688',
    country: 'Tuvalu'
  },
  {
    value: 'UG +256',
    label: 'UG +256',
    code: 'UG',
    phoneCode: '+256',
    country: 'Uganda'
  },
  {
    value: 'UA +380',
    label: 'UA +380',
    code: 'UA',
    phoneCode: '+380',
    country: 'Ukraine'
  },
  {
    value: 'AE +971',
    label: 'AE +971',
    code: 'AE',
    phoneCode: '+971',
    country: 'United Arab Emirates'
  },
  {
    value: 'GB +44',
    label: 'GB +44',
    code: 'GB',
    phoneCode: '+44',
    country: 'United Kingdom'
  },
  {
    value: 'US +1',
    label: 'US +1',
    code: 'US',
    phoneCode: '+1',
    country: 'United States'
  },
  {
    value: 'UM +1',
    label: 'UM +1',
    code: 'UM',
    phoneCode: '+1',
    country: 'United States Minor Outlying Islands'
  },
  {
    value: 'UY +598',
    label: 'UY +598',
    code: 'UY',
    phoneCode: '+598',
    country: 'Uruguay'
  },
  {
    value: 'UZ +998',
    label: 'UZ +998',
    code: 'UZ',
    phoneCode: '+998',
    country: 'Uzbekistan'
  },
  {
    value: 'VU +678',
    label: 'VU +678',
    code: 'VU',
    phoneCode: '+678',
    country: 'Vanuatu'
  },
  {
    value: 'VA +379',
    label: 'VA +379',
    code: 'VA',
    phoneCode: '+379',
    country: 'Vatican City State (Holy See)'
  },
  {
    value: 'VE +58',
    label: 'VE +58',
    code: 'VE',
    phoneCode: '+58',
    country: 'Venezuela'
  },
  {
    value: 'VN +84',
    label: 'VN +84',
    code: 'VN',
    phoneCode: '+84',
    country: 'Vietnam'
  },
  {
    value: 'VG +1-284',
    label: 'VG +1-284',
    code: 'VG',
    phoneCode: '+1-284',
    country: 'Virgin Islands (British)'
  },
  {
    value: 'VI +1-340',
    label: 'VI +1-340',
    code: 'VI',
    phoneCode: '+1-340',
    country: 'Virgin Islands (US)'
  },
  {
    value: 'WF +681',
    label: 'WF +681',
    code: 'WF',
    phoneCode: '+681',
    country: 'Wallis And Futuna Islands'
  },
  {
    value: 'EH +212',
    label: 'EH +212',
    code: 'EH',
    phoneCode: '+212',
    country: 'Western Sahara'
  },
  {
    value: 'YE +967',
    label: 'YE +967',
    code: 'YE',
    phoneCode: '+967',
    country: 'Yemen'
  },
  {
    value: 'ZM +260',
    label: 'ZM +260',
    code: 'ZM',
    phoneCode: '+260',
    country: 'Zambia'
  },
  {
    value: 'ZW +263',
    label: 'ZW +263',
    code: 'ZW',
    phoneCode: '+263',
    country: 'Zimbabwe'
  }
];

const defaultCountry = 'United States';

countries.sort((a, b) => {
  if (a.country === defaultCountry) {
    return -1;
  }
  if (b.country === defaultCountry) {
    return 1;
  }
  return a.country.localeCompare(b.country);
});

export default countries;
