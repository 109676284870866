import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
// components
import LiveChat from 'react-livechat';
// actions
import { minimizeChatWindow } from 'store/actions/ChatWindowAction';
import {
  fetchConversationalAssessment,
  fetchProgressId
} from 'store/actions/ConversationalAssessmentAction';
// utils
import featureService from 'utils/feature-service';
import history from 'utils/history';
import helpers from 'utils/helpers';
import analytics from 'utils/analytics';
// config
import config from 'config';
// hocs
import withAuth from 'hoc/withAuth';
import wrapWithApolloClient from 'hoc/wrapWithApolloClient';
// Component specific
import QuestionList from './components/QuestionList/QuestionList';
// styles
import styles from './ConversationalAssessment.module.scss';

const { container } = styles;

const ConversationalAssessment = () => {
  const { t } = useTranslation();
  if (!featureService._hasIntelliPlatformFeature()) {
    history.push(helpers.homeUrl());
  }

  const dispatch = useDispatch();
  const assessment = useSelector((state) => state.conversationalAssessment);

  const greetingMessages = useMemo(
    () => t('greetingMessageList', { returnObjects: true }),
    [t]
  );
  const assessmentId = useMemo(
    () =>
      featureService._hasCatAssessmentFeature() ? 'cat' : 'conversational',
    []
  );

  useEffect(() => {
    if (
      _.isEmpty(assessment.questions) &&
      !assessment.isCompleted &&
      assessmentId
    ) {
      dispatch(fetchConversationalAssessment(assessmentId));
    }
  }, [dispatch, assessment.questions, assessmentId, assessment.isCompleted]);

  useEffect(() => {
    if (!assessment.hasError && !assessment.isFetching) {
      dispatch(fetchProgressId(assessmentId));
    }
  }, [assessment.hasError, assessment.isFetching, assessmentId, dispatch]);

  const minimizeLiveChatWindow = useCallback(() => {
    analytics.track('assessment', 'minimized live chat');
    dispatch(minimizeChatWindow());
  }, [dispatch]);

  return (
    featureService._hasIntelliPlatformFeature() && (
      <div className={container}>
        <LiveChat
          license={config.liveChatLicenseId}
          onChatWindowHidden={minimizeLiveChatWindow}
        />
        <QuestionList
          assessmentId={assessmentId}
          greetingMessages={greetingMessages}
          questionList={assessment.isFetching ? {} : assessment.questions}
        />
      </div>
    )
  );
};
export default withAuth(wrapWithApolloClient(ConversationalAssessment));
