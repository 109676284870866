/**
 * Push Notification Token Action that sets the active token
 */

import { SET_PUSH_NOTIFICATION_DATA } from '../types';

export default ({ token, userId, updatedOn }) => ({
  type: SET_PUSH_NOTIFICATION_DATA,
  token,
  userId,
  updatedOn
});
