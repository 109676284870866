import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Alert, Col, Row } from 'antd';
import validation from 'utils/validation/validation';
import { TextAreaBlack } from '../../../../../components/ui/Inputs';
import {
  ActionButton,
  UnderlineButton
} from '../../../../../components/ui/Buttons';
import Lang from '../../../../../components/Lang';

import styles from './InPersonTherapy.module.scss';

import DateTimeField from '../../../../../components/form';

const {
  formField,
  nextButtonStyle,
  nextButtonContainer,
  previousButtonStyle,
  notesContainer,
  requestError
} = styles;

const InPersonTherapyFormSecondPage = ({
  handleSubmit,
  onPrevious,
  isFetching,
  hasError
}) => (
  <form onSubmit={handleSubmit}>
    <h3>
      <Lang path="therapyRequest.form.secondPageHeader" />
    </h3>
    <Row>
      <Col xs={{ span: 24 }} lg={{ span: 11, offset: 0 }}>
        <Field
          id="appointment-time-1"
          className={formField}
          name="date1"
          component={DateTimeField}
          label={
            <span>
              <Lang
                path="formCommon.appointmentSlotText"
                values={{ order: 1 }}
              />
              *
            </span>
          }
          validate={validation.required_day1}
          invalidDateMessage={null}
          disablePast
        />
      </Col>
      <Col xs={{ span: 24 }} lg={{ offset: 2, span: 11 }}>
        <Field
          className={formField}
          name="date2"
          component={DateTimeField}
          label={
            <Lang path="formCommon.appointmentSlotText" values={{ order: 2 }} />
          }
          invalidDateMessage={null}
          disablePast
        />
      </Col>
    </Row>
    <Row>
      <Col xs={{ span: 24 }} lg={{ span: 11, offset: 0 }}>
        <Field
          className={formField}
          name="date3"
          component={DateTimeField}
          label={
            <Lang path="formCommon.appointmentSlotText" values={{ order: 3 }} />
          }
          invalidDateMessage={null}
          disablePast
        />
      </Col>
      <Col xs={{ span: 24 }} lg={{ offset: 2, span: 11 }}>
        <Field
          className={formField}
          name="date4"
          component={DateTimeField}
          label={
            <Lang path="formCommon.appointmentSlotText" values={{ order: 4 }} />
          }
          invalidDateMessage={null}
          disablePast
        />
      </Col>
    </Row>
    <Row className={notesContainer}>
      <Col xs={24}>
        <Field
          id="request-notes"
          component={TextAreaBlack}
          label={<Lang path="therapyRequest.form.requestNotesText" />}
          name="notes"
          multiline
          rows="4"
          className={formField}
        />
      </Col>
    </Row>
    {hasError && (
      <Alert
        className={requestError}
        message={<Lang path="therapyRequest.errorMessage" />}
        showIcon
        type="error"
      />
    )}
    <Row>
      <Col className={nextButtonContainer}>
        <UnderlineButton className={previousButtonStyle} onClick={onPrevious}>
          <Lang path="backButtonText" />
        </UnderlineButton>
        <ActionButton
          testId="request-appointment-button"
          className={nextButtonStyle}
          onClick={handleSubmit}
          isLoading={isFetching}
        >
          <Lang path="therapyRequest.form.submitButton" />
        </ActionButton>
      </Col>
    </Row>
  </form>
);

InPersonTherapyFormSecondPage.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'inPersonTherapyRequest',
  destroyOnUnmount: false
})(InPersonTherapyFormSecondPage);
