import React from 'react';
import PropTypes from 'prop-types';
import style from './ResultCard.module.scss';

const { container, body, bodyContent, actionButton } = style;

const ResultCard = (props) => {
  const { title, description, buttonText, onClickHandler } = props;
  return (
    <div className={container}>
      <div className={body}>
        <div className={bodyContent}>
          {title && <h1 data-testid="result-card-title">{title}</h1>}
          {description && (
            <p data-testid="result-card-description">{description}</p>
          )}
        </div>
        <div />
      </div>
      <button
        type="button"
        onClick={onClickHandler}
        className={actionButton}
        data-testid="result-card-button"
      >
        {buttonText}
      </button>
    </div>
  );
};

ResultCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClickHandler: PropTypes.func
};

ResultCard.defaultProps = {
  title: '',
  description: '',
  buttonText: '',
  onClickHandler: () => {}
};

export default ResultCard;
