import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
// utils
import helpers from 'utils/helpers';
import logger from 'utils/logger';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
//  styles
import styles from './VideoScreen.module.scss';
// Components
import Transcript from '../Transcript';
import VideoPlayer from './VideoPlayer/VideoPlayer';
import VideoScreenOverlay from './VideoScreenOverlay/VideoScreenOverlay';
import getVideoFullScreenDetails from './videoScreen.utils';
import {
  lockScreenOrientation,
  unlockScreenOrientation
} from './lockOrientation';

const { container, fullScreenContent } = styles;

const VideoScreen = ({
  screenData,
  isBreathingExercise = false,
  previousProgress = null,
  currentProgress,
  setCurrentProgress,
  upsertSessionProgressHelper = () => {},
  renderError,
  autoplay = false,
  setScreenProgress
}) => {
  const { goalId, sessionId, screenId } = useParams();
  const { description = '', subheader = '' } = screenData;
  const videoId = _.get(screenData, 'video.videoId', '');
  const videoDuration = _.get(screenData, 'video.duration', 0);
  const videoUrl = _.get(screenData, 'video.urlMp4', '');
  const audioId = _.get(screenData, 'audio.audioId', '');
  const audioDuration = _.get(screenData, 'audio.duration', 0);
  const audioUrl = _.get(screenData, 'audio.audioUrl', '');
  const transcriptObject = _.get(screenData, 'transcript', {});
  const [isTranscriptVisible, setIsTranscriptVisible] = useState(false);
  const [hasTranscript, setHasTranscript] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoEnded, setIsVideoEnded] = useState(false);

  const playerContainerRef = useRef(null);
  const currentVideo = document.querySelector('video');
  const currentAudio = document.querySelector('audio');

  const { isFullScreen } = useSelector((state) => _.get(state, 'videoMode'));
  const { requestFullScreen, exitFullScreen } = getVideoFullScreenDetails();

  useEffect(() => {
    if (setScreenProgress) {
      setScreenProgress({
        currentDuration: 0,
        id: isBreathingExercise ? audioId : videoId
      });
    }
  }, [setScreenProgress, audioId, videoId, isBreathingExercise]);

  // Populating the previous progress made on video
  useEffect(() => {
    if (previousProgress !== null && currentProgress === null) {
      setIsPlaying(false);
      setCurrentProgress(previousProgress);
      setScreenProgress({
        currentDuration: parseInt(previousProgress, 10),
        id: isBreathingExercise ? audioId : videoId
      });
    }
  }, [
    isPlaying,
    currentProgress,
    previousProgress,
    setCurrentProgress,
    setScreenProgress,
    audioId,
    isBreathingExercise,
    videoId
  ]);

  const handleAppInBackground = useCallback(() => {
    if (!helpers.platformInfo.isDesktop && !isBreathingExercise) {
      setIsPlaying(false);
      if (currentVideo) {
        currentVideo.pause();
      }
    }
  }, [currentVideo, isBreathingExercise]);

  const handleAppOnFocus = useCallback(() => {
    if (currentAudio && isBreathingExercise) {
      if (currentAudio.paused) {
        currentVideo.pause();
        setIsPlaying(false);
      } else {
        currentVideo.play();
        setIsPlaying(true);
      }
    }
  }, [currentAudio, currentVideo, isBreathingExercise]);
  // EventListener doesnot work with dependencies for ios
  useEffect(() => {
    // EventListener for browser and IOS app
    window.addEventListener('blur', handleAppInBackground);
    // EventListener for Android app
    document.addEventListener('pause', handleAppInBackground);
    window.addEventListener('focus', handleAppOnFocus);
    return () => {
      window.removeEventListener('blur', handleAppInBackground);
      document.removeEventListener('pause', handleAppInBackground);
      window.removeEventListener('focus', handleAppOnFocus);
    };
  });

  useEffect(() => {
    // Added setTimeout to support the autoplay functionality in safari browser
    setTimeout(() => {
      setIsPlaying(autoplay);
    }, 0);
  }, [screenId, sessionId, autoplay]);

  // Error handling for transcript screen
  useEffect(() => {
    if (isBreathingExercise) {
      setHasTranscript(false);
    } else if (_.isEmpty(transcriptObject)) {
      logger.error('Found empty transcript for session', 'Session.useEffect', {
        goalId,
        sessionId,
        screenId
      });
    } else {
      setHasTranscript(true);
    }
  }, [goalId, isBreathingExercise, screenId, sessionId, transcriptObject]);

  // Full screen change event handler when user clicks on button
  const handleFullScreenChange = useCallback(
    (enableFullScreen) => {
      let msg = '';
      if (playerContainerRef && playerContainerRef.current) {
        if (enableFullScreen) {
          if (requestFullScreen) {
            msg = 'clicked go to full screen button';
            requestFullScreen(playerContainerRef.current);
            lockScreenOrientation('landscape');
          }
        } else if (exitFullScreen) {
          // To unlock orientation which will default back to the global setting
          msg = 'clicked exit full screen button';

          unlockScreenOrientation();

          exitFullScreen(playerContainerRef.current);
        }
      }
      analytics.track(
        isBreathingExercise ? eventCategories.TOOLS : eventCategories.SESSION,
        msg,
        {
          subcategory: isBreathingExercise ? 'breathing exercise' : 'session',
          [isBreathingExercise ? 'audioId' : 'videoId']: isBreathingExercise
            ? audioId
            : videoId,
          timePlayed: currentProgress
        }
      );
    },
    [
      audioId,
      currentProgress,
      exitFullScreen,
      isBreathingExercise,
      requestFullScreen,
      videoId
    ]
  );
  // Switch to transcript button click handler
  const handleToggleButtonClick = useCallback(() => {
    let type = 'video';
    if (!isTranscriptVisible) {
      type = 'transcript';
    }
    if (isFullScreen) {
      handleFullScreenChange(false);
    }
    setIsTranscriptVisible(!isTranscriptVisible);
    analytics.track(
      eventCategories.SESSION,
      `Clicked on switch to ${type} button`,
      {
        subcategory: 'session',
        sessionId
      }
    );
  }, [isTranscriptVisible, isFullScreen, sessionId, handleFullScreenChange]);

  // Error handling for missing data
  if (
    _.isEmpty(screenData) ||
    (!isBreathingExercise &&
      (_.isEmpty(screenData.video) || _.isEmpty(videoUrl))) ||
    (isBreathingExercise &&
      (_.isEmpty(screenData.audio) || _.isEmpty(audioUrl)))
  ) {
    logger.error('Found empty screen data', 'Player', {
      goalId,
      sessionId,
      screenId,
      isBreathingExercise,
      videoUrl,
      audioUrl
    });
    return renderError('selfUse.session.error.screenErrorMessage');
  }

  return (
    <div className={container}>
      {hasTranscript && isTranscriptVisible ? (
        <Transcript
          screenData={screenData}
          toggleToVideoScreen={handleToggleButtonClick}
        />
      ) : (
        <div
          className={container}
          data-testid={
            isBreathingExercise
              ? 'breathing-exercise-container'
              : 'player-container'
          }
          ref={playerContainerRef}
        >
          <VideoPlayer
            isBreathingExercise={isBreathingExercise}
            videoData={screenData.video}
            audioData={screenData.audio}
            previousProgress={previousProgress}
            currentProgress={currentProgress}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            setCurrentProgress={setCurrentProgress}
            handleFullScreenChange={handleFullScreenChange}
            upsertSessionProgressHelper={upsertSessionProgressHelper}
            setScreenProgress={setScreenProgress}
            isVideoEnded={isVideoEnded}
            setIsVideoEnded={setIsVideoEnded}
          />
          {!isPlaying && (
            <div className={`${isFullScreen ? fullScreenContent : ''}`}>
              <VideoScreenOverlay
                subheader={subheader}
                duration={isBreathingExercise ? audioDuration : videoDuration}
                description={description}
                hasTranscript={hasTranscript}
                handleToggleButtonClick={handleToggleButtonClick}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VideoScreen;
