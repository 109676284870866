/**
 * Input field in custom style to make it look Oval
 */

import React from 'react';
import { Input, FormControl } from '@material-ui/core';
import { InputError, InputSuccess } from '../../Validation';

import inputStyles from './OvalTextField.styles';

const OvalTextField = ({ className, input, errorMsg, successMsg, ...rest }) => {
  const inputClasses = inputStyles();
  return (
    <FormControl className={className || null}>
      <Input
        classes={{
          root: inputClasses.root,
          input: inputClasses.input
        }}
        error={!!errorMsg}
        disableUnderline
        inputProps={rest}
        {...input}
      />
      {errorMsg && !successMsg && <InputError error={errorMsg} />}
      {successMsg && <InputSuccess message={successMsg} />}
    </FormControl>
  );
};

export default OvalTextField;
