/**
 *
 * Coach Assignment Loader
 *
 */

import React from 'react';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CoachIcon from 'assets/img/dashboard/coach.png';
import { Ellipsis } from '../../ui/Layout';

// styles
import style from './AutoAssignmentLoader.module.scss';
import Lang from '../../Lang';

const {
  coachRequestContainer,
  coachRequestTopPanel,
  confirmationIcon,
  checkCircleIcon,
  coachRequestText,
  coachIcon,
  coachRequested,
  coachRequestModalContainer
} = style;

function AutoAssignmentLoader({ isModal }) {
  return (
    <div
      className={isModal ? coachRequestModalContainer : coachRequestContainer}
    >
      <div className={coachRequestTopPanel}>
        <div className={confirmationIcon}>
          <CheckCircleOutlineRoundedIcon className={checkCircleIcon} />
        </div>
        <p className={coachRequestText}>
          <Lang path="coachRequestCard.autoCoach.header" />
        </p>
      </div>
      <img className={coachIcon} alt="" src={CoachIcon} />
      <Ellipsis />
      <div className={coachRequested}>
        <Lang path="coachRequestCard.autoCoach.body" />
      </div>
    </div>
  );
}

export default AutoAssignmentLoader;
