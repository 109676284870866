// Icon map with fallback for fontawesome breaking changes in v5
// https://fontawesome.com/how-to-use/on-the-web/setup/upgrading-from-version-4#name-changes

export default {
  '500px': '500px',
  'address-book-o': 'address-book',
  'address-card-o': 'address-card',
  adn: 'adn',
  amazon: 'amazon',
  android: 'android',
  angellist: 'angellist',
  apple: 'apple',
  'area-chart': 'chart-area',
  'arrow-circle-o-down': 'arrow-alt-circle-down',
  'arrow-circle-o-left': 'arrow-alt-circle-left',
  'arrow-circle-o-right': 'arrow-alt-circle-right',
  'arrow-circle-o-up': 'arrow-alt-circle-up',
  arrows: 'arrows-alt',
  'arrows-alt': 'expand-arrows-alt',
  'arrows-h': 'arrows-alt-h',
  'arrows-v': 'arrows-alt-v',
  'asl-interpreting': 'american-sign-language-interpreting',
  automobile: 'car',
  bandcamp: 'bandcamp',
  bank: 'university',
  'bar-chart': 'chart-bar',
  'bar-chart-o': 'chart-bar',
  bathtub: 'bath',
  battery: 'battery-full',
  'battery-0': 'battery-empty',
  'battery-1': 'battery-quarter',
  'battery-2': 'battery-half',
  'battery-3': 'battery-three-quarters',
  'battery-4': 'battery-full',
  behance: 'behance',
  'behance-square': 'behance-square',
  'bell-o': 'bell',
  'bell-slash-o': 'bell-slash',
  bitbucket: 'bitbucket',
  'bitbucket-square': 'bitbucket',
  bitcoin: 'btc',
  'black-tie': 'black-tie',
  bluetooth: 'bluetooth',
  'bluetooth-b': 'bluetooth-b',
  'bookmark-o': 'bookmark',
  btc: 'btc',
  'building-o': 'building',
  buysellads: 'buysellads',
  cab: 'taxi',
  calendar: 'calendar-alt',
  'calendar-check-o': 'calendar-check',
  'calendar-minus-o': 'calendar-minus',
  'calendar-o': 'calendar',
  'calendar-plus-o': 'calendar-plus',
  'calendar-times-o': 'calendar-times',
  'caret-square-o-down': 'caret-square-down',
  'caret-square-o-left': 'caret-square-left',
  'caret-square-o-right': 'caret-square-right',
  'caret-square-o-up': 'caret-square-up',
  cc: 'closed-captioning',
  'cc-amex': 'cc-amex',
  'cc-diners-club': 'cc-diners-club',
  'cc-discover': 'cc-discover',
  'cc-jcb': 'cc-jcb',
  'cc-mastercard': 'cc-mastercard',
  'cc-paypal': 'cc-paypal',
  'cc-stripe': 'cc-stripe',
  'cc-visa': 'cc-visa',
  chain: 'link',
  'chain-broken': 'unlink',
  'check-circle-o': 'check-circle',
  'check-square-o': 'check-square',
  chrome: 'chrome',
  'circle-o': 'circle',
  'circle-o-notch': 'circle-notch',
  'circle-thin': 'circle',
  clipboard: 'clipboard',
  'clock-o': 'clock',
  clone: 'clone',
  close: 'times',
  'cloud-download': 'cloud-download-alt',
  'cloud-upload': 'cloud-upload-alt',
  cny: 'yen-sign',
  'code-fork': 'code-branch',
  codepen: 'codepen',
  codiepie: 'codiepie',
  'comment-o': 'comment',
  commenting: 'comment-dots',
  'commenting-o': 'comment-dots',
  'comments-o': 'comments',
  compass: 'compass',
  connectdevelop: 'connectdevelop',
  contao: 'contao',
  copyright: 'copyright',
  'creative-commons': 'creative-commons',
  'credit-card': 'credit-card',
  'credit-card-alt': 'credit-card',
  css3: 'css3',
  cutlery: 'utensils',
  dashboard: 'tachometer-alt',
  dashcube: 'dashcube',
  deafness: 'deaf',
  dedent: 'outdent',
  delicious: 'delicious',
  deviantart: 'deviantart',
  diamond: 'gem',
  digg: 'digg',
  dollar: 'dollar-sign',
  'dot-circle-o': 'dot-circle',
  dribbble: 'dribbble',
  'drivers-license': 'id-card',
  'drivers-license-o': 'id-card',
  dropbox: 'dropbox',
  drupal: 'drupal',
  edge: 'edge',
  eercast: 'sellcast',
  empire: 'empire',
  'envelope-o': 'envelope',
  'envelope-open-o': 'envelope-open',
  envira: 'envira',
  etsy: 'etsy',
  eur: 'euro-sign',
  euro: 'euro-sign',
  exchange: 'exchange-alt',
  expeditedssl: 'expeditedssl',
  'external-link': 'external-link-alt',
  'external-link-square': 'external-link-square-alt',
  eye: 'eye',
  'eye-slash': 'eye-slash',
  eyedropper: 'eye-dropper',
  fa: 'font-awesome',
  facebook: 'facebook-f',
  'facebook-f': 'facebook-f',
  'facebook-official': 'facebook',
  'facebook-square': 'facebook-square',
  feed: 'rss',
  'file-archive-o': 'file-archive',
  'file-audio-o': 'file-audio',
  'file-code-o': 'file-code',
  'file-excel-o': 'file-excel',
  'file-image-o': 'file-image',
  'file-movie-o': 'file-video',
  'file-o': 'file',
  'file-pdf-o': 'file-pdf',
  'file-photo-o': 'file-image',
  'file-picture-o': 'file-image',
  'file-powerpoint-o': 'file-powerpoint',
  'file-sound-o': 'file-audio',
  'file-text': 'file-alt',
  'file-text-o': 'file-alt',
  'file-video-o': 'file-video',
  'file-word-o': 'file-word',
  'file-zip-o': 'file-archive',
  'files-o': 'copy',
  firefox: 'firefox',
  'first-order': 'first-order',
  'flag-o': 'flag',
  flash: 'bolt',
  flickr: 'flickr',
  'floppy-o': 'save',
  'folder-o': 'folder',
  'folder-open-o': 'folder-open',
  'font-awesome': 'font-awesome',
  fonticons: 'fonticons',
  'fort-awesome': 'fort-awesome',
  forumbee: 'forumbee',
  foursquare: 'foursquare',
  'free-code-camp': 'free-code-camp',
  'frown-o': 'frown',
  'futbol-o': 'futbol',
  gbp: 'pound-sign',
  ge: 'empire',
  gear: 'cog',
  gears: 'cogs',
  'get-pocket': 'get-pocket',
  gg: 'gg',
  'gg-circle': 'gg-circle',
  git: 'git',
  'git-square': 'git-square',
  github: 'github',
  'github-alt': 'github-alt',
  'github-square': 'github-square',
  gitlab: 'gitlab',
  gittip: 'gratipay',
  glass: 'glass-martini',
  glide: 'glide',
  'glide-g': 'glide-g',
  google: 'google',
  'google-plus': 'google-plus-g',
  'google-plus-circle': 'google-plus',
  'google-plus-official': 'google-plus',
  'google-plus-square': 'google-plus-square',
  'google-wallet': 'google-wallet',
  gratipay: 'gratipay',
  grav: 'grav',
  group: 'users',
  'hacker-news': 'hacker-news',
  'hand-grab-o': 'hand-rock',
  'hand-lizard-o': 'hand-lizard',
  'hand-o-down': 'hand-point-down',
  'hand-o-left': 'hand-point-left',
  'hand-o-right': 'hand-point-right',
  'hand-o-up': 'hand-point-up',
  'hand-paper-o': 'hand-paper',
  'hand-peace-o': 'hand-peace',
  'hand-pointer-o': 'hand-pointer',
  'hand-rock-o': 'hand-rock',
  'hand-scissors-o': 'hand-scissors',
  'hand-spock-o': 'hand-spock',
  'hand-stop-o': 'hand-paper',
  'handshake-o': 'handshake',
  'hard-of-hearing': 'deaf',
  'hdd-o': 'hdd',
  header: 'heading',
  'heart-o': 'heart',
  'hospital-o': 'hospital',
  hotel: 'bed',
  'hourglass-1': 'hourglass-start',
  'hourglass-2': 'hourglass-half',
  'hourglass-3': 'hourglass-end',
  'hourglass-o': 'hourglass',
  houzz: 'houzz',
  html5: 'html5',
  'id-badge': 'id-badge',
  'id-card-o': 'id-card',
  ils: 'shekel-sign',
  image: 'image',
  imdb: 'imdb',
  inr: 'rupee-sign',
  instagram: 'instagram',
  institution: 'university',
  'internet-explorer': 'internet-explorer',
  intersex: 'transgender',
  ioxhost: 'ioxhost',
  joomla: 'joomla',
  jpy: 'yen-sign',
  jsfiddle: 'jsfiddle',
  'keyboard-o': 'keyboard',
  krw: 'won-sign',
  lastfm: 'lastfm',
  'lastfm-square': 'lastfm-square',
  leanpub: 'leanpub',
  legal: 'gavel',
  'lemon-o': 'lemon',
  'level-down': 'level-down-alt',
  'level-up': 'level-up-alt',
  'life-bouy': 'life-ring',
  'life-buoy': 'life-ring',
  'life-ring': 'life-ring',
  'life-saver': 'life-ring',
  'lightbulb-o': 'lightbulb',
  'line-chart': 'chart-line',
  linkedin: 'linkedin-in',
  'linkedin-square': 'linkedin',
  linode: 'linode',
  linux: 'linux',
  'list-alt': 'list-alt',
  'long-arrow-down': 'long-arrow-alt-down',
  'long-arrow-left': 'long-arrow-alt-left',
  'long-arrow-right': 'long-arrow-alt-right',
  'long-arrow-up': 'long-arrow-alt-up',
  'mail-forward': 'share',
  'mail-reply': 'reply',
  'mail-reply-all': 'reply-all',
  'map-marker': 'map-marker-alt',
  'map-o': 'map',
  maxcdn: 'maxcdn',
  meanpath: 'font-awesome',
  medium: 'medium',
  meetup: 'meetup',
  'meh-o': 'meh',
  'minus-square-o': 'minus-square',
  mixcloud: 'mixcloud',
  mobile: 'mobile-alt',
  'mobile-phone': 'mobile-alt',
  modx: 'modx',
  money: 'money-bill-alt',
  'moon-o': 'moon',
  'mortar-board': 'graduation-cap',
  navicon: 'bars',
  'newspaper-o': 'newspaper',
  'object-group': 'object-group',
  'object-ungroup': 'object-ungroup',
  odnoklassniki: 'odnoklassniki',
  'odnoklassniki-square': 'odnoklassniki-square',
  opencart: 'opencart',
  openid: 'openid',
  opera: 'opera',
  'optin-monster': 'optin-monster',
  pagelines: 'pagelines',
  'paper-plane-o': 'paper-plane',
  paste: 'clipboard',
  'pause-circle-o': 'pause-circle',
  paypal: 'paypal',
  pencil: 'pencil-alt',
  'pencil-square': 'pen-square',
  'pencil-square-o': 'edit',
  photo: 'image',
  'picture-o': 'image',
  'pie-chart': 'chart-pie',
  'pied-piper': 'pied-piper',
  'pied-piper-alt': 'pied-piper-alt',
  'pied-piper-pp': 'pied-piper-pp',
  pinterest: 'pinterest',
  'pinterest-p': 'pinterest-p',
  'pinterest-square': 'pinterest-square',
  'play-circle-o': 'play-circle',
  'plus-square-o': 'plus-square',
  'product-hunt': 'product-hunt',
  qq: 'qq',
  'question-circle-o': 'question-circle',
  quora: 'quora',
  ra: 'rebel',
  ravelry: 'ravelry',
  rebel: 'rebel',
  reddit: 'reddit',
  'reddit-alien': 'reddit-alien',
  'reddit-square': 'reddit-square',
  refresh: 'sync',
  registered: 'registered',
  remove: 'times',
  renren: 'renren',
  reorder: 'bars',
  repeat: 'redo',
  resistance: 'rebel',
  rmb: 'yen-sign',
  'rotate-left': 'undo',
  'rotate-right': 'redo',
  rouble: 'ruble-sign',
  rub: 'ruble-sign',
  ruble: 'ruble-sign',
  rupee: 'rupee-sign',
  s15: 'bath',
  safari: 'safari',
  scissors: 'cut',
  scribd: 'scribd',
  sellsy: 'sellsy',
  send: 'paper-plane',
  'send-o': 'paper-plane',
  'share-square-o': 'share-square',
  shekel: 'shekel-sign',
  sheqel: 'shekel-sign',
  shield: 'shield-alt',
  shirtsinbulk: 'shirtsinbulk',
  'sign-in': 'sign-in-alt',
  'sign-out': 'sign-out-alt',
  signing: 'sign-language',
  simplybuilt: 'simplybuilt',
  skyatlas: 'skyatlas',
  skype: 'skype',
  slack: 'slack',
  sliders: 'sliders-h',
  slideshare: 'slideshare',
  'smile-o': 'smile',
  snapchat: 'snapchat',
  'snapchat-ghost': 'snapchat-ghost',
  'snapchat-square': 'snapchat-square',
  'snowflake-o': 'snowflake',
  'soccer-ball-o': 'futbol',
  'sort-alpha-asc': 'sort-alpha-down',
  'sort-alpha-desc': 'sort-alpha-up',
  'sort-amount-asc': 'sort-amount-down',
  'sort-amount-desc': 'sort-amount-up',
  'sort-asc': 'sort-up',
  'sort-desc': 'sort-down',
  'sort-numeric-asc': 'sort-numeric-down',
  'sort-numeric-desc': 'sort-numeric-up',
  soundcloud: 'soundcloud',
  spoon: 'utensil-spoon',
  spotify: 'spotify',
  'square-o': 'square',
  'stack-exchange': 'stack-exchange',
  'stack-overflow': 'stack-overflow',
  'star-half-empty': 'star-half',
  'star-half-full': 'star-half',
  'star-half-o': 'star-half',
  'star-o': 'star',
  steam: 'steam',
  'steam-square': 'steam-square',
  'sticky-note-o': 'sticky-note',
  'stop-circle-o': 'stop-circle',
  stumbleupon: 'stumbleupon',
  'stumbleupon-circle': 'stumbleupon-circle',
  'sun-o': 'sun',
  superpowers: 'superpowers',
  support: 'life-ring',
  tablet: 'tablet-alt',
  tachometer: 'tachometer-alt',
  telegram: 'telegram',
  television: 'tv',
  'tencent-weibo': 'tencent-weibo',
  themeisle: 'themeisle',
  thermometer: 'thermometer-full',
  'thermometer-0': 'thermometer-empty',
  'thermometer-1': 'thermometer-quarter',
  'thermometer-2': 'thermometer-half',
  'thermometer-3': 'thermometer-three-quarters',
  'thermometer-4': 'thermometer-full',
  'thumb-tack': 'thumbtack',
  'thumbs-o-down': 'thumbs-down',
  'thumbs-o-up': 'thumbs-up',
  ticket: 'ticket-alt',
  'times-circle-o': 'times-circle',
  'times-rectangle': 'window-close',
  'times-rectangle-o': 'window-close',
  'toggle-down': 'caret-square-down',
  'toggle-left': 'caret-square-left',
  'toggle-right': 'caret-square-right',
  'toggle-up': 'caret-square-up',
  trash: 'trash-alt',
  'trash-o': 'trash-alt',
  trello: 'trello',
  tripadvisor: 'tripadvisor',
  try: 'lira-sign',
  tumblr: 'tumblr',
  'tumblr-square': 'tumblr-square',
  'turkish-lira': 'lira-sign',
  twitch: 'twitch',
  twitter: 'twitter',
  'twitter-square': 'twitter-square',
  unsorted: 'sort',
  usb: 'usb',
  usd: 'dollar-sign',
  'user-circle-o': 'user-circle',
  'user-o': 'user',
  vcard: 'address-card',
  'vcard-o': 'address-card',
  viacoin: 'viacoin',
  viadeo: 'viadeo',
  'viadeo-square': 'viadeo-square',
  'video-camera': 'video',
  vimeo: 'vimeo-v',
  'vimeo-square': 'vimeo-square',
  vine: 'vine',
  vk: 'vk',
  'volume-control-phone': 'phone-volume',
  warning: 'exclamation-triangle',
  wechat: 'weixin',
  weibo: 'weibo',
  weixin: 'weixin',
  whatsapp: 'whatsapp',
  'wheelchair-alt': 'accessible-icon',
  'wikipedia-w': 'wikipedia-w',
  'window-close-o': 'window-close',
  'window-maximize': 'window-maximize',
  'window-restore': 'window-restore',
  windows: 'windows',
  won: 'won-sign',
  wordpress: 'wordpress',
  wpbeginner: 'wpbeginner',
  wpexplorer: 'wpexplorer',
  wpforms: 'wpforms',
  xing: 'xing',
  'xing-square': 'xing-square',
  'y-combinator': 'y-combinator',
  'y-combinator-square': 'hacker-news',
  yahoo: 'yahoo',
  yc: 'y-combinator',
  'yc-square': 'hacker-news',
  yelp: 'yelp',
  yen: 'yen-sign',
  yoast: 'yoast',
  youtube: 'youtube',
  'youtube-play': 'youtube',
  'youtube-square': 'youtube-square'
};
