import { makeStyles } from '@material-ui/core';
import {
  brandColorForegroundPrimary,
  fontWeightSemibold
} from 'styles/config.scss';

export const inputStyles = makeStyles({
  root: {
    color: brandColorForegroundPrimary,
    fontWeight: fontWeightSemibold
  },
  errorclass: {
    width: '100%',
    '& svg': {
      right: '0',
      bottom: '25px',
      background: '#fff'
    }
  },
  underline: {
    color: brandColorForegroundPrimary,
    fontWeight: fontWeightSemibold,
    '&:before': {
      borderBottom: '1px solid #898b8e !important'
    },
    '&:after': {
      borderBottom: '2px solid #898b8e'
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: '2px solid #b7b8ba'
    },
    '&:hover:not($disabled):before': {
      borderBottom: '2px solid #b7b8ba',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        borderBottom: '2px solid #b7b8ba'
      }
    }
  },
  menuItem: {
    whiteSpace: 'unset'
  }
});

export const labelStyles = makeStyles({
  root: {
    color: brandColorForegroundPrimary,
    whiteSpace: 'nowrap',
    '&$focused': {
      // increase the specificity for the pseudo class
      fontWeight: fontWeightSemibold,
      color: '#b7b8ba'
    }
  },
  focused: {
    color: '#b7b8ba'
  }
});
