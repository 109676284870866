/**
 * Question Action that sets the currently active Question
 * TODO: merge this into Assessment Action in next PR since it's related to that
 */

import { SET_ACTIVE_QUESTION } from './types';

export default (questionId) => ({
  type: SET_ACTIVE_QUESTION,
  questionId
});
