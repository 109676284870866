import React from 'react';
import isEmpty from 'lodash/isEmpty';

import OptionsGroup from '../OptionsGroup/OptionsGroup';

import styles from './Options.module.scss';

const { container } = styles;

const Options = ({ options, optionsSelected, setOptionsSelected }) =>
  !isEmpty(options) ? (
    <div className={container}>
      {options.map((optionsObject) => {
        const { name, vals } = optionsObject;
        return (
          <OptionsGroup
            key={name}
            optionsSelected={optionsSelected}
            setOptionsSelected={setOptionsSelected}
            name={name}
            vals={vals}
          />
        );
      })}
    </div>
  ) : null;

export default Options;
