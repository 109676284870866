import api from '../../../api/request';

import {
  REQUEST_IN_PERSON_THERAPY,
  REQUEST_IN_PERSON_THERAPY_DONE,
  REQUEST_IN_PERSON_THERAPY_ERROR,
  GET_IN_PERSON_THERAPY_STATUS,
  GET_IN_PERSON_THERAPY_STATUS_DONE,
  GET_IN_PERSON_THERAPY_STATUS_ERROR,
  CLEAR_IN_PERSON_THERAPY_STATUS_ERROR
} from '../types';

import logger from '../../../utils/logger';
import handleUnexpectedError from '../../helper';

const inPersonTherapyRequestStart = () => ({ type: REQUEST_IN_PERSON_THERAPY });

const inPersonTherapyRequestDone = () => ({
  type: REQUEST_IN_PERSON_THERAPY_DONE
});

const inPersonTherapyRequestError = () => ({
  type: REQUEST_IN_PERSON_THERAPY_ERROR
});

const getInPersonTherapyStatusStart = () => ({
  type: GET_IN_PERSON_THERAPY_STATUS
});

const getInPersonTherapyStatusDone = (status) => ({
  type: GET_IN_PERSON_THERAPY_STATUS_DONE,
  status
});

const getInPersonTherapyStatusError = () => ({
  type: GET_IN_PERSON_THERAPY_STATUS_ERROR
});

export const clearInPersonTherapyStatusError = () => ({
  type: CLEAR_IN_PERSON_THERAPY_STATUS_ERROR
});

export const getInPersonTherapyStatus = () => (dispatch) => {
  dispatch(getInPersonTherapyStatusStart());
  api
    .getInPersonTherapyStatus()
    .then((resp) => {
      if (resp.status === 200) {
        if (resp.data.status === true) {
          dispatch(getInPersonTherapyStatusDone(true));
        } else if (resp.data.status === false) {
          dispatch(getInPersonTherapyStatusDone(false));
        } else {
          logger.error(
            'Unknown value for inPersonTherapy status',
            'InPersonTherapyRequestAction.getInPersonTherapyStatus',
            { resp }
          );
          dispatch(getInPersonTherapyStatusError());
        }
      } else {
        logger.error(
          'An error occurred while requesting the inPerson therapy status',
          'InPersonTherapyRequestAction.getInPersonTherapyStatus',
          { resp }
        );
        dispatch(getInPersonTherapyStatusError());
      }
    })
    .catch((err) => {
      handleUnexpectedError(
        'An unexpected error occurred while requesting the inPerson therapy status',
        'InPersonTherapyRequestAction.getInPersonTherapyStatus',
        err
      );
      dispatch(getInPersonTherapyStatusError());
    });
};

export default (params) => (dispatch) => {
  dispatch(inPersonTherapyRequestStart());
  api
    .requestInPersonTherapy(params)
    .then((resp) => {
      if (resp.status === 200) {
        dispatch(inPersonTherapyRequestDone());
      } else {
        logger.error(
          'An error occurred while requesting the inPerson therapy',
          'InPersonTherapyRequestAction.default',
          { resp }
        );
        dispatch(inPersonTherapyRequestError());
      }
    })
    .catch((err) => {
      handleUnexpectedError(
        'An unexpected error occurred while requesting the inPerson therapy',
        'InPersonTherapyRequestAction.default',
        err
      );
      dispatch(inPersonTherapyRequestError());
    });
};
