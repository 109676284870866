/**
 * Common App header used across app
 */

// Core
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import propTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// assets
import emergencyCallIcon from 'assets/icons/emergency-call.svg';
import menuIcon from 'assets/img/dashboard/menu.png';
// styles
// utils
import featureService from 'utils/feature-service';
import { isLoggedIn } from 'utils/auth';
import history from 'utils/history';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import GetHelpNow from 'containers/DashBoard/popups/GetHelpNow';
import { closeModal, showModal } from 'store/actions/ModalAction';
import helpers from 'utils/helpers';
import coachRequestStatusTypes from 'assets/data/dashboard/coachRequestStatusTypes';
import config from 'config';
// Components
import Lang from '../Lang';
import styles from './AppHeader.module.scss';

const { appName } = config;

const {
  container,
  logoSection,
  navTitle,
  menuIconStyle,
  sectIn,
  navList,
  logo,
  logoButton,
  buttonsSection,
  emergencyButtonStyle,
  emergencyIconStyle,
  logoutContainer,
  emergencyButtonContainer,
  active
} = styles;

const {
  getLastReportId,
  isActivePath,
  getCompanyLogo,
  isCoach,
  getActivePath
} = helpers;

const AppHeaderButton = ({
  activePath,
  testId,
  buttonHtml,
  checkCondition = true,
  onClick,
  style = null
}) =>
  checkCondition ? (
    <Button
      disableFocusRipple
      disableRipple
      className={`${
        style ||
        `${navTitle} ${activePath && isActivePath(activePath) ? active : ''}`
      }`}
      data-testid={testId}
      onClick={onClick}
    >
      {buttonHtml}
    </Button>
  ) : null;
const AppHeader = ({
  onClick,
  openSideBar,
  fixed,
  companyDetails,
  reports,
  actions,
  goalId,
  severity,
  eapPhone,
  coachRequestStatus,
  isFieldMissing
}) => {
  const hasCoachAccessOnWeb = featureService._hasCoachAccessOnWebFeature();
  const lastReportId = getLastReportId();
  const lastReportUrl = `/report/${lastReportId}`;
  const { t } = useTranslation();
  const { _hasResourcesFeature } = featureService;

  const _handleNavigation = (path, category, action) => {
    analytics.track(category, action);
    history.push(path);
  };

  const onClickHandler = () => {
    analytics.track(eventCategories.DASHBOARD, 'clicked wayforward icon');
    if (!isFieldMissing) {
      history.push('/');
    }
  };

  const _hasTakenAssessment = () => !_.isEmpty(reports);

  const _handleResourceTabVisibility = () =>
    _hasTakenAssessment() && _hasResourcesFeature();

  const _handleEmergencyClick = () => {
    analytics.track('navigation', 'clicked emergency', {
      goalId,
      severity
    });
    actions.showModal(GetHelpNow, {
      source: 'get-help-now',
      props: {
        eapPhone,
        goalId,
        severity
      }
    });
  };

  const _handleChatNavigation = () => {
    if (
      hasCoachAccessOnWeb &&
      coachRequestStatus === coachRequestStatusTypes.assigned
    ) {
      _handleNavigation('/coach', 'Chat', 'clicked on chat navigation');
    } else {
      _handleNavigation('/coach/chat', 'Chat', 'clicked on chat navigation');
    }
  };

  return (
    <div
      role="switch"
      aria-checked="false"
      tabIndex={0}
      onKeyUp={(e) => e.keyCode === 13}
      onClick={onClick}
      className={[container, navList].join(' ')}
      style={fixed ? { position: 'fixed' } : {}}
    >
      <div className={sectIn}>
        <div className={logoSection}>
          {isLoggedIn() && !isFieldMissing && (
            <button
              type="button"
              className={menuIconStyle}
              onClick={openSideBar}
              style={
                !openSideBar || !isLoggedIn() ? { visibility: 'hidden' } : {}
              }
            >
              <img src={menuIcon} alt={t('alternateText.button.menu')} />
            </button>
          )}
          <button
            type="button"
            data-testid="logo-button"
            className={logoButton}
            onClick={onClickHandler}
          >
            <img
              className={logo}
              src={getCompanyLogo(companyDetails)}
              alt={t('alternateText.image.logo', { appName })}
            />
          </button>
        </div>

        {isLoggedIn() && featureService._isProductActive() && !isFieldMissing && (
          <div className={buttonsSection}>
            <AppHeaderButton
              checkCondition={
                featureService._hasIntelliPlatformFeature() &&
                _hasTakenAssessment()
              }
              activePath="my-plan"
              testId="my-plan-btn"
              onClick={() =>
                _handleNavigation(
                  goalId && severity
                    ? `/my-plan/${goalId}/${severity}`
                    : '/my-plan',
                  eventCategories.DASHBOARD,
                  'Clicked on my-plan navigation'
                )
              }
              buttonHtml={<Lang path="navigation.myPlan" />}
            />
            <AppHeaderButton
              checkCondition={
                !featureService._hasIntelliPlatformFeature() && !!lastReportId
              }
              activePath="report"
              testId="goal-button"
              buttonHtml={<Lang path="navigation.goal" />}
              onClick={() =>
                _handleNavigation(
                  lastReportUrl,
                  'reports',
                  'Clicked on lastReport url navigation'
                )
              }
            />
            <AppHeaderButton
              checkCondition={
                (hasCoachAccessOnWeb &&
                  coachRequestStatus === coachRequestStatusTypes.assigned) ||
                (isCoach() && featureService._hasCoachChatOnWebFeature())
              }
              activePath="coach"
              testId="coach-btn"
              onClick={() => _handleChatNavigation()}
              buttonHtml={<Lang path="navigation.coach" />}
            />
            <AppHeaderButton
              checkCondition={
                _hasTakenAssessment() && featureService._hasSelfUseFeature()
              }
              activePath="self-use"
              className={`${navTitle} ${
                isActivePath('self-use') ? active : ''
              }`}
              testId="self-use-btn"
              onClick={() =>
                _handleNavigation(
                  '/self-use',
                  eventCategories.SELF_USE,
                  'Clicked on self-use navigation'
                )
              }
              buttonHtml={<Lang path="navigation.selfUse" />}
            />
            <AppHeaderButton
              checkCondition={_handleResourceTabVisibility()}
              activePath="resources"
              className={`${navTitle} ${
                isActivePath('resources') ? active : ''
              }`}
              testId="resources-btn"
              onClick={() =>
                _handleNavigation(
                  '/resources',
                  eventCategories.RESOURCES,
                  'Clicked on resources navigation'
                )
              }
              buttonHtml={<Lang path="navigation.resources" />}
            />
            <AppHeaderButton
              activePath="account"
              className={`${navTitle} ${isActivePath('account') ? active : ''}`}
              id="account-btn"
              testId="account-btn"
              onClick={() =>
                _handleNavigation(
                  '/account',
                  eventCategories.ACCOUNT,
                  'Clicked on account navigation'
                )
              }
              buttonHtml={<Lang path="navigation.account" />}
            />
          </div>
        )}
      </div>
      {isLoggedIn() && featureService._isProductActive() && (
        <div className={logoutContainer}>
          <AppHeaderButton
            style={emergencyButtonContainer}
            onClick={_handleEmergencyClick}
            buttonHtml={
              <div className={emergencyButtonStyle}>
                <span
                  data-testid="emergency-phone-icon"
                  className={emergencyIconStyle}
                >
                  <img alt="" src={emergencyCallIcon} />
                </span>
                <span>
                  <u data-testid="emergency-call-button">
                    <Lang path="emergencyCallText" />
                  </u>
                </span>
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    showModal: bindActionCreators(showModal, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch)
  }
});

const mapStateToProps = (state) => {
  const {
    reportList: { reports },
    companyDetails: { companyDetails },
    dashBoard: { goalId, severity },
    requestCoach: { coachRequestStatus }
  } = state;
  const currentPath = getActivePath();
  const fixed = currentPath.includes('/my-plan');
  const eapPhone = _.get(companyDetails, 'partner.eap_phone', '');
  return {
    companyDetails,
    reports,
    goalId,
    severity,
    eapPhone,
    coachRequestStatus,
    fixed
  };
};

AppHeader.propTypes = {
  onClick: propTypes.func,
  openSideBar: propTypes.func,
  fixed: propTypes.bool,
  reports: propTypes.arrayOf(propTypes.shape({})),
  goalId: propTypes.string,
  severity: propTypes.string,
  eapPhone: propTypes.string
};

AppHeader.defaultProps = {
  onClick: null,
  openSideBar: null,
  fixed: false,
  reports: [],
  goalId: null,
  severity: null,
  eapPhone: null
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
