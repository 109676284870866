/**
 * Progress bar wrapped in our custom style
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  brandColorPrimary,
  brandColorForegroundPrimary
} from 'styles/config.scss';

// We can inject some CSS into the DOM.
const styles = {
  root: {
    backgroundColor: `${brandColorForegroundPrimary}0d`, // 0.05 alpha = 0d in hex
    borderRadius: 5
  },
  bar: {
    backgroundColor: brandColorPrimary
  }
};

function ProgressBar(props) {
  const { classes, ...rest } = props;

  return (
    <LinearProgress
      classes={{ bar: classes.bar, root: classes.root }}
      {...rest}
    />
  );
}

export default withStyles(styles)(ProgressBar);
