import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useQuery } from '@apollo/react-hooks';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { queries } from 'utils/graphql';
import logger from 'utils/logger';

import goalIconList, { goalIcon } from 'assets/data/goalIconList';
import { Ripple } from '../../../../../../../components/ui/Layout';
import Lang from '../../../../../../../components/Lang';
import styles from './ClientProgress.module.scss';
import GoalItemContent from '../GoalItemContent/GoalItemContent';

const {
  expansionPanelContainer,
  expansionPanelDetailsContainer,
  shadowStyle,
  goalImageStyle,
  goalNameStyle,
  expansionPanelSummaryContainer,
  infoStyle,
  labelStyle,
  noProgressLabelStyle,
  selectedLabelStyle,
  goalDetailsContainer,
  disabledStyle
} = styles;

const { GET_USER_PROGRESS } = queries;

const ClientProgress = ({ clientAuth0Id }) => {
  const [activeGoal, setActiveGoal] = useState('');

  useEffect(() => {
    setActiveGoal('');
  }, [clientAuth0Id]);

  const {
    loading,
    error,
    data: progressData
  } = useQuery(GET_USER_PROGRESS, {
    variables: {
      clientAuth0Id
    }
  });

  if (loading) {
    return (
      <div className={infoStyle}>
        <Ripple />
      </div>
    );
  }

  if (error) {
    logger.error(`${error.message}`, 'ClientProgress', { error });
    return (
      <div className={infoStyle} data-testid="chat-client-progress-error">
        <Lang path="chatClientProgressErrorText" />
      </div>
    );
  }

  return (
    <>
      {!isEmpty(progressData.getUserProgress.goals) ? (
        progressData.getUserProgress.goals.map((goal, index) => {
          const expanded = activeGoal === goal.displayName;
          return (
            <div
              data-testid={`goal-number-${index}`}
              className={expansionPanelContainer}
              key={index}
            >
              <Accordion
                onChange={() => {
                  if (!isEmpty(goal.tools)) {
                    setActiveGoal(goal.displayName);
                  }
                }}
                expanded={expanded}
              >
                <AccordionSummary
                  onClick={() => {
                    if (expanded) {
                      setActiveGoal('');
                    }
                  }}
                  className={`${shadowStyle} ${
                    isEmpty(goal.tools) && disabledStyle
                  }`}
                  expandIcon={!isEmpty(goal.tools) && <ExpandMoreIcon />}
                >
                  <div
                    className={expansionPanelSummaryContainer}
                    data-testid="client-progress-container"
                  >
                    <div className={goalDetailsContainer}>
                      <img
                        className={goalImageStyle}
                        src={goalIconList[goal.category] || goalIcon}
                        alt={goal.displayName}
                      />
                      <span data-testid="goal-name" className={goalNameStyle}>
                        {goal.displayName}
                      </span>
                    </div>
                    {isEmpty(goal.tools) && (
                      <div
                        data-testid="progress-tag"
                        className={[labelStyle, noProgressLabelStyle].join(' ')}
                      >
                        No Progress
                      </div>
                    )}
                    {goal.isSelected && (
                      <div
                        data-testid="selected-tag"
                        className={[[labelStyle, selectedLabelStyle].join(' ')]}
                      >
                        Selected
                      </div>
                    )}
                  </div>
                </AccordionSummary>
                {expanded && (
                  <AccordionDetails className={expansionPanelDetailsContainer}>
                    <GoalItemContent
                      tools={goal.tools}
                      goalName={goal.displayName}
                      superExpanded={expanded}
                    />
                  </AccordionDetails>
                )}
              </Accordion>
            </div>
          );
        })
      ) : (
        <div data-testid="coach-chat-no-progress-text" className={infoStyle}>
          <Lang path="chatClientProgressNoSessions" />
        </div>
      )}
    </>
  );
};

export default ClientProgress;
