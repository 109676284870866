import { CURRENT_USER, INFO_TAB_COACH_CHAT } from '../types';

export const currentUserAction = (user) => ({
  type: CURRENT_USER,
  payload: { user }
});
export const infoTabAction = (view) => ({
  type: INFO_TAB_COACH_CHAT,
  payload: view
});
