/**
 *
 * Survey Card component
 *
 */

import React from 'react';
// components
import { Card } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import analytics from 'utils/analytics';
import history from 'utils/history';
import retakeSurveyImg from 'assets/img/dashboard/retakesurvey/retake_survey.png';
import {
  OutlineButton,
  UnderlineButton
} from '../../../../components/ui/Buttons';
import Lang from '../../../../components/Lang';
// styles
import styles from './RetakeSurveyCard.module.scss';
// utils
// assets

const { container, contentContainer, buttonsContainer } = styles;

const _handleRetakeSurveyClick = (goalId, severity) => {
  analytics.track('dashboard', 'clicked retake survey', {
    goalId,
    severity
  });
  history.push('/assessment/conversational');
};

const _handleViewResultsClick = (goalId, severity) => {
  analytics.track('dashboard', 'clicked view all results', {
    goalId,
    severity
  });
  history.push('/history');
};

const RetakeSurveyCard = (props) => {
  const { t } = useTranslation();
  return (
    <Card className={container}>
      <div className={contentContainer}>
        <img
          data-testid="retake-survey-img"
          src={retakeSurveyImg}
          alt={t('reportRetest')}
        />
        <div>
          <h2 data-testid="retake-survey-header-text">
            <Lang path="retakeSurveyCard.headerText" />
          </h2>
          <p data-testid="retake-survey-description-text">
            <Lang
              path="retakeSurveyCard.bodyText"
              components={{
                bold: <b />
              }}
            />
          </p>
        </div>
      </div>
      <div className={buttonsContainer}>
        <OutlineButton
          testId="retake-survey"
          onClick={() => _handleRetakeSurveyClick(props.goalId, props.severity)}
        >
          <Lang path="retakeSurveyCard.buttonText" />
        </OutlineButton>
        <UnderlineButton
          testId="view-results"
          onClick={() => _handleViewResultsClick(props.goalId, props.severity)}
        >
          <Lang path="retakeSurveyCard.resultButtonText" />
        </UnderlineButton>
      </div>
    </Card>
  );
};

export default RetakeSurveyCard;
