// Core
import React from 'react';

// styles
import styles from './AssessmentFooter.module.scss';

const AssessmentFooter = (props) => {
  const classes = [];
  classes.push(styles.assessmentFooter);

  if (props && props.className) {
    classes.push(props.className);
  }
  return <div className={classes.join(' ')}>{props.children}</div>;
};

export default AssessmentFooter;
