import React, { useCallback, useState } from 'react';
import { Button, FormattedTime } from 'react-player-controls';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import _ from 'lodash';
// material icons
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import getVideoFullScreenDetails from '../videoScreen.utils';
// styles
import styles from './PlayerControls.module.scss';
// components
import SliderProgressBar from './components/SliderProgressBar';

const {
  container,
  topControls,
  topLeftControls,
  topRightControls,
  playerButton,
  volumeControlsStyle,
  volumeSliderStyle,
  whiteIcons,
  timer,
  hiddenControlsStyle,
  hideVolumeSliderStyle
} = styles;

const PlayerControls = ({
  duration,
  currentProgress,
  isPlaying,
  playerRef,
  handleFullScreenChange,
  hideVideoControls,
  muted,
  volume,
  handleVolumeButtonClick,
  setVolume,
  handlePlay
}) => {
  const { isFullScreen } = useSelector((state) => _.get(state, 'videoMode'));
  const { isfullScreenSupported } = getVideoFullScreenDetails();
  const durationArr = duration.split(':');
  const durationInSeconds =
    60 * parseInt(durationArr[0], 10) + parseInt(durationArr[1], 10);
  const [showVolumeSliderBar, setShowVolumeSliderBar] = useState(false);

  // Handling the video controls basis of device width
  const showVolumeControls = useMediaQuery('(min-width: 992px)');

  // Slider seeking handler
  const handleSliderChange = useCallback(
    (val) => {
      if (playerRef.current) {
        playerRef.current.seekTo(val, 'seconds');
      }
    },
    [playerRef]
  );

  // Volume slider handler
  const handleVolumeSliderChange = useCallback(
    (val) => {
      if (val >= 0 && val <= 1) {
        setVolume(parseFloat(val.toFixed(2)));
      }
    },
    [setVolume]
  );

  return (
    <div
      className={`${container} ${hideVideoControls ? hiddenControlsStyle : ''}`}
    >
      <div className={topControls}>
        <div className={topLeftControls}>
          <Button onClick={handlePlay} className={playerButton}>
            {!isPlaying ? (
              <PlayArrowIcon
                data-testid="play-pause-icon"
                className={whiteIcons}
              />
            ) : (
              <PauseIcon data-testid="play-pause-icon" className={whiteIcons} />
            )}
          </Button>
          {showVolumeControls && (
            <div
              className={volumeControlsStyle}
              onMouseEnter={() => setShowVolumeSliderBar(true)}
              onMouseLeave={() => setShowVolumeSliderBar(false)}
            >
              <Button
                className={playerButton}
                onClick={() => handleVolumeButtonClick()}
              >
                {muted ? (
                  <VolumeOffIcon
                    data-testid="mute-unmute-icon"
                    className={whiteIcons}
                  />
                ) : (
                  <VolumeUpIcon
                    data-testid="mute-unmute-icon"
                    className={whiteIcons}
                  />
                )}
              </Button>
              <div
                className={`${volumeSliderStyle} ${
                  !showVolumeSliderBar ? hideVolumeSliderStyle : ''
                }`}
              >
                <SliderProgressBar
                  duration={1}
                  value={volume}
                  handleSliderChange={handleVolumeSliderChange}
                  rounded
                />
              </div>
            </div>
          )}
          <div className={timer}>
            <span data-testid="completed-duration">
              <FormattedTime numSeconds={Math.floor(currentProgress)} />
            </span>
            <span data-testid="total-duration">{` / ${duration}`}</span>
          </div>
        </div>
        <div className={topRightControls}>
          {isfullScreenSupported && (
            <Button
              onClick={() => handleFullScreenChange(!isFullScreen)}
              className={playerButton}
            >
              {isFullScreen ? (
                <FullscreenExitIcon className={whiteIcons} />
              ) : (
                <FullscreenIcon className={whiteIcons} />
              )}
            </Button>
          )}
        </div>
      </div>
      <SliderProgressBar
        duration={durationInSeconds}
        value={currentProgress || 0}
        handleSliderChange={handleSliderChange}
      />
    </div>
  );
};

export default PlayerControls;
