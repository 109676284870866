import { SHOW_MODAL, CLOSE_MODAL } from './types';

export const showModal = (
  component,
  { maxWidth, props, onClose, hideCloseButton, source } = {}
) => ({
  type: SHOW_MODAL,
  payload: {
    component,
    maxWidth,
    props,
    onClose,
    hideCloseButton,
    source
  }
});

export const closeModal = () => ({
  type: CLOSE_MODAL
});
