import React from 'react';
import Lang from '../../../components/Lang';
import i18n from '../../lang';

export default () => [
  {
    desc: [
      {
        text: <Lang path="goal.category.highstresswork.desc" />
      }
    ],
    name: 'highStressWork',
    descTitle: <Lang path="goal.bonusGoalDescTitle" />,
    categoryName: i18n.t('goal.category.highstresswork.name'),
    showEAP: true,
    recommend: 'app',
    levelText: [],
    level: 'bonus'
  }
];
