import React, { useState, Fragment } from 'react';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
// Wizard forms
import { getSelectStateList } from 'store/selectors/state-list.selector';
import InPersonTherapyFormFirstPage from './Wizard/InPersonTherapyFirstPage';
import InPersonTherapySecondPage from './Wizard/InPersonTherapySecondPage';

import styles from './InPersonTherapyRequest.module.scss';
// selectors
// Components
import Lang from '../../../../components/Lang';

const { headerContainer, formContainer } = styles;

const InPersonTherapyRequest = ({ onSubmit, isFetching, hasError }) => {
  const [page, setPage] = useState(1);
  const stateList = useSelector((state) => getSelectStateList(state));

  return (
    <Fragment>
      <div className={headerContainer}>
        <h2>
          <Lang path="therapyRequest.header" />
        </h2>
      </div>
      <div className={formContainer}>
        {page === 1 && (
          <InPersonTherapyFormFirstPage
            stateList={stateList}
            onSubmit={() => setPage(page + 1)}
          />
        )}
        {page === 2 && (
          <InPersonTherapySecondPage
            isFetching={isFetching}
            hasError={hasError}
            onPrevious={() => setPage(page - 1)}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </Fragment>
  );
};

export default reduxForm({
  form: 'inPersonTherapyRequest'
})(InPersonTherapyRequest);
