import { App } from '@capacitor/app';
import analytics from 'utils/analytics';
import { debounce, isEmpty } from 'lodash';
import { isLoggedIn } from 'utils/auth';
import { PushNotifications } from '@capacitor/push-notifications';
import eventCategories from 'utils/analytics/categories';
import helpers from 'utils/helpers';
import history from 'utils/history';
import { isNativePlatform, getDeviceId } from 'utils/capacitor';
import logger from 'utils/logger';
import notification from 'api/notification';
import { handleRootPathBackPress } from 'utils/navigation';
import store, { dispatch } from 'store';
import { showModal, closeModal } from 'store/actions/ModalAction';
import { closeVideoModal } from 'store/actions/media/VideoPlayerModalAction';
import BlockAccess from 'components/Popups/BlockAccess';
import i18n from 'assets/lang';
import userApi from './api/user';
import getVideoFullScreenDetails from './containers/SelfUse/components/Session/VideoScreen/videoScreen.utils';
import { unlockScreenOrientation } from './containers/SelfUse/components/Session/VideoScreen/lockOrientation';
import { videoFullScreenInactive } from './store/actions/VideoFullScreenAction';

let clickCount = 0;
let timeout = null;
let blockHardwareBackButton = false;

const showToast = ({ present }) => {
  present({
    message: i18n.t('exitAppToastMessage'),
    translucent: true
  });
};

const handleBackButtonDoubleTap = ({ present, dismiss }) => {
  clickCount += 1;
  if (clickCount === 2) {
    if (timeout) clearTimeout(timeout);
    clickCount = 0;
    dismiss();
    App.exitApp();
  } else {
    // 1 second delay to detect the double tap of hardware back button
    showToast({ present });
    timeout = setTimeout(() => {
      clickCount = 0;
      dismiss();
    }, 1000);
  }
};

export const hardwareBackButtonHandler = ({ present, dismiss }) => {
  document.addEventListener('ionBackButton', (event) => {
    event.detail.register(1, () => {
      const canGoBack = history.length > 1;
      const { exitFullScreen } = getVideoFullScreenDetails();
      const { videoMode } = store.getState();
      const { isFullScreen } = videoMode;
      analytics.track(eventCategories.APP, 'clicked on device back button');

      if (blockHardwareBackButton) {
        logger.info(
          'Blocked hardware back button action by user, rooted device detected.',
          'App.utils.hardwareBackButtonHandler'
        );
        return;
      }

      dispatch(closeModal());
      dispatch(closeVideoModal());
      if (isFullScreen && exitFullScreen) {
        unlockScreenOrientation();
        exitFullScreen();
        dispatch(videoFullScreenInactive());
      } else if (!canGoBack) {
        handleBackButtonDoubleTap({
          present,
          dismiss
        });
      } else {
        const currentPath = helpers.getActivePath();
        if (currentPath) {
          const pathName = currentPath.split('/')[1];
          const homeUrl = helpers.homeUrl();
          if (helpers.isActivePath(homeUrl.split('/')[1])) {
            // Detect double click on hardware button and exit the app
            handleBackButtonDoubleTap({ present, dismiss });
          } else if (helpers.isRootPath()) {
            // handles back button pressed on root path and/or query params on root path
            handleRootPathBackPress(pathName);
          } else {
            history.goBack();
          }
        } else {
          history.goBack();
        }
      }
    });
  });
};

export const trackActivity = debounce(() => {
  if (isLoggedIn()) {
    userApi.updateActivity({ type: 'active' });
  }
}, 2000);

export const resetBadgeCount = () => {
  PushNotifications.removeAllDeliveredNotifications();
  const deviceId = getDeviceId();
  if (!isEmpty(deviceId)) {
    notification.updateBadgeCount({ badgeCount: 0, deviceId: getDeviceId() });
  } else {
    logger.warn(
      'deviceId was empty while trying to reset badge count',
      'App.utils.js'
    );
  }
};

export const rootedDeviceHandler = () => {
  if (isNativePlatform && window.cordova) {
    window.cordova.exec(
      (isRooted) => {
        if (isRooted) {
          blockHardwareBackButton = true;
          logger.info(
            'Rooted/Jailbroken device is detected.',
            'App.utils.rootedDeviceHandler'
          );
          analytics.info(
            eventCategories.APP,
            'Rooted/Jailbroken device is detected.'
          );
          dispatch(showModal(BlockAccess, { hideCloseButton: true }));
        } else {
          blockHardwareBackButton = false;
          logger.info(
            'Device is not Rooted/Jailbroken.',
            'App.utils.rootedDeviceHandler'
          );
        }
      },
      (error) => {
        logger.error(
          'An error occurred while determining the root access status',
          'App.utils.rootedDeviceHandler',
          { error }
        );
      },
      'IRoot',
      'isRooted',
      []
    );
  }
};
