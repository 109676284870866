import {
  SET_WORK_LIFE_REQUEST_QUESTIONS_START,
  SET_WORK_LIFE_REQUEST_QUESTIONS_DONE,
  SET_WORK_LIFE_REQUEST_QUESTIONS_ERROR
} from '../../actions/types';

const INITIAL_STATE = {
  workLifeRequest: {},
  isFetching: false,
  isError: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_WORK_LIFE_REQUEST_QUESTIONS_START:
      return {
        ...state,
        isFetching: true,
        isError: false
      };
    case SET_WORK_LIFE_REQUEST_QUESTIONS_DONE:
      return {
        ...state,
        workLifeRequest: action.workLifeRequest,
        isFetching: false,
        isError: false
      };
    case SET_WORK_LIFE_REQUEST_QUESTIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        isError: true
      };
    default:
      return state;
  }
};
