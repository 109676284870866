import React from 'react';
import styles from './InputError.module.scss';

const { container } = styles;

const InputError = ({ testId, error, classes }) => (
  <span className={[container, classes].join(' ')} data-testid={testId}>
    {error}
  </span>
);

export default InputError;
