import { MINIMIZE_CHAT_WINDOW, MAXIMIZE_CHAT_WINDOW } from '../actions/types';

const INITIAL_STATE = {
  maximized: true
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MINIMIZE_CHAT_WINDOW:
      return {
        ...state,
        maximized: false
      };
    case MAXIMIZE_CHAT_WINDOW:
      return {
        ...state,
        maximized: true
      };
    default:
      return state;
  }
};
