/**
 *
 * Chat typing animation
 *
 */

import React from 'react';
import styles from './ChatTyping.module.scss';

const { spinnerContainer, spinner, bounce1, bounce2 } = styles;

const ChatTypingAnimation = () => (
  <div data-testid="chat-typing-loader">
    <span className={spinnerContainer}>
      <div className={spinner}>
        <div className={bounce1} />
        <div className={bounce2} />
        <div />
      </div>
    </span>
  </div>
);

export default ChatTypingAnimation;
