import React from 'react';
import propTypes from 'prop-types';
// components
import EditableField from 'components/EditableField';

const Input = ({
  onKeyUpHandler,
  placeholder,
  defaultValue,
  isEditing,
  type
}) => (
  <EditableField
    onKeyUpHandler={(text) => onKeyUpHandler(text)}
    placeholder={placeholder}
    defaultValue={defaultValue}
    isEditing={isEditing}
    testId={type}
  />
);

Input.propTypes = {
  placeholder: propTypes.string,
  defaultValue: propTypes.string,
  type: propTypes.string,
  onKeyUpHandler: propTypes.func,
  isEditing: propTypes.bool
};

Input.defaultProps = {
  onKeyUpHandler: () => null,
  placeholder: '',
  defaultValue: '',
  type: '',
  isEditing: false
};

export default Input;
