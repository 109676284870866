// core
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// Components
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { Col, Row } from 'antd';
import { useScrollToField } from 'utils/hooks';
import validation from 'utils/validation/validation';
import countryOptions from 'assets/data/profile/countryOptions';
import { getMonths, years, days } from 'assets/data/profile/dateOptions';
import {
  TextFieldBlack,
  SelectFieldBlack,
  CheckBoxField
} from '../../../../../components/ui/Inputs';
import { FieldSet } from '../../../../../components/ui/Layout';
import { ActionButton } from '../../../../../components/ui/Buttons';
import ProvidedByMDLive from '../ProvidedByMDLive';
import ProvidedByMockVendor from '../ProvidedByMockVendor';
// styles
import styles from './VideoTherapy.module.scss';

const {
  formField,
  nextButtonStyle,
  nextButtonContainer,
  checkBoxLabelStyle,
  permissionContainer,
  permissionError,
  dobStyle,
  phoneCodeStyle
} = styles;

const countryCodes = countryOptions.map(({ label, value }) => ({
  value,
  label
}));

const VideoTherapyFormFirstPage = ({ handleSubmit, stateList }) => {
  const { t } = useTranslation();
  const accessCode = useSelector((state) =>
    _.get(state, 'companyDetails.companyDetails.aliases[0]')
  );

  const isAetnaDemo = useMemo(
    () => accessCode === '!!!aetna-demo',
    [accessCode]
  );

  const { changeFocusField } = useScrollToField({ scrollIfNeeded: true });

  const isAnthemDemo = useMemo(() => accessCode === '114anthem', [accessCode]);

  const isSmallScreen = useMediaQuery('(max-width: 767px)');

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 5, offset: 0 }}>
          <Field
            name="firstName"
            component={TextFieldBlack}
            type="text"
            label={`${t('registerFirstName')}*`}
            labelId="first-name-label"
            className={formField}
            validate={[validation.required_first_name, validation.first_name]}
            onClick={changeFocusField}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 5, offset: 1 }}>
          <Field
            name="lastName"
            component={TextFieldBlack}
            type="text"
            label={`${t('registerLastName')}*`}
            labelId="last-name-label"
            className={formField}
            validate={[validation.required_last_name, validation.last_name]}
            onClick={changeFocusField}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ offset: 2, span: 10 }}>
          <Field
            name="companyName"
            component={TextFieldBlack}
            type="text"
            className={formField}
            label={`${t('formCommon.employerIdText')}`}
            labelId="employer-id-label"
            disabled
          />
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 10 }} lg={{ span: 4, offset: 0 }}>
          <Field
            name="phoneCode"
            component={SelectFieldBlack}
            label={`${t('therapyRequest.form.phoneCodeText')}*`}
            labelId="code-label"
            className={`${formField} ${phoneCodeStyle}`}
            options={countryCodes}
            validate={validation.required_country_code}
          />
        </Col>
        <Col xs={{ span: 14 }} lg={{ span: 7, offset: 0 }}>
          <Field
            id="phone-number"
            name="phone"
            component={TextFieldBlack}
            type="text"
            label={`${t('formCommon.phoneNumberText')}*`}
            labelId="phone-label"
            className={formField}
            validate={[validation.required_phone, validation.phone]}
            onClick={changeFocusField}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ offset: 2, span: 11 }}>
          <Field
            name="email"
            component={TextFieldBlack}
            type="email"
            label={`${t('formCommon.emailText')}*`}
            labelId="email-label"
            className={formField}
            validate={[validation.required_email, validation.email]}
          />
        </Col>
      </Row>
      <Row gutter={[0, 25]}>
        <Col xs={{ span: 24 }} className={permissionContainer}>
          <Field
            id="email-voice-mail-checkbox"
            name="isEmailAndVoiceMailAllowed"
            component={(props) => (
              <CheckBoxField
                errorClasses={[permissionError]}
                errorId="check-permission-error-message"
                {...props}
              />
            )}
            text={
              <span
                data-testid="email-voice-mail-permission-text"
                className={checkBoxLabelStyle}
              >
                {t('therapyRequest.form.permissionText')}
              </span>
            }
            validate={validation.required_permission_check_box}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }}>
          <FieldSet label={t('formCommon.dateOfBirth')} className={dobStyle}>
            <Row>
              <Col xs={{ span: 7 }}>
                <Field
                  name="birthYear"
                  component={SelectFieldBlack}
                  label={`${t('formCommon.birthYearText')}*`}
                  labelId="year-label"
                  className={formField}
                  options={years}
                  validate={validation.required_birth_year}
                />
              </Col>
              <Col xs={{ span: 7, offset: 1 }}>
                <Field
                  name="birthMonth"
                  component={SelectFieldBlack}
                  label={`${t('formCommon.birthMonthText')}*`}
                  labelId="month-label"
                  className={formField}
                  options={getMonths()}
                  validate={validation.required_birth_month}
                />
              </Col>
              <Col xs={{ span: 7, offset: 1 }}>
                <Field
                  name="birthDay"
                  component={SelectFieldBlack}
                  label={`${t('formCommon.birthDayText')}*`}
                  labelId="day-label"
                  className={formField}
                  options={days}
                  validate={validation.required_birth_day}
                />
              </Col>
            </Row>
          </FieldSet>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }}>
          <Field
            id="address"
            name="address"
            component={TextFieldBlack}
            type="text"
            label={t('formCommon.addressText')}
            labelId="address-label"
            className={formField}
            onClick={changeFocusField}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} lg={{ span: 11 }}>
          <Field
            className={formField}
            name="state"
            component={SelectFieldBlack}
            label={`${t('formCommon.stateText')}*`}
            labelId="state-label"
            options={stateList}
            validate={validation.required_geo_state}
            onClick={changeFocusField}
          />
        </Col>
        <Col xs={24} lg={{ offset: 2, span: 11 }}>
          <Field
            id="city"
            name="city"
            component={TextFieldBlack}
            type="text"
            label={t('formCommon.cityText')}
            labelId="city-label"
            className={formField}
            onClick={changeFocusField}
          />
        </Col>
      </Row>
      <Row>
        <Col className={nextButtonContainer}>
          {isAetnaDemo && !isSmallScreen && <ProvidedByMDLive />}
          {isAnthemDemo && !isSmallScreen && <ProvidedByMockVendor />}
          <ActionButton
            testId="next-button"
            className={nextButtonStyle}
            onClick={handleSubmit}
          >
            {t('nextButtonText')}
          </ActionButton>
        </Col>
      </Row>
      {isAetnaDemo && isSmallScreen && (
        <Row>
          <Col>
            <ProvidedByMDLive />
          </Col>
        </Row>
      )}
      {isAnthemDemo && isSmallScreen && (
        <Row>
          <Col>
            <ProvidedByMockVendor />
          </Col>
        </Row>
      )}
    </form>
  );
};

VideoTherapyFormFirstPage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  stateList: PropTypes.arrayOf(PropTypes.shape({}), PropTypes.string).isRequired
};

export default reduxForm({
  form: 'videoTherapyRequest',
  destroyOnUnmount: false
})(VideoTherapyFormFirstPage);
