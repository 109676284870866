import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    bottom: '20px',
    padding: 0,
    boxShadow: '0 1px 0 0 #ededed',
    '&.Mui-disabled': {
      backgroundColor: '#fff'
    }
  },
  summaryRoot: {
    padding: 0
  },
  summaryContent: {
    width: '100%',
    overflow: 'hidden'
  },
  detailsRoot: {
    display: 'block',
    width: '100%',
    padding: '0 0 20px 0'
  },
  expandIcon: {
    bottom: '5px',
    '&$expanded': {
      transform: 'none'
    }
  },
  expandIconWithProgress: {
    bottom: '15px',
    '&$expanded': {
      transform: 'none'
    }
  },
  expanded: {
    position: 'relative',
    bottom: '3px'
  }
}));
