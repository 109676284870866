// core
import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import classNamesInstance from 'classnames/bind';
import { isEmpty } from 'lodash';
// assets
import i18n from 'assets/lang';
import completedIcon from '../../assets/icons/session/completed.svg';
import uncheckedIcon from '../../assets/icons/session/unchecked-checklist.svg';
// styles
import styles from './Checklist.module.scss';

const { completedIconStyle, uncheckedIconStyle } = styles;

const classNames = classNamesInstance.bind(styles);

const Checklist = ({ id, text, description, onSelect, isSelected, index }) => {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected, setSelected]);

  const onSelectHandler = () => {
    onSelect({ isSelected: !selected, optionId: id, text });
  };

  // Conditional classames
  const containerClass = classNames({
    activeContainer: selected,
    inactiveContainer: !selected,
    largeListItemStyle: description,
    smallListItemStyle: isEmpty(description)
  });

  const contentContainerClass = classNames({
    largeContentContainer: description,
    smallContentContainer: isEmpty(description)
  });

  const textClass = classNames({
    activeText: selected,
    inactiveText: !selected
  });

  const descriptionClass = classNames({
    activeDescription: selected,
    inactiveDescription: !selected
  });

  return (
    <div
      data-testid={`checklist-container-${index}`}
      role="button"
      tabIndex={0}
      onClick={() => onSelectHandler()}
      onKeyDown={() => onSelectHandler()}
      className={containerClass}
    >
      <div className={contentContainerClass}>
        <p
          title={text}
          data-testid={`checklist-text-${index}`}
          className={textClass}
        >
          {text}
        </p>
        {selected ? (
          <span
            tabIndex={0}
            role="button"
            onKeyDown={() => onSelectHandler()}
            onClick={() => onSelectHandler()}
          >
            <img
              data-testid={`checklist-checked-${index}`}
              className={completedIconStyle}
              src={completedIcon}
              alt={i18n.t('alternateText.image.checked')}
            />
          </span>
        ) : (
          <img
            data-testid={`checklist-unchecked-${index}`}
            className={uncheckedIconStyle}
            src={uncheckedIcon}
            alt={i18n.t('alternateText.image.unchecked')}
          />
        )}
      </div>
      {description && (
        <p
          title={description}
          data-testid={`checklist-description-${index}`}
          className={descriptionClass}
        >
          {description}
        </p>
      )}
    </div>
  );
};

Checklist.propTypes = {
  id: propTypes.string,
  text: propTypes.string,
  onSelect: propTypes.func,
  isSelected: propTypes.bool,
  index: propTypes.number
};

Checklist.defaultProps = {
  id: null,
  text: null,
  onSelect: null,
  isSelected: false,
  index: null
};

export default Checklist;
