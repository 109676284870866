/**
 * Registration page container
 */

// Core
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
// Components
import ProfileForm from './components/ProfileForm';
import ContentContainer from '../../components/layout/ContentContainer';
import Lang from '../../components/Lang';
import toast from '../../components/Toast';
// assets
import profileFieldList from '../../assets/data/profileFieldList';
import fieldTypes from '../../assets/data/fieldTypes';
// hocs
import withAuth from '../../hoc/withAuth';
// action
import {
  updateUserData,
  resetUserDataStatus
} from '../../store/actions/ProfileAction';
// utils
import analytics from '../../utils/analytics';
import eventCategories from '../../utils/analytics/categories';
import helpers from '../../utils/helpers';
// styles
import styles from './Profile.module.scss';
// config
import config from '../../config';

const { supportEmail } = config;

class Profile extends Component {
  state = {
    errorMessage: '',
    readonlyProps: {},
    readonlyPropsArr: [],
    source: ''
  };

  componentWillMount() {
    const data = this.props.profile;

    const requiredProps = [];
    const readonlyPropsArr = [];
    const readonlyProps = {};

    const { registrationFields } = this.props.companyDetails;

    Object.keys(registrationFields).forEach((key) => {
      const currentField = registrationFields[key];
      if (currentField.required === true && currentField.visible === true) {
        requiredProps.push({
          name: key,
          readonly: registrationFields[key].readonly
        });
      }
      if (currentField.readonly === true && currentField.visible === true) {
        readonlyPropsArr.push(key);
      }
    });

    for (let i = 0; i < readonlyPropsArr.length; i += 1) {
      readonlyProps[readonlyPropsArr[i]] = true;
    }
    for (let i = 0; i < requiredProps.length; i += 1) {
      if (!data[requiredProps[i].name] && requiredProps[i].readonly) {
        // Make the field editable if its required but not available
        delete readonlyProps[requiredProps[i].name];
        readonlyPropsArr.splice(
          readonlyPropsArr.indexOf(requiredProps[i].name),
          1
        );
      }
    }

    const source = _.get(this.props, 'history.location.state.source', '');
    this.setState({
      readonlyProps,
      readonlyPropsArr,
      source
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isDone === true) {
      if (this.state.source === 'auth') {
        helpers.redirectAfterLogin();
      } else if (this.state.source === 'navigation' && !nextProps.hasError) {
        toast.info(<Lang path="profileUpdateSuccess" />, null, {
          autoClose: 3000
        });
      }
      this.props.actions.resetUserDataStatus();
    }
  }

  _setErrorMessage = (msg) => {
    this.setState({ errorMessage: msg });
  };

  _handleSubmit = (params) => {
    if (this.state.source === 'auth') {
      analytics.track(eventCategories.PROFILE, 'clicked finished registration');
    } else {
      analytics.track(eventCategories.PROFILE, 'clicked update profile');
    }

    this.setState({
      errorMessage: ''
    });

    const paramsCopy = { ...params };

    const { readonlyPropsArr } = this.state;

    const allProps = [];

    profileFieldList().forEach((item) => {
      if (item.fieldType === fieldTypes.array) {
        item.fields.forEach((arrItem) => {
          allProps.push(arrItem.name);
        });
      } else {
        allProps.push(item.name);
      }
    });

    for (let i = 0; i < readonlyPropsArr.length; i += 1) {
      if (allProps.indexOf(readonlyPropsArr[i]) > -1) {
        allProps.splice(allProps.indexOf(readonlyPropsArr[i]), 1);
      }
    }
    // Update user data and pass the params copy with readonly fields removed
    if (paramsCopy.birth_month && paramsCopy.birth_month.length === 1) {
      paramsCopy.birth_month = `0${paramsCopy.birth_month}`;
    }
    if (paramsCopy.birth_day && paramsCopy.birth_day.length === 1) {
      paramsCopy.birth_day = `0${paramsCopy.birth_day}`;
    }
    if (paramsCopy.phone) {
      const matchExp = /\d+/g;
      paramsCopy.phone = paramsCopy.phone.match(matchExp).join('');
    }
    this.props.actions.updateUserData(paramsCopy);
  };

  render() {
    let errorMsg = '';
    if (this.props.hasError) {
      errorMsg = <Lang path="profileUpdateError" values={{ supportEmail }} />;
    }
    if (this.state.errorMessage !== '') {
      errorMsg = this.state.errorMessage;
    }

    return (
      <ContentContainer showBg size="large">
        <div className={styles.container}>
          <ProfileForm
            onSubmit={this._handleSubmit}
            errorMessage={errorMsg}
            isLoading={this.props.isLoading}
            initialValues={{ ...this.props.profile }}
            companyNameDisabled={this.state.companyName}
            readonlyProps={this.state.readonlyProps}
            profileFields={this.props.companyDetails.registrationFields}
            source={this.state.source}
          />
        </div>
      </ContentContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    profile: { isLoading, isDone, hasError, profile },
    companyDetails: { companyDetails }
  } = state;

  return {
    isLoading,
    isDone,
    hasError,
    profile,
    companyDetails
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    updateUserData: bindActionCreators(updateUserData, dispatch),
    resetUserDataStatus: bindActionCreators(resetUserDataStatus, dispatch)
  }
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Profile));
