import React from 'react';
import ContentLoader from 'react-content-loader';
import largeImage from 'assets/img/dashboard/largeblock.png';
import smallImage from 'assets/img/dashboard/smallblock.png';

export const LoaderLarge = () => (
  <ContentLoader
    style={{
      background: `url(${largeImage}) no-repeat`,
      backgroundSize: '100% 100%'
    }}
    height="400"
    width="352"
  >
    <rect x="21" y="250" rx="0" ry="0" width="312" height="10" />
    <rect x="21" y="273" rx="0" ry="0" width="312" height="10" />
    <rect x="21" y="296" rx="0" ry="0" width="312" height="10" />
    <rect x="21" y="319" rx="0" ry="0" width="312" height="10" />
    <rect x="21" y="342" rx="0" ry="0" width="109" height="10" />
  </ContentLoader>
);

export const LoaderSmall = () => (
  <ContentLoader
    style={{
      background: `url(${smallImage}) no-repeat`,
      backgroundSize: '100% 100%'
    }}
    height="190"
    width="352"
  >
    <rect x="21" y="107" rx="0" ry="0" width="187" height="8" />
    <rect x="21" y="124" rx="0" ry="0" width="187" height="8" />
  </ContentLoader>
);
