// core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '@material-ui/core';
// components
// assets
import coachRequestStatusTypes from 'assets/data/dashboard/coachRequestStatusTypes';
import coachNotAssignedImg from 'assets/img/dashboard/focuscard/coach_not_assigned.svg';
import clockIcon from 'assets/img/dashboard/focuscard/clock.svg';
import flagIcon from 'assets/img/dashboard/focuscard/flag.svg';
import progressBarIcon from 'assets/img/dashboard/focuscard/progress-bar.svg';
import stepsIcon from 'assets/img/dashboard/focuscard/steps.svg';
import supportIcon from 'assets/img/dashboard/focuscard/support.svg';
// helpers
import { getCoachStatus } from 'store/actions/dashboard/CoachRequestAction';
import { chatNowHandler, requestCoach } from '../../../helpers';
// styles
import styles from './CoachCard.module.scss';
import ErrorRetry from '../../../../../components/ErrorRetry';
import Lang from '../../../../../components/Lang';
import { ActionButton } from '../../../../../components/ui/Buttons';

const {
  cardContainer,
  titleContainer,
  headerContainer,
  coachInfoContainer,
  contentContainer,
  retryContainer,
  buttonContainer
} = styles;

const CoachCard = () => {
  const dispatch = useDispatch();
  const _handleRetry = () => {
    dispatch(getCoachStatus());
  };
  const {
    coachRequestStatus,
    coachDetails,
    status: { hasError, isFetching }
  } = useSelector((state) => state.requestCoach);
  const { goalId, severity } = useSelector((state) => state.dashBoard);
  const {
    companyDetails: {
      partner: { name: eapName }
    }
  } = useSelector((state) => state.companyDetails);
  const { reports } = useSelector((state) => state.reportList);
  const containerClass = hasError
    ? [retryContainer, cardContainer].join(' ')
    : cardContainer;
  return (
    <Card className={containerClass}>
      {hasError ? (
        <ErrorRetry
          title={<Lang path="coachRequestStatusError" />}
          isFetching={isFetching}
          onClick={_handleRetry}
          buttonId="coach-card-retry-button"
          titleId="retry-error-text"
        />
      ) : (
        <>
          <div className={titleContainer}>
            <span data-testid="focus-area-coach-assigned-text">
              <Lang path="focusAreaCard.assignedText" />
            </span>
          </div>
          <div
            data-testid="focus-area-coach-details"
            className={headerContainer}
          >
            {coachRequestStatus === coachRequestStatusTypes.none && (
              <>
                <img src={coachNotAssignedImg} alt="" />
                <span data-testid="coach-not-assigned-text">
                  <Lang path="focusAreaCard.notAssignedText" />
                </span>
              </>
            )}
            {coachRequestStatus === coachRequestStatusTypes.inProgress && (
              <>
                <img src={coachNotAssignedImg} alt="" />
                <span data-testid="coach-assign-in-progress">
                  <Lang path="focusAreaCard.coachAssignText" />
                </span>
              </>
            )}
            {coachRequestStatus === coachRequestStatusTypes.requested && (
              <>
                <img src={coachNotAssignedImg} alt="" />
                <span>
                  <Lang path="focusAreaCard.requestedText" />
                </span>
              </>
            )}
            {coachRequestStatus === coachRequestStatusTypes.assigned && (
              <>
                <img
                  src={coachDetails.picture || coachNotAssignedImg}
                  alt={coachDetails.name}
                />
                <div
                  data-testid="coach-info-text"
                  className={coachInfoContainer}
                >
                  <h3>{coachDetails.name}</h3>
                  <span>{coachDetails.designation}</span>
                </div>
              </>
            )}
          </div>
          <div className={contentContainer}>
            <div>
              <p data-testid="focus-area-help-text">
                <Lang path="focusAreaCard.helpDescriptionText" />
              </p>
              <ul data-testid="focus-area-items">
                <li>
                  <div>
                    <img src={flagIcon} alt="" />
                  </div>
                  <span data-testid="focus-area-item-one-text">
                    <Lang path="focusAreaCard.helpText1" />
                  </span>
                </li>
                <li>
                  <div>
                    <img src={progressBarIcon} alt="" />
                  </div>
                  <span data-testid="focus-area-item-two-text">
                    <Lang path="focusAreaCard.helpText2" />
                  </span>
                </li>
                <li>
                  <div>
                    <img src={clockIcon} alt="" />
                  </div>
                  <span data-testid="focus-area-item-three-text">
                    <Lang path="focusAreaCard.helpText3" />
                  </span>
                </li>
                <li>
                  <div>
                    <img src={stepsIcon} alt="" />
                  </div>
                  <span data-testid="focus-area-item-four-text">
                    <Lang path="focusAreaCard.helpText4" />
                  </span>
                </li>
                <li>
                  <div>
                    <img src={supportIcon} alt="" />
                  </div>
                  <span data-testid="focus-area-item-five-text">
                    <Lang path="focusAreaCard.helpText5" />
                  </span>
                </li>
              </ul>
            </div>
            <div className={buttonContainer}>
              {coachRequestStatus === coachRequestStatusTypes.none && (
                <ActionButton
                  testId="request-now-button"
                  onClick={() =>
                    requestCoach({
                      goalId,
                      severity,
                      analyticsMessage:
                        'clicked request coach from focus area card',
                      eapName,
                      report: reports[0]
                    })
                  }
                >
                  <Lang path="focusAreaCard.buttonRequestText" />
                </ActionButton>
              )}
              {coachRequestStatus === coachRequestStatusTypes.assigned && (
                <ActionButton
                  testId="chat-now-button"
                  onClick={() => {
                    chatNowHandler({
                      coachRequestStatus,
                      goalId,
                      severity,
                      analyticsMessage: 'clicked chat now from focus area card'
                    });
                  }}
                >
                  <Lang path="focusAreaCard.buttonChatText" />
                </ActionButton>
              )}
            </div>
          </div>
        </>
      )}
    </Card>
  );
};

export default CoachCard;
