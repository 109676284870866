export default {
  IE: 'ie',
  FIREFOX: 'firefox',
  SAFARI: 'safari',
  MOBILE_SAFARI: 'mobile safari',
  OPERA: 'opera',
  CHROME: 'chrome',
  WEBKIT: 'webkit',
  EDGE: 'edge'
};
