/**
 * Goal Actions and action creators
 */

import api from '../../api/assessment';

import { SELECT_GOAL, SELECT_GOAL_DONE, SELECT_GOAL_ERROR } from './types';

import logger from '../../utils/logger';
import handleUnexpectedError from '../helper';

const selectGoalStart = () => ({ type: SELECT_GOAL });

const selectGoalDone = () => ({
  type: SELECT_GOAL_DONE
});

const selectGoalError = () => ({ type: SELECT_GOAL_ERROR });

export default (goalName) => (dispatch) => {
  dispatch(selectGoalStart());
  return api
    .selectGoal(goalName)
    .then((resp) => {
      if (resp.status === 200) {
        dispatch(selectGoalDone(resp.data));
      } else {
        logger.error(
          'An error occurred while selecting the goal',
          'GoalAction.default',
          { resp }
        );
        dispatch(selectGoalError());
      }
    })
    .catch((err) => {
      handleUnexpectedError(
        'An unexpected error occurred while selecting the goal',
        'GoalAction.default',
        err
      );
      dispatch(selectGoalError());
    });
};
