/**
 * Component to handle questions that requires a radio button input from the user
 */

// Core
import React, { Component } from 'react';
// Components
import { Col, Row, Radio } from 'antd/lib/index';
import RadioButton from '../../../../../components/wrapped/RadioButton';
import AssessmentSkeleton from '../../../skeleton';
// hocs
import Aux from '../../../../../components/layout/Aux';
// assets
import colors from '../../../../../assets/data/colorList';
// styles
import styles from './RadioType.module.scss';

const RadioGroup = Radio.Group;

class RadioType extends Component {
  state = { selectedValue: null };

  // Render options by mapping the data.choices prop
  _renderOptions = () => {
    if (this.props.data) {
      return this.props.data.choices.map((choice, index) => (
        <RadioButton
          testId={`option-${index + 1}`}
          key={choice.text}
          value={choice.text}
          score={choice.score}
        >
          {choice.text}
        </RadioButton>
      ));
    }
    return null;
  };

  // Reset the selected value on change and execute the onSelect prop
  // with selected score and value
  _handleChange = (e) => {
    this.setState({ selectedValue: null });
    this.props.onSelect({
      score: e.target.score,
      value: e.target.value
    });
  };

  render() {
    const {
      radioGroup,
      timeRangeBox,
      timeRangeQuote,
      questionBox,
      imageStyle
    } = styles;
    const { avatar, data } = this.props;

    const path = avatar ? avatar.path : '';
    const alt = avatar ? avatar.value : '';
    const text = data ? data.text : '';

    return (
      <Aux>
        <Row>
          <Col xs={24} md={13}>
            {this.props.isFetching ? (
              <AssessmentSkeleton type="questions" />
            ) : (
              <Row>
                <Row>
                  <Col span={2} offset={1}>
                    {data.questionContext.trim().length > 0 && path && (
                      <img
                        src={path}
                        alt={alt}
                        height="75"
                        width="75"
                        className={imageStyle}
                      />
                    )}
                  </Col>
                  <Col
                    xs={avatar ? { span: 16, offset: 5 } : { span: 24 }}
                    md={{ span: 18, offset: 3 }}
                  >
                    {data.questionContext.trim().length > 0 && (
                      <p className={avatar ? timeRangeQuote : timeRangeBox}>
                        {data.questionContext}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={{ span: 24 }}
                    md={{
                      span: 18,
                      offset: 6
                    }}
                  >
                    <p
                      data-testid="survey-question-text"
                      className={questionBox}
                      style={{ backgroundColor: colors[data && data.type] }}
                    >
                      {text}
                    </p>
                  </Col>
                </Row>
              </Row>
            )}
          </Col>
          <Col
            xs={{
              span: 24
            }}
            md={{
              span: 10,
              offset: 1
            }}
            style={
              data.questionContext.trim().length === 0 ? { marginTop: 20 } : {}
            }
          >
            {this.props.isFetching ? (
              <AssessmentSkeleton type="radiogroup" />
            ) : (
              <RadioGroup
                value={this.state.selectedValue}
                className={radioGroup}
                name="answer"
                onChange={this._handleChange}
              >
                {this._renderOptions()}
              </RadioGroup>
            )}
          </Col>
        </Row>
      </Aux>
    );
  }
}

export default RadioType;
