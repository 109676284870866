import { httpMethod, initializeApi } from './index';
import config from '../config';

const API_BASE_URL = config.api.url;
const ASSESSMENT_VERSION_MAP = config.assessmentVersionMap;

const fetchFromApi = initializeApi(API_BASE_URL);

// Api class that contains all our request endpoints
class AssessmentApi {
  fetchQuestions = () =>
    fetchFromApi({
      endpoint: `questionnaires?assessmentVersionMap=${encodeURIComponent(
        ASSESSMENT_VERSION_MAP
      )}`,
      productExpireCheck: true
    });

  fetchChatQuestions = (assessmentId) =>
    fetchFromApi({
      endpoint: `questionnaires/${assessmentId}?assessmentVersion=${encodeURIComponent(
        JSON.parse(ASSESSMENT_VERSION_MAP)[assessmentId]
      )}`,
      productExpireCheck: true
    });

  processChatQuestions = (assessmentId, responses, state) =>
    fetchFromApi({
      endpoint: `questionnaires/${assessmentId}`,
      method: httpMethod.POST,
      data: {
        assessmentVersion: JSON.parse(ASSESSMENT_VERSION_MAP)[assessmentId],
        responses,
        state
      }
    });

  // Processes the result
  fetchResult = (responses, assessmentId) =>
    fetchFromApi({
      endpoint: 'reports',
      method: httpMethod.POST,
      data: {
        data: responses,
        assessmentId,
        assessmentVersion: JSON.parse(ASSESSMENT_VERSION_MAP)[assessmentId]
      }
    });

  getLatestSelectedGoal = () =>
    fetchFromApi({
      endpoint: 'reports?latestGoal=true'
    });

  // Select the goal for a reportId
  selectGoal = ({ reportId, selectedGoal, selectedGoalOption }) =>
    fetchFromApi({
      endpoint: `reports/${reportId}`,
      method: httpMethod.PATCH,
      data: {
        selectedGoal,
        selectedGoalOption
      }
    });

  updateReportTime = ({ reportId, timestamp }) =>
    fetchFromApi({
      endpoint: `reports/${reportId}`,
      method: httpMethod.PATCH,
      data: { timestamp }
    });

  updateMultipleReportsTime = ({ timestamp }) =>
    fetchFromApi({
      endpoint: 'reports',
      method: httpMethod.PATCH,
      data: {
        timestamp
      }
    });

  // Gets the list of previous reports
  getReportList = () =>
    fetchFromApi({
      endpoint: 'reports'
    });

  // Gets a specific report
  getReport = (id) =>
    fetchFromApi({
      endpoint: `reports/${id}`
    });

  updateProfile = (data) =>
    fetchFromApi({
      endpoint: 'profile',
      method: httpMethod.PATCH,
      data
    });

  setOnBoardFlag = () =>
    fetchFromApi({
      endpoint: 'onboard',
      method: httpMethod.PATCH
    });

  getProgressId = (assessmentId, userResponse) =>
    fetchFromApi({
      endpoint: 'progress',
      method: httpMethod.POST,
      data: {
        assessmentId,
        userResponse,
        clientTimeStamp: new Date().getTime()
      },
      authRequired: true,
      retryRequired: true
    });

  saveAssessmentsProgress = (response, progressId, isCompleted, state) =>
    fetchFromApi({
      endpoint: `progress/${progressId}`,
      method: httpMethod.PATCH,
      data: {
        userResponse: response,
        isCompleted,
        clientTimeStamp: new Date().getTime(),
        state
      }
    });

  // Currently source is hard-coded because we only have assessment source.
  suicidalAlertNotification = () =>
    fetchFromApi({
      endpoint: 'notification?type=suicidalideationalert',
      method: httpMethod.POST,
      data: { source: 'assessment' }
    });

  // TODO: Client reports for coach needs to be fetched from graphQL in future.
  getClientReport = (userId) =>
    fetchFromApi({
      endpoint: `reports?user=${encodeURIComponent(userId)}&type=coach`
    });
}

// Export as new so the same instance is shared across modules
export default new AssessmentApi();
