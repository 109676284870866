import React, { useRef } from 'react';
import { isEmpty, debounce } from 'lodash';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import classNamesInstance from 'classnames/bind';
import propTypes from 'prop-types';
import { supportedSteps } from 'assets/data/self-use/thoughtRecorder';
import styles from './StepsAccordion.module.scss';
import useStyles from './StepsAccordion.styles';
import { stepsComponent, getStepsProgress } from './StepsAccordion.utils';

const {
  wrapper,
  stepProgressContainer,
  stepProgressItem,
  progressSummaryStyle,
  current,
  disabled,
  completed
} = styles;

const progressStates = [disabled, current, completed];
const classNames = classNamesInstance.bind(styles);

const StepsAccordion = ({
  stepsData,
  urlParams,
  currentProgress,
  setCurrentProgress,
  setStepsData,
  onChange,
  autoScrollRef
}) => {
  const classes = useStyles();
  const stepsRef = useRef({});

  const {
    root,
    summaryRoot,
    summaryContent,
    detailsRoot,
    expandIcon,
    expandIconWithProgress,
    expanded
  } = classes;

  const scrollToStep = debounce(({ ref }) => {
    ref.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, 500);

  const getStepSummary = ({ type, isActive, header, progressSummary }) => {
    const headerClass = classNames({
      stepHeaderActiveStyle: isActive,
      stepHeaderStyle: !isActive
    });
    return (
      <div data-testid={`step-summary-${type}`}>
        <h3 className={headerClass}>{header}</h3>
        {isActive === false && (
          <p
            data-testid="thought_recorder_progress_summary"
            className={progressSummaryStyle}
          >
            {progressSummary}
          </p>
        )}
      </div>
    );
  };

  return (
    <div data-testid="steps-accordion-wrapper" className={wrapper}>
      <ul className={stepProgressContainer}>
        {!isEmpty(stepsData) &&
          Object.keys(stepsData).map((type) => {
            const {
              id: stepId,
              header,
              progressSummary,
              isDisabled,
              isActive
            } = stepsData[type];

            if (type && stepsComponent[type]) {
              const StepContent = stepsComponent[type];
              const columns =
                type === supportedSteps.AUTOMATIC_THOUGHTS ? 24 : 12;
              return (
                <li
                  key={stepId}
                  className={`${stepProgressItem} ${
                    progressStates[getStepsProgress({ isDisabled, isActive })]
                  }`}
                >
                  <span
                    ref={(el) => {
                      stepsRef.current[type] = el;
                    }}
                  >
                    <Accordion
                      data-testid={`step-accordion-${type}`}
                      disabled={isDisabled}
                      expanded={isActive}
                      square
                      classes={{ root }}
                      onChange={(event, isExpanded) => {
                        onChange({ type, expanded: isExpanded });
                        scrollToStep({ ref: stepsRef.current[type] });
                      }}
                    >
                      <AccordionSummary
                        data-testid={`thought_recorder_${type}_heading`}
                        classes={{
                          root: summaryRoot,
                          content: summaryContent,
                          expandIcon: currentProgress[type]
                            ? expandIconWithProgress
                            : expandIcon,
                          expanded
                        }}
                        expandIcon={
                          <EditIcon
                            data-testid={`edit-step-${type}`}
                            fontSize="small"
                          />
                        }
                      >
                        {getStepSummary({
                          type,
                          isActive,
                          header,
                          progressSummary
                        })}
                      </AccordionSummary>
                      <AccordionDetails classes={{ root: detailsRoot }}>
                        <div data-testid={`steps-content-${type}`}>
                          <StepContent
                            columns={columns}
                            urlParams={urlParams}
                            stepId={stepId}
                            type={type}
                            currentProgress={currentProgress}
                            setCurrentProgress={setCurrentProgress}
                            stepsData={stepsData}
                            setStepsData={setStepsData}
                          />
                          {isActive && <div ref={autoScrollRef} />}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </span>
                </li>
              );
            }
            return null;
          })}
      </ul>
    </div>
  );
};

StepsAccordion.propTypes = {
  urlParams: propTypes.shape({
    goalId: propTypes.string,
    sessionId: propTypes.string,
    screenId: propTypes.string
  }),
  currentProgress: propTypes.objectOf(
    propTypes.oneOfType([
      propTypes.arrayOf(propTypes.object),
      propTypes.string // __typename
    ])
  ),
  stepsData: propTypes.objectOf(
    propTypes.shape({
      data: propTypes.shape({
        allowCustom: propTypes.bool,
        customOptionPlaceholder: propTypes.string,
        options: propTypes.arrayOf(propTypes.shape({})),
        maxOptions: propTypes.number
      })
    })
  ),
  setCurrentProgress: propTypes.func,
  setStepsData: propTypes.func,
  onChange: propTypes.func
};

StepsAccordion.defaultProps = {
  urlParams: {},
  currentProgress: {},
  stepsData: {},
  setCurrentProgress: () => null,
  setStepsData: () => null,
  onChange: () => null
};

export default React.memo(StepsAccordion);
