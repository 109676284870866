/**
 *
 * GoalDetail
 *
 */

import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useMutation } from '@apollo/react-hooks';
import logger from 'utils/logger';
import analytics from 'utils/analytics';
import { closeModal } from 'store/actions/ModalAction';
import config from 'config';
import categories from 'utils/analytics/categories';
import { ActionButton } from '../../../../components/ui/Buttons';
import RenderHtml from '../../../../components/RenderHtml';
import Lang from '../../../../components/Lang';
import toast from '../../../../components/Toast';
import { GET_SELECTED_GOAL_INFO } from '../../queries';
import { UPSERT_USER_GOAL } from '../../mutations';
import { getSelectedGoalInfoOptions } from '../../mutationOptions';
import styles from './GoalDetail.module.scss';

const { cdnBaseUrl, supportEmail } = config;
const {
  topContainer,
  bottomContainer,
  startTextStyle,
  imageStyle,
  goalDetailTextStyle,
  subHeaderStyle,
  descriptionStyle
} = styles;

const GoalDetail = ({ goalDetails, selectedGoalRef }) => {
  const {
    totalSessions,
    name,
    imageUrl: { modal, modalMobile },
    description: { html },
    goalId,
    version
  } = goalDetails;

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const isSmallScreen = useMediaQuery('(max-width: 575px)');

  const [upsertUserGoal] = useMutation(UPSERT_USER_GOAL, {
    refetchQueries: [{ query: GET_SELECTED_GOAL_INFO }]
  });

  const _switchGoalHandler = useCallback(
    async (id) => {
      setIsLoading(true);
      analytics.track(categories.SELF_USE, 'clicked start goal button', {
        source: categories.SELF_USE,
        trigger: 'select_goal',
        goalId: id
      });
      upsertUserGoal(
        getSelectedGoalInfoOptions({
          query: GET_SELECTED_GOAL_INFO,
          id,
          version
        })
      )
        .then((res) => {
          logger.info(
            'Successfully upsert selectedGoal',
            'GoalDetail._switchGoalHandler',
            { res, goalId: id }
          );
        })
        .catch((error) => {
          logger.error(
            'Unable to upsert the selectedgoal.',
            'GoalDetail._switchGoalHandler',
            { error, id }
          );
          toast.error(
            <Lang
              path="selfUse.goalList.upsertSelectedGoalErrorText"
              values={{ supportEmail }}
            />,
            null,
            {
              autoClose: 30000
            }
          );
        });
      await dispatch(closeModal());
      selectedGoalRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });
    },
    [dispatch, selectedGoalRef, upsertUserGoal, version]
  );

  return (
    <div>
      <div className={topContainer}>
        <img
          data-testid="goal-thumbnail-on-popup"
          className={imageStyle}
          src={`${cdnBaseUrl}${isSmallScreen ? modalMobile : modal}`}
          alt={name}
        />
        <div className={goalDetailTextStyle}>
          <span data-testid="self-guided-text-on-popup">
            <Lang path="selfUse.goalList.selfGuidedText" />
          </span>
          <span
            data-testid="goal-name-text-on-popup"
            className={subHeaderStyle}
          >
            {name}
          </span>
          <span data-testid="total-sessions-text-on-popup">
            <Lang
              path="selfUse.goalList.sessionsText"
              values={{
                totalSessions
              }}
            />
          </span>
        </div>
      </div>
      <div className={bottomContainer}>
        <p
          data-testid="goal-description-text-on-popup"
          className={descriptionStyle}
        >
          <RenderHtml>{html}</RenderHtml>
        </p>
        <ActionButton
          testId="start-now-button-on-popup"
          className={startTextStyle}
          onClick={() => _switchGoalHandler(goalId)}
          isLoading={isLoading}
        >
          <Lang path="selfUse.goalList.startText" />
        </ActionButton>
      </div>
    </div>
  );
};

export default GoalDetail;
