import gql from 'graphql-tag';

const UPSERT_USER_GOAL = gql`
  mutation upsertUserGoals($id: String!, $version: String!) {
    upsertUserGoals(input: { id: $id, version: $version }) {
      message
    }
  }
`;

const UPSERT_SESSION_PROGRESS = gql`
  mutation upsertSessionProgress(
    $id: ID
    $currentDuration: Int
    $goalId: String!
    $screenId: String!
    $screenType: String!
    $contentType: String
    $sessionId: String!
    $source: String!
    $version: String!
    $isSessionCompleted: Boolean!
    $isScreenCompleted: Boolean!
    $trackerType: String
    $trackerValue: Int
    $options: [ChecklistOptionInput]
    $stepProgress: StepProgressInput
    $textInputs: [TextInputScreenInput]
    $situations: [SituationProgressInput]
  ) {
    upsertSessionProgress(
      input: {
        goalId: $goalId
        sessionId: $sessionId
        screenId: $screenId
        screenType: $screenType
        contentType: $contentType
        source: $source
        goalVersion: $version
        data: {
          id: $id
          currentDuration: $currentDuration
          trackerValue: $trackerValue
          trackerType: $trackerType
          options: $options
          stepProgress: $stepProgress
          textInputs: $textInputs
          situations: $situations
        }
        isSessionCompleted: $isSessionCompleted
        isScreenCompleted: $isScreenCompleted
      }
    ) {
      session {
        goalId
        screenId
        screenType
        contentType
        sessionId
        source
        data {
          id
          currentDuration
          trackerValue
          trackerType
          options {
            optionId
            isSelected
            isCustom
            text
            type
            updatedAt
            createdAt
          }
          textInputs {
            text
            createdAt
            isCustom
          }
          stepProgress {
            automaticThoughts {
              optionId
              text
              isSelected
              isCustom
              updatedAt
              createdAt
              stepId
            }
            emotions {
              optionId
              isSelected
              isCustom
              updatedAt
              createdAt
              stepId
            }
            category {
              optionId
              isSelected
              isCustom
              updatedAt
              createdAt
              stepId
            }
            challenge {
              optionId
              isSelected
              isCustom
              updatedAt
              createdAt
              stepId
            }
            rationalResponse {
              text
              createdAt
              stepId
              isCustom
            }
          }
        }
      }
    }
  }
`;

export { UPSERT_USER_GOAL, UPSERT_SESSION_PROGRESS };
