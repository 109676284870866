/**
 * Component to handle questions that requires a rating input from the user
 */

// Core
import React, { Component } from 'react';
// Components
import { Col, Row, Radio } from 'antd/lib/index';
import RadioRating from '../../../../../components/wrapped/RadioRating';

// styles
import styles from './RatingType.module.scss';

const RadioGroup = Radio.Group;

class RatingType extends Component {
  state = { selectedValue: null };

  // Render options by mapping the data.choices prop
  _renderOptions = () => {
    if (this.props.data) {
      return this.props.data.choices.map((choice, index) => (
        <RadioRating
          testId={`option-${index + 1}`}
          key={choice.text}
          value={choice.text}
          score={choice.score}
        >
          {choice.text}
        </RadioRating>
      ));
    }
    return null;
  };

  // Reset the selected value on change and execute the onSelect prop
  // with selected score and value
  _handleChange = (e) => {
    this.setState({ selectedValue: null });
    this.props.onSelect({
      score: e.target.score,
      value: e.target.value
    });
  };

  render() {
    const { question, optionsContainer } = styles;

    const { data } = this.props;

    const text = data ? data.text : '';

    return (
      <Row>
        <Row>
          <Col xs={24}>
            <p className={question}>{text}</p>
          </Col>
        </Row>
        <Row className={optionsContainer}>
          <Col span={24}>
            <RadioGroup
              value={this.state.selectedValue}
              name="answer"
              onChange={this._handleChange}
            >
              {this._renderOptions()}
            </RadioGroup>
          </Col>
        </Row>
      </Row>
    );
  }
}

export default RatingType;
