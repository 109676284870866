import { useState, useEffect } from 'react';
import { Network } from '@capacitor/network';
import logger from '../logger';

export const useNetworkListener = () => {
  const [isOnline, setIsOnline] = useState(true);

  // To set isOnline initial state
  useEffect(() => {
    (async () => {
      const status = await Network.getStatus();
      setIsOnline(status.connected);
    })();
  }, []);

  useEffect(() => {
    const networkListener = Network.addListener(
      'networkStatusChange',
      (status) => {
        logger.info('Network status changed', 'useNetworkListener.useEffect', {
          status
        });
        setIsOnline(status.connected);

        if (!status.connected) {
          document.activeElement.blur();
        }
      }
    );

    return () => {
      networkListener.remove();
    };
  }, []);

  return [isOnline, setIsOnline];
};
