import {
  GET_REGISTRATION_FIELDS,
  GET_REGISTRATION_FIELDS_DONE,
  GET_REGISTRATION_FIELDS_ERROR
} from '../actions/types';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  data: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REGISTRATION_FIELDS:
      return {
        ...state,
        isFetching: true,
        hasError: false
      };
    case GET_REGISTRATION_FIELDS_DONE:
      return {
        ...state,
        data: action.payload,
        isFetching: false
      };
    case GET_REGISTRATION_FIELDS_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true
      };
    default:
      return state;
  }
};
