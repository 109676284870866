/**
 *
 * In-Person Therapy Request start
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// pick utils

import requestInPersonTherapy, {
  clearInPersonTherapyStatusError
} from 'store/actions/dashboard/InPersonTherapyRequestAction';
import analytics from 'utils/analytics';
import helpers from 'utils/helpers';

import config from 'config';
import InPersonTherapyRequestForm from '../../../forms/InPersonTherapyRequest';

class InPersonTherapyRequestStart extends React.Component {
  componentDidMount() {
    this.props.actions.clearInPersonTherapyStatusError();
  }

  _handleSubmit = (params) => {
    analytics.track('modal request in-person therapy', 'clicked submit', {
      goalId: this.props.goalId,
      severity: this.props.severity
    });

    const { date1, date2, date3, date4, ...rest } = params;
    const appointments = [helpers.getLocalTime(date1)];
    if (date2) {
      appointments.push(helpers.getLocalTime(date2));
    }
    if (date3) {
      appointments.push(helpers.getLocalTime(date3));
    }
    if (date4) {
      appointments.push(helpers.getLocalTime(date4));
    }
    const paramsToSend = { ...rest, appointments };

    delete paramsToSend.companyName;

    const { profile } = this.props;

    paramsToSend.companyName = profile.employer_id || profile.company_name;

    paramsToSend.isEmailAllowed = params.isEmailAllowed === true;
    paramsToSend.isVoiceMailAllowed = params.isVoiceMailAllowed === true;
    paramsToSend.source = config.requestManagementSource;
    this.props.actions.requestInPersonTherapy(paramsToSend);
  };

  render() {
    const {
      profile,
      inPersonTherapy: {
        request: { isFetching, hasError }
      }
    } = this.props;
    return (
      <InPersonTherapyRequestForm
        onSubmit={this._handleSubmit}
        initialValues={{
          email: profile.email,
          fullName:
            profile.name || `${profile.first_name} ${profile.last_name}`,
          companyName: profile.employer_id || profile.company_name,
          date1: null,
          date2: null,
          date3: null,
          date4: null,
          phone: profile.phone
        }}
        isFetching={isFetching}
        hasError={hasError}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const {
    profile: { profile },
    inPersonTherapy,
    dashBoard: { goalId, severity }
  } = state;
  return {
    profile,
    inPersonTherapy,
    goalId,
    severity
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    requestInPersonTherapy: bindActionCreators(
      requestInPersonTherapy,
      dispatch
    ),
    clearInPersonTherapyStatusError: bindActionCreators(
      clearInPersonTherapyStatusError,
      dispatch
    )
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InPersonTherapyRequestStart);
