/**
 * For Rendering raw html safely
 */

// Core
import React from 'react';
import sanitizeHtml from 'sanitize-html';

/* eslint-disable react/no-danger */

const RenderHtml = ({ children }) => (
  <span
    dangerouslySetInnerHTML={{
      __html: sanitizeHtml(children, {
        allowedSchemes: ['tel', 'http', 'https', 'mailto']
      })
    }}
  />
);

/* eslint-enable react/no-danger */

export default RenderHtml;
