import React from 'react';
import videoPlaceHolderImg from 'assets/img/videoPlaceHolderImg.png';
import { MediaCard } from '../../../../components/Coaching';

import styles from './MediaMessage.module.scss';

const { container, mediaCardContainer } = styles;

const MediaMessage = ({ isUser, content, ...rest }) => (
  <div data-testid="media-message-container" className={container}>
    {content &&
      content.length > 0 &&
      content.map((mediaContent) => (
        <div key={mediaContent.id} className={mediaCardContainer}>
          <MediaCard
            {...mediaContent}
            thumbnail={videoPlaceHolderImg}
            isSelectable={false}
            {...rest}
          />
        </div>
      ))}
  </div>
);
export default MediaMessage;
