import financialImg from 'assets/img/dashboard/resources/financial.png';
import worklifeServicesImg from 'assets/img/dashboard/resources/worklife-services.png';
import i18n from 'assets/lang';

export default [
  {
    id: 'work-life-service',
    name: 'Work life service',
    header: i18n.t('resources.workLifeServices.services.therapy.header'),
    description: i18n.t(
      'resources.workLifeServices.services.therapy.description'
    ),
    buttonText: i18n.t(
      'resources.workLifeServices.services.therapy.buttonText'
    ),
    url: 'https://www.resourcesforliving.com/home/services/worklife-services?txtUserName=wfbstaff&txtPassword=staff',
    img: worklifeServicesImg
  },
  {
    id: 'legal-service',
    name: 'Legal service',
    header: i18n.t('resources.workLifeServices.services.legal.header'),
    description: i18n.t(
      'resources.workLifeServices.services.legal.description'
    ),
    buttonText: i18n.t('resources.workLifeServices.services.legal.buttonText'),
    url: 'https://www.resourcesforliving.com/home/services/legal-and-financial-services?txtUserName=wfbstaff&txtPassword=staff',
    img: financialImg
  }
];
