import config from '../config';

import { initializeApi, httpMethod } from './index';

const NOTIFICATION_API_BASE_URL = config.notification.url;

const fetchFromApi = initializeApi(NOTIFICATION_API_BASE_URL);

// Api class that contains all the notification api endpoints
class NotificationAPI {
  registerDevice = (params) => {
    const data = {
      ...params,
      appName: config.appId,
      brandId: config.brand
    };

    return fetchFromApi({
      endpoint: 'push/device',
      method: httpMethod.POST,
      data,
      retryRequired: true,
      authRequired: false,
      apiKey: config.notification.apiKey
    });
  };

  updateBadgeCount = ({ badgeCount, deviceId }) =>
    fetchFromApi({
      endpoint: 'push/badge',
      method: httpMethod.PATCH,
      data: {
        badgeCount,
        deviceId
      },
      retryRequired: true,
      authRequired: false,
      apiKey: config.notification.apiKey
    });
}

export default new NotificationAPI();
