import config from '../config';

import { initializeApi, httpMethod } from './index';

const USER_API_BASE_URL = config.user.url;

const fetchFromApi = initializeApi(USER_API_BASE_URL);

// Api class that contains all our user api endpoints
class UserAPI {
  updateActivity = (payload) =>
    fetchFromApi({
      endpoint: 'activity',
      method: httpMethod.PATCH,
      data: payload
    });

  updatePassword = (payload) =>
    fetchFromApi({
      endpoint: 'password',
      method: httpMethod.PUT,
      data: payload
    });
}

export default new UserAPI();
