import update from 'immutability-helper';
import _ from 'lodash';

const getSendChatMessageOptions = ({ id, message, to, from, query }) => ({
  variables: { text: message, id },
  context: {
    serializationKey: 'sendChatMessage'
  },
  optimisticResponse: {
    __typename: 'Mutation',
    sendChatMessage: {
      id,
      from,
      to,
      content: [],
      message: {
        text: message,
        coachDesignation: null,
        coachAbout: null,
        header: null,
        body: null,
        __typename: 'MessageContent'
      },
      type: 'text',
      readFlag: false,
      createdAt: new Date(),
      __typename: 'Message'
    }
  },
  update: (proxy, { data: { sendChatMessage } }) => {
    // Read the data from our cache for this query.
    const proxyData = proxy.readQuery({ query });

    const data = update(proxyData, {
      getChatMessages: {
        messages: { $push: [sendChatMessage] }
      }
    });
    // Write our data back to the cache with the new comment in it
    proxy.writeQuery({ query, data });
  }
});

const getCoachSendChatMessageOptions = ({
  id,
  message,
  to,
  from,
  media,
  query,
  suggestions,
  contentVersionMap
}) => {
  const mediaIdsList =
    media.length > 0 ? media.map((mediaItem) => mediaItem.id) : [];
  const optimisticChatMessage = {
    id,
    from,
    to,
    message: {
      text: message,
      coachDesignation: null,
      coachAbout: null,
      header: null,
      body: null,
      __typename: 'MessageContent'
    },
    content: [],
    type: 'text',
    readFlag: false,
    createdAt: new Date(),
    __typename: 'Message'
  };

  if (!_.isEmpty(media)) {
    const optimisticContent = [];
    for (let i = 0; i < media.length; i += 1) {
      optimisticContent[i] = { ...media[i], __typename: 'MediaContent' };
    }
    optimisticChatMessage.type = 'content';
    optimisticChatMessage.content = optimisticContent;
  }
  const variables = {
    text: message,
    id,
    to,
    from,
    media: mediaIdsList,
    contentVersionMap
  };
  if (!_.isEmpty(suggestions)) {
    optimisticChatMessage.suggestions = { ...suggestions };
    variables.suggestions = { ...suggestions };
  }

  return {
    variables,
    context: {
      serializationKey: 'MUTATION'
    },
    optimisticResponse: {
      __typename: 'Mutation',
      sendChatMessage: optimisticChatMessage
    },
    update: (proxy, { data: { sendChatMessage } }) => {
      // Read the data from our cache for this query.
      const proxyData = proxy.readQuery({ query, variables: { to, from } });
      const newQuery = {
        query,
        variables: { to, from },
        data: {
          ...proxyData,
          getChatMessages: {
            ...proxyData.getChatMessages,
            messages: _.concat(
              proxyData.getChatMessages.messages,
              sendChatMessage
            )
          }
        }
      };
      // Write our data back to the cache
      proxy.writeQuery(newQuery);
    }
  };
};

const getUpdateChatMessageReadOptions = ({
  id,
  to,
  from,
  query,
  isCoach = false
}) => ({
  variables: { id },
  optimisticResponse: {
    __typename: 'Mutation',
    updateChatMessageRead: {
      id,
      readFlag: true,
      to,
      from,
      __typename: 'Message'
    }
  },
  update: (proxy, { data: { updateChatMessageRead } }) => {
    // Read the data from our cache for this query.
    const queryObject = { query };
    if (isCoach) {
      queryObject.variables = { to: from, from: to };
    }
    const proxyData = proxy.readQuery(queryObject);

    const messageIndexToUpdate = proxyData.getChatMessages.messages.findIndex(
      (message) => message.id === id
    );

    const data = update(proxyData, {
      getChatMessages: {
        messages: {
          [messageIndexToUpdate]: { $merge: updateChatMessageRead }
        }
      }
    });
    queryObject.data = data;
    // Update the cache with read flag updated
    proxy.writeQuery(queryObject);
  }
});

export default {
  getSendChatMessageOptions,
  getCoachSendChatMessageOptions,
  getUpdateChatMessageReadOptions
};
