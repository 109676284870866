import React from 'react';

import goalIconList, { goalIcon } from 'assets/data/goalIconList';
import styles from './ClientAssessmentsItemContent.module.scss';

const ClientAssessmentsItemContent = ({ name, level, category }) => {
  const {
    panelStyle,
    container,
    indicatorStyle,
    goalImageStyle,
    goalNameStyle
  } = styles;

  return (
    <div data-testid="goal-item" className={[panelStyle, container].join(' ')}>
      <span className={[indicatorStyle, level].join(' ')} />
      <img
        className={goalImageStyle}
        src={goalIconList[category] || goalIcon}
        alt={category}
      />
      <span data-testid="goal-name" className={goalNameStyle}>
        {name}
      </span>
    </div>
  );
};

export default ClientAssessmentsItemContent;
