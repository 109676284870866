import React from 'react';
import ContentContainer from 'components/layout/ContentContainer';
import styles from './Accessibility.module.scss';

const { container } = styles;

const Accessibility = () => (
  <ContentContainer size="large" className={container}>
    <h1>DarioHealth Corp Accessibility Statement</h1>
    <div>
      <br />
      <br />
      Updated: January 2022
      <br />
      <br />
      <h2>General</h2>
      DarioHealth Corp strives to ensure that its services are accessible to
      people with disabilities. DarioHealth Corp has invested a significant
      amount of resources to help ensure that its website is made easier to use
      and more accessible for people with disabilities, with the strong belief
      that every person has the right to live with dignity, equality, comfort
      and independence.
      <br />
      <br />
      <h2>Accessibility on https://my.wayforward.io/</h2>
      https://my.wayforward.io/ makes available the{' '}
      <a
        href="https://UserWay.org"
        alt="Free Website Accessibility Widget"
        title="Free Website Accessibility Widget"
      >
        UserWay Website Accessibility Widget
      </a>{' '}
      that is powered by a dedicated accessibility server. The software allows
      Https://my.wayforward.io/ to improve its compliance with the Web Content
      Accessibility Guidelines (WCAG 2.1).
      <br />
      <br />
      <h2>Enabling the Accessibility Menu</h2>
      The https://my.wayforward.io/ accessibility menu can be enabled either by
      hitting the tab key when the page first loads or by clicking the
      accessibility menu icon that appears on the corner of the page. After
      triggering the accessibility menu, please wait a moment for the
      accessibility menu to load in its entirety.
      <br />
      <br />
      <h2>Disclaimer</h2>
      DarioHealth Corp continues its efforts to constantly improve the
      accessibility of its site and services in the belief that it is our
      collective moral obligation to allow seamless, accessible and unhindered
      use also for those of us with disabilities.
      <br />
      <br />
      In an ongoing effort to continually improve and remediate accessibility
      issues, we also regularly scan https://my.wayforward.io/ with
      UserWay&apos;s{' '}
      <a
        href="https://UserWay.org/scanner"
        title="Free Website Accessibility Scanner"
      >
        Accessibility Scanner
      </a>{' '}
      to identify and fix every possible accessibility barrier on our site.
      Despite our efforts to make all pages and content on
      https://my.wayforward.io/ fully accessible, some content may not have yet
      been fully adapted to the strictest accessibility standards. This may be a
      result of not having found or identified the most appropriate
      technological solution.
      <br />
      <br />
      <h2>Here For You</h2>
      If you are experiencing difficulty with any content on
      https://my.wayforward.io/ or require assistance with any part of our site,
      please contact us during normal business hours as detailed below and we
      will be happy to assist.
      <br />
      <br />
      <h2>Contact Us</h2>
      If you wish to report an accessibility issue, have any questions or need
      assistance, please contact DarioHealth Corp Customer Support as follow:
      <br />
      <br />
      Email: <a href="mailto:support@wayforward.io">support@wayforward.io</a>
      <br />
      <br />
    </div>
  </ContentContainer>
);

export default Accessibility;
