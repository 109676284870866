import React, { Fragment, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Paper } from '@material-ui/core';
import analytics from 'utils/analytics';
import { showModal } from 'store/actions/ModalAction';
import config from 'config';
import { OutlineButton } from '../../../../../components/ui/Buttons';
import Lang from '../../../../../components/Lang';
import GoalDetail from '../../../popups/GoalDetail';
// styles
import styles from './Card.module.scss';

const {
  cardContainer,
  imageStyle,
  buttonStyle,
  buttonContainer,
  goalDescriptionContainer,
  selfGuidedTextContainer,
  goalNameTextStyle,
  selfGuidedTextStyle
} = styles;

const { cdnBaseUrl } = config;

const Card = ({ goal, currentGoal, selectedGoalRef }) => {
  const { totalSessions, name, goalId, imageUrl: { carousel } = '' } = goal;
  const elementId = name.toLowerCase().replace(' ', '-');
  const dispatch = useDispatch();

  const _handleGoalDetailClick = useCallback(
    ({ goalDetails }) => {
      analytics.track('self-use', 'clicked view details');
      dispatch(
        showModal(GoalDetail, {
          maxWidth: 600,
          source: 'goal-detail',
          props: {
            goalDetails,
            selectedGoalRef
          }
        })
      );
    },
    [dispatch, selectedGoalRef]
  );
  const sliderClass = 'keen-slider__slide';
  return (
    <Fragment>
      {goalId !== currentGoal && (
        <Paper className={`${cardContainer} ${sliderClass}`}>
          <div
            data-testid={`img-${elementId}`}
            className={imageStyle}
            style={{ backgroundImage: `url(${cdnBaseUrl}${carousel})` }}
          />
          <div className={goalDescriptionContainer}>
            <div className={selfGuidedTextContainer}>
              <span
                data-testid={`self-guided-text-${elementId}`}
                className={selfGuidedTextStyle}
              >
                <Lang path="selfUse.goalList.selfGuidedText" />
              </span>
              <span
                data-testid={`other-goal-text-${elementId}`}
                className={goalNameTextStyle}
              >
                {name}
              </span>
              <span data-testid={`total-sessions-text-${elementId}`}>
                <Lang
                  path="selfUse.goalList.sessionsText"
                  values={{
                    totalSessions
                  }}
                />
              </span>
            </div>
            <div className={buttonContainer}>
              <OutlineButton
                testId={`request-button-${elementId}`}
                className={buttonStyle}
                onClick={() =>
                  _handleGoalDetailClick({
                    goalDetails: goal
                  })
                }
              >
                <Lang path="selfUse.goalList.viewDetailsText" />
              </OutlineButton>
            </div>
          </div>
        </Paper>
      )}
    </Fragment>
  );
};

export default Card;
