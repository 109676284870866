import React from 'react';
import _ from 'lodash';

import styles from './ToolItemContent.module.scss';

const {
  toolItemContainer,
  textStyle,
  boldStyle,
  headerStyle,
  rowStyle,
  cellStyle,
  labelItemContainer,
  labelStyle,
  listStyle
} = styles;

const ToolItemContent = ({ data }) => {
  const DisplayNameValue = ({ toolData }) => (
    <div className={rowStyle}>
      <div className={[textStyle, cellStyle].join(' ')}>
        {toolData.displayName}
      </div>
      <div className={[textStyle, cellStyle].join(' ')}>:</div>
      <div className={[textStyle, cellStyle].join(' ')}>{toolData.value}</div>
    </div>
  );

  const DisplayNameCount = ({ toolData }) => (
    <div className={labelItemContainer}>
      <span className={textStyle}>{toolData.displayName}</span>
      <span className={labelStyle}>{toolData.count}</span>
    </div>
  );

  const HeadingDisplayNameCount = ({ toolData }) => (
    <>
      <div className={[boldStyle, textStyle].join(' ')}>
        {toolData.heading} :
      </div>
      <DisplayNameCount toolData={toolData} />
    </>
  );

  const DisplayNameCountListItem = ({ toolData }) => (
    <li>
      <DisplayNameCount toolData={toolData} />
    </li>
  );

  const DisplayNameListItem = ({ toolData }) => (
    <li className={textStyle}>{toolData.displayName}</li>
  );

  const DisplayNameParent = ({ toolData, jsx }) => (
    <>
      <div className={[headerStyle, boldStyle, textStyle].join(' ')}>
        {toolData.displayName}
      </div>
      {jsx}
    </>
  );

  const _renderData = (toolData) => {
    const [sample] = toolData;
    let Component;
    let isListItem = false;

    if (!_.isEmpty(sample.data)) {
      return (
        <>
          {toolData.map((toolChild, index) => (
            <DisplayNameParent
              toolData={toolChild}
              jsx={_renderData(toolChild.data)}
              key={index}
            />
          ))}
        </>
      );
    }
    if (!_.isEmpty(sample.heading)) {
      Component = HeadingDisplayNameCount;
    } else if (!_.isEmpty(sample.value)) {
      Component = DisplayNameValue;
    } else if (_.isNumber(sample.count)) {
      Component = DisplayNameCountListItem;
      isListItem = true;
    } else {
      Component = DisplayNameListItem;
      isListItem = true;
    }

    const jsx = toolData.map((toolChild, index) => (
      <Component toolData={toolChild} key={index} />
    ));

    return isListItem ? <ul className={listStyle}>{jsx}</ul> : <>{jsx}</>;
  };

  return (
    <div className={toolItemContainer} data-testid="tool-item-container">
      {_renderData(data)}
    </div>
  );
};

export default ToolItemContent;
