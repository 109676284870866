/**
 * Privacy page container
 */

import React from 'react';

import ContentContainer from '../../components/layout/ContentContainer';
import withAuth from '../../hoc/withAuth';
import styles from './Privacy.module.scss';


const Privacy = () => (
  <ContentContainer size="large" className={styles.container}>
    <div>
      <h1>Privacy Policy</h1>
      <p>Last Revised: December 10th, 2021</p>
      <p>
        <strong>Introduction</strong>
        <br />
        <br />
        We, DarioHealth, also doing business as Labstyle Innovation Ltd.,
        Upright, and Dario powered by wayForward, (collectively, “
        <strong>us</strong>”, “<strong>we</strong>” or the “
        <strong>Company</strong>”) respect and are committed to protecting your
        (“<strong>user</strong>” or “<strong>you</strong>”) privacy.
        <br />
        <br />
        This Privacy Policy describes our practices with respect to collecting,
        using, and sharing your information through the use of our websites (“
        <strong>Site</strong>”), mobile applications (“<strong>App</strong>”),
        devices, and other services made available by us (collectively, the “
        <strong>Services</strong>”).
        <br />
        <br />
        <strong>Health Plan and Employee Assistance Plan Users:</strong> Please
        note, if your use of our Services is sponsored by your health plan or
        employee assistance plan, information, including personal information,
        provided to us by your health plan or employer is subject to contractual
        restrictions we have agreed to with your health plan or employee
        assistance program.
        <br />
        <br />
        The Privacy Policy is subject to our 
        <a href="https://mydario.com/terms-of-use/" target="_blank">
          Terms of Use
        </a>
          and is incorporated therein by reference. Capitalized terms used in
        this Privacy Policy but not defined here have the meanings ascribed to
        them in the Terms of Use.
        <br />
        <br />
        We encourage you to read the Privacy Policy carefully prior to using our
        Services. By using our Services, you agree to the terms of this Privacy
        Policy.
        <br />
        <br />
        <strong>What types of information we collect</strong>
        <br />
        <br />
        We collect two types of information from our users.
        <br />
        <br />
        The first type of information is non-personal information, which is
        information that does not identify you and may include information such
        as aggregated usage information and technical information transmitted by
        your device
        <br />
        <br />
        The second type of information we collect is information that identifies
        or can be used to identify you
        <strong> (“Personal Information”)</strong>. This includes:
        <br />
        <ul>
          <li>
            <u>Registration information</u>: In order to use our certain parts
            of our Services, you may be required to register to the Services. As
            part of the registration process, we may collect your full name,
            gender, height, weight, e-mail address, phone number, birth date,
            and information about your health related to your use of the
            Services, such as information about your diagnoses (as described
            below). If you register for our Services via your social network
            account (e.g. Facebook account) we may have access to basic
            information about you from such account, such as your name, email
            address, photo, as well as any other information you made publicly
            available on such account.
          </li>
          <li>
            <u>Financial information</u>: In order to make purchases through our
            Services, you may be required to share payment information,
            including your PayPal account or credit or debit card information
            necessary to process your payment. This information is collected and
            stored by our third party payment processors. We may collect some
            limited information, such as your postal code, mobile number, and
            details of your transaction history in connection with your
            purchases. In addition, our payment processors generally provide us
            with some limited information related to you, such as a unique token
            that enables you to make additional purchases using the information
            they’ve stored, including recurring payments if you become a
            subscriber, and your card’s type, expiration date, and certain
            digits of your card number.
          </li>
          <li>
            <u>Health information</u>: As part of the registration process for
            certain Services, you may be required to share certain health
            information related to your use of the Services, such as
            condition-specific diagnosis information and the types of services
            that you are seeking. Additionally, for certain parts of our
            Services, you may optionally provide us with additional information
            related to your health such as blood tests (i.e. last HbA1c), blood
            pressure test, medicines (e.g. insulin, oral medication), nutrition,
            carbohydrate consumption, exercise activity and motion (e.g. steps,
            cardio activity, kcal, type and intensity), as well as physical,
            mental, and emotional health status, other conditions and lifestyle
            habits. You may manually upload your health information to our Site
            or App, or allow us to collect such information automatically by
            performing synchronization to the results of a Device measurement or
            App questionnaire or to the information available through your
            HealthKit account (see detailed information below, under
            “Information shared through HealthKit”). Some of this information
            may constitute Protected Health Information (“PHI”) under the Health
            Insurance Portability and Accountability Act of 1996, as amended,
            and its implementing regulations (“HIPAA”). We will take all
            necessary steps to protect your PHI as required under HIPAA.
          </li>
          <li>
            <u>Additional Voluntary information</u>: We may collect additional
            information that you provide us voluntarily. For example, when you
            respond to communications from us, post a comment on a blog post,
            enroll in our rewards programs, contact our support, communicate
            with us via email or the Services or share additional information
            about yourself or about others through your use of the Services.
          </li>
          <li>
            <u>Device Information</u>: We may collect Personal Information from
            your device (e.g. geolocation data, IP address) and information on
            your activity on the Services (e.g. pages viewed, online browsing,
            clicks, actions, etc.).
          </li>
          <li>
            <u>Third parties’ SDKs</u>: Within our App, we may use Software
            Development Kits (SDK) provided by third parties. If you grant us
            your explicit consent to such use and integration (through the App),
            we may gather additional Personal Information about your activities,
            location and behavior, such as Wi-Fi, Bluetooth, accelerometer,
            gyroscope, GPS, etc. For additional information about SDKs, we
            advise you to visit the applicable third parties’ website(s). In our
            App, we use an SDK from Facebook. The Facebook SDK allows you to
            login to our App using your Facebook login.
          </li>
          <li>
            <u>Health Plan and Employee Assistance Plan Information</u>: We may
            periodically obtain Personal Information about you from employers or
            employee assistance plans or health plans that provide you with
            access to our Services where necessary to administer the Services.
            This information may include information about your employment
            status, employee identification number or identifier, job title,
            benefits information, and your primary work site (place of work). We
            use this information only as directed or permitted by those third
            parties and we are subject to contractual obligations to keep such
            information confidential. We may also receive information about you
            from third parties for the purposes of referring you to a
            participating provider.
          </li>
          <li>
            <u>Dario powered by wayForward Services Communication</u>. If you
            use our Dario powered by wayForward Services, we may also collect
            records of communications between you and your coach or mental
            health service provider.
          </li>
          <li>
            <u>Upright Services</u>: If you use our Upright Services, we collect
            the following additional types of information from you:
            <ul>
              <li>
                Training Plan: Information about your gender, weight, height,
                age, photo (optional), location, information relating to your
                use of the device (number of hours you sit and move each day,
                pain levels you experience, your postures and progress),
                information about your habits, work environment, fitness
                routines and training preferences, back pain level, and posture
                level (Mandatory information)
              </li>
              <li>
                Upright Go Device Sensors: Posture score, movement level,
                accelerometer values, and smooth angle.
              </li>
            </ul>
          </li>
          <li>
            <u>Product or Service Reviews</u>: If you write a customer review
            about our products or services, we collect your name, email address,
            photos that you provide, and any other information that you decide
            to provide us. Please note that any information that you provide to
            us in connection with your review may be made public on our
            Services, including on our public-facing Sites. If you write a
            review, please do not share or include any information that you do
            not want to be made public.
          </li>
          <li>
            <u>Careers</u>: If you apply for a position through our Sites or
            social media accounts (e.g. LinkedIn), we collect you name, email
            address, cover letter, and any other information that you decide to
            provide us.
          </li>
        </ul>
        <br />
        By providing sensitive Personal Information to us (including health
        information and PHI), you explicitly consent to the collection, use and
        sharing of your sensitive Personal Information in accordance with this
        Privacy Policy.
        <br />
        <br />
        If we combine Personal Information with non-personal Information, the
        combined information will be treated as Personal Information.
        <br />
        <br />
        We may anonymize, aggregate or de-identify any information, including
        Personal Information, collected by the Services or via other means so
        that the information cannot, on its own, identify you. Our use and
        sharing of such aggregated or de-identified information is not subject
        to any restrictions under this Privacy Policy, and we may use and share
        it with others without limitation and for any purpose. Unless we
        de-identify PHI in accordance with HIPAA requirements, it will continue
        to be PHI subject to HIPAA’s privacy and security requirements, as
        described in this Privacy Policy.
        <br />
        <br />
        You may refuse to disclose certain information to us, but please bear in
        mind that this may result in preventing you from using certain features
        or functions of the Site and Services, and in some cases may result in
        your inability to use the Site and Services.
      </p>
      <p>
        <strong>Cookies</strong>
        <br />
        <br />
        A cookie is a small data file that is sent to your device when you first
        visit a website. Cookies usually include an identification number that
        is unique to the device you are using. Such identifier can help us
        better understand our users and how they are using the Site and the
        Services. Cookies also enable recognition of a user when they re-visit
        the Site, keeping their settings and preferences and ability to offer
        customized features.
        <br />
        <br />
        The Services may implement the following types of cookies: (i) cookies
        implemented by us for the purposes described above ("
        <strong>First Party Cookie</strong>"); and (ii) third party cookies
        which are set by other online services who run content on the page you
        are viewing, for example by third party analytics companies who monitor
        and analyze our web access or online advertisers on our Services. Ads
        appearing on our Site and/or through the Services may be delivered to
        users by advertising partners, who may set cookies. These cookies allow
        the ad server to recognize your computer each time they send you an
        online advertisement to compile information about you or others who use
        your computer.  Among other things, this information allows ad networks
        to deliver targeted advertisements that they believe will be of most
        interest to you. These interest-based ads may be presented to you while
        you are browsing our Site or third-party sites not owned by us. This
        Privacy Policy covers the use of cookies by us alone and does not cover
        the use of cookies by any advertisers.
        <br />
        <br />
        You may remove the cookies by following the instructions of your device
        preferences; however, if you choose to disable cookies, some features of
        our Services may not operate properly and your online experience may be
        limited.
      </p>
      <p>
        <strong>Analytics</strong>
        <br />
        <br />
        We may use third parties, such as Google Analytics, Tableau, Amplitude,
        Flurry, or other analytics providers, to analyze traffic to and optimize
        our Site or Services. Our analytics may include cookies, clear GIFs,
        pixel tags, and other similar technologies. Our analytics partners do
        not create individual profiles for visitors and only collect aggregate
        data. To disable Google Analytics, for example, please download the
        browser add-on for the deactivation of Google Analytics provided by
        Google at 
        <a href="http://tools.google.com/dlpage/gaoptout?hl=en" target="_blank">
          http://tools.google.com/dlpage/gaoptout?hl=en
        </a>
        . To learn more about privacy of our analytics partners, please consult
        information provided by such analytics partners. You may have the option
        to opt out of analytics partners’ use of cookies by changing your
        browser settings.
      </p>
      <p>
        <strong>How we use the information we collect</strong>
        <br />
        <br />
        We use and share Personal Information, including PHI, in the manners
        described in this Privacy Policy. In addition to the purposes listed
        above, the Personal Information we collect is used for the following
        purposes:
        <br />
        <ul>
          <li>To set up your account and to provide our Services;</li>
          <li>
            To optimize the Site, our Services and your experience using them;
          </li>
          <li>
            Analyze your use of the Services, including analyzing information
            generated through devices that you use in connection with our
            Services, and to provide recommendations to you based on our
            analyses;
            <li>
              If you use our Dario powered by wayForward Services, your coach or
              mental health service provider may use your information to provide
              their services;
            </li>
            <li>
              To allow you to obtain relevant information about your health and
              provide you tools to manage it; To personalize our recommendations
              for better management of your health and wellness (including
              through our community learning tools, Apple’s HealthKit, etc.);
            </li>
            <li>
              To send you reminders based on your location and activity
              (including through SDKs);
            </li>
            <li>
              To allow you to make purchases through the Services and carry out
              transactions you request;
            </li>
            <li>
              To identify and authenticate your access to certain features of
              the Services;
            </li>
            <li>
              To communicate with you in order to keep you informed of our
              latest updates and features;
            </li>
            <li>
              To assess your candidacy for a position that you applied to and to
              facilitate your employment application;
            </li>
            <li>
              To provide rewards programs to you when you enroll in them;{' '}
            </li>
            <li>
              To perform research or to conduct analytics in order to improve
              and customize our Services to our users’ needs and interests;
            </li>
            <li>
              To market our products and services to you (to the extent
              permitted under HIPAA where applicable);
            </li>
            <li>
              To detect and prevent illegal activity or any other type of
              activity that may jeopardize or negatively affect the integrity of
              the Services;
            </li>
            <li>
              To support and troubleshoot our Services, to respond to your
              queries and communicate with you; To comply with our obligations
              under HIPAA; and
            </li>
            <li>
              To investigate violations and enforce our policies, and as
              required by law, regulation or other governmental authority, or to
              comply with a subpoena or similar legal process or respond to a
              government request.
            </li>
          </li>
        </ul>
        <br />
        If you are a registered user of our Services and have provided your
        email address or phone number, we may occasionally send you an e-mail or
        contact you via your phone number, including automatically generated
         SMS, telephone call or push notifications in order to provide you with
        our Services (for example, for the purpose of sending you a verification
        code to confirm user login, tracking info on shipping a package, and a
        link to download the App). These will be transactional or informational
        messages related to Services you requested or subscribed to. If we wish
        to send marketing communications, we will seek separate express written
        consent to do so through email or your online Account. 
        <strong>
          You hereby consent and authorize us to contact you in accordance with
          the above.
        </strong>
        <br />
        <br />
        You may unsubscribe from informational or marketing text messages at any
        time by replying STOP or a similar command to a message you receive from
        us related to your services. We may, however, continue to send you
        transactional messages such as tracking information or verification
        codes if you subscribe to additional products and provide your phone
        number. Standard text messaging rated may apply.
        <br />
        <br />
        Notwithstanding any of the above, with respect to PHI, we typically use
        or share PHI for treatment. This includes providing our Services,
        allowing you to obtain relevant information about your health and
        providing tools to manage it, personalizing our recommendations for
        better management of your health and wellness (including through our
        community learning tools, Apple’s HealthKit, etc.) or sending you
        reminders based on your location and activity (including through SDKs). 
        We may also use or share your PHI to run our organization.  This
        includes setting up your account, identifying and authenticating your
        access to certain features of the Services, communicating with you in
        order to keep you informed of our latest updates and features and
        performing research or conducting analytics in order to improve and
        customize our Services to our users’ needs and interests.  We can also
        use and share your PHI to bill for your services.
      </p>
      <p>
        <strong>With whom we share the information we collect</strong>
        <br />
        <br />
        We do not rent, sell or share your Personal Information with third
        parties except as described in this Privacy Policy.
        <br />
        <br />
        We may transfer or share Personal Information, including PHI, to our
        subsidiaries, affiliated companies, subcontractors, your coach or mental
        health service provider (if you use our Dario powered by wayForward
        Services); SDKs or such other trusted third parties and/or service
        providers or partners (who may be located in various countries across
        the world) to perform services for us on our behalf.
        <br />
        <br />
        In addition, under your specific acknowledgment and consent, we may
        share your Personal Information and PHI with third party service
        providers in the healthcare ecosystem, such as hospitals, physicians,
        insurance companies, coaching services providers and others to allow
        them to obtain a holistic view of your needs and interests. Please note
        that under such consent, we may disclose to third party service
        providers in the healthcare ecosystem all relevant Personal Information
        and PHI that you share with us on an ongoing basis through the Services.
        <br />
        <br />
        <u>Please note</u>: When we share PHI with third parties, such
        information is either anonymized or encrypted (as required by applicable
        laws) in order to effectively protect personal and/or health related
        information of users.  To the extent PHI is shared with third parties,
        the third parties must first agree to be bound by HIPAA privacy and
        security protections.
        <br />
        <br />
        We may also share Personal Information, including PHI, or any
        information you submitted via the Services, if we have a good faith
        belief that the sharing of such information is helpful or reasonably
        necessary to: (i) comply with any applicable law, regulation, legal
        process or governmental request; (ii) enforce our policies (including
        our Agreement), including investigations of potential violations
        thereof; (iii) investigate, detect, prevent, or take action regarding
        illegal activities or other wrongdoing, suspected fraud or security
        issues; (iv) establish or exercise our rights to defend against legal
        claims; (v) prevent harm to the rights, property or safety of us, our
        users, yourself or any third party; or (vi) collaborate with law
        enforcement agencies or in case we find it necessary in order to enforce
        intellectual property or other legal rights.
        <br />
        <br />
        With respect to PHI, you have both the right and choice to tell us to
        share information with your family, close friends or others involved in
        your care and to share information in a disaster relief situation.  If
        you are unable to tell us your preference (for example, if you are
        unconscious), we may go ahead and share your PHI if we believe it is in
        your best interest.  We may also share your PHI when needed to lessen a
        serious and imminent threat to health or safety.  We will never share
        your PHI for marketing purposes or sell your PHI, unless you give us
        written permission. 
        <br />
        <br />
        We are allowed or required to share your PHI in other ways that
        contribute to the public good (such as public health and research),
        provided we meet many conditions under HIPAA before doing so.  These
        disclosures include those that help with public health and safety issues
        (such as preventing disease, helping with recalls, reporting adverse
        reactions, suspected abuse, neglect or domestic violence or preventing
        or reducing a serious threat to anyone‘s health or safety).  We can also
        share your PHI for health research or if state or federal laws require
        it, to respond to court or administrative orders or to address workers’
        compensation, law enforcement and other government requests.  In
        addition, we may share your PHI to respond to organ and tissue donation
        requests or to work with a medical examiner, coroner or funeral
        director.
      </p>
      <p>
        <strong>Information shared through HealthKit</strong>
        <br />
        <br />
        You may instruct us to share information (including Personal Information
        and PHI) with systems such as Apple Inc.’s HealthKit and to access and
        collect information from those systems, for the purpose of improving the
        Services which we provide to you. If you grant our App access to
        HealthKit, our App can add information such as glucose measures and
        carbs, amongst other data, to HealthKit. Dario is in no way responsible
        for the protection of data and information stored within the Apple
        HealthKit database. It is strongly recommended that you review Apple’s
        applicable policies and procedures before synching and backup your Apple
        HealthKit data (e.g. Apple’s privacy policy can be found 
        <a href="https://www.apple.com/legal/privacy/en-ww/" target="_blank">
          here
        </a>
        ). You can remove access at any time either inside our App or inside the
        Apple Health app.
        <br />
        <br />
        We do not use or share with third parties any information gained through
        the use of the HealthKit for advertising or similar services, other than
        for purposes of improving health or for purposes of health or medical
        research and, with respect to PHI, only to the extent permitted under
        HIPAA.
      </p>
      <p>
        <strong>Third party collection of information</strong>
        <br />
        <br />
        Our policy only addresses the use and sharing of information we collect
        from you. To the extent that you share your information with other
        parties via our Services (e.g. by clicking on a link to any other
        website or location) or via other sites throughout the internet,
        different rules may apply to their use or sharing of the information
        that you disclose to them. We shall have no liability whatsoever with
        respect to such third party sites and services and your usage of them.
      </p>
      <p>
        <strong>User Rights and Choices</strong>
        <br />
        <br />
        You can access, update, and delete certain Personal Information by
        logging into your user account. If you have questions about Personal
        Information we have about you, please email us at dpo@mydario.com or
        call us at 1-800-895-5921. You can opt-out of receiving marketing and
        promotional e-mails from Dario by using the opt-out or unsubscribe
        feature contained in the e-mails. Please note that even if you
        unsubscribe from our marketing mailing list, we may continue to send you
        Service-related updates and notifications. If you wish to unsubscribe
        from marketing text message communications, you may respond to any
        marketing message from us with a STOP or similar command.
        <br />
        <br />
        <u>EEA users:</u>
        <br />
        <br />
        This section applies to all Personal Information (or “personal data”)
        collected by us from users and customers in the European Economic Area
        (“EEA”), the United Kingdom, and Switzerland. The processing of Personal
        Information is governed by the General Data Protection Regulation
        (“GDPR”) in the EEA, and similar data privacy laws in the United Kingdom
        and Switzerland. These laws require us to provide certain information
        regarding our processing of your Personal Information.
        <br />
        <ul>
          <li>
            <strong>Our Processing of Personal Information</strong>: Unless
            otherwise stated, we are the data controller of Personal Information
            you provide to us, and the purposes of our processing of Personal
            Information are described in this Policy and outlined below.  We may
            also process Personal Information on other bases permitted by the
            GDPR and applicable laws. In general, we process Personal
            Information on the following legal grounds: 
            <ul>
              <li>
                Providing our products and services to you or performing a
                contract we have with you: In certain circumstances, we need
                your Personal Information to comply with our contractual
                obligation to deliver products you order from us and to provide
                our Services. For example, if you buy our products from us, we
                must collect certain information, including Personal Information
                from you. Similarly, we need your name and contact details so
                that we can communicate with you and deliver the products to
                you. In this case, your Personal Information is necessary to
                provide you with the Services.
              </li>
              <li>
                When the processing is necessary for purposes of our legitimate
                business interest in conducting our business: For example, we
                have a legitimate interest in communicating information about
                our company and (subject to marketing authorizations) our
                commercial products, events, and company initiatives; and
                improving our website content and functionality. We take into
                account any risks to your fundamental rights and freedoms in
                assessing these purposes (including your right to privacy);
              </li>
              <li>
                When the processing is necessary for us to comply with our legal
                obligations; and  When you give us the consent to process your
                Personal Information.
              </li>
            </ul>
          </li>
          <li>
            <strong>Categories of Personal Information</strong>: All categories
            of Personal Information collected and processed by us are described
            in this Policy.
          </li>
          <br />
          <li>
            <strong>
              Health and other Special Categories of Personal Information
            </strong>
            : To the extent that Personal Information we collect is health data
            or another special category of personal data subject to the GDPR, we
            ask for your explicit consent to process such data. We obtain this
            consent separately when you take actions leading to our obtaining
            the data, for example, when you pair your device to your account.
            You may be able to use your account settings and tools to withdraw
            your consent at any time, including by stopping use of a feature,
            removing our access to a third-party service, unpairing your device,
            or deleting your data or your account.
          </li>
          <br />
          <li>
            <strong>Recipients of Personal Information</strong>: We use various
            service providers to manage our website, help us fulfill product
            orders, and assist us as described in this Policy.  Our service
            providers change from time to time.  Note that we require our
            service providers to treat your Personal Information in a manner
            that is consistent with this Policy. If you would like specific
            information about our service providers who have received your
            Personal Information, please contact us at{' '}
            <a href="mailto:dpo@mydario.com" target="_blank">
              dpo@mydario.com
            </a>
              and we will provide that information to you.
          </li>
          <br />
          <li>
            <strong>
              Transfers of Personal Information outside of the EEA
            </strong>
            : We are based in the U.S. and that’s where we process Personal
            Information.  When you provide Personal Information to us, we
            transfer that Personal Information to the U.S. We treat the Personal
            Information of EEA users in accordance with this Policy.  We take
            appropriate steps to protect your privacy and implement reasonable
            security measures to protect your Personal Information in storage.
            We also enter into contracts with our data processors that require
            them to treat Personal Information in a manner that is consistent
            with this Policy.
          </li>
          <br />
          <li>
            <strong>Personal Information retention</strong>: We retain Personal
            Information so long as it is necessary for the purpose for which it
            was collected, and delete Personal Information after it is no longer
            needed for these purposes.  This does not affect your right to
            request that we delete your Personal Information before the end of
            its retention period.  We may archive Personal Information (which
            means storing it in inactive files) for a certain period of time
            prior to its final deletion, as part of our ordinary business
            continuity procedures.
          </li>
          <br />
          <li>
            <strong>Your rights:</strong>
            <ul>
              <li>
                You have the right to access to your Personal Information, to
                have it corrected if the Personal Information we have is
                incorrect, to request that we restrict or delete your Personal
                Information, and to object to our processing of your Personal
                Information. Your rights may be subject to various limitations
                under applicable privacy laws.  If you wish to exercise any of
                these rights, or if you have any concerns about our processing
                of your Personal Information, please contact us at{' '}
                <a href="mailto:dpo@mydario.com" target="_blank">
                  dpo@mydario.com
                </a>
                .  
              </li>
              <li>
                You have the right to file a complaint concerning our processing
                of your Personal Information with your national data protection
                authority.
              </li>
              <li>
                You have the right to file a complaint with a competent data
                protection supervisory authority, in particular in the EU Member
                State where you reside, work or the place of the alleged
                infringement. You have the right to do so if you consider that
                the processing of Personal Information relating to you infringes
                applicable data protection laws.
              </li>
            </ul>
          </li>
          <br />
          <li>
            <strong>Workplace Options:</strong>
            <p>
              Some of our global users may have access to services provided by
              WorkPlace Options LLC (“WPO”). Please note that WPO is an
              independent third party. If you provide Personal Information to
              WPO, WPO is an independent data controller of that information,
              and Dario has no control over WPO’s use of your information or
              Personal Information. Please read WPO’s privacy policy carefully
              for information about how WPO’s uses your Personal Information. If
              you wish to exercise of your rights under applicable data
              protection laws with respect to Personal Information that you have
              provided to WPO, please contact WPO to exercise those rights.
            </p>
          </li>
        </ul>
        <br />
        <u>California residents</u>:
        <br />
        <br />
        If you are a California resident, California law may provide you with
        additional rights regarding our use of your Personal Information. To
        learn more about your California privacy rights, visit the 
        <a
          href="https://mydario.com/california-privacy-notice/"
          target="_blank"
        >
          Privacy Notice for California Residents
        </a>
        .
        <br />
        <br />
        <u>How to Exercise Your Rights; Complaints</u>:
        <br />
        If you wish to exercise any of your rights with respect to your Personal
        Information, or raise a complaint regarding how we have handled your
        Personal Information or PHI, please contact us at 
        <a href="mailto:dpo@mydario.com" target="_blank">
          dpo@mydario.com
        </a>
        .
        <br />
        <br />
        In addition, if you believe your privacy rights with respect to PHI have
        been violated, or if you are dissatisfied with our privacy practices or
        procedures regarding your PHI, you may file a complaint with the U.S.
        Department of Health and Human Services by sending a letter to U.S.
        Department of Health and Human Services Office for Civil Rights, 200
        Independence Avenue, S.W., Washington, D.C. 20201, calling
        1-877-696-6775 or visiting{' '}
        <a href="https://hhs.gov/ocr/privacy/hipaa/complaints/" target="_blank">
          www.hhs.gov/ocr/privacy/hipaa/complaints/
        </a>
        . We will not retaliate against you for filing a complaint.
      </p>
      <p>
        <strong>Other State-specific Disclosures</strong>
        <br />
        <br />
        <u>California residents</u>: We do not respond to Do Not Track requests
        or signals at this time. California’s “Shine the Light” law (Civil Code
        Section 1798.83) permits California residents to request certain
        information regarding our disclosure of “personal information” (as
        defined in the Shine the Light law) to third parties for their direct
        marketing purposes. We do not share your “personal information” with
        third parties for their own direct marketing processes.
        <br />
        <br />
        <u>Nevada residents</u>: Nevada residents can opt out of the sale of
        their Personal Information.   We do not sell Personal Information today,
        and we do not have plans to sell Personal Information in the future.  
        However, Nevada residents have the legal right to opt out of the sale of
        their Personal Information, even if their information is not currently
        being sold.  You may opt out by emailing a request to dpo@mydario.com. 
        In your request, please specify that you wish to “Opt-out of Sale of
        Personal Information in Nevada.”   Please allow 60 days for a response.
      </p>
      <p>
        <strong>Retention</strong>
        <br />
        <br />
        We will retain your personal information for the duration required to
        provide our services, and as necessary to comply with our legal
        obligations, resolve disputes and enforce our policies. Retention
        periods will be determined taking into account the type of information
        that is collected and the purpose for which it is collected.{' '}
      </p>
      <p>
        <strong>How do we safeguard and transfer your information?</strong>
        <br />
        <br />
        We take great care in implementing and maintaining the security of the
        Services and your Personal Information in accordance with the
        requirements of all applicable laws (including with requirements under
        HIPAA). We have implemented appropriate administrative, technical, and
        physical security procedures to help protect the Personal Information
        you provide to us.  However, the Internet is not a risk-free
        environment, and we cannot guarantee the security of information you
        submit via our Services while it is in transit over the Internet, and
        any such submission is at your own risk.
        <br />
        <br />
        As an additional safeguard to your Personal Information, the Company
        employs a Data Protection Officer (“<strong>DPO</strong>”).  The DPO
        also serves as our HIPAA privacy officer.
        <br />
        <br />
        Since we operate globally, we may be required to transfer your Personal
        Information to countries outside of the United States. The data
        protection and other laws of these countries may not be as comprehensive
        as those in the United States − in these instances we will take steps to
        ensure that a similar level of protection is given to your Personal
        Information. You hereby consent to transfer of your Personal Information
        to countries outside the United States.
      </p>
      <p>
        <strong>Affiliates and corporate transaction</strong>
        <br />
        <br />
        We may share your information, including your Personal Information and
        PHI, with any subsidiaries, joint ventures, or other companies under our
        common control (collectively, “<strong>Affiliated Companies</strong>”).
        We may share Information, including Personal Information and PHI, in the
        event of a corporate transaction (e.g. sale of a substantial part of our
        business, merger, consolidation or asset sale) or steps taken in
        anticipation of such events (e.g., due diligence in a transaction). In
        the event of the above, our Affiliated Companies or acquiring company
        will assume the rights and obligations as described in this Privacy
        Policy and, with respect to PHI, under HIPAA.
      </p>
      <p>
        <strong>Minors</strong>
        <br />
        <br />
        The Services are not designed for the use by children under 13 years of
        age. However, users in the EEA must be 16 years of age, and users of our
        Dario powered by wayForward and Upright services must be at least 18
        years of age. If you have reason to believe that a child under the age
        of 13 (or under the age of 16 in the EEA) has provided Personal
        Information to us through the use of any of the Services without the
        consent of their parent or legal guardian, you should immediately
        contact us and we will endeavor to delete that information from our
        records.
        <br />
        <br />
        Additionally, no information should be submitted to or posted to any of
        the Services by users under 18 years of age without the consent of their
        parent or legal guardian. We encourage parents and legal guardians to
        monitor their children’s Internet usage and to help enforce our Privacy
        Policy by instructing their children to never provide Personal
        Information and PHI on any of the Services without their permission.
      </p>
      <p>
        <strong>Updates or Amendments to the Privacy Policy</strong>
        <br />
        <br />
        We may revise this Privacy Policy from time to time. We will make the
        revised Privacy Policy accessible through the Site or our Services, so
        you should review the Privacy Policy periodically. You can know if the
        Privacy Policy has changed since the last time you reviewed it by
        checking the “Last Revised” date included at the beginning of the
        document. If we make a material change to the Privacy Policy, we will
        provide you with notice in accordance with legal requirements. Your use
        of the Services following any such change constitutes your acceptance of
        the revised Privacy Policy.
      </p>
      <p>
        <strong>How to contact us</strong>
        <br />
        <br />
        If you have any general questions about the Services or our privacy
        practices in general, please contact us at
        <br />
        DarioHealth <br />
        Customer Support
        <br />
        142 W. 57th Street, 11th Floor
        <br />
        New York, NY 10019
      </p>
      <p>
        Email: 
        <a href="mailto:dpo@mydario.com" target="_blank">
          dpo@mydario.com
        </a>
         
      </p>
      <p>Toll-Free: 1-800-895-5921</p>
    </div>
  </ContentContainer>
);

export default withAuth(Privacy);
