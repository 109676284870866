import _ from 'lodash';
import fields from './fields';
import types from './types';

const _filterSuggestionsFields = (data, type) =>
  _.map(data, (suggestion) => _.pick(suggestion, fields[type]));

export default (messageObj) => {
  const data = {};
  data.message = _.get(messageObj, 'message', '');
  data.media = _.get(messageObj, 'media', []);
  data.contentVersionMap = _.get(messageObj, 'contentVersionMap', {});
  const suggestionsObj = _.get(
    messageObj,
    'suggestions.getCoachSuggestions.suggestions',
    {}
  );
  if (!_.isEmpty(suggestionsObj)) {
    data.suggestions = {};
    data.suggestions.version = _.get(suggestionsObj, 'version', '');
    data.suggestions.text = _filterSuggestionsFields(
      suggestionsObj.text,
      types.text
    );
    data.suggestions.video = _filterSuggestionsFields(
      suggestionsObj.video,
      types.video
    );
  }

  return data;
};
