/**
 * App Retake Survey Modal
 * TODO: move this to normal modal
 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import retakeAssessment from 'assets/img/retake-assessment.svg';
import closeIcon from 'assets/img/close.svg';
import { closeModal } from 'store/actions/ModalAction';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import history from 'utils/history';
import featureService from 'utils/feature-service';
import logger from 'utils/logger';
import Lang from '../Lang';
import styles from './RetakeSurvey.module.scss';

const { retakeImg, retakeSurveyModal, reloadBtn, closeIconStyle } = styles;

const RetakeSurvey = (props) => {
  const retakeSurvey = () => {
    analytics.track('Retake Survey', 'clicked retake', {
      trigger: 'modal_action'
    });
    logger.info('Clicked retake', 'RetakeSurvey.retakeSurvey');
    props.actions.closeModal();
    if (featureService._hasConversationalAssessmentFeature()) {
      history.push('/assessment/conversational');
    } else {
      history.push('/assessment');
    }
  };
  const { t } = useTranslation();
  const _handleModalClose = () => {
    const source = 'retake-survey';
    analytics.track(eventCategories.MODAL, 'clicked close', {
      source,
      trigger: 'modal_action'
    });
    logger.info('Clicked close', 'RetakeSurvey._handleModalClose', { source });
    props.actions.closeModal();
  };

  return (
    <div>
      <Dialog open>
        <span
          tabIndex={0}
          role="button"
          onClick={_handleModalClose}
          onKeyDown={_handleModalClose}
        >
          <img
            className={closeIconStyle}
            src={closeIcon}
            alt={t('alternateText.button.close')}
            data-testid="close-icon"
          />
        </span>

        <DialogContent>
          <div className={retakeSurveyModal}>
            <img className={retakeImg} src={retakeAssessment} alt="" />
            <h2>
              <Lang path="retakeAssessmentPopup.header" />
            </h2>
            <p>
              <Lang path="retakeAssessmentPopup.text" />
            </p>
            <button
              type="button"
              data-testid="retake-survey-button"
              onClick={() => retakeSurvey()}
              className={reloadBtn}
            >
              <Lang path="retakeAssessmentPopup.buttonText" />
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    closeModal: bindActionCreators(closeModal, dispatch)
  }
});

export default connect(null, mapDispatchToProps)(RetakeSurvey);
