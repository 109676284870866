/**
 *
 * Contact EAP Card component
 *
 */

import React from 'react';
import { useDispatch } from 'react-redux';

// components
import { Card } from '@material-ui/core';
import { showModal } from 'store/actions/ModalAction';
import analytics from 'utils/analytics';
import contactEapImg from 'assets/img/dashboard/resources/contact_eap.png';
import { OutlineButton } from '../../../../components/ui/Buttons';
import EAPInfo from '../../../../components/Popups/EAPInfo';

import Lang from '../../../../components/Lang';
// styles
import styles from './ContactEAP.module.scss';
// utils

// assets

const { container, contentContainer, buttonsContainer } = styles;

const ContactEAP = () => {
  const dispatch = useDispatch();
  const _handleContactEapClick = () => {
    analytics.track('resources', 'clicked contact eap');
    dispatch(showModal(EAPInfo, { maxWidth: 425, source: 'eap-info' }));
  };
  return (
    <Card className={container}>
      <div className={contentContainer}>
        <img data-testid="contact-eap-img" src={contactEapImg} alt="" />
        <div>
          <h2 data-testid="contact-eap-header-text">
            <Lang path="contactEAPCard.headerText" />
          </h2>
          <p data-testid="contact-eap-description-text">
            <Lang
              path="contactEAPCard.bodyText"
              components={{
                bold: <b />
              }}
            />
          </p>
        </div>
      </div>
      <div className={buttonsContainer}>
        <OutlineButton
          testId="contact-eap"
          onClick={() => _handleContactEapClick()}
        >
          <Lang path="contactButtonText" />
        </OutlineButton>
      </div>
    </Card>
  );
};

export default ContactEAP;
