import React from 'react';
import styles from './InputSuccess.module.scss';

const { container, messageStyle } = styles;

const InputSuccess = ({ message, testId }) => (
  <span className={container} data-testid={testId}>
    <i className={['fa fa-check', messageStyle].join(' ')} /> {message}
  </span>
);

export default InputSuccess;
