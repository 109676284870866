/**
 *
 * PopUp
 *
 */
import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// actions
import { closeModal } from 'store/actions/ModalAction';
// utils
import eventCategories from 'utils/analytics/categories';
import logger from 'utils/logger';
// styles
import analytics from 'utils/analytics';
import styles from './Modal.module.scss';

const { closeBtn, popupContainer, window } = styles;

class ModalWrap extends Component {
  _handleModalCloseFunc = () => {
    const {
      modal: { onClose, source },
      action
    } = this.props;
    analytics.track(eventCategories.MODAL, 'clicked close', {
      source,
      trigger: 'modal_action'
    });
    logger.info('Clicked close', 'Modal._handleModalCloseFunc', { source });
    if (onClose) {
      onClose();
      action.closeModal();
    } else {
      action.closeModal();
    }
  };

  render() {
    const {
      modal: { component, isOpen, maxWidth, props, hideCloseButton, source }
    } = this.props;
    const ModalContent = component;
    if (isOpen) {
      analytics.info(
        eventCategories.MODAL,
        `${source} popup shown to the user`,
        {
          source,
          trigger: 'modal_action'
        }
      );
      logger.info('Popup shown to the user', 'Modal.render', { source });
    }
    return (
      <Modal open={isOpen} className={popupContainer}>
        <div className={window} style={{ maxWidth }}>
          {!hideCloseButton && (
            <button
              type="button"
              className={closeBtn}
              data-testid="close-popup-button"
              onClick={this._handleModalCloseFunc}
            >
              <i data-testid="close-button" className="material-icons">
                close
              </i>
            </button>
          )}
          {component && <ModalContent {...props} />}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  modal: state.modal
});
const mapDispatchToProps = (dispatch) => ({
  action: { closeModal: bindActionCreators(closeModal, dispatch) }
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalWrap);
