/**
 * Report Actions and action creators that are specific to fetching a report by ID,
 * that means it's used for fetching old reports and not generating a new one
 */

import api from '../../api/assessment';
import handleUnexpectedError from '../helper';
import {
  GET_REPORT_BY_ID,
  GET_REPORT_BY_ID_DONE,
  GET_REPORT_BY_ID_ERROR
} from './types';

import logger from '../../utils/logger';

const getReportByIdStart = () => ({ type: GET_REPORT_BY_ID });

const getReportByIdDone = (data) => ({
  type: GET_REPORT_BY_ID_DONE,
  data
});

const getReportByIdError = () => ({ type: GET_REPORT_BY_ID_ERROR });

export default (id) => (dispatch) => {
  dispatch(getReportByIdStart());
  return api
    .getReport(id)
    .then((resp) => {
      if (resp.status === 200 && resp.data) {
        dispatch(getReportByIdDone(resp.data));
      } else {
        logger.error(
          `An error occurred while fetching report id ${id}`,
          'ReportByIdAction.default',
          { resp }
        );
        dispatch(getReportByIdError());
      }
    })
    .catch((err) => {
      handleUnexpectedError(
        `An unexpected error occurred while fetching report with id ${id}`,
        'ReportById.default',
        err
      );
      dispatch(getReportByIdError());
    });
};
