import React from 'react';
import { toast } from 'react-toastify';
import toastConfig from './Toast.config';
import Toast from './Toast';

const _getNotificationComponent = (props) => {
  const config = { ...toastConfig, ...props.config };
  // Toast with similar toastId will not render if already present in dom. use it to avoid duplicate notifications.
  toast(<Toast {...props} />, config);
};

export default {
  info: (message, callback, config) =>
    _getNotificationComponent({
      message,
      callback,
      config,
      type: 'info'
    }),
  error: (message, callback, config) =>
    _getNotificationComponent({
      message,
      callback,
      config,
      type: 'error'
    }),
  success: (message, callback, config) =>
    _getNotificationComponent({
      message,
      callback,
      config,
      type: 'success'
    }),
  warning: (message, callback, config) =>
    _getNotificationComponent({
      message,
      callback,
      config,
      type: 'warning'
    }),
  dismiss: () => {
    toast.dismiss();
  }
};
