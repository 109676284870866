import {
  GET_COMPANY_DETAILS,
  GET_COMPANY_DETAILS_DONE,
  GET_COMPANY_DETAILS_ERROR
} from '../actions/types';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  companyDetails: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_DETAILS:
      return {
        ...state,
        isFetching: true,
        hasError: false
      };
    case GET_COMPANY_DETAILS_DONE:
      return {
        ...state,
        companyDetails: action.payload,
        isFetching: false
      };
    case GET_COMPANY_DETAILS_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true
      };
    default:
      return state;
  }
};
