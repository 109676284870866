/**
 *
 * ChatBox
 *
 */

import React, { useContext } from 'react';

import actionCardImg from 'assets/img/dashboard/actioncard.jpg';

import analytics from 'utils/analytics';
import ConversationalAssessmentContext from '../../ConversationalAssessmentContext';

import styles from './ActionPlanCard.module.scss';

const { container, textOuter, viewButton, resetButton } = styles;

const ActionPlanCard = ({
  showResetLink,
  onClick,
  title,
  desc,
  buttonText,
  resetButtonText,
  imgId,
  descId
}) => {
  const conversationalAssessmentContext = useContext(
    ConversationalAssessmentContext
  );
  const resetChatAssessmentHandler = () => {
    analytics.track('assessment', 'clicked need help with something else?');
    conversationalAssessmentContext._onResetClick();
  };
  return (
    <div className={container}>
      <img src={actionCardImg} alt="" data-testid={imgId} />
      <div className={textOuter}>
        {title && (
          <h5 data-testid="action-plan-card-heading-for-solutions">{title}</h5>
        )}
        {desc && <p data-testid={descId}>{desc}</p>}
      </div>
      <button
        type="button"
        onClick={onClick}
        className={viewButton}
        data-testid="action-plan-view-button"
      >
        {buttonText}
      </button>
      {showResetLink && (
        <button
          type="button"
          onClick={resetChatAssessmentHandler}
          className={resetButton}
          data-testid="action-plan-reset-button"
        >
          {resetButtonText}
        </button>
      )}
    </div>
  );
};

export default ActionPlanCard;
