import _ from 'lodash';
import analytics from 'utils/analytics/analytics';
import history from 'utils/history';
import i18n from 'assets/lang';
import { dispatch } from 'store';
import logger from 'utils/logger';
import featureService from 'utils/feature-service';
import helpers from 'utils/helpers';
// actions
import { showModal } from 'store/actions/ModalAction';
import { startAutoCoachAssign } from 'store/actions/dashboard/CoachRequestAction';
import Coaching from '../popups/Coaching';
import SmartAppLink from '../../../components/Popups/SmartAppLink';

const _showDownloadModal = ({ analyticsMessage, goalId, severity }) => {
  analytics.track('dashboard', analyticsMessage, {
    goalId,
    severity
  });
  dispatch(
    showModal(SmartAppLink, {
      source: 'smart-app-link',
      props: { headerText: i18n.t('coachAssignedHeaderText') }
    })
  );
};

export const chatNowHandler = ({
  coachRequestStatus,
  analyticsMessage,
  goalId,
  severity
}) => {
  if (helpers.canShowCoachChat(coachRequestStatus)) {
    analytics.track('dashboard', analyticsMessage, {
      goalId,
      severity
    });
    history.push('/coach');
  } else {
    _showDownloadModal({ analyticsMessage });
  }
};

const _showCoachModal = ({ goalId, severity, analyticsMessage }) => {
  analytics.track('dashboard', analyticsMessage, {
    goalId,
    severity
  });
  dispatch(
    showModal(Coaching, {
      source: 'coaching',
      props: { showRequestConfirmation: false }
    })
  );
};

export const requestCoach = ({
  goalId,
  severity,
  report,
  eapName,
  analyticsMessage
}) => {
  logger.info('Requesting a coach', 'helpers.requestCoach');
  if (
    featureService._hasCoachAccessOnWebFeature() &&
    featureService._hasAutoCoachAssignmentOnRequestClickFeature()
  ) {
    logger.info(
      'User has the required features for auto coach assignment',
      'helpers.requestCoach'
    );
    const categories = _.get(report, 'categories', null);
    if (categories) {
      dispatch(
        startAutoCoachAssign({
          mode: 'auto',
          eapName,
          goals: helpers.transformBySeverity(categories)
        })
      );
      history.push('/coach');
      analytics.info(
        'coach chat screen',
        'successfully opened coach chat screen'
      );
    } else {
      logger.warn(
        "User hasn't completed any assessment, skipping auto coach assignment",
        'helpers.requestCoach'
      );
    }
  } else {
    logger.info(
      'User does not have the required features for auto coach assignment',
      'helpers.requestCoach'
    );
    _showCoachModal({ goalId, severity, analyticsMessage });
  }
};
