import React from 'react';
import { getPlatforms } from '@ionic/react';
// Navigation menus
import AppHeader from 'components/AppHeader/AppHeader';
import LeftSideBar from 'components/LeftSideBar';
import Toolbar from 'components/Toolbar';
import logger from 'utils/logger';
// utils
import helpers from 'utils/helpers';

const Navigation = ({
  openSidebar,
  closeSidebar,
  isMenuOpen,
  isFieldMissing
}) => {
  const platformType = getPlatforms();

  logger.info('Platform type comma separated', 'Navigation.Navigation', {
    platformType: platformType.join(',')
  });
  return (
    <>
      {helpers.platformInfo.isDesktop ? (
        <>
          <AppHeader
            openSideBar={openSidebar}
            onClick={closeSidebar}
            isFieldMissing={isFieldMissing}
          />
          <LeftSideBar open={isMenuOpen} closeSideBar={closeSidebar} />
        </>
      ) : (
        <Toolbar isFieldMissing={isFieldMissing} />
      )}
    </>
  );
};

export default Navigation;
