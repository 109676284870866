import React, { Fragment, useEffect, useState, useMemo } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from '@apollo/client';
import _ from 'lodash';
// hocs
import withAuth from 'hoc/withAuth';
import wrapWithApolloClient from 'hoc/wrapWithApolloClient';
// utils
import logger from 'utils/logger';
import { queries } from 'utils/graphql';
import Slider from '../../../../../components/Slider';
// components
import ErrorRetry from '../../../../../components/ErrorRetry';
import Lang from '../../../../../components/Lang';
import styles from './Carousel.module.scss';
import { LoaderSmall, LoaderLarge } from './Carousel.skeleton';
import BreathingExerciseCard from '../Card';

const { GET_BREATHING_EXERCISES } = queries;
const { breathingExercisesTitleStyle } = styles;

const renderBreathingExercises = (breathingExercises) => (
  <Fragment>
    <div
      data-testid="breathing-exercise-header"
      className={breathingExercisesTitleStyle}
    >
      <Lang path="selfUse.breathingExercise.carouselHeading" />
    </div>
    <div data-testid="breathing-exercise-carousel">
      <Slider
        nextArrowId="breathing-exercises-carousel-next-arrow-button"
        prevArrowId="breathing-exercises-carousel-prev-arrow-button"
        loop
      >
        {breathingExercises.map((breathingExercise, index) => (
          <BreathingExerciseCard
            key={breathingExercise.id}
            breathingExerciseData={breathingExercise}
            elementId={`breathing-exercise-${index + 1}`}
          />
        ))}
      </Slider>
    </div>
  </Fragment>
);

const Carousel = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const {
    error: graphQLError,
    data: fetchedBreathingExercises,
    loading: isFetching,
    refetch,
    networkStatus
  } = useQuery(GET_BREATHING_EXERCISES, {
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if (isFetching || networkStatus === NetworkStatus.refetch) {
      setIsLoading(true);
    } else if (
      graphQLError &&
      !isFetching &&
      networkStatus !== NetworkStatus.refetch
    ) {
      logger.error(
        'Failed to fetch breathing exercises.',
        'Carousel.useEffect',
        { error: graphQLError }
      );
      setIsLoading(false);
      setIsError(true);
    } else {
      setIsLoading(false);
      setIsError(false);
    }
  }, [graphQLError, isFetching, networkStatus]);

  const LoaderComponent = useMemo(
    () => (isSmallScreen ? LoaderSmall : LoaderLarge),
    [isSmallScreen]
  );
  if (isLoading) {
    return <LoaderComponent />;
  }

  if (isError) {
    return (
      <ErrorRetry
        isFetching={false}
        onClick={() => refetch()}
        title={<Lang path="selfUse.breathingExercise.error.errorText" />}
      />
    );
  }

  const breathingExercises = _.get(
    fetchedBreathingExercises,
    'getBreathingExercise.breathingExercises',
    []
  );
  return renderBreathingExercises(breathingExercises);
};

export default withAuth(wrapWithApolloClient(Carousel));
