/**
 * Reducer for managing the state of reports that are fetched by either id or newly processed
 * so we don't have to fetch them every time
 */

import {
  UPDATE_USER_DATA,
  UPDATE_USER_DATA_DONE,
  UPDATE_USER_DATA_ERROR,
  GET_USER_DATA,
  SET_ONBOARD_FLAG,
  RESET_USER_DATA_STATUS
} from '../actions/types';

const INITIAL_STATE = {
  isLoading: false,
  hasError: false,
  isDone: false,
  profile: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_USER_DATA:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        isDone: false
      };
    case UPDATE_USER_DATA_DONE:
      return {
        ...state,
        isLoading: false,
        isDone: true,
        profile: { ...state.profile, ...action.payload }
      };
    case RESET_USER_DATA_STATUS:
      return {
        ...state,
        isLoading: false,
        isDone: false,
        hasError: false
      };
    case UPDATE_USER_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true
      };
    case GET_USER_DATA:
      return {
        ...state,
        profile: action.payload
      };
    case SET_ONBOARD_FLAG:
      return {
        ...state,
        profile: { ...state.profile, onboard_complete: true }
      };
    default:
      return state;
  }
};
