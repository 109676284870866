/**
 * Content container to manage our layout size by having proper margin on both sides
 * and center aligning our stuff on the page
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
// Components
import { Row, Col } from 'antd';
// Styles
import styles from './ContentContainer.module.scss';

// Size options that can be passed to the content container
const SIZES = {
  large: 'large',
  small: 'small',
  extraLarge: 'extraLarge',
  fullWidth: 'fullWidth'
};

const ContentContainer = ({ children, className, size, showBg }) => {
  // Default medium span size
  let lg = {
    span: 10,
    offset: 7
  };

  // Check if large or small size is passed otherwise uses medium as default
  if (size === SIZES.large) {
    lg = {
      span: 12,
      offset: 6
    };
  } else if (size === SIZES.small) {
    lg = {
      span: 8,
      offset: 8
    };
  } else if (size === SIZES.extraLarge) {
    lg = {
      span: 16,
      offset: 4
    };
  } else if (size === SIZES.fullWidth) {
    lg = {
      span: 24,
      offset: 0
    };
  }

  return (
    <Row className={[styles.container, className].join(' ')}>
      <Col
        xs={{
          span: 24
        }}
        lg={lg}
        className={showBg ? styles.content : null}
      >
        {children}
      </Col>
    </Row>
  );
};

ContentContainer.propTypes = {
  className: PropTypes.string,
  showBg: PropTypes.bool
};

ContentContainer.defaultProps = {
  className: null,
  showBg: false
};

export default ContentContainer;
