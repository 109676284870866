import { getPlatform, isNativePlatform } from 'utils/capacitor';
import { PushNotifications } from '@capacitor/push-notifications';
import logger from 'utils/logger';
import notification from 'api/notification';
import setPushNotificationData from 'store/actions/push-notification/PushDataAction';
import { getPushData } from 'utils/push/push';
import { getAuth0Id } from 'utils/auth';
import { isDate, isEmpty, get } from 'lodash';
import moment from 'moment';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';

export const registerPushListener = ({
  existingPushToken,
  existingUserId,
  updatedOn,
  dispatch
}) => {
  if (isNativePlatform) {
    PushNotifications.addListener('registration', async (token) => {
      const pushToken = token.value;
      const userId = getAuth0Id();

      // make sure to refresh at-least once a day
      const hasExpired =
        !isDate(updatedOn) ||
        moment(new Date()).diff(moment(updatedOn), 'hours') > 24;

      if (
        existingPushToken === pushToken &&
        existingUserId === userId &&
        !hasExpired
      ) {
        logger.info(
          'Push notification token already registered',
          'Router.utils.registerPushListener'
        );
        return;
      }

      const pushData = getPushData({ pushToken });
      try {
        if (!isEmpty(pushData)) {
          await notification.registerDevice(pushData);
          dispatch(
            setPushNotificationData({
              token: pushToken,
              userId: pushData.auth0Id,
              updatedOn: new Date()
            })
          );
        } else {
          logger.error(
            'Push data is empty',
            'Router.utils.registerPushListener'
          );
        }
      } catch (error) {
        logger.error(
          'Error registering device for push notification',
          'Router.utils.registerPushListener',
          { error }
        );
      }
    });

    PushNotifications.addListener('registrationError', (error) => {
      logger.error(
        'Push notification registration error',
        'Router.utils.registerPushListener',
        {
          error
        }
      );
    });

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (action) => {
        const category = get(action, 'notification.data.category', '');
        const analyticsMessage = 'Opened app by clicking on push notification';
        if (isEmpty(category)) {
          logger.error(
            'Push notification action category is empty',
            'Router.utils.registerPushListener'
          );
          analytics.track(eventCategories.PUSH_NOTIFICATION, analyticsMessage, {
            platform: getPlatform()
          });
        } else {
          analytics.track(eventCategories.PUSH_NOTIFICATION, analyticsMessage, {
            platform: getPlatform(),
            category
          });
        }
      }
    );
  }
};
