import React, { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get, isEmpty } from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { NetworkStatus } from '@apollo/client';
// utils
import { FETCH_POLICIES } from 'utils/graphql';
import {
  GET_SESSION_LIST,
  GET_SELECTED_GOAL_INFO
} from 'containers/SelfUse/queries';
// components
import Lang from 'components/Lang';
import ErrorRetry from 'components/ErrorRetry';
import Graph from '../Session/Tracker/Graph';
import { LoaderSmall, LoaderLarge } from './TrackerCard.skeleton';
// styles
import styles from './TrackerCard.module.scss';
import { breakpointMedium } from '../../../../styles/config.scss';
// Component utils
import { getTrackerContent } from './TrackerCard.utils';

const { trackerCardTitleStyle, graphContainer } = styles;

const Card = () => {
  const {
    loading: fetchingGoalId,
    error: goalInfoError,
    data: currentGoalData,
    refetch: refetchGoalInfo,
    networkStatus: goalInfoQueryStatus
  } = useQuery(GET_SELECTED_GOAL_INFO, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: FETCH_POLICIES.cacheFirst
  });

  const goalId = get(currentGoalData, 'getSelectedGoalInfo.goalId', '');
  const version = get(currentGoalData, 'getSelectedGoalInfo.version', '');

  const {
    loading: fetchingSessions,
    error: sessionListError,
    data: fetchedSessions,
    refetch: refetchSessionList,
    networkStatus: sessionListQueryStatus
  } = useQuery(GET_SESSION_LIST, {
    variables: { goalId, version },
    skip: !goalId || !version,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: FETCH_POLICIES.cacheFirst
  });

  const isLargeScreen = useMediaQuery(`(min-width: ${breakpointMedium})`);

  const LoaderComponent = useMemo(
    () => (isLargeScreen ? LoaderLarge : LoaderSmall),
    [isLargeScreen]
  );

  if (
    fetchingGoalId ||
    fetchingSessions ||
    [goalInfoQueryStatus, sessionListQueryStatus].includes(
      NetworkStatus.refetch
    )
  ) {
    return <LoaderComponent />;
  }

  if (goalInfoError || sessionListError) {
    return (
      <ErrorRetry
        isFetching={false}
        onClick={() =>
          goalInfoError ? refetchGoalInfo() : refetchSessionList()
        }
        title={<Lang path="selfUse.session.error.errorText" />}
      />
    );
  }

  const { emoticon, subheader, description, noProgressText } =
    getTrackerContent(fetchedSessions);
  const trackerProgress = get(
    fetchedSessions,
    'getSession.tools.trackerProgress',
    {}
  );

  return !isEmpty(trackerProgress) ? (
    <>
      <div data-testid="tracker-card-header" className={trackerCardTitleStyle}>
        {subheader}
      </div>
      <div
        data-testid="tracker-card-graph-container"
        className={graphContainer}
      >
        <Graph
          emoticon={emoticon}
          subheader={subheader}
          description={description}
          trackerProgress={trackerProgress}
          noProgressText={noProgressText}
        />
      </div>
    </>
  ) : null;
};

export default Card;
