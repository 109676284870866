/**
 * Root page container
 */

// Core
import React, { Component, Fragment } from 'react';
import { parse } from 'tiny-querystring';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openVideoModal } from 'store/actions/media/VideoPlayerModalAction';
// utils
import logger from 'utils/logger/logger';
import { isLoggedIn } from 'utils/auth';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import helpers from 'utils/helpers';
import { useNetworkListener } from 'utils/hooks';
// hocs
import withoutAuth from 'hoc/withoutAuth';
import loginFromUrl from 'hoc/loginFromUrl';
import withHook from 'hoc/withHook';
// Config
import config from 'config';
// assets
import i18n from 'assets/lang';
import poweredByLogo from 'assets/img/powered-by-logo.svg';
import Logo from '../../assets/icons/play.svg';
import Lang from '../../components/Lang';
import toast from '../../components/Toast';
import Aux from '../../components/layout/Aux';
import { ActionButton } from '../../components/ui/Buttons';
// styles
import styles from './Home.module.scss';

const { appName, cdnBaseUrl, videoUrl } = config;

class Home extends Component {
  constructor(props) {
    super(props);
    analytics.info(
      eventCategories.LANDING_PAGE,
      'user reached the signup/login screen',
      { trigger: 'login_view' }
    );
    logger.info('User reached the signup/login screen', 'Home.constructor');
    const stateObj = {
      logout: false,
      forcedLogout: false,
      forcedLogoutReason: ''
    };
    if (window.location.search) {
      const { action, reason } = parse(window.location.search.slice(1));
      if (action && action === 'logout') {
        stateObj.logout = true;
      }
      if (reason && ['expired', 'error'].includes(reason)) {
        logger.info(
          'Setting forceLogout variable to true',
          'Home.constructor',
          { reason }
        );
        stateObj.forcedLogout = true;
        stateObj.forcedLogoutReason = reason;
      }
    }
    this.state = stateObj;
  }

  componentDidMount() {
    if (isLoggedIn()) {
      this.props.history.push(helpers.homeUrl());
    } else if (this.props.companyName) {
      logger.info(
        'User had company name in url params, auto-redirect to company selection page',
        'Home.componentDidMount'
      );
      this.props.history.push(
        `/select_company?company_name=${this.props.companyName}`
      );
    }
    if (this.state.forcedLogout) {
      logger.info('Forced logout', 'Home.componentDidMount', {
        reason: this.state.forcedLogoutReason
      });
      this._showForcedLogoutToast(this.state.forcedLogoutReason);
    }
  }

  _showForcedLogoutToast = (reason) => {
    switch (reason) {
      case 'expired':
        logger.info(
          'Session expiration toast shown',
          'Home._showForcedLogoutToast'
        );
        toast.info(<Lang path="sessionExpiredText" />, null, {
          autoClose: 30000
        });
        break;
      case 'error':
        logger.info('Error toast shown', 'Home._showForcedLogoutToast');
        toast.warning(
          <Lang path="somethingWentWrongNotificationText" />,
          null,
          { autoClose: 30000 }
        );
        break;
      default:
        break;
    }
  };

  _handleStartClick = () => {
    analytics.track(eventCategories.LANDING_PAGE, 'clicked get started', {
      source: 'signup',
      trigger: 'login_click'
    });
    logger.info('Clicked on get started button', 'Home._handleStartClick');
    if (isLoggedIn()) {
      this.props.history.push(helpers.homeUrl());
    } else {
      this.props.history.push('/select_company?next=register');
    }
  };

  _handleLogin = () => {
    analytics.track(eventCategories.LANDING_PAGE, 'clicked signin', {
      source: 'login',
      trigger: 'login_click'
    });
    logger.info('Clicked on login button', 'Home._handleLogin');
    this.props.history.push('/select_company?next=login');
  };

  _showVideo = () => {
    const url = cdnBaseUrl + videoUrl;
    analytics.track(eventCategories.LANDING_PAGE, 'played home page video');
    logger.info(
      'Clicked play video button on the home page',
      'Home._showVideo'
    );
    const analyticsMessage = eventCategories.LANDING_PAGE;
    this.props.actions.openVideoModal({
      analyticsMessage,
      url,
      autoPlay: true
    });
  };

  render() {
    const {
      container,
      button,
      text,
      text2,
      play,
      descriptionTextStyle,
      signInButton,
      versionContainer,
      contentContainer,
      topContentContainer,
      bottomContentContainer,
      logoStyle,
      poweredByLogoContainer
    } = styles;

    return (
      <Aux>
        <div className={container}>
          <div className={contentContainer}>
            <div className={topContentContainer}>
              {config.videoUrl !== 'none' && !this.props.hideVideo && (
                <Fragment>
                  <input
                    type="image"
                    className={play}
                    src={Logo}
                    alt={i18n.t('alternateText.image.logo', { appName })}
                    height="66"
                    width="66"
                    onClick={this._showVideo}
                  />
                </Fragment>
              )}
              {(config.videoUrl === 'none' || this.props.hideVideo) && (
                <div className={play} style={{ visibility: 'hidden' }} />
              )}
              <p className={`${text} ${descriptionTextStyle}`}>
                <Lang
                  path="homeDescription"
                  values={{ appName }}
                  components={{ br: <br /> }}
                />
              </p>
            </div>
            <div className={bottomContentContainer}>
              <ActionButton
                className={button}
                type="secondary"
                onClick={this._handleStartClick}
                testId="home-btn-get-started"
              >
                <Lang path="startButtonText" />
              </ActionButton>
              <p className={text}>
                <Lang
                  path="homeCompliant"
                  values={{ appName }}
                  components={{ bold: <b /> }}
                />
              </p>
              <p className={text2}>
                <Lang path="loginOldUser" />
                <button
                  type="button"
                  data-testid="home-btn-sign-in"
                  className={signInButton}
                  onClick={this._handleLogin}
                >
                  <b>
                    <u>
                      <Lang path="loginButtonText" />
                    </u>
                  </b>
                </button>
              </p>
            </div>
          </div>
        </div>
        <div data-testid="root" className={versionContainer}>
          <p>{process.env.REACT_APP_BUILD_VER}</p>
        </div>
        {config.showPoweredByLogo === 'true' && helpers.platformInfo.isDesktop && (
          <div className={poweredByLogoContainer}>
            <img
              className={logoStyle}
              alt={i18n.t('alternateText.image.poweredBy', {
                appName: config.appName
              })}
              src={poweredByLogo}
            />
          </div>
        )}
      </Aux>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    openVideoModal: bindActionCreators(openVideoModal, dispatch)
  }
});

export default withoutAuth(
  loginFromUrl(
    connect(null, mapDispatchToProps)(withHook(Home, useNetworkListener))
  )
);
