import React from 'react';
import { InputAdornment } from '@material-ui/core';

import styles from './ErrorAdornment.module.scss';

const { errorAdornmentStyle } = styles;

const ErrorAdornment = () => (
  <InputAdornment position="end">
    <i className={['fa fa-times', errorAdornmentStyle].join(' ')} />
  </InputAdornment>
);

export default ErrorAdornment;
