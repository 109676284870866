/**
 * Reducer that manages the loading and error state when fetching reports by id
 */

import {
  GET_REPORT_BY_ID_ERROR,
  GET_REPORT_BY_ID,
  GET_REPORT_BY_ID_DONE
} from '../actions/types';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REPORT_BY_ID:
      return {
        ...state,
        isFetching: true
      };
    case GET_REPORT_BY_ID_DONE:
      return {
        ...state,
        isFetching: false,
        hasError: false
      };
    case GET_REPORT_BY_ID_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true
      };
    default:
      return state;
  }
};
