import React from 'react';
import { useTranslation } from 'react-i18next';
import mdLiveLogo from 'assets/img/dashboard/videotherapy/mock_vendor.png';
import Lang from '../../../../../components/Lang';

import styles from './ProvidedByMockVendor.module.scss';

const {
  providedByMockVendorContainer,
  providedByTextStyle,
  mockVendorLogoStyle
} = styles;

const ProvidedByMockVendor = () => {
  const { t } = useTranslation();
  return (
    <div className={providedByMockVendorContainer}>
      <span className={providedByTextStyle}>
        <Lang path="therapyRequest.form.providedByText" />:
      </span>
      <img
        src={mdLiveLogo}
        alt={t('alternateText.image.mockVendor')}
        className={mockVendorLogoStyle}
      />
    </div>
  );
};

export default ProvidedByMockVendor;
