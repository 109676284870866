import gql from 'graphql-tag';

const CHAT_USERS_PAGINATE_LIMIT = 100;

const GET_CHAT_USER_INFO = gql`query
{
    getChatUserInfo(
        input: {
            pagination: {
                limit: ${CHAT_USERS_PAGINATE_LIMIT},
                pageNum: 1
            }
        }
    )
    {
        user {
            name
            image
            from
            to
            company
            partner
            unread
            birthDay
            birthMonth
            birthYear
            gender
        }
        pagination {
            hasNextPage
        }
    }
}
`;

const GET_CHAT_USER_INFO_FOR_COACH = gql`query getChatUserInfo(
    $pageNum: Int!, $search: String, $message: String, $activity: String) {
        getChatUserInfo(
            input: {
                pagination: {
                    limit: ${CHAT_USERS_PAGINATE_LIMIT},
                    pageNum: $pageNum
                },
                filters: {
                    search: $search
                    activity: $activity
                    message: $message
                }
            }
        )
        {
            user {
                name
                image
                from
                to
                company
                partner
                unread
                birthDay
                birthMonth
                birthYear
                gender
                appMetaData {
                    hasSuicidalIdeationAlertFeature
                }
            }
            pagination {
                hasNextPage
            }
        }
}
`;

const GET_CHAT_MESSAGES = gql`
  query {
    getChatMessages(input: { pagination: { limit: 20 } }) {
      messages {
        id
        from
        to
        message {
          text
          coachDesignation
          coachAbout
          header
          body
        }
        content {
          id
          type
          duration
          header
          subHeader
          url
          urlHlsv3
          urlCommon
          thumbnailUrl
          tags
        }
        type
        readFlag
        createdAt
      }
      pagination {
        hasNextPage
      }
    }
  }
`;

const GET_MORE_CHAT_MESSAGES = gql`
  query getChatMessages($before: String) {
    getChatMessages(input: { pagination: { limit: 20, before: $before } }) {
      messages {
        id
        from
        to
        message {
          text
          coachDesignation
          coachAbout
          header
          body
        }
        content {
          id
          type
          duration
          header
          subHeader
          url
          urlHlsv3
          urlCommon
          thumbnailUrl
          tags
        }
        type
        readFlag
        createdAt
      }
      pagination {
        hasNextPage
      }
    }
  }
`;

const GET_CHAT_MESSAGES_AFTER = gql`
  query getChatMessages($after: String) {
    getChatMessages(input: { pagination: { limit: 20, after: $after } }) {
      messages {
        id
        from
        to
        message {
          text
          coachDesignation
          coachAbout
          header
          body
        }
        content {
          id
          type
          duration
          header
          subHeader
          url
          urlHlsv3
          urlCommon
          thumbnailUrl
          tags
        }
        type
        readFlag
        createdAt
      }
      pagination {
        hasNextPage
      }
    }
  }
`;

const GET_CHAT_MESSAGES_FOR_CLIENT = gql`
  query getChatMessages($to: String!, $from: String!) {
    getChatMessages(
      input: { pagination: { limit: 20 }, to: $to, from: $from }
    ) {
      messages {
        id
        from
        to
        message {
          text
          coachDesignation
          coachAbout
          header
          body
        }
        content {
          id
          type
          duration
          header
          subHeader
          url
          urlHlsv3
          urlCommon
          thumbnailUrl
          tags
        }
        type
        readFlag
        createdAt
      }
      pagination {
        hasNextPage
      }
    }
  }
`;

const GET_MORE_CHAT_MESSAGES_FOR_CLIENT = gql`
  query getChatMessages($to: String!, $from: String!, $before: String!) {
    getChatMessages(
      input: {
        pagination: { limit: 20, before: $before }
        to: $to
        from: $from
      }
    ) {
      messages {
        id
        from
        to
        message {
          text
          coachDesignation
          coachAbout
          header
          body
        }
        content {
          id
          type
          duration
          header
          subHeader
          url
          urlHlsv3
          urlCommon
          thumbnailUrl
          tags
        }
        type
        readFlag
        createdAt
      }
      pagination {
        hasNextPage
      }
    }
  }
`;

const GET_CHAT_MESSAGES_AFTER_FOR_CLIENT = gql`
  query getChatMessages($after: String, $to: String, $from: String) {
    getChatMessages(
      input: { pagination: { limit: 20, after: $after }, to: $to, from: $from }
    ) {
      messages {
        id
        from
        to
        message {
          text
          coachDesignation
          coachAbout
          header
          body
        }
        content {
          id
          type
          duration
          header
          subHeader
          url
          urlHlsv3
          urlCommon
          thumbnailUrl
          tags
        }
        type
        readFlag
        createdAt
      }
      pagination {
        hasNextPage
      }
    }
  }
`;

const GET_USER_REPORTS = gql`
  query getUserReports($clientAuth0Id: String!) {
    getUserReports(input: { clientAuth0Id: $clientAuth0Id, type: "coach" }) {
      suicidal
      domesticViolence
      assessments {
        id
        assessmentName
        categories {
          name
          level
          category
        }
        suicidal
        domesticViolence
        timestamp
      }
    }
  }
`;

const GET_USER_PROGRESS = gql`
  query getUserProgress($clientAuth0Id: String!) {
    getUserProgress(input: { clientAuth0Id: $clientAuth0Id }) {
      goals {
        displayName
        category
        type
        isSelected
        tools {
          ...ToolMeta
          data {
            ...ToolInfo
            data {
              ...ToolInfo
              data {
                ...ToolChild
              }
            }
          }
        }
      }
    }
  }

  fragment ToolMeta on Tool {
    displayName
    type
  }

  fragment ToolInfo on Tool {
    displayName
    value
    count
    type
    heading
  }

  fragment ToolChild on Tool {
    displayName
    count
  }
`;

const GET_COACH_SUGGESTIONS = gql`
  query getCoachSuggestions($message: String!) {
    getCoachSuggestions(input: { message: $message }) {
      suggestions {
        version
        text {
          message
          intentId
          intentScore
          order
        }
        video {
          id
          intentId
          intentScore
          heading
          duration
          url
          urlHlsv3
          urlCommon
          thumbnailUrl
          order
        }
      }
    }
  }
`;

const GET_BREATHING_EXERCISES = gql`
  query getBreathingExercise {
    getBreathingExercise {
      breathingExercises {
        id
        description
        subheader
        audio {
          duration
          audioId
          audioUrl
          imageUrl
          imageUrlMobile
        }
        video {
          videoId
          urlMp4
          urlHlsv4
          urlHlsv3
        }
      }
    }
  }
`;

export default {
  GET_CHAT_USER_INFO,
  GET_CHAT_MESSAGES,
  GET_MORE_CHAT_MESSAGES,
  GET_CHAT_MESSAGES_AFTER,
  GET_CHAT_MESSAGES_FOR_CLIENT,
  GET_MORE_CHAT_MESSAGES_FOR_CLIENT,
  GET_CHAT_MESSAGES_AFTER_FOR_CLIENT,
  GET_CHAT_USER_INFO_FOR_COACH,
  GET_USER_REPORTS,
  GET_USER_PROGRESS,
  GET_COACH_SUGGESTIONS,
  GET_BREATHING_EXERCISES
};
