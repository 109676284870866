/**
 * Button wrapped in our custom style
 */

import React from 'react';
import PropTypes from 'prop-types';

import styles from './UnderlineButton.module.scss';

const { container } = styles;

const UnderlineButton = ({
  children,
  buttonText,
  onClick,
  className,
  testId,
  ...rest
}) => {
  const classes = [container];
  if (className) {
    classes.push(className);
  }
  return (
    <button
      type="button"
      className={classes.join(' ')}
      onClick={onClick}
      data-testid={testId}
      {...rest}
    >
      {children || buttonText}
    </button>
  );
};

UnderlineButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default UnderlineButton;
