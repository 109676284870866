// core
import React from 'react';
import { Card } from '@material-ui/core';
// assets
import bigCardImg from 'assets/img/dashboard/actionplan/self.jpg';
// styles
import styles from './GoalDescription.module.scss';

const { cardContainer, imgStyle, textContainer } = styles;

const GoalDescription = (props) => (
  <Card className={cardContainer}>
    <div style={{ background: `url(${bigCardImg}` }} className={imgStyle} />
    <div data-testid="goal-description-text" className={textContainer}>
      <p>{props.bigCardText}</p>
    </div>
  </Card>
);

export default GoalDescription;
