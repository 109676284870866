import React from 'react';
import propTypes from 'prop-types';
// components
import Lang from '../../../Lang';
// styles
import styles from './PasswordValidator.module.scss';

const { container, validRuleStyle } = styles;

const PasswordValidator = ({
  isLengthValid,
  isRuleCountValid,
  hasLowerCase,
  hasUpperCase,
  hasNumber,
  hasSpecialChar
}) => (
  <ul className={container}>
    <li className={isLengthValid ? validRuleStyle : null}>
      {isLengthValid ? (
        <i className="fa fa-check" />
      ) : (
        <i className="fa fa-times" />
      )}
      <Lang path="validation.password.validLength" />
    </li>
    <li className={isRuleCountValid ? validRuleStyle : null}>
      {isRuleCountValid ? (
        <i className="fa fa-check" />
      ) : (
        <i className="fa fa-times" />
      )}
      <Lang path="validation.password.ruleCount" />
    </li>
    <li className={hasLowerCase ? validRuleStyle : null}>
      {hasLowerCase ? (
        <i className="fa fa-check" />
      ) : (
        <i className="fa fa-times" />
      )}
      <Lang path="validation.password.hasLowerCase" />
    </li>
    <li className={hasUpperCase ? validRuleStyle : null}>
      {hasUpperCase ? (
        <i className="fa fa-check" />
      ) : (
        <i className="fa fa-times" />
      )}
      <Lang path="validation.password.hasUpperCase" />
    </li>
    <li className={hasNumber ? validRuleStyle : null}>
      {hasNumber ? (
        <i className="fa fa-check" />
      ) : (
        <i className="fa fa-times" />
      )}
      <Lang path="validation.password.hasNumber" />
    </li>
    <li className={hasSpecialChar ? validRuleStyle : null}>
      {hasSpecialChar ? (
        <i className="fa fa-check" />
      ) : (
        <i className="fa fa-times" />
      )}
      <Lang path="validation.password.hasSpecialCharacter" />
    </li>
  </ul>
);

PasswordValidator.propTypes = {
  isLengthValid: propTypes.bool.isRequired,
  isRuleCountValid: propTypes.bool.isRequired,
  hasLowerCase: propTypes.bool.isRequired,
  hasUpperCase: propTypes.bool.isRequired,
  hasNumber: propTypes.bool.isRequired,
  hasSpecialChar: propTypes.bool.isRequired
};

export default PasswordValidator;
