import i18n from 'assets/lang';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import logger from '../../../../../utils/logger';

const validateText = (text) => {
  if (text && text.trim()) {
    return { isValid: true };
  }
  return {
    isValid: false,
    errorText: i18n.t('selfUse.session.validations.emptyField', {
      field: i18n.t('selfUse.session.fields.response')
    })
  };
};

const onAddHandler = ({ goalId, sessionId, screenId }) => {
  analytics.track(eventCategories.SELF_USE, 'clicked on add textInput button', {
    goalId,
    sessionId,
    screenId,
    subcategory: 'TextInputScreen'
  });
  logger.info(
    'User has clicked on add textInput button.',
    'TextInputScreen.utils.onAddHandler',
    {
      goalId,
      sessionId,
      screenId
    }
  );
};

const onSaveTextInputHandler = ({
  data,
  setTextInputData,
  setCurrentTextInputData,
  goalId,
  sessionId,
  screenId
}) => {
  const inputData = {
    text: data,
    createdAt: new Date().toISOString(),
    isCustom: true
  };
  setTextInputData((previousState) => [inputData, ...previousState]);
  setCurrentTextInputData((previousState) => [inputData, ...previousState]);
  analytics.track(eventCategories.SELF_USE, 'clicked on add textInput button', {
    goalId,
    sessionId,
    screenId,
    subcategory: 'TextInputScreen'
  });
  logger.info(
    'User has clicked on save TextInput button.',
    'TextInputScreen.utils.onSaveTextInputHandler'
  );
};

const onCancelHandler = ({ goalId, sessionId, screenId }) => {
  analytics.track(
    eventCategories.SELF_USE,
    'clicked on cancel add TextInput button',
    {
      goalId,
      sessionId,
      screenId,
      subcategory: 'TextInput'
    }
  );
  logger.info(
    'User has clicked on cancel add TextInput button.',
    'TextInputScreen.utils.onCancelHandler',
    {
      goalId,
      sessionId,
      screenId
    }
  );
};
export { onAddHandler, onSaveTextInputHandler, validateText, onCancelHandler };
