/**
 *
 * Request received
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// components
// styles
// assets
import requestReceivedImg from 'assets/img/dashboard/coach_requested.png';
// action
import { showModal, closeModal } from 'store/actions/ModalAction';
// utils
import analytics from 'utils/analytics/analytics';
import featureService from 'utils/feature-service';
// config
import config from 'config';
import style from './RequestReceived.module.scss';
import Lang from '../../../../../components/Lang';
import { ActionButton } from '../../../../../components/ui/Buttons';
import SmartAppLink from '../../../../../components/Popups/SmartAppLink';

const { appName } = config;

function RequestReceived({ actions, goalId, severity }) {
  const requestReceivedHandler = () => {
    analytics.track('modal coach request received', 'clicked start here', {
      goalId,
      severity
    });
    if (featureService._hasCoachAccessOnWebFeature()) {
      actions.closeModal();
    } else {
      actions.showModal(SmartAppLink, {
        source: 'smart-app-link',
        props: {
          goalId,
          severity,
          subcategory: 'request received',
          headerText: <Lang path="coachRequestedHeaderText" />
        }
      });
    }
  };
  return (
    <div>
      <div className={style.topSection}>
        <img src={requestReceivedImg} alt="" />
        <h2>
          <Lang path="popups.requestReceivedHeader" />
        </h2>
        <p>
          <Lang
            path="popups.requestReceivedMessage"
            values={{
              appName
            }}
          />
        </p>
      </div>
      <div className={style.btmSection}>
        <ActionButton
          onClick={() => requestReceivedHandler()}
          className={style.requestButton}
        >
          <Lang path="popups.startHere" />
        </ActionButton>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    showModal: bindActionCreators(showModal, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch)
  }
});

const mapStateToProps = (state) => ({
  goalId: state.dashBoard.goalId,
  severity: state.dashBoard.severity,
  coachRequestStatus: state.requestCoach.coachRequestStatus
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestReceived);
