// core
import React from 'react';
// components
import { Modal } from 'antd';
// utils
import moment from 'utils/moment';
// assets
import genderOptions from 'assets/data/profile/genderOptions';
import Lang from '../../../../../components/Lang';
// styles
import { ActionButton } from '../../../../../components/ui/Buttons';
import styles from './ClientDetails.module.scss';

const { confirm } = Modal;

const {
  container,
  infoItemStyle,
  infoContainer,
  suicidalAlertContainer,
  suicidalAlertButtonStyle
} = styles;

const _handleSuicidalAlert = (
  suicidalAlertClickHandler,
  clientAuth0Id,
  clientName
) => {
  confirm({
    title: (
      <span data-testid="confirm-modal-title">
        <Lang values={{ clientName }} path="suicidalAlertConfirmationText" />
      </span>
    ),
    okText: (
      <span data-testid="confirm-modal-ok-text">
        <Lang path="okText" />
      </span>
    ),
    cancelText: (
      <span data-testid="confirm-modal-cancel-text">
        <Lang path="cancelText" />
      </span>
    ),
    onOk() {
      return suicidalAlertClickHandler(clientAuth0Id, clientName);
    }
  });
};

const _renderDateOfBirth = ({ birthDay, birthMonth, birthYear }) => {
  // Add default values in case some fields are missing in user metadata
  // It'll allow us to format using momentJS and we'll hide fields that are
  // not available while formatting
  const month = birthMonth || '01';
  const day = birthDay || '01';
  const year = birthYear || '1970';
  const dateOfBirth = moment(new Date(`${month}/${day}/${year}`));
  let momentDateOfBirth = null;
  if (birthDay && birthMonth && birthYear) {
    momentDateOfBirth = dateOfBirth.format('MMMM Do, YYYY');
  } else if (birthMonth && birthYear) {
    momentDateOfBirth = dateOfBirth.format('MMMM, YYYY');
  } else if (birthYear) {
    momentDateOfBirth = dateOfBirth.format('YYYY');
  }

  const age = moment().diff(dateOfBirth, 'years');
  return (
    <React.Fragment>
      <div className={infoItemStyle}>
        <span>
          <Lang path="coachChat.chatRight.clientDetails.dobText" />
        </span>
        : {momentDateOfBirth}
      </div>
      <div className={infoItemStyle}>
        <span>
          <Lang path="coachChat.chatRight.clientDetails.ageText" />
        </span>
        : {age} <Lang path="coachChat.chatRight.clientDetails.agePostfixText" />
      </div>
    </React.Fragment>
  );
};

const ClientDetails = ({
  name,
  company,
  partner,
  gender,
  birthMonth,
  birthYear,
  birthDay,
  clientReport,
  to,
  appMetaData,
  suicidalAlertClickHandler,
  isFetchingSuicidalAlert
}) => {
  const genderObj = genderOptions.find((item) => item.value === gender);
  let genderLabel = gender;
  if (genderObj) {
    genderLabel = genderObj.label;
  }
  return (
    <div className={container}>
      <div data-testid="info-container" className={infoContainer}>
        {company && (
          <div className={infoItemStyle}>
            <span>
              <Lang path="coachChat.chatRight.clientDetails.employerText" />
            </span>
            : {company}
          </div>
        )}
        {partner && (
          <div className={infoItemStyle}>
            <span>
              <Lang path="coachChat.chatRight.clientDetails.partnerText" />
            </span>
            : {partner}
          </div>
        )}
        {gender && (
          <div className={infoItemStyle}>
            <span>
              <Lang path="coachChat.chatRight.clientDetails.genderText" />
            </span>
            : {genderLabel}
          </div>
        )}
        {_renderDateOfBirth({ birthMonth, birthYear, birthDay })}
        {clientReport && (
          <div className={infoItemStyle}>
            <span>
              <Lang path="suicidalIdeationText" />
            </span>
            :{' '}
            {clientReport.isFetching ? (
              <Lang path="loadingTextEllipsis" />
            ) : (
              clientReport.suicidal
            )}
          </div>
        )}
        {clientReport && (
          <div className={infoItemStyle}>
            <span>
              <Lang path="domesticViolenceText" />
            </span>
            :{' '}
            {clientReport.isFetching ? (
              <Lang path="loadingTextEllipsis" />
            ) : (
              clientReport.domesticViolence
            )}
          </div>
        )}
        {to && (
          <div className={infoItemStyle}>
            <span>
              <Lang path="coachChat.chatRight.clientDetails.userIdText" />
            </span>
            : {to}
          </div>
        )}
      </div>
      {appMetaData && appMetaData.hasSuicidalIdeationAlertFeature && (
        <div
          className={suicidalAlertContainer}
          data-testid="suicidal-alert-container"
        >
          <ActionButton
            testId="send-si-alert-button"
            isLoading={isFetchingSuicidalAlert}
            className={suicidalAlertButtonStyle}
            onClick={() =>
              _handleSuicidalAlert(suicidalAlertClickHandler, to, name)
            }
          >
            <Lang path="sendSuicidalAlertButtonText" />
          </ActionButton>
        </div>
      )}
    </div>
  );
};

export default ClientDetails;
