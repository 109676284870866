export default {
  TOOLS: 'tools',
  DASHBOARD: 'dashboard',
  LEFT_MENU: 'leftmenu',
  TAB_MENU: 'tabmenu',
  TOOLBAR: 'toolbar',
  SELF_USE: 'self-use',
  SESSION: 'session',
  PROFILE: 'profile',
  RESOURCES: 'resources',
  ACCOUNT: 'account',
  PASSWORD: 'password',
  APP: 'APP',
  PUSH_NOTIFICATION: 'push-notification',
  LANDING_PAGE: 'landing page',
  COMPANY_SELECT: 'company select',
  ASSESSMENT: 'assessment',
  COACH_ASSIGNMENT: 'coach-assignment',
  REPORT: 'report',
  WORK_LIFE_REQUEST: 'work-life-request',
  MODAL: 'modal'
};
