/**
 * The new red button with icon support
 */

import React from 'react';
import Icon from '@material-ui/core/Icon';
import { Icon as LoadingIcon } from 'antd';
import styles from './OutlineButton.module.scss';
import Aux from '../../../layout/Aux';

const { container, buttonText, loadingIconStyle } = styles;

const OutlineButton = ({
  onClick,
  children,
  icon,
  className,
  disabled,
  isLoading,
  testId,
  ...rest
}) => {
  const containerClassNames = [];

  // Container class names
  containerClassNames.push(container);
  if (className) {
    containerClassNames.push(className);
  }

  // Button text class names
  const buttonTextClassNames = [];
  if (icon) {
    buttonTextClassNames.push(buttonText);
  }
  return (
    <button
      type="button"
      className={containerClassNames.join(' ')}
      onClick={onClick}
      disabled={isLoading || disabled}
      data-testid={testId}
      {...rest}
    >
      {icon && <Icon>{icon}</Icon>}
      <span className={buttonTextClassNames.join(' ')}>
        {isLoading && (
          <Aux>
            <LoadingIcon
              className={loadingIconStyle}
              style={{ fontSize: 14, margin: 0 }}
              type="loading-3-quarters"
              spin
              data-testid="outline-button-loader"
            />{' '}
            &nbsp;
          </Aux>
        )}
        {children}
      </span>
    </button>
  );
};

export default OutlineButton;
