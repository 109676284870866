// core
import React, { useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import classNamesInstance from 'classnames/bind';
// components
import { List } from '@material-ui/core';
import { getAuth0Id } from 'utils/auth';
import analytics from 'utils/analytics/analytics';
import moment from 'utils/moment';
import helpers from 'utils/helpers';
import { Spinner } from '../../../../components/ui/Layout';
import ChatDateMarker from '../DateMarker';
import Message from '../Message';

import { ChatListError, PaginationError, SubscriptionError } from '../Error';
// styles
import styles from './MessageList.module.scss';

const classNames = classNamesInstance.bind(styles);
const { container, loadingContainer, scrollRefStyle } = styles;
const MessageList = ({
  messages,
  lastMessageVisible,
  isLoading,
  paginationError,
  subscriptionError,
  error,
  retry,
  paginationRetry,
  subscriptionRetry,
  isCoach,
  coachSuggestionsLoading,
  coachName,
  coachImage
}) => {
  const autoScrollRef = useRef(null);
  const messageListRef = useRef(null);
  let prevDate = null;
  const auth0Id = getAuth0Id();

  const { isOpen: isKeyboardOpen } = useSelector((state) =>
    get(state, 'keyboard')
  );

  // effect to auto scroll
  // TODO: can be modified to not auto scroll if the user is viewing history
  // and receives a message from coach
  const scrollToBottom = useCallback(() => {
    if (autoScrollRef && autoScrollRef.current && lastMessageVisible) {
      autoScrollRef.current.scrollIntoView({
        block: 'end',
        behaviour: 'smooth'
      });
    }
  }, [lastMessageVisible]);

  const containerClasses = [container];
  const retryHandler = () => {
    analytics.track(
      'coach chat window message list',
      'clicked retry to load the messages'
    );
    retry();
  };

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  useEffect(() => {
    if (isKeyboardOpen) {
      scrollToBottom();
    }
  }, [isKeyboardOpen, scrollToBottom]);

  useEffect(() => {
    if (!coachSuggestionsLoading) {
      scrollToBottom();
    }
  }, [coachSuggestionsLoading, scrollToBottom]);

  // Conditional classames
  const listStyleClass = classNames({
    listStyle: true,
    listStyleDesktop: helpers.platformInfo.isDesktop,
    listStyleMobileWeb: helpers.platformInfo.isMobileWeb && isKeyboardOpen
  });

  return (
    <div
      className={containerClasses.join(' ')}
      ref={messageListRef}
      data-testid="message-list-container"
    >
      {error ? (
        <ChatListError retry={retryHandler} />
      ) : (
        <List
          className={listStyleClass}
          id="scroll-in-chat-list"
          data-testid="scroll-in-chat-list"
        >
          {isLoading && (
            <div className={loadingContainer}>
              <Spinner />
            </div>
          )}
          {paginationError && <PaginationError retry={paginationRetry} />}
          {messages.map((message) => {
            const messageDate = moment(message.createdAt).format('YYYY-MM-DD');
            const messageTime = moment(message.createdAt).format('HH:mm');
            const isDateDifferent = messageDate !== prevDate;
            const messageComponent = [];
            if (isDateDifferent) {
              messageComponent.push(
                <ChatDateMarker key={messageDate} timeStamp={messageDate} />
              );
            }
            prevDate = messageDate !== prevDate ? messageDate : prevDate;
            messageComponent.push(
              <Message
                key={message.id}
                auth0Id={auth0Id}
                messageTime={messageTime}
                scroll={scrollToBottom}
                retry={retry}
                isCoach={isCoach}
                message={message}
                coachName={coachName}
                coachImage={coachImage}
              />
            );
            return messageComponent;
          })}
          {subscriptionError && <SubscriptionError retry={subscriptionRetry} />}
          <div className={scrollRefStyle} ref={autoScrollRef} />
        </List>
      )}
    </div>
  );
};
export default MessageList;
