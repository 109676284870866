import { createMuiTheme } from '@material-ui/core';
import {
  brandColorForegroundPrimary,
  fontSizeHeaderDefault
} from 'styles/config.scss';

export default createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: '16px',
        '&:hover $notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.42)'
        },
        fontSize: fontSizeHeaderDefault
      },
      notchedOutline: {
        border: '2px solid',
        borderColor: '#F3F3F5'
      },
      input: {
        color: brandColorForegroundPrimary
      }
    },
    MuiFormControl: {
      root: {
        marginBottom: '20px'
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: fontSizeHeaderDefault,
        color: '#b3b4b7'
      }
    },
    MuiInput: {
      root: {
        fontSize: fontSizeHeaderDefault
      },
      underline: {
        '&:before': {
          borderBottom: '2px solid #F3F3F5'
        },
        '&&&&:hover:before': {
          borderBottom: '2px solid rgba(0, 0, 0, 0.42)'
        }
      },
      input: {
        color: brandColorForegroundPrimary,
        marginTop: '4px',
        marginBottom: '2px'
      }
    },
    MuiInputAdornment: {
      root: {
        color: 'rgba(0, 0, 0, 0.42)'
      }
    }
  },
  palette: {
    primary: {
      main: '#17B6CC'
    }
  }
});
