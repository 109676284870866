import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  useContext,
  useMemo
} from 'react';
import {
  useQuery,
  useMutation,
  useApolloClient,
  useSubscription
} from '@apollo/react-hooks';
import propTypes from 'prop-types';
import { serializeError } from 'serialize-error';
import _ from 'lodash';
import { Icon } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  queries,
  subscriptions,
  mutations,
  mutationOptions,
  functions
} from 'utils/graphql';
import subscriptionOptions from 'utils/graphql/subscriptions/subscription-options';
import logger from 'utils/logger';
import analytics from 'utils/analytics';
import { CHAT_POLLING_TIMEOUT } from 'utils/graphql/graphQL-config';
import helpers from 'utils/helpers';
import featureService from 'utils/feature-service';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Ripple } from '../../../../../components/ui/Layout';
import Lang from '../../../../../components/Lang';
import MessageList from '../../../components/MessageList';
import CoachChatInput from '../CoachChatInput';
import CoachChatFiltersContext from '../ChatFilter/CoachChatFiltersContext';
import { NetworkError } from '../Error';
import styles from './ChatCenter.module.scss';

const {
  chatCenterContainer,
  container,
  inputContainer,
  clientInfoContainer,
  clientDetailsContainer,
  clientInfoButtonStyle,
  infoIconStyle,
  noClientsAssignedContainer,
  clientInitialStyle
} = styles;

const { CHAT_MESSAGE_RECEIVED_COACH, CHAT_MESSAGE_READ } = subscriptions;
const { getCoachChatMessageReceivedOptions, readFlagSubscriptionOptions } =
  subscriptionOptions;
const {
  GET_CHAT_MESSAGES_FOR_CLIENT,
  GET_MORE_CHAT_MESSAGES_FOR_CLIENT,
  GET_CHAT_MESSAGES_AFTER_FOR_CLIENT,
  GET_COACH_SUGGESTIONS
} = queries;
const { SEND_CHAT_MESSAGE_COACH } = mutations;
const { getCoachSendChatMessageOptions } = mutationOptions;
const { syncWithServer, transformSuggestions } = functions;
const { createInitials } = helpers;
const { isDesktop, isTab, isMobile } = helpers.platformInfo;

const ChatCenter = ({
  user,
  backButtonHandler,
  toggleDetails,
  chatListError,
  isChatListEmpty,
  isSessionExpired,
  mediaMessages,
  setMediaMessages,
  coachImage,
  coachName,
  infoTab
}) => {
  const [lastMessageVisible, setLastMessageVisible] = useState(true);
  const [coachSuggestionText, setCoachSuggestionText] = useState(null);
  const [showCoachSuggestions, setCoachSuggestionsVisible] = useState(false);
  const [loadingMoreMessages, setLoadingMoreMessages] = useState(false);
  const [paginationError, setPaginationError] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [networkError, setNetworkError] = useState(null);
  const to = _.get(user, 'to', null);
  const from = _.get(user, 'from', null);
  const name = _.get(user, 'name', null);
  const clientInitials = name ? createInitials(name) : '';
  const chatWindowScrollRef = useRef(null);
  const chatMessagesPolling = useRef(null);
  const readMessageSubscriptionHandle = useRef(null);
  const newMessagesSubscriptionHandle = useRef(null);
  const { coachChatFilters } = useContext(CoachChatFiltersContext);
  const client = useApolloClient();
  const [sendChatMessageMutation] = useMutation(SEND_CHAT_MESSAGE_COACH);
  const hasCoachSuggestionFeature = featureService._hasCoachSuggestionFeature();
  const {
    loading: chatMessagesLoading,
    error: chatMessagesError,
    data: chatMessagesData,
    subscribeToMore,
    fetchMore
  } = useQuery(GET_CHAT_MESSAGES_FOR_CLIENT, {
    variables: { to, from },
    skip: !from && !to,
    onError: (e) => {
      const statusCode = _.get(e, 'networkError.statusCode', null);
      if (statusCode === 401) {
        setNetworkError(<Lang path="sessionExpiredText" />);
        window.clearInterval(chatMessagesPolling.current);
      }
    }
  });

  const messages = _.get(chatMessagesData, 'getChatMessages.messages', []);
  const hasMoreMessages = _.get(
    chatMessagesData,
    'getChatMessages.pagination.hasNextPage',
    false
  );
  const lastMessageId = messages[0] && messages[0].id;
  const fetchMoreMessages = () =>
    fetchMore({
      query: GET_MORE_CHAT_MESSAGES_FOR_CLIENT,
      variables: { to, from, before: lastMessageId },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newMessages = fetchMoreResult.getChatMessages.messages;
        const { pagination } = fetchMoreResult.getChatMessages;
        return {
          ...prev,
          getChatMessages: {
            ...prev.getChatMessages,
            messages: [...newMessages, ...prev.getChatMessages.messages],
            pagination
          }
        };
      }
    });

  const loadPreviousMessages = () => {
    setPaginationError(false);
    setLoadingMoreMessages(true);
    fetchMoreMessages()
      .then(() => {
        setLoadingMoreMessages(false);

        const scrollElement = document.getElementById('scroll-in-chat-list');
        if (scrollElement && scrollElement.scrollTop === 0) {
          const lastMessage = document.getElementById(lastMessageId);
          if (lastMessage) {
            lastMessage.scrollIntoView();
          }
        }
      })
      .catch((error) => {
        setLoadingMoreMessages(false);
        setPaginationError(true);
        logger.error(
          `Error while loading more messages. Error message is ${error.toString()}`,
          'fetchMoreMessages',
          { error }
        );
      });
  };

  const onScroll = (e) => {
    const scrollPosition = _.get(e, 'target.scrollTop');
    const scrollHeight = _.get(e, 'target.scrollHeight');
    const clientHeight = _.get(e, 'target.clientHeight');
    const isVisible = scrollHeight - scrollPosition - 30 < clientHeight;
    if (lastMessageVisible !== isVisible) {
      setLastMessageVisible(isVisible);
    }
    if (
      scrollPosition === 0 &&
      !loadingMoreMessages &&
      hasMoreMessages &&
      !networkError &&
      !lastMessageVisible
    ) {
      loadPreviousMessages();
    }
  };

  const fetchCoachSuggestions = (message) => {
    setCoachSuggestionText(message);
    setCoachSuggestionsVisible(true);
  };

  useEffect(() => {
    if (!chatMessagesLoading && messages.length && hasCoachSuggestionFeature) {
      const lastMessage = messages[messages.length - 1];
      const message = _.get(lastMessage, 'message.text', null);
      const sender = _.get(lastMessage, 'from', null);
      if (message && sender !== from) {
        fetchCoachSuggestions(message);
      }
    }
  }, [chatMessagesLoading, messages, hasCoachSuggestionFeature, from]);

  const subscribeForNewMessages = useCallback(() => {
    let handle;
    if (from && to) {
      setSubscriptionError(false);
      handle = subscribeToMore(
        getCoachChatMessageReceivedOptions({
          to,
          from,
          subscription: CHAT_MESSAGE_RECEIVED_COACH,
          coachChatFilters,
          client,
          onError: () => {
            setSubscriptionError(true);
          }
        })
      );
    }
    return handle;
  }, [from, to, subscribeToMore, client, coachChatFilters]);

  const handleStateOnline = useCallback(() => {
    setNetworkError(null);
    if (!isSessionExpired) {
      syncWithServer({
        client,
        messages,
        catchUpQuery: GET_CHAT_MESSAGES_AFTER_FOR_CLIENT,
        query: GET_CHAT_MESSAGES_FOR_CLIENT,
        to,
        from,
        isCoach: true
      });

      chatMessagesPolling.current = setInterval(() => {
        syncWithServer({
          client,
          messages,
          catchUpQuery: GET_CHAT_MESSAGES_AFTER_FOR_CLIENT,
          query: GET_CHAT_MESSAGES_FOR_CLIENT,
          to,
          from,
          isCoach: true
        });
      }, CHAT_POLLING_TIMEOUT);
    }
  }, [to, from, client, messages, isSessionExpired]);

  const handleStateOffline = useCallback(() => {
    setNetworkError(<Lang path="offlineStateText" />);
    window.clearInterval(chatMessagesPolling.current);
  }, []);

  useEffect(() => {
    window.addEventListener('online', handleStateOnline);
    window.addEventListener('offline', handleStateOffline);

    return () => {
      window.removeEventListener('online', handleStateOnline);
      window.removeEventListener('offline', handleStateOffline);
    };
  }, [handleStateOnline, handleStateOffline]);

  readMessageSubscriptionHandle.current = useSubscription(CHAT_MESSAGE_READ, {
    skip: !to || !from,
    variables: { to, from },
    shouldResubscribe: true,
    onSubscriptionData: (data) => {
      readFlagSubscriptionOptions(data);
    }
  });

  useEffect(() => {
    if (!isSessionExpired && !isChatListEmpty) {
      chatMessagesPolling.current = setInterval(() => {
        syncWithServer({
          client,
          messages,
          catchUpQuery: GET_CHAT_MESSAGES_AFTER_FOR_CLIENT,
          query: GET_CHAT_MESSAGES_FOR_CLIENT,
          to,
          from,
          isCoach: true
        });
      }, CHAT_POLLING_TIMEOUT);
    }
    return () => {
      if (chatMessagesPolling.current) {
        clearInterval(chatMessagesPolling.current);
      }
    };
  }, [messages, client, from, to, isSessionExpired, isChatListEmpty]);

  useEffect(() => {
    setPaginationError(false);
    setLastMessageVisible(true);
    setCoachSuggestionsVisible(false);
    setMediaMessages([]);
    newMessagesSubscriptionHandle.current = subscribeForNewMessages();
    return () => {
      if (typeof newMessagesSubscriptionHandle.current === 'function') {
        newMessagesSubscriptionHandle.current();
      }
    };
  }, [user, subscribeForNewMessages, setMediaMessages]);

  useEffect(() => {
    if (isSessionExpired) {
      window.clearInterval(chatMessagesPolling.current);
      if (typeof readMessageSubscriptionHandle.current === 'function') {
        readMessageSubscriptionHandle.current();
      }

      if (typeof newMessagesSubscriptionHandle.current === 'function') {
        newMessagesSubscriptionHandle.current();
      }
    }
  }, [isSessionExpired]);

  const sendChatMessage = (messageObj) => {
    if (!_.isEmpty(messageObj)) {
      const transformedMessage = transformSuggestions(messageObj);
      const { message, media } = transformedMessage;
      if (message.trim() !== '' || media.length > 0) {
        const id = `local-${new Date().getTime()}`;
        sendChatMessageMutation(
          getCoachSendChatMessageOptions({
            ...transformedMessage,
            id,
            to,
            from,
            query: GET_CHAT_MESSAGES_FOR_CLIENT
          })
        )
          .then(() => {
            analytics.track('Chat', 'message sent by coach');
            logger.info(
              'Chat message sent from coach',
              'sendChatMessageMutation',
              {
                to,
                from
              }
            );
          })
          .catch((error) => {
            logger.error(
              `Error while sending chat message from coach. Error message is ${error.toString()}`,
              'sendChatMessageMutation',
              {
                to,
                from,
                error
              }
            );
          });
        setLastMessageVisible(true);
      }
    }
  };
  const {
    error: coachSuggestionsError,
    data: coachSuggestionsData,
    loading: coachSuggestionsLoading
  } = useQuery(GET_COACH_SUGGESTIONS, {
    variables: { message: coachSuggestionText },
    skip: !showCoachSuggestions || isSessionExpired
  });
  if (coachSuggestionsError) {
    logger.error('Error in fetching coach suggestions', 'chatCenter.useQuery', {
      error: serializeError(coachSuggestionsError),
      isSessionExpired
    });
    setCoachSuggestionsVisible(false);
  }
  // returns a boolean value for showing the arrow back button
  // (to be shown only in case of native devices and if info chat is open in case of tablet devices)
  const showBackButton = useMemo(
    () => !isDesktop && ((isTab && !_.isEmpty(infoTab)) || isMobile),
    [infoTab]
  );
  return (
    <div className={chatCenterContainer} data-testid="chat-center-container">
      <div className={clientInfoContainer} data-testid="client-info-container">
        <div
          className={clientDetailsContainer}
          data-testid="client-details-container"
        >
          {user && !chatListError && !isChatListEmpty && (
            <React.Fragment>
              <div className={clientInitialStyle}>
                {showBackButton && (
                  <ArrowBackIcon onClick={backButtonHandler} />
                )}
                <span>{clientInitials}</span>
              </div>
              <h5 data-testid="client-name">{name}</h5>
            </React.Fragment>
          )}
        </div>

        <button
          type="button"
          className={clientInfoButtonStyle}
          onClick={toggleDetails}
          data-testid="info-button"
        >
          <Icon className={infoIconStyle}>info</Icon>
        </button>
      </div>
      {networkError && <NetworkError error={networkError} />}
      {(chatMessagesLoading || !user) &&
        !loadingMoreMessages &&
        !chatListError &&
        !isChatListEmpty && (
          <center>
            <Ripple />
          </center>
        )}
      {!user && (isChatListEmpty || chatListError) && (
        <center>
          <div
            data-testid="no-client-assigned"
            className={noClientsAssignedContainer}
          >
            <Lang path="noClientAssignedText" />
          </div>
        </center>
      )}
      {((!chatMessagesLoading &&
        !chatMessagesError &&
        user &&
        !chatListError &&
        !isChatListEmpty) ||
        loadingMoreMessages) && (
        <div
          data-testid="chat-center-scroll"
          className={container}
          onScroll={onScroll}
          ref={chatWindowScrollRef}
        >
          <MessageList
            isLoading={loadingMoreMessages}
            messages={messages}
            lastMessageVisible={lastMessageVisible}
            error={chatMessagesError}
            paginationError={paginationError}
            paginationRetry={loadPreviousMessages}
            subscriptionError={subscriptionError}
            subscriptionRetry={subscribeForNewMessages}
            coachSuggestionsLoading={coachSuggestionsLoading}
            isCoach
            coachName={coachName}
            coachImage={coachImage}
          />
          {!isChatListEmpty && (
            <div className={inputContainer}>
              <CoachChatInput
                sendMessage={sendChatMessage}
                mediaMessages={mediaMessages}
                setMediaMessages={setMediaMessages}
                coachSuggestionsData={coachSuggestionsData}
                showCoachSuggestions={showCoachSuggestions}
                setCoachSuggestionsVisible={setCoachSuggestionsVisible}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  const {
    sessionExpired: { isSessionExpired },
    profile: {
      profile: { picture: coachImage, name: coachName }
    }
  } = state;
  return { isSessionExpired, coachImage, coachName };
};
ChatCenter.propTypes = {
  user: propTypes.shape({
    name: propTypes.string,
    to: propTypes.string,
    from: propTypes.string
  }),
  backButtonHandler: propTypes.func,
  toggleDetails: propTypes.func,
  chatListError: propTypes.bool,
  isChatListEmpty: propTypes.bool,
  isSessionExpired: propTypes.bool,
  mediaMessages: propTypes.arrayOf(propTypes.shape({})),
  setMediaMessages: propTypes.func,
  coachImage: propTypes.string,
  coachName: propTypes.string,
  infoTab: propTypes.string
};

ChatCenter.defaultProps = {
  user: {},
  mediaMessages: [],
  coachImage: '',
  coachName: '',
  infoTab: '',
  setMediaMessages: () => {},
  backButtonHandler: () => {},
  toggleDetails: () => {},
  chatListError: false,
  isChatListEmpty: false,
  isSessionExpired: true
};
export default connect(mapStateToProps)(ChatCenter);
