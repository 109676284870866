/**
 * Reducer that manages the state for assessment
 */

import {
  FETCH_QUESTIONS_AND_INTERSTITIAL,
  FETCH_QUESTIONS_AND_INTERSTITIAL_DONE,
  FETCH_QUESTIONS_AND_INTERSTITIAL_ERROR,
  HIDE_STARTING_INTERSTITIAL,
  RESET_ASSESSMENT,
  SET_ACTIVE_QUESTION,
  SET_ASSESSMENT_PROGRESS_ID
} from '../actions/types';

const INITIAL_STATE = {
  isFetching: false,
  questionList: [],
  interstitials: [],
  activeQuestion: 1,
  hasError: false,
  showAvatar: false,
  showStartingInterstitial: true, // to control the visibility of the starting interstitial
  assessmentId: null,
  progressId: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_QUESTIONS_AND_INTERSTITIAL:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_QUESTIONS_AND_INTERSTITIAL_DONE:
      return {
        ...state,
        isFetching: false,
        questionList: action.data.questions,
        interstitials: action.data.interstitials,
        showAvatar: action.data.showAvatar || false,
        hasError: false,
        assessmentId: action.data.assessmentId
      };
    case FETCH_QUESTIONS_AND_INTERSTITIAL_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true
      };
    case SET_ACTIVE_QUESTION:
      return {
        ...state,
        activeQuestion: action.questionId
      };
    case RESET_ASSESSMENT: {
      return {
        ...state,
        activeQuestion: 1,
        showStartingInterstitial: true,
        progressId: null
      };
    }
    case HIDE_STARTING_INTERSTITIAL: {
      return {
        ...state,
        showStartingInterstitial: action.val
      };
    }
    case SET_ASSESSMENT_PROGRESS_ID: {
      return {
        ...state,
        progressId: action.val
      };
    }
    default:
      return state;
  }
};
