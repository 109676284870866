import React from 'react';
// styles
import styles from './Bubble.module.scss';

const { container, userMessage } = styles;

const Bubble = ({ children, user }) => {
  const classNames = [container];
  if (user) {
    classNames.push(userMessage);
  }
  return (
    <div data-testid="question" className={classNames.join(' ')}>
      <span>{children}</span>
    </div>
  );
};

export default Bubble;
