/**
 * RadioButton field wrapped with our custom style for the rating question type
 */

import React from 'react';
import { Radio } from 'antd';
import styles from './RadioRating.module.scss';

const RadioButton = Radio.Button;

const RadioRating = ({ children, testId, ...rest }) => {
  const { radioRating } = styles;
  return (
    <RadioButton className={radioRating} data-testid={testId} {...rest}>
      {children}
    </RadioButton>
  );
};

export default RadioRating;
