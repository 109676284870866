import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNamesInstance from 'classnames/bind';
import { isEmpty, isString } from 'lodash';
// components
import { Card } from '@material-ui/core';
import { getCoachStatus } from 'store/actions/dashboard/CoachRequestAction';
import { OutlineButton } from '../../../../components/ui/Buttons';
import ErrorRetry from '../../../../components/ErrorRetry';
import Lang from '../../../../components/Lang';
// styles
import styles from './ActionPlanCardSmall.module.scss';
// actions

const {
  container,
  headerContainer,
  countContainer,
  contentContainer,
  statusSeparatorStyle,
  statusTextStyle
} = styles;

const classNames = classNamesInstance.bind(styles);

const ActionPlanCardSmall = ({
  header,
  cardId,
  categoryCount,
  categoryUnit,
  children,
  buttonText,
  onClick,
  showButton,
  statusText,
  buttonId,
  imgSrc,
  showError,
  isFetching
}) => {
  const dispatch = useDispatch();
  const _handleRetry = useCallback(() => {
    dispatch(getCoachStatus());
  }, [dispatch]);

  const _renderError = useCallback(
    () => (
      <ErrorRetry
        title={<Lang path="coachRequestStatusError" />}
        isFetching={isFetching}
        onClick={_handleRetry}
      />
    ),
    [_handleRetry, isFetching]
  );

  const _renderContentContainer = useCallback(
    () => (
      <>
        {children}
        {showButton && (
          <div>
            <OutlineButton testId={buttonId} onClick={onClick}>
              {buttonText}
            </OutlineButton>
          </div>
        )}
        {statusText && (
          <div>
            <hr className={statusSeparatorStyle} />
            <span
              data-testid={`${cardId}-request-received-text`}
              className={statusTextStyle}
            >
              {statusText}
            </span>
          </div>
        )}
      </>
    ),
    [buttonId, buttonText, cardId, children, onClick, showButton, statusText]
  );

  return (
    <Card className={container}>
      <div data-testid={`${cardId}-header`} className={headerContainer}>
        <h2>{header}</h2>
      </div>
      <div className={countContainer}>
        <div>
          <span
            data-testid={`${cardId}-total-sessions-text`}
            className={classNames({
              categoryCountLongClass:
                isString(categoryCount) && !isEmpty(categoryCount)
            })}
          >
            {categoryCount}
          </span>
          <span data-testid={`${cardId}-sessions-text`}>{categoryUnit}</span>
        </div>
      </div>
      <div
        className={contentContainer}
        style={{ backgroundImage: !showError && `url(${imgSrc})` }}
      >
        {showError ? _renderError() : _renderContentContainer()}
      </div>
    </Card>
  );
};

ActionPlanCardSmall.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  categoryCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  categoryUnit: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  buttonId: PropTypes.string.isRequired,
  statusText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showButton: PropTypes.bool
};

ActionPlanCardSmall.defaultProps = {
  onClick: () => {},
  buttonText: '',
  statusText: '',
  showButton: false
};

export default ActionPlanCardSmall;
