/**
 * Wrapper for date picker for redux-form
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import { InputError } from '../../ui/Validation';

const DatePickerField = ({
  className,
  input,
  meta: { error, touched },
  ...rest
}) => {
  const { t } = useTranslation();
  let showError = false;

  if (touched && error) {
    showError = true;
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        className={className}
        value={input.value || null}
        onChange={(val) => input.onChange(val)}
        {...rest}
        clearable
        okLabel={t('dateTimePicker.okLabel')}
        clearLabel={t('dateTimePicker.clearLabel')}
        cancelLabel={t('dateTimePicker.cancelLabel')}
        format="LLLL"
      />
      {showError && <InputError error={error} />}
    </MuiPickersUtilsProvider>
  );
};
export default DatePickerField;
