import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row } from 'antd';
import { getAuth0Id } from 'utils/auth';
import { useSelector } from 'react-redux';
import withAuth from 'hoc/withAuth';
import _ from 'lodash';
import { stringify } from 'tiny-querystring';
import { getQueryParams, backButtonHandlersMap } from 'utils/navigation';
import helpers from 'utils/helpers';
import history from 'utils/history';
import logger from 'utils/logger';
import { dispatch } from 'store';
import { infoTabAction } from 'store/actions/coachchat/ActiveUserAction';
import getDimensions from './CoachChat.utils';

// styles
import styles from './CoachChat.module.scss';
import ChatLeft from './components/ChatLeft';
import ChatCenter from './components/ChatCenter';
import ChatRight from './components/ChatRight';
import CoachChatFiltersContext from './components/ChatFilter/CoachChatFiltersContext';

const {
  container,
  chatRightContainer,
  chatCentreContainer,
  chatLeftContainer,
  fullHeight
} = styles;

const { isCoach } = helpers;

const CoachChat = ({ isSessionExpired }) => {
  useEffect(() => {
    if (!isCoach()) {
      history.push('/intro');
    }
  }, []);

  const { isMobile, isTab } = helpers.platformInfo;
  const auth0Id = getAuth0Id();
  const [selectedUser, setSelectedUser] = useState(null);
  const [chatListEmpty, setChatListEmpty] = useState(false);
  const [chatListError, setChatListError] = useState(false);
  const [mediaMessages, setMediaMessages] = useState([]);

  const [components, setComponents] = useState({
    showLeft: true,
    showCenter: true,
    showRight: true
  });

  const currentUser = useSelector((state) => _.get(state, 'currentUser', {}));
  const infoTabActive = useSelector((state) => _.get(state, 'infoTab', null));
  const queryParams = getQueryParams();

  const setActiveScreens = useCallback(() => {
    const query = getQueryParams();
    const isQueryParamsEmpty = _.isEmpty(query);
    const userInfoDisplay = !!infoTabActive;

    let chatComponent = {
      showLeft: true,
      showCenter: true,
      showRight: userInfoDisplay
    };

    if (isMobile) {
      // one screen to be active at a time
      chatComponent = {
        ...chatComponent,
        showLeft: isQueryParamsEmpty,
        showCenter: !!currentUser && !userInfoDisplay
      };
    } else if (isTab) {
      // two screens to be active at a time
      chatComponent.showLeft = !userInfoDisplay;
    }

    setComponents(chatComponent);
  }, [currentUser, infoTabActive, isMobile, isTab]);

  const toggleDetails = () => {
    let searchParams = getQueryParams();
    if (!_.isEmpty(searchParams) && searchParams.view === 'info') {
      // remove view for info tab from query params and direct to center chat
      delete searchParams.view;
      searchParams = stringify(searchParams);
      history.replace({
        search: searchParams
      });
      dispatch(infoTabAction(null));
      logger.info(
        'Removed view from query params. Going to center chat.',
        'CoachChat.toggleDetails'
      );
    } else {
      // added view for info tab in query params and direct to info tab
      searchParams.view = 'info';
      searchParams = stringify(searchParams);
      history.push({
        search: searchParams
      });
      dispatch(infoTabAction('info'));
      logger.info(
        'Added view to query params. Going to info tab.',
        'CoachChat.toggleDetails'
      );
    }
  };

  const [coachChatFilters, setCoachChatFilters] = useState({
    search: '',
    activity: '',
    message: ''
  });

  const dimensions = getDimensions(components.showRight);
  return (
    <React.Fragment>
      <CoachChatFiltersContext.Provider
        value={{ coachChatFilters, setCoachChatFilters }}
      >
        <div className={container}>
          <Row className={fullHeight}>
            <Col
              style={{
                display: components.showLeft ? 'block' : 'none'
              }}
              {...dimensions.leftChat}
              className={chatLeftContainer}
            >
              <ChatLeft
                auth0Id={auth0Id}
                isSessionExpired={isSessionExpired}
                setSelectedUser={setSelectedUser}
                setChatListEmpty={setChatListEmpty}
                setChatListError={setChatListError}
                setComponents={setComponents}
                components={components}
                setActiveScreens={setActiveScreens}
              />
            </Col>

            <Col
              style={{
                display: components.showCenter ? 'block' : 'none'
              }}
              {...dimensions.centerChat}
              className={chatCentreContainer}
            >
              <ChatCenter
                toggleDetails={toggleDetails}
                chatListError={chatListError}
                isChatListEmpty={chatListEmpty}
                user={selectedUser}
                mediaMessages={mediaMessages}
                setMediaMessages={setMediaMessages}
                setComponents={setComponents}
                components={components}
                setActiveScreens={setActiveScreens}
                backButtonHandler={backButtonHandlersMap.coach}
                infoTab={infoTabActive}
              />
            </Col>

            <Col
              style={{
                display: components.showRight ? 'block' : 'none'
              }}
              className={chatRightContainer}
              {...dimensions.rightChat}
            >
              <ChatRight
                user={selectedUser}
                chatListError={chatListError}
                isChatListEmpty={chatListEmpty}
                mediaMessages={mediaMessages}
                setMediaMessages={setMediaMessages}
                setComponents={setComponents}
                components={components}
                setActiveScreens={setActiveScreens}
                backButtonHandler={backButtonHandlersMap.coach}
                queryParams={queryParams}
              />
            </Col>
          </Row>
        </div>
      </CoachChatFiltersContext.Provider>
    </React.Fragment>
  );
};

export default withAuth(CoachChat);
