import { get } from 'lodash';
import { Modal } from 'antd';
import store, { dispatch } from 'store';
import helpers from 'utils/helpers';
import auth, { isLoggedIn } from 'utils/auth';
import history from 'utils/history';
import logger from 'utils/logger';
import { showModal } from 'store/actions/ModalAction';
import GetHelpNow from 'containers/DashBoard/popups/GetHelpNow';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import i18n from 'assets/lang';

const { confirm } = Modal;

export const getCompanyLogoUrl = () => {
  const {
    companyDetails: { companyDetails }
  } = store.getState();
  return helpers.getCompanyLogo(companyDetails);
};

export const logoClickHandler = (isFieldMissing) => {
  analytics.track(eventCategories.TOOLBAR, 'clicked on brand logo');
  if (!isFieldMissing) {
    history.push(isLoggedIn() ? helpers.homeUrl() : '/');
  }
};

export const emergencyClickHandler = () => {
  analytics.track(eventCategories.TOOLBAR, 'clicked on emergency button');
  const {
    companyDetails: { companyDetails },
    dashBoard: { goalId, severity }
  } = store.getState();
  const eapPhone = get(companyDetails, 'partner.eap_phone', '');
  dispatch(
    showModal(GetHelpNow, {
      source: 'get-help-now',
      props: {
        eapPhone,
        goalId,
        severity
      }
    })
  );
};

export const backButtonHandler = () => {
  analytics.track(eventCategories.TOOLBAR, 'Clicked back button on toolbar');
  if (helpers.isFirstAssessmentScreen()) {
    logger.info('First assessment screen.', 'Toolbar.utils.backButtonHandler');
    confirm({
      title: i18n.t('toolbar.popup.logoutConfirmation.descText'),
      okText: i18n.t('toolbar.popup.logoutConfirmation.onOkText'),
      cancelText: i18n.t('toolbar.popup.logoutConfirmation.onCancelText'),
      onOk() {
        analytics.track(
          eventCategories.TOOLBAR,
          'Clicked on confirm logout option'
        );
        auth.logout();
      },
      onCancel() {
        analytics.track(
          eventCategories.TOOLBAR,
          'Clicked on cancel logout option'
        );
      }
    });
  } else {
    history.goBack();
  }
};
