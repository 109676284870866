import _ from 'lodash';
import api from '../../../api/request';
import logger from '../../../utils/logger';
import handleUnexpectedError from '../../helper';
import {
  SET_WORK_LIFE_REQUEST_QUESTIONS_START,
  SET_WORK_LIFE_REQUEST_QUESTIONS_DONE,
  SET_WORK_LIFE_REQUEST_QUESTIONS_ERROR
} from '../types';

const getWorkLifeRequestQuestionsStart = () => ({
  type: SET_WORK_LIFE_REQUEST_QUESTIONS_START
});

const getWorkLifeRequestQuestionsDone = (workLifeRequest) => ({
  type: SET_WORK_LIFE_REQUEST_QUESTIONS_DONE,
  workLifeRequest
});

const getWorkLifeRequestQuestionsError = () => ({
  type: SET_WORK_LIFE_REQUEST_QUESTIONS_ERROR
});

const getWorkLifeRequestQuestions = () => (dispatch) => {
  dispatch(getWorkLifeRequestQuestionsStart());
  return api
    .getWorkLifeRequestQuestions()
    .then((resp) => {
      if (resp.status === 200) {
        const data = _.get(resp, 'data', {});
        dispatch(getWorkLifeRequestQuestionsDone(data));
      } else {
        dispatch(getWorkLifeRequestQuestionsError());
        logger.error(
          'An error occurred while fetching the Question list',
          'WorkLifeRequestAction.getWorkLifeRequestQuestions',
          { resp }
        );
      }
    })
    .catch((err) => {
      dispatch(getWorkLifeRequestQuestionsError());
      handleUnexpectedError(
        'An unexpected error occurred while fetching the Question list',
        'WorkLifeRequestAction.getWorkLifeRequestQuestions',
        err
      );
    });
};

export default getWorkLifeRequestQuestions;
