/* eslint-disable import/no-cycle */
import _ from 'lodash';
import logger from '../logger';
import { dispatch } from '../../store';
import setSessionExpired from '../../store/actions/SessionExpiredAction';

const handleGraphqlError = (errorMessage, errorSource, error) => {
  const message = _.get(error, 'networkError.result.errors[0].message', null);
  if (message === 'Token has expired.') {
    logger.info(`${errorMessage}`, `${errorSource}`, { error });
    dispatch(setSessionExpired(true));
  } else {
    logger.error(`${errorMessage}`, `${errorSource}`, { error });
  }
};

export default handleGraphqlError;
