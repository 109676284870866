// core
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useApolloClient } from '@apollo/react-hooks';
import _ from 'lodash';
import { connect, useDispatch } from 'react-redux';
// components
import withAuth from 'hoc/withAuth';
// graphql
import {
  mutationOptions,
  mutations,
  queries,
  subscriptions,
  functions
} from 'utils/graphql';
import subscriptionOptions from 'utils/graphql/subscriptions/subscription-options';
import logger from 'utils/logger';
import analytics from 'utils/analytics';
import { keyboardShown, keyboardClosed } from 'store/actions/KeyboardAction';
import {
  CHAT_POLLING_TIMEOUT,
  COACH_POLLING_TIMEOUT
} from 'utils/graphql/graphQL-config';
import { Ripple } from 'components/ui/Layout';
// utils
import helpers from 'utils/helpers';
import { getBowserParser } from 'utils/bowser';
import Lang from '../../../../components/Lang';
import styles from './ChatWindow.module.scss';
import NetworkIndicator from '../NetworkIndicator/NetworkIndicator';
import ChatBar from '../ChatBar';
import Input from '../Input';
import MessageList from '../MessageList';

const {
  GET_CHAT_MESSAGES,
  GET_MORE_CHAT_MESSAGES,
  GET_CHAT_USER_INFO,
  GET_CHAT_MESSAGES_AFTER
} = queries;
const { SEND_CHAT_MESSAGE } = mutations;
const { CHAT_MESSAGE_RECEIVED } = subscriptions;
const { getSendChatMessageOptions } = mutationOptions;
const { getChatMessageReceivedOptions } = subscriptionOptions;
const initialVisualHeight = window.innerHeight;
const KEYBOARD_HEIGHT_OFFSET = 200;

const { syncWithServer } = functions;

const {
  container,
  spinnerContainer,
  loadingTextStyle,
  loading,
  chatContainer
} = styles;

const ChatWindow = ({ onClick, isSessionExpired }) => {
  const [lastMessageVisible, setLastMessageVisible] = useState(true);
  const [loadingMoreMessages, setLoadingMoreMessages] = useState(false);
  const [paginationError, setPaginationError] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [networkError, setNetworkError] = useState(null);
  const chatWindowScrollRef = useRef(null);
  const chatMessagesPolling = useRef(null);
  const chatMessagesSubscriptionHandler = useRef(null);
  const client = useApolloClient();
  const dispatch = useDispatch();

  const {
    error: chatInfoError,
    data: chatInfoData,
    loading: chatInfoLoading,
    startPolling: startCoachPolling,
    stopPolling: stopCoachPolling,
    refetch: chatInfoRefetch
  } = useQuery(GET_CHAT_USER_INFO);

  if (chatInfoError && !isSessionExpired) {
    logger.error(
      `Error while fetching coach details. Error message is ${chatInfoError.toString()}`,
      'useQuery(GET_CHAT_USER_INFO)',
      { chatInfoError }
    );
    startCoachPolling(COACH_POLLING_TIMEOUT);
  }
  if (chatInfoData) {
    stopCoachPolling();
  }

  const {
    error: chatMessagesError,
    data: chatMessagesData,
    subscribeToMore,
    fetchMore,
    refetch: chatMessagesRefetch
  } = useQuery(GET_CHAT_MESSAGES, {
    skip: !chatInfoData,
    onError: (e) => {
      const statusCode = _.get(e, 'networkError.statusCode', null);
      if (statusCode === 401) {
        setNetworkError(<Lang path="sessionExpiredText" />);
        window.clearInterval(chatMessagesPolling.current);
      }
    }
  });
  const inputEnabled = !chatMessagesError;

  if (chatMessagesError) {
    logger.error(
      `Error while fetching chat messages. Error message is ${chatMessagesError.toString()}`,
      'useQuery(GET_CHAT_MESSAGES)',
      { chatMessagesError }
    );
  }

  const chatInfo = _.get(chatInfoData, 'getChatUserInfo.user[0]', {});

  const { to, from, image: coachImage, name: coachName } = chatInfo;
  const [sendChatMessageMutation] = useMutation(SEND_CHAT_MESSAGE);
  const messages = _.get(chatMessagesData, 'getChatMessages.messages', []);
  const hasMoreMessages = _.get(
    chatMessagesData,
    'getChatMessages.pagination.hasNextPage',
    false
  );
  const lastMessageId = messages[0] && messages[0].id;

  const fetchMoreMessages = () =>
    fetchMore({
      query: GET_MORE_CHAT_MESSAGES,
      variables: { before: lastMessageId },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newMessages = fetchMoreResult.getChatMessages.messages;
        const { pagination } = fetchMoreResult.getChatMessages;
        return {
          ...prev,
          getChatMessages: {
            ...prev.getChatMessages,
            messages: [...newMessages, ...prev.getChatMessages.messages],
            pagination
          }
        };
      }
    });

  const loadPreviousMessages = () => {
    setPaginationError(false);
    setLoadingMoreMessages(true);
    fetchMoreMessages()
      .then(() => {
        setLoadingMoreMessages(false);
        const lastMessageElement = document.getElementById(lastMessageId);
        const scrollElement = document.getElementById('scroll-in-chat-list');
        if (
          lastMessageElement &&
          scrollElement &&
          scrollElement.scrollTop === 0
        ) {
          const newMessageOffsetTop = lastMessageElement.offsetTop;
          scrollElement.scrollTop = newMessageOffsetTop - 48;
        }
      })
      .catch((error) => {
        setLoadingMoreMessages(false);
        setPaginationError(true);
        logger.error(
          `Error while loading more messages. Error message is ${error.toString()}`,
          'fetchMoreMessages',
          { error }
        );
      });
  };

  const onScroll = (e) => {
    const scrollPosition = _.get(e, 'target.scrollTop');
    const scrollHeight = _.get(e, 'target.scrollHeight');
    const clientHeight = _.get(e, 'target.clientHeight');
    const isVisible = scrollHeight - scrollPosition - 30 < clientHeight;
    if (lastMessageVisible !== isVisible) {
      setLastMessageVisible(isVisible);
    }
    if (scrollPosition === 0 && !loadingMoreMessages && hasMoreMessages) {
      loadPreviousMessages();
    }
  };

  const subscribeForNewMessages = useCallback(() => {
    let handle;
    if (from && to) {
      handle = subscribeToMore(
        getChatMessageReceivedOptions({
          to,
          from,
          subscription: CHAT_MESSAGE_RECEIVED,
          onError: () => {
            setSubscriptionError(true);
          }
        })
      );
      setSubscriptionError(false);
    }
    return handle;
  }, [from, to, subscribeToMore]);

  const sendChatMessage = (message) => {
    if (message !== '') {
      sendChatMessageMutation(
        getSendChatMessageOptions({
          id: `local-${new Date().getTime()}`,
          message,
          to,
          from,
          query: GET_CHAT_MESSAGES
        })
      )
        .then(() => {
          analytics.track('Chat', 'message sent by client');
          logger.info(
            'Chat message sent by client',
            'sendChatMessageMutation',
            {
              to,
              from
            }
          );
          // To handle chat input position immediately after message is sent when keyboard is open
          // Only required for safari browser in mobile web
          const { isMobileWeb, isIos } = helpers.platformInfo;
          const {
            parsedResult: { browser }
          } = getBowserParser();
          if (isMobileWeb && isIos && browser.name === 'Safari') {
            window.scrollTo(0, document.body.scrollHeight);
          }
        })
        .catch((error) => {
          logger.error(
            `Error while sending chat message. Error message is ${error.toString()}`,
            'sendChatMessageMutation',
            {
              to,
              from,
              error
            }
          );
        });
    }
    setLastMessageVisible(true);
  };

  const handleStateOnline = useCallback(() => {
    setNetworkError(null);
    if (!isSessionExpired) {
      syncWithServer({
        client,
        messages,
        catchUpQuery: GET_CHAT_MESSAGES_AFTER,
        query: GET_CHAT_MESSAGES
      });
      chatMessagesPolling.current = setInterval(() => {
        syncWithServer({
          client,
          messages,
          catchUpQuery: GET_CHAT_MESSAGES_AFTER,
          query: GET_CHAT_MESSAGES
        });
      }, CHAT_POLLING_TIMEOUT);
    }
  }, [client, messages, isSessionExpired]);

  const handleStateOffline = useCallback(() => {
    setNetworkError(<Lang path="offlineStateText" />);
    window.clearInterval(chatMessagesPolling.current);
  }, []);

  useEffect(() => {
    window.addEventListener('online', handleStateOnline);
    window.addEventListener('offline', handleStateOffline);

    return () => {
      window.removeEventListener('online', handleStateOnline);
      window.removeEventListener('offline', handleStateOffline);
    };
  }, [
    handleStateOnline,
    handleStateOffline,
    startCoachPolling,
    stopCoachPolling
  ]);

  const detectKeyboardHandler = useCallback(
    ({ currentVisualHeight }) => {
      if (initialVisualHeight - currentVisualHeight > KEYBOARD_HEIGHT_OFFSET) {
        dispatch(keyboardShown());
      } else {
        dispatch(keyboardClosed());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const { isMobileWeb, isAndroid } = helpers.platformInfo;
    let listener;
    // Skipping safari mobile web due to browser limitations
    // Handle mobile web and tablets web for android
    if (isMobileWeb && isAndroid) {
      // Keyboard lifecycle methods (show and hide) for mobile web browsers
      listener = window.visualViewport.addEventListener('resize', (event) => {
        detectKeyboardHandler({
          currentVisualHeight: event.currentTarget.height
        });
      });
    }
    // clean up
    return () => window.visualViewport.removeEventListener('resize', listener);
  }, [detectKeyboardHandler]);

  useEffect(() => {
    if (!isSessionExpired) {
      chatMessagesPolling.current = setInterval(() => {
        syncWithServer({
          client,
          messages,
          catchUpQuery: GET_CHAT_MESSAGES_AFTER,
          query: GET_CHAT_MESSAGES
        });
      }, CHAT_POLLING_TIMEOUT);
    }
    return () => {
      window.clearInterval(chatMessagesPolling.current);
    };
  }, [messages, client, isSessionExpired]);

  useEffect(() => {
    if (isSessionExpired) {
      window.clearInterval(chatMessagesPolling.current);
      stopCoachPolling();
      if (typeof chatMessagesSubscriptionHandler.current === 'function') {
        chatMessagesSubscriptionHandler.current();
      }
    }
  }, [isSessionExpired, stopCoachPolling]);

  useEffect(() => {
    chatMessagesSubscriptionHandler.current = subscribeForNewMessages();
    return () => {
      if (typeof chatMessagesSubscriptionHandler.current === 'function') {
        chatMessagesSubscriptionHandler.current();
      }
    };
  }, [subscribeForNewMessages]);

  useEffect(() => {
    // When coach is reassigned, clients can get messages from new coach while being on the chat with old coach
    // To counter this we are checking the last message for coach auth0id, if not present we refetch chatUserInfo and messages
    const lastMessage = _.last(messages);
    if (lastMessage && ![lastMessage.from, lastMessage.to].includes(to)) {
      chatInfoRefetch().then(chatMessagesRefetch);
    }
  }, [chatInfoRefetch, chatMessagesRefetch, messages, to]);

  return (
    <div className={container} onScroll={onScroll} ref={chatWindowScrollRef}>
      {!chatInfoError &&
      ((!chatMessagesError && !chatMessagesData) || chatInfoLoading) ? (
        <div className={loading}>
          <div data-testid="chat-spinner" className={spinnerContainer}>
            <Ripple />
            <h3 className={loadingTextStyle}>
              <Lang path="chatLoadingText" />
            </h3>
          </div>
        </div>
      ) : (
        <>
          <ChatBar
            onClick={onClick}
            coachDetails={chatInfo || {}}
            error={chatInfoError}
          />
          <React.Fragment>
            <div className={chatContainer}>
              <NetworkIndicator networkError={networkError} />
              <MessageList
                isLoading={loadingMoreMessages}
                messages={messages}
                lastMessageVisible={lastMessageVisible}
                retry={chatInfoError ? chatInfoRefetch : chatMessagesRefetch}
                error={chatInfoError || chatMessagesError}
                paginationError={paginationError}
                paginationRetry={loadPreviousMessages}
                subscriptionError={subscriptionError}
                subscriptionRetry={subscribeForNewMessages}
                coachName={coachName}
                coachImage={coachImage}
              />
              {inputEnabled && <Input sendMessage={sendChatMessage} />}
            </div>
          </React.Fragment>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    sessionExpired: { isSessionExpired }
  } = state;
  return { isSessionExpired };
};

export default withAuth(connect(mapStateToProps)(ChatWindow));
