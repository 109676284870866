import gql from 'graphql-tag';

const GET_GOAL_LIST = gql`
  query {
    getGoalList {
      goals {
        id
        name
        description {
          html
        }
        goalId
        features
        imageUrl {
          carousel
          topBar
          modal
          topBarMobile
          modalMobile
        }
        type
        version
        totalSessions
      }
    }
  }
`;

const GET_SELECTED_GOAL_INFO = gql`
  query {
    getSelectedGoalInfo {
      goalId
      completedSessionsCount
      version
    }
  }
`;

const GET_SESSION_LIST = gql`
  query getSession($goalId: String, $version: String!) {
    getSession(input: { goalId: $goalId, goalVersion: $version }) {
      sessions {
        id
        screens {
          id
          type
          contentType
          content {
            subheader
            header
            description
            noProgressText
            emoticon {
              moodEmoticonUrl
              graphEmoticonUrl
            }
          }
        }
      }
      completedScreens
      tools {
        trackerProgress {
          timestamp
          trackerValue
        }
      }
    }
  }
`;

const GET_SELECTED_SESSION_INFO = gql`
  query getSession($sessionId: String, $goalId: String, $version: String!) {
    getSession(
      input: { sessionId: $sessionId, goalId: $goalId, goalVersion: $version }
    ) {
      sessions {
        id
        screens {
          id
          type
          contentType
          content {
            subheader
            header
            description
            descriptionV2 {
              html
            }
            placeholderText
            transcript {
              html
            }
            imageUrl
            trackerType
            trackerId
            title
            subtitle
            noProgressText
            emoticon {
              moodEmoticonUrl
              graphEmoticonUrl
            }
            context
            actionDescription
            ratings {
              label
            }
            video {
              videoId
              urlMp4
              urlHlsv3
              urlHlsv4
              duration
            }
            audio {
              audioId
              audioUrl
              duration
              imageUrl
              imageUrlMobile
            }
            checklistScreenId
            textInputId
            checklist {
              allowCustom
              customOptionPlaceholder
              type
              maxOptions
              minOptions
              options {
                optionId
                text
              }
            }
            textScreen {
              type
              text {
                html
              }
              imageUrl
            }
            additionalInfo {
              html
            }
            toolTip {
              imageUrl
              header
              text {
                html
              }
            }
            toolHistoryContent {
              header
              text
            }
            thoughtRecorderActiveSteps
            sliderScreenId
            situations {
              id
              title
              ratings {
                id
                label
              }
            }
            thoughtRecorder {
              id
              steps {
                id
                type
                title
                automaticThoughts {
                  maxOptions
                  minOptions
                  allowCustom
                  options {
                    optionId
                    optionDescription
                    text
                  }
                  customOptionPlaceholder
                }
                emotions {
                  maxOptions
                  minOptions
                  allowCustom
                  options {
                    optionId
                    optionDescription
                    text
                  }
                  customOptionPlaceholder
                }
                category {
                  maxOptions
                  minOptions
                  allowCustom
                  options {
                    optionId
                    optionDescription
                    text
                  }
                  customOptionPlaceholder
                }
                challenge {
                  maxOptions
                  minOptions
                  allowCustom
                  options {
                    optionId
                    optionDescription
                    text
                  }
                  customOptionPlaceholder
                }
                rationalResponse {
                  placeholderText
                }
              }
            }
          }
          progress {
            currentDuration
            trackerValue
            options {
              optionId
              isSelected
              isCustom
              text
              updatedAt
              createdAt
              type
            }
            textInputs {
              text
              createdAt
              isCustom
            }
            situations {
              id
              value
              ratingId
              ratingValue
            }
          }
        }
      }
      completedScreens
      tools {
        thoughtRecorder {
          id
          stepProgress {
            automaticThoughts {
              optionId
              text
              isSelected
              isCustom
              updatedAt
              createdAt
              stepId
            }
            emotions {
              optionId
              text
              isSelected
              isCustom
              updatedAt
              createdAt
              stepId
            }
            category {
              optionId
              text
              isSelected
              isCustom
              updatedAt
              createdAt
              stepId
            }
            challenge {
              optionId
              text
              isSelected
              isCustom
              updatedAt
              createdAt
              stepId
            }
            rationalResponse {
              text
              createdAt
              stepId
              isCustom
            }
          }
        }
        trackerProgress {
          timestamp
          trackerValue
        }
      }
    }
  }
`;

export {
  GET_GOAL_LIST,
  GET_SELECTED_GOAL_INFO,
  GET_SESSION_LIST,
  GET_SELECTED_SESSION_INFO
};
