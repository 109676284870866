/**
 * hoc that injects the avatar prop in the component
 */

// Core
import React from 'react';
import { connect } from 'react-redux';

const withAvatar = (WrappedComponent) => {
  const decWithAvatar = (props) => <WrappedComponent {...props} />;

  const mapStateToProps = (state) => {
    const { activeAvatar } = state.avatar;

    return { activeAvatar };
  };

  return connect(mapStateToProps)(decWithAvatar);
};

export default withAvatar;
