import React from 'react';
import ContentLoader from 'react-content-loader';

export const LoaderLarge = () => (
  <ContentLoader viewBox="0 0 900 300" height={600} width={900}>
    <rect x="0" y="3" rx="0" ry="0" width="144" height="17" />
    <rect x="0" y="32" rx="3" ry="3" width="900" height="264" />
  </ContentLoader>
);

export const LoaderSmall = () => (
  <ContentLoader viewBox="0 0 900 900" height={900} width={900}>
    <rect x="0" y="5" rx="0" ry="0" width="330" height="42" />
    <rect x="0" y="82" rx="0" ry="0" width="900" height="850" />
  </ContentLoader>
);
