// Core
import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
// config
import config from 'config';
import i18n from 'assets/lang';
import styles from './Completion.module.scss';

const { cdnBaseUrl } = config;

const { container, content, headerStyle, subheadingStyle } = styles;

const Completion = ({ completeScreenData, sessionNumber }) => {
  const { name } = useSelector((state) => _.get(state, 'profile.profile'));
  const imageUrl = _.get(completeScreenData, 'imageUrl');
  const header = (completeScreenData.header || '').replace('{{name}}', name);
  const subheader = (completeScreenData.subheader || '').replace(
    '{{sessionNumber}}',
    sessionNumber
  );
  return (
    <div data-testid="completed-session-container" className={container}>
      <div className={content}>
        <img
          src={`${cdnBaseUrl}${imageUrl}`}
          alt={i18n.t('alternateText.image.completedSession')}
          data-testid="completed-session-image"
          draggable="false"
        />
        <div>
          <h1 data-testid="completed-session-header" className={headerStyle}>
            {header}
          </h1>
          <h2
            data-testid="completed-session-subheader"
            className={subheadingStyle}
          >
            {subheader}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Completion;
