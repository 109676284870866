/*

*/

// Core
import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './ReportLoader.module.scss';

const { skeletonWrapper } = styles;
// components
const Report = () => (
  <ContentLoader height="57" width="600">
    {/* Only SVG shapes */}
    <rect x="0" y="20" rx="8" ry="8" width="10" height="10" />
    <rect x="20" y="0" rx="15" ry="15" width="52" height="52" />
    <rect x="85" y="17" rx="4" ry="4" width="400" height="9" />
    <rect x="85" y="35" rx="3" ry="3" width="250" height="7" />
  </ContentLoader>
);

const ReportLoader = () => (
  <div data-testid="report-loader" className={skeletonWrapper}>
    <Report />
  </div>
);

export default ReportLoader;
