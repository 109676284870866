/**
 *
 * In-Person Therapy
 *
 */

import React from 'react';
import { connect } from 'react-redux';
// components
import analytics from 'utils/analytics/analytics';
import bigCardImg from 'assets/img/dashboard/actionplan/inperson.png';
import InPersonAppoinment from '../../popups/InPersonAppoinment';
import {
  LoaderSmall,
  LoaderLarge
} from '../ActionPlan/components/ActionPlanCardSkeleton';
import ActionPlanCardSmall from '../ActionPlanCardSmall';
import ActionPlanCardBig from '../ActionPlanCardBig';
import Lang from '../../../../components/Lang';
// styles
import styles from './InPersonTherapy.module.scss';
// utils
// assets

const { textStyle } = styles;

const requestAppoinmentHandler = (goalId, severity, showModal) => {
  analytics.track('dashboard', 'clicked request appointment', {
    goalId,
    severity
  });
  showModal(InPersonAppoinment, {
    maxWidth: 820,
    source: 'in-person-therapy-request'
  });
};

const InPersonTherapy = ({
  type,
  index,
  showModal,
  bigCardText,
  inPersonTherapyRequestDone,
  isFetching,
  goalId,
  severity,
  action,
  inPersonSessionCount
}) => {
  const LoaderComponent = type === 'large' ? LoaderLarge : LoaderSmall;
  if (isFetching) {
    return <LoaderComponent />;
  }

  const componentProps = {
    header: (
      <Lang path="therapyCard.headerText" values={{ index: index + 1 }} />
    ),
    categoryCount: inPersonSessionCount,
    categoryUnit: <Lang path="therapyCard.unitText" />,
    showButton: !inPersonTherapyRequestDone,
    onClick: () =>
      requestAppoinmentHandler(goalId, severity, showModal, action),
    cardId: 'in-person-therapy',
    buttonId: 'in-person-request-btn',
    buttonText: <Lang path="therapyCard.buttonText" />
  };

  if (inPersonTherapyRequestDone) {
    componentProps.statusText = <Lang path="therapyCard.statusDoneText" />;
  }

  if (index > 0) {
    componentProps.buttonType = 'secondary';
  }

  return type === 'large' ? (
    <ActionPlanCardBig
      content={bigCardText}
      imgSrc={bigCardImg}
      {...componentProps}
    />
  ) : (
    <ActionPlanCardSmall {...componentProps}>
      <p className={textStyle}>
        <Lang path="therapyCard.descriptionText" />
      </p>
    </ActionPlanCardSmall>
  );
};

const mapStateToProps = (state) => {
  const {
    inPersonTherapy: {
      inPersonTherapyRequestDone,
      status: { isFetching }
    },
    dashBoard: { goalId, severity, sessionCount }
  } = state;
  return {
    inPersonTherapyRequestDone,
    isFetching,
    goalId,
    severity,
    inPersonSessionCount: sessionCount.inperson[severity]
  };
};

export default connect(mapStateToProps)(InPersonTherapy);
