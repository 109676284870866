/**
 * Actions that manage the redirect url to which user might need to be redirected in
 * case they weren't logged in initially
 */

import { SET_REDIRECT_URL, RESET_REDIRECT_URL } from './types';

export const setRedirectUrl = (url) => ({
  type: SET_REDIRECT_URL,
  url
});

export const resetRedirectUrl = () => ({
  type: RESET_REDIRECT_URL
});
