/**
 * Company Select page container
 */

// Core
import React, { Component } from 'react';
import propTypes from 'prop-types';
import { parse } from 'tiny-querystring';
// utils
import auth from 'utils/auth';
import locale from 'utils/locale';
// hocs
import withoutAuth from 'hoc/withoutAuth';
import loginFromUrl from 'hoc/loginFromUrl';
// utils
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import logger from 'utils/logger';
import storage from 'utils/storage';
import FullPageLoader from 'components/FullPageLoader';
import Lang from 'components/Lang';
import { isNativePlatform } from 'utils/capacitor';
// assets
import i18n from 'assets/lang';
import poweredByLogo from 'assets/img/powered-by-logo.svg';
// config
import config from 'config';
// Components
import ContentContainer from 'components/layout/ContentContainer';
import CompanySelectForm from './components/CompanySelectForm';
// styles
import styles from './CompanySelect.module.scss';

const { container, poweredByLogoContainer, poweredByLogoStyle } = styles;
class CompanySelect extends Component {
  constructor(props) {
    super(props);
    let nextAction = null;
    if (window.location.search) {
      nextAction = parse(window.location.search.slice(1)).next;
    }
    const stateObj = {
      errorMessage: '',
      isLoading: false,
      fullPageLoader: false
    };
    this.source = nextAction === 'register' ? 'signup' : nextAction;
    const { source } = this;
    logger.info(
      'User reached the access code screen',
      'CompanySelect.constructor'
    );
    analytics.info(
      eventCategories.COMPANY_SELECT,
      'user reached the access code screen',
      { trigger: 'access_code_view', source }
    );

    if (nextAction) {
      stateObj.nextAction = nextAction;
    }

    this.state = stateObj;
  }

  _handleSubmit = async ({ access_code: accessCodeReceived }) => {
    const self = this;
    const { source } = self;

    self.setState({ isLoading: true });
    const accessCode = accessCodeReceived
      ? accessCodeReceived.toLowerCase()
      : null;
    await storage.setItem('accessCode', accessCodeReceived);
    const companyObj = this.props.companyList.find(
      (item) => item.alias.toLowerCase() === accessCode.toLowerCase()
    );
    logger.info(
      'Clicked continue after selecting Access Code',
      'CompanySelect._handleSubmit',
      {
        accessCode,
        companyObj
      }
    );
    analytics.track(eventCategories.COMPANY_SELECT, 'clicked continue', {
      trigger: 'access_code_click',
      source
    });
    await auth._getDetailsAndAuthenticate({
      accessCode,
      companyObj,
      nextAction: this.state.nextAction,
      activeLanguage: locale.getActiveLanguage(),
      onExit() {
        self.setState({ fullPageLoader: false, isLoading: false });
      }
    });
    if (isNativePlatform) {
      self.setState({ fullPageLoader: true });
    }
  };

  render() {
    const { fullPageLoader } = this.state;
    return fullPageLoader ? (
      <FullPageLoader loaderText={<Lang path="loadingTextEllipsis" />} />
    ) : (
      <div className={container}>
        <ContentContainer size="large" showBg>
          <CompanySelectForm
            onSubmit={this._handleSubmit}
            isLoading={this.state.isLoading}
            companyList={this.props.companyList}
            companyListLoading={this.props.companyListLoading}
            companyListError={this.props.companyListError}
          />
        </ContentContainer>
        {config.showPoweredByLogo === 'true' && (
          <div className={poweredByLogoContainer}>
            <img
              className={poweredByLogoStyle}
              src={poweredByLogo}
              alt={i18n.t('alternateText.image.poweredBy', {
                appName: config.appName
              })}
            />
          </div>
        )}
      </div>
    );
  }
}

CompanySelect.propTypes = {
  companyList: propTypes.arrayOf(propTypes.shape({ alias: propTypes.string }))
    .isRequired,
  companyListLoading: propTypes.bool.isRequired,
  companyListError: propTypes.bool.isRequired
};

export default withoutAuth(loginFromUrl(CompanySelect));
