const supportedSteps = {
  AUTOMATIC_THOUGHTS: 'automaticThoughts',
  EMOTIONS: 'emotions',
  CATEGORY: 'category',
  CHALLENGE: 'challenge',
  RATIONAL_RESPONSE: 'rationalResponse'
};

const progressStates = {
  DISABLED: 0,
  CURRENT: 1,
  COMPLETED: 2
};

const stepTypes = {
  CHECKLIST: 'CHECKLIST',
  INPUT: 'INPUT'
};

const stepTypesMap = {
  [supportedSteps.AUTOMATIC_THOUGHTS]: stepTypes.CHECKLIST,
  [supportedSteps.EMOTIONS]: stepTypes.CHECKLIST,
  [supportedSteps.CHALLENGE]: stepTypes.CHECKLIST,
  [supportedSteps.CATEGORY]: stepTypes.CHECKLIST,
  [supportedSteps.RATIONAL_RESPONSE]: stepTypes.INPUT
};

export { supportedSteps, progressStates, stepTypesMap, stepTypes };
