/**
 * Report page container
 */

// Core
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Components
import { Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';
import { Accordion, AccordionSummary } from '@material-ui/core';
import featureService from 'utils/feature-service';
import categories from 'utils/analytics/categories';
import ContentContainer from '../../components/layout/ContentContainer';
import {
  ItemHeader,
  ItemContent,
  SelfHarmContent,
  ReportTimePicker
} from './components';
import EAPInfo from '../../components/Popups/EAPInfo';
import DownloadApp from '../../components/Popups/SmartAppLink';
import ReportLoader from '../../components/SkeletonLoading/ReportLoader';
import LevelSeparator from '../../components/LevelSeparator/LevelSeparator';
import CoachInfo from '../../components/CoachInfo/CoachInfo';
import Lang from '../../components/Lang';
// assets
import bonusGoalMap from '../../assets/data/bonusGoalMap';

// utils
import helpers from '../../utils/helpers';
import moment from '../../utils/moment';
// actions
import { resetAssessment } from '../../store/actions/AssessmentAction';
import getReport from '../../store/actions/ReportByIdAction';
import selectGoal from '../../store/actions/GoalAction';
import { showModal } from '../../store/actions/ModalAction';
// hocs
import withAuth from '../../hoc/withAuth';
// styles
import styles from './Report.module.scss';
// utils
import analytics from '../../utils/analytics';
import api from '../../api/assessment';
import highStressWorkContent from '../../assets/data/assessment/highStressWork';
import covid19Content from '../../assets/data/assessment/covid19';
import ErrorRetry from '../../components/ErrorRetry';

const {
  container,
  bottomLinkContainer,
  link,
  tryAgainContainer,
  retakeLink,
  reportTimestampStyle,
  low,
  medium,
  high,
  bonus,
  goalsHeaderSection,
  goalsHeader,
  goalsHeaderContent,
  headerStyle,
  headerDescStyle,
  reportFooterImage,
  viewAllResults,
  footerText,
  panel,
  collapseDiv,
  expansionPanelContainer,
  retakeAssessmentButton,
  reportText
} = styles;

const levelTypes = {
  low: {
    className: low,
    name: 'low'
  },
  moderate: {
    className: medium,
    name: 'moderate'
  },
  high: {
    className: high,
    name: 'high'
  },
  bonus: {
    className: bonus,
    name: 'bonus'
  }
};

class Report extends Component {
  state = {
    activeCategory: ''
  };

  componentDidMount() {
    // if we haven't fetched the report already then fetch it
    if (!this.props.report) {
      this.props.actions.getReport(this.props.match.params.id);
    }
  }

  // Sets the currently active modal to eap
  _handleEAPClick = (goalId) => {
    analytics.track('assessment results', 'clicked eap', {
      goalId
    });
    this.props.actions.showModal(EAPInfo, {
      maxWidth: 425,
      source: 'eap-info'
    });
  };

  _handleGoalClick = (goalId, goalName) => {
    analytics.track('assessment results', 'selected goal', {
      goalId,
      source: categories.REPORT,
      trigger: 'select_goal'
    });
    if (featureService._hasIntelliPlatformFeature()) {
      const severity = bonusGoalMap.includes(goalId)
        ? 'bonus'
        : this.props.report[goalId].level;
      window.scroll(0, 0);
      this.props.history.push(`/my-plan/${goalId.toLowerCase()}/${severity}`);
    } else {
      this.props.actions.showModal(DownloadApp, {
        source: 'smart-app-link'
      });
    }
    this.props.actions.selectGoal({
      selectedGoalOption: goalId,
      selectedGoal: goalName,
      reportId: this.props.reportId
    });
  };

  goalLevel = (report) =>
    report.map((reportItem) => {
      const expanded = this.state.activeCategory === reportItem.categoryName;
      const { displayName, icon } = this.props.companyDetails.partner;
      const hasContentAccess =
        featureService._hasContentAccessFeature() &&
        this.props.reportList &&
        this.props.reportList.reports[0]._id === this.props.match.params.id;
      return (
        <div className={expansionPanelContainer} key={reportItem.categoryName}>
          <Accordion
            onChange={() => {
              this.setState({ activeCategory: reportItem.categoryName });
              analytics.track('assessment results', 'expanded goal', {
                goalId: reportItem.categoryName
              });
            }}
            className={[panel, collapseDiv].join(' ')}
            expanded={expanded}
          >
            {this.state.activeCategory !== reportItem.categoryName && (
              <AccordionSummary>
                <ItemHeader {...reportItem} />
              </AccordionSummary>
            )}
            <ItemContent
              onCollapse={this._handleCollapse}
              showAlert={!this.props.suicidal}
              eapName={displayName}
              eapIcon={icon && `/eap/${icon}`}
              {...reportItem}
              onGoal={this._handleGoalClick}
              onEAP={this._handleEAPClick}
              expanded={expanded}
              hasContentAccess={hasContentAccess}
            />
          </Accordion>
        </div>
      );
    });

  _handleCollapse = () => {
    analytics.track('assessment results', 'collapsed goal', {
      goalId: this.state.activeCategory
    });
    this.setState({ activeCategory: '' });
  };

  // Render the report items
  _getReportItems = (modifier) => {
    if (this.props.report) {
      const reportArr = _.toArray(
        _.mapValues(this.props.report, (value, key) => ({
          ...value,
          name: key
        }))
      );
      return this.goalLevel(
        reportArr
          .filter((val) => val.level === modifier)
          .sort(helpers.levelSortFunc)
      );
    }
    return null;
  };

  _handleReAssess = () => {
    analytics.track('assessment results', 'clicked retake survey');
    this.props.actions.resetAssessment();
    if (featureService._hasConversationalAssessmentFeature()) {
      this.props.history.replace('/assessment/conversational');
    } else {
      this.props.history.replace('/assessment');
    }
  };

  _handleRetry = () => {
    analytics.track('assessment results', 'clicked retry');
    this.props.actions.getReport(this.props.match.params.id);
  };

  _handleHistoryClick = () => {
    analytics.track('assessment results', 'clicked view all results');
    this.props.history.push('/history');
  };

  _renderReportItems = (level, isFetching) => {
    const reportItems = this._getReportItems(level.name);
    if (isFetching) {
      return (
        <>
          <LevelSeparator text={level.name} bgClass={level.className} />
          <ReportLoader />
        </>
      );
    }
    return reportItems && reportItems.length > 0 ? (
      <>
        <LevelSeparator text={level.name} bgClass={level.className} />
        <div>{reportItems}</div>
      </>
    ) : null;
  };

  _renderHighWorkStressGoal = () => {
    if (featureService._hasBonusGoalFeature()) {
      if (this.props.isFetching) {
        return (
          <Fragment>
            <LevelSeparator
              text={levelTypes.bonus.name}
              bgClass={levelTypes.bonus.className}
            />
            <ReportLoader />
          </Fragment>
        );
      }
      return (
        <Fragment>
          <LevelSeparator
            text={levelTypes.bonus.name}
            bgClass={levelTypes.bonus.className}
          />
          <div>
            {featureService._hasHighWorkStressGoalFeature() &&
              this.goalLevel(highStressWorkContent())}
            {featureService._hasCovid19Goal() &&
              this.goalLevel(covid19Content())}
          </div>
        </Fragment>
      );
    }
    return null;
  };

  _beenSixWeeksSinceAssessment = () => {
    if (
      featureService._hasRetakeAssessmentPopupFeature() &&
      this.props.reportList &&
      this.props.reportList.reports.length
    ) {
      const { timestamp } = this.props.reportList.reports[0];
      const today = moment();
      const lastAssessmentTaken = moment(timestamp, 'YYYY-MM-DD');
      return today.diff(lastAssessmentTaken, 'week') >= 6;
    }

    return false;
  };

  render() {
    const beenSixWeeksSinceAssessment = this._beenSixWeeksSinceAssessment();
    let { headerText, headerDesc } = '';
    if (beenSixWeeksSinceAssessment) {
      headerText = (
        <Lang
          values={{ components: { br: <br /> } }}
          path="reportPage.sixWeeks.headerText"
        />
      );
      headerDesc = (
        <Lang
          values={{ components: { br: <br /> } }}
          path="reportPage.sixWeeks.headerDesc"
        />
      );
    } else {
      headerText = (
        <Lang
          values={{ components: { br: <br /> } }}
          path="reportPage.headerText"
        />
      );
      headerDesc = (
        <Lang
          values={{ components: { br: <br /> } }}
          path="reportPage.headerDesc"
        />
      );
    }

    if (featureService._hasDiagnosticSurveyFeature()) {
      headerText = (
        <Lang
          values={{ components: { br: <br /> } }}
          path="reportPage.diagnosticSurvey.headerText"
        />
      );
      headerDesc = (
        <Lang
          values={{ components: { br: <br /> } }}
          path="reportPage.diagnosticSurvey.headerDesc"
        />
      );
    }

    if (
      Object.keys(this.props.coachDetails).length > 0 &&
      featureService._hasCoachWelcomeMessageFeature()
    ) {
      headerText = (
        <Lang
          values={{ components: { br: <br /> } }}
          path="reportPage.coachAssigned.headerText"
        />
      );
      headerDesc = (
        <Lang
          values={{ components: { br: <br /> } }}
          path="reportPage.coachAssigned.headerDesc"
        />
      );
    }

    const {
      isFetching,
      hasError,
      report,
      companyDetails: {
        partner: { name: eapName }
      }
    } = this.props;

    const timestamp = new Date(this.props.timestamp);
    const suicidalContentHeader = _.get(
      this.props,
      'companyDetails.suicidalIdeationData.header',
      ''
    );

    return (
      <div className={container}>
        <div className={goalsHeaderSection}>
          <div className={goalsHeader}>
            <div className={goalsHeaderContent}>
              <span data-testid="goal-page-header" className={headerStyle}>
                {headerText}
              </span>
              <p
                data-testid="report-page-goal-desc-text"
                className={headerDescStyle}
              >
                {headerDesc}
              </p>
              {beenSixWeeksSinceAssessment ? (
                <p className={headerDescStyle}>
                  <button
                    type="button"
                    className={retakeAssessmentButton}
                    onClick={this._handleReAssess}
                  >
                    <Lang path="reportRetest" />
                  </button>
                </p>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>

        <ContentContainer size="large">
          {hasError && (
            <div className={tryAgainContainer}>
              <ErrorRetry
                title={<Lang path="reportError" />}
                onClick={this._handleRetry}
                isFetching={isFetching}
              />
            </div>
          )}
          {this.props.suicidal && (
            <SelfHarmContent
              content={this.props.suicidal}
              header={suicidalContentHeader}
            />
          )}
          {featureService._hasCoachWelcomeMessageFeature() && (
            <CoachInfo
              details={this.props.coachDetails}
              autoCoachInitiated={this.props.autoCoachInitiated}
              autoCoachError={this.props.autoCoachError}
              eapName={eapName}
              report={report}
            />
          )}
          {this._renderHighWorkStressGoal()}
          {this._renderReportItems(levelTypes.high, isFetching)}
          {this._renderReportItems(levelTypes.moderate, isFetching)}
          {this._renderReportItems(levelTypes.low, isFetching)}
          {!hasError && (
            <Row>
              {!isFetching && (
                <h5 className={reportTimestampStyle}>
                  <Lang
                    path="reportTimestamp"
                    values={{ reportTimestamp: timestamp.toLocaleDateString() }}
                  />
                </h5>
              )}
            </Row>
          )}
          <Row>
            <ReportTimePicker
              reportId={this.props.reportId}
              updateFunction={api.updateReportTime}
            />
          </Row>
        </ContentContainer>

        <div className={`${reportFooterImage} ${footerText}`}>
          <div className={bottomLinkContainer}>
            <Row>
              <Col span={24} className={retakeLink}>
                <button
                  data-testid="survey-retake-button"
                  type="button"
                  onClick={this._handleReAssess}
                  className={link}
                >
                  <Lang path="reportRetest" />
                </button>
              </Col>
              <Col span={24}>
                <button
                  data-testid="view-all-results-button"
                  type="button"
                  onClick={this._handleHistoryClick}
                  className={viewAllResults}
                >
                  <Lang path="reportHistory" />
                </button>
              </Col>
            </Row>
          </div>
          <p className={reportText}>
            <Lang
              path="reportFooter"
              values={{ surveyName: this.props.t('surveyName') }}
            />
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    reportList: { reports },
    reportById: { isFetching, hasError },
    companyDetails: { companyDetails },
    profile: { profile },
    reportList,
    requestCoach: {
      coachDetails,
      autoCoach: { isFetching: autoCoachInitiated, hasError: autoCoachError }
    }
  } = state;

  const currentReportId = ownProps.match.params.id;

  const report = reports.find((item) => item._id === currentReportId);

  const defaultProps = {
    isFetching,
    hasError,
    companyDetails,
    profile,
    coachDetails,
    autoCoachInitiated,
    autoCoachError
  };

  if (report) {
    return {
      suicidal: report.suicidal,
      report: report.categories,
      reportId: report._id,
      timestamp: report.timestamp,
      reportList,
      ...defaultProps
    };
  }
  return defaultProps;
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    resetAssessment: bindActionCreators(resetAssessment, dispatch),
    getReport: bindActionCreators(getReport, dispatch),
    selectGoal: bindActionCreators(selectGoal, dispatch),
    showModal: bindActionCreators(showModal, dispatch)
  }
});

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Report))
);
