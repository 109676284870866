// Core
import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import helpers from 'utils/helpers';
import history from 'utils/history';
import { stringify } from 'tiny-querystring';

// components
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// import { Scrollbars } from 'react-custom-scrollbars';
// import Collapse, { Panel } from 'rc-collapse';
// import 'rc-collapse/assets/index.css';
import 'react-tabs/style/react-tabs.css';
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel
// } from 'react-accessible-accordion';
// import 'react-accessible-accordion/dist/fancy-example.css';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import Label from '../Label';
// import GraphChart from '../GraphChart';
// import closeIcons from '../../../../assets/icons/closeIcon.svg';
// import AddIcon from '../../../../assets/icons/addwhite.svg';
// import Depression from '../../../../assets/img/depression.jpg';
// import Anger from '../../../../assets/img/angers.jpg';
// import EditIcon from '../../../../assets/icons/pencil.svg';
// const bgColors = {
//   Green: '#8CC152',
//   Yellow: '#F6BB42'
// };

// api
import api from 'api/assessment';

// logger
import logger from 'utils/logger';

// assets
import videoPlaceHolderImg from 'assets/img/videoPlaceHolderImg.png';

// actions
import fetchMediaList from 'store/actions/coachchat/MediaListAction';

// graphql
import { mutations } from 'utils/graphql';
import handleUnexpectedError from 'store/helper';
import Lang from '../../../../../components/Lang';
import { MediaCard } from '../../../../../components/Coaching';
import toast from '../../../../../components/Toast';
import { Spinner } from '../../../../../components/ui/Layout';
import { ChatMediaError } from '../Error';
import ClientProgress from './components/ClientProgress/ClientProgress';
import { ClientAssessments } from './components';
import ClientDetails from '../ClientDetails';
import styles from './ChatRight.module.scss';

const { SEND_SUICIDAL_ALERT } = mutations;

const {
  mainTab,
  mediaContainer,
  mediaCardContainer,
  tabListStyle,
  tabStyle,
  selectedTabStyle,
  tabPanelStyle,
  selectedTabPanelStyle,
  arrowBackContainerStyle
} = styles;

const ChatRight = ({
  user,
  chatListError,
  isChatListEmpty,
  mediaMessages,
  setMediaMessages,
  actions,
  mediaList,
  isError,
  isFetchingMediaList,
  backButtonHandler,
  queryParams
}) => {
  const infoTabActive = useSelector((state) => _.get(state, 'infoTab'));
  if (!_.isEmpty(infoTabActive) && !_.has(queryParams, 'view')) {
    const searchParam = stringify({ ...queryParams, view: 'info' });
    history.replace({
      search: searchParam
    });
  }
  const [clientReport, setClientReport] = useState({
    isFetching: true,
    suicidal: 'NA',
    domesticViolence: 'NA'
  });
  const [isFetchingSuicidalAlert, setIsFetchingSuicidalAlert] = useState(false);
  const [sendSuicidalAlertMutation] = useMutation(SEND_SUICIDAL_ALERT);
  const clientId = _.get(user, 'to', '');
  // TODO: Need to consider empty response use case
  useEffect(() => {
    if (
      _.isEmpty(mediaList) &&
      isError === false &&
      isFetchingMediaList === false
    ) {
      actions.fetchMediaList();
    }
  }, [actions, mediaList, isError, isFetchingMediaList]);

  useEffect(() => {
    if (!_.isEmpty(clientId)) {
      setClientReport({ isFetching: true });
      api
        .getClientReport(clientId)
        .then((result) => {
          const data = _.get(result, 'data', {});
          if (_.isEmpty(data) || _.isEmpty(_.get(data, 'assessments', []))) {
            setClientReport({
              isFetching: false,
              suicidal: 'NA',
              domesticViolence: 'NA'
            });
          } else {
            setClientReport({
              isFetching: false,
              suicidal: _.get(data, 'suicidal', false) === true ? 'YES' : 'NO',
              domesticViolence:
                _.get(data, 'domesticViolence', false) === true ? 'YES' : 'NO'
            });
          }
        })
        .catch((error) => {
          setClientReport({
            isFetching: false,
            suicidal: 'NA',
            domesticViolence: 'NA'
          });
          handleUnexpectedError(
            'An error occurred while fetching client report for coach',
            'ChatRight.useEffect',
            error
          );
        });
    }
  }, [clientId]);

  const onSelectMedia = (id) => {
    const selectedMedia = mediaList.filter((mediaItem) => mediaItem.id === id);
    if (_.isEmpty(selectedMedia)) return;
    // setMediaMessages(mediaMessages.concat(selectedMedia[0])); // Need for multiple media message support
    setMediaMessages([selectedMedia[0]]);
  };
  const fetchMedia = () => actions.fetchMediaList();

  const _suicidalAlertClickHandler = (clientAuth0Id, clientName) => {
    // sets button to loading
    setIsFetchingSuicidalAlert(true);
    const source = 'coach';
    sendSuicidalAlertMutation({
      variables: {
        clientAuth0Id,
        source
      }
    })
      .then((res) => {
        logger.info(
          'Successfully sent suicidal ideation alert',
          'ChatRight._suicidalAlertClickHandler',
          { res }
        );
        setIsFetchingSuicidalAlert(false);
        toast.info(
          <Lang path="suicidalAlertSuccessText" values={{ clientName }} />,
          null,
          {
            toastId: 'suicidal-alert-success-toast'
          }
        );
      })
      .catch((err) => {
        logger.error(
          'Unable to send suicidal ideation alert.',
          'ChatRight._suicidalAlertClickHandler',
          { clientAuth0Id, source, err }
        );
        setIsFetchingSuicidalAlert(false);
        toast.error(<Lang path="suicidalAlertFailureText" />);
      });
    return null;
  };

  return (
    <>
      {!user && !chatListError && !isChatListEmpty ? (
        <Spinner />
      ) : (
        <div className={mainTab} data-testid="chat-right-container">
          <Tabs>
            <TabList className={tabListStyle}>
              {helpers.platformInfo.isMobile && (
                <div className={arrowBackContainerStyle}>
                  <ArrowBackIcon onClick={backButtonHandler} />
                </div>
              )}

              <Tab data-testid="about-tab">
                <Lang
                  path="coachChat.chatRight.aboutTab"
                  values={{
                    name:
                      user && user.name
                        ? user.name.split(' ')[0].toUpperCase()
                        : ''
                  }}
                />
              </Tab>
              <Tab data-testid="content-suggestion-tab">
                <Lang path="coachChat.chatRight.contentTab" />
              </Tab>
            </TabList>

            <TabPanel className={tabPanelStyle}>
              {user && !chatListError && !isChatListEmpty && (
                <>
                  <ClientDetails
                    {...user}
                    clientReport={clientReport}
                    suicidalAlertClickHandler={_suicidalAlertClickHandler}
                    isFetchingSuicidalAlert={isFetchingSuicidalAlert}
                  />
                  <Tabs>
                    <TabList className={tabListStyle}>
                      <Tab
                        selectedClassName={selectedTabStyle}
                        className={tabStyle}
                      >
                        <span data-testid="coach-chat-assessment-tab">
                          <Lang path="coachChat.chatRight.assessmentTab" />
                        </span>
                      </Tab>
                      <Tab
                        selectedClassName={selectedTabStyle}
                        className={tabStyle}
                      >
                        <span data-testid="coach-chat-goal-tab">
                          <Lang path="coachChat.chatRight.goalTab" />
                        </span>
                      </Tab>
                    </TabList>

                    <TabPanel
                      selectedClassName={selectedTabPanelStyle}
                      className={tabPanelStyle}
                    >
                      <ClientAssessments clientAuth0Id={clientId} />
                    </TabPanel>
                    <TabPanel
                      selectedClassName={selectedTabPanelStyle}
                      className={tabPanelStyle}
                    >
                      <ClientProgress clientAuth0Id={clientId} />
                    </TabPanel>
                  </Tabs>
                </>
              )}
            </TabPanel>
            <TabPanel className={tabPanelStyle}>
              {isFetchingMediaList === true && <Spinner />}
              {isFetchingMediaList === false && isError === true && (
                <ChatMediaError retry={fetchMedia} />
              )}
              {isError === false && isFetchingMediaList === false && (
                <div className={mediaContainer}>
                  {!_.isEmpty(mediaList) &&
                    mediaList.map((mediaItem) => (
                      <div className={mediaCardContainer} key={mediaItem.id}>
                        <MediaCard
                          {...mediaItem}
                          thumbnail={videoPlaceHolderImg}
                          isSelected={
                            !_.isEmpty(mediaMessages) &&
                            mediaMessages.indexOf(mediaItem) !== -1
                          }
                          onSelectMedia={() => onSelectMedia(mediaItem.id)}
                          isSelectable
                          // Todo: need to update this if more media types are added
                          analyticsMessage="ClientChat preview content suggestion video"
                        />
                      </div>
                    ))}
                </div>
              )}
            </TabPanel>
          </Tabs>
        </div>
      )}

      {/* <div className={tabSectionOuter}>
        <Scrollbars autoHide>
          <Tabs className={tabSection}>
            <TabList className={tabList}>
              <Tab className={tabTitle}>Client Activity</Tab>
              <Tab className={tabTitle}>Notes</Tab>
              <Tab className={tabTitle}>Reminder</Tab>
            </TabList>

            <TabPanel className={tabPanel}>
              <div className={clientAct}>
                <Accordion>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <div className={itemsSec}>
                          <span className={indicatorColor} style={{ backgroundColor: bgColors.Yellow }} />
                          <img src={Depression} alt="" className={imgStyle} />
                          <span className={headingStyle}>Depression<p>Last Activity: 15 Hours ago</p></span>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <span className={scoreSec}>Score : <p>Medium</p></span>
                      <Collapse className={arrowBlock}>
                        <Panel header="Session">
                          <GraphChart />
                        </Panel>
                      </Collapse>
                      <Collapse>
                        <Panel header="Anxiety Monitor">
                          <p>We strongly encourage you to write your own styles for your accordions</p>
                        </Panel>
                      </Collapse>
                      <Collapse>
                        <Panel header="Behaviours">
                          <p>We strongly encourage you to write your own styles for your accordions</p>
                        </Panel>
                      </Collapse>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <div className={itemsSec}>
                          <span className={indicatorColor} style={{ backgroundColor: bgColors.Green }} />
                          <img src={Anger} alt="" className={imgStyle} />
                          <span className={HeadingStyle}>Anger<p>Last Activity: 3 Hours ago</p></span>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <span className={scoreSec}>Score : <p>Medium</p></span>
                      <Collapse className={arrowBlock}>
                        <Panel header="Session">
                          <p>We strongly encourage you to write your own styles for your </p>
                        </Panel>
                      </Collapse>
                      <Collapse>
                        <Panel header="Anxiety Monitor">
                          <p>We strongly encourage you to write your own styles for your accordions</p>
                        </Panel>
                      </Collapse>
                      <Collapse>
                        <Panel header="Behaviours">
                          <p>We strongly encourage you to write your own styles for your accordions</p>
                        </Panel>
                      </Collapse>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <div className={atemsSec}>
                          <span className={andicatorColor} style={{ backgroundColor: bgColors.Yellow }} />
                          <img src={Depression} alt="" className={amgStyle} />
                          <span className={headingStyle}>Depression<p>Last Activity: 15 Hours ago</p></span>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <span className={scoreSec}>Score : <p>Medium</p></span>
                      <Collapse className={arrowBlock}>
                        <Panel header="Session">
                          <p>We strongly encourage you to write your own styles for your </p>
                        </Panel>
                      </Collapse>
                      <Collapse>
                        <Panel header="Anxiety Monitor">
                          <p>We strongly encourage you to write your own styles for your accordions</p>
                        </Panel>
                      </Collapse>
                      <Collapse>
                        <Panel header="Behaviours">
                          <p>We strongly encourage you to write your own styles for your accordions</p>
                        </Panel>
                      </Collapse>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion>
              </div>
            </TabPanel>

            <TabPanel className={tabPanel}>
              <div className={noteSec}>
                <button className={addNote}>Add a New Note...</button>
                <div className={noteTop}>
                  <textarea type="text" data-testid="" placeholder="Type your message" />
                  <button className={closeBtn}><img src={closeIcons} alt="" /></button>
                  <button className={saveBtn}>Save</button>
                </div>
                <Accordion>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        June 28, 2019, Friday
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Exercitation in fugiat est ut ad ea cupidatat ut in
                        cupidatat occaecat ut occaecat consequat est minim minim
                        esse tempor laborum consequat esse adipisicing eu
                        reprehenderit enim.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        June 26, 2019, Wednesday
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In ad velit in ex nostrud dolore cupidatat consectetur
                        ea in ut nostrud velit in irure cillum tempor laboris
                        sed adipisicing eu esse duis nulla non.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </TabPanel>

            <TabPanel className={tabPanel}>
              <div className={reminderSec}>
                <button className={reminderBtn}><img src={AddIcon} alt="" />Add new remindaer</button>
                <div className={addRemind}>
                  <form>
                    <div className={inputOuter}>
                      <input type="" data-testid="" placeholder="Add Title" className={inputStyle} />
                    </div>
                    <div className={inputOuterDate}>
                      <DatePicker
                        className={inputDate}
                        name="eventDate"
                        selected={eventDate}
                        placeholderText="Date"
                        onChange={(e) => {
                          handleDateChange(e, 'eventDate');
                        }}
                      />
                    </div>
                    <div className={inputOuter}>
                      <DatePicker
                        className={inputTime}
                        name="eventTime"
                        selected={eventTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                        timeCaption="Time"
                        placeholderText="Time"
                        onChange={(e) => {
                          handleDateChange(e, 'eventTime');
                        }}
                      />
                    </div>
                    <div className={inputOuter}>
                      <textarea type="" data-testid="" placeholder="Add Details" className={textareaStyle} />
                    </div>
                    <button className={closeBtn}><img src={closeIcons} alt="" /></button>
                    <button className={saveBtn}>Save</button>
                  </form>
                </div>

                <div className={remiderList}>
                  <div className={listBoxe}>
                    <input type="checkbox" data-testid="box-1" />
                    <Label htmlFor="box-1">
                      <span className={remidListSec}>
                        <h6>Send follow up message to Steve</h6>
                        <p>regarding his interview scheduled on July 2nd, 2019.</p>
                        <div className={dateBorder}>June 26, 2019, Wednesday</div>
                      </span>
                    </Label>
                    <button className={editBtn}><img src={EditIcon} alt="" /></button>
                  </div>
                  <div className={listBoxe}>
                    <input type="checkbox" data-testid="box-2" />
                    <Label htmlFor="box-2">
                      <span className={remidListSec}>
                        <h6>Send follow up message to Steve</h6>
                        <p>regarding his interview scheduled on July 2nd, 2019.</p>
                        <div className={dateRed}>June 26, 2019, Wednesday</div>
                      </span>
                    </Label>
                    <button className={editBtn}><img src={EditIcon} alt="" /></button>
                  </div>

                </div>
              </div>
            </TabPanel>
          </Tabs>
        </Scrollbars>
      </div> */}
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    coachChat: {
      mediaList: { mediaList, isError, isFetching: isFetchingMediaList }
    }
  } = state;
  return {
    mediaList,
    isError,
    isFetchingMediaList
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchMediaList: bindActionCreators(fetchMediaList, dispatch)
  }
});

ChatRight.propTypes = {
  user: propTypes.shape({ name: propTypes.string }),
  chatListError: propTypes.bool,
  isChatListEmpty: propTypes.bool,
  mediaMessages: propTypes.arrayOf(propTypes.shape({})),
  setMediaMessages: propTypes.func,
  actions: propTypes.shape({ fetchMediaList: propTypes.func }),
  mediaList: propTypes.oneOfType([
    propTypes.object,
    propTypes.arrayOf(propTypes.object)
  ]),
  isError: propTypes.bool,
  isFetchingMediaList: propTypes.bool,
  backButtonHandler: propTypes.func,
  queryParams: propTypes.shape({ view: propTypes.string })
};

ChatRight.defaultProps = {
  user: {},
  chatListError: false,
  isChatListEmpty: false,
  mediaMessages: [],
  setMediaMessages: () => {},
  actions: {},
  mediaList: [],
  isError: false,
  isFetchingMediaList: false,
  backButtonHandler: () => {},
  queryParams: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRight);
