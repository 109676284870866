export default (messages) => {
  const lastMessageIndex = messages.length - 1;
  let lastMessageId = null;
  for (let i = lastMessageIndex; i >= 0; i -= 1) {
    const currentMessageId = messages[i].id;
    if (
      !currentMessageId.startsWith('graphQlError') &&
      !currentMessageId.startsWith('graphQlError')
    ) {
      lastMessageId = currentMessageId;
      break;
    }
  }
  return lastMessageId;
};
