import React from 'react';
import { ApolloProvider } from '@apollo/react-common';
import apolloClient from 'utils/graphql/apollo-client';

const wrapWithApolloClient = (WrappedComponent) => ({ ...props }) => (
  <ApolloProvider client={apolloClient}>
    <WrappedComponent {...props} />
  </ApolloProvider>
);

export default wrapWithApolloClient;
