/**
 * hoc that redirects the user to intro page in case they decide to
 * open pages that shouldn't be opened while still logged in, like login,register,forget password etc
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isLoggedIn } from '../../utils/auth';
import helpers from '../../utils/helpers';
import getCompanyList from '../../store/actions/CompanyListAction';
import logger from '../../utils/logger';

const withoutAuth = (WrappedComponent) => {
  class decWithoutAuth extends Component {
    componentDidMount() {
      if (isLoggedIn()) {
        this.props.history.replace(helpers.homeUrl());
      } else if (this.props.companyList.length <= 0) {
        logger.info('Fetching company list', 'withoutAuth.componentDidMount');
        this.props.actions.getCompanyList();
      }
    }

    render() {
      return !isLoggedIn() ? <WrappedComponent {...this.props} /> : null;
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    actions: {
      getCompanyList: bindActionCreators(getCompanyList, dispatch)
    }
  });

  const mapStateToProps = (state) => {
    const {
      companyList: { companyList }
    } = state;
    return { companyList };
  };

  return connect(mapStateToProps, mapDispatchToProps)(decWithoutAuth);
};

export default withoutAuth;
