/**
 * Conversational assessment Actions
 */

import api from '../../api/assessment';

import {
  FETCH_CONVERSATIONAL_ASSESSMENT,
  FETCH_CONVERSATIONAL_ASSESSMENT_DONE,
  FETCH_CONVERSATIONAL_ASSESSMENT_ERROR,
  SET_CONVERSATIONAL_ASSESSMENT_PROGRESS_ID,
  SET_CONVERSATIONAL_ASSESSMENT_DATA,
  CLEAR_CONVERSATIONAL_ASSESSMENT_DATA
} from './types';

import logger from '../../utils/logger';
import handleUnexpectedError from '../helper';

const fetchConversationalAssessmentStart = () => ({
  type: FETCH_CONVERSATIONAL_ASSESSMENT
});

const fetchConversationalAssessmentDone = (data) => ({
  type: FETCH_CONVERSATIONAL_ASSESSMENT_DONE,
  data
});

const fetchConversationalAssessmentError = () => ({
  type: FETCH_CONVERSATIONAL_ASSESSMENT_ERROR
});

const setProgressId = (payload) => ({
  type: SET_CONVERSATIONAL_ASSESSMENT_PROGRESS_ID,
  payload
});

export const setConversationalAssessmentData = (payload) => ({
  type: SET_CONVERSATIONAL_ASSESSMENT_DATA,
  payload
});

export const clearConversationalAssessmentData = () => ({
  type: CLEAR_CONVERSATIONAL_ASSESSMENT_DATA
});

export const fetchConversationalAssessment = (assessmentId) => (dispatch) => {
  dispatch(fetchConversationalAssessmentStart());
  return api
    .fetchChatQuestions(assessmentId)
    .then((resp) => {
      if (resp.status === 200) {
        dispatch(fetchConversationalAssessmentDone(resp.data.questions));
      } else {
        logger.error(
          'An error occurred while fetching the conversational questions',
          'ConversationalAssessmentAction.fetchConversationalAssessment',
          { resp }
        );
        dispatch(fetchConversationalAssessmentError());
      }
    })
    .catch((err) => {
      handleUnexpectedError(
        'An unexpected error occurred while fetching the conversational questions',
        'ConversationalAssessmentAction.fetchConversationalAssessment',
        err
      );
      dispatch(fetchConversationalAssessmentError());
    });
};

export const fetchProgressId = (assessmentId) => (dispatch) =>
  api
    .getProgressId(assessmentId)
    .then((resp) => {
      if (resp.status === 200) {
        dispatch(setProgressId(resp.data.progressId));
      } else {
        logger.error(
          'An unexpected error occurred while fetching the progress id',
          'ConversationalAssessmentAction.getProgressId',
          { resp }
        );
      }
    })
    .catch((err) => {
      handleUnexpectedError(
        'An error occurred while fetching the progress id',
        'AssessmentAction.getProgressId',
        err
      );
    });
