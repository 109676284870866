import React from 'react';
import punycode from 'punycode';
import Linkify from 'react-linkify';
import analytics from 'utils/analytics';

const componentDecorator = (href, text, key) => (
  <a
    href={href}
    key={key}
    onClick={() =>
      analytics.track('chat', 'clicked on the link', { link: href })
    }
    target="_blank"
    rel="noopener noreferrer"
  >
    {punycode.toASCII(text)}
  </a>
);

const LinkifyDecorated = ({ children, messageId }) => (
  <Linkify data-testid={messageId} componentDecorator={componentDecorator}>
    {children}
  </Linkify>
);

export default LinkifyDecorated;
