import React from 'react';
import Lang from '../../components/Lang';

export const reasons = {
  expired: {
    desc: <Lang path="logoutReasons.expired" />,
    action: 'session expired'
  },
  noLogin: {
    desc: <Lang path="logoutReasons.noLogin" />,
    action: 'no login'
  },
  error: {
    desc: <Lang path="logoutReasons.errorText" />,
    action: 'error'
  },
  default: {
    desc: <Lang path="logoutReasons.defaultText" />,
    action: 'clicked logout'
  }
};
