import React from 'react';
import LocalPhone from '@material-ui/icons/LocalPhone';
import { ActionButton, OutlineButton } from 'components/ui/Buttons';
import counselorImg from 'assets/img/dashboard/resources/counselor.png';
import chatTherapyImg from 'assets/img/dashboard/resources/chat.png';
import televideoTherapyImg from 'assets/img/dashboard/resources/chat-and-teletherapy.png';
import Lang from 'components/Lang';
import featureService from 'utils/feature-service';

export default [
  {
    id: 'counselor',
    isVisible: () => featureService._hasCounselorTherapyFeature(),
    title: <Lang path="popups.therapy.counselorCard.title" />,
    description: (
      <Lang
        path="popups.therapy.counselorCard.description"
        components={{ br: <br /> }}
      />
    ),
    img: counselorImg,
    footerContent: [
      {
        id: 'counselor-button-1',
        name: 'counselor',
        text: <Lang path="popups.therapy.counselorCard.footerButton1" />,
        buttonComponent: ActionButton,
        url: 'https://www.resourcesforliving.com/find-counselor?txtUserName=wfbstaff&txtPassword=staff',
        hasIcon: false
      },
      {
        id: 'counselor-button-2',
        name: 'call counselor',
        text: <Lang path="popups.therapy.counselorCard.footerButton2" />,
        buttonComponent: OutlineButton,
        hasIcon: true,
        url: 'tel:123-456-7890',
        icon: LocalPhone
      }
    ]
  },
  {
    id: 'chat-therapy',
    isVisible: () => featureService._hasChatTherapyFeature(),
    title: <Lang path="popups.therapy.chatTherapy.title" />,
    description: (
      <Lang
        path="popups.therapy.chatTherapy.description"
        components={{ br: <br /> }}
      />
    ),
    img: chatTherapyImg,
    footerContent: [
      {
        id: 'chat-therapy-button',
        name: 'chat therapy',
        text: <Lang path="popups.therapy.chatTherapy.footerButton" />,
        hasIcon: false,
        url: 'https://www.resourcesforliving.com/home/services/talkspace-online-therapy?txtUserName=wfbstaff&txtPassword=staff',
        buttonComponent: OutlineButton
      }
    ]
  },
  {
    id: 'televideoTherapy',
    isVisible: () => featureService._hasTelevideoTherapyFeature(),
    title: <Lang path="popups.therapy.televideoTherapy.title" />,
    description: (
      <Lang
        path="popups.therapy.televideoTherapy.description"
        components={{ br: <br />, bold: <b /> }}
      />
    ),
    img: televideoTherapyImg,
    footerContent: [
      {
        id: 'televideoTherapy-button',
        name: 'televideo therapy',
        text: <Lang path="popups.therapy.televideoTherapy.footerButton" />,
        hasIcon: false,
        url: 'https://www.resourcesforliving.com/home/services/talkspace-online-therapy?txtUserName=wfbstaff&txtPassword=staff',
        buttonComponent: OutlineButton
      }
    ]
  },
  {
    id: 'eap-counselor',
    isVisible: () => featureService._hasTherapyAccessWithEAPFeature(),
    title: <Lang path="popups.therapy.eapCounselorCard.title" />,
    description: (
      <Lang
        path="popups.therapy.eapCounselorCard.description"
        components={{ br: <br /> }}
      />
    ),
    img: counselorImg,
    footerContent: [
      {
        id: 'eap-counselor-button-1',
        name: 'eapCounselor',
        text: <Lang path="popups.therapy.eapCounselorCard.footerButton1" />,
        buttonComponent: ActionButton,
        url: 'https://hrblock.lyrahealth.com',
        hasIcon: false
      },
      {
        id: 'eap-counselor-button-2',
        name: 'call eapCounselor',
        text: <Lang path="popups.therapy.eapCounselorCard.footerButton2" />,
        buttonComponent: OutlineButton,
        hasIcon: true,
        url: 'tel:877-219-3218',
        icon: LocalPhone
      }
    ]
  }
];
