/**
 *
 * Coach Assignment Error
 *
 */

import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ErrorRetry from '../../ErrorRetry';
import Lang from '../../Lang';

// styles
import style from './AutoAssignmentError.module.scss';

const {
  coachRequestModalContainer,
  coachRequestContainer,
  coachRequestModalTopPanel,
  coachRequestTopPanel,
  confirmationIcon,
  errorIcon
} = style;

function AutoAssignmentError({ isModal, retryHandler, isFetching }) {
  return (
    <div
      className={isModal ? coachRequestModalContainer : coachRequestContainer}
    >
      <div
        className={isModal ? coachRequestModalTopPanel : coachRequestTopPanel}
      >
        <div className={confirmationIcon}>
          <ErrorOutlineIcon className={errorIcon} />
        </div>
        <ErrorRetry
          title={<Lang path="coachRequestCard.autoCoachError.header" />}
          onClick={retryHandler}
          isFetching={isFetching}
          titleId="coach-assignment-error-message"
          buttonId="coach-assignment-retry-button"
        />
      </div>
    </div>
  );
}

export default AutoAssignmentError;
