import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo
} from 'react';
import { Modal } from 'antd';
import { parse } from 'tiny-querystring';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import uuid from 'uuid/v1';

import api from 'api/request';

import withAuth from 'hoc/withAuth';

import { Spinner } from 'components/ui/Layout';
import RenderHtml from 'components/RenderHtml';
import ErrorRetry from 'components/ErrorRetry';
import Lang from 'components/Lang';

import history from 'utils/history';
import { getAuth0Id } from 'utils/auth';
import logger from 'utils/logger';
import analytics from 'utils/analytics';
import featureService from 'utils/feature-service';
import helpers from 'utils/helpers';
import moment from 'utils/moment';
import categories from 'utils/analytics/categories';

import getWorkLifeRequestQuestions from 'store/actions/dashboard/WorkLifeRequestAction';

import config from 'config';

import {
  ContentCard,
  Fields,
  Footer,
  Icon,
  Replies,
  Options,
  RequestComplete
} from './components';

import styles from './WorkLifeRequest.module.scss';

const { confirm } = Modal;

const {
  container,
  contentContainer,
  retryButtonContainer,
  spinnerTextStyle,
  spinnerFlexContainer,
  spinnerContainer,
  fieldErrorStyle
} = styles;

const LoadingSpinner = ({ children }) => (
  <div className={spinnerFlexContainer}>
    <div className={spinnerContainer}>
      <Spinner />
      <p className={spinnerTextStyle}>{children}</p>
    </div>
  </div>
);

const WorkLifeRequest = () => {
  if (!featureService._hasWorkLifeRequestFeature()) {
    history.push(helpers.homeUrl());
  }
  const shouldBlock = useRef(false);

  const dispatch = useDispatch();
  const requestId = useMemo(() => uuid(), []);
  const {
    workLifeRequest: { version: requestVersion, questions },
    isFetching
  } = useSelector((state) => _.get(state, 'workLifeRequest'));
  const { company_name: companyName, employer_id: employerId } = useSelector(
    (state) => _.get(state, 'profile.profile')
  );

  const [state, setState] = useState({
    currentQuestionKey: 'Start',
    responses: [],
    fieldsValue: {},
    optionsSelected: {},
    history: []
  });
  const [subject, setSubject] = useState('');
  const [isProcessing, setisProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [fieldError, setFieldError] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    if (window.location.search) {
      const searchString = parse(window.location.search.slice(1));
      if (searchString.type) {
        setSubject(searchString.type);
      }
    }

    let lastHistoryLocation;
    history.listen(({ pathname }) => {
      lastHistoryLocation = pathname;
    });

    const unblock = history.block(({ pathname }) => {
      const pathnameDiffers = lastHistoryLocation !== pathname;
      if (pathnameDiffers && shouldBlock.current) {
        confirm({
          title: (
            <span data-testid="confirm-modal-title">
              <Lang path="workLifeRequest.confirmModalTitle" />
            </span>
          ),
          okText: (
            <span data-testid="confirm-modal-ok-text">
              <Lang path="workLifeRequest.confirmModalOkText" />
            </span>
          ),
          cancelText: (
            <span data-testid="confirm-modal-cancel-text">
              <Lang path="cancelText" />
            </span>
          ),
          onOk() {
            setState({
              currentQuestionKey: 'Start',
              responses: [],
              fieldsValue: {},
              optionsSelected: {},
              history: []
            });
            setIsCompleted(false);
            setIsError(false);
            window.onbeforeunload = null;
            shouldBlock.current = false;
            history.push(pathname);
          }
        });
        return false;
      }
      return true;
    });

    return () => {
      window.onbeforeunload = null;
      shouldBlock.current = false;
      unblock();
    };
  }, []);

  useEffect(() => {
    if (_.isEmpty(questions)) {
      dispatch(getWorkLifeRequestQuestions());
    }
  }, [dispatch, questions]);

  const _getKeyAndValue = useCallback((reply) => {
    const resp = reply.split("'");
    let value;
    if (
      resp[2] &&
      (resp[2].startsWith(',true') || resp[2].startsWith(',false'))
    ) {
      value = resp[2].split(',');
      value = value[1].split(')');
      [value] = value;
    } else {
      [, , , value] = resp;
    }
    return { [resp[1]]: value };
  }, []);

  const _sendUserResponse = useCallback(async () => {
    analytics.track(
      categories.WORK_LIFE_REQUEST,
      'clicked submit for review button',
      {
        trigger: 'work_life_request_submit_click',
        subcategory: subject
      }
    );
    setisProcessing(true);
    try {
      const result = await api.processWorkLifeRequestQuestions({
        id: requestId,
        userId: getAuth0Id(),
        requestId: 'workLifeRequest',
        requestVersion,
        response: Object.assign({}, ...state.responses, state.fieldsValue),
        companyName: companyName || employerId,
        tenant: config.brand,
        sourceName: helpers.platformInfo.isDesktop ? 'Web App' : 'Mobile App'
      });
      if (result && result.status === 200) {
        analytics.info(
          categories.WORK_LIFE_REQUEST,
          'successful work life request form submission',
          {
            trigger: 'work_life_request_action'
          }
        );
        logger.info(
          'Successful work life request form submission',
          'WorkLifeRequest._sendUserResponse'
        );
        setState({
          ...state,
          currentQuestionKey: 'submit_questionnaire'
        });
        setIsError(false);
        setIsCompleted(true);
        window.onbeforeunload = null;
        shouldBlock.current = false;
      } else {
        analytics.info(
          categories.WORK_LIFE_REQUEST,
          'unsuccessful work life request form submission',
          {
            trigger: 'work_life_request_action'
          }
        );
        logger.error(
          'Unsuccessful work life request form submission',
          'WorkLifeRequest._sendUserResponse'
        );
        setIsError(true);
        setIsCompleted(false);
      }
    } catch (err) {
      analytics.info(
        categories.WORK_LIFE_REQUEST,
        'error in submitting work life request form',
        {
          trigger: 'work_life_request_action'
        }
      );
      logger.error(
        'Error in submitting work life request form',
        '_sendUserResponse.WorkLifeRequest',
        { err }
      );
      setIsError(true);
      setIsCompleted(false);
    }
    setisProcessing(false);
  }, [companyName, employerId, requestId, requestVersion, state, subject]);

  const { icon, txt, options, fields, replies, validation } = _.get(
    questions,
    `${state.currentQuestionKey}`,
    {}
  );

  const validateFields = useCallback(
    (currentState, fieldsArr, fieldValidation) => {
      let validationObj = { isValid: true };
      if (fieldValidation) {
        if (fieldsArr && fieldsArr.length > 0) {
          for (let i = 0; i < fieldsArr.length; i += 1) {
            const val = _.get(
              currentState,
              `fieldsValue[${fieldsArr[i].name}]`,
              ''
            );
            if (fieldValidation.required && _.isEmpty(val.trim())) {
              validationObj = {
                isValid: false,
                message: (
                  <Lang
                    path="validations.emptyField"
                    values={{ field: fieldValidation.name }}
                  />
                )
              };
            } else if (
              fieldValidation.regex &&
              !new RegExp(fieldValidation.regex).test(val)
            ) {
              validationObj = {
                isValid: false,
                message: (
                  <Lang
                    path="validations.invalidField"
                    values={{ field: fieldValidation.name }}
                  />
                )
              };
            }
          }
        }

        const value = _.get(
          currentState,
          `fieldsValue[${state.currentQuestionKey}]`,
          ''
        );

        if (
          fieldValidation.dateFormat &&
          !moment(value, fieldValidation.dateFormat, true).isValid()
        ) {
          validationObj = {
            isValid: false,
            message: (
              <Lang
                path="validations.invalidDateField"
                values={{ field: fieldValidation.name }}
              />
            )
          };
        }
      }
      return validationObj;
    },
    [state.currentQuestionKey]
  );

  const _goToNextQuestion = useCallback(
    (selectedReply) => {
      if (selectedReply[1] === 'topics' && subject !== '') {
        const questionReplies = _.get(questions, 'topics.replies', []);
        const resp = _getKeyAndValue(
          questionReplies.filter((reply) => reply[1] === subject)[0][2]
        );
        setState({
          ...state,
          currentQuestionKey: subject,
          history: [...state.history, state.currentQuestionKey],
          responses: [
            ...state.responses,
            {
              started: 'true',
              ...resp
            }
          ]
        });
      } else if (selectedReply[0] === 'Submit For Review') {
        _sendUserResponse();
      } else {
        const resp = selectedReply[2] ? _getKeyAndValue(selectedReply[2]) : {};
        const validationObj = validateFields(state, fields, validation);
        if (validationObj.isValid) {
          setFieldError('');
          setState({
            fieldsValue: {},
            optionsSelected: {},
            currentQuestionKey: selectedReply[1],
            history: [...state.history, state.currentQuestionKey],
            responses: [
              ...state.responses,
              {
                ...state.fieldsValue,
                ...state.optionsSelected,
                ...resp
              }
            ]
          });
        } else {
          setFieldError(validationObj.message);
        }
      }
      if (!isCompleted) {
        shouldBlock.current = true;
        window.onbeforeunload = () => true;
      }
    },
    [
      _getKeyAndValue,
      _sendUserResponse,
      fields,
      isCompleted,
      questions,
      state,
      subject,
      validateFields,
      validation
    ]
  );

  const _goToPreviousQuestion = useCallback(() => {
    if (!_.isEmpty(state.history)) {
      if (state.history.length === 1) {
        window.onbeforeunload = null;
        shouldBlock.current = false;
      }
      setFieldError('');
      setState({
        history: [...state.history].slice(0, -1),
        responses: [...state.responses].slice(0, -1),
        currentQuestionKey: state.history[state.history.length - 1],
        optionsSelected: {},
        fieldsValue: {}
      });
    } else {
      history.goBack();
    }
  }, [state.history, state.responses]);

  const _startOver = useCallback(() => {
    setFieldError('');
    confirm({
      title: <Lang path="workLifeRequest.confirmModalTitle" />,
      okText: <Lang path="workLifeRequest.confirmModalOkText" />,
      cancelText: <Lang path="cancelText" />,
      onOk() {
        setState({
          currentQuestionKey: 'Start',
          responses: [],
          fieldsValue: {},
          optionsSelected: {},
          history: []
        });
        setIsCompleted(false);
        setIsError(false);
        window.onbeforeunload = null;
        shouldBlock.current = false;
      }
    });
  }, []);

  return (
    <div className={container}>
      {isError && !isProcessing && (
        <div className={retryButtonContainer}>
          <ErrorRetry
            isFetching={false}
            onClick={_sendUserResponse}
            title={<Lang path="workLifeRequest.processErrorText" />}
          />
        </div>
      )}
      {isCompleted && !isError ? (
        <RequestComplete />
      ) : (
        <div className={contentContainer}>
          {isProcessing && (
            <LoadingSpinner>
              <Lang path="workLifeRequest.processText" />
            </LoadingSpinner>
          )}
          {isFetching && (
            <LoadingSpinner>
              <Lang path="workLifeRequest.loadingText" />
            </LoadingSpinner>
          )}
          {!isFetching &&
            !isProcessing &&
            !isCompleted &&
            !isError &&
            _.has(questions, `${state.currentQuestionKey}`) && (
              <>
                <Icon icon={icon && icon.slice(icon.indexOf('fa-') + 3)} />
                <ContentCard>
                  <RenderHtml>{txt}</RenderHtml>
                </ContentCard>
                <Fields
                  fields={fields}
                  fieldsValue={state.fieldsValue}
                  setFieldsValue={(fieldsValue) =>
                    setState({ ...state, fieldsValue })
                  }
                />
                <p className={fieldErrorStyle}>{fieldError}</p>
                <Options
                  options={options}
                  optionsSelected={state.optionsSelected}
                  setOptionsSelected={(optionsSelected) =>
                    setState({ ...state, optionsSelected })
                  }
                />
                <Replies replies={replies} handleClick={_goToNextQuestion} />
              </>
            )}
        </div>
      )}
      {!isProcessing &&
        !isCompleted &&
        !isError &&
        _.has(questions, `${state.currentQuestionKey}`) && (
          <Footer
            handleBack={_goToPreviousQuestion}
            handleStartOver={!_.isEmpty(state.history) && _startOver}
          />
        )}
    </div>
  );
};

export default withAuth(WorkLifeRequest);
