/**
 * The Avatar selection form
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
// Components
import { Field, reduxForm } from 'redux-form';
import { Icon, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import RadioImage from '../../../../components/wrapped/RadioImage';
import { ActionButton } from '../../../../components/ui/Buttons';
import RadioField from '../../../../components/form/RadioField';
import BackButton from '../../../../components/wrapped/BackButton';
import Lang from '../../../../components/Lang';
import AssessmentFooter from '../AssessmentFooter';
// Styles
import styles from './AvatarForm.module.scss';

// Method to render the avatar selection control, takes a list from and to
// so we can generate multiple rows with same list instead of using multiple lists per row
const renderAvatarSelectionControl = (list, from, to) =>
  list
    .filter((elem, i) => i >= from && i < to)
    .map((avatar) => (
      <Col span={8} key={avatar.id}>
        <RadioImage value={avatar.id} src={avatar.path} alt={avatar.value} />
      </Col>
    ));

const AvatarForm = ({ handleSubmit, avatarList, onBack }) => {
  const { t } = useTranslation();
  const { container, headerText, buttonContainer, startButton } = styles;

  return (
    <form onSubmit={handleSubmit} className={container}>
      <h3 className={headerText}>
        <Lang path="avatarHeader" values={{ surveyName: t('surveyName') }} />
      </h3>
      <Field
        prefix={<Icon type="key" style={{ color: 'rgba(0,0,0,.25)' }} />}
        name="avatar_choice"
        component={RadioField}
      >
        <Row>{renderAvatarSelectionControl(avatarList, 0, 3)}</Row>
        <Row>{renderAvatarSelectionControl(avatarList, 3, 6)}</Row>
      </Field>
      <AssessmentFooter>
        <Row className={buttonContainer}>
          <Col span={12}>
            <BackButton onClick={onBack} />
          </Col>
          <Col offset={4} span={6}>
            <ActionButton
              testId="avatar-get-started"
              onClick={handleSubmit}
              className={startButton}
              action="submit"
            >
              <Lang path="startButtonText" />
            </ActionButton>
          </Col>
        </Row>
      </AssessmentFooter>
    </form>
  );
};

AvatarForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'avatar'
})(AvatarForm);
