import React, { useRef } from 'react';
import { Card } from '@material-ui/core';
import { ActionButton } from 'components/ui/Buttons';
import Lang from 'components/Lang';
import withAuth from '../../hoc/withAuth';
import styles from './Account.module.scss';
import optionList from './Account.data';
import { redirectHandler, logoutHandler } from './Account.utils';

const {
  container,
  topContainer,
  cardStyle,
  optionTextStyle,
  logoutButtonStyle,
  appVersionContainer,
  appVersionRulerStyle,
  appVersionStyle,
  termsContainer,
  termsTextStyle,
  bottomContainer
} = styles;

const Account = () => {
  const imgRef = useRef({});

  return (
    <div data-testid="account-container" className={container}>
      <div className={topContainer}>
        {optionList.map(
          (option) =>
            option.isVisible() && (
              <Card
                data-testid={`${option.id}-card`}
                className={cardStyle}
                key={option.id}
                onMouseOver={() => {
                  imgRef.current[option.id].src = option.active;
                }}
                onMouseOut={() => {
                  imgRef.current[option.id].src = option.inactive;
                }}
                onClick={() =>
                  redirectHandler({
                    name: option.text,
                    url: option.route
                  })
                }
              >
                <img
                  ref={(el) => {
                    imgRef.current[option.id] = el;
                  }}
                  src={option.inactive}
                  alt=""
                />
                <p className={optionTextStyle}>{option.text}</p>
              </Card>
            )
        )}
      </div>
      <div data-testid="logout-button-container" className={bottomContainer}>
        <ActionButton
          onClick={() => logoutHandler()}
          className={logoutButtonStyle}
          data-testid="logout-button"
        >
          <Lang path="navigation.logout" />
        </ActionButton>
        <div
          data-testid="app-version-container"
          className={appVersionContainer}
        >
          <hr className={appVersionRulerStyle} />
          <p data-testid="app-version" className={appVersionStyle}>
            {process.env.REACT_APP_BUILD_VER}
          </p>
        </div>
        <div
          data-testid="terms-and-privacy-container"
          className={termsContainer}
        >
          <span
            data-testid="privacy"
            role="button"
            tabIndex={0}
            onClick={() =>
              redirectHandler({
                name: 'Privacy',
                url: '/account/privacy'
              })
            }
            className={termsTextStyle}
            onKeyDown={() => null}
          >
            <Lang path="privacyText" />
          </span>
          <span
            role="button"
            data-testid="terms-of-services"
            tabIndex={-1}
            onClick={() =>
              redirectHandler({
                name: 'Terms of Use',
                url: '/account/terms'
              })
            }
            className={termsTextStyle}
            onKeyDown={() => null}
          >
            <Lang path="termsText" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default withAuth(Account);
