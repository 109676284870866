import React from 'react';
import ServiceCard from 'components/ServiceCard';
import { OutlineButton } from 'components/ui/Buttons';
import services from './WorkLifeServices.data';
import styles from './WorkLifeServices.module.scss';
import { onClickHandler } from './WorkLifeServices.utils';

const { container, cardContainer } = styles;

const WorkLifeServices = () => {
  const renderOutlineButton = ({ id, text, service, url }) => (
    <OutlineButton
      key={id}
      data-testid={id}
      onClick={() => onClickHandler({ url, service })}
    >
      {text}
    </OutlineButton>
  );

  return (
    <div className={container}>
      {services &&
        services.map((service) => (
          <div
            className={cardContainer}
            data-testid="work-life-container"
            key={service.id}
          >
            <ServiceCard
              id={service.id}
              img={service.img}
              header={service.header}
              desc={service.description}
              footerContent={renderOutlineButton({
                id: service.id,
                text: service.buttonText,
                url: service.url,
                service: service.name
              })}
            />
          </div>
        ))}
    </div>
  );
};

export default WorkLifeServices;
