/**
 * Assessment Intro container
 */
// Core
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
// Components
import { withTranslation } from 'react-i18next';
import featureService from 'utils/feature-service';
import { UnderlineButton, ActionButton } from '../../components/ui/Buttons';
import AssessmentHeader from '../Assessment/components/AssessmentHeader/AssessmentHeader';
import AssessmentFooter from '../Assessment/components/AssessmentFooter';
import ContentContainer from '../../components/layout/ContentContainer';
import PrivacyNotice from '../../components/sections';
import Lang from '../../components/Lang';
// hocs
import withAuth from '../../hoc/withAuth';
// assets
import calendarIcon from '../../assets/icons/calendar.svg';
import clipBoardIcon from '../../assets/icons/clipboard_intro.svg';
// utils
import config from '../../config';
// styles
import styles from './Intro.module.scss';
import analytics from '../../utils/analytics';

class Intro extends Component {
  _handleClick = () => {
    analytics.track('assessment', 'clicked next', {
      subcategory: 'intro',
      assessmentId: this.props.assessmentId
    });
    this.props.history.push('/assessment');
  };

  _handleHistoryClick = () => {
    analytics.track('assessment', 'clicked view all results', {
      subcategory: 'intro',
      assessmentId: this.props.assessmentId
    });
    this.props.history.push('/history');
  };

  _handleDashBoardClick = () => {
    analytics.track('assessment', 'clicked see action plan', {
      subcategory: 'intro',
      assessmentId: this.props.assessmentId
    });
    this.props.history.push('/my-plan');
  };

  render() {
    const {
      footerSection,
      spacingTop,
      introContent,
      alignCenter,
      privacyNoticeStyle
    } = styles;

    return (
      <div className={alignCenter}>
        <AssessmentHeader>
          <p>
            <Lang
              path="introHeader"
              values={{ surveyName: this.props.t('surveyName') }}
            />
          </p>
        </AssessmentHeader>
        <ContentContainer size="large">
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={introContent}
          >
            <Grid className={alignCenter} item xs={12} lg={4}>
              <img className={spacingTop} src={calendarIcon} alt="" />
              <p>
                <Lang path="introListItem1" />
              </p>
            </Grid>
            <Grid item xs={12} lg={4}>
              <img src={clipBoardIcon} alt="" />
              <p>
                <Lang path="introListItem2" />
              </p>
            </Grid>
          </Grid>
          {config.showPrivacyInfo === 'true' && (
            <PrivacyNotice className={privacyNoticeStyle} />
          )}
          <AssessmentFooter className={footerSection}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              className={alignCenter}
            >
              {featureService._hasIntelliPlatformFeature() && (
                <Grid
                  container
                  item
                  xs={4}
                  direction="column"
                  alignItems="center"
                >
                  <UnderlineButton onClick={this._handleDashBoardClick}>
                    <Lang path="dashBoardLink" />
                  </UnderlineButton>
                </Grid>
              )}
              <Grid
                container
                item
                xs={4}
                direction="column"
                alignItems="center"
              >
                <UnderlineButton onClick={this._handleHistoryClick}>
                  <Lang path="reportHistory" />
                </UnderlineButton>
              </Grid>

              <Grid
                container
                item
                xs={4}
                direction="column"
                alignItems="center"
              >
                <ActionButton
                  testId="intro-page-next-button"
                  onClick={this._handleClick}
                  type="primary"
                >
                  <Lang path="nextButtonText" />
                </ActionButton>
              </Grid>
            </Grid>
          </AssessmentFooter>
        </ContentContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    questions: { assessmentId }
  } = state;
  return {
    assessmentId
  };
};

export default withAuth(connect(mapStateToProps)(withTranslation()(Intro)));
