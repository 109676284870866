/**
 * The form for Number type questions in assessment
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
// Components
import { reduxForm, Field } from 'redux-form';
import { ActionButton } from '../../../../components/ui/Buttons';
import { OvalTextField } from '../../../../components/ui/Inputs';
import Lang from '../../../../components/Lang';
// Styles
import styles from './NumberTypeForm.module.scss';

const NumberTypeForm = ({ handleSubmit, validateProps }) => {
  const { input, buttonStyle, fieldLabelStyle } = styles;

  return (
    <form onSubmit={handleSubmit}>
      <span data-testid="total-hours-text" className={fieldLabelStyle}>
        <Lang path="totalHours" />
      </span>
      <Field
        component={OvalTextField}
        className={input}
        name="answer"
        type="number"
        data-testid="assessment-number-input"
        {...validateProps}
      />
      <ActionButton
        className={buttonStyle}
        action="submit"
        testId="assessment-number-submit"
      >
        <Lang path="submitButtonText" />
      </ActionButton>
    </form>
  );
};

NumberTypeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'assessmentNumberType'
})(NumberTypeForm);
