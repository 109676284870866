import _ from 'lodash';
import featureService from 'utils/feature-service';
import api from '../../../api/request';
import logger from '../../../utils/logger';
import analytics from '../../../utils/analytics';

import {
  GET_COACH_REQUEST_STATUS,
  GET_COACH_REQUEST_STATUS_DONE,
  GET_COACH_REQUEST_STATUS_ERROR,
  CLEAR_COACH_REQUEST_STATUS_ERROR,
  REQUEST_COACH,
  REQUEST_COACH_DONE,
  REQUEST_COACH_ERROR,
  AUTO_COACH_REQUEST,
  AUTO_COACH_REQUEST_DONE,
  AUTO_COACH_REQUEST_ERROR,
  AUTO_COACH_REQUEST_ELIGIBILITY
} from '../types';
import handleUnexpectedError from '../../helper';

import { closeModal } from '../ModalAction';
import { maximizeChatWindow } from '../ChatWindowAction';

// utils

const coachRequestStart = () => ({ type: REQUEST_COACH });

const coachRequestDone = () => ({
  type: REQUEST_COACH_DONE
});

const coachRequestError = () => ({ type: REQUEST_COACH_ERROR });

const getCoachRequestStatus = () => ({ type: GET_COACH_REQUEST_STATUS });

const getCoachRequestStatusDone = (status, details) => ({
  type: GET_COACH_REQUEST_STATUS_DONE,
  status,
  details
});

const getCoachRequestStatusError = () => ({
  type: GET_COACH_REQUEST_STATUS_ERROR
});

export const clearCoachRequestStatusError = () => ({
  type: CLEAR_COACH_REQUEST_STATUS_ERROR
});

export const getCoachStatus = () => (dispatch) => {
  dispatch(getCoachRequestStatus());
  return api
    .getCoachStatus()
    .then((resp) => {
      if (resp.status === 200) {
        if (resp.data.coachRequestStatus) {
          dispatch(
            getCoachRequestStatusDone(
              resp.data.coachRequestStatus,
              resp.data.coachDetails
            )
          );
        } else {
          logger.error(
            "Got 200 but didn't get coach request status",
            'CoachRequestAction.getCoachStatus',
            { resp }
          );
          dispatch(getCoachRequestStatusError());
        }
      } else {
        logger.error(
          'An error occurred while getting coach request status',
          'CoachRequestAction.getCoachStatus',
          { resp }
        );
        dispatch(getCoachRequestStatusError());
      }
    })
    .catch((err) => {
      handleUnexpectedError(
        'An unexpected error occurred while getting coach request status',
        'CoachRequestAction.getCoachStatus',
        err
      );
      dispatch(getCoachRequestStatusError());
    });
};

export default () => (dispatch) => {
  dispatch(coachRequestStart());
  api
    .requestCoach()
    .then((resp) => {
      if (resp.status === 200) {
        dispatch(coachRequestDone());
      } else {
        logger.error(
          'An error occurred while requesting the coach',
          'CoachAction.default',
          { resp }
        );
        dispatch(coachRequestError());
      }
    })
    .catch((err) => {
      handleUnexpectedError(
        'An unexpected error occurred while requesting the coach',
        'CoachAction.default',
        err
      );
      dispatch(coachRequestError());
    });
};

const autoCoachRequestStart = () => ({ type: AUTO_COACH_REQUEST });
const autoCoachRequestStartForEligibility = () => ({
  type: AUTO_COACH_REQUEST_ELIGIBILITY
});

const autoCoachRequestDone = (coachDetails) => ({
  type: AUTO_COACH_REQUEST_DONE,
  coachDetails
});

const autoCoachRequestError = () => ({ type: AUTO_COACH_REQUEST_ERROR });

export const startAutoCoachAssign = (params) => (dispatch) => {
  if (!_.isEmpty(params)) {
    dispatch(
      featureService._hasEligibilityAssessmentFeature()
        ? autoCoachRequestStartForEligibility()
        : autoCoachRequestStart()
    );
    api
      .assignCoach(params)
      .then((resp) => {
        if (resp.status === 200 && resp.data.coachDetails) {
          dispatch(autoCoachRequestDone(resp.data.coachDetails));
          analytics.info(
            'modal coach request recieved',
            'auto coach assignment successful'
          );
          if (featureService._hasCoachAccessOnWebFeature()) {
            dispatch(closeModal());
            if (window.LC_API && window.LC_API.hide_chat_window) {
              window.LC_API.hide_chat_window();
            }
            dispatch(maximizeChatWindow());
            analytics.info(
              'coach chat window',
              'successfully opened coach chat window'
            );
          }
          logger.info(
            'Auto coach assignment successful',
            'CoachRequestAction.startAutoCoachAssign'
          );
        } else {
          dispatch(autoCoachRequestError());
          logger.error(
            'An error occurred while auto coach assignment',
            'CoachRequestAction.startAutoCoachAssign',
            {
              params,
              resp
            }
          );
        }
      })
      .catch((error) => {
        dispatch(autoCoachRequestError());
        handleUnexpectedError(
          'An unexpected error occurred while auto coach assignment',
          'startAutoCoachAssign',
          {
            params,
            error
          }
        );
      });
  } else {
    logger.error(
      'An error occurred while auto coach assignment. Coach data is empty',
      'startAutoCoachAssign'
    );
  }
};
