/**
 * The registration form
 */

// Core
import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
// Components
import { Field, reduxForm } from 'redux-form';
import { isDirty, reset } from 'redux-form/immutable';
import { withTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Row, Col, Alert, Modal } from 'antd';
import { ArrowBack } from '@material-ui/icons';
// validation
import profileFieldList from 'assets/data/profileFieldList';
import fieldTypes from 'assets/data/fieldTypes';
// utils
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import history from 'utils/history';
import validation from 'utils/validation';
// config
import config from 'config';
import i18n from 'assets/lang';
import { FieldSet } from '../../../../components/ui/Layout';
import {
  TextFieldBlack,
  SelectFieldBlack
} from '../../../../components/ui/Inputs';
import toast from '../../../../components/Toast';
import RenderLink from '../../../../components/RenderLink';
import Lang from '../../../../components/Lang';
import { ActionButton } from '../../../../components/ui/Buttons';
// styles
import styles from './ProfileForm.module.scss';

const { appName, fallbackLang } = config;
const { confirm } = Modal;

const FieldCol = ({ colSize, colOffset, children }) => (
  <Col
    xs={{
      span: colSize || 24,
      offset: colOffset >= 0 ? colOffset : 0
    }}
  >
    {children}
  </Col>
);

const ProfileForm = (props) => {
  const {
    container,
    formField,
    registerButton,
    text,
    headingText,
    errorContainer,
    errorText,
    link,
    registerTermsAndPolicyContainer,
    PolicyContainerProfile,
    arrayFieldStyle,
    backButtonStyle,
    alertContainer
  } = styles;

  const {
    initialValues,
    handleSubmit,
    errorMessage,
    isLoading,
    readonlyProps,
    isDirty: isFormDirty
  } = props;
  const { locale } = initialValues;
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isFormDirty) {
      setIsFormUpdated(true);
    }
    const currentPath = history.location.pathname;
    const showConfirmationPopUp = history.block(({ pathname }) => {
      const isCurrentPath = currentPath !== pathname;
      if (
        isFormUpdated &&
        props.source !== 'auth' &&
        isCurrentPath &&
        pathname !== '/logout'
      ) {
        if (isLoading) {
          toast.info(<Lang path="profile.preventNavigationText" />, null, {
            autoClose: 4000
          });
        } else {
          confirm({
            title: (
              <span data-testid="confirm-modal-title">
                <Lang path="profile.confirmModalTitleText" />
              </span>
            ),
            okText: (
              <span data-testid="confirm-modal-ok-text">
                <Lang path="profile.confirmModalOkText" />
              </span>
            ),
            cancelText: (
              <span data-testid="confirm-modal-cancel-text">
                <Lang path="profile.confirmModalCancelText" />
              </span>
            ),
            async onOk() {
              setIsFormUpdated(false);
              window.onbeforeunload = null;
              await dispatch(reset('profile', initialValues, true));
              i18n.changeLanguage(locale || fallbackLang);
              history.push(pathname);
            }
          });
        }
        return false;
      }
      return true;
    });

    return () => {
      window.onbeforeunload = null;
      setIsFormUpdated(false);
      showConfirmationPopUp();
    };
  }, [
    isFormDirty,
    props.source,
    dispatch,
    initialValues,
    locale,
    isFormUpdated,
    isLoading
  ]);

  const _renderField = (item, style) => {
    const { colSize, colOffset } = item;
    const colProps = { colSize, colOffset };

    // Logic to make fields required or hide fields based on response from partner service
    const itemProps = _.cloneDeep(item.props);
    if (item.fieldType !== fieldTypes.array) {
      const fieldDetails = _.get(props, `profileFields[${item.name}]`, {});
      // special check to ignore password field
      if (fieldDetails.visible !== true && item.name !== 'password') {
        return null;
      }
      if (fieldDetails.required === true) {
        itemProps.label = <span>{itemProps.label}*</span>;
        itemProps.validate.push(validation[`required_${item.name}`]);
      }
    }
    const formFieldClassName = [formField];
    if (style) {
      formFieldClassName.push(style);
    }
    switch (item.fieldType) {
      case fieldTypes.text: {
        return (
          <FieldCol {...colProps}>
            <Field
              className={formFieldClassName.join(' ')}
              component={TextFieldBlack}
              disabled={readonlyProps[item.name]}
              {...itemProps}
            />
          </FieldCol>
        );
      }
      case fieldTypes.select: {
        return (
          <FieldCol {...colProps}>
            <Field
              className={formFieldClassName.join(' ')}
              component={SelectFieldBlack}
              disabled={readonlyProps[item.name]}
              {...itemProps}
            />
          </FieldCol>
        );
      }
      case fieldTypes.array:
        return (
          <FieldCol>
            <FieldSet label={item.label}>
              {item.fields.map((fieldItem) => (
                <Fragment key={fieldItem.name}>
                  {_renderField(fieldItem, arrayFieldStyle)}
                </Fragment>
              ))}
            </FieldSet>
          </FieldCol>
        );
      default:
        return null;
    }
  };

  const _renderFormFields = () =>
    profileFieldList().map((item) => (
      <Fragment key={item.name}>{_renderField(item)}</Fragment>
    ));

  const _addAnalyticsEvent = (source) => {
    analytics.track('profile', `clicked ${source}`);
  };

  const backButtonHandler = () => {
    analytics.track(eventCategories.PROFILE, 'clicked profile back button');
    history.goBack();
  };

  return (
    <form className={container} onSubmit={handleSubmit}>
      {props.source !== 'auth' && (
        <button
          className={backButtonStyle}
          onClick={() => backButtonHandler()}
          type="button"
        >
          <ArrowBack />
        </button>
      )}
      <Row>
        <Col>
          {props.source === 'auth' && (
            <h3 className={[text, headingText].join(' ')}>
              <Lang path="profileHeader" />
              <br /> <Lang path="profileHeader2" />
            </h3>
          )}
        </Col>
      </Row>
      <Row>{_renderFormFields()}</Row>
      <Row className={alertContainer}>
        <div className={errorContainer}>
          {errorMessage && (
            <Alert
              className={errorText}
              message={errorMessage}
              type="error"
              showIcon
            />
          )}
        </div>
      </Row>
      <Row>
        <Col
          className={styles.alignCenter}
          xs={24}
          lg={{
            span: 24
          }}
        >
          <ActionButton
            onClick={handleSubmit}
            isLoading={isLoading}
            className={registerButton}
            testId="profile-form-register-button"
            action="submit"
          >
            {props.source === 'auth' ? (
              <Lang path="profileCompleteText" />
            ) : (
              <Lang path="profileUpdateText" />
            )}
          </ActionButton>
        </Col>
      </Row>
      {props.source === 'auth' && (
        <Row className={PolicyContainerProfile}>
          <Col
            xs={24}
            lg={{
              span: 24
            }}
          >
            <div>
              <div className={registerTermsAndPolicyContainer}>
                <span className={text}>
                  <Lang
                    path="registerTerms"
                    values={{ appName }}
                    components={{
                      terms: (
                        <RenderLink
                          className={link}
                          link="/terms"
                          onClick={() => _addAnalyticsEvent('terms')}
                          linkText={<Lang path="termsText" />}
                        />
                      ),
                      privacy: (
                        <RenderLink
                          className={link}
                          link="/privacy"
                          onClick={() => _addAnalyticsEvent('privacy')}
                          linkText={<Lang path="privacyText" />}
                        />
                      )
                    }}
                  />
                </span>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </form>
  );
};
const mapStateToProps = (state) => ({
  isDirty: isDirty('profile')(state)
});
export default reduxForm({
  form: 'profile'
})(connect(mapStateToProps)(withTranslation()(ProfileForm)));
