import React from 'react';
import { IonToolbar, IonBackButton, IonButtons } from '@ionic/react';
import config from 'config';
import i18n from 'assets/lang';
import classNamesInstance from 'classnames/bind';
import { isLoggedIn } from 'utils/auth';
import featureService from 'utils/feature-service';
import poweredByLogo from 'assets/img/powered-by-logo-invert.svg';
import mdArrowBack from 'assets/icons/navigation/md-arrow-back.svg';
import iosArrowBack from 'assets/icons/navigation/ios-arrow-back.svg';
import emergencyCallIcon from 'assets/icons/emergency-call.svg';
import helpers from 'utils/helpers';
import styles from './Toolbar.module.scss';
import {
  getCompanyLogoUrl,
  logoClickHandler,
  emergencyClickHandler,
  backButtonHandler
} from './Toolbar.utils';
import history from '../../utils/history';

const { appName } = config;

const {
  container,
  emergencyButtonStyle,
  toolBarStyle,
  poweredByLogoStyle,
  poweredByLogoContainer,
  toolbarContainer
} = styles;

const classNames = classNamesInstance.bind(styles);

const Toolbar = ({ isFieldMissing }) => {
  const isAuth = isLoggedIn();
  const currentPath = helpers.getActivePath();
  const homeUrl = helpers.homeUrl();
  const showPoweredByLogo = () => {
    if (
      config.showPoweredByLogo === 'true' &&
      !helpers.platformInfo.isDesktop &&
      currentPath === '/'
    ) {
      return true;
    }
    return false;
  };
  const showBackButton = !isFieldMissing && helpers.canShowBackButton();
  const { isIos } = helpers.platformInfo;
  return (
    currentPath !== '/callback' && (
      <IonToolbar className={toolBarStyle}>
        <div slot="start" className={toolbarContainer}>
          {showBackButton && (
            <div
              className={classNames({
                backButtonStyle: true,
                backButtonStyleIos: isIos
              })}
            >
              <IonButtons>
                <IonBackButton
                  disabled={history.length === 1}
                  defaultHref={homeUrl}
                  onClick={() => backButtonHandler()}
                  text=""
                  icon={isIos ? iosArrowBack : mdArrowBack}
                  color="dark"
                />
              </IonButtons>
            </div>
          )}
          <div
            className={classNames({
              container: isAuth,
              missingAuthContainer: !isAuth,
              landingPageContentStyle: !isAuth && currentPath === '/'
            })}
          >
            <span
              tabIndex={0}
              role="button"
              onKeyDown={() => null}
              onClick={() => logoClickHandler(isFieldMissing)}
              className={classNames({
                logoStyle: true,
                logoStyleNestedScreen: !showBackButton
              })}
            >
              <img
                src={getCompanyLogoUrl()}
                alt={i18n.t('alternateText.image.logo', { appName })}
              />
            </span>
            {showPoweredByLogo() && (
              <div className={poweredByLogoContainer}>
                <img
                  className={poweredByLogoStyle}
                  src={poweredByLogo}
                  alt={i18n.t('alternateText.image.poweredBy', {
                    appName: config.appName
                  })}
                />
              </div>
            )}
          </div>
          {isAuth && featureService._isProductActive() && (
            <div className={container} slot="end">
              <span
                tabIndex={-1}
                role="button"
                onKeyDown={() => null}
                className={emergencyButtonStyle}
                onClick={() => emergencyClickHandler()}
              >
                <img
                  alt={i18n.t('alternateText.icon.emergencyCall')}
                  src={emergencyCallIcon}
                />
              </span>
            </div>
          )}
        </div>
      </IonToolbar>
    )
  );
};

export default Toolbar;
