// core
import React from 'react';
import Icon from '@material-ui/core/Icon';
// styles
// config
import config from 'config';
// Components
import Lang from '../../../../components/Lang';
import styles from './Error.module.scss';

const { supportEmail } = config;

const {
  container,
  chatListErrorText,
  errorTextCoachCard,
  chatListRetry,
  retryButton,
  containerCoachError,
  containerPaginationError,
  errorText,
  coachInfo,
  warningIcon
} = styles;

export const ChatListError = ({ retry }) => (
  <div className={container}>
    <h2 className={chatListErrorText}>
      <Lang path="chatListLoadingErrorText" values={{ supportEmail }} />
    </h2>
    <button
      className={chatListRetry}
      onClick={() => {
        retry();
      }}
      type="button"
    >
      <span>
        <Lang path="chatRetryButtonText" />
      </span>
    </button>
  </div>
);

export const CoachCardError = () => (
  <div className={containerCoachError}>
    <div className={coachInfo}>
      <span className={errorTextCoachCard}>
        <Lang path="coachLabel" />
      </span>
    </div>
  </div>
);

export const PaginationError = ({ retry }) => (
  <div className={containerPaginationError}>
    <Icon className={warningIcon}>warning</Icon>
    <span className={errorText}>
      <Lang path="chatPaginationErrorText" />
      &nbsp;
      <button type="button" className={retryButton} onClick={() => retry()}>
        <Lang path="chatRetryButtonText" />
      </button>
    </span>
  </div>
);

export const SubscriptionError = ({ retry }) => (
  <div className={containerPaginationError}>
    <Icon className={warningIcon}>warning</Icon>
    <span className={errorText}>
      <span data-testid="subscription-error-message-text">
        <Lang path="chatSubscriptionErrorText" />
      </span>
      &nbsp;
      <button
        data-testid="subscription-error-retry-button"
        type="button"
        className={retryButton}
        onClick={() => retry()}
      >
        <Lang path="chatRetryButtonText" />
      </button>
    </span>
  </div>
);
