import React from 'react';
import ServiceCard from 'components/ServiceCard';
import Lang from 'components/Lang';
import styles from './Therapy.module.scss';
import services from './Therapy.data';
import { onClickHandler } from './Therapy.utils';

const {
  headerContainer,
  cardContainer,
  footerButtonStyle,
  iconStyle,
  buttonContentStyle,
  buttonTextStyle
} = styles;

const renderFooter = (content) => (
  <content.buttonComponent
    key={content.id}
    data-testid={content.id}
    className={footerButtonStyle}
    onClick={() =>
      onClickHandler({
        url: content.url,
        service: content.name
      })
    }
  >
    <div className={buttonContentStyle}>
      {content.hasIcon && (
        <content.icon fontSize="small" className={iconStyle} />
      )}
      <p className={buttonTextStyle}>{content.text}</p>
    </div>
  </content.buttonComponent>
);

const Therapy = () => (
  <>
    <div data-testid="therapy-popup" className={headerContainer}>
      <h2 data-testid="therapy-popup-header">
        <Lang path="therapyRequest.header" />
      </h2>
    </div>
    <div className={cardContainer} data-testid="therapy-container">
      {services &&
        services.map((service) => {
          if (service.isVisible()) {
            return (
              <div className={cardContainer} key={service.id}>
                <ServiceCard
                  id={service.id}
                  img={service.img}
                  header={service.title}
                  desc={service.description}
                  footerContent={service.footerContent.map((content) =>
                    renderFooter({ ...content })
                  )}
                />
              </div>
            );
          }
          return null;
        })}
    </div>
  </>
);
export default Therapy;
