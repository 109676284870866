/**
 * The Company select form
 */

// Core
import React, { useCallback, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { parse } from 'tiny-querystring';
import { useDispatch, useSelector } from 'react-redux';
import { get, debounce } from 'lodash';
import { Field, reduxForm } from 'redux-form';
// Components
import logger from 'utils/logger';
import validation from 'utils/validation';
import config from 'config';
import getCompanyList from 'store/actions/CompanyListAction';
import AutoCompleteField from '../../../../components/form/AutoCompleteField';
import ErrorRetry from '../../../../components/ErrorRetry';
import { ActionButton } from '../../../../components/ui/Buttons';
import PrivacyNotice from '../../../../components/sections';
import Lang from '../../../../components/Lang';
// utils
import { handleFilter } from './CompanySelectForm.utils';
import analytics from '../../../../utils/analytics';
import eventCategories from '../../../../utils/analytics/categories';
// styles
import styles from './CompanySelectForm.module.scss';

const { showPrivacyInfo } = config;

const {
  container,
  companySelectButton,
  formField,
  headingText,
  disclaimerStyle
} = styles;

const CompanySelectForm = ({
  companyList,
  handleSubmit,
  isLoading,
  companyListLoading,
  companyListError
}) => {
  const [dataSource, setDataSource] = useState([]);
  const dispatch = useDispatch();

  const companyValue = useSelector((state) =>
    get(state, 'form.companySelect.values.access_code')
  );

  const sendAnalyticsEventOnAccessCodeError = useCallback(
    debounce(() => {
      if (window.location.search) {
        const nextAction = parse(window.location.search.slice(1)).next;
        const source = nextAction === 'register' ? 'signup' : nextAction;
        analytics.track(
          eventCategories.COMPANY_SELECT,
          'error occurred with the access code',
          { trigger: 'access_code_error', source }
        );
      }
    }, 2000),
    []
  );
  const accessCodeValidator = useCallback(
    (value) => {
      const isInvalidAccessCode = validation.access_code(companyList)(value);
      if (isInvalidAccessCode) {
        sendAnalyticsEventOnAccessCodeError();
      }
      return isInvalidAccessCode;
    },
    [companyList, sendAnalyticsEventOnAccessCodeError]
  );

  useEffect(() => {
    setDataSource(!companyValue ? [] : companyList.map((item) => item.alias));
  }, [companyList, companyValue]);

  const retryHandler = () => {
    logger.info(
      'Clicked try again to fetch companyList',
      'CompanySelectForm.retryHandler',
      {
        companyListError,
        companyListLoading
      }
    );
    dispatch(getCompanyList());
  };
  const renderForm = useCallback(
    () => (
      <form onSubmit={handleSubmit}>
        <h3 data-testid="access-code-header-text" className={headingText}>
          <Lang path="companySelectHeader" />
        </h3>
        <Field
          isLoading={!!companyValue && companyListLoading}
          dataSource={dataSource}
          className={formField}
          name="access_code"
          component={AutoCompleteField}
          type="text"
          data-testid="access_code"
          label={<Lang path="companySelectInput" />}
          filterOption={handleFilter}
          autoComplete="off"
          validate={[validation.required_access_code, accessCodeValidator]}
        />
        <div>
          <p className={disclaimerStyle}>
            <Lang path="companySelectDisclaimerText" />
          </p>
        </div>

        {showPrivacyInfo === 'true' && (
          <PrivacyNotice className={styles.privacyNotice} />
        )}

        <div className={styles.buttonOuter}>
          <ActionButton
            isLoading={isLoading}
            className={companySelectButton}
            onClick={handleSubmit}
            type="primary"
            testId="company_select_btn"
          >
            <Lang path="continueButtonText" />
          </ActionButton>
        </div>
      </form>
    ),
    [
      accessCodeValidator,
      companyListLoading,
      companyValue,
      dataSource,
      handleSubmit,
      isLoading
    ]
  );

  return (
    <div className={container}>
      {companyListError ? (
        <ErrorRetry
          buttonId="access-code-retry-button"
          titleId="access-code-error-message"
          onClick={retryHandler}
          isFetching={companyListLoading}
          title={<Lang path="companySelectionPage.error" />}
        />
      ) : (
        renderForm()
      )}
    </div>
  );
};

CompanySelectForm.propTypes = {
  handleSubmit: propTypes.func.isRequired,
  companyList: propTypes.arrayOf(propTypes.shape({ alias: propTypes.string }))
    .isRequired,
  isLoading: propTypes.bool.isRequired,
  companyListLoading: propTypes.bool.isRequired,
  companyListError: propTypes.bool.isRequired
};

export default reduxForm({
  form: 'companySelect'
})(CompanySelectForm);
