/**
 * Input wrapped with our stying
 */

import React from 'react';
import { OutlinedInput, FormControl, InputLabel } from '@material-ui/core';
import { InputError, InputSuccess } from '../../Validation';

import { inputStyles, labelStyles } from './TextAreaBlack.styles';

const TextAreaBlack = ({
  className,
  input,
  label,
  validateIcon,
  validateOnFocus,
  meta: { error, touched, active, success },
  multiline,
  rows,
  ...rest
}) => {
  let showError = false;

  if (touched && error) {
    showError = true;
  }

  if (validateOnFocus && error && active) {
    showError = true;
  }

  const inputClasses = inputStyles();
  const labelClasses = labelStyles();
  return (
    <FormControl className={className || null}>
      <InputLabel
        classes={{ root: labelClasses.root, focused: labelClasses.focused }}
        variant="outlined"
      >
        {label}
      </InputLabel>
      <OutlinedInput
        classes={{
          root: inputClasses.root,
          notchedOutline: inputClasses.notchedOutline,
          focused: inputClasses.focused,
          inputMultiline: inputClasses.inputMultiline
        }}
        label={label}
        inputProps={rest}
        {...input}
        multiline={multiline}
        rows={rows}
      />
      {showError && <InputError error={error} />}
      {success && <InputSuccess message={success} />}
    </FormControl>
  );
};

export default TextAreaBlack;
