/**
 * Reducer to manage the state of currently active avatar
 */
// eslint-disable-next-line import/no-cycle
import i18n from '../../../assets/lang';
import {
  SET_DASHBOARD_DATA,
  SET_DASHBOARD_SESSION_COUNT,
  SET_DASHBOARD_CALL_COMPLETED
} from '../../actions/types';

const INITIAL_STATE = {
  goalId: null, // goalId for the current goal selected in the ibhp dashboard
  severity: null, // severity for the current goal selected in the ibhp dashboard
  isCallCompleted: null, // flag for completion of api call
  reportId: null, // reportId for the last conversation report
  sessionCount: {
    // sessions count for the IBHP
    text: {
      low: i18n.t('unlimitedSessions'),
      moderate: i18n.t('unlimitedSessions'),
      high: i18n.t('unlimitedSessions')
    },
    self: {
      low: i18n.t('unlimitedSessions'),
      moderate: i18n.t('unlimitedSessions'),
      high: i18n.t('unlimitedSessions')
    },
    video: { low: 0, moderate: 4, high: 6 },
    inperson: { low: 6, moderate: 6, high: 6 }
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DASHBOARD_DATA:
      return {
        ...state,
        goalId: action.goalId,
        severity: action.severity,
        isCallCompleted: true,
        reportId: action.reportId
      };
    case SET_DASHBOARD_SESSION_COUNT:
      return {
        ...state,
        sessionCount: action.sessionCountData
      };
    case SET_DASHBOARD_CALL_COMPLETED:
      return {
        ...state,
        isCallCompleted: true
      };
    default:
      return state;
  }
};
