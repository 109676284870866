import React from 'react';
import { Col, Row } from 'antd';
import uuid from 'uuid/v4';
import propTypes from 'prop-types';
// components
import CustomInput from 'components/CustomInput';
import Checklist from 'components/Checklist';
// styles
import styles from './Option.module.scss';

const { stepsChecklistContainer } = styles;

const Option = ({
  columns,
  options,
  currentProgress,
  type,
  onSelectHandler,
  allowCustom,
  customOptionPlaceholder,
  onAddHandler,
  onSaveCustomOptionHandler,
  validateCustomInput,
  onCancelHandler
}) => (
  <div
    className={stepsChecklistContainer}
    data-testid="steps-checklist-container"
  >
    <Row>
      {options.map((option, index) => (
        <Col key={option.optionId} md={columns} lg={columns} xs={24}>
          <Checklist
            index={index}
            id={option.optionId}
            description={option.optionDescription}
            text={option.text}
            isSelected={
              !!(
                currentProgress &&
                currentProgress.find(
                  (selectedOptions) =>
                    selectedOptions.optionId === option.optionId &&
                    selectedOptions.isSelected === true
                )
              )
            }
            onSelect={({ isSelected, optionId, text }) =>
              onSelectHandler({ isSelected, optionId, text })
            }
          />
        </Col>
      ))}
      <Col md={columns} lg={columns} xs={24}>
        {allowCustom && (
          <CustomInput
            testId={`${type}-step-custom-input`}
            customValidator={(text) => validateCustomInput({ text })}
            customOptionPlaceholder={customOptionPlaceholder}
            onClickAdd={() => onAddHandler()}
            onClickSave={(text) =>
              onSaveCustomOptionHandler({ option: { optionId: uuid(), text } })
            }
            onClickCancel={() => onCancelHandler()}
          />
        )}
      </Col>
    </Row>
  </div>
);

Option.propTypes = {
  columns: propTypes.number,
  options: propTypes.arrayOf(propTypes.shape({})),
  currentProgress: propTypes.arrayOf(propTypes.shape({})),
  type: propTypes.string,
  onSelectHandler: propTypes.func,
  allowCustom: propTypes.bool,
  customOptionPlaceholder: propTypes.string,
  onAddHandler: propTypes.func,
  onSaveCustomOptionHandler: propTypes.func,
  validateCustomInput: propTypes.func,
  onCancelHandler: propTypes.func
};

Option.defaultProps = {
  columns: 24,
  options: [],
  currentProgress: [],
  type: '',
  allowCustom: false,
  customOptionPlaceholder: '',
  onSelectHandler: () => null,
  onAddHandler: () => null,
  onSaveCustomOptionHandler: () => null,
  validateCustomInput: () => null,
  onCancelHandler: () => null
};

export default Option;
