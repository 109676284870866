import React from 'react';
import { useTranslation } from 'react-i18next';
// Styles
// assets
import readingIcon from 'assets/icons/session/prefer-reading.svg';
// components
import Lang from '../../../../../../components/Lang';
import styles from './VideoScreenOverlay.module.scss';

const {
  container,
  contextStyle,
  headingStyle,
  durationStyle,
  subheadingStyle,
  toggleButtonStyle,
  toggleButtonTextStyle,
  toggleToTranscriptButtonStyle
} = styles;

const VideoScreenOverlay = ({
  subheader,
  duration,
  description,
  hasTranscript,
  handleToggleButtonClick
}) => {
  const { t } = useTranslation();
  return (
    <div className={container}>
      <div className={contextStyle}>
        <h3 data-testid="video-subheader" className={headingStyle}>
          {subheader}
        </h3>
        <p data-testid="video-duration" className={durationStyle}>
          {`Duration - ${duration}`}
        </p>
        <hr />
        <p data-testid="video-description" className={subheadingStyle}>
          {description}
        </p>
      </div>
      {hasTranscript && (
        <div className={toggleButtonStyle}>
          <button
            type="button"
            className={toggleToTranscriptButtonStyle}
            onClick={() => handleToggleButtonClick()}
            data-testid="toggle-to-transcript-button"
          >
            <img
              src={readingIcon}
              alt={t('alternateText.icon.readTranscript')}
            />
            <span className={toggleButtonTextStyle}>
              <Lang path="selfUse.session.toggleToTranscriptButtonText" />
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoScreenOverlay;
