import React from 'react';
import Lang from '../../../components/Lang';
import i18n from '../../lang';

const getGoalActionPlans = () => ({
  sleep: {
    categoryId: 'sleep',
    categoryName: i18n.t('goal.category.sleep.name'),
    actionPlans: [
      {
        title: <Lang path="goal.actionPlans.selfGuided.title" />,
        description: <Lang path="goal.actionPlans.selfGuided.description" />,
        componentType: 'self',
        bigCardText: <Lang path="goal.category.sleep.desc" />
      },
      {
        title: i18n.t('goal.actionPlans.textCoaching.title', {
          returnObjects: true
        }),
        description: <Lang path="goal.actionPlans.textCoaching.description" />,
        componentType: 'text',
        bigCardText: i18n.t('goal.actionPlans.textCoaching.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: i18n.t('goal.actionPlans.therapy.title', {
          returnObjects: true
        }),
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'video',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'inperson',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      }
    ]
  },
  anger: {
    categoryId: 'anger',
    categoryName: i18n.t('goal.category.anger.name'),
    actionPlans: [
      {
        title: <Lang path="goal.actionPlans.selfGuided.title" />,
        description: <Lang path="goal.actionPlans.selfGuided.description" />,
        componentType: 'self',
        bigCardText: <Lang path="goal.category.anger.desc" />
      },
      {
        title: <Lang path="goal.actionPlans.textCoaching.title" />,
        description: <Lang path="goal.actionPlans.textCoaching.description" />,
        componentType: 'text',
        bigCardText: i18n.t('goal.actionPlans.textCoaching.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'video',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'inperson',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      }
    ]
  },
  worklifebalance: {
    categoryId: 'workLifeBalance',
    categoryName: i18n.t('goal.category.worklifebalance.name'),
    actionPlans: [
      {
        title: <Lang path="goal.actionPlans.selfGuided.title" />,
        description: <Lang path="goal.actionPlans.selfGuided.description" />,
        componentType: 'self',
        bigCardText: <Lang path="goal.category.worklifebalance.desc" />
      },
      {
        title: <Lang path="goal.actionPlans.textCoaching.title" />,
        description: <Lang path="goal.actionPlans.textCoaching.description" />,
        componentType: 'text',
        bigCardText: i18n.t('goal.actionPlans.textCoaching.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'video',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'inperson',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      }
    ]
  },
  stress: {
    categoryId: 'stress',
    categoryName: i18n.t('goal.category.stress.name'),
    actionPlans: [
      {
        title: <Lang path="goal.actionPlans.selfGuided.title" />,
        description: <Lang path="goal.actionPlans.selfGuided.description" />,
        componentType: 'self',
        bigCardText: <Lang path="goal.category.stress.desc" />
      },
      {
        title: <Lang path="goal.actionPlans.textCoaching.title" />,
        description: <Lang path="goal.actionPlans.textCoaching.description" />,
        componentType: 'text',
        bigCardText: i18n.t('goal.actionPlans.textCoaching.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'video',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'inperson',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      }
    ]
  },
  socialanxiety: {
    categoryId: 'socialAnxiety',
    categoryName: i18n.t('goal.category.socialanxiety.name'),
    actionPlans: [
      {
        title: <Lang path="goal.actionPlans.selfGuided.title" />,
        description: <Lang path="goal.actionPlans.selfGuided.description" />,
        componentType: 'self',
        bigCardText: <Lang path="goal.category.socialanxiety.desc" />
      },
      {
        title: <Lang path="goal.actionPlans.textCoaching.title" />,
        description: <Lang path="goal.actionPlans.textCoaching.description" />,
        componentType: 'text',
        bigCardText: i18n.t('goal.actionPlans.textCoaching.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'video',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'inperson',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      }
    ]
  },
  relationshipconflict: {
    categoryId: 'relationshipConflict',
    categoryName: i18n.t('goal.category.relationshipconflict.name'),
    actionPlans: [
      {
        title: <Lang path="goal.actionPlans.selfGuided.title" />,
        description: <Lang path="goal.actionPlans.selfGuided.description" />,
        componentType: 'self',
        bigCardText: <Lang path="goal.category.relationshipconflict.desc" />
      },
      {
        title: <Lang path="goal.actionPlans.textCoaching.title" />,
        description: <Lang path="goal.actionPlans.textCoaching.description" />,
        componentType: 'text',
        bigCardText: i18n.t('goal.actionPlans.textCoaching.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'video',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'inperson',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      }
    ]
  },
  productivity: {
    categoryId: 'productivity',
    categoryName: i18n.t('goal.category.productivity.name'),
    actionPlans: [
      {
        title: <Lang path="goal.actionPlans.selfGuided.title" />,
        description: <Lang path="goal.actionPlans.selfGuided.description" />,
        componentType: 'self',
        bigCardText: <Lang path="goal.category.productivity.desc" />
      },
      {
        title: <Lang path="goal.actionPlans.textCoaching.title" />,
        description: <Lang path="goal.actionPlans.textCoaching.description" />,
        componentType: 'text',
        bigCardText: i18n.t('goal.actionPlans.textCoaching.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'video',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'inperson',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      }
    ]
  },
  presentationanxiety: {
    categoryId: 'presentationAnxiety',
    categoryName: i18n.t('goal.category.presentationanxiety.name'),
    actionPlans: [
      {
        title: <Lang path="goal.actionPlans.selfGuided.title" />,
        description: <Lang path="goal.actionPlans.selfGuided.description" />,
        componentType: 'self',
        bigCardText: <Lang path="goal.category.presentationanxiety.desc" />
      },
      {
        title: <Lang path="goal.actionPlans.textCoaching.title" />,
        description: <Lang path="goal.actionPlans.textCoaching.description" />,
        componentType: 'text',
        bigCardText: i18n.t('goal.actionPlans.textCoaching.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'video',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'inperson',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      }
    ]
  },
  marriage: {
    categoryId: 'marriage',
    categoryName: i18n.t('goal.category.marriage.name'),
    actionPlans: [
      {
        title: <Lang path="goal.actionPlans.selfGuided.title" />,
        description: <Lang path="goal.actionPlans.selfGuided.description" />,
        componentType: 'self',
        bigCardText: <Lang path="goal.category.marriage.desc" />
      },
      {
        title: <Lang path="goal.actionPlans.textCoaching.title" />,
        description: <Lang path="goal.actionPlans.textCoaching.description" />,
        componentType: 'text',
        bigCardText: i18n.t('goal.actionPlans.textCoaching.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'video',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'inperson',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      }
    ]
  },
  interviewanxiety: {
    categoryId: 'interviewAnxiety',
    categoryName: i18n.t('goal.category.interviewanxiety.name'),
    actionPlans: [
      {
        title: <Lang path="goal.actionPlans.selfGuided.title" />,
        description: <Lang path="goal.actionPlans.selfGuided.description" />,
        componentType: 'self',
        bigCardText: <Lang path="goal.category.interviewanxiety.desc" />
      },
      {
        title: <Lang path="goal.actionPlans.textCoaching.title" />,
        description: <Lang path="goal.actionPlans.textCoaching.description" />,
        componentType: 'text',
        bigCardText: i18n.t('goal.actionPlans.textCoaching.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'video',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'inperson',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      }
    ]
  },
  generalanxiety: {
    categoryId: 'generalAnxiety',
    categoryName: i18n.t('goal.category.generalanxiety.name'),
    actionPlans: [
      {
        title: <Lang path="goal.actionPlans.selfGuided.title" />,
        description: <Lang path="goal.actionPlans.selfGuided.description" />,
        componentType: 'self',
        bigCardText: <Lang path="goal.category.generalanxiety.desc" />
      },
      {
        title: <Lang path="goal.actionPlans.textCoaching.title" />,
        description: <Lang path="goal.actionPlans.textCoaching.description" />,
        componentType: 'text',
        bigCardText: i18n.t('goal.actionPlans.textCoaching.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'video',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'inperson',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      }
    ]
  },
  depression: {
    categoryId: 'depression',
    categoryName: i18n.t('goal.category.depression.name'),
    actionPlans: [
      {
        title: <Lang path="goal.actionPlans.selfGuided.title" />,
        description: <Lang path="goal.actionPlans.selfGuided.description" />,
        componentType: 'self',
        bigCardText: <Lang path="goal.category.depression.desc" />
      },
      {
        title: <Lang path="goal.actionPlans.textCoaching.title" />,
        description: <Lang path="goal.actionPlans.textCoaching.description" />,
        componentType: 'text',
        bigCardText: i18n.t('goal.actionPlans.textCoaching.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'video',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'inperson',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      }
    ]
  },
  datinganxiety: {
    categoryId: 'datingAnxiety',
    categoryName: i18n.t('goal.category.datinganxiety.name'),
    actionPlans: [
      {
        title: <Lang path="goal.actionPlans.selfGuided.title" />,
        description: <Lang path="goal.actionPlans.selfGuided.description" />,
        componentType: 'self',
        bigCardText: <Lang path="goal.category.datinganxiety.desc" />
      },
      {
        title: <Lang path="goal.actionPlans.textCoaching.title" />,
        description: <Lang path="goal.actionPlans.textCoaching.description" />,
        componentType: 'text',
        bigCardText: i18n.t('goal.actionPlans.textCoaching.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'video',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'inperson',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      }
    ]
  },
  conflictatwork: {
    categoryId: 'conflictAtWork',
    categoryName: i18n.t('goal.category.conflictatwork.name'),
    actionPlans: [
      {
        title: <Lang path="goal.actionPlans.selfGuided.title" />,
        description: <Lang path="goal.actionPlans.selfGuided.description" />,
        componentType: 'self',
        bigCardText: <Lang path="goal.category.conflictatwork.desc" />
      },
      {
        title: <Lang path="goal.actionPlans.textCoaching.title" />,
        description: <Lang path="goal.actionPlans.textCoaching.description" />,
        componentType: 'text',
        bigCardText: i18n.t('goal.actionPlans.textCoaching.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'video',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'inperson',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      }
    ]
  },
  substanceuseandabuse: {
    categoryId: 'substanceUseAndAbuse',
    categoryName: i18n.t('goal.category.substanceuseandabuse.name'),
    actionPlans: [
      {
        title: <Lang path="goal.actionPlans.selfGuided.title" />,
        description: <Lang path="goal.actionPlans.selfGuided.description" />,
        componentType: 'self',
        bigCardText: <Lang path="goal.category.substanceuseandabuse.desc" />
      },
      {
        title: <Lang path="goal.actionPlans.textCoaching.title" />,
        description: <Lang path="goal.actionPlans.textCoaching.description" />,
        componentType: 'text',
        bigCardText: i18n.t('goal.actionPlans.textCoaching.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'video',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'inperson',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      }
    ]
  },
  covid19: {
    categoryId: 'covid19',
    categoryName: i18n.t('goal.category.covid19.name'),
    actionPlans: [
      {
        title: <Lang path="goal.actionPlans.selfGuided.title" />,
        description: <Lang path="goal.actionPlans.selfGuided.description" />,
        componentType: 'self',
        bigCardText: <Lang path="goal.category.covid19.desc" />
      },
      {
        title: <Lang path="goal.actionPlans.textCoaching.title" />,
        description: <Lang path="goal.actionPlans.textCoaching.description" />,
        componentType: 'text',
        bigCardText: i18n.t('goal.actionPlans.textCoaching.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'video',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'inperson',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      }
    ]
  },
  highstresswork: {
    categoryId: 'highStressWork',
    categoryName: i18n.t('goal.category.highstresswork.name'),
    actionPlans: [
      {
        title: <Lang path="goal.actionPlans.selfGuided.title" />,
        description: <Lang path="goal.actionPlans.selfGuided.description" />,
        componentType: 'self',
        bigCardText: <Lang path="goal.category.highstresswork.desc" />
      },
      {
        title: <Lang path="goal.actionPlans.textCoaching.title" />,
        description: <Lang path="goal.actionPlans.textCoaching.description" />,
        componentType: 'text',
        bigCardText: i18n.t('goal.actionPlans.textCoaching.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'video',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      },
      {
        title: <Lang path="goal.actionPlans.therapy.title" />,
        description: <Lang path="goal.actionPlans.therapy.description" />,
        componentType: 'inperson',
        bigCardText: i18n.t('goal.actionPlans.therapy.bigCardText', {
          returnObjects: true
        })
      }
    ]
  }
});

export default getGoalActionPlans;
