/**
 * hoc that redirects the user to login page in case they are not logged in
 */

// core
import React, { useCallback, useEffect } from 'react';
// components
import featureService from 'utils/feature-service';
import * as helpers from 'utils/auth/helpers';
import ContentContainer from '../../components/layout/ContentContainer';
import { ActionButton } from '../../components/ui/Buttons';
import Error from '../../components/Error';
import Lang from '../../components/Lang';
// utils
import auth from '../../utils/auth';
import logger from '../../utils/logger/logger';
// config
import config from '../../config';

const { supportEmail } = config;

const footerContent = [
  <ActionButton
    testId="logout-button"
    onClick={() => {
      logger.info('Click on Logout Button', 'withAuth._renderComponent');
      return auth.logout();
    }}
  >
    <Lang path="product.buttonText" />
  </ActionButton>
];
const withAuth = (WrappedComponent) => (props) => {
  useEffect(() => {
    if (!helpers.isLoggedIn()) {
      logger.info('User not logged in', 'withAuth.componentWillMount');
      auth.logout('noLogin', window.location.pathname);
    }
  }, []);

  const _renderComponent = useCallback(() => {
    if (featureService._isProductActive()) {
      return <WrappedComponent {...props} />;
    }
    logger.info('User product has expired', 'withAuth._renderComponent');
    return (
      <ContentContainer size="extraLarge">
        <Error
          message={<Lang path="product.expiredMessage" />}
          footer={footerContent}
          content={
            <React.Fragment>
              <Lang path="product.expiredDesc" values={{ supportEmail }} />
            </React.Fragment>
          }
          messageTestId="user-product-expire"
        />
      </ContentContainer>
    );
  }, [props]);

  return helpers.isLoggedIn() ? _renderComponent() : null;
};

export default withAuth;
