import React, { useState, useCallback } from 'react';
import { has, get } from 'lodash';
import { useKeenSlider } from 'keen-slider/react';
import helpers from 'utils/helpers';
import classNamesInstance from 'classnames/bind';
import sliderConfig from './sliderConfig';
import NextArrow from './NextArrow';
import PrevArrow from './PrevArrow';
import {
  nextArrowClick,
  backArrowClick,
  getNumberOfCard,
  moveToCard
} from './Slider.utils';

// styles
import styles from './Slider.module.scss';

const { navigationWrapper, dots } = styles;
const classNames = classNamesInstance.bind(styles);

export default ({
  loop = false,
  children,
  start = 0,
  dotsVisible = false,
  nextArrowId,
  prevArrowId,
  testId
}) => {
  const { isMobile } = helpers.platformInfo;
  const [currentSlide, setCurrentSlide] = useState(start);
  const [loaded, setLoaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: start,
    detailsChanged(slider) {
      const currentProgress = get(slider, 'track.details.progress', 0);
      setProgress(currentProgress);
    },
    loop,
    ...sliderConfig,

    slideChanged(slider) {
      const slide = get(slider, 'track.details.rel', 0);
      setCurrentSlide(slide);
    },
    created() {
      setLoaded(true);
    }
  });

  const detectLeftArrowAvailable = useCallback(() => {
    if (has(instanceRef, 'current') && loaded) {
      if (loop) {
        return !isMobile;
      }

      return !isMobile && !!currentSlide;
    }
    return false;
  }, [currentSlide, instanceRef, isMobile, loaded, loop]);

  const detectRightArrowAvailable = useCallback(() => {
    if (has(instanceRef, 'current') && loaded) {
      if (loop || progress !== 1) {
        return !isMobile;
      }
      return false;
    }

    return false;
  }, [instanceRef, isMobile, loaded, loop, progress]);

  return (
    <div className={navigationWrapper} data-testid={testId}>
      {detectLeftArrowAvailable() && (
        <PrevArrow
          testId={prevArrowId}
          onClick={() => {
            backArrowClick(instanceRef);
          }}
        />
      )}

      <div
        ref={sliderRef}
        className={classNames({
          sliderWrapper: true,
          keenSlider: true
        })}
      >
        {children}
      </div>
      {detectRightArrowAvailable() && (
        <NextArrow
          testId={nextArrowId}
          onClick={() => nextArrowClick(instanceRef)}
        />
      )}

      {loaded && has(instanceRef, 'current') && dotsVisible && (
        <div className={dots}>
          {[...Array(getNumberOfCard(instanceRef)).keys()].map((index) => (
            <button
              type="button"
              key={index}
              onClick={() => {
                moveToCard(instanceRef, index);
              }}
              className={classNames({
                dot: true,
                dotActive: currentSlide === index
              })}
            />
          ))}
        </div>
      )}
    </div>
  );
};
