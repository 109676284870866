/**
 * RadioButton wrapped with out stying, currently used in the radio type assessments
 */

import React from 'react';
import { Radio } from 'antd';
import styles from './RadioButton.module.scss';

const RadioButton = Radio.Button;

const RadioButtonComponent = ({ customStyle, children, testId, ...rest }) => {
  const { radioButton } = styles;

  const classNames = [radioButton];
  if (customStyle) {
    classNames.push(customStyle);
  }

  return (
    <RadioButton
      className={classNames.join(' ')}
      data-testid={testId}
      {...rest}
    >
      {children}
    </RadioButton>
  );
};

export default RadioButtonComponent;
