import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Col, Row } from 'antd';
import validation from 'utils/validation/validation';
import {
  TextFieldBlack,
  SelectFieldBlack,
  CheckBoxField
} from '../../../../../components/ui/Inputs';
import { ActionButton } from '../../../../../components/ui/Buttons';
import Lang from '../../../../../components/Lang';

import styles from './InPersonTherapy.module.scss';

const { formField, nextButtonStyle, nextButtonContainer, checkBoxLabelStyle } =
  styles;

const InPersonTherapyFormFirstPage = ({ handleSubmit, stateList }) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Col xs={{ span: 24 }} lg={{ span: 11, offset: 0 }}>
        <Field
          name="fullName"
          component={TextFieldBlack}
          type="text"
          label={
            <span>
              <Lang path="formCommon.fullNameText" />*
            </span>
          }
          labelId="full-name-label"
          className={formField}
          validate={validation.required_first_name}
        />
      </Col>
      <Col xs={{ span: 24 }} lg={{ offset: 2, span: 11 }}>
        <Field
          name="companyName"
          component={TextFieldBlack}
          type="text"
          className={formField}
          label={<Lang path="formCommon.employerIdText" />}
          labelId="employer-id-label"
          disabled
        />
      </Col>
    </Row>

    <Row>
      <Col xs={{ span: 24 }} lg={{ span: 11, offset: 0 }}>
        <Field
          id="phone-number"
          name="phone"
          component={TextFieldBlack}
          type="text"
          label={
            <span>
              <Lang path="formCommon.phoneNumberText" />*
            </span>
          }
          labelId="phone-label"
          className={formField}
          validate={validation.required_phone}
        />
        <Field
          name="isVoiceMailAllowed"
          component={CheckBoxField}
          text={
            <span
              data-testid="voice-mail-permission-text"
              className={checkBoxLabelStyle}
            >
              <Lang path="therapyRequest.form.contactPermissionText" />
            </span>
          }
        />
      </Col>
      <Col xs={{ span: 24 }} lg={{ offset: 2, span: 11 }}>
        <Field
          name="email"
          component={TextFieldBlack}
          type="email"
          label={
            <span>
              <Lang path="formCommon.emailText" />*
            </span>
          }
          labelId="email-label"
          className={formField}
          validate={[validation.required_email, validation.email]}
        />
        <Field
          name="isEmailAllowed"
          component={CheckBoxField}
          text={
            <span
              data-testid="email-permission-text"
              className={checkBoxLabelStyle}
            >
              <Lang path="therapyRequest.form.mailPermissionText" />
            </span>
          }
        />
      </Col>
    </Row>
    <Row>
      <Col xs={{ span: 24 }}>
        <Field
          id="address"
          name="address"
          component={TextFieldBlack}
          type="text"
          label={<Lang path="formCommon.addressText" />}
          labelId="address-label"
          className={formField}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={24} lg={{ span: 7 }}>
        <Field
          className={formField}
          name="state"
          component={SelectFieldBlack}
          label={
            <span>
              <Lang path="formCommon.stateText" />*
            </span>
          }
          labelId="state-label"
          options={stateList}
          validate={validation.required_geo_state}
        />
      </Col>
      <Col xs={24} lg={{ offset: 1, span: 8 }}>
        <Field
          id="city"
          name="city"
          component={TextFieldBlack}
          type="text"
          label={<Lang path="formCommon.cityText" />}
          labelId="city-label"
          className={formField}
        />
      </Col>
      <Col xs={24} lg={{ offset: 1, span: 7 }}>
        <Field
          id="zip-code"
          name="zipCode"
          component={TextFieldBlack}
          type="text"
          label={<Lang path="therapyRequest.form.zipCodeText" />}
          labelId="zip-label"
          className={formField}
        />
      </Col>
    </Row>
    <Row>
      <Col className={nextButtonContainer}>
        <ActionButton
          testId="next-button"
          className={nextButtonStyle}
          onClick={handleSubmit}
        >
          <Lang path="nextButtonText" />
        </ActionButton>
      </Col>
    </Row>
  </form>
);

InPersonTherapyFormFirstPage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  stateList: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ).isRequired
};

export default reduxForm({
  form: 'inPersonTherapyRequest',
  destroyOnUnmount: false
})(InPersonTherapyFormFirstPage);
