/**
 * Starting interstitial component
 */

// Core
import React from 'react';
import { connect } from 'react-redux';
// Components
import { Row, Col } from 'antd/lib/index';
import { ActionButton } from '../../../../components/ui/Buttons';
import BackButton from '../../../../components/wrapped/BackButton';
import Lang from '../../../../components/Lang';
// hocs
import Aux from '../../../../components/layout/Aux';
// Styles
import styles from './StartingInterstitial.module.scss';
// utils
import analytics from '../../../../utils/analytics';

const StartingInterstitial = ({ avatar, onNext, onBack, assessmentId }) => {
  const _handleNext = () => {
    analytics.track('assessment', 'clicked continue', {
      subcategory: 'get started',
      assessmentId
    });
    onNext();
  };

  const _handleBack = () => {
    analytics.track('assessment', 'clicked back', {
      subcategory: 'get started',
      assessmentId
    });
    onBack();
  };

  const {
    container,
    img,
    quoteContainer,
    quote,
    quoteBox,
    nextButtonContainer,
    connector,
    boldText,
    alignedButton
  } = styles;

  const path = avatar ? avatar.path : '';
  const alt = avatar ? avatar.value : '';
  return (
    <Aux>
      <Row className={container}>
        <Col
          xs={8}
          md={{
            span: 4,
            offset: 6
          }}
        >
          <img className={img} src={path} alt={alt} />
        </Col>
        <Col xs={14} md={8} className={quoteContainer}>
          <p className={quoteBox}>
            <Lang path="textBeforeAssessment.readyText" />
          </p>
          <div className={connector} />
          <p className={quote}>
            <span className={boldText}>
              <Lang path="textBeforeAssessment.readyDescriptionText.readyText1" />
            </span>
            <br />
            <Lang path="textBeforeAssessment.readyDescriptionText.readyText2" />
          </p>
        </Col>
      </Row>
      <Row className={nextButtonContainer}>
        <Col span={4} xs={{ offset: 4 }} md={{ offset: 6 }}>
          <BackButton className={alignedButton} onClick={_handleBack} />
        </Col>
        <Col span={12} md={{ offset: 2 }} xs={{ offset: 4 }}>
          <ActionButton
            testId="interstitial-continue-button"
            onClick={_handleNext}
          >
            <Lang path="continueButtonText" />
          </ActionButton>
        </Col>
      </Row>
    </Aux>
  );
};

const mapStateToProps = (state) => {
  const {
    questions: { assessmentId }
  } = state;
  return {
    assessmentId
  };
};

export default connect(mapStateToProps)(StartingInterstitial);
