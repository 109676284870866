import config from '../config';

import { initializeApi, httpMethod } from './index';

const {
  content: { url },
  contentVersionMap
} = config;

const fetchFromApi = initializeApi(url);

// Api class that contains all our user api endpoints
class ContentAPI {
  getMediaList = () =>
    fetchFromApi({
      endpoint: `media?contentVersionMap=${encodeURIComponent(
        contentVersionMap
      )}`,
      method: httpMethod.GET
    });
}

export default new ContentAPI();
