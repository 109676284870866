import React from 'react';
import { Card } from '@material-ui/core';
import propTypes from 'prop-types';

import styles from './ServiceCard.module.scss';

const { container, contentContainer, footerContainer } = styles;

const ServiceCard = ({ id, img, header, desc, footerContent }) => (
  <Card className={container} key={id} data-testid={id}>
    <div className={contentContainer}>
      <img data-testid={`${id}-img`} src={img} alt="" />
      <div>
        <h2 data-testid={`${id}-header-text`}>{header}</h2>
        <p data-testid={`${id}-description-text`}>{desc}</p>
      </div>
    </div>
    <div className={footerContainer}>{footerContent}</div>
  </Card>
);

ServiceCard.propTypes = {
  id: propTypes.string,
  header: propTypes.oneOfType([propTypes.object, propTypes.string]),
  desc: propTypes.oneOfType([propTypes.object, propTypes.string]),
  img: propTypes.string,
  footerContent: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.object),
    propTypes.object
  ])
};

ServiceCard.defaultProps = {
  id: '',
  header: '',
  desc: '',
  img: propTypes.object,
  footerContent: null
};

export default ServiceCard;
