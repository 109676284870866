import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import propTypes from 'prop-types';
import { Col, Row, Modal } from 'antd';
// assets
import i18n from 'assets/lang';
// components
import StepsAccordion from './partials/StepsAccordion';
// styles
import styles from './ThoughtRecorder.module.scss';
// utils
import {
  getScreenData,
  validateScreenData,
  stepsDataHandler,
  validateCurrentSteps,
  onStepChange,
  getCurrentProgress,
  getActiveStepsProgress,
  checkPreviousStepsData
} from './ThoughtRecorder.utils';

const { container, subheaderStyle, descriptionStyle, wrapper, stepsContainer } =
  styles;

const ThoughtRecorder = ({
  screenData,
  setScreenProgress,
  setIsNextDisabled,
  renderError,
  thoughtRecorderProgress,
  autoScrollRef,
  scrollToTopRef
}) => {
  const {
    subheader,
    description,
    thoughtRecorder: { steps, id },
    thoughtRecorderActiveSteps
  } = getScreenData({ screenData });

  const urlParams = useParams();

  const [hasValidPreviousSteps, setHasValidPreviousSteps] = useState(null);
  const [stepsData, setStepsData] = useState({});
  const [currentProgress, setCurrentProgress] = useState({});

  // Set to default on user navigation
  useEffect(() => {
    setCurrentProgress({});
    setStepsData({});
    setHasValidPreviousSteps(null);
  }, [urlParams, id]);

  // Handles current user progress
  useEffect(() => {
    const progress = getCurrentProgress({
      progress: thoughtRecorderProgress,
      id
    });
    if (isEmpty(currentProgress) && !isEmpty(progress)) {
      setCurrentProgress(progress);
    }
  }, [id, currentProgress, thoughtRecorderProgress]);

  // Sets thought recorder progress
  useEffect(() => {
    if (id && !isEmpty(currentProgress) && !isEmpty(stepsData)) {
      setScreenProgress({
        id,
        stepProgress: getActiveStepsProgress({ currentProgress, stepsData })
      });
    }
  }, [setScreenProgress, currentProgress, id, stepsData]);

  const showInvalidStepsPopUp = useCallback(() => {
    Modal.info({
      title: (
        <span data-testid="invalid-previous-steps-pop-up-title">
          {i18n.t('selfUse.session.popup.missingStepsProgress.title')}
        </span>
      ),
      okText: (
        <span data-testid="invalid-previous-steps-pop-up-confirmation">
          {i18n.t('selfUse.session.popup.missingStepsProgress.okText')}
        </span>
      )
    });
  }, []);

  // Sets steps data
  useEffect(() => {
    if (isEmpty(stepsData) && !isEmpty(steps)) {
      stepsDataHandler({
        id,
        steps,
        urlParams,
        setStepsData,
        progress: thoughtRecorderProgress,
        activeSteps: thoughtRecorderActiveSteps
      });
    }
  }, [
    id,
    steps,
    stepsData,
    thoughtRecorderActiveSteps,
    thoughtRecorderProgress,
    urlParams
  ]);

  // Checks previous steps data
  useEffect(() => {
    if (!isEmpty(stepsData) && hasValidPreviousSteps === null) {
      const progress = getCurrentProgress({
        progress: thoughtRecorderProgress,
        id
      });
      checkPreviousStepsData({
        ...urlParams,
        stepsData,
        currentProgress: progress,
        activeSteps: thoughtRecorderActiveSteps,
        setHasValidPreviousSteps,
        showInvalidStepsPopUp,
        setIsNextDisabled
      });
    }
  }, [
    hasValidPreviousSteps,
    id,
    setIsNextDisabled,
    showInvalidStepsPopUp,
    stepsData,
    thoughtRecorderActiveSteps,
    thoughtRecorderProgress,
    urlParams
  ]);

  // Show invalid previous state popup
  useEffect(() => {
    if (hasValidPreviousSteps === false) {
      showInvalidStepsPopUp();
    }
  }, [hasValidPreviousSteps, showInvalidStepsPopUp]);

  // Validates current steps data
  useEffect(() => {
    if (!isEmpty(stepsData) && hasValidPreviousSteps) {
      validateCurrentSteps({
        stepsData,
        currentProgress,
        setIsNextDisabled
      });
    }
  }, [
    currentProgress,
    hasValidPreviousSteps,
    setIsNextDisabled,
    stepsData,
    urlParams
  ]);

  const isValid = validateScreenData({
    id,
    subheader,
    steps,
    screenData
  });

  if (!isValid) return renderError('selfUse.session.error.screenErrorMessage');

  return (
    <div
      data-testid="thought-recorder-screen"
      className={wrapper}
      ref={scrollToTopRef}
    >
      <div className={container}>
        <Row>
          <Col md={12} lg={12} xs={24}>
            <h2
              data-testid="thought-recorder-subheader"
              className={subheaderStyle}
            >
              {subheader}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={24} xs={24}>
            <p
              data-testid="thought-recorder-description"
              className={descriptionStyle}
            >
              {description}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={24} xs={24}>
            <div data-testid="steps-container" className={stepsContainer}>
              <StepsAccordion
                stepsData={stepsData}
                urlParams={urlParams}
                currentProgress={currentProgress}
                setCurrentProgress={setCurrentProgress}
                setStepsData={setStepsData}
                autoScrollRef={autoScrollRef}
                onChange={({ type, expanded }) => {
                  onStepChange({
                    urlParams,
                    expanded,
                    type,
                    stepsData,
                    setStepsData
                  });
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

ThoughtRecorder.propTypes = {
  screenData: propTypes.shape({
    thoughtRecorder: propTypes.shape({
      id: propTypes.string,
      steps: propTypes.arrayOf(propTypes.shape({}))
    }),
    subheader: propTypes.string,
    description: propTypes.string,
    thoughtRecorderActiveSteps: propTypes.arrayOf(propTypes.string)
  }),
  thoughtRecorderProgress: propTypes.arrayOf(propTypes.shape({})),
  renderError: propTypes.func,
  setScreenProgress: propTypes.func,
  setIsNextDisabled: propTypes.func
};

ThoughtRecorder.defaultProps = {
  screenData: {
    subheader: null,
    description: null,
    thoughtRecorder: {
      id: null,
      steps: []
    }
  },
  thoughtRecorderProgress: [],
  renderError: () => null,
  setScreenProgress: () => null,
  setIsNextDisabled: () => null
};

export default ThoughtRecorder;
