import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import _ from 'lodash';
import classNamesInstance from 'classnames/bind';
import { useIdleTimer } from 'react-idle-timer';
import { useSelector } from 'react-redux';
// icons
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
// config
import config from 'config';
// utils
import logger from 'utils/logger';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import helpers from 'utils/helpers';
import { useNetworkListener } from 'utils/hooks';
// components
import LoadingSpinner from 'components/LoadingSpinner';
import PlayerControls from '../PlayerControls';
// styles
import styles from './VideoPlayer.module.scss';

const {
  container,
  videoScreenStyle,
  audioPlayerStyle,
  midIcon,
  clickableStyle
} = styles;

const classNames = classNamesInstance.bind(styles);
const { cdnBaseUrl } = config;

const videoConfig = {
  file: {
    attributes: {
      controlsList: 'nodownload',
      disablePictureInPicture: true
    }
  }
};

const sendAnalyticsEvent = ({
  eventMessage,
  timePlayed,
  videoId,
  audioId,
  isBreathingExercise
}) => {
  analytics.track(
    isBreathingExercise ? eventCategories.TOOLS : eventCategories.SESSION,
    eventMessage,
    {
      subcategory: isBreathingExercise ? 'breathing exercise' : 'session',
      [isBreathingExercise ? 'audioId' : 'videoId']: isBreathingExercise
        ? audioId
        : videoId,
      timePlayed
    }
  );
};

const VideoPlayer = ({
  isBreathingExercise,
  videoData,
  audioData,
  currentProgress,
  isPlaying,
  setIsPlaying,
  setCurrentProgress,
  handleFullScreenChange,
  upsertSessionProgressHelper,
  setScreenProgress,
  isVideoEnded,
  setIsVideoEnded
}) => {
  const videoId = _.get(videoData, 'videoId', '');
  const videoDuration = _.get(videoData, 'duration', 0);
  const videoUrl = _.get(videoData, 'urlMp4', '');
  const audioId = _.get(audioData, 'audioId', '');
  const audioDuration = _.get(audioData, 'duration', 0);
  const audioUrl = _.get(audioData, 'audioUrl', '');
  const [muted, setMuted] = useState(false);
  const { screenId } = useParams();
  const [isControlsVisible, setIsControlsVisible] = useState(false);
  const [hideVideoControls, setHideVideoControls] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const playerRef = useRef(null);
  const videoScreenRef = useRef(null);
  const isModalOpen = useSelector((state) => _.get(state, 'modal.isOpen'));
  const { isFullScreen } = useSelector((state) => _.get(state, 'videoMode'));
  const [isOnline] = useNetworkListener();

  useEffect(() => {
    if (volume > 0 && muted && isPlaying) {
      setMuted(false);
    } else if (
      (volume === 0 && !muted) ||
      (!isPlaying && !helpers.platformInfo.isDesktop && !isBreathingExercise)
    ) {
      // muting the media if app is locked to disable media controls
      setMuted(true);
    }
  }, [isBreathingExercise, isPlaying, muted, volume]);

  useEffect(() => {
    setIsLoading(true);
  }, [screenId]);

  // Called when media is ready
  const onReady = useCallback(() => {
    if (playerRef.current) {
      const totalDuration = playerRef.current.getDuration();
      if (currentProgress < totalDuration - 2) {
        setIsVideoEnded(false);
        setIsControlsVisible(true);
      } else {
        setIsVideoEnded(true);
        setIsControlsVisible(false);
      }
      setIsLoading(false);
    }
  }, [currentProgress, setIsVideoEnded]);

  // Function for save user progress on intervals of 5 sec
  const throttledUpsertSessionProgressHelper = useCallback(
    _.throttle(
      (progress) =>
        upsertSessionProgressHelper(false, screenId, {
          currentDuration: parseInt(progress, 10),
          id: isBreathingExercise ? audioId : videoId
        }),
      5000
    ),
    [screenId]
  );

  useEffect(
    () => () => {
      // save progress throttle clean up
      throttledUpsertSessionProgressHelper.cancel();
    },
    [throttledUpsertSessionProgressHelper]
  );

  // Player onProgress callback
  const onProgress = ({ playedSeconds }) => {
    if (playedSeconds && isPlaying) {
      setCurrentProgress(playedSeconds);
      if (setScreenProgress) {
        setScreenProgress({
          currentDuration: parseInt(playedSeconds, 10),
          id: isBreathingExercise ? audioId : videoId
        });
      }
      if (!isBreathingExercise) {
        throttledUpsertSessionProgressHelper(currentProgress);
      }
    }
  };

  // Player onSeek callback
  const onSeek = useCallback(
    (seekTime) => {
      sendAnalyticsEvent({
        eventMessage: 'video seeked',
        timePlayed: seekTime,
        videoId,
        audioId,
        isBreathingExercise
      });
      if (!isBreathingExercise && currentProgress !== seekTime) {
        throttledUpsertSessionProgressHelper(seekTime);
      }
      if (seekTime > 0) {
        setCurrentProgress(seekTime);
        if (setScreenProgress) {
          setScreenProgress({
            currentDuration: parseInt(seekTime, 10),
            id: isBreathingExercise ? audioId : videoId
          });
        }
      }
    },
    [
      setScreenProgress,
      audioId,
      currentProgress,
      isBreathingExercise,
      setCurrentProgress,
      throttledUpsertSessionProgressHelper,
      videoId
    ]
  );

  // Player onError callback
  const onError = useCallback((error) => {
    logger.error(
      'Error occurred while playing the video',
      'VideoPlayer.onError',
      { error }
    );
  }, []);

  // Player onPlay callback
  const onPlay = useCallback(() => {
    sendAnalyticsEvent({
      eventMessage: 'clicked play video button',
      timePlayed: currentProgress,
      videoId,
      audioId,
      isBreathingExercise
    });
    if (!isBreathingExercise) {
      upsertSessionProgressHelper(false, screenId, {
        currentDuration: parseInt(currentProgress, 10),
        id: videoId
      });
    }
    videoScreenRef.current.focus();
  }, [
    audioId,
    currentProgress,
    isBreathingExercise,
    upsertSessionProgressHelper,
    videoId,
    screenId
  ]);

  // Player onPause callback
  const onPause = useCallback(() => {
    sendAnalyticsEvent({
      eventMessage: 'clicked pause video button',
      timePlayed: currentProgress,
      videoId,
      audioId,
      isBreathingExercise
    });
    setHideVideoControls(false);
    if (!isBreathingExercise) {
      upsertSessionProgressHelper(false, screenId, {
        currentDuration: parseInt(currentProgress, 10),
        id: videoId
      });
    }
  }, [
    screenId,
    audioId,
    currentProgress,
    isBreathingExercise,
    upsertSessionProgressHelper,
    videoId
  ]);

  // Player onEnded callback
  const onEnded = useCallback(() => {
    setIsVideoEnded(true);
    setIsPlaying(false);
    setIsControlsVisible(false);
    if (isFullScreen) {
      handleFullScreenChange(false);
    }
    sendAnalyticsEvent({
      eventMessage: 'video has ended',
      timePlayed: currentProgress,
      videoId,
      audioId,
      isBreathingExercise
    });
  }, [
    audioId,
    setIsPlaying,
    currentProgress,
    handleFullScreenChange,
    isBreathingExercise,
    isFullScreen,
    videoId,
    setIsVideoEnded
  ]);

  const handlePlay = useCallback(
    (event, progress) => {
      setIsPlaying(!isPlaying);
      // to force player to become ready in case of videoUrl change
      const seekToValue = _.isNumber(progress)
        ? progress
        : currentProgress || 0;
      playerRef.current.seekTo(seekToValue, 'seconds');
    },
    [currentProgress, isPlaying, setIsPlaying]
  );

  // Replay button click handler
  const handleReplay = useCallback(() => {
    sendAnalyticsEvent({
      eventMessage: 'clicked replay video button',
      timePlayed: currentProgress,
      videoId,
      audioId,
      isBreathingExercise
    });
    setCurrentProgress(0);
    if (setScreenProgress) {
      setScreenProgress({
        currentDuration: 0,
        id: isBreathingExercise ? audioId : videoId
      });
    }
    handlePlay(null, 0);
    setIsVideoEnded(false);
    setIsControlsVisible(true);
  }, [
    audioId,
    currentProgress,
    handlePlay,
    isBreathingExercise,
    setCurrentProgress,
    setScreenProgress,
    videoId,
    setIsVideoEnded
  ]);

  // Function for automatically hide video controls
  const hideControls = () => {
    if (isPlaying && helpers.platformInfo.isDesktop) {
      setHideVideoControls(true);
    }
  };

  useIdleTimer({
    timeout: 3000,
    onIdle: () => {
      hideControls();
    },
    debounce: 500
  });

  // Mute button click handler
  const handleVolumeButtonClick = useCallback(() => {
    if (muted) {
      setMuted(false);
      setVolume(1);
    } else {
      setMuted(true);
      setVolume(0);
    }
  }, [muted, setMuted, setVolume]);

  useEffect(() => {
    const keyDownEventListener = (event) => {
      event.preventDefault();
      switch (event.keyCode) {
        // Mute/Unmute with `M`
        case 77:
          handleVolumeButtonClick();
          break;
        // F key for full-screen
        case 70: {
          handleFullScreenChange(!isFullScreen);
          break;
        }
        // Space
        case 32: {
          if (isVideoEnded) {
            handleReplay();
          } else {
            handlePlay();
          }
          break;
        }
        // Left Arrow
        case 37: {
          const updatedProgress =
            currentProgress - 5 > 0 ? currentProgress - 5 : 0;
          if (playerRef.current) {
            playerRef.current.seekTo(updatedProgress, 'seconds');
          }
          break;
        }
        // Right Arrow
        case 39: {
          if (playerRef.current && !isVideoEnded) {
            const totalDuration = playerRef.current.getDuration();
            const updatedProgress =
              currentProgress + 5 < totalDuration
                ? currentProgress + 5
                : // Deducting 0.01 to avoid flicker
                  totalDuration - 0.01;
            if (playerRef.current) {
              playerRef.current.seekTo(updatedProgress, 'seconds');
            }
          }
          break;
        }
        // Up arrow
        case 38: {
          const newVolume = volume + 0.05;
          if (muted && newVolume > 0) {
            setMuted(false);
          }
          if (newVolume <= 1) {
            setVolume(newVolume);
          } else {
            setVolume(1);
          }
          break;
        }
        // Down arrow
        case 40: {
          const newVolume = volume - 0.05;
          if (newVolume > 0) {
            setVolume(newVolume);
          } else {
            setVolume(0);
            setMuted(true);
          }
          break;
        }
        default:
          break;
      }
    };
    window.addEventListener('keydown', keyDownEventListener);
    return () => {
      window.removeEventListener('keydown', keyDownEventListener);
    };
  }, [
    currentProgress,
    handleFullScreenChange,
    handleReplay,
    handleVolumeButtonClick,
    isFullScreen,
    isPlaying,
    isVideoEnded,
    muted,
    onSeek,
    volume,
    handlePlay
  ]);
  useEffect(() => {
    if (isModalOpen && isPlaying) {
      setIsPlaying(false);
    }
  }, [isModalOpen, isPlaying, setIsPlaying]);

  const MidIcon = useCallback(() => {
    let icon;
    const midIconClassNames = [midIcon];
    if (isLoading) {
      icon = <LoadingSpinner />;
    } else if (isVideoEnded) {
      icon = <ReplayOutlinedIcon onClick={handleReplay} />;
      midIconClassNames.push(clickableStyle);
    } else if (!isPlaying) {
      icon = <PlayArrowIcon onClick={handlePlay} />;
      midIconClassNames.push(clickableStyle);
    } else {
      return null;
    }
    return (
      <div data-testid="replay-button" className={midIconClassNames.join(' ')}>
        {icon}
      </div>
    );
  }, [handlePlay, handleReplay, isLoading, isPlaying, isVideoEnded]);

  return (
    <div
      className={container}
      style={{ cursor: hideVideoControls ? 'none' : 'default' }}
      onMouseMove={() => {
        setHideVideoControls(false);
      }}
      onMouseLeave={() => {
        _.debounce(hideControls, 3000, { maxWait: 3000 })();
      }}
    >
      <div
        className={classNames({
          normalVideoScreen: !isFullScreen,
          maxVideoScreen: isFullScreen
        })}
        ref={videoScreenRef}
      >
        <div
          className={classNames({
            videoWrapper: true,
            backdropStyle: !isPlaying
          })}
        >
          {isBreathingExercise && (
            <ReactPlayer
              data-testid="video-player"
              className={videoScreenStyle}
              key={`${audioId}${screenId}`}
              url={`${cdnBaseUrl}${videoUrl}`}
              pip={false}
              controls={false}
              config={videoConfig}
              playing={isPlaying}
              loop
              muted
              onClick={() => setIsPlaying(false)}
              playsinline
            />
          )}
          <ReactPlayer
            data-testid={isBreathingExercise ? 'audio-player' : 'video-player'}
            className={
              isBreathingExercise ? audioPlayerStyle : videoScreenStyle
            }
            url={`${cdnBaseUrl}${isBreathingExercise ? audioUrl : videoUrl}`}
            pip={false}
            key={`${videoId}${screenId}`}
            controls={false}
            ref={playerRef}
            volume={volume}
            muted={muted}
            config={videoConfig}
            onPlay={onPlay}
            onPause={onPause}
            onSeek={(seekTime) => onSeek(seekTime)}
            onEnded={onEnded}
            onError={onError}
            onReady={onReady}
            onProgress={onProgress}
            playing={isPlaying && isOnline}
            loop={false}
            onClick={() => setIsPlaying(false)}
            playsinline
          />
        </div>
        {isControlsVisible && (
          <div
            className={classNames({
              playerControlsPos: true,
              playerControlsPosFullscreen: isFullScreen
            })}
          >
            <PlayerControls
              duration={isBreathingExercise ? audioDuration : videoDuration}
              currentProgress={currentProgress}
              isPlaying={isPlaying}
              handlePlay={handlePlay}
              playerRef={playerRef}
              handleFullScreenChange={handleFullScreenChange}
              hideVideoControls={hideVideoControls}
              handleVolumeButtonClick={handleVolumeButtonClick}
              muted={muted}
              volume={volume}
              setVolume={setVolume}
              playsinline
            />
          </div>
        )}
      </div>
      <MidIcon />
    </div>
  );
};
export default VideoPlayer;
