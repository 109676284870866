/**
 * Storage class for persisting apollo cache
 */

import { PersistentStorage } from 'apollo3-cache-persist';
// eslint-disable-next-line import/no-cycle
import storage from '.';

class Storage implements PersistentStorage {
  getItem = async (key) => storage.getItem(key);

  removeItem = async (key) => {
    await storage.removeItem(key);
  };

  setItem = async (key, value) => {
    await storage.setItem(key, value);
  };
}

export default Storage;
