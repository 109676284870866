import React from 'react';
import config from 'config';
import styles from './PrivacyNotice.module.scss';
import Lang from '../../Lang';

const { appName } = config;
const { infoSection, infoText } = styles;

const PrivacyNotice = ({ className }) => {
  const parentClasses = [infoSection];
  if (className) {
    parentClasses.push(className);
  }
  return (
    <div className={parentClasses.join(' ')}>
      <div className={infoText}>
        <Lang path="companySelectPrivacyText" values={{ appName }} />
      </div>
    </div>
  );
};

export default PrivacyNotice;
