/**
 * Contains list of types to be used by our actions and reducers.
 */

// Assessment Action types
export const FETCH_QUESTIONS_AND_INTERSTITIAL =
  'FETCH_QUESTIONS_AND_INTERSTITIAL';
export const FETCH_QUESTIONS_AND_INTERSTITIAL_DONE =
  'FETCH_QUESTIONS_AND_INTERSTITIAL_DONE';
export const FETCH_QUESTIONS_AND_INTERSTITIAL_ERROR =
  'FETCH_QUESTIONS_AND_INTERSTITIAL_ERROR';
export const RESET_ASSESSMENT = 'RESET_ASSESSMENT';
export const HIDE_STARTING_INTERSTITIAL = 'HIDE_STARTING_INTERSTITIAL';

// Partial Assessment Progress
export const SET_ASSESSMENT_PROGRESS_ID = 'SET_ASSESSMENT_PROGRESS_ID';

// Question Action types
export const SET_ACTIVE_QUESTION = 'SET_ACTIVE_QUESTION';

// Response Action types
export const ADD_RESPONSE = 'ADD_RESPONSE';
export const REMOVE_RESPONSE = 'REMOVE_RESPONSE';
export const REMOVE_LAST_RESPONSE = 'REMOVE_LAST_RESPONSE';

// Avatar Action types
export const SET_AVATAR = 'SET_AVATAR';

// Report Action types
export const PROCESS_REPORT = 'PROCESS_REPORT';
export const PROCESS_REPORT_DONE = 'PROCESS_REPORT_DONE';
export const PROCESS_REPORT_ERROR = 'PROCESS_REPORT_ERROR';
export const PROCESS_REPORT_TO_LIST = 'PROCESS_REPORT_TO_LIST';
export const GET_REPORT_BY_ID = 'GET_REPORT_BY_ID';
export const GET_REPORT_BY_ID_DONE = 'GET_REPORT_BY_ID_DONE';
export const GET_REPORT_BY_ID_ERROR = 'GET_REPORT_BY_ID_ERROR';

// Reset Store Action types
export const RESET_ROOT_STATE = 'RESET_ROOT_STATE';

// Goal Action types
export const SELECT_GOAL = 'SELECT_GOAL';
export const SELECT_GOAL_DONE = 'SELECT_GOAL_DONE';
export const SELECT_GOAL_ERROR = ' SELECT_GOAL_ERROR';

// Report List Action types
export const FETCH_REPORT_LIST = 'FETCH_REPORT_LIST';
export const FETCH_REPORT_LIST_DONE = 'FETCH_REPORT_LIST_DONE';
export const FETCH_REPORT_LIST_ERROR = 'FETCH_REPORT_LIST_ERROR';

// Actions for redirecting the user back to the requested page login
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
export const RESET_REDIRECT_URL = 'RESET_REDIRECT_URL';

// Profile Action types
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_USER_DATA_DONE = 'UPDATE_USER_DATA_DONE';
export const UPDATE_USER_DATA_ERROR = 'UPDATE_USER_DATA_ERROR';
export const GET_USER_DATA = 'GET_USER_DATA';
export const SET_ONBOARD_FLAG = 'SET_ONBOARD_FLAG';
export const SET_ONBOARD_FLAG_DONE = 'SET_ONBOARD_FLAG_DONE';
export const SET_ONBOARD_FLAG_ERROR = 'SET_ONBOARD_FLAG_ERROR';
export const RESET_USER_DATA_STATUS = 'RESET_USER_DATA_STATUS';

// Company Details Action Type
export const GET_COMPANY_DETAILS = 'GET_COMPANY_DETAILS';
export const GET_COMPANY_DETAILS_DONE = 'GET_COMPANY_DETAILS_DONE';
export const GET_COMPANY_DETAILS_ERROR = 'GET_COMPANY_DETAILS_ERROR';

// Layout Types
export const ENABLE_APP_HEADER = 'ENABLE_APP_HEADER';
export const DISABLE_APP_HEADER = 'DISABLE_APP_HEADER';

// Modal Actions
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

// Window Actions
export const MINIMIZE_CHAT_WINDOW = 'MINIMIZE_CHAT_WINDOW';
export const MAXIMIZE_CHAT_WINDOW = 'MAXIMIZE_CHAT_WINDOW';

// Chat Actions
export const ADD_VISITOR_CHAT_MESSAGE = 'ADD_VISITOR_CHAT_MESSAGE';
export const ADD_SYSTEM_CHAT_MESSAGE = 'ADD_SYSTEM_CHAT_MESSAGE';
export const SYSTEM_MESSAGE_TYPING = 'SYSTEM_MESSAGE_TYPING';
export const SYSTEM_MESSAGE_TYPING_DONE = 'SYSTEM_MESSAGE_TYPING_DONE';
export const POP_LAST_CHAT_MESSAGE = 'POP_LAST_CHAT_MESSAGE';

// Chat assessment questions
export const FETCH_CONVERSATIONAL_ASSESSMENT =
  'FETCH_CONVERSATIONAL_ASSESSMENT';
export const FETCH_CONVERSATIONAL_ASSESSMENT_DONE =
  'FETCH_CONVERSATIONAL_ASSESSMENT_DONE';
export const FETCH_CONVERSATIONAL_ASSESSMENT_ERROR =
  'FETCH_CONVERSATIONAL_ASSESSMENT_ERROR';

// Set chat assessment progressId
export const SET_CONVERSATIONAL_ASSESSMENT_PROGRESS_ID =
  'SET_CONVERSATIONAL_ASSESSMENT_PROGRESS_ID';

// Set chat assessment data
export const SET_CONVERSATIONAL_ASSESSMENT_DATA =
  'SET_CONVERSATIONAL_ASSESSMENT_DATA';

// Clear chat assessment data
export const CLEAR_CONVERSATIONAL_ASSESSMENT_DATA =
  'CLEAR_CONVERSATIONAL_ASSESSMENT_DATA';

// Chat assessment responses
export const ADD_CHAT_ASSESSMENT_RESPONSE = 'ADD_CHAT_ASSESSMENT_RESPONSE';
export const REMOVE_CHAT_ASSESSMENT_RESPONSE =
  'REMOVE_CHAT_ASSESSMENT_RESPONSE';

// Chat assessment report
export const CHAT_PROCESS_REPORT = 'CHAT_PROCESS_REPORT';
export const CHAT_PROCESS_REPORT_DONE = 'CHAT_PROCESS_REPORT_DONE';
export const CHAT_PROCESS_REPORT_ERROR = 'CHAT_PROCESS_REPORT_ERROR';

// Request coach
export const REQUEST_COACH = 'REQUEST_COACH';
export const REQUEST_COACH_DONE = 'REQUEST_COACH_DONE';
export const REQUEST_COACH_ERROR = 'REQUEST_COACH_ERROR';
export const GET_COACH_REQUEST_STATUS = 'GET_COACH_REQUEST_STATUS';
export const GET_COACH_REQUEST_STATUS_DONE = 'GET_COACH_REQUEST_STATUS_DONE';
export const GET_COACH_REQUEST_STATUS_ERROR = 'GET_COACH_REQUEST_STATUS_ERROR';
export const CLEAR_COACH_REQUEST_STATUS_ERROR =
  'CLEAR_COACH_REQUEST_STATUS_ERROR';
export const AUTO_COACH_REQUEST = 'AUTO_COACH_REQUEST';
export const AUTO_COACH_REQUEST_DONE = 'AUTO_COACH_REQUEST_DONE';
export const AUTO_COACH_REQUEST_ERROR = 'AUTO_COACH_REQUEST_ERROR';
export const AUTO_COACH_REQUEST_ELIGIBILITY = 'AUTO_COACH_REQUEST_ELIGIBILITY';

// Request Video therapy
export const REQUEST_VIDEO_THERAPY = 'REQUEST_VIDEO_THERAPY';
export const REQUEST_VIDEO_THERAPY_DONE = 'REQUEST_VIDEO_THERAPY_DONE';
export const REQUEST_VIDEO_THERAPY_ERROR = 'REQUEST_VIDEO_THERAPY_ERROR';
export const GET_VIDEO_THERAPY_STATUS = 'GET_VIDEO_THERAPY_STATUS';
export const GET_VIDEO_THERAPY_STATUS_DONE = 'GET_VIDEO_THERAPY_STATUS_DONE';
export const GET_VIDEO_THERAPY_STATUS_ERROR = 'GET_VIDEO_THERAPY_STATUS_ERROR';
export const CLEAR_VIDEO_THERAPY_STATUS_ERROR =
  'CLEAR_VIDEO_THERAPY_STATUS_ERROR';

// Request In-Person therapy
export const REQUEST_IN_PERSON_THERAPY = 'REQUEST_IN_PERSON_THERAPY';
export const REQUEST_IN_PERSON_THERAPY_DONE = 'REQUEST_IN_PERSON_THERAPY_DONE';
export const REQUEST_IN_PERSON_THERAPY_ERROR =
  'REQUEST_IN_PERSON_THERAPY_ERROR';
export const GET_IN_PERSON_THERAPY_STATUS = 'GET_IN_PERSON_THERAPY_STATUS';
export const GET_IN_PERSON_THERAPY_STATUS_DONE =
  'GET_IN_PERSON_THERAPY_STATUS_DONE';
export const GET_IN_PERSON_THERAPY_STATUS_ERROR =
  'GET_IN_PERSON_THERAPY_STATUS_ERROR';
export const CLEAR_IN_PERSON_THERAPY_STATUS_ERROR =
  'CLEAR_IN_PERSON_THERAPY_STATUS_ERROR';

// Dashboard action
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const SET_DASHBOARD_SESSION_COUNT = 'SET_DASHBOARD_SESSION_COUNT';
export const SET_DASHBOARD_CALL_COMPLETED = 'SET_DASHBOARD_CALL_COMPLETED';

// Company List
export const GET_COMPANY_LIST_START = 'GET_COMPANY_LIST_START';
export const GET_COMPANY_LIST_DONE = 'GET_COMPANY_LIST_DONE';
export const GET_COMPANY_LIST_ERROR = 'GET_COMPANY_LIST_ERROR';

// Question list of Work Life Request
export const SET_WORK_LIFE_REQUEST_QUESTIONS_START =
  'SET_WORK_LIFE_REQUEST_QUESTIONS_START';
export const SET_WORK_LIFE_REQUEST_QUESTIONS_DONE =
  'SET_WORK_LIFE_REQUEST_QUESTIONS_DONE';
export const SET_WORK_LIFE_REQUEST_QUESTIONS_ERROR =
  'SET_WORK_LIFE_REQUEST_QUESTIONS_ERROR';

// Registration fields
export const GET_REGISTRATION_FIELDS = 'GET_REGISTRATION_FIELDS';
export const GET_REGISTRATION_FIELDS_DONE = 'GET_REGISTRATION_FIELDS_DONE';
export const GET_REGISTRATION_FIELDS_ERROR = 'GET_REGISTRATION_FIELDS_ERROR';

// Coach chat message types
export const ADD_CHAT_MESSAGE = 'ADD_CHAT_MESSAGE';
export const MESSAGE_TYPING = 'MESSAGE_TYPING';
export const MESSAGE_TYPING_DONE = 'MESSAGE_TYPING_DONE';
// Coach chat current message types
export const SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE';
// Access token status
export const SET_SESSION_EXPIRED = 'SET_SESSION_EXPIRED';

// Current User and active screen in coach chat
export const CURRENT_USER = 'CURRENT_USER';
export const INFO_TAB_COACH_CHAT = 'INFO_TAB_COACH_CHAT';

// Coach chat media
export const FETCH_MEDIA_LIST_START = 'FETCH_MEDIA_LIST_START';
export const FETCH_MEDIA_LIST_DONE = 'FETCH_MEDIA_LIST_DONE';
export const FETCH_MEDIA_LIST_ERROR = 'FETCH_MEDIA_LIST_ERROR';

// Video Modal Actions
export const OPEN_VIDEO_MODAL = 'OPEN_VIDEO_MODAL';
export const CLOSE_VIDEO_MODAL = 'CLOSE_VIDEO_MODAL';

// PUSH NOTIFICATION Actions
export const SET_PUSH_NOTIFICATION_DATA = 'SET_PUSH_NOTIFICATION_DATA';

// Keyboard Actions
export const KEYBOARD_SHOWN = 'KEYBOARD_SHOWN';
export const KEYBOARD_CLOSED = 'KEYBOARD_CLOSED';

export const VIDEO_FULLSCREEN_ACTIVE = 'VIDEO_FULLSCREEN_ACTIVE';
export const VIDEO_FULLSCREEN_INACTIVE = 'VIDEO_FULLSCREEN_INACTIVE';
