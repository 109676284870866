import React from 'react';
import ContentLoader from 'react-content-loader';

export const LoaderLarge = () => (
  <ContentLoader
    data-testid="loader-large"
    viewBox="0 0 900 220"
    height={210}
    width={900}
  >
    <rect x="0" y="0" rx="0" ry="0" width="153" height="18" />
    <rect x="0" y="39" rx="4" ry="4" width="295" height="150" />
    <rect x="313" y="39" rx="4" ry="4" width="295" height="150" />
    <rect x="623" y="39" rx="4" ry="4" width="277" height="150" />
  </ContentLoader>
);

export const LoaderSmall = () => (
  <ContentLoader
    data-testid="loader-small"
    viewBox="0 0 900 600"
    height={600}
    width={900}
  >
    <rect x="0" y="0" rx="0" ry="0" width="520" height="50" />
    <rect x="0" y="130" rx="10" ry="10" width="350" height="600" />
    <rect x="416" y="130" rx="10" ry="10" width="350" height="600" />
    <rect x="830" y="130" rx="10" ry="10" width="25" height="600" />
  </ContentLoader>
);
