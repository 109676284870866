import React from 'react';
import { getPlatform } from 'utils/capacitor';
import i18n from 'assets/lang';
import styles from './BlockAccess.module.scss';

const { container } = styles;

const BlockAccess = () => {
  const platform = getPlatform();
  const breachType = i18n.t(`blockAccessPopup.breachType.${platform}`);
  return (
    <>
      <div className={container}>
        <h2 data-testid="header-text">
          {i18n.t('blockAccessPopup.header', { breachType })}
        </h2>
        <p data-testid="description-text">
          {i18n.t('blockAccessPopup.description', {
            breachType,
            device: platform
          })}
        </p>
      </div>
    </>
  );
};

export default BlockAccess;
