import React from 'react';
import { Route } from 'react-router-dom';

// containers
import Report from 'containers/Report';
import DashBoard from 'containers/DashBoard';
import CoachChat from 'containers/Coach/Chat';
import SelfUse from 'containers/SelfUse';
import Resources from 'containers/Resources';
import ChatContainer from 'containers/Coach';
import Account from 'containers/Account';

// hocs
import wrapperHoc from 'hoc/wrapperHoc';
import assessmentModule from 'hoc/assessmentModule';
import checkAssessmentCompleted from 'hoc/checkAssessmentCompleted';

const routes = [
  <Route
    key="0"
    path="/:tab(my-plan)/:goalId?/:severity?"
    component={checkAssessmentCompleted(wrapperHoc(DashBoard))}
  />,
  <Route
    key="1"
    path="/:tab(report)/:id"
    component={assessmentModule(wrapperHoc(Report))}
  />,
  <Route
    key="2"
    path="/:tab(coach)/chat"
    component={wrapperHoc(CoachChat)}
    exact
  />,
  <Route
    key="3"
    path="/:tab(coach)"
    component={wrapperHoc(ChatContainer)}
    exact
  />,
  <Route
    key="4"
    path="/:tab(resources)"
    exact
    component={checkAssessmentCompleted(wrapperHoc(Resources))}
  />,
  <Route
    key="5"
    path="/:tab(self-use)"
    exact
    component={checkAssessmentCompleted(wrapperHoc(SelfUse))}
  />,
  <Route
    key="6"
    exact
    path="/:tab(account)"
    component={wrapperHoc(Account, { setBackground: true })}
  />
];

export default routes;
