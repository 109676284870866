/**
 * Avatar contains that contains the avatar form and the underlying logic
 */

// Core
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
// Components
import AvatarForm from '../AvatarForm/AvatarForm';
import ContentContainer from '../../../../components/layout/ContentContainer';
// hocs
import withAuth from '../../../../hoc/withAuth';
// actions
import setActiveAvatar from '../../../../store/actions/AvatarAction';
// utils
import analytics from '../../../../utils/analytics';

class Avatar extends Component {
  _handleSubmit = ({ avatar_choice: avatarChoice }) => {
    analytics.track('assessment', 'clicked get started', {
      subcategory: 'avatar',
      assessmentId: this.props.assessmentId
    });
    if (avatarChoice) {
      this.props.actions.setActiveAvatar(
        this.props.avatarList.find((avatar) => avatar.id === avatarChoice)
      );
    } else {
      this.props.actions.setActiveAvatar(this.props.avatarList[0]);
    }
    this.props.onSubmit();
  };

  _handleBack = () => {
    analytics.track('assessment', 'clicked back', {
      subcategory: 'avatar',
      assessmentId: this.props.assessmentId
    });
    this.props.onBack();
  };

  render() {
    return (
      <ContentContainer>
        <AvatarForm
          avatarList={this.props.avatarList}
          onSubmit={this._handleSubmit}
          onBack={this._handleBack}
        />
      </ContentContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    avatar: { avatarList },
    questions: { assessmentId }
  } = state;

  return { avatarList, assessmentId };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    setActiveAvatar: bindActionCreators(setActiveAvatar, dispatch)
  }
});

Avatar.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Avatar));
