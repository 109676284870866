import CryptoJS from 'crypto-js';
import { SecureStoragePlugin } from '@wayforward/capacitor-secure-storage-plugin';
// eslint-disable-next-line import/no-cycle
import logger from '../logger';

const generateNewKey = (password) => {
  // 512 bits random password to generate secret key
  const salt = CryptoJS.lib.WordArray.random(16);
  const key = CryptoJS.PBKDF2(password, salt, {
    keySize: 8, // 256 bits
    iterations: 10000, // NIST recommended minimum
    hasher: CryptoJS.algo.SHA256 // NIST recommended PBKDF2 hash algo
  }).toString();
  return key;
};

const STORAGE_ENCRYPTION_SECRET_KEY_KEY = 'wayforward_secret_key';

export const clearStorageEncryptionKey = async () => {
  try {
    await SecureStoragePlugin.remove({
      key: STORAGE_ENCRYPTION_SECRET_KEY_KEY
    });
    logger.info(
      'Successfully cleared secure storage',
      'storage-encryption-key.clearStorageEncryptionKey'
    );
  } catch (error) {
    logger.error(
      'Error clearing secure storage',
      'storage-encryption-key.clearStorageEncryptionKey',
      { error }
    );
  }
};

const getEncryptionKey = async () => {
  try {
    const { value } = await SecureStoragePlugin.get({
      key: STORAGE_ENCRYPTION_SECRET_KEY_KEY
    });
    return value;
  } catch (error) {
    logger.error(
      'Error in getEncryptionKey',
      'storage-encryption-key.getEncryptionKey',
      { error }
    );
    throw error;
  }
};

const generateEncryptionKey = async () => {
  try {
    const password = CryptoJS.lib.WordArray.random(16);
    const encryptionKey = generateNewKey(password);

    await SecureStoragePlugin.set({
      key: STORAGE_ENCRYPTION_SECRET_KEY_KEY,
      value: encryptionKey
    });

    logger.info(
      'Secret key successfully generated.',
      'storage-encryption-key.generateEncryptionKey'
    );
    return encryptionKey;
  } catch (error) {
    logger.error(
      'Error in generateEncryptionKey',
      'storage-encryption-key.generateEncryptionKey',
      { error }
    );
    throw error;
  }
};

export const getStorageEncryptionKey = async () => {
  let encryptionKey;
  try {
    const { value: keys } = await SecureStoragePlugin.keys();
    if (keys.includes(STORAGE_ENCRYPTION_SECRET_KEY_KEY)) {
      encryptionKey = await getEncryptionKey();
      return encryptionKey;
    }
    logger.info(
      'Secret key not available in secure storage, generating new key',
      'storage-encryption-key.getStorageEncryptionKey'
    );

    encryptionKey = await generateEncryptionKey();
    return encryptionKey;
  } catch (error) {
    logger.error(
      'Error in getStorageEncryptionKey',
      'storage-encryption-key.getStorageEncryptionKey',
      { error }
    );
    throw error;
  }
};
