/**
 *
 * LeftSideBar
 *
 */

// Core
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
// utils
import analytics from 'utils/analytics/analytics';
import eventCategories from 'utils/analytics/categories';
import history from 'utils/history';
import coachRequestStatusTypes from 'assets/data/dashboard/coachRequestStatusTypes';
import auth from 'utils/auth';
import featureService from 'utils/feature-service';
import helpers from 'utils/helpers';
import userIcon from 'assets/icons/user-icon.svg';
// Components
import logger from 'utils/logger';
import Lang from '../Lang';
import styles from './LeftSideBar.module.scss';

const {
  container,
  openStyle,
  sidebarName,
  profileNameStyle,
  closeButton,
  closeButtonIcon,
  navTitle,
  overLayout,
  active
} = styles;

const { _hasResourcesFeature } = featureService;

const { isActivePath, getLastReportId, isCoach } = helpers;

const LeftSideBarButton = ({
  checkCondition = true,
  onClick,
  tabIndex = 0,
  activePath = '',
  buttonText
}) =>
  checkCondition ? (
    <li>
      <div
        role="button"
        onClick={onClick}
        tabIndex={tabIndex}
        onKeyUp={(e) => e.keyCode === 13 && null}
      >
        <div
          className={`${navTitle} ${
            activePath && isActivePath(activePath) ? active : ''
          }`}
        >
          <span>{buttonText}</span>
        </div>
      </div>
    </li>
  ) : null;

const LeftSideBar = ({
  open,
  goalId,
  severity,
  closeSideBar,
  coachRequestStatus,
  profileName,
  reports
}) => {
  const lastReportId = getLastReportId();
  const lastReportUrl = `/report/${lastReportId}`;
  const containerClassNames = [container];
  const hasTakenAssessment = !_.isEmpty(reports);

  const handleChatNavigation = () => {
    if (
      coachRequestStatus === coachRequestStatusTypes.assigned &&
      featureService._hasCoachAccessOnWebFeature()
    ) {
      analytics.track(eventCategories.LEFT_MENU, 'clicked coach chat', {
        goalId,
        severity
      });
      history.push('/coach');
    } else {
      analytics.track(eventCategories.LEFT_MENU, 'clicked chat', {
        goalId,
        severity
      });
      history.push('/coach/chat');
    }
  };

  if (open) {
    containerClassNames.push(openStyle);
  }

  return (
    <React.Fragment>
      <div
        className={open ? overLayout : ''}
        role="button"
        onClick={closeSideBar}
        tabIndex={0}
        onKeyUp={(e) => e.keyCode === 13 && null}
      />
      <div className={containerClassNames.join(' ')}>
        {open && (
          <CloseIcon
            className={[closeButtonIcon, closeButton].join(' ')}
            onClick={() => {
              analytics.track(eventCategories.LEFT_MENU, 'clicked close', {
                goalId,
                severity
              });
              closeSideBar();
            }}
          />
        )}
        <div className={sidebarName}>
          <img src={userIcon} alt="" />
          <h2 className={profileNameStyle}>{profileName}</h2>
        </div>
        <ul>
          <LeftSideBarButton
            checkCondition={
              featureService._hasIntelliPlatformFeature() && hasTakenAssessment
            }
            onClick={() => {
              closeSideBar();
              analytics.track(eventCategories.LEFT_MENU, 'clicked my plan', {
                goalId,
                severity
              });
              history.push(
                goalId && severity
                  ? `/my-plan/${goalId}/${severity}`
                  : '/my-plan'
              );
            }}
            activePath="my-plan"
            buttonText={<Lang path="navigation.myPlan" />}
          />
          <LeftSideBarButton
            checkCondition={
              !featureService._hasIntelliPlatformFeature() && !!lastReportId
            }
            onClick={() => {
              closeSideBar();
              analytics.track(eventCategories.LEFT_MENU, 'clicked goal', {
                goalId,
                severity
              });
              history.push(lastReportUrl);
            }}
            activePath="report"
            buttonText={<Lang path="navigation.goal" />}
          />
          <LeftSideBarButton
            checkCondition={
              (coachRequestStatus === coachRequestStatusTypes.assigned &&
                featureService._hasCoachAccessOnWebFeature()) ||
              (isCoach() && featureService._hasCoachChatOnWebFeature())
            }
            onClick={() => handleChatNavigation()}
            activePath="coach"
            buttonText={<Lang path="navigation.coach" />}
          />
          <LeftSideBarButton
            checkCondition={
              hasTakenAssessment && featureService._hasSelfUseFeature()
            }
            onClick={() => {
              closeSideBar();
              analytics.track(eventCategories.LEFT_MENU, 'clicked self-use');
              history.push('/self-use');
            }}
            activePath="self-use"
            buttonText={<Lang path="navigation.selfUse" />}
          />
          <LeftSideBarButton
            checkCondition={hasTakenAssessment && _hasResourcesFeature()}
            onClick={() => {
              closeSideBar();
              analytics.track(eventCategories.LEFT_MENU, 'clicked resources');
              history.push('/resources');
            }}
            activePath="resources"
            buttonText={<Lang path="navigation.resources" />}
          />
          <LeftSideBarButton
            onClick={() => {
              analytics.track(eventCategories.LEFT_MENU, 'clicked account', {
                goalId,
                severity
              });
              logger.info(
                'Clicked on account option LeftSideBar',
                'LeftSideBar.render'
              );
              closeSideBar();
              history.push('/account');
            }}
            activePath="account"
            buttonText={<Lang path="navigation.account" />}
          />
          <LeftSideBarButton
            onClick={() => {
              analytics.track('logout', 'clicked logout', {
                goalId,
                severity
              });
              logger.info(
                'Clicked on logout option from LeftSideBar',
                'LeftSideBar.render'
              );
              auth.logout();
            }}
            buttonText={<Lang path="navigation.logout" />}
          />
        </ul>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const {
    reportList: { reports },
    profile: {
      profile: { name }
    },
    dashBoard: { goalId, severity, sessionCount },
    companyDetails: { companyDetails },
    requestCoach: {
      coachRequestStatus,
      status: { isFetching }
    }
  } = state;
  return {
    profileName: name,
    goalId,
    severity,
    sessionCount,
    companyDetails,
    coachRequestStatus,
    isFetching,
    reports
  };
};

export default connect(mapStateToProps)(LeftSideBar);
