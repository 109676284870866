/**
 * Terms page container
 */

// Core
import React from 'react';
// Components
import ContentContainer from '../../components/layout/ContentContainer';
// hoc
import withAuth from '../../hoc/withAuth';
// styles
import styles from './Terms.module.scss';

const Terms = () => (
  <ContentContainer size="large" className={styles.container}>
    <h1>Dario Terms of use</h1>
    <div>
      <p>
        These Terms of Use (the “Terms”) form a binding agreement between you
        (including, if applicable, any legal entity which you represent or act
        for) (collectively, “you” or “User”) and DarioHealth, together with its
        affiliates and subsidiaries Labstyle Innovation Ltd, UpRight, Dario
        powered by wayForward, (collectively, “Dario”, “us”, “we”, “our”, or
        “Company”) and sets forth the terms and conditions governing your access
        to, and use of, Company websites (the “Site”), Company mobile
        applications (the “App”), hardware and software devices that we make
        available (“Devices”), and any services, applications, software,
        features, content, websites and/or linked pages, offered from time to
        time by Company in connection therewith, and any other product or
        service that links to these Terms (collectively, the “Services”). Please
        read these Terms carefully before using the Services. By accessing or
        using the Services, by clicking “I accept” or by installing or
        downloading the App on your mobile device, you agree to be bound by
        these Terms, as may be amended by us from time to time. If you do not
        agree to these Terms, please refrain from using the Site, App or the
        Services.
        <br />
        <br />
        THESE TERMS CONTAIN A BINDING ARBITRATION PROVISION AND CLASS ACTION
        WAIVER. Please make sure you read these Terms along with our Privacy
        Policy (the “Privacy Policy”). Your use of the App, Site or Services
        shall be deemed to constitute your consent to be legally bound by these
        Terms and the Privacy Policy, incorporated by reference herein.
        <br />
        <br />
        In the Terms, sometimes we make specific reference to DarioHealth, Dario
        powered by wayForward, or UpRight Services. In such cases, those
        references apply only to the Services specified. When we use the term
        "Services” generally, we mean all of our collective Services, including
        DarioHealth, Dario powered by wayForward and UpRight.
      </p>
      <p>
        <strong>
          <u>Changes To These Terms</u>
        </strong>
        <br />
        <br />
        We may revise and update these Terms from time to time in our sole
        discretion. All changes are effective immediately on the date that they
        are posted to the Services and/or otherwise made available through the
        Services, and apply to all access to and use of the Services thereafter.
        Your continued use of the Services following the posting of revised
        Terms means that you accept and agree to the changes. If you don’t agree
        to be bound by the updated Terms, then you may not use the Services and
        you may cancel your Account as described in these Terms.
      </p>
      <p>
        <strong>
          <u>Our Services</u>
        </strong>
        <br />
        <br />
        To use certain parts of the Services, you may be required to provide us
        with certain information, including personal information and health
        information. To provide the Services, we may apply certain proprietary
        methodologies for analysis, processing and transmission of such
        information. The collection, aggregation and analysis of such
        information may enables us and approved third party medical providers to
        provide the Services to you. For details about how we collect and use
        your information, please see our Privacy Policy.
        <br />
        <br />
        The Services include information regarding the Company as well as
        content such as contact information, videos, text, logos, images, data
        compilations, links, other specialized content, technical data,
        documentation, know-how, specifications materials, designs, data, the
        “look and feel” of the Services, algorithms, interface, GUI, interactive
        features related graphics, illustrations, drawings, animations, and
        other features obtained from or through the Services (collectively, “Our
        Content”). We reserve all rights in and to Our Content. You acknowledge
        that all of Our Content, accessed by you using the Services is at your
        own risk and you will be solely responsible for any damage or loss to
        you or any other party resulting therefrom. To the full extent permitted
        by law, we make no representations, warranties or guarantees with
        respect to any of Our Content that you access on or through the
        Services.
      </p>
      <p>
        <span>
          <strong>DarioHealth Services: </strong>
        </span>
        Our DarioHealth Services offer an innovative digital solution for people
        with chronic conditions that utilizes sophisticated devices (hardware)
        and software, personalized coaching, and individual support designed to
        increase engagement and improve clinical outcomes as members work toward
        life-long behavior change.
        <br />
        <br />
        The DarioHealth App offers health, fitness, and nutritional information,
        is designed for educational purposes only, and should not be relied upon
        for diagnostic or treatment purposes. The information provided might not
        be accurate, full, complete or effective. Accordingly it is not intended
        to be used for medical diagnosis or treatment or as a substitute for
        professional medical advice. You are highly advised to consult your
        physician or qualified health professional regarding any conditions you
        may have, and appropriate medical treatment. Individual symptoms,
        situations, and circumstances may vary.
      </p>
      <p>
        <span>
          <strong>Dario powered by wayForward Services: </strong>
        </span>
        Our Dario powered by wayForward Services assist Users with identifying
        potential emotional wellness and mental health challenges and coping
        with those challenges, including by (i) providing Users access to
        information relating to emotional wellness and mental health conditions,
        suggested behavioral treatment options, self-help tools, reminders,
        assessment reports, survey reports and related information and (ii)
        enabling users to communicate with third party psychologists, mental
        health practitioners, health coaches, and related behavioral health
        professionals (collectively, “Clinicians”). The Clinicians you
        communicate with may have privacy policies and privacy obligations that
        are different from those of DarioHealth and Dario powered by wayForward.
        Please review these policies before agreement to receive services from
        the Clinicians. As further described below, the behavioral health
        information and the Dario powered by wayForward Services are not
        medical, psychiatric, or other professional health care advice. If you
        use the Dario powered by wayForward Services to obtain health care
        advice from Clinicians, your informed consent will be required.
        <br />
        <br />
        NEITHER THE SERVICES GENERALLY NOR THE DARIO POWERED BY WAYFORWARD
        SERVICES PROVIDE MEDICAL, PSYCHIATRIC, OR OTHER PROFESSIONAL HEALTH CARE
        ADVICE; NOR ARE THEY INTENDED TO BE USED TO ASSESS HEALTH CONDITIONS OR
        SUBSTITUTE FOR PROFESSIONAL MEDICAL ADVICE, OR RELIED UPON FOR DIAGNOSIS
        OR TREATMENT. Any behavioral health information you receive via the
        Dario powered by wayForward Services is not medical advice, and is not
        reviewed or authorized by a physician or healthcare provider. Use of the
        Dario powered by wayForward Services does not, and is not intended to,
        create or constitute, a physician-patient relationship with the Company,
        its officers, directors, employees, independent contractors, agents, or
        anyone acting on behalf of the Company. Use of the Dario powered by
        wayForward Services may offer certain potential benefits (e.g.,
        automated reminders to engage in certain activities), but also presents
        certain potential risks (e.g., the behavioral health information does
        not take into account all the specifics of each user’s medical history,
        the Dario powered by wayForward Services are not able to exercise
        medical judgment, security protocols could fail, causing a breach of
        privacy of personal medical information, delays could be caused by
        malfunctioning of equipment or connections).
        <br />
        <br />
        BY USING THE DARIO POWERED BY WAYFORWARD SERVICES, YOU REPRESENT AND
        WARRANT THAT YOU HAVE HAD THE OPPORTUNITY TO CONSIDER THE RISKS AND
        BENEFITS OF THE USE OF THE SERVICES, INCLUDING THE BEHAVIORAL HEALTH
        INFORMATION, AND THAT YOU HAVE PROVIDED VOLUNTARY INFORMED CONSENT TO
        USE THE DARIO POWERED BY WAYFORWARD SERVICES AND PROVISION OF THE
        SERVICES TO YOU.
      </p>
      <p>
        <span>
          <strong>UpRight Services: </strong>
        </span>
        The UpRight App offers tracking of your posture while using our UpRight
        sensor device, or our  “GO” & Necklaces & adhesives (for purposes of
        this section, each an “<strong>Upright Device</strong>”) and enables you
        to subscribe to receive content, training plans, exercises and
        instructions, articles, questionnaires, recommendations such as videos
        with exercises and stretches, and motivational content (the “
        <strong>Subscription Service</strong>“). The UpRight Device and the
        necklace accessory or a discreet wearable electronic sensor device which
        connects through Bluetooth 4.0 to your mobile device to track your
        posture. When you are slouching or sitting not in an upright posture the
        UpRight Device gently vibrates to remind you to correct your posture. In
        order to use the UpRight Device, you must first set it to the position
        that you feel is optimal for your posture. The UpRight Services may
        provide you with a personal training program that guides you to sit
        correctly through a certain period of time, which will be made available
        via the UpRight App and/or sent to you via email communications. The
        Subscription Service will be made available via the UpRight App and/or a
        Site designated by the Company. The UpRight Services may send you
        push-notifications. You can deactivate the push-notifications at any
        time by changing the notification settings on your Upright Device.
        <br />
        <br />
        THE UPRIGHT SERVICES OFFER INFORMATION ABOUT YOUR POSTURE AND ARE
        DESIGNED AS A TOOL TO ASSIST IN IMPROVING YOUR POSTURE AND WELLBEING BUT
        ARE NOT INTENDED TO REPLACE CONSULTATION WITH A QUALIFIED HEALTHCARE
        PROFESSIONAL, AND ARE NOT INTENDED TO SERVE AS A SUBSTITUTE FOR ANY
        MEDICAL DIAGNOSIS OR TREATMENT.  YOU SHOULD ALWAYS CONSULT WITH A
        QUALIFIED PERSONAL MEDICAL ADVISOR.
      </p>
      <br />
      <p>
        <strong>
          <u>Who May Use The Services</u>
        </strong>
        <br />
        <br />
        We may, in our sole discretion, refuse to offer the Services to any
        person or entity at any time. You are solely responsible for ensuring
        that you comply with all laws, rules and regulations applicable to you
        in connection with your use of the Services. You are prohibited from
        using the Services if your use of the Services is prohibited or
        conflicts with any applicable law.
        <br />
        <br />
        Except as otherwise provided, minors between the ages of 13 and 18 may
        use the Services (or between the ages of 16 or 18 outside of the US),
        provided that (a) they and their parent/guardian agree to be bound by
        these Terms; and (b) their parent/guardian is supervising their use of
        the Services at all times. Otherwise, you must be at least 18 years old,
        or the age of legal majority in your jurisdiction of residence, to
        download, register with and/or use the Services. Additionally, to use
        Dario powered by wayForward or UpRight Services, you must be at least 18
        years old. Under no circumstances may any user solicit or submit to us
        or the Services any personal information from children under the age of
        13 (or age 16 if you are outside the US). The Services are not intended
        to be used by children under the age of 13 (or under age 16 outside the
        US).
      </p>
      <p>
        <strong>
          <u>Registration and User Accounts</u>
        </strong>
        <br />
        <br />
        You may be required to create an account in order to use certain parts
        or features of the Services (“Account”). Your Account is personal to
        you, may be used only by you, and you may only have one Account at a
        time. By creating an Account, you give us permission to contact you
        about the Services and your use thereof.
        <br />
        <br />
        You may not use a user name that is misleading or that includes any
        inappropriate language. We reserve the right to reject any user name and
        prevent the creation of an Account with any user name in our sole and
        absolute discretion. By creating an Account you agree: (i) that the
        information you provided to create your Account is accurate and
        complete, (ii) to promptly update and maintain your information with us,
        and (iii) that the information you are providing is your own or you are
        providing it on behalf of someone who authorized you to provide their
        personal information to us for the purposes of communication related to
        our Services.
        <br />
        <br />
        You acknowledge and agree that we rely on the user ID and password (the
        “Account Credentials”) to confirm whether Users accessing and using our
        Site, App or Services are authorized to do so. Maintaining the
        confidentiality of your Account Credentials is your responsibility, and
        you are responsible for any activity performed by any use of your
        Account Credentials. You must notify us immediately at
        support@mydario.com if you become aware that your Account Credentials
        have been compromised, that your Account is being used without
        authorization, or of any other breach of security related to your
        Account. You are solely responsible and solely liable for all actions
        and activities that occur in your Account using your Account
        Credentials, and for any User Content (as defined below) uploaded to
        your Account.
      </p>
      <p>
        <strong>
          <u>Consent to Communications and Text Messaging</u>
        </strong>
        <br />
        <br />
        <strong>Consent to Communications. </strong>
        By using the Services or providing your contact information to us, you
        agree that we may communicate with you, including electronically, about
        the Services and your use thereof. If you wish to unsubscribe from
        marketing communications from us, please follow the unsubscribe
        instructions at the bottom of such communication.
        <br />
        <br />
        <strong>SMS Text Messages and Push Notifications.</strong> When you
        register for the Services, we may send you an SMS text message
        containing a code in order to verify your phone number and may send
        further SMS text messages and push notifications (“Messages and
        Notifications”) in order to keep you informed about the Services. By
        using the Services, you agree to receive Messages and Notifications
        regarding your use of the Services. SMS text messages are for
        transactional purposes only. We will obtain your consent if we wish to
        send you marketing or promotional SMS text messages. Reply “STOP” to
        stop receiving SMS text messages. We may, however, continue to send you
        transactional messages such as tracking information or verification
        codes if you subscribe to additional products and provide your phone
        number related to those later transactions. Depending on your current
        carrier plan, you may incur charges for these Messages and Notifications
        and agree that we are not responsible for any failure of warranty by any
        such third party. We cannot control certain factors relating to message
        delivery. You acknowledge that, depending on your mobile carrier’s
        service, it may not be possible to transmit a text message to you
        successfully. We have no liability for transmission delays or message
        failures.
      </p>
      <p>
        <strong>
          <u>User Generated Content</u>
        </strong>
        <br />
        <br />
        You may be permitted to input, provide, or otherwise make available
        certain information in conjunction with any Device or submit, share,
        upload, or otherwise make available certain information, messages
        (including “Messages” defined below), comments, posts, text,
        photographs, data and other materials to the Services (collectively, the
        “User Content”). You represent and warrant that you own or have (and
        will continue to have) all the necessary licenses, rights, consents, and
        permissions to use and provide such User Content to us and that such
        User Content, and our use of such User Content, does not infringe any
        third party’s intellectual property, privacy, publicity or other rights.
        Your further represent and warrant that we will not need to obtain
        licenses from any third party or pay royalties to any third party in
        order to use such User Content. You grant to us a worldwide, perpetual,
        irrevocable, non-exclusive, sublicensable (through multiple tiers),
        transferable, royalty-free license and right to use, copy, transmit,
        distribute, publicly perform and display (through all media now known or
        later created), edit, modify, and make derivative works from your User
        Content (including, without limitation, translations) for any purpose
        whatsoever, commercial or otherwise, without compensation to you, to the
        extent permitted by applicable law. In addition, you waive any so-called
        “moral rights” or rights of privacy or publicity in your User Content.
        You shall have the sole responsibility for the legitimacy, accuracy and
        legality of any User Content you submit to us.
        <br />
        <br />
        You represent and warrant that your User Content is complete and
        accurate and accurately reflects your use of our Services. We shall have
        no liability for any loss, damage, cost or expense that you or any third
        party may suffer or incur as a result of, or in connection with,
        uploading any User Content. You agree that you will not post or upload
        any User Content that: (i) infringes, misappropriates or violates a
        third party’s patent, copyright, trademark, trade secret, moral rights
        or other intellectual property rights, or rights of publicity or
        privacy; (ii) violates, or encourages any conduct that would violate,
        any applicable law or regulation or would give rise to civil liability;
        (iii) is fraudulent, false, misleading or deceptive; (iv) is defamatory,
        obscene, pornographic, vulgar or offensive; (v) promotes discrimination,
        bigotry, racism, hatred, harassment or harm against any individual or
        group; (vi) is violent or threatening or promotes violence or actions
        that are threatening to any person, animal, or entity; (vii) exploits
        minors or (viii) promotes illegal or harmful activities or substances.
        We have no obligation to screen, edit or monitor any of your User
        Content, and we explicitly reserve the right to remove and/or edit any
        User Content on the Services at any time without notice, and for any
        reason, and you are solely responsible for creating backup copies of
        your User Content at your sole expense. We may, at our sole discretion,
        terminate Accounts or remove User Content from the Services if we
        determine, or suspect, that those Accounts or User Content violate these
        Terms, any law, or the rights of any third party.
      </p>
      <p>
        <strong>
          <u>Use Restrictions</u>
        </strong>
        <br />
        For purposes of these Terms, “Content” means all text, graphics, images,
        music, software, audio, video, works of authorship of any kind, and
        information or other materials that are posted, generated, provided or
        otherwise made available through the Services. The Services includes all
        Content.
        <br />
        <br />
        You acknowledge and agree that you may not (a) make available or use the
        Services for the benefit of any third party; (b) sell, resell, license,
        sublicense, distribute, make available, rent or lease the Services, or
        exploit the Services for any commercial purposes; (c) use any Services,
        or any Third Party Services (as defined below), to store or transmit any
        illegal, immoral, unlawful and/or unauthorized materials or interfere
        with or violate a third party’s rights to privacy and other rights, or
        harvest or collect personally identifiable information about third
        parties without their express consent; (d) use the Services, or any
        Third Party Services (as defined below), to transmit or otherwise make
        available any malicious code, including any virus, worm, trojan horse,
        time bomb, web bug, spyware, or any other computer code, file, or
        program; (e) interfere with or disrupt the integrity, performance or
        operation of the Services, or any part thereof; (f) attempt to gain
        unauthorized access or bypass any measures imposed to prevent or
        restrict access to the Services; (g) use or take any direct or indirect
        action that imposes or circumvents any usage limits; (h) copy, modify,
        distribute, create derivative works, translate, port, reverse engineer,
        decompile, or disassemble any portion of the Services, or any material
        that is subject to our proprietary rights or use any of the foregoing to
        create any software or service similar to the Services; (i) use any User
        Content of any user or other third party appearing on or through the
        Services, without our prior written consent; and (j) misrepresent or
        impersonate any person or provide inaccurate User Content. Any breach of
        the Terms by you, as shall be determined in our sole discretion, may
        result in the immediate suspension or termination of your Account, with
        full reservation of all of our rights and remedies.
      </p>
      <p>
        <strong>
          <u>Fees</u>
        </strong>
        <br />
        <br />
        We will provide information on our then-current fees for the Services on
        the Sites and/or by other means through the Services. Features and
        prices are subject to change. If you purchase a subscription to use the
        Services, unless otherwise stated, your subscription shall automatically
        renew at the end of your subscription term for the same number of months
        as the expiring subscription term, unless cancelled by you in advance as
        described below.
        <br />
        <br />
        You may cancel your subscription at any time within your account
        settings or by contacting us using the contact information provided in
        these Terms. Your cancellation will take effect immediately or on a date
        requested by you, and we shall refund a pro-rated portion of any
        subscription fees pre-paid by you for any unused portion of your
        then-current subscription term.
        <br />
        <br />
        In connection with your download, use of, and/or subscription to the
        Services, you agree to pay all applicable fee(s), taxes and other
        charges. You may be asked to provide a payment method (such as a credit,
        charge or debit card number) in order to activate your account or
        subscription, along with other payment information, to one of our third
        party payment processor(s) as described in more detail in the
        <a href="https://mydario.com/privacy-policy/" target="_blank">
          {' '}
          Privacy Policy
        </a>
        . You authorize us and our payment processor to charge your payment
        method for your subscription to the Services, including any recurring
        charges for automatic renewal of your subscription, and for any other
        products or services you may purchase from us.
      </p>
      <p>
        <strong>
          <u>Membership Program Terms</u>
        </strong>
        <br />
        <br />
        We may provide various membership programs as specified on our shop for
        independent consumer purchase. No insurance coverage is necessary, and
        no prescriptions or co-pay programs are required in order to become a
        member. Additional terms may apply.
        <br />
        <br />
        In some cases, memberships are offered through specific contracted
        employer groups and health plan entities. Qualified participants are
        notified of eligibility and may enroll as long as deemed eligible from
        said employer group or health plan. Membership continues until you
        cancel or are no longer affiliated with the employer or health plan
        under which you became eligible. Associated costs under this membership
        are paid directly by the employer group or health plan. You are entitled
        to participate in and receive a Device, App, or Service for whichever
        solution(s) outlined in the contract between us and your employer group
        or health plan. You can get more information about your eligibility by
        contacting your employer group or health plan.
        <br />
        <br />
        Throughout the term of your membership in any of our diabetes offerings,
        glucometer test strips will be shipped based on your frequency of use
        (please note that our algorithm determines this, and we do not warrant
        that such algorithm is error-free or has no vulnerabilities.)
        <br />
        In the event that you need test strips sooner, you may contact our
        support team by calling us at 1-800-895-5921, Monday through Friday
        9AM-9PM Eastern Standard Time.
        <br />
        <br />
        An independently purchased membership will renew automatically upon the
        end of the membership term. If you wish to cancel your membership,
        please visit our
        <a href="https://mydario.com/return-policy/" target="_blank">
          {' '}
          Refund and Cancellation Policy.
        </a>
        <br />
        If we determine, in our sole discretion, that you are using Company
        products or Services in a misleading, fraudulent or deceptive manner,
        you hereby agree and acknowledge that we may suspend or cancel your
        membership at any time.
      </p>
      <p>
        <strong>
          <u>
            Membership Loyalty Plan Terms – for DarioHealth Glucometer Users
            Only
          </u>
        </strong>
        <br />
        <br />
        This section applies to Membership Loyalty Plan (the “Plan”)
        Participants (“Participants”). The Plan is offered by us at our sole
        discretion. Information about the program is posted on the Site and App
        or may be obtained by calling 1-800-895-5921. All new Participants in
        the Plan must accept its terms before they may participate. Except as
        otherwise expressly prohibited or limited by applicable laws, we may, at
        any time and with or without notice, modify, amend, supplement, or
        terminate the Plan and/or its terms. Any such modification, amendment or
        supplement will be posted on our 
        <a href="https://www.mydario.com/" target="_blank">
          Site
        </a>
         and App. Participants should regularly check the Site and App for any
        modifications, amendments, or supplements to the Plan and/or its terms.
        Should the Plan be terminated, all unredeemed Healthies (our rewards
        program points) will be forfeited without any obligation or liability of
        Dario, and no Healthies will be honored after the conclusion of the
        termination notice period, if such a period is announced.
        <br />
        <br />
        If Dario determines that a Participant has abused any of the Plan’s
        privileges, failed to comply with any of the Terms, or has made any
        misrepresentation to Dario, Dario may, in its sole discretion, take such
        actions as it deems appropriate, including without limitation,
        suspending such Participant’s privileges under the Plan, revoking any or
        all of the Healthies in such Participant’s account, and/or revoking the
        Participant’s participation in the Plan, in each case, with or without
        advance notice to the Participant, and without liability to Dario.
        <br />
        <br />
        Throughout the term of your membership in any of our diabetes offerings,
        glucometer test strips will be shipped based on your frequency of use
        (please note that our algorithm determines this, and we do not warrant
        that such algorithm is error-free or has no vulnerabilities.)
        <br />
        <br />
        In the event that you need test strips sooner, you may contact our
        support team by calling us at 1-800-895-5921, Monday through Friday
        9AM-5PM Eastern Standard Time.
        <br />
        <br />
        <strong>Earning Healthies.</strong> Details on how Participants in the
        Plan can earn and redeem Healthies is described on the Site and App, via
        ‘My Profile’.
        <br />
        <br />
        <strong>Use of Healthies.</strong> Plan Participants may redeem their
        Healthies against vouchers that may be used to obtain certain products
        available on the App, Participants will receive vouchers at their
        registered email address with a link that may be used to obtain products
        through the Dario Shop. Participants retain any Healthies earned while
        in membership status, even if they subsequently cancel their membership
        plan. Upon redemption of Healthies, the Healthies will immediately be
        deducted from the Participant’s account. Once Healthies are redeemed,
        they cannot be credited back to a Participant’s account. Healthies have
        no fixed value, may not be redeemed for cash, and are not transferable
        to any other individual or entity. Any transfer or attempt to transfer
        Healthies may result in automatic revocation of the Participant’s
        Healthies. Dario will not compensate or pay cash for any forfeited,
        revoked, or unused Healthies.
        <br />
        <br />
        Vouchers expire one [1] year from the date the voucher is issued. All
        vouchers must be used by their expiration date to be honored by us.
        <br />
        <br />
        Participants may not make any commercial use of products purchased
        through the Plan.
        <br />
        <br />
        <strong>No Refunds.</strong> Products purchased in whole or part using
        vouchers, and vouchers obtained with Healthies are non-refundable.
        <br />
        <br />
        <strong>Monitoring Participants’ Accounts.</strong> Dario reserves the
        right to monitor the Healthies accounts of all Participants, at any time
        and without notice, for compliance with terms of the Plan. Dario also
        may review all Participants’ Healthies balances and transaction history.
        <br />
        <br />
        <strong>Taxes.</strong> The Plan is offered to Dario customers free of
        charge. Healthies may be subject to income or other taxes. The
        Participant is responsible for paying all such taxes and for making all
        applicable disclosures to third parties. Dario will not be liable for
        any tax liability, duty or other charges in connection with the issuance
        of Healthies.
        <br />
        <br />
        <strong>Not Responsible for Acts, Errors, or Omissions.</strong> Dario
        is not responsible for: (a) any loss or misdirection of, or delay in
        receiving, any Participant’s application or Participant’s acceptance of
        Plan terms or conditions, correspondence, redemption requests or
        Healthies; (b) theft or unauthorized redemption of Healthies; (c) any
        acts or omissions of third parties; or (d) any errors in published
        materials involving the Plan, including, without limitation, any product
        pricing or typographical errors, errors of description, errors in the
        redemption and crediting of Healthies from a Participant’s account, and
        errors in the issuance of vouchers. Without derogation of the foregoing,
        Dario reserves the right to correct any such errors.
        <br />
        <br />
        Please contact our support team at any time by calling us at
        1-800-895-5921, if you have any questions about the Plan.
      </p>
      <p>
        <strong>
          <u>Third Party Services and Social Media</u>
        </strong>
        <br />
        <br />
        We or third parties may offer you third party services as part of the
        Services (the “Third Party Services”), including but not limited to
        third party coaching services. Your use of the Third Party Services is
        subject to such terms and conditions and specifications offered by each
        provider of Third Party Services. You acknowledge that we are not the
        provider of any Third Party Services, nor are we responsible for or
        endorse any Third Party Services. All Third Party Services are made
        available “as is” and we make no representation or warranty with respect
        to them, and we are not responsible for any loss, damage, injury or
        other matters or any sort incurred as a result of your use of Third
        Party Services and your interactions and dealings with their providers.
        <br />
        <br />
        When we make available to our users certain social media features
        through Facebook, Twitter, Instagram, or other social media sites, you
        may take such actions as are enabled by those features. Please be aware
        that your activities on social media sites, or facilitated by or through
        social media sites, are subject to the terms and conditions of the
        applicable social media site(s). Any Content (including your User
        Content) provided to social media sites may be processed and used by the
        applicable social media sites in accordance with their policies and any
        agreements with us.
      </p>
      <p>
        <strong>
          <u>Termination or Suspension</u>
        </strong>
        <br />
        <br />
        You may stop using the Services at any time. You may also delete your
        Account at any time. To delete your Account please contact us at:
        <a href="mailto:support@mydario.com" target="_blank">
          {' '}
          support@mydario.com
        </a>
        . We may verify your request before taking action.
        <br />
        <br />
        If you cancel or delete your Account, we have no obligation to keep or
        retain any data or information, including User Content, in your Account,
        and such data and information, including User Content, may be deleted.
        We have no liability for the loss of any User Content or data or
        information in Account when you delete your Account.
        <br />
        <br />
        Termination of your Account or relationship with us shall not relieve
        you of your obligations to pay fees or amounts accrued or owing, nor
        affect any legal rights or obligations which may have arisen under these
        Terms prior to, or at, the date of termination. We may terminate, limit
        or suspend your access to all or any part of your Account, or our
        Services at any time, with or without cause, or with or without prior
        notice, effective immediately, and such termination may result in the
        deletion of all information and data, including User Content, associated
        with your Account and your use of the Services. Upon termination of your
        Account: (i) all rights granted to you hereunder will automatically
        terminate, and (ii) you must immediately cease all use of the Services.
        All Sections of these Terms which by their nature are meant to survive,
        shall survive termination of these Terms.
      </p>
      <p>
        <strong>
          <u>No Medical Advice</u>
        </strong>
        <br />
        <br />
        DARIO IS NOT A HEALTH CARE PROVIDER AND CANNOT AND DOES NOT DIAGNOSE OR
        TREAT YOUR HEALTH CONDITIONS. WE PROVIDE NO ENDORSEMENT, REPRESENTATION
        OR WARRANTY THAT ANY PARTICULAR MEDICATION OR TREATMENT IS OR WILL BE
        EFFECTIVE OR RIGHT FOR YOU. The Services, including, but not limited to,
        all Content obtained from the Services and its licensors, are not
        intended to be used or viewed as providing medical advice, or as a
        substitute for consultation with a qualified healthcare provider. The
        information provided by the Services cannot be the basis for diagnosis
        of any medical condition or therapy. You are advised to ALWAYS SEEK THE
        ADVICE OF YOUR DOCTOR OR OTHER QUALIFIED HEALTHCARE PROVIDER REGARDING
        ANY MEDICAL CONDITION AND BEFORE STARTING ANY NEW TREATMENT OR ALTERING
        YOUR EXISTING TREATMENT. THE SERVICES ARE NOT INTENDED AS A SUBSTITUTE
        FOR PROFESSIONAL ADVICE FROM A QUALIFIED HEALTHCARE PROVIDER FAMILIAR
        WITH YOUR UNIQUE FACTS AND MEDICAL HISTORY. NEVER DISREGARD PROFESSIONAL
        MEDICAL ADVICE OR DELAY IN SEEKING IT BECAUSE OF SOMETHING YOU READ OR
        LEARNED FROM THE SERVICES.
        <strong>
          {' '}
          IF YOU ARE EXPERIENCING A MEDICAL EMERGENCY, YOU SHOULD DIAL “911” OR
          YOUR LOCAL EMERGENCY SERVICES NUMBER IMMEDIATELY.
        </strong>
        <br />
        <br />
        Materials and Content provided as part of the Services are intended to
        assist you in understanding your health. Reliance on any information
        provided through the Services is solely at your own risk. We assume no
        liability or responsibility for damage or injury to persons or property
        arising from any use of any materials, Content, product, information,
        ideas, or advice contained in the Services.
        <br />
        <br />
        You further acknowledge that certain services provided through the
        Services, including any notifications, depend on information that you
        input into the Services. We do not recommend or endorse any drug or
        product, and notifications are not guidance regarding dosing
        information. The provision of notifications, reminders or alerts with
        respect to any drug or product is not a recommendation or endorsement.
        The absence of a notification, reminder, warning and/or alert does not,
        and should not, be construed to indicate that any activities, food,
        drugs or other health recommendations are appropriate or effective.
      </p>
      <p>
        <strong>
          <u>Disclaimer</u>
        </strong>
        <br />
        <br />
        THE SERVICES AND OUR CONTENT ARE PROVIDED ON AN “AS IS” BASIS. WITHOUT
        LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ALL WARRANTIES, EXPRESS
        OR IMPLIED, REGARDING ANY DEVICE, SITE, APP, SERVICES AND OUR CONTENT,
        INCLUDING ANY WARRANTIES OF QUALITY, AVAILABILITY, MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. WE MAKE NO, AND
        DISCLAIM ANY, WARRANTY THAT ANY DEVICE, SITE, APP, SERVICES OR OUR
        CONTENT WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED,
        SECURE, OR ERROR-FREE BASIS. WE MAKE NO, AND DISCLAIM ANY, WARRANTY
        REGARDING THE QUALITY OF ANY DEVICE, SITE, APP, SERVICES OR OUR CONTENT,
        OR TO THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR
        RELIABILITY THEREOF. THE ABOVE SHALL ALSO APPLY TO ANY INFORMATION YOU
        MAY RECEIVE FROM US OR THROUGH US AT ANY TIME. YOU HEREBY RELEASE US
        FROM ANY DAMAGES, CLAIMS OR OTHER CAUSES OF ACTION RELATED IN ANY WAY,
        DIRECTLY OR INDIRECTLY, TO ANY DEVICE, SITE, APP, SERVICES OR OUR
        CONTENT.
        <br />
        <br />
        WITHOUT LIMITING THE FOREGOING, WE FURTHER DISCLAIM ANY WARRANTIES THAT
        ANY DEVICE, SITE, APP AND/OR SERVICES ARE FREE OF VIRUSES OR OTHER
        HARMFUL COMPONENTS. YOUR USE OF ANY DEVICE, SITE, APP AND SERVICES IS AT
        YOUR OWN RISK, AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
        COMPUTER OR MOBILE DEVICE AND/OR ANY DATA LOSS THAT MAY RESULT FROM YOUR
        USE OF ANY DEVICE, SITE, APP OR SERVICES, INCLUDING WITHOUT LIMITATION,
        DAMAGE RESULTING FROM COMPUTER VIRUSES. NO COMMUNICATION, INFORMATION OR
        ADVICE GIVEN BY US OR ANY REPRESENTATIVE OF OURS SHALL CREATE ANY
        WARRANTY. PLEASE NOTE THAT SOME JURISDICTIONS MAY NOT ALLOW THE
        EXCLUSION OF IMPLIED WARRANTIES, AND THEREFORE SOME OF THE ABOVE
        EXCLUSIONS MAY NOT APPLY TO YOU. THIS DISCLAIMER CONSTITUTES AN
        ESSENTIAL PART OF THESE TERMS.
        <br />
        <br />
        NO CONTENT PUBLISHED ON ANY DEVICE, SITE OR APP, OR THROUGH THE
        SERVICES, CONSTITUTES A RECOMMENDATION, ENDORSEMENT OR OPINION OF
        COMPANY. YOU ARE SOLELY AND EXCLUSIVELY RESPONSIBLE FOR ALL YOUR
        COMMUNICATIONS AND INTERACTIONS WITH US OR WITH ANY PERSON OR ENTITY
        WITH WHOM YOU MAY COMMUNICATE, INTERACT OR ENGAGE AS A RESULT OF YOUR
        USE OF ANY DEVICE, SITE, APP AND/OR SERVICES. YOU AGREE AND CONFIRM THAT
        WE DO NOT, AND DO NOT INTEND TO, MAKE ANY INQUIRIES OF ANY USER, PERSON
        OR ENTITY OR VERIFY THE INFORMATION ANY USER, PERSON AND/OR ENTITY
        SUBMITS OR PROVIDES WHILE USING ANY DEVICE, SITE, APP AND/OR SERVICES.
        YOU AGREE TO TAKE PRECAUTIONS IN ALL COMMUNICATIONS AND INTERACTIONS
        WITH ANY PERSON OR ENTITY WHETHER MADE ON-LINE OR OFF-LINE.
        <br />
        <br />
        BY USING THE SERVICES, YOU REPRESENT AND WARRANT THAT YOU HAVE HAD THE
        OPPORTUNITY TO CONSIDER THE RISKS AND BENEFITS OF THE USE OF THE
        SERVICES, AND THAT YOU HAVE PROVIDED VOLUNTARY INFORMED CONSENT TO USE
        THE SERVICES AND PROVISION OF THE SERVICES TO YOU.
      </p>
      <p>
        <strong>
          <u>Limitation of Liability</u>
        </strong>
        <br />
        In the event of any problem with the Services and/or any Content, you
        agree that your sole remedy is to cease using the Services. In no event
        shall we or our subsidiaries, affiliates, directors, officers, employees
        or any party involved in creating or producing the Services be liable to
        you for (a) any amount exceeding the fees paid by you to us during the
        twelve (12) months preceding the occurrence of events giving rise to
        your claim, or $100 if no fees have been paid by you to us, or (B) any
        special, indirect, incidental, consequential, punitive or exemplary
        damages, or any other damages whatsoever, resulting from the use of the
        Services (or with the delay or inability to use the Services) or any
        Content or otherwise arising out of using the Services and Content,
        whether under a theory of breach of contract, tort, strict liability,
        negligence, or otherwise, even if such party has been advised of the
        possibility of such damages. If you live in a jurisdiction that does not
        allow the exclusion or limitation of liability for consequential or
        similar damages, the above limitation does not apply to you.
        <br />
        <br /> To the extent that one or any aspect of limitations set out above
        does not apply, all remaining aspects survive. The exclusions and
        limitations of damages set forth above are fundamental elements of the
        basis of the bargain between us and you.
      </p>
      <p>
        <strong>
          <u>Indemnity</u>
        </strong>
        <br />
        You agree to defend, indemnify, and hold harmless Company, its
        affiliates and each of its affiliates employees, contractors, directors,
        suppliers and representatives from all liabilities, losses, claims, and
        expenses, including, without limitation, reasonable attorney’s fees,
        that arise from or relate to (i) your access, use or misuse of the
        Services, including, without limitation, with respect to your User
        Content and any other information you submit, post or transmit through
        the Services, (ii) your violation of these Terms or any applicable law,
        contract, policy, regulation or other obligation, or (iii) your
        violation of any third party rights, including without limitation any
        intellectual property rights or privacy right of such third party with
        respect to your use of the Services. Company reserves the right to
        assume the exclusive defense and control of any matter otherwise subject
        to indemnification by you, in which event you will assist and fully
        cooperate with Company in connection therewith.
      </p>
      <p>
        <strong>
          <u>Intellectual Property</u>
        </strong>
        <br />
        <br />
        Subject to the limited rights expressly granted hereunder, we and our
        licensors and third party providers, as may be applicable, reserve all
        rights, title and interest in and to the Services and Our Content,
        including all related intellectual property rights therein. No rights
        are granted to you hereunder other than as expressly set forth herein.
        Nothing in the Terms constitutes a waiver of the Company’s intellectual
        property rights under any law.
      </p>
      <p>
        <strong>
          <u>Feedback and Suggestions</u>
        </strong>
        <br />
        <br />
        Subject to any applicable terms and conditions set forth in our
        <a href="https://mydario.com/privacy-policy/" target="_blank">
          {' '}
          Privacy Policy
        </a>
        , any communication or other material that you send to us, such as any
        questions, comments, feedback, suggestions, testimonials, or the like,
        is and will be deemed to be non-confidential and we shall have no
        obligation of any kind with respect to such information. We shall be
        free to use any ideas, concepts, know-how or techniques contained in
        such communication for any purpose whatsoever, including but not limited
        to, operating, developing, and improving the Services. As further
        described herein, you are solely responsible for all information you
        send to us, including, without limitation, its accuracy, truthfulness
        and non-infringement of any other party’s legal rights.
      </p>
      <p>
        <strong>
          <u>Trademarks and Trade Name</u>
        </strong>
        <br />
        “Labstyle”, “DarioHealth”, “Dario”, “MyDario”, “mydario.com”,
        “dariohealth.com”, “UpRight ”, “Dario powered by wayForward”, and any
        associated names, marks and logos and all proprietary identifiers used
        by us in connection with any Device, Site, App and/or Service are our
        trademarks, service marks and trade names of Company, whether or not
        registered. No right, license, or interest to such marks is granted
        hereunder. Other names that are mentioned on any Device, Site and/or App
        or provided as part of the Services may be trademarks of their
        respective owners. We reserve all rights that are not expressly granted
        to you hereunder.
      </p>
      <p>
        <strong>
          <u>App Usage Rules</u>
        </strong>
        <br />
        <br />
        If you are downloading the App from a third-party platform, service
        provider or distributor (“Provider”) your use of the App may also be
        governed by usage rules which the Provider may have established, and
        which relate to your use of the App (“Usage Rules”). In addition to the
        Usage Rules specified below, it is your responsibility to determine what
        other Usages Rules are applicable to your use of the App. You undertake
        to comply with all Usage Rules applicable to your use of the App and
        these are incorporated herein by reference.
        <br />
        <br />
        <strong>Apple Inc.</strong> The following applies to you if you
        downloaded the App from the Apple App Store (“App Store Sourced
        Application”): You acknowledge and agree that (i) these Terms are
        concluded between you and Company only, and not Apple; and (ii) Company,
        not Apple, is solely responsible for the App Store Sourced Application
        and content thereof. Your use of the App Store Sourced Application must
        comply with the App Store Terms of Services. You acknowledge that Apple
        has no obligation whatsoever to furnish any maintenance and support
        services with respect to the App Store Sourced Application. You
        acknowledge that, to the maximum extent permitted by applicable law,
        Apple has no other warranty or obligation whatsoever with respect to the
        Application and all other claims, losses, liabilities, damages, costs or
        expenses attributable to any failure to conform to any warranty shall be
        the sole responsibility of Company, to the extent applicable. As between
        Company and Apple, any other claims, losses, liabilities, damages, costs
        or expenses attributable to any failure to conform to any warranty will
        be the sole responsibility of Company. You acknowledge that (i) you are
        not located in a country that is subject to a U.S. Government embargo,
        or that has been designated by the U.S. Government as a “terrorist
        supporting” country; and (ii) you are not listed on any U.S. Government
        list of prohibited or restricted parties. You and Company acknowledge
        that, as between Company and Apple, Apple is not responsible for
        addressing any claims you have or any claims of any third party relating
        to the App Store Sourced Application or your possession and use of the
        App Store Sourced Application, including, but not limited to: (i)
        product liability claims; (ii) any claim that the App Store Sourced
        Application fails to conform to any applicable legal or regulatory
        requirement; and (iii) claims arising under consumer protection or
        similar legislation. You and Company acknowledge that, in the event of
        any third-party claim that the App Store Sourced Application or your
        possession and use of that App Store Sourced Application infringes that
        third party’s intellectual property rights, as between Company and
        Apple, Company, not Apple, will be solely responsible for the
        investigation, defense, settlement and discharge of any such
        intellectual property infringement claim to the extent required by these
        Terms. You and Company acknowledge and agree that Apple, and Apple’s
        subsidiaries, are third party beneficiaries of these Terms as related to
        your license of the App Store Sourced Application, and that, upon your
        acceptance of the Terms, Apple will have the right (and will be deemed
        to have accepted the right) to enforce these Terms as related to your
        license of the App Store Sourced Application against you as a
        third-party beneficiary thereof. Without limiting these Terms, you must
        comply with all applicable third party terms of agreement when using the
        App Store Sourced Application.
      </p>
      <p>
        <strong>
          <u>Changes to the Services</u>
        </strong>
        <br />
        We reserve the right to modify, correct, amend, enhance, improve, make
        any other changes to, or discontinue, temporarily or permanently the
        Services or any part thereof, without notice, at any time. You agree
        that your continued use of the Services following such modifications
        constitutes your acceptance of such modifications. We shall not be
        liable to you or to any third party for any modification, suspension, or
        discontinuance of the Services and/or Our Content included therein.
        <br />
        <br />
        If we supply to you any updates, upgrades and any new versions of the
        App according to its then current policies, it may include automatic
        updating or upgrading of the App with or without any additional notice
        to you and these Terms will govern any such updates unless these are
        accompanied by a separate license agreement which will prevail, and all
        references herein to the App shall include such updates. For clarity,
        Company has no obligation to provide updates.
        <br />
        <br />
        Status and announcements for the DarioHealth infrastructure are
        available through the following link: 
        <a href="https://status.mydario.com" target="_blank">
          https://status.mydario.com
        </a>
        <br />
        <br />
        2. Security
        <br />
        DarioHealth Corp. uses commercially reasonable physical, technical and
        administrative security measures and safeguards to protect the Services.
        However, since the Internet is not a 100% secure environment, we cannot
        guarantee, ensure, or warrant the security of the Services or of any
        User Content or information you transmit to us.
        <br />
        <br />
        3. Planned maintenance window
        <br />
        Planned maintenance to the Services, if required, will typically happen
        during the standard weekly maintenance windows on Sunday 2:00-9:00 EST,
        or at other times as determined by DarioHealth. Certain Services may be
        temporary not available during maintenance. The Dario App can work
        “offline” and will not be affected from such maintenance.
      </p>
      <p>
        <strong>
          <u>
            ARBITRATION CLAUSE & CLASS ACTION WAIVER – APPLICABLE TO THE FULLEST
            EXTENT PERMITTED BY LAW
          </u>
        </strong>
        <br />
        <br />
        <strong>Mandatory Arbitration of Disputes.</strong> Any dispute, claim
        or controversy arising out of or relating to these Terms or the breach,
        termination, enforcement, interpretation or validity thereof, including
        the determination of the scope or applicability of this agreement to
        arbitrate, shall be determined by arbitration in New York, New York,
        United States of America, before one arbitrator. If the dispute, claim
        or controversy exceeds $250,000, the arbitration shall be administered
        by JAMS pursuant to its Comprehensive Arbitration Rules and Procedures
        (the “Rules”), and if not, the arbitration shall be administered by JAMS
        pursuant to the Rules and in accordance with the Expedited Procedures or
        similar process set forth in the Rules. Judgment on the award may be
        entered in any court having jurisdiction. This clause shall not preclude
        parties from seeking provisional remedies in aid of arbitration from a
        court of appropriate jurisdiction.
        <br />
        <br />
        <strong>Attorney’s Fees.</strong> In any arbitration arising out of or
        related to these Terms, the arbitrator shall award to the prevailing
        party, if any, the costs and attorneys’ fees reasonably incurred by the
        prevailing party in connection with the arbitration. If the arbitrator
        determines a party to be the prevailing party under circumstances where
        the prevailing party won on some but not all of the claims and
        counterclaims, the arbitrator may award the prevailing party an
        appropriate percentage of the costs and attorneys’ fees reasonably
        incurred by the prevailing party in connection with the arbitration.
        <br />
        <br />
        <strong>Class Action Waiver.</strong> YOU AND DARIO AGREE THAT EACH OF
        US MAY BRING CLAIMS AGAINST THE OTHER ONLY IN AN INDIVIDUAL CAPACITY,
        AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
        REPRESENTATIVE PROCEEDING. Further, if the parties’ dispute is resolved
        through arbitration, the arbitrator may not consolidate another person’s
        claims with your claims and may not otherwise preside over any form of a
        representative or class proceeding.
      </p>
      <p>
        <strong>
          <u>Governing Law And Jurisdiction</u>
        </strong>
        <br />
        These Terms shall be governed by the laws of the State of New York,
        United States of America, without regard to principles of conflicts of
        law. The Uniform Commercial Code, the Uniform Computer Information
        Transaction Act, and the United Nations Convention of Controls for
        International Sale of Goods shall not apply.
        <br />
        <br />
        Exclusive jurisdiction for all disputes that are not required to be
        arbitrated will be the state and federal courts located in New York, New
        York, United States of America, and you consent to the jurisdiction of
        those courts.
      </p>
      <p>
        <strong>
          <u>Notice To International Users</u>
        </strong>
        <br />
        The Services are operated from the United States and international users
        of the Services agree to be subject to applicable laws in the United
        States as set forth in the GOVERNING LAW AND JURISDICTION section above.
        Please note that other countries may have laws and regulatory
        requirements that differ from those in the United States, and if you
        access the Services from outside the United States, you do so on your
        own initiative and are responsible for compliance with all local laws.
      </p>
      <p>
        <strong>
          <u>General Terms</u>
        </strong>
        <br />
        These Terms do not create any partnership, employment, agency or other
        relationship not herein specifically and explicitly stated. These Terms
        are not intended to, and shall not, be construed to give any third party
        any interest or rights (including, without limitation, any third party
        beneficiary rights) with respect to, or in connection with, any
        agreement or provision contained herein or contemplated hereby. We may
        assign this Agreement in whole or in part at any time without notice.
        You may not assign your rights under these Terms, in whole or in part,
        to any third party without our prior written consent, and any attempt by
        you to do so shall be null and void. Should any part of these Terms be
        held invalid or unenforceable, that portion will be construed consistent
        with applicable law and the remaining portions will remain in full force
        and effect. Failure by us to enforce any provision of these Terms will
        not be considered a waiver of the right to enforce such provision. Our
        rights hereunder will survive any termination or expiration of these
        Terms. Unless a specific agreement is signed by and between you and us,
        these Terms constitute the entire and only agreement between you and us,
        and supersede all prior or contemporaneous agreements, representations,
        warranties and understandings that relate to the subject matter hereof.
        In any case where a specific agreement is signed by you and us, its
        provisions shall supersede the provisions of these Terms.
      </p>
      <p>
        <strong>
          <u>Force Majeure</u>
        </strong>
        <br />
        <br />
        Neither you nor us shall be liable for any failure or delay in
        performance under these Terms (other than for delay in the payment of
        money due and payable in accordance with these Terms) for causes beyond
        the party’s reasonable control and not caused by that party’s fault, or
        negligence, including, but not limited to, “acts of God”, acts of
        government, flood, fire, civil unrest, acts of terror, pandemics and
        epidemics, strikes or other labor problems, computer attacks or
        malicious acts, such as attacks on or through the internet, any internet
        service provider, telecommunications or hosting facility.
      </p>
      <p>
        Contact Us
        <br />
        <br />
        If you have questions regarding our Terms, please contact us at:
        info@mydario.com
      </p>
      <p className="text-para"> Last updated December 10th, 2021 </p>
      <p>All rights reserved, DarioHealth Corp.</p>
    </div>
  </ContentContainer>
);

export default withAuth(Terms);
