import React, { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';

import { OutlineButton } from 'components/ui/Buttons';
import Lang from 'components/Lang';

import styles from './Replies.module.scss';

const { buttonStyle, labelStyle, alertLabelStyle } = styles;

const Replies = ({ replies, handleClick }) =>
  !isEmpty(replies)
    ? replies.map((reply, index) => (
        <Fragment key={index}>
          <OutlineButton
            testId={`reply-option-${index}`}
            className={buttonStyle}
            onClick={() => handleClick(reply)}
          >
            {reply[0].startsWith('<i')
              ? reply[0].slice(reply[0].indexOf('i>') + 2).trim()
              : reply[0]}
          </OutlineButton>
          {reply[3] &&
            (reply[1] === 'topics' || reply[1] === 'dl_health_insur' ? (
              <div className={alertLabelStyle}>
                <Lang path="workLifeRequest.alertText" />
              </div>
            ) : (
              <div className={labelStyle}>{reply[3]}</div>
            ))}
        </Fragment>
      ))
    : null;

export default Replies;
