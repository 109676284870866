/**
 *
 * Videotherapy
 *
 */

import React from 'react';
import { connect } from 'react-redux';
// components
import analytics from 'utils/analytics/analytics';
import bigCardImg from 'assets/img/dashboard/actionplan/video.jpg';
import smallCardImg from 'assets/img/dashboard/actionplan/videosmall.png';
import RequestAppointment from '../../popups/RequestAppoinment';
import {
  LoaderSmall,
  LoaderLarge
} from '../ActionPlan/components/ActionPlanCardSkeleton';
import ActionPlanCardBig from '../ActionPlanCardBig';
import ActionPlanCardSmall from '../ActionPlanCardSmall';
import Lang from '../../../../components/Lang';
// utils
// styles
import styles from './VideoTherapy.module.scss';
// assets

const { textStyle } = styles;

const requestAppoinmentHandler = (goalId, severity, showModal) => {
  analytics.track('dashboard', 'clicked request appointment', {
    goalId,
    severity
  });
  showModal(RequestAppointment, {
    maxWidth: 820,
    source: 'video-therapy-request'
  });
};

const VideoTherapy = ({
  type,
  index,
  showModal,
  bigCardText,
  videoTherapyRequestDone,
  isFetching,
  goalId,
  severity,
  action,
  videoSessionCount
}) => {
  const LoaderComponent = type === 'large' ? LoaderLarge : LoaderSmall;
  if (isFetching) {
    return <LoaderComponent />;
  }

  const componentProps = {
    header: (
      <Lang path="therapyCard.headerText" values={{ index: index + 1 }} />
    ),
    categoryCount: videoSessionCount,
    categoryUnit: <Lang path="therapyCard.unitText" />,
    showButton: !videoTherapyRequestDone,
    onClick: () =>
      requestAppoinmentHandler(goalId, severity, showModal, action),
    cardId: 'video-therapy',
    buttonId: 'request-appointment-btn',
    buttonText: <Lang path="therapyCard.buttonText" />
  };

  if (videoTherapyRequestDone) {
    componentProps.statusText = <Lang path="therapyCard.statusDoneText" />;
  }

  if (index > 0) {
    componentProps.buttonType = 'secondary';
  }
  return type === 'large' ? (
    <ActionPlanCardBig
      content={bigCardText}
      imgSrc={bigCardImg}
      {...componentProps}
    />
  ) : (
    <ActionPlanCardSmall imgSrc={smallCardImg} {...componentProps}>
      <p data-testid="video-therapy-description-text" className={textStyle}>
        <Lang path="therapyCard.descriptionText" />
      </p>
    </ActionPlanCardSmall>
  );
};

const mapStateToProps = (state) => {
  const {
    videoTherapy: {
      videoTherapyRequestDone,
      status: { isFetching }
    },
    dashBoard: { goalId, severity, sessionCount }
  } = state;
  return {
    videoTherapyRequestDone,
    isFetching,
    goalId,
    severity,
    videoSessionCount: sessionCount.video[severity]
  };
};

export default connect(mapStateToProps)(VideoTherapy);
