// Core
import React, { useRef } from 'react';
// Components
import featureService from 'utils/feature-service';
import GoalListCarousel from './components/GoalList/Carousel';
import RetakeSurveyCard from '../DashBoard/components/RetakeSurveyCard';
import SelectedGoal from './components/SelectedGoal';
import BreathingExerciseCarousel from './components/BreathingExercise/Carousel';
import SessionCarousel from './components/Session/Carousel';
import TrackerCard from './components/TrackerCard';
import Lang from '../../components/Lang';
import FullWidthContainer from '../../components/FullWidthContainer';
// styles
import styles from './SelfUse.module.scss';
// utils
import history from '../../utils/history';
import helpers from '../../utils/helpers';
// hocs
import wrapWithApolloClient from '../../hoc/wrapWithApolloClient';

const {
  containerStyle,
  selectedGoalContainer,
  retakeSurveyTitleStyle
} = styles;

const {
  _hasGoalListFeature,
  _hasRetakeSurveyCardFeature,
  _hasBreathingExerciseFeature,
  _hasSelfUseFeature,
  _hasTrackerFeature
} = featureService;

const SelfUse = () => {
  const selectedGoalRef = useRef(null);
  if (!_hasSelfUseFeature()) {
    history.push(helpers.homeUrl());
  }
  return (
    <div className={containerStyle}>
      <FullWidthContainer
        Component={SelectedGoal}
        style={selectedGoalContainer}
        checkFeature={_hasGoalListFeature}
        refProp={selectedGoalRef}
      />
      <FullWidthContainer
        Component={SessionCarousel}
        checkFeature={_hasGoalListFeature}
      />
      <FullWidthContainer
        Component={TrackerCard}
        checkFeature={_hasTrackerFeature}
        containerId="tracker-card-container"
      />
      <FullWidthContainer
        Component={BreathingExerciseCarousel}
        checkFeature={_hasBreathingExerciseFeature}
      />
      <FullWidthContainer
        Component={GoalListCarousel}
        checkFeature={_hasGoalListFeature}
        selectedGoalRef={selectedGoalRef}
      />
      <FullWidthContainer
        titleText={<Lang path="selfUse.takeSurveyText" />}
        titleStyle={retakeSurveyTitleStyle}
        Component={RetakeSurveyCard}
        checkFeature={_hasRetakeSurveyCardFeature}
      />
    </div>
  );
};

export default wrapWithApolloClient(SelfUse);
