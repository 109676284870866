// TODO: Need to fix dependency cycle
/* eslint-disable import/no-cycle */
import _ from 'lodash';
import { onError } from 'apollo-link-error';
import client from './apollo-client';
import { queries } from '.';
import handleGraphqlError from './helper';
import errorTypes from './error-types';
import logger from '../logger';

const { GET_CHAT_MESSAGES, GET_CHAT_MESSAGES_FOR_CLIENT } = queries;

const _handleError = ({ operation, sendChatMessage }) => {
  const sendChatMessageCopy = _.clone(sendChatMessage);
  const {
    variables: { to, from }
  } = operation;
  const isCoachQuery = to && from;
  const queryObject = {};
  queryObject.query = isCoachQuery
    ? GET_CHAT_MESSAGES_FOR_CLIENT
    : GET_CHAT_MESSAGES;
  if (isCoachQuery) {
    queryObject.variables = { to, from };
  }
  const proxyData = client.readQuery(queryObject);
  sendChatMessageCopy.id = `graphQlError-${new Date().getTime()}`;
  const newQuery = {
    ...queryObject,
    data: {
      ...proxyData,
      getChatMessages: {
        ...proxyData.getChatMessages,
        messages: _.concat(
          proxyData.getChatMessages.messages,
          sendChatMessageCopy
        )
      }
    }
  };
  client.writeQuery(newQuery);
};

const _isServerError = (type) => !!errorTypes[type];

const errorLink = onError(
  ({ operation, response, graphQLErrors, networkError }) => {
    const { operationName } = operation;
    if (
      operationName === 'sendChatMessage' &&
      operation.getContext().optimisticResponse
    ) {
      const { sendChatMessage } = operation.getContext().optimisticResponse;
      if (graphQLErrors) {
        const type = _.get(graphQLErrors, '[0].errorType', '');
        const isServerError = _isServerError(type);
        if (isServerError) {
          _handleError({ operation, sendChatMessage });
        } else {
          sendChatMessage.id = `local-${new Date().getTime()}`;
          if (response) {
            response.data = { sendChatMessage };
            response.errors = null;
          }
        }
      }
      if (networkError) {
        _handleError({ operation, sendChatMessage });
      }
    }
    const error = { graphQLErrors, networkError };
    handleGraphqlError(
      'An error occurred in graphql client',
      'Graphql Error Handler (errorLink.onError)',
      error
    );
    if (networkError && networkError.statusCode === 401) {
      logger.error('Got 401 in graphql client', 'errorLink.onError', {
        networkError
      });
    }
  }
);

export default errorLink;
