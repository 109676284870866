/**
 * Action that responsible for auth token management
 */

import { SET_SESSION_EXPIRED } from './types';

const setSessionExpired = (status) => ({
  type: SET_SESSION_EXPIRED,
  status
});

export default setSessionExpired;
