import { makeStyles } from '@material-ui/core/styles';
import {
  brandColorForegroundPrimary,
  fontWeightSemibold
} from 'styles/config.scss';

export default makeStyles(() => ({
  paper: {
    background: '#f9fafa',
    marginBottom: '18px'
  },
  tab: {
    color: 'rgba(39, 71, 89, 0.75)',
    letterSpacing: '1px',
    marginRight: '5%',
    '@media (max-width: 576px)': {
      marginRight: 0
    },
    '@media (max-width: 320px)': {
      width: '50%'
    },
    '&.Mui-selected': {
      color: brandColorForegroundPrimary,
      fontWeight: fontWeightSemibold
    }
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      position: 'relative',
      left: '5%',
      maxWidth: 80,
      width: '100%',
      backgroundColor: brandColorForegroundPrimary
    }
  },
  flexContainer: {
    position: 'relative',
    left: '10%',
    '@media (max-width: 576px)': {
      justifyContent: 'center',
      left: 0
    }
  }
}));
