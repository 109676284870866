/**
 * Callback component that handles the response returned by auth0
 */

// Core
import React, { Component } from 'react';
import { parse } from 'tiny-querystring';
import { bindActionCreators } from 'redux';
import decode from 'jwt-decode';
import { connect } from 'react-redux';
import { serializeError } from 'serialize-error';
import _ from 'lodash';
import wrapperHoc from 'hoc/wrapperHoc';
// Components
import featureService from 'utils/feature-service';
import helpers from 'utils/helpers';
import ContentContainer from '../../components/layout/ContentContainer';
import Error from '../../components/Error';
import RetakeSurvey from '../../components/RetakeSurvey';
import FullPageError from '../../components/FullPageLoader';
import Lang from '../../components/Lang';
import { ActionButton } from '../../components/ui/Buttons';
// Actions
import fetchAssessment from '../../store/actions/AssessmentAction';
import getReportList from '../../store/actions/ReportListAction';
import resetRootState from '../../store/actions/ResetRootStateAction';
import { resetRedirectUrl } from '../../store/actions/LoginRedirectAction';
import { getUserData } from '../../store/actions/ProfileAction';
import getCompanyDetails from '../../store/actions/CompanyDetailsAction';
import { showModal } from '../../store/actions/ModalAction';
import {
  getCoachStatus,
  startAutoCoachAssign
} from '../../store/actions/dashboard/CoachRequestAction';
import setSessionExpired from '../../store/actions/SessionExpiredAction';
// Utils
import auth, {
  authService,
  setAuth0IdFromToken,
  setUserDetails
} from '../../utils/auth';
import logger from '../../utils/logger';
import analytics from '../../utils/analytics';
import handleUnexpectedError from '../../store/helper';
import moment from '../../utils/moment';
import storage from '../../utils/storage';
import { getDurationOfLastAssessmentAndToday } from './Callback.utils';
// API
import userApi from '../../api/user';
import authApi from '../../api/auth';
import config from '../../config';
import i18n from '../../assets/lang';
import { isNativePlatform } from '../../utils/capacitor';
import { updatePushRegistration } from '../../utils/push/push';

const {
  retakeAssessmentReminder: { type, duration },
  supportEmail,
  fallbackLang
} = config;

class Callback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isError: false,
      errorMessage: null
    };
  }

  async componentDidMount() {
    // Reset the root state on successful login and parse the hash
    this.props.actions.resetRootState();
    logger.info('Fetching token data', 'Callback.componentDidMount');
    this._handleCallBack(await this.getTokenData());
  }

  getTokenData = async () => {
    let token = {};
    if (window.location.search) {
      const urlParams = parse(window.location.search.slice(1));
      try {
        const { code } = urlParams;
        if (!_.isEmpty(code)) {
          logger.info(
            'Fetching refresh token from code',
            'Callback.getTokenData',
            { code: helpers.getMaskedData(code) }
          );
          token = await authApi.fetchRefreshTokenFromCode(code);
        } else {
          logger.error(
            'Code is empty, Unable to fetch refresh token from code',
            'Callback.getTokenData',
            { urlParams }
          );
          throw new Error(
            'Code is empty, Unable to fetch refresh token from code'
          );
        }
      } catch (err) {
        logger.error(
          'Error while getting token data',
          'Callback.getTokenData',
          { err }
        );
        return { err };
      }
    }
    const decoded = decode(token.access_token);
    const res = {
      accessToken: token.access_token,
      idToken: token.id_token,
      refreshToken: token.refresh_token,
      expiresIn: token.expires_in,
      idTokenPayload: {
        sub: decoded.sub
      },
      locale: _.get(decoded, 'http://wayforward_locale', '')
    };
    return { res };
  };

  handleRedirect = async () => {
    await analytics.track('login', 'failed login');
    this.props.history.replace('/');
  };

  _handleCallBack = async ({ err, res }) => {
    const accessCode = await storage.getItem('accessCode');
    await storage.removeItem('accessCode');

    logger.info('Response received from auth0', 'Callback._handleCallback', {
      accessCode
    });

    if (err) {
      analytics.info('login', 'failed login');
      logger.error(
        'An error occurred while logging in',
        'Callback._handleCallback',
        {
          accessCode,
          error: serializeError(err)
        }
      );
      let errorMessage = null;
      if (
        !_.isEmpty(err.errorDescription) &&
        err.errorDescription.startsWith('Access code:') &&
        err.errorDescription.endsWith('not found')
      ) {
        const accessCodeText = err.errorDescription.slice(
          err.errorDescription.indexOf(':') + 1,
          err.errorDescription.indexOf('not found')
        );
        errorMessage = (
          <Lang
            path="incorrectAccessCodeText"
            values={{
              accessCode: accessCodeText,
              supportEmail
            }}
          />
        );
        logger.error(`${err.errorDescription}`, 'Callback._handleCallback', {
          error: serializeError(err)
        });
      }
      this.setState({
        isError: true,
        errorMessage
      });
    }
    // If response is not falsy then do all the login related stuff
    if (res) {
      try {
        i18n.changeLanguage(res.locale || fallbackLang);
        logger.info('Successfully logged in', 'Callback._handleCallBack');
        const timestamp = moment().format();
        await authService.setLastUpdatedTimestamp(timestamp);
        await authService.setLogggedInTime(timestamp);
        await authService.setRefreshToken(res.refreshToken);
        await authService.setAccessToken(res.accessToken);
        setAuth0IdFromToken(res.accessToken);
        analytics.info('login', 'success login');
        await authService.setIdToken(res.idToken);
        auth.startTokenInspection();
        await this.props.actions.getUserData();
        const {
          company_name: company,
          employer_id: employerId,
          features,
          connection,
          roles,
          locale
        } = this.props.profile.profile;
        setUserDetails({ features, locale, roles, connection });
        const companyName = company || employerId;
        await this.props.actions.getCompanyDetails(companyName);
        if (this.props.companyDetails && this.props.companyDetails.name) {
          const networkRequests = [
            this.props.actions.fetchAssessment,
            this.props.actions.getReportList
          ];
          if (
            featureService._hasCoachAccessFeature() ||
            featureService._hasCoachAccessOnWebFeature()
          ) {
            networkRequests.push(this.props.actions.getCoachStatus);
          }
          const auth0Id = res.idTokenPayload.sub;
          logger.info(
            `User with the id: ${auth0Id} has features: ${JSON.stringify(
              features
            )}`,
            'Callback._handleCallBack'
          );
          this.props.actions.setSessionExpired(false);

          networkRequests.push(
            userApi.updateActivity.bind(null, { type: 'login' })
          );
          await Promise.all(networkRequests.map((request) => request()));
          this._checkLastAssessment();
          // Associate user id with push token
          if (isNativePlatform) {
            try {
              updatePushRegistration({
                pushNotificationToken: this.props.pushNotificationToken
              });
            } catch (error) {
              logger.error(
                'Error occurred while associating user id with push token',
                'Callback._handleCallBack',
                { error }
              );
            }
          }

          const { reports, coachRequestStatus, partner } = this.props;
          helpers.checkAndAssignAutoCoachForEligibility(
            reports,
            partner,
            coachRequestStatus,
            startAutoCoachAssign
          );
          helpers.redirectAfterLogin();
          this.props.actions.resetRedirectUrl();
        } else {
          await auth.clearData();
          logger.error(
            'Unable to fetch company details',
            'Callback._handleCallBack',
            { companyName }
          );
          this.setState({ isError: true });
        }
      } catch (error) {
        handleUnexpectedError(
          'An error occurred when executing post login operations.',
          'Callback._handleCallBack',
          error
        );
      }
    }
  };

  _checkLastAssessment = () => {
    if (
      !helpers.isCoach() &&
      featureService._hasRetakeAssessmentPopupFeature() &&
      this.props.reports &&
      this.props.reports.length
    ) {
      const dayDiff = getDurationOfLastAssessmentAndToday(
        type,
        this.props.reports
      );
      if (dayDiff >= duration) {
        this.props.actions.showModal(RetakeSurvey, {
          source: 'retake-survey'
        });
      }
    }
  };

  render() {
    const footerContent = [
      <ActionButton onClick={() => this.handleRedirect()}>
        <Lang path="errorBoundaryHomeButtonText" />
      </ActionButton>
    ];
    return (
      <div>
        {!this.state.isError && (
          <FullPageError
            loaderText={<Lang path="loggedInUserRedirectText" />}
          />
        )}
        {this.state.isError && (
          <ContentContainer size="extraLarge">
            <Error
              message={<Lang path="errorOccurred" />}
              footer={footerContent}
              content={
                <React.Fragment>
                  {this.state.errorMessage || (
                    <p>
                      {' '}
                      <Lang path="errorText" values={{ supportEmail }} />
                    </p>
                  )}
                </React.Fragment>
              }
            />
          </ContentContainer>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    profile,
    companyDetails,
    reportList: { reports },
    requestCoach: { coachRequestStatus },
    pushNotificationData: { token },
    companyDetails: {
      companyDetails: { partner }
    }
  } = state;
  return {
    profile,
    companyDetails: companyDetails.companyDetails,
    reports,
    pushNotificationToken: token,
    coachRequestStatus,
    partner
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchAssessment: bindActionCreators(fetchAssessment, dispatch),
    getReportList: bindActionCreators(getReportList, dispatch),
    resetRootState: bindActionCreators(resetRootState, dispatch),
    resetRedirectUrl: bindActionCreators(resetRedirectUrl, dispatch),
    getUserData: bindActionCreators(getUserData, dispatch),
    getCompanyDetails: bindActionCreators(getCompanyDetails, dispatch),
    showModal: bindActionCreators(showModal, dispatch),
    getCoachStatus: bindActionCreators(getCoachStatus, dispatch),
    setSessionExpired: bindActionCreators(setSessionExpired, dispatch)
  }
});

export default wrapperHoc(
  connect(mapStateToProps, mapDispatchToProps)(Callback)
);
