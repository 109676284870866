import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import propTypes from 'prop-types';

import styles from './TextInputScreen.module.scss';
import RenderHtml from '../../../../../components/RenderHtml';
import CustomInput from '../../../../../components/CustomInput';

import { Separator } from '../../../../../components/ui/Layout';

import {
  onAddHandler,
  onSaveTextInputHandler,
  validateText,
  onCancelHandler
} from './TextInputScreen.utils';
import TextInputLog from './TextInputLog/TextInputLog';

const {
  textInputContainer,
  textInputHeaderStyle,
  textInputMetaContainer,
  textInputTitleStyle,
  textInputSubTitleStyle,
  textInputAdditionalInfoStyle,
  textInputContentStyle,
  separatorStyle,
  customInputContainer
} = styles;

const separatorConfig = {
  height: '5px',
  width: '100%',
  x1: '0%',
  y1: '0%',
  x2: '100%',
  y2: '0%',
  stroke: 'rgba(109, 114, 120, 0.35)'
};

const TextInputScreen = ({
  screenData,
  textInputProgress,
  setScreenProgress,
  setUnsavedProgress,
  autoScrollRef,
  scrollToTopRef
}) => {
  const { goalId, screenId, sessionId } = useParams();
  const [textInputData, setTextInputData] = useState(textInputProgress);
  const { toolHistoryContent, textInputId } = screenData;
  const [currentTextBoxInput, setCurrentTextBoxInput] = useState(null);
  const [currentTextInputData, setCurrentTextInputData] = useState([]);

  useEffect(() => {
    if (textInputId) {
      setScreenProgress({
        id: textInputId,
        textInputs: currentTextInputData || []
      });
    }
  }, [setScreenProgress, screenId, textInputId, currentTextInputData]);

  useEffect(() => {
    if (currentTextBoxInput && currentTextBoxInput.trim()) {
      const input = {
        text: currentTextBoxInput,
        isCustom: true,
        createdAt: new Date().toISOString()
      };
      const data = {
        id: textInputId,
        textInputs: [input, ...(currentTextInputData || [])]
      };
      setUnsavedProgress(data);
    } else {
      setUnsavedProgress({});
    }
  }, [
    currentTextBoxInput,
    currentTextInputData,
    setUnsavedProgress,
    textInputId
  ]);

  return (
    <div
      data-testid="text-input-screen-container"
      className={textInputContainer}
      ref={scrollToTopRef}
    >
      <div
        data-testid="text-input-meta-container"
        className={textInputMetaContainer}
      >
        <h2 data-testid="text-input-header" className={textInputHeaderStyle}>
          {screenData.subheader}
        </h2>
        <div data-testid="text-input-content" className={textInputContentStyle}>
          <div data-testid="text-input-title" className={textInputTitleStyle}>
            {screenData.title}
          </div>
          <div
            data-testid="text-input-content-subtitle"
            className={textInputSubTitleStyle}
          >
            {screenData.subtitle}
          </div>
          {screenData.additionalInfo ? (
            <div
              data-testid="text-input-content-additional-info"
              className={textInputAdditionalInfoStyle}
            >
              <RenderHtml>{screenData.additionalInfo.html}</RenderHtml>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className={customInputContainer}>
        <CustomInput
          screenType="textInput"
          testId="text-input"
          setCurrentTextBoxInput={setCurrentTextBoxInput}
          customOptionPlaceholder={screenData.placeholderText}
          customValidator={(text) => validateText(text)}
          onClickAdd={() => onAddHandler({ goalId, screenId, sessionId })}
          onClickSave={(data) =>
            onSaveTextInputHandler({
              data,
              setTextInputData,
              setCurrentTextInputData,
              goalId,
              screenId,
              sessionId
            })
          }
          onClickCancel={() =>
            onCancelHandler({
              goalId,
              sessionId,
              screenId
            })
          }
        />
      </div>
      <div ref={autoScrollRef} />

      <div className={separatorStyle}>
        <Separator {...separatorConfig} />
      </div>

      <TextInputLog
        noProgressPlaceHolder={toolHistoryContent.text}
        header={toolHistoryContent.header}
        textInputData={textInputData}
      />
    </div>
  );
};

TextInputScreen.propTypes = {
  screenData: propTypes.shape({
    title: propTypes.string,
    subtitle: propTypes.string,
    subheader: propTypes.string,
    description: propTypes.string,
    customOptionPlaceholder: propTypes.string,
    toolHistoryContent: propTypes.shape({
      header: propTypes.string,
      text: propTypes.string
    })
  }),
  setScreenProgress: propTypes.func,
  textInputProgress: propTypes.arrayOf(
    propTypes.shape({
      createdAt: propTypes.string,
      text: propTypes.string,
      isCustom: propTypes.bool
    })
  ),
  setUnsavedProgress: propTypes.func
};

TextInputScreen.defaultProps = {
  screenData: {},
  setScreenProgress: () => null,
  textInputProgress: [],
  setUnsavedProgress: () => null
};
export default TextInputScreen;
