import React from 'react';
import { Trans } from 'react-i18next';

const Lang = ({ path, values = {}, components = {}, defaultText = '' }) => (
  <Trans
    i18nKey={path}
    values={values}
    components={components}
    tOptions={{ interpolation: { escapeValue: false } }}
  >
    {defaultText}
  </Trans>
);

export default Lang;
