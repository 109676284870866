import React from 'react';
import _ from 'lodash';

// utils
import moment from 'utils/moment';
// styles
import styles from './ClientAssessmentsItemHeader.module.scss';

const ClientAssessmentsItemHeader = ({
  timestamp,
  suicidal,
  domesticViolence
}) => {
  const {
    container,
    headerTextStyle,
    labelStyle,
    suicidalBgColor,
    domesticViolenceBgColor
  } = styles;

  return (
    <div className={container} data-testid="client-assessment-container">
      <span className={headerTextStyle}>
        {_.upperFirst(moment(timestamp).fromNow())}
      </span>
      <div>
        {suicidal && (
          <span
            data-testid="suicidal"
            className={[labelStyle, suicidalBgColor].join(' ')}
          >
            SI
          </span>
        )}
        {domesticViolence && (
          <span
            data-testid="domestic"
            className={[labelStyle, domesticViolenceBgColor].join(' ')}
          >
            DV
          </span>
        )}
      </div>
    </div>
  );
};

export default ClientAssessmentsItemHeader;
