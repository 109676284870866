/*

 */

// Core
import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './AssessmentSkeleton.module.scss';

const { skeletonWrapper, skOuter } = styles;
// components
const QuestionSkeleton = () => (
  <div className={skOuter}>
    <ContentLoader height="165" width="200">
      <rect x="0" y="0" rx="8" ry="8" width="200" height="40" />
      <rect x="0" y="45" rx="8" ry="8" width="200" height="115" />
    </ContentLoader>
  </div>
);
const RadioGroup = () => (
  <div>
    <ContentLoader height="220" width="220">
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="15" ry="15" width="220" height="32" />
      <rect x="0" y="37" rx="15" ry="15" width="220" height="32" />
      <rect x="0" y="74" rx="15" ry="15" width="220" height="32" />
      <rect x="0" y="111" rx="15" ry="15" width="220" height="32" />
    </ContentLoader>
  </div>
);
const AssessmentSkeleton = (props) => (
  <div className={skeletonWrapper}>
    <div>
      {(() => {
        switch (props.type) {
          case 'questions':
            return <QuestionSkeleton />;
          case 'radiogroup':
            return <RadioGroup />;
          default:
            return null;
        }
      })()}
    </div>
  </div>
);

export default AssessmentSkeleton;
