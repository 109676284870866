/**
 * Live Chat history action to manage the history of live chat
 */

import {
  SET_DASHBOARD_DATA,
  SET_DASHBOARD_SESSION_COUNT,
  SET_DASHBOARD_CALL_COMPLETED
} from '../types';

export const setDashBoardData = (goalId, severity, reportId) => ({
  type: SET_DASHBOARD_DATA,
  goalId,
  severity,
  reportId
});

export const setDashBoardCallCompleted = () => ({
  type: SET_DASHBOARD_CALL_COMPLETED
});

export const setDashBoardSessionCount = (sessionCountData) => ({
  type: SET_DASHBOARD_SESSION_COUNT,
  sessionCountData
});
