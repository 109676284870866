/**
 * Reducer to manage the state of currently active avatar
 */

import { SET_PUSH_NOTIFICATION_DATA } from '../../actions/types';

const INITIAL_STATE = {
  token: null,
  userId: null,
  updatedOn: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PUSH_NOTIFICATION_DATA: {
      const { updatedOn, token, userId } = action;
      return { token, userId, updatedOn };
    }
    default:
      return state;
  }
};
