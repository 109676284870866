/**
 * Input wrapped with our stying
 */

import React from 'react';
import {
  Input,
  FormControl,
  InputLabel,
  InputAdornment
} from '@material-ui/core';
import { InputError, InputSuccess, ErrorAdornment } from '../../Validation';

import { inputStyles, labelStyles } from './TextFieldBlack.styles';

const TextFieldBlack = ({
  className,
  input,
  labelId,
  label,
  validateIcon,
  validateOnFocus,
  meta: { error, touched, active, success, successId, errorId },
  disabled,
  testId,
  ...rest
}) => {
  let showError = false;

  if (touched && error) {
    showError = true;
  }

  if (validateOnFocus && error && active) {
    showError = true;
  }

  let adornmentComponent = null;
  if (
    (validateIcon && touched && !error) ||
    (validateIcon && validateOnFocus && !error)
  ) {
    adornmentComponent = (
      <InputAdornment position="end">
        <i className="fa fa-check" />
      </InputAdornment>
    );
  } else if (showError) {
    adornmentComponent = <ErrorAdornment />;
  }

  const inputClasses = inputStyles();
  const labelClasses = labelStyles();
  return (
    <FormControl className={className || null}>
      <InputLabel
        data-testid={labelId}
        classes={{
          root: labelClasses.root,
          focused: labelClasses.focused,
          disabled: labelClasses.disabled
        }}
      >
        {label}
      </InputLabel>
      <Input
        disabled={disabled}
        classes={{
          root: inputClasses.root,
          underline: inputClasses.underline,
          disabled: inputClasses.disabled
        }}
        endAdornment={adornmentComponent}
        error={showError}
        disableUnderline={false}
        inputProps={rest}
        data-testid={testId}
        {...input}
      />
      {showError && <InputError testId={errorId} error={error} />}
      {success && <InputSuccess testId={successId} message={success} />}
    </FormControl>
  );
};

export default TextFieldBlack;
