import React, { useState, useEffect, useMemo } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from '@apollo/client';
import _ from 'lodash';
import logger from 'utils/logger';
import Slider from '../../../../../components/Slider';
import ErrorRetry from '../../../../../components/ErrorRetry';
import Lang from '../../../../../components/Lang';
import GoalListCard from '../Card';
import { LoaderSmall, LoaderLarge } from './Carousel.skeleton';
import styles from './Carousel.module.scss';
import { GET_GOAL_LIST, GET_SELECTED_GOAL_INFO } from '../../../queries';

const { carouselContainer, containerTextStyle } = styles;

const renderGoalList = (goalList, currentGoal, selectedGoalRef) => (
  <div data-testid="goal-list-carousel" className={carouselContainer}>
    <div data-testid="explore-other-goals-text" className={containerTextStyle}>
      <Lang path="selfUse.goalList.exploreOtherGoalsText" />
    </div>
    <Slider
      testId="goal-list-slider"
      nextArrowId="goal-list-carousel-next-arrow-button"
      prevArrowId="goal-list-carousel-prev-arrow-button"
      loop
    >
      {goalList.map((goal) => (
        <GoalListCard
          key={goal.id}
          currentGoal={currentGoal}
          goal={goal}
          selectedGoalRef={selectedGoalRef}
        />
      ))}
    </Slider>
  </div>
);

const Carousel = ({ selectedGoalRef }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const {
    loading,
    error,
    data: goalListData,
    refetch,
    networkStatus
  } = useQuery(GET_GOAL_LIST, {
    notifyOnNetworkStatusChange: true
  });
  const { data: currentGoalData } = useQuery(GET_SELECTED_GOAL_INFO);
  useEffect(() => {
    if (loading || networkStatus === NetworkStatus.refetch) {
      setIsLoading(true);
    } else if (error && !loading && networkStatus !== NetworkStatus.refetch) {
      logger.error(`${error.message}`, 'GoalListCarousel.GoalListCarousel', {
        error
      });
      setIsLoading(false);
      setIsError(true);
    } else {
      setIsLoading(false);
      setIsError(false);
    }
  }, [loading, networkStatus, error]);

  const LoaderComponent = useMemo(
    () => (isSmallScreen ? LoaderSmall : LoaderLarge),
    [isSmallScreen]
  );
  if (isLoading) {
    return <LoaderComponent />;
  }

  if (isError) {
    return (
      <ErrorRetry
        testId="error"
        isFetching={false}
        onClick={() => refetch()}
        title={<Lang path="selfUse.goalList.carouselErrorText" />}
      />
    );
  }
  const goalList = _.get(goalListData, 'getGoalList.goals', []);
  const currentGoal = _.get(currentGoalData, 'getSelectedGoalInfo.goalId', '');
  return renderGoalList(goalList, currentGoal, selectedGoalRef);
};

export default Carousel;
