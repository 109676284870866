import React from 'react';
import './Spinner.scss';

const Spinner = ({ color = '', size = '', align = '' }) => (
  <center>
    <div
      data-testid="spinner"
      className={`lds-ring ${color} ${size} ${align} `}
    >
      <div />
      <div />
      <div />
      <div />
    </div>
  </center>
);

export default Spinner;
