export default [
    {
        language: 'English (United States)',
        label: 'English',
        value: 'en-US',
        id: 'english-united-states'
    },
   { 
       language: 'Spanish (United States)',
       label: 'Español (Estados Unidos)',
       value: 'es-US',
       id: 'spanish-united-states'
    }
];