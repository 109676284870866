/**
 * Breathing Exercise page container
 */
// Core
import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { NetworkStatus } from '@apollo/client';
// utils
import logger from 'utils/logger';
import history from 'utils/history';
import { queries, FETCH_POLICIES } from 'utils/graphql';
import featureService from 'utils/feature-service';
import helpers from 'utils/helpers';
// hocs
import withAuth from 'hoc/withAuth';
import wrapWithApolloClient from 'hoc/wrapWithApolloClient';
// Components
import { OutlineButton, ActionButton } from 'components/ui/Buttons';
import { Spinner } from 'components/ui/Layout';
import Error from 'components/Error';
import ErrorRetry from 'components/ErrorRetry';
import Lang from 'components/Lang';
import VideoScreen from '../components/Session/VideoScreen';
// styles
import styles from './BreathingExercise.module.scss';

const { GET_BREATHING_EXERCISES } = queries;
const {
  backButton,
  videoContainer,
  spinnerContainer,
  spinnerTextStyle,
  container,
  footerContainer
} = styles;
const footerContent = [
  <ActionButton onClick={() => history.push('/my-plan')}>
    <Lang path="selfUse.breathingExercise.error.buttonText" />
  </ActionButton>
];
const renderError = () => (
  <Error
    message={<Lang path="selfUse.breathingExercise.error.message" />}
    footer={footerContent}
  />
);

const BreathingExerciseContainer = () => {
  if (!featureService._hasBreathingExerciseFeature()) {
    history.push(helpers.homeUrl());
  }
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(null);
  const [autoplay, setAutoplay] = useState(false);
  const {
    error: graphQLError,
    data: fetchedBreathingExercises,
    loading: isFetching,
    refetch,
    networkStatus
  } = useQuery(GET_BREATHING_EXERCISES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: FETCH_POLICIES.cacheFirst
  });

  const { id } = useParams();

  const breathingExercises = _.get(
    fetchedBreathingExercises,
    'getBreathingExercise.breathingExercises',
    []
  );
  const breathingExerciseData = breathingExercises.find(
    (obj) => _.get(obj, 'audio.audioId') === id
  );

  const subheader = _.get(breathingExerciseData, 'subheader', '');
  const exercise = subheader.replace(/[^a-zA-Z0-9/]/g, '-').toLowerCase();

  useEffect(() => {
    const isAutoplay = _.get(history, 'location.state.autoplay', false);
    setAutoplay(isAutoplay);

    if (!isFetching && networkStatus !== NetworkStatus.refetch) {
      history.replace(`/self-use/breathing-exercise/${id}/${exercise}`);
    }
  }, [isFetching, networkStatus, exercise, id]);

  useEffect(() => {
    if (isFetching || networkStatus === NetworkStatus.refetch) {
      setIsLoading(true);
    } else if (
      graphQLError &&
      !isFetching &&
      networkStatus !== NetworkStatus.refetch
    ) {
      logger.error(
        'Failed to fetch breathing exercises.',
        'BreathingExercise.useEffect',
        { error: graphQLError }
      );
      setIsLoading(false);
      setIsError(true);
    } else {
      setIsLoading(false);
      setIsError(false);
    }
  }, [graphQLError, isFetching, networkStatus]);

  const renderBreathingExercise = useCallback(
    (exerciseData) => (
      <div className={container}>
        <div className={videoContainer}>
          <VideoScreen
            isBreathingExercise
            screenData={exerciseData}
            currentProgress={currentProgress}
            setCurrentProgress={setCurrentProgress}
            renderError={renderError}
            autoplay={autoplay}
          />
        </div>
        <div className={footerContainer}>
          <OutlineButton
            testId="back-button"
            className={backButton}
            onClick={() => history.goBack()}
          >
            <Lang path="backButtonText" />
          </OutlineButton>
        </div>
      </div>
    ),
    [currentProgress, autoplay]
  );

  if (isLoading) {
    return (
      <div className={spinnerContainer}>
        <Spinner />
        <p className={spinnerTextStyle}>
          <Lang path="loadingTextEllipsis" />
        </p>
      </div>
    );
  }

  if (isError) {
    return (
      <ErrorRetry
        isFetching={false}
        onClick={() => refetch()}
        title={<Lang path="selfUse.breathingExercise.error.errorText" />}
      />
    );
  }

  return _.isEmpty(breathingExerciseData)
    ? renderError()
    : renderBreathingExercise(breathingExerciseData);
};

export default withAuth(wrapWithApolloClient(BreathingExerciseContainer));
