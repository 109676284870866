/**
 * Action for managing video player modal state
 */

import { OPEN_VIDEO_MODAL, CLOSE_VIDEO_MODAL } from '../types';

export const openVideoModal = ({
  url,
  videoId,
  analyticsMessage,
  autoPlay
}) => ({
  type: OPEN_VIDEO_MODAL,
  url,
  videoId,
  analyticsMessage,
  autoPlay
});

export const closeVideoModal = () => ({
  type: CLOSE_VIDEO_MODAL
});
