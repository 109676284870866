// core
import React from 'react';
// components
// styles
import styles from './ChatBar.module.scss';

import { CoachCardError } from '../Error';

const { container, coachImage, coachInfo, coachName, coachInfoWrapper } =
  styles;

const ChatBar = ({ coachDetails }) => {
  const isCoachReady = Boolean(Object.keys(coachDetails).length);
  const getChatBar = () => (
    <React.Fragment>
      <img src={coachDetails.image} className={coachImage} alt="ClientChat" />
      <div className={coachInfo}>
        <span className={coachName}>{coachDetails.name}</span>
      </div>
    </React.Fragment>
  );

  return (
    <div className={container}>
      <div className={coachInfoWrapper}>
        {isCoachReady ? (
          getChatBar()
        ) : (
          <React.Fragment>
            <CoachCardError />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ChatBar;
