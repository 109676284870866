// eslint-disable-next-line import/no-cycle
import { getUserDetails } from './auth';

const _hasFeature = (featureName) => {
  const userDetails = getUserDetails();
  return userDetails.features && userDetails.features.indexOf(featureName) >= 0;
};

const _isProductActive = () => {
  const userDetails = getUserDetails();
  return userDetails.features && userDetails.features.length > 0;
};

class FeatureService {
  _hasIntelliPlatformFeature = () =>
    _hasFeature('user_allowed_to_access_intelliplatform');

  _hasOnBoardingFlowFeature = () =>
    _hasFeature('user_allowed_to_access_onboarding_flow');

  _hasHighWorkStressGoalFeature = () =>
    _hasFeature('user_allowed_to_access_high_stress_work_goal');

  _hasDiagnosticSurveyFeature = () =>
    _hasFeature('user_allowed_to_access_diagnostic_survey');

  _hasSuicidalIdeationEndorsementAlertFeature = () =>
    _hasFeature('user_allowed_to_send_suicidal_ideation_endorsement_alert');

  _hasSuicidalAlertDisclaimerFeature = () =>
    _hasFeature('user_allowed_to_see_suicidal_alert_disclaimer');

  _hasContentAccessFeature = () =>
    _hasFeature('user_allowed_to_access_content');

  _hasAutoCoachAssignmentOnCompletingSurveyFeature = () =>
    _hasFeature(
      'user_allowed_to_access_coach_auto_assignment_on_completing_survey'
    );

  _hasAutoCoachAssignmentOnRequestClickFeature = () =>
    _hasFeature(
      'user_allowed_to_access_coach_auto_assignment_on_request_click'
    );

  _hasCoachAccessFeature = () => _hasFeature('user_allowed_to_access_coach');

  _hasCoachWelcomeMessageFeature = () =>
    _hasFeature('user_allowed_to_receive_chat_welcome_message');

  _hasRetakeAssessmentPopupFeature = () =>
    _hasFeature('user_allowed_to_see_retake_assessment_popup');

  _isProductActive = () => _isProductActive();

  _hasCoachAccessOnWebFeature = () =>
    _hasFeature('user_allowed_to_access_coach_on_web');

  _hasCovid19Goal = () => _hasFeature('user_allowed_to_access_covid_19_goal');

  _hasBonusGoalFeature = () =>
    this._hasHighWorkStressGoalFeature() || this._hasCovid19Goal();

  _hasCoachChatOnWebFeature = () =>
    _hasFeature('user_allowed_to_access_coach_chat_on_web');

  _hasTestControlFeature = () =>
    _hasFeature('user_allowed_to_access_test_controls');

  _hasCoachSuggestionFeature = () =>
    _hasFeature('user_allowed_to_access_coach_suggestion');

  _hasWorkLifeRequestFeature = () =>
    _hasFeature('user_allowed_to_access_work_life_request');

  _hasVideoTherapyFeature = () =>
    _hasFeature('user_allowed_to_access_video_therapy');

  _hasInpersonTherapyFeature = () =>
    _hasFeature('user_allowed_to_access_inperson_therapy');

  _hasCatAssessmentFeature = () =>
    _hasFeature('user_allowed_to_access_cat_assessment');

  _hasGoalListFeature = () => _hasFeature('user_allowed_to_access_goal_list');

  _hasRetakeSurveyCardFeature = () =>
    _hasFeature('user_allowed_to_access_retake_survey_card');

  _hasBreathingExerciseFeature = () =>
    _hasFeature('user_allowed_to_access_breathing_exercise');

  _hasSelfUseFeature = () => _hasFeature('user_allowed_to_access_self_use');

  _hasConversationalAssessmentFeature = () =>
    _hasFeature('user_allowed_to_access_conversational_assessment');

  _hasContactEAPCardFeature = () =>
    _hasFeature('user_allowed_to_access_eap_info_tool');

  _hasResourcesFeature = () => _hasFeature('user_allowed_to_access_resources');

  _hasTrackerFeature = () =>
    _hasFeature('user_allowed_to_access_session_progress_tracker');

  _hasWorkLifeBenefitsResourceFeature = () =>
    _hasFeature('user_allowed_to_access_worklife_benefits');

  _hasCounsellingResourceFeature = () =>
    _hasFeature('user_allowed_to_access_mental_wellbeing_counseling');

  _hasTelevideoTherapyFeature = () =>
    _hasFeature('user_allowed_to_access_chat_and_televideo_therapy');

  _hasChatTherapyFeature = () =>
    _hasFeature('user_allowed_to_access_chat_therapy');

  _hasCounselorTherapyFeature = () =>
    _hasFeature('user_allowed_to_access_counselor_therapy');

  _hasTherapyAccessFeature = () =>
    _hasFeature('user_allowed_to_access_therapy_card');

  _hasTherapyAccessWithEAPFeature = () =>
    _hasFeature('user_allowed_to_access_hr_therapy_card');

  _hasEligibilityAssessmentFeature = () =>
    _hasFeature('user_allowed_to_access_eligibility_assessment');
}

export default new FeatureService();
