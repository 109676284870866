/**
 * Button wrapped in our custom style and logic to act as a back button
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import Lang from '../Lang';

import styles from './BackButton.module.scss';

const BackButton = ({ props, className, onClick }) => {
  const classes = [];
  classes.push(styles.container);
  if (className) {
    classes.push(className);
  }
  return (
    <Button
      onClick={onClick}
      data-testid="back-button"
      className={classes.join(' ')}
      {...props}
    >
      <Lang path="backButtonText" />
    </Button>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default BackButton;
