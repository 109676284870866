/**
 * The new red button with icon support
 */

import React from 'react';
import propTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import { Icon as LoadingIcon } from 'antd';
import styles from './ActionButton.module.scss';
import Aux from '../../../layout/Aux';

const { container, containerPrimary, containerSecondary, buttonText } = styles;

const ActionButton = ({
  onClick,
  children,
  icon,
  className,
  type,
  isLoading,
  action,
  disabled,
  iconComponent,
  testId,
  ...rest
}) => {
  const containerClassNames = [];
  const buttonTextClassNames = [];
  // Check for class names and types and customizing based on that
  if (className) {
    containerClassNames.push(className);
  }
  containerClassNames.push(container);
  if (type === 'secondary') {
    containerClassNames.push(containerSecondary);
  } else {
    containerClassNames.push(containerPrimary);
  }
  if (icon || iconComponent) {
    buttonTextClassNames.push(buttonText);
  }
  return (
    <button
      className={containerClassNames.join(' ')}
      onClick={onClick}
      // eslint-disable-next-line react/button-has-type
      type={action}
      disabled={isLoading || disabled}
      data-testid={testId}
      {...rest}
    >
      {icon && <Icon>{icon}</Icon>}
      {iconComponent}
      <span className={buttonTextClassNames.join(' ')}>
        {isLoading && (
          <Aux>
            <LoadingIcon
              style={{ fontSize: 14, margin: 0 }}
              type="loading-3-quarters"
              spin
            />{' '}
            &nbsp;
          </Aux>
        )}
        {children}
      </span>
    </button>
  );
};

ActionButton.propTypes = {
  onClick: propTypes.func,
  isLoading: propTypes.bool,
  icon: propTypes.string,
  type: propTypes.oneOf(['primary', 'secondary']),
  action: propTypes.oneOf(['button', 'submit']),
  className: propTypes.string,
  disabled: propTypes.bool
};

ActionButton.defaultProps = {
  onClick: null,
  isLoading: false,
  icon: null,
  type: 'primary',
  action: 'button',
  className: null,
  disabled: false
};

export default ActionButton;
