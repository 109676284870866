/**
 * Component to handle questions that requires a numeric input from the user
 */

// Core
import React, { Component } from 'react';
// Components
import { Col, Row } from 'antd/lib/index';
import NumberTypeForm from '../../NumberTypeForm/NumberTypeForm';
// Styles
import styles from './NumberType.module.scss';

class NumberType extends Component {
  // On selection with take the onSelect prop passed on from parent component
  // and execute it by passing selectValue for both score and value since both are
  // are same for numeric question types
  _handleSubmit = ({ answer }) => {
    this.props.onSelect({
      score: answer,
      value: answer
    });
  };

  render() {
    const { question, inputContainer } = styles;

    const { data } = this.props;

    const text = data ? data.text : '';

    return (
      <Row>
        <Row>
          <Col xs={24}>
            <p className={question}>{text}</p>
          </Col>
        </Row>
        <Row className={inputContainer}>
          <Col span={24}>
            <NumberTypeForm
              onSubmit={this._handleSubmit}
              validateProps={data.validate}
            />
          </Col>
        </Row>
      </Row>
    );
  }
}

export default NumberType;
