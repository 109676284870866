// contains back button handlers for root paths and/or query params

import store, { dispatch } from 'store';
import {
  currentUserAction,
  infoTabAction
} from 'store/actions/coachchat/ActiveUserAction';
import { closeVideoModal } from 'store/actions/media/VideoPlayerModalAction';
import { parse, stringify } from 'tiny-querystring';
import helpers from 'utils/helpers';
import history from 'utils/history';
import _ from 'lodash';
import analytics from './analytics';
import logger from './logger';

const { isMobile } = helpers.platformInfo;

const redirectToHome = () => {
  const homeUrl = helpers.homeUrl();
  logger.info('redirecting to home', 'navigation.redirectToHome', { homeUrl });
  history.replace(homeUrl);
};

export const getQueryParams = () => {
  const { currentUser } = store.getState();
  const { infoTab } = store.getState();

  let queryParams = {};
  try {
    queryParams = parse(_.get(window, 'location.search', '').substring(1));
    if (_.isEmpty(queryParams)) {
      if (!_.isEmpty(currentUser)) {
        queryParams.user = currentUser.to;
        if (!_.isEmpty(infoTab)) {
          queryParams.view = 'info';
        }
      }
    }
  } catch (error) {
    logger.error(
      'Error while parsing query parameters',
      'navigation.getQueryParams',
      { error }
    );
    history.replace({
      search: ''
    });
  }

  return queryParams;
};

const coachChatBackButtonHandler = () => {
  const queryParams = getQueryParams();
  const {
    media: {
      videoPlayerModal: { isOpen: isVideoModalOpen }
    }
  } = store.getState();

  if (_.isEmpty(queryParams)) {
    redirectToHome();
  } else if (isVideoModalOpen) {
    dispatch(closeVideoModal());
    analytics.track(
      'navigation',
      'Clicked back button on video modal chat info tab'
    );
    logger.info(
      'Clicked back button on video modal chat info tab',
      'navigation.coachChatBackButtonHandler'
    );
  } else if (_.has(queryParams, 'view')) {
    dispatch(infoTabAction(null));
    let param = queryParams;
    delete param.view;
    param = stringify(param);
    history.replace({
      search: param
    });

    analytics.track('navigation', 'Clicked back button on chat info tab');
    logger.info(
      'Clicked back button on chat info tab',
      'navigation.coachChatBackButtonHandler'
    );
  } else if (isMobile) {
    dispatch(infoTabAction(null));
    history.replace({
      search: ''
    });

    analytics.track('navigation', 'Clicked back button on chat center tab');
    logger.info(
      'Clicked back button on chat center tab',
      'navigation.coachChatBackButtonHandler'
    );
    dispatch(currentUserAction(null));
  }
};

export const backButtonHandlersMap = {
  coach: coachChatBackButtonHandler
};

export const handleRootPathBackPress = (pathName) => {
  if (_.has(backButtonHandlersMap, pathName)) {
    backButtonHandlersMap[pathName]();
  } else {
    redirectToHome();
  }
};
