import React from 'react';
import { Col, Row } from 'antd';
import propTypes from 'prop-types';
import Slider from 'components/RatingSlider';
import styles from './Situation.module.scss';

const { container, cardStyle, textStyle, sliderContainer } = styles;

const Situation = ({ id, order, title, value, ratings, onChange }) => (
  <div data-testid={`situation-container-${order}`} className={container}>
    <div className={cardStyle}>
      <Row>
        <Col md={12} lg={12} xs={24}>
          <p data-testid={`situation-title-${order}`} className={textStyle}>
            {title}
          </p>
        </Col>
        <Col md={12} lg={12} xs={24}>
          <div
            data-testid={`situation-slider-${order}`}
            className={sliderContainer}
          >
            <Slider
              value={value}
              marks={ratings.map((rating, ratingIndex) => ({
                label: (
                  <span data-testid={`rating-label-${ratingIndex}`}>
                    {rating.label}
                  </span>
                ),
                value: ratingIndex
              }))}
              min={0}
              max={ratings.length - 1}
              onChange={(event, ratingIndex) =>
                onChange({
                  id,
                  ratingId: ratings[ratingIndex].id,
                  ratingValue: ratings[ratingIndex].label
                })
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

Situation.propTypes = {
  id: propTypes.string,
  title: propTypes.string,
  value: propTypes.number,
  order: propTypes.number,
  ratings: propTypes.arrayOf(propTypes.shape({})),
  onChange: propTypes.func
};

Situation.defaultProps = {
  id: '',
  title: '',
  value: 0,
  order: 0,
  ratings: [],
  onChange: () => null
};

export default Situation;
