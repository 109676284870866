import analytics from 'utils/analytics/analytics';
import auth from 'utils/auth';
import logger from 'utils/logger/logger';
import { updatePushRegistration } from 'utils/push/push';
import { isNativePlatform } from 'utils/capacitor';
import handleUnexpectedError from 'store/helper';
import userApi from 'api/user';

export const cleanUpAndLogout = async ({
  reason,
  urlParams,
  reasons,
  pushNotificationToken
}) => {
  try {
    if (reason.action !== reasons.noLogin.action) {
      await userApi.updateActivity({ type: 'logout' });
      await analytics.track('logout', reason.action);

      // disassociated push notification token on logout
      if (isNativePlatform) {
        await updatePushRegistration({
          pushNotificationToken,
          hasLogout: true
        });
      }
    } else {
      logger.info(
        "User wasn't logged in, redirecting to home",
        'Logout.utils.cleanUpAndLogout'
      );
    }
  } catch (error) {
    handleUnexpectedError(
      'An error occurred when executing logout operations',
      'Logout.utils.cleanUpAndLogout',
      error
    );
  }
  await auth.clearDataAndRedirect(urlParams);
};
