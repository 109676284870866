import React from 'react';
import classNamesInstance from 'classnames/bind';
import helper from 'utils/helpers';
import { Slider, Direction } from 'react-player-controls';
import styles from './SliderProgressBar.module.scss';

const { sliderStyle, sliderBarStyle, roundedStyle } = styles;

const classNames = classNamesInstance.bind(styles);

const direction = Direction.HORIZONTAL;

// Handler for player seek bar (slider)
const SliderHandler = ({ value, classname, css }) => (
  <div
    className={classname}
    style={Object.assign(
      {},
      {
        [css]: `${value > 100 ? 100 : value}%`,
        display: `${css === 'left' && value >= 98 ? 'none' : 'block'}`
      }
    )}
  />
);

const SliderProgressBar = ({
  duration,
  value,
  handleSliderChange,
  rounded = false
}) => {
  const { isDesktop } = helper.platformInfo;

  const sliderClass = classNames({
    sliderHandleStyle: true,
    sliderHoverStyle: isDesktop
  });

  return (
    <Slider
      direction={direction}
      className={`${sliderStyle} ${rounded ? roundedStyle : ''}`}
      onChange={(val) => handleSliderChange(val * duration)}
    >
      <SliderHandler
        value={(value / duration) * 100}
        classname={`${sliderBarStyle} ${rounded ? roundedStyle : ''}`}
        css="width"
      />
      <SliderHandler
        value={(value / duration) * 100}
        classname={sliderClass}
        css="left"
      />
    </Slider>
  );
};

export default SliderProgressBar;
