import React, { useState, useEffect } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ToolItemContent } from '../index';

import styles from './GoalItemContent.module.scss';

const { expansionPanelContainer, shadowStyle, toolNameStyle } = styles;

const GoalItemContent = ({ tools, goalName, superExpanded }) => {
  const [activeTool, setActiveTool] = useState('');

  useEffect(() => {
    setActiveTool('');
  }, [superExpanded]);

  return (
    <>
      {tools.map((tool) => {
        if (!tool.type || !tool.displayName || !tool.data) {
          return <></>;
        }
        const expanded = activeTool === tool.type;
        return (
          <div className={expansionPanelContainer} key={tool.type}>
            <Accordion
              onChange={() => {
                setActiveTool(tool.type);
              }}
              expanded={expanded}
            >
              <AccordionSummary
                onClick={() => {
                  if (expanded) {
                    setActiveTool('');
                  }
                }}
                className={shadowStyle}
                expandIcon={<ExpandMoreIcon />}
              >
                <div
                  data-testid={`${goalName}-${tool.displayName}-header-text`
                    .toLowerCase()
                    .replace(/ /g, '-')}
                  className={toolNameStyle}
                >
                  {tool.displayName}
                </div>
              </AccordionSummary>
              {expanded && (
                <AccordionDetails>
                  <ToolItemContent data={tool.data} />
                </AccordionDetails>
              )}
            </Accordion>
          </div>
        );
      })}
    </>
  );
};

export default GoalItemContent;
