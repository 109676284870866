// core
import React, { Component } from 'react';
import branch from 'branch-sdk';
import platform from 'platform-detect/os.mjs';
// components
import { Row, Col } from 'antd';
// assets
import downloadAppImg from 'assets/img/common/downloadapp.png';
import appStoreImg from 'assets/img/common/appstore.png';
import googlePlayImg from 'assets/img/common/googleplay.png';
// utils
import analytics from 'utils/analytics';
import logger from 'utils/logger';
// styles
// config
import config from 'config';
import i18n from 'assets/lang';
import styles from './SmartAppLink.module.scss';
import Lang from '../../Lang';
import Aux from '../../layout/Aux';
import { TextFieldBlack } from '../../ui/Inputs';
import { ActionButton } from '../../ui/Buttons';

const {
  container,
  topContainer,
  bottomContainer,
  responsiveImgStyle,
  downloadSectionContainer,
  enterPhoneStyle,
  sendButtonStyle,
  downloadNowButtonStyle,
  disclaimerStyle,
  inputStyle
} = styles;
class SmartAppLink extends Component {
  state = {
    phone: '',
    init: true,
    smsSent: false,
    branchIoLink: null,
    smsError: false
  };

  componentDidMount() {
    branch.init(config.branchIo.key, {}, (err, res) => {
      if (res) {
        this.setState({ init: true });
        branch.link(
          {
            channel: 'assess-ui',
            stage: 'Smart App Link Download',
            data: {
              $ios_url: config.branchIo.link.appStore,
              $android_url: config.branchIo.link.playStore
            }
          },
          (linkErr, link) => link && this.setState({ branchIoLink: link })
        );
      }
      if (err) {
        logger.error(
          'Branch: Error on init',
          'SmartAppLink.componentDidMount',
          { err }
        );
        this.setState({ init: false });
      }
    });
  }

  _handleSend = () => {
    if (this.props.subcategory) {
      analytics.track('modal download app', 'clicked send', {
        goalId: this.props.goalId,
        severity: this.props.severity,
        subcategory: this.props.subcategory
      });
    } else {
      analytics.track('assessment results', 'clicked send');
    }
    logger.info('Branch: Requesting to send SMS', 'SmartAppLink._handleSend');
    branch.sendSMS(
      this.state.phone,
      {
        channel: 'assess-ui',
        stage: 'Smart App Link Download',
        data: {
          $ios_url: config.branchIo.link.appStore,
          $android_url: config.branchIo.link.playStore
        }
      },
      null,
      (err) => {
        if (!err) {
          logger.info(
            'Branch: SMS sent successfully',
            'SmartAppLink._handleSend'
          );
          this.setState({ smsSent: true, smsError: false });
          setTimeout(() => this.setState({ smsSent: false }), 5000);
        } else {
          logger.error(
            'Branch: Error on sending SMS',
            'SmartAppLink._handleSend',
            { err }
          );
          this.setState({ smsError: true });
        }
      }
    );
  };

  _handleMobileLinkClick = () => {
    if (this.props.subcategory) {
      analytics.track('modal download app', 'clicked branch io mobile link', {
        goalId: this.props.goalId,
        severity: this.props.severity,
        subcategory: this.props.subcategory
      });
    } else {
      analytics.track('assessment results', 'clicked branch io mobile link');
    }
    window.open(this.state.branchIoLink);
  };

  _handleAppStoreClick = () => {
    if (this.props.subcategory) {
      analytics.track('modal download app', 'clicked app store link', {
        goalId: this.props.goalId,
        severity: this.props.severity,
        subcategory: this.props.subcategory
      });
    } else {
      analytics.track('assessment results', 'clicked app store link');
    }
    window.open(config.branchIo.link.appStore);
  };

  _handlePlayStoreClick = () => {
    if (this.props.subcategory) {
      analytics.track('modal download app', 'clicked play store link', {
        goalId: this.props.goalId,
        severity: this.props.severity,
        subcategory: this.props.subcategory
      });
    } else {
      analytics.track('assessment results', 'clicked play store link');
    }
    window.open(config.branchIo.link.playStore);
  };

  _renderSmsSection = () => {
    if (this.state.init) {
      return (
        <Aux>
          <p className={enterPhoneStyle}>
            <Lang path="downloadAppSms" />
          </p>
          <Col xs={24} lg={18} className={inputStyle}>
            <TextFieldBlack
              value={this.state.phone}
              onChange={(e) => this.setState({ phone: e.target.value })}
              placeholder="+12345678901"
              label={<Lang path="downloadAppPhoneNumberField" />}
              testId="smart-app-link-popup-phone-number-field"
              meta={{
                error: this.state.smsError ? (
                  <Lang path="downloadAppSmsError" />
                ) : null,
                touched: true,
                success: this.state.smsSent ? (
                  <Lang path="downloadAppSmsSuccess" />
                ) : null,
                successId: 'smart-app-link-popup-sms-success-message',
                errorId: 'smart-app-link-popup-sms-failure-message'
              }}
            />
          </Col>
          <Col xs={24} lg={6} style={{ marginTop: 15 }}>
            <ActionButton
              onClick={this._handleSend}
              className={sendButtonStyle}
              testId="smart-app-link-popup-send-button"
            >
              <Lang path="downloadAppSendButtonText" />
            </ActionButton>
          </Col>
        </Aux>
      );
    }
    return (
      <Aux>
        <p className={enterPhoneStyle}>
          <Lang path="downloadAppAdBlockMessage" />
        </p>
      </Aux>
    );
  };

  _renderDesktopLinks = () => (
    <Aux>
      <Col xs={24}>
        <p>
          <Lang path="downloadAppMultipleLinks" />
        </p>
      </Col>
      <Col xs={24}>
        <Row>
          <Col lg={{ span: 10, offset: 0 }} xs={{ span: 10, offset: 1 }}>
            <input
              type="image"
              onClick={this._handleAppStoreClick}
              className={responsiveImgStyle}
              src={appStoreImg}
              alt={i18n.t('alternateText.icon.appStoreDownload')}
            />
          </Col>
          <Col xs={{ span: 10, offset: 2 }}>
            <input
              onClick={this._handlePlayStoreClick}
              type="image"
              className={responsiveImgStyle}
              src={googlePlayImg}
              alt={i18n.t('alternateText.icon.playStoreDownload')}
            />
          </Col>
        </Row>
      </Col>
    </Aux>
  );

  _renderOrText = () => (
    <Col xs={24} style={{ marginTop: 20 }}>
      <p>{this.state.init && <Lang path="downloadAppOrText" />}</p>
    </Col>
  );

  _renderMobileLink = () =>
    this.state.branchIoLink ? (
      <Aux>
        <Col xs={24}>
          <p>
            <Lang path="downloadAppSingleLink" />
          </p>
        </Col>
        <Col xs={24}>
          <ActionButton
            onClick={this._handleMobileLinkClick}
            className={downloadNowButtonStyle}
          >
            Download Now
          </ActionButton>
        </Col>
      </Aux>
    ) : (
      this._renderDesktopLinks()
    );

  _renderAppDownloadSection = () => {
    if (platform.ios || platform.android) {
      return (
        <Aux>
          {this._renderMobileLink()}
          {this._renderOrText()}
          {this._renderSmsSection()}
        </Aux>
      );
    }
    return (
      <Aux>
        {this._renderSmsSection()}
        {this._renderOrText()}
        {this._renderDesktopLinks()}
      </Aux>
    );
  };

  render() {
    const { headerText } = this.props;
    return (
      <div className={container}>
        <div className={topContainer}>
          <Row>
            <Col xs={24} lg={{ span: 18, offset: 3 }}>
              <h2>{headerText || <Lang path="downloadAppHeader" />}</h2>
            </Col>
            <Col xs={24} lg={{ span: 20, offset: 2 }}>
              <p>
                <Lang
                  path="downloadAppDesc"
                  values={{ appName: config.appName }}
                />
              </p>
            </Col>
          </Row>
        </div>
        <div className={bottomContainer}>
          <Row>
            <Col xs={{ span: 10, offset: 7 }} lg={{ span: 8, offset: 2 }}>
              <img
                className={responsiveImgStyle}
                src={downloadAppImg}
                alt={i18n.t('alternateText.icon.downloadApp')}
              />
            </Col>
            <Col
              xs={{ span: 20, offset: 2 }}
              lg={{ span: 10, offset: 2 }}
              className={downloadSectionContainer}
            >
              <Row>{this._renderAppDownloadSection()}</Row>
            </Col>
            <Col xs={{ span: 20, offset: 2 }}>
              <p className={disclaimerStyle}>
                <span>*</span>
                <Lang path="downloadAppDisclaimer" />
              </p>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default SmartAppLink;
