/**
 *
 * Request Coach
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import RequestAppointment from './components/RequestAppointment';
import RequestReceived from './components/VideoTherapyRequestReceived';

const VideoTherapyRequest = (props) => {
  const { videoTherapyRequestDone } = props;
  return videoTherapyRequestDone ? <RequestReceived /> : <RequestAppointment />;
};

const mapStateToProps = (state) => {
  const {
    videoTherapy: { isFetching, videoTherapyRequestDone }
  } = state;
  return {
    videoTherapyRequestDone,
    isFetching
  };
};

export default connect(mapStateToProps)(VideoTherapyRequest);
