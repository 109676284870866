import helpers from 'utils/helpers';
import { get } from 'lodash';

const { isBrowser } = helpers.platformInfo;

const lock = get(window, 'screen.orientation.lock', null);
const lockScreenOrientation = (orientation) => {
  if (!isBrowser && lock) {
    lock(orientation);
  }
};

const unlockScreenOrientation = () => {
  if (!isBrowser && lock) {
    lock('portrait');
  }
};

export { lockScreenOrientation, unlockScreenOrientation };
