import React from 'react';
import styles from './NetworkIndicator.module.scss';

const { offlineText } = styles;

const NetworkIndicator = ({ networkError }) => {
  if (networkError) {
    return <span className={offlineText}>{networkError}</span>;
  }
  return null;
};

export default NetworkIndicator;
