import React from 'react';
import _ from 'lodash';
import { Paper } from '@material-ui/core';
// assets
import completedIcon from 'assets/icons/session/completed.svg';
// components
// utils
import history from 'utils/history';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import Lang from '../../../../../components/Lang';
import {
  OutlineButton,
  ActionButton
} from '../../../../../components/ui/Buttons';
// style
import styles from './Card.module.scss';

const {
  container,
  topPanelStyle,
  headerStyle,
  headerContainerStyle,
  subHeadingStyle,
  bottomPanelStyle,
  rectangleStyle,
  buttonStyle
} = styles;

const sessionButtonMap = {
  start: <Lang path="selfUse.session.card.buttonText.startButtonText" />,
  restart: <Lang path="selfUse.session.card.buttonText.restartButtonText" />,
  skip: <Lang path="selfUse.session.card.buttonText.skipToButtonText" />
};

const Card = ({
  screenData,
  cardIndex,
  goalId,
  buttonType,
  sessionId,
  screenId,
  sessionNumber
}) => {
  const header = _.get(screenData, 'content[0].header', '');
  const subheader = _.get(screenData, 'content[0].subheader', '');

  const handleButtonClick = () => {
    analytics.track(eventCategories.SELF_USE, 'clicked on session card', {
      subcategory: 'sessions',
      sessionId,
      screenId,
      goalId
    });
    history.push(
      `self-use/goal/${goalId}/session/${sessionId}/screen/${screenId}`
    );
  };
  const sliderClass = 'keen-slider__slide';

  return (
    <Paper className={` ${container} ${sliderClass}`}>
      <div className={topPanelStyle}>
        <span className={rectangleStyle} />
        <div className={headerContainerStyle}>
          <span data-testid={`card-heading-${cardIndex}`}>
            <Lang
              path="selfUse.session.cardHeader"
              values={{ number: sessionNumber }}
            />
            :
          </span>
          <span
            className={headerStyle}
            data-testid={`card-heading-text-${cardIndex}`}
          >
            {/* TODO: fix casing in CMS */}
            {_.startCase(_.lowerCase(header))}
          </span>
        </div>
        {buttonType === 'restart' && (
          <img
            src={completedIcon}
            alt=""
            data-testid={`card-completed-icon-${cardIndex}`}
          />
        )}
      </div>
      <div className={bottomPanelStyle}>
        <span
          className={subHeadingStyle}
          data-testid={`card-subheading-${cardIndex}`}
        >
          {subheader}
        </span>
        {buttonType === 'start' ? (
          <ActionButton
            testId={`card-button-${cardIndex}`}
            className={buttonStyle}
            onClick={() => handleButtonClick()}
          >
            {sessionButtonMap[buttonType]}
          </ActionButton>
        ) : (
          <OutlineButton
            testId={`card-button-${cardIndex}`}
            className={buttonStyle}
            onClick={() => handleButtonClick()}
          >
            {sessionButtonMap[buttonType]}
          </OutlineButton>
        )}
      </div>
    </Paper>
  );
};

export default Card;
