import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';

import { Device } from '@capacitor/device';
import platformTypes from 'assets/data/platform-types';
// TODO: Fix cyclic dependency
// eslint-disable-next-line import/no-cycle
import logger from './logger';

const platform = Capacitor.getPlatform();

export const isNativePlatform = platform !== 'web';

export const getPlatform = () => platform;

let deviceId;

export const initDeviceId = async () => {
  const id = await Device.getId();
  deviceId = id.uuid;
};

export const getDeviceId = () => deviceId;

let notificationProvider;
if (platform === platformTypes.ANDROID) {
  notificationProvider = 'fcm';
} else if (platform === platformTypes.IOS) {
  notificationProvider = 'apns';
}

export const getNotificationProvider = () => notificationProvider;

export const addAppEventListeners = () => {
  App.addListener('appStateChange', ({ isActive }) => {
    logger.info(`App state changed. Active state: ${isActive}`, 'App.App');
  });
};
