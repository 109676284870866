import { get, cloneDeep, isEmpty, pick } from 'lodash';
// assets
import i18n from 'assets/lang';
import safetyBehaviorTabActive from 'assets/icons/session/safety-behavior-tab-active.svg';
import safetyBehaviorTabInactive from 'assets/icons/session/safety-behavior-tab-inactive.svg';
import avoidedBehaviorTabActive from 'assets/icons/session/avoided-behavior-tab-active.svg';
import avoidedBehaviorTabInactive from 'assets/icons/session/avoided-behavior-tab-inactive.svg';
// utils
import moment from 'utils/moment';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import logger from '../../../../../utils/logger';

const supportedBehaviors = {
  safetyBehavior: 'safety',
  avoidedBehavior: 'avoided'
};

const behaviorTabIconMap = {
  safety: {
    icons: {
      active: safetyBehaviorTabActive,
      inactive: safetyBehaviorTabInactive
    },
    text: i18n.t('selfUse.session.behaviorScreenTabs.safety')
  },
  avoided: {
    icons: {
      active: avoidedBehaviorTabActive,
      inactive: avoidedBehaviorTabInactive
    },
    text: i18n.t('selfUse.session.behaviorScreenTabs.avoided')
  }
};

const onCancelHandler = ({ behavior, goalId, sessionId, screenId }) => {
  analytics.track(
    eventCategories.SELF_USE,
    'clicked on cancel add behavior button',
    {
      goalId,
      sessionId,
      screenId,
      subcategory: 'behavior'
    }
  );
  logger.info(
    'User has clicked on cancel add behavior button.',
    'Behavior.utils.onCancelHandler',
    {
      behavior,
      goalId,
      sessionId,
      screenId
    }
  );
};

const onAddHandler = ({ behavior, goalId, sessionId, screenId }) => {
  analytics.track(
    eventCategories.SELF_USE,
    `clicked on add ${behavior} behavior button`,
    {
      goalId,
      sessionId,
      screenId,
      subcategory: 'behavior'
    }
  );
  logger.info(
    'User has clicked on add behavior button.',
    'Behavior.utils.onAddHandler',
    {
      behavior,
      goalId,
      sessionId,
      screenId
    }
  );
};

const onSelectHandler = ({
  behavior,
  goalId,
  sessionId,
  screenId,
  isSelected,
  option,
  currentProgress,
  setCurrentProgress
}) => {
  const eventAction = isSelected ? 'selected' : 'unselected';
  analytics.track(eventCategories.SELF_USE, `${eventAction} a behavior`, {
    goalId,
    sessionId,
    screenId,
    customId: option.optionId,
    subcategory: 'behavior'
  });
  logger.info(
    'User has selected a behavior.',
    'Behavior.utils.onSelectHandler',
    {
      behavior,
      goalId,
      sessionId,
      screenId,
      optionId: option.optionId,
      isSelected
    }
  );

  const progress = cloneDeep(currentProgress) || [];
  const optionIndex = progress.findIndex(
    (currentOption) => currentOption.optionId === option.optionId
  );
  const date = moment().locale('en').utc().format();
  if (optionIndex === -1) {
    progress.push({
      ...option,
      isCustom: false,
      isSelected,
      updatedAt: date,
      createdAt: date,
      type: behavior
    });
  } else {
    progress[optionIndex] = {
      ...progress[optionIndex],
      isCustom: progress[optionIndex].isCustom || false,
      isSelected,
      updatedAt: date,
      type: behavior
    };
  }
  setCurrentProgress(progress);
};

const onSaveCustomOptionHandler = ({
  behavior,
  goalId,
  sessionId,
  screenId,
  option,
  currentProgress,
  currentBehaviorData,
  setCurrentProgress,
  setCurrentBehaviorData
}) => {
  analytics.track(
    eventCategories.SELF_USE,
    `clicked on save ${behavior} custom option`,
    {
      goalId,
      sessionId,
      screenId,
      customId: option.optionId,
      subcategory: 'behavior'
    }
  );
  logger.info(
    'User has added a custom behavior.',
    'Behavior.utils.onSaveCustomOptionHandler',
    {
      behavior,
      goalId,
      sessionId,
      screenId,
      optionId: option.optionId
    }
  );

  const updatedBehaviorData = {
    ...currentBehaviorData,
    [behavior]: {
      ...currentBehaviorData[behavior],
      options: [...currentBehaviorData[behavior].options, option]
    }
  };
  setCurrentBehaviorData(updatedBehaviorData);
  const progress = cloneDeep(currentProgress) || [];
  const date = moment().locale('en').utc().format();
  progress.push({
    ...option,
    isCustom: true,
    isSelected: true,
    updatedAt: date,
    createdAt: date,
    type: behavior
  });
  setCurrentProgress(progress);
};

const getConsolidatedList = ({
  behavior,
  customOptions = [],
  staticOptions = []
}) => {
  if (isEmpty(customOptions)) {
    return staticOptions;
  }

  const filteredCustomOptions = [];
  for (let i = 0; i < customOptions.length; i += 1) {
    const option = staticOptions.find(
      (currentOption) => currentOption.optionId === customOptions[i].optionId
    );
    if (isEmpty(option) && customOptions[i].type === behavior) {
      filteredCustomOptions.push(customOptions[i]);
    }
  }

  return [...staticOptions, ...filteredCustomOptions];
};

const behaviorDataHandler = ({
  data = {},
  currentSelectedOptions = [],
  setCurrentBehaviorData
}) => {
  const behaviorData = cloneDeep(data);
  const behaviorList = Object.keys(data);
  for (let i = 0; i < behaviorList.length; i += 1) {
    behaviorData[behaviorList[i]].options = getConsolidatedList({
      behavior: behaviorList[i],
      customOptions: currentSelectedOptions, // need to update to support different behavior
      staticOptions: behaviorData[behaviorList[i]].options
    });
  }
  setCurrentBehaviorData(behaviorData);
};

const onChangeTabHandler = ({ goalId, sessionId, screenId, tab }) => {
  analytics.track(eventCategories.SELF_USE, `clicked on ${tab} tab.`, {
    goalId,
    sessionId,
    screenId,
    subcategory: 'behavior'
  });
  logger.info(
    'User has clicked on behavior tab.',
    'Behavior.utils.onChangeTabHandler',
    {
      tab,
      goalId,
      sessionId,
      screenId
    }
  );
};

const getBehaviorData = (screenContent) => {
  const checklistArr = get(screenContent, 'checklist', []);
  const behavior = {};
  for (let i = 0; i < checklistArr.length; i += 1) {
    const behaviorType = supportedBehaviors[checklistArr[i].type];
    if (behaviorType) {
      behavior[behaviorType] = pick(checklistArr[i], [
        'checklistId',
        'options',
        'customOptionPlaceholder',
        'allowCustom'
      ]);
    } else {
      logger.warn('Unsupported behavior.', 'Behavior.utils.getBehaviorData', {
        behavior: checklistArr[i].type,
        supportedBehaviors
      });
    }
  }
  return behavior;
};

const validateScreenData = ({ screenContent, descriptionV2, subheader }) => {
  const isScreenContentEmpty = isEmpty(screenContent);
  if (isScreenContentEmpty || isEmpty(descriptionV2) || isEmpty(subheader)) {
    logger.error(
      'Data missing to render behavior screen.',
      'Behavior.utils.validateScreenData',
      {
        subheader,
        descriptionV2,
        isScreenContentEmpty
      }
    );
    return false;
  }
  return true;
};

const validateCustomInput = ({ text, options }) => {
  let errorText = '';
  const filteredText = text.replace(/\s+/g, ' ').trim();
  if (isEmpty(filteredText)) {
    errorText = i18n.t('selfUse.session.validations.emptyField', {
      field: i18n.t('selfUse.session.fields.behavior')
    });
    return { isValid: false, errorText };
  }
  const optionIndex = options.findIndex(
    (option) => option.text.toLowerCase().trim() === filteredText.toLowerCase()
  );

  if (optionIndex !== -1) {
    errorText = i18n.t('selfUse.session.validations.duplicateEntry', {
      field: i18n.t('selfUse.session.fields.behavior')
    });
    return { isValid: false, errorText };
  }
  return { isValid: true };
};

export {
  onCancelHandler,
  onAddHandler,
  onSelectHandler,
  onSaveCustomOptionHandler,
  getConsolidatedList,
  behaviorDataHandler,
  validateScreenData,
  validateCustomInput,
  getBehaviorData,
  behaviorTabIconMap,
  onChangeTabHandler
};
