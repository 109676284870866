/**
 * Reducer to store media in coach chat
 */

import {
  FETCH_MEDIA_LIST_START,
  FETCH_MEDIA_LIST_DONE,
  FETCH_MEDIA_LIST_ERROR
} from '../../actions/types';

const INITIAL_STATE = {
  mediaList: {},
  isFetching: false,
  isError: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MEDIA_LIST_START: {
      return {
        ...state,
        isFetching: true,
        isError: false
      };
    }
    case FETCH_MEDIA_LIST_DONE: {
      return {
        ...state,
        mediaList: action.mediaList,
        isFetching: false,
        isError: false
      };
    }
    case FETCH_MEDIA_LIST_ERROR: {
      return {
        ...state,
        isFetching: false,
        isError: true
      };
    }
    default:
      return state;
  }
};
