import logger from '../utils/logger';

const handleUnexpectedError = (errorMessage, errorSource, error) => {
  if (error.message === 'Access token expired') {
    logger.info(`${errorMessage}`, `${errorSource}`, { error });
  } else if (error.toString() === 'Error: Product expired') {
    logger.info(`Product Expired: ${errorMessage}`, `${errorSource}`, {
      error
    });
  } else if (error.toString() === 'Cancel') {
    logger.info(`Request timed out : ${errorMessage}`, `${errorSource}`, {
      error
    });
  } else if (error.toString() === 'Error: Network Error') {
    logger.info(
      `Network error, request timed out or response was not received : ${errorMessage}`,
      `${errorSource}`,
      { error }
    );
  } else {
    logger.error(`${errorMessage}`, `${errorSource}`, { error });
  }
};

export default handleUnexpectedError;
