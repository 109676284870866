/**
 * Router component that manages all our app routes
 */
// core
import React, { useEffect } from 'react';
import { isLoggedIn } from 'utils/auth';
import { PushNotifications } from '@capacitor/push-notifications';
import { Keyboard } from '@capacitor/keyboard';
import { IonApp, isPlatform, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useDispatch, useSelector } from 'react-redux';

import { MuiThemeProvider } from '@material-ui/core';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import DateFnsUtils from '@date-io/moment';
import theme from 'theme';
import { App } from '@capacitor/app';
import { get } from 'lodash';
// components
import Modal from 'components/Modal';
import VideoPlayerModal from 'components/VideoPlayerModal';
// store actions
import fetchQuestionsAndInterstitials from 'store/actions/AssessmentAction';
import getReportList from 'store/actions/ReportListAction';
import setSessionExpired from 'store/actions/SessionExpiredAction';
import { closeModal } from 'store/actions/ModalAction';
import { keyboardShown, keyboardClosed } from 'store/actions/KeyboardAction';
import {
  videoFullScreenActive,
  videoFullScreenInactive
} from 'store/actions/VideoFullScreenAction';
// utils
import history from 'utils/history';
import analytics from 'utils/analytics';
import { isNativePlatform } from 'utils/capacitor';
// containers
import getVideoFullScreenDetails from 'containers/SelfUse/components/Session/VideoScreen/videoScreen.utils';
// config
import config from 'config';
// routes
import Routes from './routes';
import { registerPushListener } from './Router.utils';
import platform from '../assets/data/platform-types';

let unlisten;

setupIonicReact({
  swipeBackEnabled: false
});

const Router = () => {
  const dispatch = useDispatch();

  const { token, userId, updatedOn } = useSelector((state) =>
    get(state, 'pushNotificationData')
  );
  const { fullscreenchangeEventName, isfullScreenSupported } =
    getVideoFullScreenDetails();
  useEffect(() => {
    // sets keyboard state to closed whenever app is opened
    dispatch(keyboardClosed());
    if (isPlatform(platform.CAPACITOR)) {
      window.addEventListener('ionKeyboardDidShow', () => {
        dispatch(keyboardShown());
      });

      window.addEventListener('ionKeyboardDidHide', () => {
        dispatch(keyboardClosed());
      });
      Keyboard.setScroll({ isDisabled: false });
      Keyboard.setResizeMode({ mode: 'native' });
    }

    // This components gets mounted first, do stuff if user is logged in
    if (isLoggedIn()) {
      dispatch(fetchQuestionsAndInterstitials());
      dispatch(getReportList());
      dispatch(setSessionExpired(false));
    }

    unlisten = history.listen((location, action) => {
      // scroll to top of the page
      setTimeout(() => window.scrollTo(0, 0), 100);
      if (action === 'POP') {
        // handling the back button click
        dispatch(closeModal());
      }
    });

    // Support routing with capacitor app URL
    App.addListener('appUrlOpen', (event) => {
      const slug = event.url.split(config.mobileAppUrl).pop();
      if (slug) {
        history.push(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });

    return () => {
      unlisten();
    };
  }, [dispatch]);

  // Full screen change event listener for player container div
  useEffect(() => {
    const changeStateOnFullScreen = () => {
      const isVideoInFullScreen = !!(
        document.webkitFullscreenElement ||
        document.fullscreenElement ||
        null
      );
      if (isVideoInFullScreen) {
        dispatch(videoFullScreenActive());
      } else {
        dispatch(videoFullScreenInactive());
      }
    };
    if (isfullScreenSupported) {
      // Handles orientation of app in portrait mode
      dispatch(videoFullScreenInactive());
      document.addEventListener(
        fullscreenchangeEventName,
        changeStateOnFullScreen,
        false
      );
    }
    return () => {
      if (isfullScreenSupported) {
        document.removeEventListener(
          fullscreenchangeEventName,
          changeStateOnFullScreen
        );
      }
    };
  }, [dispatch, fullscreenchangeEventName, isfullScreenSupported]);
  useEffect(() => {
    registerPushListener({
      existingPushToken: token,
      existingUserId: userId,
      updatedOn,
      dispatch
    });

    return () => {
      if (isNativePlatform) {
        PushNotifications.removeAllListeners();
      }
    };
  }, [dispatch, token, updatedOn, userId]);

  return (
    <IonApp>
      <IonReactRouter history={history}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={theme}>
            <Routes />
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </IonReactRouter>
      <Modal />
      <VideoPlayerModal />
    </IonApp>
  );
};

history.listen((location) => {
  analytics.screen('app', 'redirect', { url: location.pathname });
});

export default Router;
