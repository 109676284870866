import Bowser from 'bowser';
import PLATFORM_TYPES from 'assets/data/platform-types';
import logger from './logger';

const getBowserParser = () => {
  try {
    return Bowser.getParser(window.navigator.userAgent);
  } catch (error) {
    logger.error('Error getting Bowser parser', 'bowser.getBowserParser', {
      error,
      userAgent: window.navigator.userAgent
    });
    throw error;
  }
};

const getPlatformType = () => {
  try {
    return getBowserParser().getPlatformType();
  } catch (error) {
    logger.error('Error getting platform type', 'bowser.getPlatformType', {
      error
    });
    // default platform type is desktop
    return PLATFORM_TYPES.DESKTOP;
  }
};

const platformType = getPlatformType();

export { getBowserParser, getPlatformType, platformType };
