/**
 * Contains the color assigned per category.
 * In future could be fetched from server
 */

export default {
  sleep: '#ffc332',
  substanceUse: '#d29604',
  pts: '#e78555',
  anxiety: '#ff715b',
  relationshipConflict: '#a07cba',
  stress: '#0f89cf',
  depression: '#5977b3',
  workEngagement: '#2c9aa8',
  anger: '#26bcb3',
  substanceAbuse: '#32903e',
  additional: '#65bd36'
};
