import React, { Component } from 'react';
import _, { uniqBy } from 'lodash';
import { Row, Col } from 'antd';
import {
  colorGoalSeverityHigh,
  colorGoalSeverityModerate,
  colorGoalSeverityLow
} from 'styles/config.scss';
import goalIconList, { goalIcon } from '../../../../assets/data/goalIconList';
import i18n from '../../../../assets/lang';
import styles from './ItemContent.module.scss';
import RenderHtml from '../../../../components/RenderHtml';
import { ActionButton } from '../../../../components/ui/Buttons';
import Lang from '../../../../components/Lang';

const {
  eapScore,
  eapContent,
  lineOpacity,
  categoryHeading,
  categoryImage,
  eapImage,
  collapseButton,
  alertText,
  contactEapButton,
  eapInfoContainer,
  eapNameStyle,
  eapImageContainer
} = styles;

const levelStyles = {
  low: {
    borderTop: `5px solid ${colorGoalSeverityLow}`
  },
  moderate: {
    borderTop: `5px solid ${colorGoalSeverityModerate}`
  },
  high: {
    borderTop: `5px solid ${colorGoalSeverityHigh}`
  }
};

class ItemContent extends Component {
  _renderItemHeaderOpen = (categoryName, name) => (
    <div
      className={collapseButton}
      role="button"
      onKeyUp={() => this.props.onCollapse()}
      tabIndex={0}
      onClick={() => this.props.onCollapse()}
    >
      <Row>
        <Col
          span={12}
          className={[categoryHeading, 'goal-title-locator'].join(' ')}
        >
          {categoryName}
        </Col>
        <Col span={12}>
          <img
            className={categoryImage}
            src={`${goalIconList[name]}` || goalIcon}
            alt=""
            height="104"
            width="100"
          />
        </Col>
      </Row>
    </div>
  );

  _renderEapSection = () => {
    const { eapIcon, eapName } = this.props;
    return (
      <Row className={eapInfoContainer}>
        <Col xs={{ span: 20, offset: 2 }} lg={{ span: 10, offset: 2 }}>
          <div className={eapNameStyle}>{eapName}</div>
          <span
            data-testid="contact-button"
            role="button"
            className={contactEapButton}
            tabIndex={0}
            onKeyUp={this.props.onEAP}
            onClick={this._handleEap}
          >
            <Lang path="contactButtonText" />
          </span>
        </Col>
        <Col
          xs={{ span: 20, offset: 2 }}
          lg={{ span: 10, offset: 2 }}
          className={eapImageContainer}
        >
          {eapIcon && <img className={eapImage} src={eapIcon} alt={eapName} />}
        </Col>
      </Row>
    );
  };

  _handleLevelText = (level) => `levelTexts.${level}Text`;

  _renderLevelText = () =>
    _.get(this, 'props.levelText', []).length > 0 && (
      <React.Fragment>
        <hr className={lineOpacity} />
        <span className={eapScore}>
          <Lang
            path="reportGoalScoreText"
            values={{ score: i18n.t(this._handleLevelText(this.props.level)) }}
          />
        </span>
        {this.props.levelText.map((item, index) => (
          <div key={index}>
            <p className={eapContent}>{item}</p>
            <hr className={lineOpacity} />
            {this.props.showEAP && this._renderEapSection()}
          </div>
        ))}
      </React.Fragment>
    );

  _renderAlertText = () => {
    if (this.props.alerts) {
      // Multiple alerts with same content can be triggered for an assessment,
      // Using uniqBy function to only show unique alerts
      const alerts = uniqBy(this.props.alerts, 'text');
      return alerts.map((item, index) => (
        <p className={alertText} key={index}>
          <strong>
            <RenderHtml>{item.text}</RenderHtml>
          </strong>
        </p>
      ));
    }
    return null;
  };

  _renderDesc = () =>
    this.props.desc.map((item, index) => (
      <div key={index} className={eapContent}>
        <p>{item.text}</p>
      </div>
    ));

  _handleEap = () => {
    this.props.onEAP(this.props.name);
  };

  _handleGoal = () => {
    this.props.onGoal(this.props.name, this.props.categoryName);
  };

  render() {
    const { container, buttonContainer } = styles;

    return (
      <div
        className={container}
        style={this.props.expanded ? levelStyles[this.props.level] : null}
      >
        {this._renderItemHeaderOpen(this.props.categoryName, this.props.name)}
        {this._renderLevelText()}
        {this.props.showAlert && this._renderAlertText()}
        {this.props.showEAP && <hr className={lineOpacity} />}
        {this._renderDesc()}
        {this.props.hasContentAccess && (
          <div className={buttonContainer}>
            <ActionButton onClick={this._handleGoal}>
              <span data-testid="start-now-button">
                <Lang path="startNowButtonText" />
              </span>
            </ActionButton>
          </div>
        )}
      </div>
    );
  }
}

export default ItemContent;
