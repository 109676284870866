import { makeStyles } from '@material-ui/core';
import { brandColorPrimary } from 'styles/config.scss';

export default makeStyles({
  root: {
    border: `1px solid ${brandColorPrimary}80`, // 0.5 alpha = 80 in hex
    backgroundColor: 'rgba(255,255,255,1)',
    color: 'rgba(0,0,0,1)',
    borderRadius: 20,
    textAlign: 'center'
  },
  input: {
    textAlign: 'center'
  }
});
