import React, { Fragment, useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
// Components
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import analytics from 'utils/analytics';
import SendIcon from 'assets/icons/send.svg';
import videoPlaceHolderImg from 'assets/img/videoPlaceHolderImg.png';
import config from 'config';
import { MediaPreview } from '../../../../../components/Coaching';
import styles from './CoachChatInput.module.scss';
import sliderConfig from './Slider/sliderConfig';
import CoachSuggestion from '../CoachSuggestion';

const {
  container,
  inputStyle,
  sendButtonStyle,
  messageContainer,
  previewContainer,
  mediaContainer,
  sliderSection
} = styles;
const { contentVersionMap } = config;

const CoachChatInput = ({
  sendMessage,
  mediaMessages,
  setMediaMessages,
  coachSuggestionsData,
  showCoachSuggestions,
  setCoachSuggestionsVisible
}) => {
  const [message, setMessage] = useState('');
  const [selectedSuggestion, setSelectedSuggestion] = useState('');
  const textAreaRef = useRef(null);
  const { t } = useTranslation();
  const _handleKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      sendMessage({
        message,
        media: mediaMessages,
        contentVersionMap: JSON.parse(contentVersionMap)
      });
      setMessage('');
      setMediaMessages([]);
      if (showCoachSuggestions) {
        setCoachSuggestionsVisible(false);
      }
      textAreaRef.current.style.height = '';
    }
  };

  const _handleChange = (e) => {
    setMessage(e.target.value);
  };

  const _sendMessage = () => {
    sendMessage({
      message,
      media: mediaMessages,
      suggestions: coachSuggestionsData,
      contentVersionMap: JSON.parse(contentVersionMap)
    });
    setMessage('');
    setMediaMessages([]);
    setSelectedSuggestion('');
    if (showCoachSuggestions) {
      setCoachSuggestionsVisible(false);
    }
  };

  const onRemoveMedia = (id) => {
    const updatedMediaMessages = mediaMessages.filter(
      (mediaMessage) => mediaMessage.id !== id
    );
    setMediaMessages(updatedMediaMessages);
  };

  const _onSuggestionSelect = ({ text, intentScore }) => {
    setMessage(text);
    setSelectedSuggestion(text);
    analytics.track('Chat', 'suggestion being picked', {
      intentScore
    });
  };

  useEffect(() => {
    textAreaRef.current.focus();
  }, [selectedSuggestion]);

  useEffect(() => {
    textAreaRef.current.style.height = '';
    const maxHeight = 50;
    const { scrollHeight } = textAreaRef.current;
    textAreaRef.current.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
  }, [message, selectedSuggestion]);

  return (
    <Fragment>
      {showCoachSuggestions &&
        _.get(coachSuggestionsData, 'getCoachSuggestions.suggestions.text', [])
          .length > 0 && (
          <>
            <Slider {...sliderConfig} className={`${sliderSection}`}>
              {_.get(
                coachSuggestionsData,
                'getCoachSuggestions.suggestions.text',
                []
              ).map((suggestion, index) => (
                <CoachSuggestion
                  index={index}
                  key={index}
                  text={suggestion.message}
                  intentScore={suggestion.intentScore}
                  onSuggestionSelect={_onSuggestionSelect}
                />
              ))}
            </Slider>
          </>
        )}
      <div
        className={
          !_.isEmpty(mediaMessages)
            ? [container, mediaContainer].join(' ')
            : container
        }
      >
        <div
          data-testid="coach-chat-input-container"
          className={messageContainer}
        >
          {!_.isEmpty(mediaMessages) && (
            <div className={previewContainer}>
              {mediaMessages.map((mediaMessage) => (
                <MediaPreview
                  key={mediaMessage.id}
                  title={mediaMessage.subHeader}
                  mediaThumbnail={videoPlaceHolderImg}
                  removeMedia={() => onRemoveMedia(mediaMessage.id)}
                />
              ))}
            </div>
          )}
          <textarea
            data-testid="chat-input"
            wrap="soft"
            ref={textAreaRef}
            rows={1}
            name="message"
            value={message}
            onChange={_handleChange}
            className={inputStyle}
            onKeyDown={_handleKeyDown}
            placeholder={t('chatInputPlaceholder')}
            autoComplete="off"
          />
        </div>
        <div>
          <button
            data-testid="send-chat"
            type="button"
            className={sendButtonStyle}
            onClick={_sendMessage}
          >
            <img src={SendIcon} alt={t('alternateText.button.send')} />
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default CoachChatInput;
