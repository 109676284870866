import { createSelector } from 'reselect';
import _ from 'lodash';

export const selectStateList = (state) =>
  _.get(state, 'companyDetails.companyDetails.stateList', []);

export const getSelectStateList = createSelector(
  [selectStateList],
  (stateList) => stateList.map((state) => ({ label: state, value: state }))
);
