/**
 *
 * GetHelpNow
 *
 */

import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import analytics from 'utils/analytics/analytics';
import styles from './GetHelpNow.module.scss';
import ActionButton from '../../../../components/ui/Buttons/ActionButton/ActionButton';
import Lang from '../../../../components/Lang';
import i18n from '../../../../assets/lang';

const { container, topContainer, bottomContainer, getHelpText } = styles;

const emergencyContactHandler = (goalId, severity, eapPhone) => {
  analytics.track('dashboard', 'clicked on eap number', {
    goalId,
    severity,
    eapPhone
  });
  window.location.href = `tel:${eapPhone}`;
};

function GetHelpNow({ eapPhone, goalId, severity }) {
  // TODO: Remove check for United states when emergency number is setup for other countries
  const phone = eapPhone || i18n.t('getHelpPopupDefaultPhone');
  const country = useSelector((state) =>
    _.get(state, 'profile.profile.country', '')
  );
  return (
    <div className={container}>
      <div className={topContainer}>
        <h2 data-testid="header-text">
          <Lang path="getHelpPopupHeader" />
        </h2>
        <p data-testid="sub-header-text">
          <Lang path="getHelpPopupDesc" />
          {country === 'United States' && (
            <Lang
              path="getHelpPopupDescForUS"
              values={{
                emergencyPhoneNumber: i18n.t('getHelpPopupDefaultPhone')
              }}
            />
          )}
          .
        </p>
      </div>
      <div className={bottomContainer}>
        <h4>
          <ActionButton
            className={getHelpText}
            onClick={() => emergencyContactHandler(goalId, severity, phone)}
          >
            <i data-testid="phone-icon" className="material-icons">
              call
            </i>
            <span>
              <Lang
                path="getHelpPopupCall"
                values={{
                  eapPhone: phone
                }}
              />
            </span>
          </ActionButton>
        </h4>
      </div>
    </div>
  );
}

export default GetHelpNow;
