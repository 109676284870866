/* eslint-disable import/no-cycle */
import _ from 'lodash';
import getLastMessageId from './get-last-message-id';
import logger from '../../logger';
import handleGraphqlError from '../helper';
import { FETCH_POLICIES } from '../graphQL-config';

export default ({
  client,
  messages,
  catchUpQuery,
  query,
  to,
  from,
  isCoach = false
}) => {
  const lastMessageFetchedFromServerId = getLastMessageId(messages);
  if (!lastMessageFetchedFromServerId && messages.length > 0) {
    logger.error(
      "Couldn't get the last message id",
      'ChatWindow.handleStateOnline'
    );
    return;
  }
  const variables = {};
  if (lastMessageFetchedFromServerId) {
    variables.after = lastMessageFetchedFromServerId;
  }
  if (to) {
    variables.to = to;
  }
  if (from) {
    variables.from = from;
  }
  client
    .query({
      query: catchUpQuery,
      fetchPolicy: FETCH_POLICIES.networkOnly,
      variables
    })
    .then((resp) => {
      const newMessages = _.get(resp, 'data.getChatMessages.messages', []);
      if (newMessages.length > 0) {
        const queryObject = { query };
        if (isCoach) {
          queryObject.variables = { to, from };
        }
        const dataProxy = client.readQuery({ query });
        const currentLastMessageId = getLastMessageId(
          dataProxy.getChatMessages.messages
        );
        if (currentLastMessageId !== lastMessageFetchedFromServerId) {
          logger.info(
            'Last Message id is different from when request was initiated, skipping update',
            'syncWithServer'
          );
          return;
        }
        queryObject.data = {
          ...dataProxy,
          getChatMessages: {
            ...dataProxy.getChatMessages,
            messages: _.concat(dataProxy.getChatMessages.messages, newMessages)
          }
        };
        client.writeQuery(queryObject);
      }
    })
    .catch((error) => {
      handleGraphqlError(
        'An error occurred while syncing messages',
        'syncWithServer',
        error
      );
    });
};
