import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import propTypes from 'prop-types';
import CancelIcon from '@material-ui/icons/Cancel';
import classNamesInstance from 'classnames/bind';
// assets
import completedIcon from 'assets/icons/session/completed.svg';
import screenTypes from 'assets/data/self-use/screenTypes';
import i18n from 'assets/lang';
// styles
import styles from './EditableField.module.scss';

const {
  editFieldActionContainer,
  cancelIconStyle,
  editFieldContainer,
  completedIconStyle,
  textErrorStyle,
  actionButtonContainer
} = styles;

const classNames = classNamesInstance.bind(styles);

const EditableField = ({
  onSave,
  onCancel,
  testId,
  isEditing,
  placeholder,
  errorMessage,
  showControls,
  onKeyUpHandler,
  defaultValue,
  screenType,
  setCurrentTextBoxInput
}) => {
  const textFieldRef = useRef(null);
  const [value, setValue] = useState(defaultValue);
  const { isOpen: isKeyboardOpen } = useSelector((state) =>
    get(state, 'keyboard')
  );

  useEffect(() => {
    if (isEditing) {
      textFieldRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    if (isKeyboardOpen && textFieldRef.current && isEditing) {
      setTimeout(() => {
        textFieldRef.current.scrollIntoView({ block: 'center' });
      }, 1000);
    }
  }, [isEditing, isKeyboardOpen, testId]);

  const onChange = () => {
    setValue(textFieldRef.current.value);
    setCurrentTextBoxInput(textFieldRef.current.value);
  };

  // Conditional classames
  const textFieldClass = classNames({
    editFieldStyle: true,
    invalidInputBorderStyle: errorMessage,
    textInputHeightStyle: screenTypes.textInput === screenType
  });

  return (
    <div
      data-testid={`${testId}-custom-input-container`}
      className={editFieldContainer}
    >
      <textarea
        data-testid={`${testId}-custom-input-text-area`}
        placeholder={placeholder}
        value={value}
        className={textFieldClass}
        ref={textFieldRef}
        onChange={() => onChange()}
        onKeyUp={() => onKeyUpHandler(value)}
      />
      <div className={editFieldActionContainer}>
        {errorMessage && (
          <p data-testid="validation-error-message" className={textErrorStyle}>
            {errorMessage}
          </p>
        )}
        {showControls && (
          <div className={actionButtonContainer}>
            <CancelIcon
              data-testid={`${testId}-cancel-icon`}
              style={{ fontSize: 27 }}
              onClick={() => onCancel()}
              className={cancelIconStyle}
            />
            <span
              tabIndex={0}
              role="button"
              onClick={() => onSave(textFieldRef.current.value)}
              onKeyDown={() => onSave(textFieldRef.current.value)}
            >
              <img
                data-testid={`${testId}-completed-icon`}
                className={completedIconStyle}
                src={completedIcon}
                alt={i18n.t('alternateText.image.save')}
              />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

EditableField.propTypes = {
  onSave: propTypes.func,
  onCancel: propTypes.func,
  testId: propTypes.string,
  isEditing: propTypes.bool,
  showControls: propTypes.bool,
  placeholder: propTypes.string,
  defaultValue: propTypes.string,
  errorMessage: propTypes.string,
  onKeyUpHandler: propTypes.func,
  screenType: propTypes.string,
  setCurrentTextBoxInput: propTypes.func
};

EditableField.defaultProps = {
  onSave: () => null,
  onCancel: () => null,
  testId: '',
  isEditing: false,
  showControls: false,
  placeholder: '',
  errorMessage: '',
  defaultValue: '',
  onKeyUpHandler: () => null,
  screenType: '',
  setCurrentTextBoxInput: () => null
};

export default React.memo(EditableField);
