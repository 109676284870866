import parentingImg from 'assets/img/dashboard/worklife/parenting.jpg';
import financeImg from 'assets/img/dashboard/worklife/finance.jpg';
import childCareImg from 'assets/img/dashboard/worklife/childcare.jpg';
import elderCareImg from 'assets/img/dashboard/worklife/eldercare.jpg';
import housingImg from 'assets/img/dashboard/worklife/housing.jpg';
import legalImg from 'assets/img/dashboard/worklife/legal.jpg';
import dailyLifeImg from 'assets/img/dashboard/worklife/dailylife.jpg';
import healthCareImg from 'assets/img/dashboard/worklife/healthcare.jpg';

export default {
  child: childCareImg,
  elder: elderCareImg,
  dailyliving: dailyLifeImg,
  dl_parenting: parentingImg,
  dl_legal: legalImg,
  housing: housingImg,
  dl_health: healthCareImg,
  dl_financial: financeImg
};
