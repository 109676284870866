/**
 *
 * ActionPlan
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import getGoalActionPlans from 'assets/data/dashboard/goalActionPlans';
import { showModal } from 'store/actions/ModalAction';
import featureService from 'utils/feature-service';
import styles from './ActionPlan.module.scss';
// components
import FocusAreaCard from '../FocusAreaCard';
import SelfGuided from '../SelfGuided';
import TextCoaching from '../TextCoaching';
import VideoTherapy from '../VideoTherapy';
import InPersonTherapy from '../InPersonTherapy/InPersonTherapy';
import TherapyCard from '../TherapyCard';
import Lang from '../../../../components/Lang';

const {
  container,
  focusAreaLabelStyle,
  focusCardContainer,
  recommendedOptionContainer,
  recommendedLabelStyle,
  otherOptionsContainer,
  otherOptionLabelStyle
} = styles;
class ActionPlan extends Component {
  _mapComponentToPlan = ({ componentType, ...props }) => {
    switch (componentType) {
      case 'self':
        return <SelfGuided key={props.index} {...props} />;
      case 'text':
        return <TextCoaching key={props.index} {...props} />;
      case 'video':
        if (
          featureService._hasTherapyAccessFeature() ||
          featureService._hasTherapyAccessWithEAPFeature()
        ) {
          return <TherapyCard key={props.index} {...props} />;
        }
        return (
          featureService._hasVideoTherapyFeature() && (
            <VideoTherapy key={props.index} {...props} />
          )
        );
      case 'inperson':
        return (
          featureService._hasInpersonTherapyFeature() && (
            <InPersonTherapy key={props.index} {...props} />
          )
        );
      default:
        return null;
    }
  };

  render() {
    const { goalId, severity, action, sessionCount, planOrderData } =
      this.props;
    // Get the color class for the severity

    const goalActionPlans = getGoalActionPlans();
    const actionPlanData = goalActionPlans[goalId] || goalActionPlans.sleep;

    const { categoryId, categoryName, actionPlans } = actionPlanData;

    const planOrder = planOrderData[goalId] || planOrderData.sleep;

    // We are adding feature check to filter out the text coaching from action plans
    const sortedActionPlans = [];
    planOrder[severity].forEach((item) => {
      if (sessionCount[item][severity] === 0) {
        return;
      }
      if (item === 'text' && !featureService._hasCoachAccessFeature()) {
        return;
      }
      if (
        item === 'video' &&
        !(
          featureService._hasVideoTherapyFeature() ||
          featureService._hasTherapyAccessFeature() ||
          featureService._hasTherapyAccessWithEAPFeature()
        )
      ) {
        return;
      }

      const planData = actionPlans.find((planItem) => {
        if (planItem.componentType === item) {
          return planItem;
        }
        return null;
      });
      if (!_.isEmpty(planData)) {
        sortedActionPlans.push(planData);
      }
    });
    const { bigCardText } = sortedActionPlans.find(
      (item) => item.componentType === 'self'
    );
    return (
      <div className={container}>
        <div className={focusCardContainer} data-testid="focus-area-container">
          <span className={focusAreaLabelStyle}>
            <Lang path="focusArea" />
          </span>
          <FocusAreaCard
            categoryName={categoryName}
            categoryId={categoryId}
            bigCardText={bigCardText}
          />
        </div>
        {sortedActionPlans.length > 0 && (
          <div
            data-testid="recommended-sol-container"
            className={recommendedOptionContainer}
          >
            <span className={recommendedLabelStyle}>
              <Lang path="recommended" />
            </span>
            {sortedActionPlans.slice(0, 1).map((e, i) =>
              this._mapComponentToPlan({
                ...e,
                categoryName,
                categoryId,
                showModal: action.showModal,
                index: i,
                type: 'large'
              })
            )}
          </div>
        )}
        {sortedActionPlans.length > 1 && (
          <div
            data-testid="other-options-container"
            className={otherOptionsContainer}
          >
            <span className={otherOptionLabelStyle}>
              <Lang path="otherOptions" />
            </span>
            {sortedActionPlans.slice(1, 3).map((e, i) =>
              this._mapComponentToPlan({
                ...e,
                categoryName,
                categoryId,
                showModal: action.showModal,
                index: i + 1,
                type: sortedActionPlans.length === 2 ? 'large' : 'small'
              })
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    dashBoard: { goalId, severity, sessionCount },
    companyDetails: {
      companyDetails: { planOrder }
    }
  } = state;

  return {
    goalId: goalId.toLowerCase(),
    severity,
    sessionCount,
    planOrderData: planOrder
  };
};

const mapDispatchToProps = (dispatch) => ({
  action: {
    showModal: bindActionCreators(showModal, dispatch)
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionPlan);
