/**
 * Gender Options for the registration form
 */
import React from 'react';
import Lang from '../../../components/Lang';

export default [
  {
    value: 'female',
    label: <Lang path="genderOptions.female" />
  },
  {
    value: 'male',
    label: <Lang path="genderOptions.male" />
  },
  {
    value: 'transgenderfemale',
    label: <Lang path="genderOptions.transgenderfemale" />
  },
  {
    value: 'transgendermale',
    label: <Lang path="genderOptions.transgendermale" />
  },
  {
    value: 'variant',
    label: <Lang path="genderOptions.variant" />
  },
  {
    value: 'notlisted',
    label: <Lang path="genderOptions.notlisted" />
  },
  {
    value: 'notanswer',
    label: <Lang path="genderOptions.notanswer" />
  }
];
