/**
 * Session list page container
 */
// Core
import React, { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { IonContent } from '@ionic/react';
import { useParams } from 'react-router-dom';
import { NetworkStatus } from '@apollo/client';
import { useSelector } from 'react-redux';
import classNamesInstance from 'classnames/bind';
// utils
import history from 'utils/history';
import logger from 'utils/logger';
import analytics from 'utils/analytics';
import { FETCH_POLICIES } from 'utils/graphql';
import eventCategories from 'utils/analytics/categories';
import featureService from 'utils/feature-service';
import helpers from 'utils/helpers';
// hocs
import withAuth from 'hoc/withAuth';
import wrapWithApolloClient from 'hoc/wrapWithApolloClient';
// assets
import i18n from 'assets/lang';
import screenTypes from 'assets/data/self-use/screenTypes';
// Components
import { Spinner } from 'components/ui/Layout';
import { ActionButton, OutlineButton } from 'components/ui/Buttons';
import Error from 'components/Error';
import ErrorRetry from 'components/ErrorRetry';
import toast from 'components/Toast';
import Lang from 'components/Lang';
import config from 'config';

import InProgressPopup from './InProgressPopup/InProgressPopup';
import Completion from '../components/Completion';
import Tracker from '../components/Session/Tracker';
import TextScreen from '../components/Session/TextScreen';
import ThoughtRecorder from '../components/Session/ThoughtRecorder';
import BehaviorScreen from '../components/Session/Behavior';
import VideoScreen from '../components/Session/VideoScreen';
import TextInputScreen from '../components/Session/TextInputScreen/TextInputScreen';
import SituationScreen from '../components/Session/SituationScreen';
import ChecklistScreen from '../components/Session/ChecklistScreen';

// styles
import styles from './Session.module.scss';
// config
import { getSessionOptions } from '../mutationOptions';
import { UPSERT_SESSION_PROGRESS } from '../mutations';
import {
  GET_SELECTED_GOAL_INFO,
  GET_SELECTED_SESSION_INFO,
  GET_SESSION_LIST
} from '../queries';

const { getSupportedScreenTypes } = helpers;
const supportedScreenTypes = getSupportedScreenTypes();
const { supportEmail } = config;
const {
  container,
  topPanelStyle,
  screenDotsStyle,
  activeDotStyle,
  inactiveDotStyle,
  dotStyle,
  buttonStyle,
  spinnerContainerStyle,
  spinnerTextStyle,
  sessionNameStyle,
  popupButtonStyle,
  navContainer
} = styles;

const classNames = classNamesInstance.bind(styles);
const footerContent = [
  <ActionButton onClick={() => history.push('/self-use')}>
    <Lang path="selfUse.session.error.buttonText" />
  </ActionButton>
];
// Function to render error screen
const renderError = (errorMessagePath) => (
  <Error message={<Lang path={errorMessagePath} />} footer={footerContent} />
);

// Function to render dots on basis of total allowed screen
const renderScreenDots = (screensData, currentScreenIndex) => {
  const html = [];
  screensData.forEach((screen, index) => {
    if (supportedScreenTypes.includes(screen.type)) {
      html.push(
        <span
          key={`dot-${index}`}
          className={`${dotStyle} ${
            currentScreenIndex === index ? activeDotStyle : inactiveDotStyle
          }`}
        />
      );
    }
  });
  return html;
};

const SessionContainer = () => {
  if (!featureService._hasGoalListFeature()) {
    history.push(helpers.homeUrl());
  }

  const { goalId, sessionId, screenId } = useParams();
  const [isError, setIsError] = useState(false);
  const [nextSession, setNextSession] = useState({
    id: '',
    screenId: ''
  });
  const [currentSessionNumber, setCurrentSessionNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [screenProgress, setScreenProgress] = useState({});

  // State to keep the current progress made on screen
  const [currentProgress, setCurrentProgress] = useState(null);

  // State to keep the previous progress made on screen
  const [previousProgress, setPreviousProgress] = useState(null);

  const [refetchQuery, setRefetchQuery] = useState();
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const [upsertSessionProgress] = useMutation(UPSERT_SESSION_PROGRESS);
  const currentScreenData = useRef({});
  const screensData = useRef([]);
  const totalScreens = useRef(null);
  const currentScreenIndex = useRef(null);
  const screenContent = useRef({});
  const currentScreenType = useRef(null);
  const currentDuration = useRef(null);
  const autoScrollRef = useRef(null);
  const [unsavedProgress, setUnsavedProgress] = useState({});
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const scrollToTopRef = useRef(null);

  const { isOpen: isKeyboardOpen } = useSelector((state) =>
    _.get(state, 'keyboard')
  );

  // Query to fetch the goal info (Basically version)
  const {
    loading,
    error: goalInfoError,
    data: currentGoalData
  } = useQuery(GET_SELECTED_GOAL_INFO, {
    fetchPolicy: FETCH_POLICIES.cacheFirst
  });

  const version = _.get(currentGoalData, 'getSelectedGoalInfo.version', '');

  // Query to fetch the session data
  const {
    error: sessionError,
    data: fetchedSession,
    loading: isFetchingSession,
    refetch: refetchSession,
    networkStatus: sessionNetworkStatus
  } = useQuery(GET_SELECTED_SESSION_INFO, {
    variables: { sessionId, goalId, version },
    skip: !sessionId || !screenId || !goalId || !version,
    notifyOnNetworkStatusChange: true
  });
  const totalScreensData = _.get(
    fetchedSession,
    'getSession.sessions[0].screens',
    []
  );

  screensData.current =
    totalScreensData.filter((screen) =>
      supportedScreenTypes.includes(screen.type)
    ) || [];

  currentScreenData.current = screensData.current.find(
    (screen) => screen.id === screenId
  );
  screenContent.current = _.get(currentScreenData.current, 'content[0]', {});

  currentScreenType.current = _.get(currentScreenData.current, 'type', null);

  totalScreens.current = _.size(screensData.current);

  currentScreenIndex.current = screensData.current.indexOf(
    currentScreenData.current
  );

  currentDuration.current = _.get(
    currentScreenData.current,
    'progress.currentDuration',
    0
  );
  const currentTrackerValue = _.get(
    currentScreenData.current,
    'progress.trackerValue',
    null
  );
  const currentChecklistOption = _.get(
    currentScreenData.current,
    'progress.options',
    null
  );
  const sessionLastScreenId = _.get(
    totalScreensData,
    `[${totalScreensData.length - 1}].id`,
    ''
  );
  const thoughtRecorderProgress = _.get(
    fetchedSession,
    'getSession.tools.thoughtRecorder',
    []
  );
  const trackerProgress = _.get(
    fetchedSession,
    'getSession.tools.trackerProgress',
    {}
  );
  const textInputProgress =
    _.get(currentScreenData.current, 'progress.textInputs', []) || [];
  const situationProgress = _.get(
    currentScreenData.current,
    'progress.situations',
    null
  );
  const contentType = _.get(currentScreenData.current, 'contentType', null);

  const isLastScreen = currentScreenIndex.current === totalScreens.current - 1;
  const {
    error: sessionListError,
    data: sessionListData,
    loading: isFetchingSessionList,
    refetch: refetchSessionList,
    networkStatus: sessionListNetworkStatus
  } = useQuery(GET_SESSION_LIST, {
    variables: { goalId, version },
    skip: !goalId || !version,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: FETCH_POLICIES.cacheFirst
  });

  // scroll to top on render
  useEffect(() => {
    if (scrollToTopRef && scrollToTopRef.current) {
      scrollToTopRef.current.scrollTo(0, 0);
    }
  }, [scrollToTopRef, screenId]);

  // Effect to restore next button state
  useEffect(() => {
    setIsNextDisabled(false);
    setPopupVisible(false);
  }, [goalId, sessionId, screenId]);

  // Effect to store the previous screen progress
  useEffect(() => {
    if (currentDuration.current !== null && !previousProgress) {
      setPreviousProgress(currentDuration.current);
    }
  }, [fetchedSession, previousProgress, screenId]);

  // Effect for error handling for queries
  useEffect(() => {
    const isFetching = isFetchingSession || loading || isFetchingSessionList;
    const networkStatus = sessionNetworkStatus || sessionListNetworkStatus;
    const graphQLError = sessionError || sessionListError || goalInfoError;
    if (isFetching || networkStatus === NetworkStatus.refetch) {
      setIsLoading(true);
    } else if (
      graphQLError &&
      !isFetching &&
      networkStatus !== NetworkStatus.refetch
    ) {
      logger.error('Failed to fetch sessions.', 'Session.useEffect', {
        error: graphQLError,
        goalId,
        sessionId,
        screenId
      });
      setIsLoading(false);
      setIsError(true);
      setRefetchQuery(() => {
        if (
          sessionError &&
          !isFetchingSession &&
          sessionNetworkStatus !== NetworkStatus.refetch
        ) {
          return refetchSession;
        }
        return refetchSessionList;
      });
    } else {
      setIsLoading(false);
      setIsError(false);
    }
  }, [
    refetchSessionList,
    sessionError,
    sessionListError,
    goalId,
    goalInfoError,
    isFetchingSession,
    isFetchingSessionList,
    loading,
    refetchSession,
    sessionListNetworkStatus,
    screenId,
    sessionId,
    sessionNetworkStatus
  ]);

  // Gets the next session Id and next session first allowed screen Id
  useEffect(() => {
    if (sessionListData) {
      const currentSessionIndex = _.get(
        sessionListData,
        'getSession.sessions',
        []
      ).findIndex((session) => session.id === sessionId);
      let nextSessionId = '';
      let nextAllowedScreenId = '';
      setCurrentSessionNumber(currentSessionIndex + 1);
      const nextSessionDetails = _.get(
        sessionListData,
        'getSession.sessions',
        []
      ).find(
        (session, index) =>
          index > currentSessionIndex &&
          session.screens.find(
            (screen) =>
              screen.type !== 'completion' &&
              supportedScreenTypes.includes(screen.type)
          )
      );
      if (!_.isEmpty(nextSessionDetails)) {
        nextSessionId = nextSessionDetails.id || '';

        const nextScreenDetails =
          nextSessionDetails.screens.find((screen) =>
            supportedScreenTypes.includes(screen.type)
          ) || {};
        nextAllowedScreenId = nextScreenDetails.id || '';
      }
      setNextSession({
        id: nextSessionId,
        screenId: nextAllowedScreenId
      });
    }
  }, [sessionListData, sessionId]);

  useEffect(() => {
    if (
      currentScreenData.current &&
      currentScreenType.current !== 'completion' &&
      !contentType
    ) {
      setIsError(true);
    }
  }, [setIsError, contentType]);

  // TODO: Move this to Session.utils.js file once session component refactor is done.
  const getScreenHeader = () => {
    const screenName = _.get(
      currentScreenData.current,
      'content[0].header',
      ''
    );
    return `${i18n.t('selfUse.session.cardHeader', {
      number: currentSessionNumber
    })} > ${screenName}`;
  };

  // Function to find out whether the current screen is last screen of the session
  const isLastScreenOfSession = useCallback(() => {
    const restScreenIdsOfCurrentSession = screensData.current
      .map((screen) => screen.id)
      .filter(
        (id) =>
          id !== currentScreenData.current.id && id !== sessionLastScreenId
      );
    const completedScreensIds = _.uniq(
      _.get(fetchedSession, 'getSession.completedScreens', [])
    );
    return _.isEmpty(
      _.difference(restScreenIdsOfCurrentSession, completedScreensIds)
    );
  }, [fetchedSession, sessionLastScreenId]);

  // Function using mutation to upsert the current progress
  const upsertSessionProgressHelper = useCallback(
    async (nextButtonClick = false, updatedScreenId = screenId, data = {}) => {
      try {
        const screenType = currentScreenType.current;
        if (
          screenType !== 'completion' &&
          supportedScreenTypes.includes(screenType)
        ) {
          let getSessionOptionsParameterObject = {
            selectedSession: GET_SELECTED_SESSION_INFO,
            sessionList: GET_SESSION_LIST,
            selectedGoalInfo: GET_SELECTED_GOAL_INFO,
            goalId,
            screenId: updatedScreenId,
            screenType,
            contentType,
            sessionId,
            source: 'session',
            version,
            isSessionCompleted: nextButtonClick && isLastScreenOfSession(),
            isScreenCompleted: nextButtonClick
          };
          if (screenType !== screenTypes.text) {
            getSessionOptionsParameterObject = {
              ...getSessionOptionsParameterObject,
              data: _.isEmpty(data) ? screenProgress : data
            };
          }
          await upsertSessionProgress(
            getSessionOptions(getSessionOptionsParameterObject)
          );
        }
      } catch (error) {
        logger.error(
          'An error occurred while updating user progress',
          'Session.updateSessionProgress',
          {
            error,
            screenId,
            goalId,
            sessionId
          }
        );
        toast.error(
          <Lang
            path="selfUse.session.upsertSessionProgressErrorText"
            values={{
              supportEmail
            }}
          />,
          null,
          {
            autoClose: 30000
          }
        );
      }
    },
    [
      goalId,
      screenProgress,
      isLastScreenOfSession,
      screenId,
      sessionId,
      upsertSessionProgress,
      version,
      contentType
    ]
  );

  // handle progress on next
  const handleProgressOnNext = useCallback(
    (data) => {
      setPopupVisible(false);
      if (isLastScreen) {
        analytics.track(eventCategories.SESSION, 'clicked take next session', {
          subcategory: 'completed',
          sessionId,
          goalId,
          screenId
        });
        history.push(
          `/self-use/goal/${goalId}/session/${nextSession.id}/screen/${nextSession.screenId}`
        );
        setIsLoading(true);
      } else if (currentScreenIndex.current < totalScreens.current - 1) {
        analytics.track(eventCategories.SESSION, 'clicked next button', {
          subcategory: 'screen',
          sessionId,
          goalId,
          screenId
        });
        upsertSessionProgressHelper(true, screenId, data);
        setPreviousProgress(null);
        setCurrentProgress(null);
        setScreenProgress({});
        const nextScreen = screensData.current[currentScreenIndex.current + 1];
        history.push(
          `/self-use/goal/${goalId}/session/${sessionId}/screen/${nextScreen.id}`
        );
      }
    },
    [
      screenId,
      isLastScreen,
      goalId,
      nextSession,
      sessionId,
      upsertSessionProgressHelper
    ]
  );

  // function for saving the progress through popup if user clicked on next w/o saving the progress
  const saveProgressOnNext = useCallback(() => {
    handleProgressOnNext(unsavedProgress);
    setUnsavedProgress({});
  }, [handleProgressOnNext, unsavedProgress]);

  // handle progress on back
  const handleProgressOnBack = useCallback(
    (data) => {
      setPopupVisible(false);
      if (
        currentScreenIndex.current > 0 &&
        currentScreenIndex.current < totalScreens.current &&
        currentScreenType.current !== 'completion'
      ) {
        analytics.track(eventCategories.SESSION, 'clicked back button', {
          subcategory: 'screen',
          sessionId,
          screenId,
          goalId
        });
        if (
          [
            screenTypes.audio,
            screenTypes.video,
            screenTypes.textInput
          ].includes(currentScreenType.current)
        ) {
          upsertSessionProgressHelper(false, screenId, data);
        }
        setPreviousProgress(null);
        setCurrentProgress(null);
        setScreenProgress({});
        const nextScreen = screensData.current[currentScreenIndex.current - 1];
        history.push(
          `/self-use/goal/${goalId}/session/${sessionId}/screen/${nextScreen.id}`
        );
      } else if (
        currentScreenIndex.current === 0 ||
        totalScreens.current === 1 ||
        currentScreenType.current === 'completion'
      ) {
        analytics.track(eventCategories.SESSION, 'clicked continue to home', {
          subcategory: 'completed',
          goalId,
          sessionId,
          screenId
        });
        history.push('/self-use');
      }
    },
    [goalId, sessionId, upsertSessionProgressHelper, screenId]
  );

  // function for saving the progress through popup if user clicked on back w/o saving the progress
  const saveProgressOnBack = useCallback(() => {
    handleProgressOnBack(unsavedProgress);
    setUnsavedProgress({});
  }, [handleProgressOnBack, unsavedProgress]);

  // config for popup. TODO: move this to session.utils file after refactor is done.
  const footerConfig = {
    onNext: {
      buttons: [
        {
          text: i18n.t('selfUse.session.popup.unsavedProgress.okText'),
          action: saveProgressOnNext,
          buttonColor: 'primary',
          style: popupButtonStyle
        },
        {
          text: i18n.t('selfUse.session.popup.unsavedProgress.continueText'),
          action: handleProgressOnNext,
          buttonColor: 'default',
          style: popupButtonStyle
        }
      ]
    },

    onBack: {
      buttons: [
        {
          text: i18n.t('selfUse.session.popup.unsavedProgress.okText'),
          action: saveProgressOnBack,
          buttonColor: 'primary',
          style: popupButtonStyle
        },
        {
          text: i18n.t('selfUse.session.popup.unsavedProgress.continueText'),
          action: handleProgressOnBack,
          buttonColor: 'default',
          style: popupButtonStyle
        }
      ]
    }
  };

  // handle spacebar and return key press
  const handleKeyUp = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault();
    }
  };

  // Next button click handler
  const handleNextButtonClick = useCallback(() => {
    logger.info(
      'Clicked on next button in session component',
      'Session.handleNextButtonClick'
    );

    if (
      currentScreenType.current === screenTypes.textInput &&
      !_.isEmpty(unsavedProgress)
    ) {
      setPopupVisible(true);
      setPopupData(footerConfig.onNext);
      return;
    }
    handleProgressOnNext(screenProgress);
  }, [
    unsavedProgress,
    handleProgressOnNext,
    screenProgress,
    footerConfig.onNext
  ]);

  const renderNextButton = useCallback(() => {
    const buttonText = isLastScreen
      ? i18n.t('selfUse.session.buttonText.nextSessionButtonText')
      : i18n.t('selfUse.session.buttonText.nextButtonText');
    return (
      (nextSession.id || (!nextSession.id && !isLastScreen)) && (
        <ActionButton
          disabled={isNextDisabled}
          className={buttonStyle}
          onMouseDown={() => handleNextButtonClick()}
          onKeyUp={(e) => handleKeyUp(e)}
          testId={`${buttonText}-button`.replace(/\s+/g, '-').toLowerCase()}
        >
          {buttonText}
        </ActionButton>
      )
    );
  }, [handleNextButtonClick, isNextDisabled, isLastScreen, nextSession.id]);

  // Back button click handler
  const handleBackButtonClick = useCallback(() => {
    logger.info(
      'Clicked on back button in session component',
      'Session.handleBackButtonClick'
    );

    if (
      currentScreenType.current === screenTypes.textInput &&
      !_.isEmpty(unsavedProgress)
    ) {
      setPopupVisible(true);
      setPopupData(footerConfig.onBack);
      return;
    }
    handleProgressOnBack(screenProgress);
  }, [
    unsavedProgress,
    handleProgressOnBack,
    screenProgress,
    footerConfig.onBack
  ]);

  const renderBackButton = useCallback(() => {
    const buttonText = isLastScreen
      ? i18n.t('selfUse.session.buttonText.homeButtonText')
      : i18n.t('selfUse.session.buttonText.backButtonText');
    return (
      <OutlineButton
        className={buttonStyle}
        onMouseDown={() => handleBackButtonClick()}
        onKeyUp={(e) => handleKeyUp(e)}
        testId={`${buttonText}-button`.replace(/\s+/g, '-').toLowerCase()}
      >
        {buttonText}
      </OutlineButton>
    );
  }, [handleBackButtonClick, isLastScreen]);

  const scrollToBottom = useCallback(() => {
    if (autoScrollRef && autoScrollRef.current) {
      autoScrollRef.current.scrollIntoView({
        block: 'end',
        behaviour: 'smooth'
      });
    }
  }, []);

  useEffect(() => {
    if (isKeyboardOpen) {
      scrollToBottom();
    }
  }, [isKeyboardOpen, scrollToBottom]);

  if (isLoading) {
    return (
      <div className={spinnerContainerStyle}>
        <Spinner />
        <p className={spinnerTextStyle}>
          <Lang path="selfUse.session.loadingText" />
        </p>
      </div>
    );
  }
  if (isError) {
    return (
      <ErrorRetry
        isFetching={false}
        onClick={() => refetchQuery()}
        title={<Lang path="selfUse.session.error.errorText" />}
      />
    );
  }

  if (
    _.isEmpty(goalId) ||
    _.isEmpty(sessionId) ||
    _.isEmpty(screenId) ||
    _.isEmpty(fetchedSession) ||
    _.isEmpty(screensData.current) ||
    _.isEmpty(currentScreenData.current) ||
    _.isEmpty(screenContent.current) ||
    !currentScreenType.current
  ) {
    logger.error('Found empty session or screen data', 'Session', {
      goalId,
      sessionId,
      screenId
    });
    return renderError('selfUse.session.error.sessionErrorMessage');
  }
  // Function to conditionally render the requested screen in the middle panel
  const renderScreenComponent = () => {
    let screenComponent = null;
    switch (currentScreenType.current) {
      case screenTypes.video:
        screenComponent = (
          <VideoScreen
            screenData={screenContent.current}
            previousProgress={previousProgress}
            currentProgress={currentProgress}
            setCurrentProgress={setCurrentProgress}
            upsertSessionProgressHelper={upsertSessionProgressHelper}
            renderError={renderError}
            setScreenProgress={setScreenProgress}
          />
        );
        break;
      case screenTypes.audio:
        screenComponent = (
          <VideoScreen
            isBreathingExercise
            screenData={screenContent.current}
            currentProgress={currentProgress}
            setCurrentProgress={setCurrentProgress}
            renderError={renderError}
            setScreenProgress={setScreenProgress}
          />
        );
        break;
      case screenTypes.tracker:
        screenComponent = (
          <Tracker
            trackerValue={
              _.isInteger(currentTrackerValue) ? currentTrackerValue : 0
            }
            screenData={screenContent.current}
            setScreenProgress={setScreenProgress}
            trackerProgress={trackerProgress}
            renderError={renderError}
            scrollToTopRef={scrollToTopRef}
          />
        );
        break;
      case screenTypes.checklist:
        screenComponent = (
          <ChecklistScreen
            screenContent={screenContent.current}
            currentSelectedOptions={currentChecklistOption}
            renderError={renderError}
            setScreenProgress={setScreenProgress}
            contentType={contentType}
            autoScrollRef={autoScrollRef}
            scrollToTopRef={scrollToTopRef}
          />
        );
        break;
      case screenTypes.multiChecklist:
        screenComponent = (
          <BehaviorScreen
            screenContent={screenContent.current}
            currentSelectedOptions={currentChecklistOption}
            renderError={renderError}
            setScreenProgress={setScreenProgress}
            autoScrollRef={autoScrollRef}
            scrollToTopRef={scrollToTopRef}
          />
        );
        break;
      case screenTypes.textInput:
        screenComponent = (
          <TextInputScreen
            screenData={screenContent.current}
            textInputProgress={textInputProgress}
            setScreenProgress={setScreenProgress}
            setUnsavedProgress={setUnsavedProgress}
            autoScrollRef={autoScrollRef}
            scrollToTopRef={scrollToTopRef}
          />
        );
        break;

      case screenTypes.text:
        screenComponent = (
          <TextScreen
            screenData={screenContent.current}
            renderError={renderError}
            scrollToTopRef={scrollToTopRef}
          />
        );
        break;
      case screenTypes.completion:
        screenComponent = (
          <Completion
            completeScreenData={screenContent.current}
            sessionNumber={currentSessionNumber}
          />
        );
        break;
      case screenTypes.thoughtRecorder:
        screenComponent = (
          <ThoughtRecorder
            screenData={screenContent.current}
            setScreenProgress={setScreenProgress}
            setIsNextDisabled={setIsNextDisabled}
            thoughtRecorderProgress={thoughtRecorderProgress}
            renderError={renderError}
            autoScrollRef={autoScrollRef}
            scrollToTopRef={scrollToTopRef}
          />
        );
        break;
      case screenTypes.situation:
        screenComponent = (
          <SituationScreen
            screenData={screenContent.current}
            currentProgress={situationProgress}
            setScreenProgress={setScreenProgress}
            renderError={renderError}
            scrollToTopRef={scrollToTopRef}
          />
        );
        break;
      default:
        logger.error(
          'Encountered unsupported screen type',
          'Session.renderScreenComponent',
          { goalId, sessionId, screenId }
        );
        break;
    }
    return screenComponent;
  };

  return (
    <IonContent scrollY={false}>
      <div className={container}>
        {currentScreenType.current !== 'completion' && (
          <div className={topPanelStyle}>
            <div data-testid="screen-header" className={sessionNameStyle}>
              {getScreenHeader()}
            </div>
            <div data-testid="screen-dots" className={screenDotsStyle}>
              {renderScreenDots(
                screensData.current,
                currentScreenIndex.current
              )}
            </div>
          </div>
        )}
        <div
          className={classNames({
            midPanelStyle: true,
            midPanelMediaScreenStyle: [
              screenTypes.video,
              screenTypes.audio
            ].includes(currentScreenType.current)
          })}
        >
          {renderScreenComponent()}
        </div>
        <InProgressPopup
          setPopupVisible={setPopupVisible}
          buttonConfig={popupData}
          popupVisible={popupVisible}
        />
        <div
          className={classNames({
            bottomPanelStyle: true,
            bottomPanelKeyboardOpenStyle: isKeyboardOpen
          })}
        >
          <div className={navContainer}>
            {renderBackButton()}
            {renderNextButton()}
          </div>
        </div>
      </div>
    </IonContent>
  );
};

export default withAuth(wrapWithApolloClient(SessionContainer));
