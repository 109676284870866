/**
 * hoc that shows the onboarding in case of assessment
 */

// core
import React, { Component } from 'react';
import { connect } from 'react-redux';
import featureService from 'utils/feature-service';
import { isLoggedIn } from '../../utils/auth';

const assessmentModule = (WrappedComponent) => {
  class decWithAuth extends Component {
    componentWillMount() {
      if (
        isLoggedIn() &&
        !this.props.profile.onboard_complete &&
        featureService._hasOnBoardingFlowFeature()
      ) {
        this.props.history.push(
          `/onboard?redirectTo=${window.location.pathname}`
        );
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state) => {
    const { profile } = state.profile;
    return { profile };
  };

  return connect(mapStateToProps)(decWithAuth);
};

export default assessmentModule;
