/**
 * Config options for our app.
 * Defined in .env file
 * use .env.development.local to override these
 */
// TODO fix this dependency cycle issue
// eslint-disable-next-line import/no-cycle
import { isNativePlatform } from '../utils/capacitor';

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID_WEB,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_RESPONSE_TYPE,
  REACT_APP_AUTH0_CLIENT_ID_MOBILE,
  REACT_APP_AUTH0_SCOPE,
  REACT_APP_MOBILE_APP_URL,
  REACT_APP_BRAZE_ENABLED,
  REACT_APP_BRAZE_API_KEY,
  REACT_APP_BRAZE_SDK_ENDPOINT,
  REACT_APP_IDLE_SESSION_TIMEOUT,
  REACT_APP_API_URL,
  REACT_APP_API_TIMEOUT,
  REACT_APP_LOGDNA_KEY,
  REACT_APP_ENVIRONMENT,
  REACT_APP_ANALYTICS_KEY,
  REACT_APP_ANALYTICS_URL,
  REACT_APP_ANALYTICS_ENABLED,
  REACT_APP_ANALYTICS_TAGS,
  REACT_APP_HOME_PAGE_VIDEO_URL,
  REACT_APP_BRAND,
  REACT_APP_BRANCH_IO_KEY,
  REACT_APP_APP_STORE_LINK,
  REACT_APP_PLAY_STORE_LINK,
  REACT_APP_LIVE_CHAT_LICENSE_ID,
  REACT_APP_REQUEST_API_URL,
  REACT_APP_PARTNER_API_URL,
  REACT_APP_NOTIFICATION_API_URL,
  REACT_APP_NOTIFICATION_API_KEY,
  REACT_APP_USER_API_URL,
  REACT_APP_CONTENT_API_URL,
  REACT_APP_CDN_BASE_URL,
  REACT_APP_CONTENT_VERSION_MAP,
  REACT_APP_LOGDNA_APP_NAME,
  REACT_APP_ANALYTICS_SESSION_TIMEOUT,
  REACT_APP_REQUEST_MANAGEMENT_SOURCE,
  REACT_APP_SHOW_PRIVACY_INFO,
  REACT_APP_SHOW_POWERED_BY_LOGO,
  REACT_APP_ASSESSMENT_VERSION_MAP,
  REACT_APP_PARTNER_KEY,
  REACT_APP_CHAT_SERVICE_ENDPOINT,
  REACT_APP_CHAT_SERVICE_REGION,
  REACT_APP_DEBUG_LOGS,
  REACT_APP_RETAKE_SURVEY_DURATION,
  REACT_APP_RETAKE_SURVEY_DURATION_TYPE,
  REACT_APP_LOGO_PATH,
  REACT_APP_SUPPORT_EMAIL,
  REACT_APP_APP_NAME,
  REACT_APP_FALLBACK_LANG,
  REACT_APP_AUTH_TOKEN_INSPECTION_INTERVAL,
  REACT_APP_AUTH_TOKEN_TIMEOUT_DURATION,
  REACT_APP_REFRESH_TOKEN_REUSE_INTERVAL,
  REACT_APP_REFRESH_TOKEN_LIFETIME_WEB,
  REACT_APP_REFRESH_TOKEN_LIFETIME_MOBILE,
  NODE_ENV,
  REACT_APP_APP_ID,
  REACT_APP_RETAKE_ASSESSMENT_FOR_ELIGIBILITY_DURATION,
  REACT_APP_BUILD_VER
} = process.env;

export default {
  appId: REACT_APP_APP_ID,
  auth0: {
    domain: REACT_APP_AUTH0_DOMAIN,
    clientID: isNativePlatform
      ? REACT_APP_AUTH0_CLIENT_ID_MOBILE
      : REACT_APP_AUTH0_CLIENT_ID_WEB,
    // Callback url is based on the current url so we calculate it dynamically
    redirectUri: isNativePlatform
      ? `${REACT_APP_MOBILE_APP_URL}/callback`
      : `${window.location.origin}/callback`,
    audience: REACT_APP_AUTH0_AUDIENCE,
    responseType: REACT_APP_AUTH0_RESPONSE_TYPE,
    scope: JSON.parse(REACT_APP_AUTH0_SCOPE).join(' ')
  },
  mobileAppUrl: REACT_APP_MOBILE_APP_URL,
  idleSessionTimeout: REACT_APP_IDLE_SESSION_TIMEOUT,
  tokenInspectionInterval: REACT_APP_AUTH_TOKEN_INSPECTION_INTERVAL,
  tokenTimeoutDuration: REACT_APP_AUTH_TOKEN_TIMEOUT_DURATION,
  refreshTokenReuseInterval: REACT_APP_REFRESH_TOKEN_REUSE_INTERVAL,
  refreshTokenLifeTime: isNativePlatform
    ? REACT_APP_REFRESH_TOKEN_LIFETIME_MOBILE
    : REACT_APP_REFRESH_TOKEN_LIFETIME_WEB,
  api: {
    url: REACT_APP_API_URL,
    timeout: REACT_APP_API_TIMEOUT,
    requestUrl: REACT_APP_REQUEST_API_URL
  },
  partner: {
    url: REACT_APP_PARTNER_API_URL,
    key: REACT_APP_PARTNER_KEY
  },
  logDna: {
    key: REACT_APP_LOGDNA_KEY,
    appName: REACT_APP_LOGDNA_APP_NAME
  },
  environment: REACT_APP_ENVIRONMENT,
  analytics: {
    key: REACT_APP_ANALYTICS_KEY,
    url: REACT_APP_ANALYTICS_URL,
    enabled: REACT_APP_ANALYTICS_ENABLED,
    tags: REACT_APP_ANALYTICS_TAGS.split(',')
  },
  videoUrl: REACT_APP_HOME_PAGE_VIDEO_URL,
  brand: REACT_APP_BRAND,
  branchIo: {
    key: REACT_APP_BRANCH_IO_KEY,
    link: {
      playStore: REACT_APP_PLAY_STORE_LINK,
      appStore: REACT_APP_APP_STORE_LINK
    }
  },
  liveChatLicenseId: parseInt(REACT_APP_LIVE_CHAT_LICENSE_ID, 10),
  sessionTimeout: REACT_APP_ANALYTICS_SESSION_TIMEOUT,
  requestManagementSource: REACT_APP_REQUEST_MANAGEMENT_SOURCE,
  showPrivacyInfo: REACT_APP_SHOW_PRIVACY_INFO,
  showPoweredByLogo: REACT_APP_SHOW_POWERED_BY_LOGO,
  assessmentVersionMap: REACT_APP_ASSESSMENT_VERSION_MAP,
  chat: {
    url: REACT_APP_CHAT_SERVICE_ENDPOINT,
    region: REACT_APP_CHAT_SERVICE_REGION
  },
  user: {
    url: REACT_APP_USER_API_URL
  },
  notification: {
    url: REACT_APP_NOTIFICATION_API_URL,
    apiKey: REACT_APP_NOTIFICATION_API_KEY
  },
  retakeAssessmentReminder: {
    duration: REACT_APP_RETAKE_SURVEY_DURATION,
    type: REACT_APP_RETAKE_SURVEY_DURATION_TYPE
  },
  content: {
    url: REACT_APP_CONTENT_API_URL
  },
  cdnBaseUrl: REACT_APP_CDN_BASE_URL,
  contentVersionMap: REACT_APP_CONTENT_VERSION_MAP,
  logoPath: REACT_APP_LOGO_PATH,
  supportEmail: REACT_APP_SUPPORT_EMAIL,
  appName: REACT_APP_APP_NAME,
  fallbackLang: REACT_APP_FALLBACK_LANG,
  debugLogs: REACT_APP_DEBUG_LOGS,
  nodeEnvironment: NODE_ENV,
  retakeAssessmentForEligibilityDuration:
    REACT_APP_RETAKE_ASSESSMENT_FOR_ELIGIBILITY_DURATION,
  braze: {
    enabled: REACT_APP_BRAZE_ENABLED,
    apiKey: REACT_APP_BRAZE_API_KEY,
    endpoint: REACT_APP_BRAZE_SDK_ENDPOINT
  },
  appVersion: REACT_APP_BUILD_VER
};
