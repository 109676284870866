import React, { useMemo } from 'react';
import uuid from 'uuid/v4';
import propTypes from 'prop-types';
// components
import Input from '../../StepsType/Input';
// utils
import {
  getLastResponse,
  rationalResponseHandler
} from './RationalResponse.utils';

const RationalResponse = ({
  urlParams,
  stepId,
  type,
  currentProgress,
  setCurrentProgress,
  stepsData
}) => {
  // Reference id required for rational response
  const referenceId = useMemo(() => uuid(), []);

  const {
    isActive,
    data: { placeholderText }
  } = stepsData[type];

  return (
    <Input
      onKeyUpHandler={(text) =>
        rationalResponseHandler({
          ...urlParams,
          stepId,
          referenceId,
          text,
          type,
          currentProgress,
          setCurrentProgress
        })
      }
      placeholder={placeholderText}
      type={type}
      defaultValue={getLastResponse({ currentProgress })}
      isEditing={isActive}
    />
  );
};

RationalResponse.propTypes = {
  urlParams: propTypes.shape({
    goalId: propTypes.string,
    sessionId: propTypes.string,
    screenId: propTypes.string
  }),
  type: propTypes.string,
  stepId: propTypes.string,
  currentProgress: propTypes.objectOf(
    propTypes.oneOfType([
      propTypes.arrayOf(propTypes.object),
      propTypes.string // __typename
    ])
  ),
  setCurrentProgress: propTypes.func,
  stepsData: propTypes.objectOf(
    propTypes.shape({
      data: propTypes.shape({
        allowCustom: propTypes.bool,
        customOptionPlaceholder: propTypes.string,
        options: propTypes.arrayOf(propTypes.shape({})),
        maxOptions: propTypes.number
      })
    })
  )
};

RationalResponse.defaultProps = {
  urlParams: {},
  stepId: '',
  currentProgress: {},
  stepsData: {},
  type: '',
  setCurrentProgress: () => null
};

export default RationalResponse;
