import React from 'react';

const RenderLink = ({ className, link, onClick, linkText }) => (
  <a
    className={className}
    href={link}
    target="_blank"
    onClick={onClick}
    rel="noreferrer"
  >
    {linkText}
  </a>
);

export default RenderLink;
