/**
 *
 * Request Coach
 *
 */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import requestCoach, {
  clearCoachRequestStatusError,
  startAutoCoachAssign
} from 'store/actions/dashboard/CoachRequestAction';
import analytics from 'utils/analytics/analytics';
import coachStatus from 'assets/data/dashboard/coachRequestStatusTypes';
import helpers from 'utils/helpers';
import featureService from 'utils/feature-service';
import { closeModal } from 'store/actions/ModalAction';
import logger from 'utils/logger';
import {
  AutoAssignmentLoader,
  AutoAssignmentError
} from '../../../../components/Coaching';
import SmartAppLink from '../../../../components/Popups/SmartAppLink';
import RequestReceived from './components/RequestReceived';
import RequestCoach from './components/RequestCoach';
import Lang from '../../../../components/Lang';

const { transformBySeverity } = helpers;

class Coaching extends Component {
  componentDidMount() {
    this.props.actions.clearCoachRequestStatusError();
  }

  componentWillReceiveProps(nextProps) {
    if (
      featureService._hasCoachAccessOnWebFeature() &&
      featureService._hasAutoCoachAssignmentOnRequestClickFeature() &&
      nextProps.autoCoach.isFetching === false &&
      nextProps.autoCoach.hasError === false &&
      nextProps.coachRequestStatus === coachStatus.none &&
      nextProps.showRequestConfirmation === false
    ) {
      this._initiateAutoCoach();
    }
  }

  _retryHandler = () => {
    analytics.track(
      'modal auto coach assignment failure',
      'clicked retry again for auto coach assignment'
    );
    this._initiateAutoCoach();
  };

  _renderCoachStatus = (status) => {
    if (featureService._hasAutoCoachAssignmentOnRequestClickFeature()) {
      if (this.props.autoCoach.isFetching) {
        analytics.info(
          'modal coach request recieved',
          'auto coach assignment starts',
          { goalId: this.props.goalId, severity: this.props.severity }
        );
        return <AutoAssignmentLoader isModal />;
      }
      if (this.props.autoCoach.hasError) {
        return (
          <AutoAssignmentError
            isModal
            isFetching={this.props.autoCoach.isFetching}
            retryHandler={this._retryHandler}
          />
        );
      }
    }

    switch (status) {
      case coachStatus.none: {
        const { hasError, isFetching } = this.props;
        return (
          <RequestCoach
            isFetching={isFetching}
            onClick={this._requestCoach}
            hasError={hasError}
          />
        );
      }
      case coachStatus.requested:
        return <RequestReceived />;
      case coachStatus.assigned:
        return !featureService._hasCoachAccessOnWebFeature() ? (
          <SmartAppLink headerText={<Lang path="coachAssignedHeaderText" />} />
        ) : null;
      default:
        return null;
    }
  };

  _requestCoach = () => {
    analytics.track('dashboard', 'clicked request coach', {
      goalId: this.props.goalId,
      severity: this.props.severity
    });
    this.props.actions.clearCoachRequestStatusError();
    if (featureService._hasAutoCoachAssignmentOnRequestClickFeature()) {
      this._initiateAutoCoach();
    } else {
      this.props.actions.requestCoach();
    }
  };

  _initiateAutoCoach = () => {
    const { props } = this;
    const categories = _.get(props, 'reports[0].categories', null);
    if (categories) {
      this.props.actions.startAutoCoachAssign({
        mode: 'auto',
        eapName: this.props.eapName,
        goals: transformBySeverity(categories)
      });
    } else {
      // fallback to manual coach assignment
      logger.info(
        'Goal categories not found, could not trigger auto coach assignment',
        '_initiateAutoCoach'
      );
      this.props.actions.requestCoach();
    }
  };

  render() {
    const { coachRequestStatus } = this.props;
    return this._renderCoachStatus(coachRequestStatus);
  }
}

const mapStateToProps = (state) => {
  const {
    requestCoach: {
      request: { isFetching, hasError },
      coachRequestStatus,
      autoCoach
    },
    reportList: { reports },
    companyDetails: {
      companyDetails: {
        partner: { name: eapName }
      }
    },
    dashBoard: { goalId, severity }
  } = state;
  return {
    coachRequestStatus,
    isFetching,
    hasError,
    goalId,
    severity,
    reports,
    eapName,
    autoCoach
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    requestCoach: bindActionCreators(requestCoach, dispatch),
    clearCoachRequestStatusError: bindActionCreators(
      clearCoachRequestStatusError,
      dispatch
    ),
    startAutoCoachAssign: bindActionCreators(startAutoCoachAssign, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch)
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Coaching);
