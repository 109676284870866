import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { Paper } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'utils/moment';
import Slider from 'components/RatingSlider';
import config from 'config';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import styles from './TrackerSlider.module.scss';

const { cdnBaseUrl } = config;
const { container, iconStyle, labelStyle, sliderContainer, emoticonStyle } =
  styles;

const TrackerSlider = ({
  emoticon,
  rating,
  trackerValue,
  onSliderChange,
  trackerType,
  id,
  setScreenProgress
}) => {
  const isSmallScreen = useMediaQuery('(max-width: 767px)');

  const sliderMarks = useMemo(() => {
    const marks = [];
    const increment = isSmallScreen ? 2 : 1;
    for (let i = 0; i <= 10; i += increment) {
      const value = i;
      const label = <span data-testid={`tracker-mark-${i}`}>{i}</span>;
      marks.push({ value, label });
    }
    return marks;
  }, [isSmallScreen]);

  const onChangeHandler = useCallback(
    (props, number) => {
      if (_.isInteger(number)) {
        analytics.track(
          eventCategories.SESSION,
          `Updated tracker value ${number}`,
          {
            subcategory: 'session'
          }
        );
        setScreenProgress({
          trackerValue: number,
          trackerType,
          id
        });
        onSliderChange(number, moment(new Date()).format('MMM DD'));
      }
    },
    [onSliderChange, setScreenProgress, trackerType, id]
  );
  return (
    <Paper data-testid="tracker-slider-container" className={container}>
      {emoticon && (
        <div className={iconStyle}>
          {emoticon.map((image, index) => (
            <img
              key={index}
              data-testid={`emoticon-${index}`}
              className={emoticonStyle}
              src={`${cdnBaseUrl}${image.moodEmoticonUrl}`}
              alt=""
            />
          ))}
        </div>
      )}
      <div className={labelStyle}>
        {rating.map((label, index) => (
          <div data-testid={`tracker-slider-label-${index}`} key={index}>
            {label}
          </div>
        ))}
      </div>
      <div className={sliderContainer}>
        <Slider
          value={trackerValue}
          marks={sliderMarks}
          min={0}
          max={10}
          onChange={onChangeHandler}
        />
      </div>
    </Paper>
  );
};

export default TrackerSlider;
