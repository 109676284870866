// core
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import uuid from 'uuid/v4';
import { isNull, kebabCase } from 'lodash';
import propTypes from 'prop-types';
import { Col, Row } from 'antd';
// components
import Checklist from 'components/Checklist';
import CustomInput from 'components/CustomInput';
import RenderHtml from 'components/RenderHtml';
// utils
import {
  getScreenData,
  onCancelHandler,
  onAddHandler,
  onSelectHandler,
  onSaveCustomOptionHandler,
  optionsListHandler,
  validateScreenData,
  validateCustomInput
} from './ChecklistScreen.utils';
// styles
import styles from './ChecklistScreen.module.scss';

const {
  container,
  subheaderStyle,
  descriptionStyle,
  checklistContainer,
  wrapper
} = styles;

const ChecklistScreen = ({
  screenContent,
  renderError,
  setScreenProgress,
  currentSelectedOptions,
  contentType,
  autoScrollRef,
  scrollToTopRef
}) => {
  const {
    id,
    subheader,
    descriptionV2,
    optionsData,
    customOptionPlaceholder,
    checklistScreenId,
    allowCustom,
    maxOptions: maxSelectableOptions
  } = getScreenData(screenContent);
  const { goalId, screenId, sessionId } = useParams();
  const [currentProgress, setCurrentProgress] = useState([]);
  const [currentOptions, setCurrentOptions] = useState([]);

  useEffect(() => {
    setCurrentProgress(currentSelectedOptions);
  }, [id, currentSelectedOptions]);

  useEffect(() => {
    optionsListHandler({
      optionsData,
      currentSelectedOptions,
      setCurrentOptions
    });
  }, [id, currentSelectedOptions, optionsData, setCurrentOptions]);

  useEffect(() => {
    if (checklistScreenId) {
      let options = [];
      if (!isNull(currentProgress)) {
        options = currentProgress;
      }
      setScreenProgress({
        id: checklistScreenId,
        options
      });
    }
  }, [checklistScreenId, setScreenProgress, currentProgress]);

  const isValid = validateScreenData({
    screenContent,
    id,
    subheader
  });

  if (!isValid) return renderError('selfUse.session.error.screenErrorMessage');

  return (
    <div
      data-testid={`${kebabCase(contentType)}-screen`}
      className={wrapper}
      ref={scrollToTopRef}
    >
      <div className={container}>
        <Row>
          <Col md={12} lg={12} xs={24}>
            <h2
              data-testid={`${kebabCase(contentType)}-screen-subheader`}
              className={subheaderStyle}
            >
              {subheader}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={24} xs={24}>
            <p
              data-testid={`${kebabCase(contentType)}-screen-description`}
              className={descriptionStyle}
            >
              <RenderHtml>{descriptionV2.html}</RenderHtml>
            </p>
          </Col>
        </Row>
      </div>
      <div
        data-testid={`${kebabCase(contentType)}-screen-container`}
        className={checklistContainer}
      >
        <Row>
          {currentOptions.map((option, index) => (
            <Col key={option.optionId} md={12} lg={12} xs={24}>
              <Checklist
                index={index}
                id={option.optionId}
                text={option.text}
                isSelected={
                  !!(
                    currentProgress &&
                    currentProgress.find(
                      (selectedOptions) =>
                        selectedOptions.optionId === option.optionId &&
                        selectedOptions.isSelected === true
                    )
                  )
                }
                onSelect={({ isSelected, optionId, text }) =>
                  onSelectHandler({
                    goalId,
                    sessionId,
                    screenId,
                    isSelected,
                    option: { optionId, text },
                    currentProgress,
                    setCurrentProgress,
                    contentType,
                    maxSelectableOptions
                  })
                }
              />
            </Col>
          ))}
          {allowCustom && (
            <Col md={12} lg={12} xs={24}>
              <CustomInput
                id={id}
                testId={`${kebabCase(contentType)}`}
                customValidator={(text) =>
                  validateCustomInput({
                    text,
                    currentOptions,
                    maxSelectableOptions,
                    currentProgress,
                    contentType
                  })
                }
                customOptionPlaceholder={customOptionPlaceholder}
                onClickAdd={() =>
                  onAddHandler({
                    goalId,
                    sessionId,
                    screenId,
                    contentType
                  })
                }
                onClickSave={(text) =>
                  onSaveCustomOptionHandler({
                    goalId,
                    sessionId,
                    screenId,
                    option: { optionId: uuid(), text },
                    currentProgress,
                    currentOptions,
                    setCurrentProgress,
                    setCurrentOptions,
                    contentType,
                    maxSelectableOptions
                  })
                }
                onClickCancel={() =>
                  onCancelHandler({
                    goalId,
                    sessionId,
                    screenId,
                    contentType
                  })
                }
              />
            </Col>
          )}
        </Row>
        <div ref={autoScrollRef} />
      </div>
    </div>
  );
};

ChecklistScreen.propTypes = {
  screenContent: propTypes.shape({
    checklist: propTypes.arrayOf(propTypes.shape({})),
    subheader: propTypes.string,
    descriptionV2: propTypes.shape({
      html: propTypes.string
    }),
    customOptionPlaceholder: propTypes.string
  }),
  renderError: propTypes.func,
  setScreenProgress: propTypes.func,
  currentSelectedOptions: propTypes.arrayOf(propTypes.shape({})),
  contentType: propTypes.string
};

ChecklistScreen.defaultProps = {
  screenContent: {},
  renderError: () => null,
  setScreenProgress: () => null,
  currentSelectedOptions: null,
  contentType: null
};

export default ChecklistScreen;
