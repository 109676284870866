import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
// components
// styles
// actions
import {
  clearConversationalAssessmentData,
  fetchConversationalAssessment,
  setConversationalAssessmentData
} from 'store/actions/ConversationalAssessmentAction';
import { saveAssessmentsProgress } from 'store/actions/AssessmentAction';
import { closeModal, showModal } from 'store/actions/ModalAction';
import { processReportToList } from 'store/actions/ReportProcessAction';
import { setDashBoardData } from 'store/actions/dashboard/DashBoardAction';
import { startAutoCoachAssign } from 'store/actions/dashboard/CoachRequestAction';
import selectGoal from 'store/actions/GoalAction';
// utils
import api from 'api/assessment';
import logger from 'utils/logger';
import analytics from 'utils/analytics';
import featureService from 'utils/feature-service';
import helpers from 'utils/helpers';
import categories from 'utils/analytics/categories';
// assets
import i18n from 'assets/lang';
import assessmentMessageTypes from 'assets/data/dashboard/assessmentMessageTypes';
import coachRequestStatusTypes from 'assets/data/dashboard/coachRequestStatusTypes';
import GetHelpNow from '../../../../DashBoard/popups/GetHelpNow';
import Alert from '../../../../../components/Popups/Alert';
import styles from './QuestionList.module.scss';
import Message from '../Message/Message';
import { ChatTyping } from '../index';
// context
import { ConversationalAssessmentProvider } from '../../ConversationalAssessmentContext';

import { UPSERT_USER_GOAL } from '../../../../SelfUse/mutations';
import { getSelectedGoalInfoOptions } from '../../../../SelfUse/mutationOptions';
import {
  GET_GOAL_LIST,
  GET_SELECTED_GOAL_INFO
} from '../../../../SelfUse/queries';

const { isCoach, transformBySeverity } = helpers;

const { container, messagesContainer, messageStyle } = styles;

const QuestionList = ({ assessmentId, greetingMessages, questionList }) => {
  const dispatch = useDispatch();
  const { data: goalListData } = useQuery(GET_GOAL_LIST);
  const { data: currentGoalData } = useQuery(GET_SELECTED_GOAL_INFO);
  const [upsertUserGoal] = useMutation(UPSERT_USER_GOAL);
  const {
    conversationalAssessment: { state, isCompleted, progressId, hasError },
    requestCoach: { coachRequestStatus },
    companyDetails: {
      companyDetails: { partner }
    }
  } = useSelector((reduxState) => reduxState);

  const [isTyping, setIsTyping] = useState(true);
  const [isAssessmentCompleted, setIsAssessmentCompleted] = useState(false);
  const [messages, setMessages] = useState([]);
  const messageQueue = useRef([]);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(1);
  const timeoutRef = useRef(0);
  const firstTime = useRef(true);
  const [responses, setResponses] = useState({});
  const messagesEndRef = useRef(null);

  const _handleModalClose = useCallback(() => {
    const source = 'question-list-alert';
    analytics.track(categories.MODAL, 'clicked close', {
      source,
      trigger: 'modal_action'
    });
    logger.info('Clicked close', 'QuestionList._handleModalClose', { source });
    dispatch(closeModal());
  }, [dispatch]);

  const _addSystemMessage = useCallback(
    (content, type = assessmentMessageTypes.text, hideTyping = false) =>
      new Promise((resolve) => {
        timeoutRef.current = setTimeout(
          () => {
            setMessages((prevMessages) =>
              prevMessages.concat({
                messageType: type,
                content,
                sender: 'system',
                showAvatar:
                  _.get(prevMessages[prevMessages.length - 1], 'sender') !==
                  'system'
              })
            );
            if (type !== assessmentMessageTypes.text) {
              setIsTyping(false);
            }
            resolve();
          },
          hideTyping ? 0 : 1000
        );
      }),
    []
  );

  const _addVisitorMessage = useCallback((content) => {
    setMessages((prevMessages) =>
      prevMessages.concat({
        messageType: 'text',
        content,
        sender: 'visitor',
        showAvatar:
          _.get(prevMessages[prevMessages.length - 1], 'sender') !== 'visitor'
      })
    );
  }, []);

  const _addToMessageQueue = useCallback((content, type, hideTyping) => {
    const questionContext = _.get(content, 'questionContext', '');
    if (!_.isEmpty(questionContext)) {
      if (_.isArray(questionContext)) {
        for (let i = 0; i < questionContext.length; i += 1) {
          messageQueue.current.push({
            content: questionContext[i]
          });
        }
      } else {
        messageQueue.current.push({
          content: questionContext
        });
      }
    }
    messageQueue.current.push({
      content,
      type,
      hideTyping
    });
  }, []);

  const _addGreetingMessages = useCallback(() => {
    if (greetingMessages) {
      greetingMessages.forEach((greetingMessage) =>
        _addToMessageQueue(greetingMessage)
      );
    }
  }, [_addToMessageQueue, greetingMessages]);

  const _handleEmergencyClick = useCallback(
    (category, score) => {
      analytics.track('conversational assessment', 'clicked emergency', {
        goalId: category,
        severity: score
      });
      setTimeout(() => {
        dispatch(
          showModal(GetHelpNow, {
            source: 'get-help-now',
            props: {
              eapPhone: partner.eap_phone
            }
          })
        );
      }, 250);
    },
    [dispatch, partner.eap_phone]
  );

  const _getNextQuestion = useCallback(
    (questionId, currentResponses) => {
      let nextQuestionId = questionId;
      let question = null;
      const questionsLength = Object.keys(questionList).length;
      if (nextQuestionId === questionsLength + 1) {
        return { hasMoreQuestions: false };
      }
      for (
        nextQuestionId;
        nextQuestionId <= questionsLength;
        nextQuestionId += 1
      ) {
        question = questionList ? questionList[nextQuestionId] : null;
        // Check if the question exists and it has the showIf property,
        // if it does then we take sum of all the questions mentioned for the condition.
        if (question && question.showIf) {
          let sum = 0;
          question.showIf.questionIds.forEach((i) => {
            const score = _.get(currentResponses, `[${i}].score`);
            sum += score || 0;
          });

          const operation = question.showIf.condition.total.op;
          // Check if the sum is greater or less than total value from condition
          if (operation === 'gt') {
            if (sum > question.showIf.condition.total.value) {
              // Subtract the current question id from next question to flow with the user choice
              return { hasMoreQuestions: true, id: nextQuestionId };
            }
          } else if (operation === 'lt') {
            if (sum < question.showIf.condition.total.value) {
              // Subtract the current question id from next question to flow with the user choice
              return { hasMoreQuestions: true, id: nextQuestionId };
            }
          } else if (operation === 'eq') {
            if (sum === question.showIf.condition.total.value) {
              // Subtract the current question id from next question to flow with the user choice
              return { hasMoreQuestions: true, id: nextQuestionId };
            }
          } else {
            logger.error(
              'Unknown operation received from the server',
              'QuestionList.componentWillReceiveProps',
              { operation }
            );
          }
        } else {
          return { hasMoreQuestions: true, id: nextQuestionId };
        }
      }
      return { hasMoreQuestions: false };
    },
    [questionList]
  );

  const _restartAssessment = useCallback(async () => {
    logger.info('Restart assessment', 'QuestionList._restartAssessment');
    analytics.info(categories.ASSESSMENT, 'assessment restart');
    setIsTyping(true);
    setResponses({});
    if (_.isEmpty(questionList)) {
      dispatch(fetchConversationalAssessment(assessmentId));
      return;
    }
    setActiveQuestionIndex(1);
    _addToMessageQueue(questionList[1], assessmentMessageTypes.question, true);
  }, [_addToMessageQueue, assessmentId, dispatch, questionList]);

  const _completeAssessment = useCallback(
    async (resultData, categoryId) => {
      const categoryData = resultData.categories[categoryId];
      const reportId = resultData._id;
      if (categoryId && categoryData) {
        const data = {
          categoryId,
          severity: categoryData.level,
          reportId,
          categoryName: categoryData.categoryName
        };
        _addToMessageQueue(data, assessmentMessageTypes.assessmentResult);
        if (featureService._hasAutoCoachAssignmentOnCompletingSurveyFeature()) {
          logger.info(
            'User has coach_auto_assignment_on_completing_survey feature',
            'QuestionList._completeAssessment',
            { isCoach }
          );
          analytics.info(
            categories.COACH_ASSIGNMENT,
            'User has autocoach assignment feature',
            { source: categories.ASSESSMENT }
          );
          if (
            !isCoach() &&
            coachRequestStatus === coachRequestStatusTypes.none
          ) {
            dispatch(
              startAutoCoachAssign({
                mode: 'auto',
                eapName: partner.name,
                goals: transformBySeverity(resultData.categories)
              })
            );
          }
        }
        dispatch(processReportToList(resultData));
        dispatch(
          setDashBoardData(data.categoryId, data.severity, data.reportId)
        );
        if (
          featureService._hasSelfUseFeature() &&
          goalListData &&
          data.categoryId &&
          currentGoalData
        ) {
          const goalData = _.get(goalListData, 'getGoalList.goals', []).find(
            (goal) => goal.goalId === data.categoryId
          );
          if (goalData) {
            upsertUserGoal(
              getSelectedGoalInfoOptions({
                query: GET_SELECTED_GOAL_INFO,
                id: data.categoryId,
                version: goalData.version
              })
            )
              .then((res) => {
                logger.info(
                  'Successfully upsert selectedGoal questionList',
                  'QuestionList._completeAssessment',
                  { res, goalId: data.categoryId }
                );
                analytics.info(
                  categories.SELF_USE,
                  'Successfully selected user goal',
                  {
                    trigger: 'select_goal',
                    source: categories.ASSESSMENT,
                    goalId: data.categoryId
                  }
                );
              })
              .catch((err) => {
                logger.error(
                  'Unable to upsert the selectedgoal.',
                  'QuestionList._completeAssessment',
                  { err, goalId: data.categoryId }
                );
              });
          } else {
            logger.error(
              'GoalId not found in goalList from graphCMS',
              'QuestionList._completeAssessment',
              { goalId: data.categoryId }
            );
          }
        }
        if (resultData.selectedGoalOption) {
          logger.info(
            'SelectedGoalOption is already set',
            'QuestionList._handleSelection'
          );
        } else {
          dispatch(
            selectGoal({
              selectedGoalOption: data.categoryId,
              selectedGoal: data.categoryName,
              reportId: data.reportId
            })
          );
        }
      } else {
        logger.info(
          'Empty category while fetching chat assessment result.',
          'QuestionList._handleSelection',
          { response: resultData }
        );
        _addToMessageQueue(
          i18n.t('chatWrongOptionMessage'),
          assessmentMessageTypes.text
        );
        _restartAssessment();
      }
    },
    [
      _addToMessageQueue,
      dispatch,
      coachRequestStatus,
      partner,
      _restartAssessment,
      goalListData,
      upsertUserGoal,
      currentGoalData
    ]
  );

  const _generateReport = useCallback(
    (reportData, categoryId) => {
      try {
        if (_.isEmpty(reportData)) {
          logger.error(
            'An error occurred while fetching Chat Report',
            'QuestionList._generateReport'
          );
          _addToMessageQueue(null, assessmentMessageTypes.processError);
        } else if (_.isEmpty(categoryId)) {
          logger.info(
            'Empty category while fetching chat assessment result.',
            'QuestionList._generateReport',
            { response: reportData }
          );
          _addToMessageQueue(
            i18n.t('chatWrongOptionMessage'),
            assessmentMessageTypes.text
          );
          _restartAssessment();
        } else {
          _completeAssessment(reportData, categoryId);
        }
      } catch (error) {
        logger.error(
          'An error occurred while processing Chat Report',
          'QuestionList._generateReport',
          { error }
        );
        _addToMessageQueue(null, assessmentMessageTypes.processError);
      }
    },
    [_addToMessageQueue, _completeAssessment, _restartAssessment]
  );

  const _generateConversationalReport = useCallback(
    async (currentResponses) => {
      try {
        const responseObj = _.mapValues(
          currentResponses,
          (value) => value.score
        );
        const result = await api.fetchResult(responseObj, assessmentId);
        if (result.status === 200) {
          _generateReport(result.data, Object.keys(result.data.categories)[0]);
        } else {
          logger.error(
            'An error occurred while fetching Chat Report',
            'QuestionList._generateConversationalReport'
          );
          _addToMessageQueue(null, assessmentMessageTypes.processError);
        }
      } catch (error) {
        logger.error(
          'Unexcepted error occurrred while fetching Chat Report',
          'QuestionList._generateConversationalReport'
        );
        _addToMessageQueue(null, assessmentMessageTypes.processError);
      }
    },
    [_generateReport, _addToMessageQueue, assessmentId]
  );

  const _generateCatReport = useCallback(async () => {
    try {
      const result = await api.fetchResult({ state }, assessmentId);
      if (result.status === 200) {
        if (result.data.selectedGoalOption) {
          setIsAssessmentCompleted(true);
          _generateReport(result.data, result.data.selectedGoalOption);
        } else {
          logger.error(
            'Missing required field selectedGoalOption for processing cat assessment',
            'QuestionList._generateCatReport'
          );
          _addToMessageQueue(null, assessmentMessageTypes.processError);
        }
      } else {
        logger.error(
          'Unexcepted error occurrred while fetching Chat Report',
          'QuestionList._generateCatReport'
        );
        _addToMessageQueue(null, assessmentMessageTypes.processError);
      }
    } catch (error) {
      logger.error(
        'An error occurred while fetching Chat Report',
        'QuestionList._generateCatReport'
      );
      _addToMessageQueue(null, assessmentMessageTypes.processError);
    }
  }, [_generateReport, _addToMessageQueue, assessmentId, state]);

  const _processQuestions = useCallback(
    async (responseObj) => {
      try {
        const result = await api.processChatQuestions(
          assessmentId,
          responseObj,
          state
        );
        if (result.status === 200) {
          dispatch(setConversationalAssessmentData(result.data));
          setResponses({});
        } else {
          logger.error(
            'An error occurred while fetching new set of questions',
            'QuestionList._processQuestions'
          );
          _addToMessageQueue(null, assessmentMessageTypes.fetchError);
        }
      } catch (error) {
        logger.error(
          'An error occurred while fetching new set of questions',
          'QuestionList._processQuestions',
          { error }
        );
        _addToMessageQueue(null, assessmentMessageTypes.fetchError);
      }
    },
    [_addToMessageQueue, assessmentId, dispatch, state]
  );

  const _handleSelection = useCallback(
    async (questionId, choice, category) => {
      const currentResponses = {
        ...responses,
        [questionId]: {
          text: choice.text,
          score: choice.score,
          category
        }
      };

      _addVisitorMessage(choice.text);
      setIsTyping(true);

      const question =
        questionList[Object.keys(questionList)[activeQuestionIndex - 1]];
      const alertItem = _.get(question, 'alert');
      if (alertItem) {
        alertItem.forEach((item) => {
          if (
            choice.score >= item.scoreRange.min &&
            choice.score <= item.scoreRange.max
          ) {
            if (item.content) {
              currentResponses[questionId].isAlertTriggered = true;
              setTimeout(() => {
                dispatch(
                  showModal(Alert, {
                    source: 'question-list-alert',
                    maxWidth: 500,
                    props: {
                      content: item.content,
                      onClose: _handleModalClose
                    },
                    hideCloseButton: true
                  })
                );
              }, 250);
            } else {
              logger.error(
                "Alert didn't contain the content",
                'QuestionList._handleSelection',
                { questionId }
              );
            }
          }
        });
      }

      if (choice.action) {
        if (choice.action === 'show_livechat') {
          _addToMessageQueue(null, assessmentMessageTypes.liveChatMessage);
        } else if (choice.action === 'show_emergency') {
          _addToMessageQueue(null, assessmentMessageTypes.emergencyMessage);
          setTimeout(() => {
            _handleEmergencyClick(category, choice.score);
          }, 250);
        }
        return;
      }

      setResponses(currentResponses);

      if (assessmentId === 'cat') {
        if (activeQuestionIndex < Object.keys(questionList).length) {
          _addToMessageQueue(
            questionList[Object.keys(questionList)[activeQuestionIndex]],
            assessmentMessageTypes.question
          );
          setActiveQuestionIndex((index) => index + 1);
        } else if (!isCompleted) {
          await _processQuestions(currentResponses);
        }
        if (!_.isEmpty(currentResponses)) {
          dispatch(
            saveAssessmentsProgress(currentResponses, progressId, false, state)
          );
        }
        return;
      }

      const questionIdInt = parseInt(questionId, 10);
      const nextQuestion = _getNextQuestion(
        questionIdInt + 1,
        currentResponses
      );
      if (!_.isEmpty(currentResponses)) {
        dispatch(
          saveAssessmentsProgress(
            currentResponses,
            progressId,
            !nextQuestion.hasMoreQuestions
          )
        );
      }
      if (nextQuestion.hasMoreQuestions) {
        setActiveQuestionIndex(nextQuestion.id);
        _addToMessageQueue(
          questionList[nextQuestion.id],
          assessmentMessageTypes.question
        );
      } else {
        await _generateConversationalReport(currentResponses);
      }
    },
    [
      _addToMessageQueue,
      _addVisitorMessage,
      _generateConversationalReport,
      _getNextQuestion,
      _handleEmergencyClick,
      _processQuestions,
      activeQuestionIndex,
      assessmentId,
      dispatch,
      isCompleted,
      progressId,
      questionList,
      responses,
      state,
      _handleModalClose
    ]
  );

  const _messageQueueToSystemMessage = useCallback(async () => {
    if (!_.isEmpty(messageQueue.current)) {
      const { content, type, hideTyping } = messageQueue.current[0];
      messageQueue.current.shift();
      await _addSystemMessage(content, type, hideTyping);
    }
  }, [_addSystemMessage]);

  useEffect(() => {
    (async () => {
      if (firstTime.current) {
        firstTime.current = false;
        _addGreetingMessages();
      }
      if (!_.isEmpty(questionList)) {
        setActiveQuestionIndex(1);
        _addToMessageQueue(
          questionList[Object.keys(questionList)[0]],
          assessmentMessageTypes.question,
          true
        );
      }
    })();
  }, [_addGreetingMessages, _addToMessageQueue, questionList]);

  useEffect(() => {
    (async () => {
      if (isCompleted && !isAssessmentCompleted) {
        await _generateCatReport();
      }
    })();
  }, [_generateCatReport, isCompleted, isAssessmentCompleted]);

  useEffect(() => {
    (async () => {
      if (isCompleted && !_.isEmpty(responses)) {
        dispatch(saveAssessmentsProgress(responses, progressId, true, state));
      }
    })();
  }, [dispatch, isCompleted, progressId, responses, state]);

  useEffect(() => {
    const _handleBeforeUnload = (e) => {
      if (assessmentId === 'cat') {
        dispatch(clearConversationalAssessmentData());
      }
      delete e.returnValue;
    };
    window.addEventListener('beforeunload', _handleBeforeUnload);
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (assessmentId === 'cat') {
        dispatch(clearConversationalAssessmentData());
      }
      window.removeEventListener('beforeunload', _handleBeforeUnload);
    };
  }, [assessmentId, dispatch]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }
  }, [messages, isTyping]);

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    []
  );

  useEffect(() => {
    (async () => {
      if (hasError) {
        _addToMessageQueue(null, assessmentMessageTypes.fetchError);
      }
    })();
  }, [_addToMessageQueue, hasError]);

  useEffect(() => {
    const intervalId = setInterval(_messageQueueToSystemMessage, 2000);
    return () => {
      clearInterval(intervalId);
    };
  }, [_messageQueueToSystemMessage]);

  const conversationalAssessmentContext = useMemo(
    () => ({
      assessmentId,
      _onClick: _handleSelection,
      _onResetClick: _restartAssessment,
      setIsTyping,
      _popLastChatMessage: () => {
        setMessages((prevMessages) => prevMessages.slice(0, -1));
      },
      _retryForProcessError:
        assessmentId === 'cat'
          ? _generateCatReport
          : () => _generateConversationalReport(responses),
      _retryForFetchError: hasError
        ? () => {
            dispatch(fetchConversationalAssessment(assessmentId));
          }
        : () => {
            _processQuestions(responses);
          }
    }),
    [
      _generateCatReport,
      _generateConversationalReport,
      _handleSelection,
      _processQuestions,
      _restartAssessment,
      assessmentId,
      dispatch,
      hasError,
      responses
    ]
  );

  return (
    <ConversationalAssessmentProvider value={conversationalAssessmentContext}>
      <div className={container}>
        <div className={messagesContainer}>
          {messages &&
            messages.map(
              ({ messageType, content, sender, showAvatar }, index) => (
                <div className={messageStyle} key={index}>
                  <Message
                    type={messageType}
                    content={content}
                    sender={sender}
                    showAvatar={showAvatar}
                    isActive={index === messages.length - 1}
                  />
                </div>
              )
            )}
          {isTyping && (
            <div className={messageStyle}>
              <ChatTyping />
            </div>
          )}
        </div>
        <div style={{ float: 'left' }} ref={messagesEndRef} />
      </div>
    </ConversationalAssessmentProvider>
  );
};

QuestionList.propTypes = {
  questionList: PropTypes.shape({}).isRequired,
  greetingMessages: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object)
  ])
};

QuestionList.defaultProps = {
  greetingMessages: []
};

export default QuestionList;
