import React, { useMemo, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { get, pickBy } from 'lodash';
import { Line, Chart } from 'react-chartjs-2';
import { Paper } from '@material-ui/core';
import config from 'config';
import { platformType } from 'utils/bowser';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import logger from 'utils/logger';
import PLATFORM_TYPES from 'assets/data/platform-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styles from './Graph.module.scss';
import chartConfig from './config';
import { Separator } from '../../../../../../components/ui/Layout';
import { breakpointMedium } from '../../../../../../styles/config.scss';

const { cdnBaseUrl } = config;
const {
  container,
  numberStyle,
  canvasContainer,
  imageStyle,
  leftPanelStyle,
  subheaderStyle,
  headerStyle,
  tooltipContainerStyle,
  noProgressStyle
} = styles;

const Graph = ({
  trackerProgress,
  description,
  subheader,
  emoticon,
  noProgressText
}) => {
  const canvasInstance = useRef();
  const tooltipRef = useRef();
  const { goalId, screenId, sessionId } = useParams();
  const header = description
    .replace('{{subheader}}', `\n${subheader}`)
    .split('\n')[0];
  const trackerType = description
    .replace('{{subheader}}', `\n${subheader}`)
    .split('\n')[1];
  const emoticonMap = useMemo(() => {
    const map = {};
    for (let i = 0; i <= 10; i++) {
      map[i] = get(emoticon, `${[parseInt(i / 2, 10)]}.graphEmoticonUrl`);
    }
    return map;
  }, [emoticon]);

  useEffect(() => {
    const canvas = canvasInstance.current;
    if (canvas) {
      const tooltipContainerNode = canvas.childNodes[0];
      tooltipContainerNode.style.visibility = 'hidden';
    }
  }, [trackerProgress]);

  useEffect(() => {
    Chart.register({
      id: 'chartArea',
      beforeDraw: (chartInstance) => {
        Chart.defaults.font.size = 10;
        if (chartInstance.chartArea) {
          const { ctx } = chartInstance;
          const { chartArea } = chartInstance;
          ctx.save();
          ctx.fillStyle = 'rgba(249,250,250)';
          ctx.fillRect(
            chartArea.left,
            chartArea.top,
            chartArea.right - chartArea.left,
            chartArea.bottom - chartArea.top
          );
          ctx.restore();
        }
      }
    });
  }, []);

  useEffect(() => {
    const tooltip = tooltipRef.current;
    let timer;
    const handleMouseOver = () => {
      const canvas = canvasInstance.current;
      if (canvas) {
        const tooltipContainerNode = canvas.childNodes[0];
        tooltipContainerNode.style.opacity = 1;
        tooltipContainerNode.style.visibility = 'visible';
      }
      timer = setTimeout(() => {
        analytics.track(
          eventCategories.SELF_USE,
          'Hovered over tracker point',
          pickBy({ goalId, screenId, sessionId })
        );
        logger.info(
          'User hovered over tracker point',
          'Graph.handleMouseOver',
          { platformType, ...pickBy({ goalId, screenId, sessionId }) }
        );
      }, 1000);
    };
    const handleMouseOut = () => {
      clearTimeout(timer);
      setTimeout(() => {
        const canvas = canvasInstance.current;
        if (canvas) {
          const tooltipContainerNode = canvas.childNodes[0];
          tooltipContainerNode.style.opacity = 0;
          tooltipContainerNode.style.visibility = 'hidden';
        }
      }, 1000);
    };
    if (tooltip && platformType === PLATFORM_TYPES.DESKTOP) {
      tooltip.addEventListener('mouseover', handleMouseOver);
      tooltip.addEventListener('mouseout', handleMouseOut);
    }
  }, [goalId, screenId, sessionId]);

  const chartData = useMemo(
    () => ({
      labels: trackerProgress.timestamp,
      datasets: [
        {
          data: trackerProgress.trackerValue,
          fill: 'start',
          borderColor: 'rgba(0,178,197)',
          backgroundColor: 'rgba(0,178,197,0.1)',
          pointBackgroundColor: 'black',
          pointBorderColor: 'black',
          pointBorderWidth: 0.5,
          tension: 0
        }
      ]
    }),
    [trackerProgress]
  );

  const customTooltip = useCallback(
    (tooltipModel) => {
      const canvas = canvasInstance.current;
      if (canvas) {
        const tooltipContainerNode = canvas.childNodes[0];
        const imageNode = tooltipContainerNode.childNodes[0];
        const graphValueNode = tooltipContainerNode.childNodes[1];
        const graphValue = get(tooltipModel, 'tooltip.body[0].lines[0]', '');
        imageNode.src = `${cdnBaseUrl}${emoticonMap[graphValue]}`;
        graphValueNode.innerText = graphValue;
        tooltipContainerNode.style.opacity = 1;
        tooltipContainerNode.style.visibility = 'visible';
        // Adjust tooltip position
        tooltipContainerNode.style.left = `${
          tooltipModel.tooltip.caretX - 8
        }px`;
        tooltipContainerNode.style.top = `${
          tooltipModel.tooltip.caretY - 45
        }px`;
        if (
          [PLATFORM_TYPES.MOBILE, PLATFORM_TYPES.TABLET].includes(platformType)
        ) {
          analytics.track(
            eventCategories.SELF_USE,
            'Touched tracker point',
            pickBy({ goalId, screenId, sessionId })
          );
          logger.info('User touched tracker point', 'Graph.handleMouseOver', {
            platformType,
            ...pickBy({ goalId, screenId, sessionId })
          });
        }
      }
    },
    [emoticonMap, goalId, screenId, sessionId]
  );
  const isGraphAvailable = get(trackerProgress, 'trackerValue.length', 0) !== 0;

  const isSmallScreen = useMediaQuery(`(max-width:${breakpointMedium})`);
  const mediaScreenParameters = isSmallScreen
    ? {
        height: '7%',
        width: '100%',
        x1: '8%',
        y1: '100%',
        x2: '95%',
        y2: '100%',
        stroke: 'rgba(0, 0, 0, 0.15)'
      }
    : {
        height: '100%',
        width: '3%',
        x1: '70%',
        y1: '2.5%',
        x2: '70%',
        y2: '96%',
        stroke: 'rgba(0, 0, 0, 0.15)'
      };

  return (
    <Paper className={container}>
      <div data-testid="graph-left-panel" className={leftPanelStyle}>
        <div data-testid="graph-header" className={headerStyle}>
          {header}
        </div>
        {trackerType && (
          <div data-testid="graph-subheader" className={subheaderStyle}>
            {trackerType}
          </div>
        )}
      </div>
      {isGraphAvailable ? (
        <div
          data-testid="graph-container"
          className={canvasContainer}
          ref={canvasInstance}
        >
          <div
            data-testid="tooltip-container"
            className={tooltipContainerStyle}
            ref={tooltipRef}
          >
            <img className={imageStyle} alt="" />
            <p className={numberStyle} />
          </div>
          <Line
            data={chartData}
            options={{
              clip: false,
              spanGaps: true,
              ...chartConfig.line,
              plugins: {
                ...chartConfig.line.plugins,
                tooltip: {
                  enabled: false,
                  animations: false,
                  external: (tooltipModel) => customTooltip(tooltipModel)
                }
              }
            }}
          />
        </div>
      ) : (
        <>
          <Separator {...mediaScreenParameters} />

          <div
            data-testid="no-progress-text-self-use"
            className={noProgressStyle}
          >
            {noProgressText}
          </div>
        </>
      )}
    </Paper>
  );
};

export default React.memo(Graph);
