import React from 'react';
import Icon from '@material-ui/core/Icon';
import styles from './MediaPreview.module.scss';

const {
  container,
  thumbnail,
  playIcon,
  previewTitle,
  removePreview,
  clearIcon
} = styles;

const MediaPreview = ({ title, removeMedia, mediaThumbnail }) => (
  <div data-testid="media-preview-container" className={container}>
    <div
      className={thumbnail}
      style={{
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(${mediaThumbnail})`
      }}
    >
      <Icon className={playIcon}>play_circle_filled</Icon>
    </div>
    <div className={previewTitle}>{title}</div>
    <div className={removePreview}>
      <Icon onClick={removeMedia} className={clearIcon}>
        clear
      </Icon>
    </div>
  </div>
);

export default MediaPreview;
