/**
 * Reducer to manage the state of currently active avatar
 */

import { SET_AVATAR } from '../actions/types';
import avatarList from '../../assets/data/avatarList';

const INITIAL_STATE = {
  avatarList, // avatar list from which the active avatar will be selected
  activeAvatar: null // active avatar that can be changed
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_AVATAR:
      return {
        ...state,
        activeAvatar: action.avatar
      };
    default:
      return state;
  }
};
