import { CURRENT_USER, INFO_TAB_COACH_CHAT } from '../../actions/types';

const DEFAULT_USER = null;
const SCREEN_STATE = null;

export const currentUser = (state = DEFAULT_USER, action) => {
  switch (action.type) {
    case CURRENT_USER: {
      return action.payload.user;
    }
    default:
      return state;
  }
};

export const infoTab = (state = SCREEN_STATE, action) => {
  switch (action.type) {
    case INFO_TAB_COACH_CHAT:
      return action.payload;
    default:
      return state;
  }
};
