import _ from 'lodash';
// for substituting fetch in unit tests
import 'cross-fetch/polyfill';
import ApolloClient from 'apollo-client';
import { createAuthLink } from 'aws-appsync-auth-link';
import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory';
import { CachePersistor } from 'apollo3-cache-persist';
import { RetryLink } from 'apollo-link-retry';
import QueueLink from 'apollo-link-queue';
import SerializingLink from 'apollo-link-serialize';
import { createSubscriptionHandshakeLink } from 'wayforward-aws-appsync-subscription-link';
import { ApolloLink, concat } from 'apollo-link';
// eslint-disable-next-line import/no-cycle
import auth, { authService } from '../auth';
// eslint-disable-next-line import/no-cycle
import logger from '../logger';
// eslint-disable-next-line import/no-cycle
import Storage from '../storage/apollo';
// TODO: Need to fix dependency cycle
// eslint-disable-next-line import/no-cycle
import errorLink from './error-link';
// eslint-disable-next-line import/no-cycle
import config from '../../config';
import {
  MAX_RETRY_ATTEMPTS,
  INITIAL_DELAY,
  AUTHENTICATION_TYPE,
  FETCH_POLICIES
} from './graphQL-config';

const retryLink = new RetryLink({
  attempts: {
    max: MAX_RETRY_ATTEMPTS,
    retryIf: (error) => {
      const statusCode = _.get(error, 'statusCode', null);
      if (statusCode) {
        return statusCode === 429 || statusCode >= 500;
      }
      return true;
    }
  },
  delay: { initial: INITIAL_DELAY, jitter: false }
});
// Setting dataId to url so that we don't use cloudfront cdn url in the apollo cache
// TODO: Set up a proper strategy for versioning so that we do not inadvertently use cached data
const cache = new InMemoryCache({
  dataIdFromObject(responseObj) {
    if (responseObj.__typename === 'MediaContent') {
      return responseObj.url;
    }
    return defaultDataIdFromObject(responseObj);
  }
});

export const persistor = new CachePersistor({
  cache,
  storage: new Storage()
});

const apolloConfig = {
  url: config.chat.url,
  region: config.chat.region,
  auth: {
    type: AUTHENTICATION_TYPE,
    jwtToken: async () => {
      try {
        await auth.renewAccessTokenIfExpired();
      } catch (error) {
        logger.error(
          'Error while renewing access token',
          'apolloClient.apolloConfig',
          { error }
        );
      }
      return authService.getAccessToken();
    }
  }
};

const client = new ApolloClient({
  link: ApolloLink.from([
    new QueueLink(),
    new SerializingLink(),
    errorLink,
    concat(createAuthLink(apolloConfig), retryLink),
    createSubscriptionHandshakeLink(apolloConfig)
  ]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: FETCH_POLICIES.cacheAndNetwork
    }
  }
});

export default client;
