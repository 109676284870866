import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Icon, Collapse, Button } from '@material-ui/core';
import { brandColorPrimary, fontWeightBold } from 'styles/config.scss';
import { OutlineButton } from '../../../../components/ui/Buttons';
import Aux from '../../../../components/layout/Aux';
import styles from './SelfHarmContent.module.scss';
import Lang from '../../../../components/Lang';

const {
  selfHarmContainer,
  selfHarmButton,
  selfHarmPhone,
  selfHarmPhoneContainer,
  importantButtonContainer,
  expandIconStyle,
  confirmButton,
  confirmTextStyle,
  selfHarmTextStyle,
  hrStyle
} = styles;

class SelfHarmContent extends Component {
  state = { expanded: true };

  _handleExpand = () => {
    this.setState({ expanded: true });
  };

  _handleCollapse = () => {
    this.setState({ expanded: false });
  };

  render() {
    const {
      content: { body, footer },
      header
    } = this.props;
    return (
      <Aux>
        <Row>
          <Col xs={24} className={importantButtonContainer}>
            {!this.state.expanded && (
              <Button
                style={{
                  color: brandColorPrimary,
                  fontWeight: fontWeightBold
                }}
                onClick={this._handleExpand}
              >
                <Lang path="selfHarmContent.expandButton" />
                <Icon className={expandIconStyle}>expand_more</Icon>
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={24} lg={{ span: 16, offset: 4 }}>
            <Collapse in={this.state.expanded}>
              <div className={selfHarmContainer}>
                <p className={selfHarmTextStyle}>{header}</p>
                <div className={selfHarmPhoneContainer}>
                  <Icon>phone</Icon>
                  <a className={selfHarmPhone} href={`tel:${body}`}>
                    {body}
                  </a>
                </div>
                <hr className={hrStyle} />
                <p className={confirmTextStyle}>{footer}</p>
                <div className={selfHarmButton}>
                  <OutlineButton
                    className={confirmButton}
                    onClick={this._handleCollapse}
                  >
                    <Lang path="selfHarmContent.dismissButton" />
                  </OutlineButton>
                </div>
              </div>
            </Collapse>
          </Col>
        </Row>
      </Aux>
    );
  }
}

export default SelfHarmContent;
