/**
 * Reducer to hold the list of current responses added by the user (live Chat)
 */

import _ from 'lodash';
import {
  ADD_SYSTEM_CHAT_MESSAGE,
  ADD_VISITOR_CHAT_MESSAGE,
  SYSTEM_MESSAGE_TYPING,
  SYSTEM_MESSAGE_TYPING_DONE,
  POP_LAST_CHAT_MESSAGE
} from '../actions/types';

import messageTypes from '../../assets/data/dashboard/assessmentMessageTypes';

const INITIAL_STATE = {
  isTyping: false,
  messages: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_SYSTEM_CHAT_MESSAGE: {
      let showAvatar = true;
      if (
        state.messages.length > 0 &&
        state.messages[state.messages.length - 1].sender === 'system'
      ) {
        showAvatar = false;
      }
      const { messageType, content } = action;
      return {
        ...state,
        messages: _.concat(state.messages, {
          messageType,
          content,
          sender: 'system',
          showAvatar
        })
      };
    }
    case ADD_VISITOR_CHAT_MESSAGE: {
      let showAvatar = true;
      if (
        state.messages.length > 0 &&
        state.messages[state.messages.length - 1].sender === 'visitor'
      ) {
        showAvatar = false;
      }
      return {
        ...state,
        messages: _.concat(state.messages, {
          messageType: messageTypes.text,
          content: action.message,
          sender: 'visitor',
          showAvatar
        })
      };
    }
    case POP_LAST_CHAT_MESSAGE: {
      const currentMessages = state.messages;
      return {
        ...state,
        messages: currentMessages.slice(0, currentMessages.length - 1)
      };
    }
    case SYSTEM_MESSAGE_TYPING: {
      return { ...state, isTyping: true };
    }
    case SYSTEM_MESSAGE_TYPING_DONE: {
      return { ...state, isTyping: false };
    }
    default:
      return state;
  }
};
