import _ from 'lodash';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import { Storage } from '@capacitor/storage';
// eslint-disable-next-line import/no-cycle
import { getStorageEncryptionKey } from './encryption-key';
// eslint-disable-next-line import/no-cycle
import logger from '../logger';
// eslint-disable-next-line import/no-cycle
import helpers from '../helpers';
// eslint-disable-next-line import/no-cycle
import { isNativePlatform } from '../capacitor';

const encrypt = async (value) => {
  try {
    const key = await getStorageEncryptionKey();
    return AES.encrypt(value, key).toString();
  } catch (error) {
    logger.error('Error in encrypt function', 'storage.encrypt', {
      error
    });
    throw error;
  }
};

const decrypt = async (value) => {
  try {
    const key = await getStorageEncryptionKey();
    const bytes = AES.decrypt(value, key);
    return bytes.toString(Utf8);
  } catch (error) {
    logger.error('Error in decrypt function', 'storage.decrypt', {
      error
    });
    throw error;
  }
};

// encrypt/decrypt only on native platforms,
// use session storage on web
export default {
  setItem: async (key, value) => {
    if (isNativePlatform) {
      try {
        // Setting value in mobile app storage
        if (!_.isEmpty(value)) {
          await Storage.set({
            key,
            value: await encrypt(JSON.stringify({ value }))
          });
        } else {
          logger.warn(
            `Value to be saved in mobile app session storage for ${key} is empty`,
            'storage.setItem',
            { value }
          );
        }
      } catch (error) {
        logger.error('Error in setItem function', 'storage.setItem', {
          error,
          key
        });
      }
    } else if (!_.isEmpty(value)) {
      window.sessionStorage.setItem(key, value);
    } else {
      logger.warn(
        `Value to be saved in web app session storage for ${key} is empty`,
        'storage.setItem',
        { value }
      );
    }
  },
  getItem: async (key) => {
    let value = null;
    if (isNativePlatform) {
      try {
        // Getting value from mobile app storage
        ({ value } = await Storage.get({ key }));
        if (!_.isEmpty(value)) {
          value = await decrypt(value);
          ({ value } = helpers.getParsedValue(value));
        } else {
          logger.warn(
            `Value for ${key} is not available in mobile app session storage`,
            'storage.getItem',
            { value }
          );
        }
      } catch (error) {
        logger.error('Error in getItem function', 'storage.getItem', {
          error,
          key
        });
      }
    } else {
      value = window.sessionStorage.getItem(key);
    }
    return value;
  },
  removeItem: async (key) => {
    if (isNativePlatform) {
      try {
        // Removing value from mobile app storage
        await Storage.remove({ key });
      } catch (error) {
        logger.error('Error in removeItem function', 'storage.removeItem', {
          error,
          key
        });
      }
    } else {
      // Removing value from web application storage
      window.sessionStorage.removeItem(key);
    }
  }
};
