import React, { useEffect, Fragment, useState, useMemo } from 'react';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/react-hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { NetworkStatus } from '@apollo/client';
import logger from 'utils/logger';
import api from 'api/assessment';
import config from 'config';
import ErrorRetry from '../../../../components/ErrorRetry';
import Lang from '../../../../components/Lang';
import styles from './SelectedGoal.module.scss';
import { GET_GOAL_LIST, GET_SELECTED_GOAL_INFO } from '../../queries';
import { UPSERT_USER_GOAL } from '../../mutations';
import { getSelectedGoalInfoOptions } from '../../mutationOptions';
import { LoaderSmall, LoaderLarge } from './SelectedGoal.skeleton';

const {
  topContainer,
  container,
  imageStyle,
  currentGoalTextStyle,
  goalNameStyle,
  currentGoalContainer,
  sessionStyle,
  goalDescriptionContainer,
  currentSessionStyle,
  sessionTextStyle,
  goalNameContainerStyle,
  totalSessionsContainer,
  totalSessionTextStyle,
  imageContainer,
  containerTextStyle
} = styles;
const { cdnBaseUrl } = config;

const SelectedGoal = ({ refProp }) => {
  const [upsertUserGoal] = useMutation(UPSERT_USER_GOAL);
  const [currentGoal, setCurrentGoal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [completedSessionsCount, setCompletedSessionsCount] = useState(0);
  const {
    loading,
    error,
    data: currentGoalData,
    refetch,
    networkStatus
  } = useQuery(GET_SELECTED_GOAL_INFO, {
    notifyOnNetworkStatusChange: true
  });
  const {
    loading: goalListDataLoading,
    data: goalListData,
    error: currentGoalDataError
  } = useQuery(GET_GOAL_LIST);

  const useMobileImage = useMediaQuery('(max-width: 991px)');
  const isLargeScreen = useMediaQuery('(min-width: 768px)');
  // For backward compatibility
  useEffect(() => {
    if (
      !loading &&
      !_.isUndefined(goalListData) &&
      !_.isUndefined(currentGoalData)
    ) {
      const current =
        _.get(goalListData, 'getGoalList.goals', []).find(
          (goal) =>
            goal.goalId ===
            _.get(currentGoalData, 'getSelectedGoalInfo.goalId', '')
        ) || {};
      setCompletedSessionsCount(
        _.get(currentGoalData, 'getSelectedGoalInfo.completedSessionsCount', 0)
      );
      if (_.isEmpty(current)) {
        api
          .getLatestSelectedGoal()
          .then((resp) => {
            if (resp.status === 200 && Object.keys(resp.data).length > 0) {
              const categoryId = Object.keys(resp.data)[0];
              const goalData = _.get(
                goalListData,
                'getGoalList.goals',
                []
              ).find((goal) => goal.goalId === categoryId);
              if (goalData) {
                setCurrentGoal(goalData);
                setIsLoading(false);
                upsertUserGoal(
                  getSelectedGoalInfoOptions({
                    query: GET_SELECTED_GOAL_INFO,
                    id: categoryId,
                    version: goalData.version
                  })
                )
                  .then((res) => {
                    logger.info(
                      'Successfully upsert selectedGoal for the backward comptability use case.',
                      'SelectedGoal.SelectedGoal',
                      { res, categoryId }
                    );
                  })
                  .catch((err) => {
                    logger.error(
                      'Unable to upsert the selectedgoal for the backward comptability use case.',
                      'SelectedGoal.SelectedGoal',
                      { err, id: categoryId }
                    );
                  });
              } else {
                logger.error(
                  'GoalId not found in goalList data of graphCMS',
                  'SelectedGoal.SelectedGoal'
                );
                setIsError(true);
                setIsLoading(false);
              }
            }
          })
          .catch((err) => {
            setIsError(true);
            logger.error(
              'Error occurred while fetching last chat assessment',
              'SelectedGoal.SelectedGoal',
              { error: JSON.parse(JSON.stringify(err)) }
            );
          });
      } else {
        setCurrentGoal(current);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [upsertUserGoal, loading, goalListData, currentGoalData, error]);

  useEffect(() => {
    if (
      loading ||
      goalListDataLoading ||
      networkStatus === NetworkStatus.refetch
    ) {
      setIsLoading(true);
    } else if (
      (error || currentGoalDataError) &&
      !loading &&
      !goalListDataLoading &&
      networkStatus !== NetworkStatus.refetch
    ) {
      logger.error(
        'Error occurred while fetching the current selected goal data',
        'SelectedGoal.SelectedGoal',
        { error, currentGoalDataError }
      );
      setIsLoading(false);
      setIsError(true);
    } else {
      setIsLoading(false);
      setIsError(false);
    }
  }, [
    loading,
    networkStatus,
    error,
    currentGoalDataError,
    goalListDataLoading
  ]);
  const LoaderComponent = useMemo(
    () => (isLargeScreen ? LoaderLarge : LoaderSmall),
    [isLargeScreen]
  );
  if (isLoading) {
    return <LoaderComponent />;
  }

  if (isError) {
    return (
      <ErrorRetry
        isFetching={false}
        onClick={() => refetch()}
        title={<Lang path="selfUse.goalList.currentGoalErrorText" />}
      />
    );
  }

  return (
    <div ref={refProp} className={topContainer}>
      {!_.isEmpty(currentGoal) && (
        <Fragment>
          <div
            data-testid="start-self-care-text"
            className={containerTextStyle}
          >
            <Lang path="selfUse.goalList.startYourSelfCareText" />
          </div>
          <div className={container}>
            <div className={imageContainer}>
              <img
                className={imageStyle}
                src={`${cdnBaseUrl}${
                  useMobileImage
                    ? currentGoal.imageUrl.topBarMobile
                    : currentGoal.imageUrl.topBar
                }`}
                alt={currentGoal.name}
              />
            </div>
            <div className={goalDescriptionContainer}>
              <div className={currentGoalContainer}>
                <span
                  data-testid="self-use-current-goal-text"
                  className={currentGoalTextStyle}
                >
                  <Lang path="selfUse.goalList.currentGoalText" />
                </span>
                <div className={goalNameContainerStyle}>
                  <span
                    data-testid="self-use-current-goal-name"
                    className={goalNameStyle}
                  >
                    {currentGoal.name}
                  </span>
                </div>
              </div>
              <div className={sessionStyle}>
                <span
                  data-testid="self-use-sessions-text"
                  className={sessionTextStyle}
                >
                  <Lang
                    path="selfUse.goalList.sessionsText"
                    values={{
                      totalSessions: ''
                    }}
                  />
                </span>
                <div className={totalSessionsContainer}>
                  <span
                    data-testid="self-use-current-session"
                    className={currentSessionStyle}
                  >
                    {completedSessionsCount}
                  </span>
                  <span
                    data-testid="self-use-total-sessions"
                    className={totalSessionTextStyle}
                  >
                    {`/${currentGoal.totalSessions}`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default SelectedGoal;
