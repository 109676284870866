/**
 * Radio field wrapped with our custom style to appear as selectable radio images.
 * Currently used for avatar selection
 */

import React from 'react';
import { Radio } from 'antd';

import styles from './RadioImage.module.scss';

const RadioImage = (props) => {
  const { src, alt, value } = props;
  const { radioField, img } = styles;
  return (
    <Radio className={radioField} value={value}>
      <img src={src} alt={alt} className={img} />
    </Radio>
  );
};

export default RadioImage;
