import React, { useState, useEffect, useCallback } from 'react';
import { Network } from '@capacitor/network';
import { delay } from 'lodash';
// components
import { ActionButton } from '../ui/Buttons';
import Lang from '../Lang';
import Error from '../Error';
// utils
import logger from '../../utils/logger';
import { useNetworkListener } from '../../utils/hooks';
import config from '../../config';
// styles
import styles from './NoConnectionError.module.scss';

const { supportEmail } = config;

const { container } = styles;

const NoConnectionError = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOnline, setIsOnline] = useNetworkListener();
  useEffect(() => {
    // Disabling scroll so that app cannot be seen by scrolling down
    // when NoConnectionError is shown
    if (!isOnline) {
      document.body.classList.add('disable-scroll-style');
    } else {
      document.body.classList.remove('disable-scroll-style');
    }
  }, [isOnline]);

  const checkNetworkStatus = useCallback(async () => {
    setIsLoading(true);
    const status = await Network.getStatus();
    logger.info(
      'Checking network status',
      'noConnectionError.checkNetworkStatus',
      { status }
    );
    setIsOnline(status.connected);
    // to throttle retry attempts
    delay(setIsLoading, 2500, false);
  }, [setIsOnline]);
  const footerContent = [
    <ActionButton onClick={() => checkNetworkStatus()}>
      <Lang path="noConnection.retryButtonText" />
    </ActionButton>
  ];
  return (
    <>
      <div
        className={container}
        style={!isOnline ? { display: 'block' } : { display: 'none' }}
      >
        <Error
          message={<Lang path="noConnection.message" />}
          content={
            // TODO: use href mailto for support email
            <Lang path="noConnection.content" values={{ supportEmail }} />
          }
          isLoading={isLoading}
          footer={footerContent}
        />
      </div>
    </>
  );
};

export default NoConnectionError;
