import React from 'react';
import propTypes from 'prop-types';
import { Modal, Button } from 'antd';
import HelpOutlineSharpIcon from '@material-ui/icons/HelpOutlineSharp';

import i18n from 'assets/lang';

import styles from './InProgressPopup.module.scss';

const {
  inProgressTextDisplayStyle,
  inProgressIconStyle,
  inProgressTitleStyle,
  inProgressIconContainerStyle
} = styles;

// popup to ask and save unsaved data
const InProgressPopup = ({ setPopupVisible, buttonConfig, popupVisible }) => {
  if (buttonConfig) {
    return (
      <Modal
        visible={!!popupVisible}
        width={416}
        onCancel={() => setPopupVisible(false)}
        maskClosable
        footer={buttonConfig.buttons.map(
          ({ text, action, buttonColor, style }, index) => (
            <Button
              key={index}
              data-testid={`popup-button-${index}`}
              type={buttonColor}
              className={style}
              onClick={() => {
                setPopupVisible(false);
                return action();
              }}
            >
              {text}
            </Button>
          )
        )}
      >
        <div className={inProgressTextDisplayStyle} data-testid="text-display">
          <div
            className={inProgressIconContainerStyle}
            data-testid="icon-container"
          >
            <HelpOutlineSharpIcon className={inProgressIconStyle} />
          </div>
          <div className={inProgressTitleStyle} data-testid="title">
            {i18n.t('selfUse.session.popup.unsavedProgress.title')}
          </div>
        </div>
      </Modal>
    );
  }
  return null;
};

InProgressPopup.propTypes = {
  setPopupVisible: propTypes.func,
  popupVisible: propTypes.bool,
  buttonConfig: propTypes.shape({
    button: propTypes.arrayOf({
      text: propTypes.string,
      action: propTypes.func,
      buttonColor: propTypes.string
    })
  })
};

InProgressPopup.defaultProps = {
  setPopupVisible: () => null,
  popupVisible: false,
  buttonConfig: {}
};
export default InProgressPopup;
