/**
 *
 * VideoTherapy
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import uuid from 'uuid/v1';
// pick utils

import requestVideoTherapy, {
  clearVideoTherapyStatusError
} from 'store/actions/dashboard/VideoTherapyRequestAction';
import analytics from 'utils/analytics';
import helpers from 'utils/helpers';
import config from 'config';
import countryOptions from 'assets/data/profile/countryOptions';
import VideoTherapyRequestForm from '../../../forms/VideoTherapyRequest/VideoTherapyRequest';

class VideoTherapyRequest extends React.Component {
  componentDidMount() {
    this.requestId = uuid();
    this.props.actions.clearVideoTherapyStatusError();
  }

  _handleSubmit = (params) => {
    analytics.track('modal request appointment', 'clicked submit', {
      goalId: this.props.goalId,
      severity: this.props.severity
    });

    const {
      firstName,
      lastName,
      date1,
      date2,
      date3,
      date4,
      phoneCode,
      phone,
      ...rest
    } = params;
    const appointments = [helpers.getLocalTime(date1)];
    if (date2) {
      appointments.push(helpers.getLocalTime(date2));
    }
    if (date3) {
      appointments.push(helpers.getLocalTime(date3));
    }
    if (date4) {
      appointments.push(helpers.getLocalTime(date4));
    }

    const paramsToSend = { ...rest, appointments };
    const countryCode = phoneCode.split(' ')[1] || '';

    paramsToSend.phone = `${countryCode}${phone}`;
    paramsToSend.fullName = `${firstName} ${lastName}`;
    delete paramsToSend.phoneCode;
    delete paramsToSend.companyName;

    const { profile } = this.props;

    paramsToSend.companyName = profile.employer_id || profile.company_name;

    paramsToSend.isEmailAllowed = params.isEmailAndVoiceMailAllowed;
    paramsToSend.isVoiceMailAllowed = params.isEmailAndVoiceMailAllowed;
    paramsToSend.sessionCount = this.props.sessionCount.video[
      this.props.severity
    ];
    paramsToSend.source = config.requestManagementSource;
    paramsToSend.requestId = this.requestId;
    this.props.actions.requestVideoTherapy(paramsToSend);
  };

  render() {
    const {
      profile,
      videoTherapy: {
        request: { isFetching, hasError }
      }
    } = this.props;
    const selectedCountry = countryOptions.find(
      ({ country }) => country === profile.country
    );

    let firstName = '';
    let lastName = '';
    if (profile.name) {
      [firstName] = profile.name.split(' ');
      lastName = profile.name.substring(firstName.length).trim();
    } else {
      firstName = profile.first_name;
      lastName = profile.last_name;
    }

    return (
      <VideoTherapyRequestForm
        onSubmit={this._handleSubmit}
        initialValues={{
          email: profile.email,
          firstName,
          lastName,
          companyName: profile.employer_id || profile.company_name,
          date1: null,
          date2: null,
          date3: null,
          date4: null,
          birthDay: profile.birth_day,
          birthMonth: profile.birth_month,
          birthYear: profile.birth_year,
          phone: profile.phone,
          phoneCode: _.get(selectedCountry, 'value', ''),
          isEmailAndVoiceMailAllowed: true
        }}
        isFetching={isFetching}
        hasError={hasError}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const {
    profile: { profile },
    videoTherapy,
    dashBoard: { goalId, severity, sessionCount }
  } = state;
  return {
    profile,
    videoTherapy,
    goalId,
    severity,
    sessionCount
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    requestVideoTherapy: bindActionCreators(requestVideoTherapy, dispatch),
    clearVideoTherapyStatusError: bindActionCreators(
      clearVideoTherapyStatusError,
      dispatch
    )
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoTherapyRequest);
