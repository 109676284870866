import React, { useState } from 'react';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { Alert } from 'antd';
import { Field, reduxForm } from 'redux-form';
import featureService from 'utils/feature-service';
import DateTimeField from '../../../../components/form';
import { ActionButton } from '../../../../components/ui/Buttons';

import styles from './ReportTimePicker.module.scss';

// utils
import logger from '../../../../utils/logger';
import moment from '../../../../utils/moment';

import Lang from '../../../../components/Lang';

const { buttonStyle, container, formFieldStyle, errorAlertStyle } = styles;

const ReportTimePickerForm = reduxForm({
  form: 'reportTimePicker',
  destroyOnUnmount: false,
  enableReinitialize: true
})(({ handleSubmit, isLoading, isSuccess, hasError }) => {
  const isDisabled = (timestamp) =>
    timestamp.valueOf() >= moment().startOf('day').valueOf();
  return (
    <form onSubmit={handleSubmit}>
      <div className={container}>
        <Field
          className={formFieldStyle}
          name="reportTime"
          component={DateTimeField}
          label={<Lang path="reportTimeLabelText" />}
          validate={(val) => !val && 'Please select the time'}
          invalidDateMessage={null}
          shouldDisableDate={isDisabled}
        />
        <ActionButton
          isLoading={isLoading}
          className={buttonStyle}
          onClick={handleSubmit}
        >
          <Lang path="reportTimeUpdateButtonText" />
        </ActionButton>
        {isSuccess && (
          <div>
            <Lang path="reportTimeUpdateSuccessText" />
          </div>
        )}
        {hasError && (
          <Alert
            className={errorAlertStyle}
            message={<Lang path="reportTimeUpdateErrorText" />}
            showIcon
            type="error"
          />
        )}
      </div>
    </form>
  );
});

const ReportTimePicker = ({ reportId, updateFunction }) => {
  const [state, setState] = useState({
    isLoading: false,
    isSuccess: false,
    hasError: false
  });

  const _handleSubmit = async ({ reportTime }) => {
    setState({ isLoading: true, isSuccess: false, hasError: false });
    try {
      const response = await updateFunction({
        reportId,
        timestamp: moment(reportTime).format('MMMM DD, YYYY HH:mm:ss')
      });
      setState({ ...state, isLoading: false });
      if (response.status === 200) {
        setState({ ...state, isSuccess: true });
      } else {
        setState({ ...state, hasError: true });
        logger.error(
          'Error occurred while updating report(s) time',
          'ReportTimePicker',
          { response }
        );
      }
    } catch (error) {
      setState({ ...state, isLoading: false, hasError: true });
      logger.error(
        'Error occurred while updating report(s) time',
        'ReportTimePicker',
        { error }
      );
    }
  };

  return (
    featureService._hasTestControlFeature() && (
      <MuiThemeProvider theme={createMuiTheme()}>
        <ReportTimePickerForm
          onSubmit={_handleSubmit}
          isLoading={state.isLoading}
          isSuccess={state.isSuccess}
          hasError={state.hasError}
          initialValues={{ reportTime: null }}
        />
      </MuiThemeProvider>
    )
  );
};

export default ReportTimePicker;
