import React from 'react';

import { Scrollbars } from 'react-custom-scrollbars';
import closeBlue from 'assets/icons/closeBlue.svg';
import Lang from '../../../../../components/Lang';

import Label from '../Label';
import styles from './ChatFilter.module.scss';

const { filterIcon, selectFilter, allCheck, inputSec, filterLabel } = styles;

const ChatFilter = ({
  onSelectActivityFilterHandler,
  onSelectMessageFilterHandler,
  selectedActivityFilter,
  selectedMessageFilter,
  clearFiltersHandler
}) => (
  <div className={filterIcon}>
    <h5>
      <Lang path="coachChat.filterText" />
      <button
        data-testid="filter-clear-button"
        type="button"
        onClick={clearFiltersHandler}
      >
        <Lang path="coachChat.filter.clearText" />
        <img src={closeBlue} alt="" />
      </button>
    </h5>
    <div className={selectFilter}>
      <Scrollbars autoHide>
        <div className={allCheck}>
          <h6>
            <Lang path="coachChat.filter.statusText" />
          </h6>
          <div className={inputSec} data-testid="status-show-all-filter">
            <input
              type="radio"
              name="message"
              value=""
              checked={selectedMessageFilter === ''}
              onChange={() => onSelectMessageFilterHandler('')}
            />
            <Label
              className={filterLabel}
              onClick={() => onSelectMessageFilterHandler('')}
              htmlFor="all"
            >
              <Lang path="coachChat.filter.showAllText" />
            </Label>
          </div>
          <div className={inputSec} data-testid="read-filter">
            <input
              type="radio"
              name="message"
              checked={selectedMessageFilter === 'read'}
              value="read"
              onChange={() => onSelectMessageFilterHandler('read')}
            />
            <Label
              className={filterLabel}
              onClick={() => onSelectMessageFilterHandler('read')}
              htmlFor="read"
            >
              <Lang path="coachChat.filter.readText" />
            </Label>
          </div>
          <div className={inputSec} data-testid="unread-filter">
            <input
              type="radio"
              name="message"
              checked={selectedMessageFilter === 'unread'}
              value="unread"
              onChange={() => onSelectMessageFilterHandler('unread')}
              data-testid="unread-filter-button"
            />
            <Label
              className={filterLabel}
              onClick={() => onSelectMessageFilterHandler('unread')}
              htmlFor="unread"
            >
              <Lang path="coachChat.filter.unreadText" />
            </Label>
          </div>
        </div>

        <div className={allCheck}>
          <h6>
            <Lang path="coachChat.filter.activityText" />
          </h6>
          <div className={inputSec} data-testid="activity-show-all-filter">
            <input
              type="radio"
              name="activity"
              value=""
              checked={selectedActivityFilter === ''}
              onChange={() => onSelectActivityFilterHandler('')}
            />
            <Label
              onClick={() => onSelectActivityFilterHandler('')}
              htmlFor="all"
              className={filterLabel}
            >
              <Lang path="coachChat.filter.showAllText" />
            </Label>
          </div>
          <div className={inputSec} data-testid="active-filter">
            <input
              type="radio"
              name="activity"
              checked={selectedActivityFilter === 'active'}
              value="active"
              onChange={() => onSelectActivityFilterHandler('active')}
            />
            <Label
              onClick={() => onSelectActivityFilterHandler('active')}
              htmlFor="activity"
              className={filterLabel}
            >
              <Lang path="coachChat.filter.activeText" />
            </Label>
          </div>
          <div className={inputSec} data-testid="inactive-7-days-filter">
            <input
              type="radio"
              name="activity"
              value="inactive7d"
              checked={selectedActivityFilter === 'inactive7d'}
              onChange={() => onSelectActivityFilterHandler('inactive7d')}
            />
            <Label
              onClick={() => onSelectActivityFilterHandler('inactive7d')}
              htmlFor="inactive7d"
              className={filterLabel}
            >
              <Lang
                path="coachChat.filter.inactiveText"
                values={{ days: '7' }}
              />
            </Label>
          </div>
          <div className={inputSec} data-testid="inactive-14-days-filter">
            <input
              type="radio"
              name="activity"
              value="inactive14d"
              checked={selectedActivityFilter === 'inactive14d'}
              onChange={() => onSelectActivityFilterHandler('inactive14d')}
            />
            <Label
              onClick={() => onSelectActivityFilterHandler('inactive14d')}
              htmlFor="inactive14d"
              className={filterLabel}
            >
              <Lang
                path="coachChat.filter.inactiveText"
                values={{ days: '14' }}
              />
            </Label>
          </div>
          <div className={inputSec} data-testid="inactive-21-days-filter">
            <input
              type="radio"
              name="activity"
              value="inactive21d"
              checked={selectedActivityFilter === 'inactive21d'}
              onChange={() => onSelectActivityFilterHandler('inactive21d')}
            />
            <Label
              onClick={() => onSelectActivityFilterHandler('inactive21d')}
              htmlFor="inactive21d"
              className={filterLabel}
            >
              <Lang
                path="coachChat.filter.inactiveText"
                values={{ days: '21' }}
              />
            </Label>
          </div>
        </div>
      </Scrollbars>
    </div>
  </div>
);

export default ChatFilter;
