/**
 * Action that adds the response to the list of responses
 */

import { ADD_RESPONSE, REMOVE_RESPONSE, REMOVE_LAST_RESPONSE } from './types';

export const addResponse = (questionId, response, score, category) => ({
  type: ADD_RESPONSE,
  questionId,
  response,
  score,
  category
});

export const removeResponse = (questionId) => ({
  type: REMOVE_RESPONSE,
  questionId
});

export const removeLastResponse = () => ({ type: REMOVE_LAST_RESPONSE });
