import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
// components
// utils
import analytics from 'utils/analytics';
import history from 'utils/history';
import featureService from 'utils/feature-service';
import helpers from 'utils/helpers';
// styles
// actions
import { clearConversationalAssessmentData } from 'store/actions/ConversationalAssessmentAction';
// assets
import messageTypes from 'assets/data/dashboard/assessmentMessageTypes';
// utils
import RenderLink from '../../../../../components/RenderLink';
import RadioButton from '../../../../../components/wrapped/RadioButton';
import Lang from '../../../../../components/Lang';
// common
import { UnderlineButton } from '../../../../../components/ui/Buttons';
import ConversationalAssessmentContext from '../../ConversationalAssessmentContext';
import styles from './Message.module.scss';
import ResultCard from '../ResultCard/ResultCard';
import Bubble from '../Bubble/Bubble';
import ActionPlanCard from '../ActionPlanCard/ActionPlanCard';
import Avatar from '../Avatar/Avatar';

const {
  systemMessageStyle,
  userMessageStyle,
  choicesContainer,
  choiceStyle,
  choiceButtonStyle,
  errorButtonStyle
} = styles;

const TextMessage = ({ children, sender }) => {
  if (Array.isArray(children)) {
    return children.map((item) => (
      <TextMessage key={item} sender={sender}>
        {item}
      </TextMessage>
    ));
  }
  return (
    <Fragment key={children}>
      {sender === 'system' && (
        <div className={systemMessageStyle}>
          {children && <Bubble>{children}</Bubble>}
        </div>
      )}
      {sender === 'visitor' && (
        <div className={userMessageStyle}>
          <Bubble user>{children}</Bubble>
        </div>
      )}
    </Fragment>
  );
};

const Message = ({ type, content, sender, showAvatar, isActive }) => {
  const { t } = useTranslation();
  const conversationalAssessmentContext = useContext(
    ConversationalAssessmentContext
  );
  const eapPhone = useSelector((state) =>
    _.get(state, 'companyDetails.companyDetails.partner.eap_phone', '')
  );
  const country = useSelector((state) =>
    _.get(state, 'profile.profile.country', '')
  );
  const dispatch = useDispatch();
  const hasAutoCoachAssignment =
    featureService._hasAutoCoachAssignmentOnCompletingSurveyFeature();
  const coachDesc = t(
    'conversationalAssessment.actionPlanCard.assessmentResult.coachDesc'
  );
  const assessmentResultDesc = t(
    'conversationalAssessment.actionPlanCard.assessmentResult.description'
  );
  const description =
    (hasAutoCoachAssignment && coachDesc) || assessmentResultDesc;
  const coachButtonText = t(
    'conversationalAssessment.actionPlanCard.assessmentResult.coachButtonText'
  );
  const assessmentResultButtonText = t(
    'conversationalAssessment.actionPlanCard.assessmentResult.buttonText'
  );
  const buttonText =
    (hasAutoCoachAssignment && coachButtonText) || assessmentResultButtonText;
  switch (type) {
    case messageTypes.text:
      return (
        <>
          <Avatar sender={sender} showAvatar={showAvatar} />
          <TextMessage sender={sender}>{content}</TextMessage>
        </>
      );
    case messageTypes.question: {
      const { text, id, choices, type: category } = content;
      return (
        <>
          <Avatar sender={sender} showAvatar={showAvatar} />
          {text && <TextMessage sender={sender}>{text}</TextMessage>}
          <div className={choicesContainer}>
            {isActive &&
              choices.map((choice, choiceIndex) => (
                <div
                  className={choiceStyle}
                  data-testid={`choice-${choiceIndex}`}
                  key={choice.text}
                >
                  <RadioButton
                    onClick={() => {
                      conversationalAssessmentContext._onClick(
                        id,
                        choice,
                        category
                      );
                    }}
                    value={choice.text}
                    score={choice.score}
                    customStyle={choiceButtonStyle}
                  >
                    {choice.text}
                  </RadioButton>
                </div>
              ))}
          </div>
        </>
      );
    }
    case messageTypes.assessmentResult:
      return (
        <>
          <Avatar sender={sender} showAvatar={showAvatar} />
          <ResultCard
            buttonText={buttonText}
            title={
              <Lang path="conversationalAssessment.actionPlanCard.assessmentResult.title" />
            }
            description={description}
            onClickHandler={() => {
              const redirectUrl =
                (helpers.isCoach() && '/coach/chat') ||
                (hasAutoCoachAssignment && '/coach') ||
                '/my-plan';
              analytics.track(
                'assessment chatbox',
                !helpers.isCoach()
                  ? `clicked ${
                      hasAutoCoachAssignment ? 'next' : 'my plan'
                    } plan`
                  : 'Coach clicked next',
                {
                  goalId: content.categoryId,
                  severity: content.severity
                }
              );
              dispatch(clearConversationalAssessmentData());
              history.push(redirectUrl);
            }}
          />
        </>
      );
    case messageTypes.liveChatMessage:
      return (
        <>
          <Avatar sender={sender} showAvatar={showAvatar} />
          <ActionPlanCard
            showResetLink={isActive}
            title={
              <Lang path="conversationalAssessment.actionPlanCard.title" />
            }
            desc={
              <Lang path="conversationalAssessment.actionPlanCard.liveChatMessage.description" />
            }
            buttonText={
              <Lang path="conversationalAssessment.actionPlanCard.liveChatMessage.buttonText" />
            }
            resetButtonText={
              <Lang path="conversationalAssessment.actionPlanCard.liveChatMessage.resetButtonText" />
            }
            onClick={() => {
              if (window.LC_API) {
                window.LC_API.open_chat_window();
                setTimeout(() => {
                  document
                    .querySelector('meta[name="viewport"]')
                    .setAttribute(
                      'content',
                      'viewport-fit=cover, width=device-width, initial-scale=1, shrink-to-fit=no'
                    );
                }, 500);
              }
            }}
            imgId="livechat-img"
            descId="livechat-desc"
          />
        </>
      );
    case messageTypes.emergencyMessage:
      // TODO: Remove check for United states when emergency number is setup for other countries
      return (
        <>
          <Avatar sender={sender} showAvatar={showAvatar} />
          <ActionPlanCard
            showResetLink={isActive}
            title={
              <Lang path="conversationalAssessment.actionPlanCard.title" />
            }
            desc={
              <span>
                <Lang
                  path="conversationalAssessment.actionPlanCard.emergencyMessage.description.header"
                  values={{
                    eapPhone
                  }}
                  components={{
                    link: (
                      <RenderLink
                        link={`tel:${eapPhone}`}
                        linkText={eapPhone}
                      />
                    )
                  }}
                />
                {country === 'United States' && (
                  <Lang path="conversationalAssessment.actionPlanCard.emergencyMessage.description.body2" />
                )}
              </span>
            }
            buttonText={
              <Lang path="conversationalAssessment.actionPlanCard.emergencyMessage.buttonText" />
            }
            resetButtonText={
              <Lang path="conversationalAssessment.actionPlanCard.emergencyMessage.resetButtonText" />
            }
            onClick={() => {
              window.location.href = `tel:${eapPhone}`;
            }}
          />
        </>
      );
    case messageTypes.processError:
      return (
        isActive && (
          <>
            <Avatar sender={sender} showAvatar={showAvatar} />
            <Bubble>
              <div>
                <Lang
                  path="conversationalAssessment.processErrorMessageText"
                  components={{
                    retryButton: (
                      <UnderlineButton
                        onClick={() => {
                          conversationalAssessmentContext.setIsTyping(true);
                          conversationalAssessmentContext._popLastChatMessage();
                          conversationalAssessmentContext._retryForProcessError();
                        }}
                        className={errorButtonStyle}
                        buttonText={
                          <Lang path="conversationalAssessment.hereText" />
                        }
                      />
                    )
                  }}
                />
              </div>
            </Bubble>
          </>
        )
      );
    case messageTypes.fetchError:
      return (
        isActive && (
          <>
            <Avatar sender={sender} showAvatar={showAvatar} />
            <Bubble>
              <div>
                <Lang
                  path="conversationalAssessment.fetchErrorMessageTextPart"
                  components={{
                    retryButton: (
                      <UnderlineButton
                        onClick={() => {
                          conversationalAssessmentContext.setIsTyping(true);
                          conversationalAssessmentContext._popLastChatMessage();
                          conversationalAssessmentContext._retryForFetchError();
                        }}
                        className={errorButtonStyle}
                        buttonText={
                          <Lang path="conversationalAssessment.hereText" />
                        }
                      />
                    )
                  }}
                />
              </div>
            </Bubble>
          </>
        )
      );
    default:
      return null;
  }
};

Message.propTypes = {
  type: PropTypes.oneOf(Object.values(messageTypes)).isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  sender: PropTypes.string.isRequired,
  showAvatar: PropTypes.bool,
  isActive: PropTypes.bool
};

Message.defaultProps = {
  showAvatar: false,
  isActive: false
};

export default Message;
