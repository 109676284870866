/**
 * Reducer for managing the state of reports that are fetched by either id or newly processed
 * so we don't have to fetch them every time
 */

import { GET_REPORT_BY_ID_DONE, PROCESS_REPORT_DONE } from '../actions/types';

const INITIAL_STATE = {
  reports: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROCESS_REPORT_DONE:
      return {
        ...state,
        reports: [...state.reports, action.data],
        isFetching: false
      };
    case GET_REPORT_BY_ID_DONE:
      return {
        ...state,
        reports: [...state.reports, action.data],
        isFetching: false
      };
    default:
      return state;
  }
};
