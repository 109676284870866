import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import styles from './LoadingSpinner.module.scss';

const { spinIcon, centeredStyle } = styles;

const LoadingSpinner = ({ testId, centered }) => (
  <div className={classNames({ [centeredStyle]: centered })}>
    <div data-testid={testId} className={spinIcon} />
  </div>
);

LoadingSpinner.propTypes = {
  testId: propTypes.string
};

LoadingSpinner.defaultProps = {
  testId: null
};

export default LoadingSpinner;
