import gql from 'graphql-tag';

const SEND_CHAT_MESSAGE = gql`
  mutation sendChatMessage($text: String!) {
    sendChatMessage(input: { text: $text }) {
      id
      to
      from
      message {
        text
        coachAbout
        coachDesignation
        header
        body
      }
      content {
        id
        type
        duration
        header
        subHeader
        url
        urlHlsv3
        urlCommon
        thumbnailUrl
        tags
      }
      readFlag
      type
      createdAt
    }
  }
`;

const SEND_CHAT_MESSAGE_COACH = gql`
  mutation sendChatMessage(
    $text: String!
    $from: String
    $to: String
    $media: [String!]
    $suggestions: SuggestionsInput
    $contentVersionMap: ContentVersionMap
  ) {
    sendChatMessage(
      input: {
        text: $text
        from: $from
        to: $to
        media: $media
        suggestions: $suggestions
        contentVersionMap: $contentVersionMap
      }
    ) {
      id
      to
      from
      message {
        text
        coachAbout
        coachDesignation
        header
        body
      }
      content {
        id
        type
        duration
        header
        subHeader
        url
        urlHlsv3
        urlCommon
        thumbnailUrl
        tags
      }
      readFlag
      type
      createdAt
    }
  }
`;

const UPDATE_CHAT_MESSAGE_READ = gql`
  mutation updateChatMessageRead($id: ID!) {
    updateChatMessageRead(input: { id: $id }) {
      id
      readFlag
      from
      to
    }
  }
`;

const SEND_SUICIDAL_ALERT = gql`
  mutation sendSuicidalAlert($clientAuth0Id: String!, $source: String!) {
    sendSuicidalAlert(
      input: { clientAuth0Id: $clientAuth0Id, source: $source }
    ) {
      message
    }
  }
`;

export default {
  SEND_CHAT_MESSAGE,
  UPDATE_CHAT_MESSAGE_READ,
  SEND_CHAT_MESSAGE_COACH,
  SEND_SUICIDAL_ALERT
};
