/**
 * Reducer that manages the state of RequestCoach
 */
// Request Coach Action types
import {
  REQUEST_VIDEO_THERAPY,
  REQUEST_VIDEO_THERAPY_DONE,
  REQUEST_VIDEO_THERAPY_ERROR,
  GET_VIDEO_THERAPY_STATUS_DONE,
  GET_VIDEO_THERAPY_STATUS,
  GET_VIDEO_THERAPY_STATUS_ERROR,
  CLEAR_VIDEO_THERAPY_STATUS_ERROR
} from '../../actions/types';

const INITIAL_STATE = {
  request: {
    isFetching: false,
    hasError: false
  },
  status: {
    isFetching: false
  },
  videoTherapyRequestDone: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_VIDEO_THERAPY:
      return {
        ...state,
        request: {
          isFetching: true,
          hasError: false
        }
      };
    case REQUEST_VIDEO_THERAPY_DONE:
      return {
        ...state,
        videoTherapyRequestDone: true,
        request: {
          isFetching: false,
          hasError: false
        }
      };
    case REQUEST_VIDEO_THERAPY_ERROR:
      return {
        ...state,
        request: {
          isFetching: false,
          hasError: true
        }
      };

    case GET_VIDEO_THERAPY_STATUS:
      return {
        ...state,
        status: {
          isFetching: true
        }
      };
    case GET_VIDEO_THERAPY_STATUS_DONE: {
      return {
        ...state,
        status: {
          isFetching: false
        },
        videoTherapyRequestDone: action.status
      };
    }
    case GET_VIDEO_THERAPY_STATUS_ERROR: {
      return {
        ...state,
        status: {
          isFetching: false
        }
      };
    }
    case CLEAR_VIDEO_THERAPY_STATUS_ERROR: {
      return {
        ...state,
        request: {
          hasError: false
        }
      };
    }
    default:
      return state;
  }
};
