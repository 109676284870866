/**
 *
 * SelfGuided
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// components
// styles
// assets
import goalIconList, { goalIcon } from 'assets/data/goalIconList';
// actions
import selectGoal from 'store/actions/GoalAction';
// utils
import analytics from 'utils/analytics/analytics';
import featureService from 'utils/feature-service';
import history from 'utils/history';
import categories from 'utils/analytics/categories';

// assets
import bigCardImg from 'assets/img/dashboard/actionplan/self.jpg';
import style from './SelfGuided.module.scss';
import Lang from '../../../../components/Lang';
import ActionPlanCardBig from '../ActionPlanCardBig';
import ActionPlanCardSmall from '../ActionPlanCardSmall';
import SmartAppLink from '../../../../components/Popups/SmartAppLink';

const {
  contentContainer,
  detailsContainer,
  goalNameTextStyle,
  descriptionTextStyle
} = style;
class SelfGuided extends Component {
  _showDownloadModal = () => {
    analytics.track(categories.DASHBOARD, 'clicked start session', {
      goalId: this.props.categoryId,
      severity: this.props.severity,
      source: categories.DASHBOARD,
      trigger: 'start_session'
    });
    this.props.actions.selectGoal({
      selectedGoal: this.props.categoryName,
      selectedGoalOption: this.props.categoryId,
      reportId: this.props.reportId
    });

    this.props.showModal(SmartAppLink, {
      source: 'smart-app-link',
      props: {
        goalId: this.props.categoryId,
        severity: this.props.severity,
        subcategory: 'start session'
      }
    });
  };

  _handleStartSession = () => {
    // TODO: This check is not required because
    // both apps have same functionality now.
    // Ideally, shouldn't show this card if feature isn't present
    if (featureService._hasSelfUseFeature()) {
      history.push('/self-use');
    } else {
      this._showDownloadModal();
    }
  };

  render() {
    const {
      type,
      index,
      categoryName,
      categoryId,
      bigCardText,
      selfSessionCount
    } = this.props;
    const componentProps = {
      header: (
        <Lang path="selfGuidedCard.headerText" values={{ index: index + 1 }} />
      ),
      categoryCount: selfSessionCount,
      categoryUnit: <Lang path="selfGuidedCard.unitText" />,
      showButton: true,
      onClick: this._handleStartSession,
      buttonText: <Lang path="selfGuidedCard.buttonText" />,
      buttonId: 'start-here-button',
      cardId: 'self-guided'
    };

    return type === 'large' ? (
      <ActionPlanCardBig
        content={bigCardText}
        imgSrc={bigCardImg}
        {...componentProps}
      />
    ) : (
      <ActionPlanCardSmall {...componentProps}>
        <div className={contentContainer}>
          <div className={detailsContainer}>
            <img
              src={`${goalIconList[categoryId]}` || goalIcon}
              alt={categoryName}
            />
            <div
              className={goalNameTextStyle}
              data-testid="self-guided-goal-name-text"
            >
              {categoryName}
            </div>
          </div>
          <p
            data-testid="self-guided-goal-description-text"
            className={descriptionTextStyle}
          >
            <Lang
              path="selfGuidedCard.descriptionText"
              components={{ categoryName: categoryName.toLowerCase() }}
            />
          </p>
        </div>
      </ActionPlanCardSmall>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    dashBoard: { reportId, severity, sessionCount }
  } = state;
  return { reportId, severity, selfSessionCount: sessionCount.self[severity] };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    selectGoal: bindActionCreators(selectGoal, dispatch)
  }
});

SelfGuided.propTypes = {
  type: PropTypes.string,
  index: PropTypes.number,
  categoryName: PropTypes.string,
  reportId: PropTypes.string,
  severity: PropTypes.string,
  selfSessionCount: PropTypes.string,
  showModal: PropTypes.func
};
SelfGuided.defaultProps = {
  index: null,
  type: '',
  categoryName: '',
  reportId: '',
  severity: '',
  selfSessionCount: '',
  showModal: () => null
};

export default connect(mapStateToProps, mapDispatchToProps)(SelfGuided);
