/**
 * Error component used to show an error message along with a button
 * to retry. Currently used for failed network requests.
 */

// Core
import React from 'react';
import propTypes from 'prop-types';
// Components
import config from 'config';
import { ActionButton } from '../ui/Buttons';
import Lang from '../Lang';
// Styles
import styles from './ErrorRetry.module.scss';

const { supportEmail } = config;

const { container } = styles;

const ErrorRetry = ({
  title,
  isFetching,
  onClick,
  textStyle = '',
  titleId,
  buttonId
}) => (
  <div className={container}>
    <h3 className={textStyle} data-testid={titleId}>
      {title} <Lang path="errorText" values={{ supportEmail }} />
    </h3>
    <ActionButton onClick={onClick} isLoading={isFetching} testId={buttonId}>
      <Lang path="retryButtonText" />
    </ActionButton>
  </div>
);

ErrorRetry.propTypes = {
  title: propTypes.oneOfType([propTypes.string, propTypes.object]).isRequired,
  isFetching: propTypes.bool.isRequired,
  onClick: propTypes.func.isRequired
};

export default ErrorRetry;
