import React from 'react';
import ContentLoader from 'react-content-loader';

export const LoaderLarge = () => (
  <ContentLoader viewBox="0 0 900 120" height={120} width={900}>
    <rect x="0" y="0" rx="0" ry="0" width="150" height="20" />
    <rect x="0" y="30" rx="0" ry="0" width="200" height="80" />
    <rect x="213" y="30" rx="0" ry="0" width="200" height="80" />
    <rect x="425" y="30" rx="0" ry="0" width="200" height="80" />
    <rect x="637" y="30" rx="0" ry="0" width="200" height="80" />
    <rect x="849" y="30" rx="0" ry="0" width="48" height="80" />
  </ContentLoader>
);

export const LoaderSmall = () => (
  <ContentLoader viewBox="0 0 900 370" height={370} width={900}>
    <rect x="0" y="0" rx="0" ry="0" width="300" height="35" />
    <rect x="0" y="60" rx="0" ry="0" width="210" height="330" />
    <rect x="260" y="60" rx="0" ry="0" width="210" height="330" />
    <rect x="520" y="60" rx="0" ry="0" width="210" height="330" />
    <rect x="780" y="60" rx="0" ry="0" width="120" height="330" />
  </ContentLoader>
);
