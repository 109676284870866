import React from 'react';
import { connect } from 'react-redux';
import { parse } from 'tiny-querystring';
import { bindActionCreators } from 'redux';
import SwipeableViews from 'react-swipeable-views';
import SliderScreen from './components/SliderScreen';
import getSliderAssets from '../../assets/data/sliderAssets';
import styles from './OnBoard.module.scss';
// hocs
import withAuth from '../../hoc/withAuth';
// actions
import { setOnboardFlag } from '../../store/actions/ProfileAction';
// img
import redDots from '../../assets/img/desktop/red_pager.png';
import blueDots from '../../assets/img/desktop/blue_pager.png';
import blueDotsCoach from '../../assets/img/desktop/blue_pager1.png';
import yellowDots from '../../assets/img/desktop/yellow_pager.png';
import whiteDots from '../../assets/img/desktop/white_pager.png';
import prevIcon from '../../assets/img/desktop/arrow-prev.svg';
import nextIcon from '../../assets/img/desktop/arrow-next.svg';
import i18n from '../../assets/lang';

class Introduction extends React.Component {
  constructor(props) {
    super(props);
    let redirectTo;
    if (window.location.search) {
      ({ redirectTo } = parse(window.location.search.slice(1)));
    }
    this.state = {
      index: 0,
      assets: [],
      dot: ''
    };
    if (redirectTo) {
      this.state.redirectTo = redirectTo;
    }
  }

  componentDidMount() {
    const sliderAssets = getSliderAssets();
    const assets = sliderAssets.filter((item) => item.show() === true);
    this._updateAssets(assets);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.profile.onboard_complete) {
      this.props.history.replace(this.state.redirectTo || '/intro');
    }
  }

  _renderSlides = () =>
    this.state.assets.map((item) => (
      <SliderScreen
        key={item.slide}
        title={item.title}
        text={item.text}
        list={item.list ? item.list : []}
        slide={item.slide}
      />
    ));

  _handleChangeIndex = (val) => {
    if (val === 1 && this.state.index >= this.state.assets.length - 2) {
      this.props.actions.setOnboardFlag();
      return;
    }
    if (val === -1 && this.state.index === 0) {
      return;
    }
    const newIndex = this.state.index + val;
    this.setState((prevState) => ({
      index: newIndex,
      dot: prevState.assets[newIndex].dots
    }));
  };

  _handleSwiperIndex = (val) => {
    if (val >= this.state.assets.length - 1) {
      this.props.actions.setOnboardFlag();
    }
    this.setState((prevState) => ({
      dot: prevState.assets[val].dots
    }));
  };

  _updateAssets(assets) {
    this.setState({ assets, dot: assets[0].dots });
  }

  render() {
    const { arrows, prevButton, nextButton, bullets, swipableViewsContainer } =
      styles;
    return (
      <div>
        <SwipeableViews
          className={swipableViewsContainer}
          enableMouseEvents
          index={this.state.index}
          onChangeIndex={this._handleSwiperIndex}
        >
          {this._renderSlides()}
        </SwipeableViews>
        <div className={arrows}>
          <button
            className={prevButton}
            onClick={() => this._handleChangeIndex(-1)}
            data-testid="onboard-btn-previous"
            type="button"
          >
            <img src={prevIcon} alt={i18n.t('alternateText.icon.previous')} />
          </button>
          <button
            data-testid="onboard-btn-next"
            type="button"
            className={nextButton}
            onClick={() => this._handleChangeIndex(1)}
          >
            <img src={nextIcon} alt={i18n.t('alternateText.icon.next')} />
          </button>
        </div>
        <div className={bullets}>
          {this.state.dot === 'yellow' && <img src={yellowDots} alt="" />}

          {this.state.dot === 'red' && <img src={redDots} alt="" />}
          {this.state.dot === 'blue' && <img src={blueDots} alt="" />}

          {this.state.dot === 'white' && <img src={whiteDots} alt="" />}

          {this.state.dot === 'blueDotsCoach' && (
            <img src={blueDotsCoach} alt="" />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { profile } = state.profile;

  return { profile };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    setOnboardFlag: bindActionCreators(setOnboardFlag, dispatch)
  }
});

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(Introduction)
);
