/**
 *
 * The Dashboard page container, contains all the things related to the
 * EAP Dashboard
 */

// core
import React, { Component } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// components
import LiveChat from 'react-livechat';
// hoc
import withAuth from 'hoc/withAuth';
// utils
import api from 'api/assessment';
import featureService from 'utils/feature-service';
import { isLoggedIn } from 'utils/auth';
import config from 'config';
import logger from 'utils/logger';
import analytics from 'utils/analytics';
// actions
import {
  setDashBoardData,
  setDashBoardSessionCount,
  setDashBoardCallCompleted
} from 'store/actions/dashboard/DashBoardAction';
import { minimizeChatWindow } from 'store/actions/ChatWindowAction';
import { getVideoTherapyStatus } from 'store/actions/dashboard/VideoTherapyRequestAction';
import { getCoachStatus } from 'store/actions/dashboard/CoachRequestAction';
import { getInPersonTherapyStatus } from 'store/actions/dashboard/InPersonTherapyRequestAction';
import getCompanyDetails from 'store/actions/CompanyDetailsAction';
// assets
import coachRequestStatusTypes from 'assets/data/dashboard/coachRequestStatusTypes';
import Lang from '../../components/Lang';
import toast from '../../components/Toast';
import FullPageLoader from '../../components/FullPageLoader';
import HomePage from './components/HomePage';
// styles
import style from './DashBoard.module.scss';

const severityLevels = ['low', 'moderate', 'high', 'bonus'];

class DashBoard extends Component {
  componentDidMount() {
    if (isLoggedIn() && !featureService._hasIntelliPlatformFeature()) {
      this.props.history.replace('/intro');
      return;
    }
    const { ibhpSessionCount, employerId } = this.props;
    this.props.actions
      .getCompanyDetails(employerId)
      .then(() => {
        const { ibhpSessionCount: sessionCount } = this.props;
        if (!_.isEmpty(sessionCount)) {
          this.props.actions.setDashBoardSessionCount(sessionCount);
        } else {
          logger.error(
            'Could not get the ibhpSessionCount from company details',
            'DashBoard.componentDidMount',
            {
              companyName: employerId
            }
          );
        }
      })
      .catch((error) => {
        if (!_.isEmpty(ibhpSessionCount)) {
          this.props.actions.setDashBoardSessionCount(ibhpSessionCount);
        } else {
          logger.error(
            'Could not get the ibhpSessionCount from props',
            'DashBoard.componentDidMount',
            {
              companyName: employerId
            }
          );
        }
        logger.error(
          'Error occurred while fetching the company details',
          'DashBoard.componentDidMount',
          {
            companyName: employerId,
            error
          }
        );
      });
    const hasInPersonTherapy = !!_.get(
      this.props.partner,
      'inperson_therapy_sheet_id',
      false
    );
    const hasVideoTherapy = !!_.get(
      this.props.partner,
      'video_therapy_sheet_id',
      false
    );

    if (hasVideoTherapy) {
      this.props.actions.getVideoTherapyStatus();
    }
    if (hasInPersonTherapy) {
      this.props.actions.getInPersonTherapyStatus();
    }
    if (
      (featureService._hasCoachAccessFeature() ||
        featureService._hasCoachAccessOnWebFeature()) &&
      this.props.coachRequestStatus !== coachRequestStatusTypes.inProgress
    ) {
      this.props.actions.getCoachStatus();
    }
    const { goalId, severity } = this.props.match.params;
    if (goalId && severity) {
      if (severityLevels.indexOf(severity) >= 0) {
        this.props.actions.setDashBoardData(goalId, severity);
        this.props.actions.minimizeChatWindow();
        api
          .getLatestSelectedGoal()
          .then((resp) => {
            if (resp.status === 200 && Object.keys(resp.data).length > 0) {
              const categoryName = Object.keys(resp.data)[0];
              const { level, reportId } = resp.data[categoryName];
              if (
                goalId.toLowerCase() === categoryName.toLowerCase() &&
                level.toLowerCase() === severity.toLowerCase()
              ) {
                this.props.actions.setDashBoardData(goalId, severity, reportId);
              }
            }
          })
          .catch((err) =>
            logger.error(
              'Error occurred while fetching last chat assessment',
              'Dashboard.componentDidMount.if',
              { error: JSON.parse(JSON.stringify(err)) }
            )
          );
      }
    } else if (!goalId) {
      api
        .getLatestSelectedGoal()
        .then((resp) => {
          if (resp.status === 200 && Object.keys(resp.data).length > 0) {
            const categoryName = Object.keys(resp.data)[0];
            const { level, reportId } = resp.data[categoryName];
            this.props.actions.setDashBoardData(categoryName, level, reportId);
            this.props.actions.minimizeChatWindow();
            this.props.history.push(
              `/my-plan/${categoryName.toLowerCase()}/${level}`
            );
          } else {
            logger.error(
              'Unable to fetch reports successfuly. Redirecting to conversational assessment',
              'Dashboard.componentDidMount',
              { status: resp.status }
            );
            this.props.history.push('/assessment/conversational');
            toast.info(<Lang path="myPlan.noGoalRedirectText" />, null, {
              autoClose: 10000
            });
          }
        })
        .catch((err) =>
          logger.error(
            'Error occurred while fetching last chat assessment',
            'Dashboard.componentDidMount.elseif',
            { error: JSON.parse(JSON.stringify(err)) }
          )
        );
    }
  }

  minimizeLiveChatWindow = () => {
    analytics.track('dashboard', 'minimized live chat');
    this.props.actions.minimizeChatWindow();
  };

  render() {
    const { goalId, severity, isCallCompleted } = this.props;
    return !isCallCompleted ? (
      <FullPageLoader loaderText={<Lang path="myPlan.loadingText" />} />
    ) : (
      <div className="container">
        <LiveChat
          license={config.liveChatLicenseId}
          onChatWindowHidden={this.minimizeLiveChatWindow}
        />
        <div
          role="switch"
          aria-checked="false"
          tabIndex={0}
          onKeyUp={(e) => e.keyCode === 13 && this.closeSidebar()}
          className={style.main}
          onClick={this.closeSidebar}
        >
          {isCallCompleted &&
            (goalId && severity ? (
              <HomePage />
            ) : (
              <div className={style.fullPageContainer} />
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    requestCoach: { coachRequestStatus },
    dashBoard: { goalId, severity, isCallCompleted },
    profile: {
      profile: { employer_id: employerId, company_name: companyName }
    },
    companyDetails: {
      companyDetails: { partner, ibhpSessionCount }
    }
  } = state;
  return {
    goalId,
    severity,
    isCallCompleted,
    employerId: employerId || companyName,
    partner,
    ibhpSessionCount,
    coachRequestStatus
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getCompanyDetails: bindActionCreators(getCompanyDetails, dispatch),
    setDashBoardData: bindActionCreators(setDashBoardData, dispatch),
    minimizeChatWindow: bindActionCreators(minimizeChatWindow, dispatch),
    getVideoTherapyStatus: bindActionCreators(getVideoTherapyStatus, dispatch),
    getCoachStatus: bindActionCreators(getCoachStatus, dispatch),
    getInPersonTherapyStatus: bindActionCreators(
      getInPersonTherapyStatus,
      dispatch
    ),
    setDashBoardSessionCount: bindActionCreators(
      setDashBoardSessionCount,
      dispatch
    ),
    setDashBoardCallCompleted: bindActionCreators(
      setDashBoardCallCompleted,
      dispatch
    )
  }
});

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(DashBoard)
);
