import React, { useCallback } from 'react';
import sliderPointerImg from 'assets/img/sliderPointer.svg';
import propTypes from 'prop-types';
import { Slider } from '@material-ui/core';
import materialStyles from './RatingSlider.styles';
import styles from './RatingSlider.module.scss';

const { pointerStyle } = styles;

const RatingSlider = ({ value, marks, onChange, min, max }) => {
  const listItemMaterialStyle = materialStyles();
  const sliderPointer = useCallback(
    (props) => (
      <span {...props} data-testid={`slider-pointer-${props['aria-valuenow']}`}>
        <img
          data-testid="slider-pointer-image"
          alt=""
          className={pointerStyle}
          src={sliderPointerImg}
        />
      </span>
    ),
    []
  );
  return (
    <div data-testid="rating-slider-container">
      <Slider
        classes={listItemMaterialStyle}
        key={value}
        defaultValue={value}
        min={min}
        max={max}
        valueLabelDisplay="off"
        aria-labelledby="non-linear-slider"
        marks={marks}
        ThumbComponent={sliderPointer}
        onChangeCommitted={onChange}
      />
    </div>
  );
};

RatingSlider.propTypes = {
  value: propTypes.number,
  min: propTypes.number,
  max: propTypes.number,
  marks: propTypes.arrayOf(propTypes.shape({})),
  onChange: propTypes.func
};

RatingSlider.defaultProps = {
  value: 0,
  min: 0,
  max: 1,
  marks: [],
  onChange: () => null
};

export default RatingSlider;
