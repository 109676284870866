import React from 'react';
import { useDispatch } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import mediaTypes from 'assets/data/chat/mediaTypes';
import { openVideoModal } from 'store/actions/media/VideoPlayerModalAction';
import config from 'config';
import styles from './MediaCard.module.scss';

const {
  container,
  thumbnailStyle,
  upperContainer,
  footerStyle,
  footerWhiteBackground,
  tagsStyle,
  addMedia,
  headerStyle,
  lowerContainer,
  subHeaderContainer,
  subHeaderStyle,
  playButtonStyle,
  playIcon,
  mediaDuration,
  selectedMediaIcon,
  highlight
} = styles;

const { cdnBaseUrl } = config;

const MediaCard = ({
  header,
  subHeader,
  duration,
  tags,
  isSelected,
  onSelectMedia,
  thumbnail,
  isSelectable,
  id,
  urlCommon,
  type,
  isCoachMessage,
  analyticsMessage
}) => {
  const dispatch = useDispatch();

  const footerClasses = [footerStyle];
  if (isCoachMessage) {
    footerClasses.push(footerWhiteBackground);
  }

  const _playMedia = () => {
    if (type === mediaTypes.video) {
      let url = '';
      if (!urlCommon.startsWith('http')) {
        url = cdnBaseUrl + urlCommon;
      } else {
        url = urlCommon;
      }

      dispatch(
        openVideoModal({ url, videoId: id, analyticsMessage, autoPlay: true })
      );
    }
  };
  return (
    <div
      data-testid="media-card-container"
      className={isSelected ? [container, highlight].join(' ') : container}
    >
      <div
        data-testid={id}
        className={thumbnailStyle}
        style={{
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${thumbnail})`
        }}
      >
        <div className={upperContainer}>
          <span className={headerStyle}>{header}</span>
          <div className={lowerContainer}>
            <div className={subHeaderContainer}>
              <span title={subHeader} className={subHeaderStyle}>
                {subHeader}
              </span>
              <span className={mediaDuration}>{duration}</span>
            </div>
            <button
              data-testid="play-button"
              type="button"
              className={playButtonStyle}
              onClick={_playMedia}
            >
              <Icon className={playIcon}>play_circle_filled</Icon>
            </button>
          </div>
        </div>
      </div>
      <div className={footerClasses.join(' ')}>
        <span className={tagsStyle} key={id}>
          {tags.length > 0 && `#${tags.join(' #')}`}
        </span>
        {isSelectable && (
          <React.Fragment>
            {isSelected ? (
              <Icon
                data-testid="check-media-button"
                className={selectedMediaIcon}
              >
                check
              </Icon>
            ) : (
              <Icon
                data-testid="select-media-button"
                onClick={onSelectMedia}
                className={addMedia}
              >
                add
              </Icon>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default MediaCard;
