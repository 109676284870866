/**
 * The main assessment container that contains our assessment.
 * Will need a lot of refactor in future to support multiple assessments
 */
// Core
import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNamesInstance from 'classnames/bind';
// Components
import { Col, Modal, Row, Spin } from 'antd';
import featureService from 'utils/feature-service';
import eventCategories from 'utils/analytics/categories';
import ProgressBar from '../../components/mui/ProgressBar';
import ContentContainer from '../../components/layout/ContentContainer';
import Question from './components/Question/Question';
import BackButton from '../../components/wrapped/BackButton';
import { ActionButton } from '../../components/ui/Buttons';
import Interstitial from './components/Interstitial/Interstitial';
import StartingInterstitial from './components/StartingInterstitial/StartingInterstitial';
import ErrorRetry from '../../components/ErrorRetry';
import AssessmentHeader from './components/AssessmentHeader';
import AvatarSelection from './components/Avatar';
import AssessmentFooter from './components/AssessmentFooter';
import RenderHtml from '../../components/RenderHtml';
import Alert from '../../components/Popups/Alert';
import Lang from '../../components/Lang';
// hocs
import withAvatar from '../../hoc/withAvatar';
import withAuth from '../../hoc/withAuth';
import Aux from '../../components/layout/Aux';
// actions
import setActiveQuestion from '../../store/actions/QuestionsAction';
import {
  addResponse,
  removeResponse,
  removeLastResponse
} from '../../store/actions/ResponseAction';
import processReport from '../../store/actions/ReportProcessAction';
import fetchQuestionsAndInterstitials, {
  fetchProgressId,
  hideStartingInterstitial,
  saveAssessmentsProgress
} from '../../store/actions/AssessmentAction';
import { showModal, closeModal } from '../../store/actions/ModalAction';
import { getCoachStatus } from '../../store/actions/dashboard/CoachRequestAction';
// styles
import styles from './Assessment.module.scss';
// utils
import api from '../../api/assessment';
import analytics from '../../utils/analytics';
import logger from '../../utils/logger';
import helpers from '../../utils/helpers';
// assets
import ConfidentialityIcon from '../../assets/brand/img/assessment/confidentialityIcon.png';
// config
import i18n from '../../assets/lang';

const classNames = classNamesInstance.bind(styles);

const { confirm, warning } = Modal;

class Assessment extends Component {
  // This state is used to show the interstitial
  constructor(props) {
    super(props);
    this.state = {
      showInterstitial: false,
      width: 0,
      avatarSelection: this.props.showAvatar
    };
    this._saveProgress = _.debounce(
      this.props.actions.saveAssessmentsProgress,
      500
    );
    this._updateWindowDimensions = this._updateWindowDimensions.bind(this);
  }

  componentWillMount() {
    this._updateWindowDimensions();
    window.addEventListener('resize', this._updateWindowDimensions);
  }

  componentDidMount() {
    if (!this.props.progressId) {
      this.props.actions.fetchProgressId(this.props.assessmentId);
    }
    if (this.props.activeQuestion === 1) {
      analytics.info('assessment', 'started', {
        assessmentId: this.props.assessmentId
      });
    }
    if (
      featureService._hasCoachAccessFeature() ||
      featureService._hasCoachAccessOnWebFeature()
    ) {
      this.props.actions.getCoachStatus();
    }
  }

  componentWillReceiveProps(nextProps) {
    // Checking if we want to skip the next question or not.
    if (this.props.activeQuestion === nextProps.activeQuestion) {
      return;
    }

    let nextQuestionId = nextProps.activeQuestion;
    const props = nextProps;
    // Checking if we want to skip the next question or not.
    const { questionList } = props;

    let showQuestion = false;
    let question = null;

    if (!questionList) {
      return;
    }

    while (!showQuestion) {
      question = questionList ? questionList[nextQuestionId] : null;
      // Check if the question exists and it has the showIf property,
      // if it does then we take sum of all the questions mentioned for the condition.
      if (nextQuestionId > this._getAssessmentLength()) {
        if (props.progressId) {
          props.actions.saveAssessmentsProgress(
            props.responses,
            props.progressId,
            true
          );
        }
        props.actions.processReport(props.assessmentId);
        showQuestion = false;
        break;
      }

      if (this._isQuestionValid(nextQuestionId, props)) {
        showQuestion = true;
      } else {
        if (
          this.props.interstitials &&
          this.props.interstitials[nextQuestionId + 1]
        ) {
          this.setState({ showInterstitial: true });
          props.actions.setActiveQuestion(nextQuestionId);
          break;
        }

        nextQuestionId += 1;
      }
    }
    if (showQuestion && question) {
      props.actions.setActiveQuestion(nextQuestionId);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._updateWindowDimensions);
  }

  _handleModalClose = () => {
    const source = 'assessment-alert';
    analytics.track(eventCategories.MODAL, 'clicked close', {
      source,
      trigger: 'modal_action'
    });
    logger.info('Clicked close', 'Assessment._handleModalClose', { source });
    this.props.actions.closeModal();
  };

  _isQuestionValid = (questionId, props) => {
    let isValid = false;
    const { questionList } = props;
    const question = questionList ? questionList[questionId] : null;

    if (question) {
      if (question.showIf) {
        let sum = 0;
        question.showIf.questionIds.forEach((i) => {
          const score = _.get(props, `responses[${i}].score`);
          sum += score || 0;
        });

        const operation = question.showIf.condition.total.op;
        // Check if the sum is greater or less than total value from condition
        if (operation === 'gt') {
          if (sum > question.showIf.condition.total.value) {
            isValid = true;
          }
        } else if (operation === 'lt') {
          if (sum < question.showIf.condition.total.value) {
            isValid = true;
          }
        } else if (operation === 'eq') {
          if (sum === question.showIf.condition.total.value) {
            isValid = true;
          }
        } else {
          logger.error(
            'Unknown operation received from the server',
            'Assessment.componentWillReceiveProps',
            { operation }
          );
        }
      } else {
        isValid = true;
      }
    }
    return isValid;
  };

  // Shows the warning dialog with the message passed in
  _showWarning = (message) => {
    const self = this;
    warning({
      title: <Lang path="assessmentWarningHeader" />,
      content: message,
      onOk() {
        analytics.track('assessment', 'clicked ok', {
          subcategory: 'alert',
          assessmentId: self.props.assessmentId
        });
      }
    });
  };

  // Confirms with user before going back from the assessment
  // TODO: discuss, Might not be needed??
  _handleBackConfirm = () => {
    const self = this;
    confirm({
      title: <Lang path="workLifeRequest.confirmModalTitle" />,
      onOk() {
        analytics.track('assessment', 'clicked ok', {
          subcategory: 'alert quit',
          assessmentId: self.props.assessmentId
        });
        // TODO:  We don't have access to previous history right now
        // Need to revisit this and probably change the routing package
        self.props.history.push('/intro');
      },
      onCancel() {
        analytics.track('assessment', 'clicked cancel', {
          subcategory: 'alert quit',
          assessmentId: self.props.assessmentId
        });
      },
      okText: <Lang path="okText" />,
      cancelText: <Lang path="cancelText" />
    });
  };

  _getAssessmentLength = () => {
    let lastQuestionId;
    let lastinterstitialId;
    let max;

    if (this.props.questionList) {
      const questionIds = Object.keys(this.props.questionList);

      if (questionIds.length > 0) {
        lastQuestionId = parseInt(questionIds[questionIds.length - 1], 10);
      }
    }

    if (this.props.interstitials) {
      const interstitialIds = Object.keys(this.props.interstitials);

      if (interstitialIds.length > 0) {
        lastinterstitialId = parseInt(
          interstitialIds[interstitialIds.length - 1],
          10
        );
      }
    }

    if (lastinterstitialId) {
      max = Math.max(lastQuestionId, lastinterstitialId - 1);
    } else {
      max = lastQuestionId;
    }

    return max;
  };

  // Showing the next question
  _showNextQuestion = () => {
    // Check if the assessment is complete and process the result
    if (this.props.activeQuestion > this._getAssessmentLength()) {
      this.props.actions.processReport(this.props.assessmentId);
    } else {
      // Otherwise set active question to +1 to go to next question
      this.props.actions.removeResponse(this.props.activeQuestion + 1);
      this.props.actions.setActiveQuestion(this.props.activeQuestion + 1);
    }
  };

  // Handle the selection of a response
  _handleSelection = async ({ value, score }) => {
    const properties = {
      subcategory: 'question',
      questionId: this.props.activeQuestion,
      assessmentId: this.props.assessmentId
    };
    const isAssessmentCompleted =
      this.props.activeQuestion === Object.keys(this.props.questionList).length;

    if (
      this.props.questionList[this.props.activeQuestion].inputType ===
      'inputNumber'
    ) {
      properties.answer = score;
    } else {
      properties.optionId = score;
    }

    analytics.track('assessment', 'selected option', properties);
    // Add the response to the responses
    await this.props.actions.addResponse(
      this.props.activeQuestion,
      value,
      score,
      this.props.questionList[this.props.activeQuestion].type
    );
    if (this.props.progressId) {
      if (!isAssessmentCompleted) {
        this._saveProgress(
          this.props.responses,
          this.props.progressId,
          isAssessmentCompleted
        );
      } else {
        this.props.actions.saveAssessmentsProgress(
          this.props.responses,
          this.props.progressId,
          isAssessmentCompleted
        );
      }
    }
    // Check if the alert property exists on the active question object
    // if it does then check if a alert needs to be shown and display it accordingly
    const { alert: alertItem } =
      this.props.questionList[this.props.activeQuestion];
    if (alertItem) {
      alertItem.forEach((item) => {
        if (score >= item.scoreRange.min && score <= item.scoreRange.max) {
          if (item.content) {
            this.props.actions.showModal(Alert, {
              source: 'assessment-alert',
              maxWidth: 500,
              props: {
                content: item.content,
                onClose: this._handleModalClose
              },
              hideCloseButton: true
            });
            if (
              featureService._hasSuicidalIdeationEndorsementAlertFeature() &&
              item.type === 'suicidal'
            ) {
              api
                .suicidalAlertNotification()
                .catch((err) =>
                  logger.error(
                    'An error occurred while sending suicidal ideation endorsement alert',
                    'Assessment._handleSelection',
                    { err }
                  )
                );
            }
          } else {
            this._showWarning(<RenderHtml>{item.text}</RenderHtml>);
          }
        }
      });
    }

    // Check if the interstitial property exists on the next question.
    // If it does then show the interstitial before that and let the interstitial
    // component handle redirection to the next question otherwise show next question
    if (
      this.props.interstitials &&
      this.props.interstitials[this.props.activeQuestion + 1]
    ) {
      this.setState({ showInterstitial: true });
    } else {
      this._showNextQuestion();
    }
  };

  _handleInterstitialNext = () => {
    analytics.track('assessment', 'clicked next', {
      subcategory: 'interstitial',
      interstitialId: this.props.activeQuestion + 1,
      assessmentId: this.props.assessmentId
    });
    this._showNextQuestion();
    this.setState({ showInterstitial: false });
  };

  _getPreviousValidQuestionId = () => {
    const currentQuestionId = this.props.activeQuestion;
    let previousValidQuestionId = currentQuestionId - 1;

    while (
      !this._isQuestionValid(previousValidQuestionId, this.props) &&
      previousValidQuestionId > 0
    ) {
      previousValidQuestionId -= 1;
    }
    return previousValidQuestionId;
  };

  _handleBack = () => {
    analytics.track('assessment', 'clicked back', {
      assessmentId: this.props.assessmentId
    });
    if (this.state.showInterstitial) {
      this.setState({ showInterstitial: false }, () => {
        if (!this._isQuestionValid(this.props.activeQuestion, this.props)) {
          this._handleBack();
        }
      });
    } else {
      const previousValidQuestionId = this._getPreviousValidQuestionId();

      if (this.props.activeQuestion > 1 && previousValidQuestionId > 0) {
        // If the active question is greater than 1 then we simply set the active question
        // to current active question - 1 and remove the response for the last question
        // otherwise if it's 1 then we show the starting interstitial
        this.props.actions.removeResponse(previousValidQuestionId);
        this.props.actions.setActiveQuestion(previousValidQuestionId);
      } else if (!this.props.interstitials) {
        this._handleBackConfirm();
      } else {
        this.props.actions.hideStartingInterstitial(true);
      }
    }
  };

  _handleStartingInterstitialNext = () => {
    this.props.actions.hideStartingInterstitial(false);
  };

  _handleRetry = () => {
    analytics.track('assessment', 'clicked questions retry', {
      subcategory: 'questions',
      assessmentId: this.props.assessmentId
    });
    this.props.actions.fetchQuestionsAndInterstitials();
  };

  _handleProcessReport = () => {
    analytics.track('assessment', 'clicked process retry', {
      subcategory: 'questions',
      assessmentId: this.props.assessmentId
    });
    this.props.actions.processReport(this.props.assessmentId);
  };

  _avatarSelectionVisible = (val) => {
    this.setState({ avatarSelection: val });
  };

  _renderAvatarSelection = () => (
    <AvatarSelection
      onSubmit={() => this._avatarSelectionVisible(false)}
      onBack={this._handleBackConfirm}
    />
  );

  _renderInterstitialAndQuestion = () => {
    const { questionsContainer } = styles;
    const { questionList, activeQuestion } = this.props;
    const question = questionList[activeQuestion];
    return this.state.showInterstitial ? (
      <Interstitial
        avatar={this.props.activeAvatar}
        data={this.props.interstitials[this.props.activeQuestion + 1]}
        onNext={this._handleInterstitialNext}
      />
    ) : (
      <Col
        xs={{ span: 20, offset: 2 }}
        lg={{ span: 22, offset: 1 }}
        className={questionsContainer}
      >
        <Question
          onBack={this._handleBack}
          avatar={this.props.activeAvatar}
          data={question}
          onSelect={this._handleSelection}
          isFetching={this.props.fetchingQuestion}
        />
      </Col>
    );
  };

  _renderSpinner = () => (
    <div className={styles.spinnerContainer}>
      <Spin size="large" />
    </div>
  );

  _renderAssessment = () => {
    const {
      interstitialMargin,
      questionMargin,
      alignedButtonStyle,
      interstitialHeader,
      nextButton,
      progressBar,
      confidentialityBadgeWrapper
    } = styles;

    const { activeQuestion, hasError, fetchingQuestion } = this.props;
    let percent = Math.floor(
      (activeQuestion / this._getAssessmentLength()) * 100
    );
    // Don't set percent to 100 unless the last interstitial is shown
    if (percent === 100) {
      if (
        !this.state.showInterstitial &&
        !(this.props.fetchingReport || this.props.reportError)
      ) {
        percent = 99;
      }
    }

    const showStartingInterstital =
      this.props.interstitials && this.props.showStartingInterstitial;

    // If the starting interstitial is shown then set the percent to 0
    if (showStartingInterstital) {
      percent = 0;
    }

    if (this.props.fetchingReport) {
      percent = 100;
    }

    // for the pixel perfect progress bar on mobile and desktop
    let progressTextMargin = percent < 2 ? percent : percent - 2;
    if (percent > 91) {
      progressTextMargin = 91;
    }
    if (this.state.width < 992) {
      progressTextMargin = percent - 5;
      if (percent > 93) {
        progressTextMargin = 89;
      }
      if (percent < 6) {
        progressTextMargin = 1;
      }
    }
    return (
      <Row>
        {this.state.showInterstitial && (
          <AssessmentHeader>
            <p className={interstitialHeader}>
              {percent === 100 ? 'Finished' : `${percent}% Completed`}
            </p>
          </AssessmentHeader>
        )}
        <ContentContainer
          className={
            this.state.showInterstitial ? interstitialMargin : questionMargin
          }
          size="extraLarge"
        >
          {!(this.state.showInterstitial || showStartingInterstital) && (
            <Aux>
              <div className={confidentialityBadgeWrapper}>
                <img
                  src={ConfidentialityIcon}
                  alt={i18n.t('alternateText.icon.confidential')}
                />
              </div>
              <span
                data-testid="assessment-progress"
                style={{
                  marginLeft: `${progressTextMargin}%`,
                  transition: 'margin 0.4s linear'
                }}
              >
                {percent}%
              </span>
              <ProgressBar
                className={progressBar}
                variant="determinate"
                value={percent}
              />
            </Aux>
          )}
          {this.props.reportError ? (
            <ErrorRetry
              title={<Lang path="reportProcessingError" />}
              isFetching={this.props.fetchingReport}
              onClick={this._handleProcessReport}
            />
          ) : (
            <div>
              {hasError ? (
                <ErrorRetry
                  title={<Lang path="assessmentError" />}
                  isFetching={fetchingQuestion}
                  onClick={this._handleRetry}
                />
              ) : (
                <div>
                  {this.props.fetchingReport || this.props.fetchingQuestion ? (
                    this._renderSpinner()
                  ) : (
                    <div>
                      {showStartingInterstital ? (
                        <StartingInterstitial
                          avatar={this.props.activeAvatar}
                          onNext={this._handleStartingInterstitialNext}
                          onBack={() =>
                            this.props.showAvatar
                              ? this._avatarSelectionVisible(true)
                              : this._handleBackConfirm()
                          }
                        />
                      ) : (
                        this._renderInterstitialAndQuestion()
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </ContentContainer>
        {!(showStartingInterstital || this.props.fetchingReport) && (
          <AssessmentFooter
            className={classNames({
              assessmentFooterInterstitial:
                this.state.showInterstitial && this.state.width < 992,
              assessmentFooterInterstitialElevated:
                !helpers.platformInfo.isDesktop
            })}
          >
            <BackButton
              className={alignedButtonStyle}
              onClick={this._handleBack}
            />
            {this.state.showInterstitial && (
              <ActionButton
                testId="next-button"
                className={nextButton}
                onClick={this._handleInterstitialNext}
              >
                <Lang path="nextButtonText" />
              </ActionButton>
            )}
          </AssessmentFooter>
        )}
      </Row>
    );
  };

  _renderContainer = () =>
    this.state.avatarSelection
      ? this._renderAvatarSelection()
      : this._renderAssessment();

  _updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  render() {
    return this._renderContainer();
  }
}

const mapStateToProps = (state) => {
  const {
    questions: {
      questionList,
      activeQuestion,
      interstitials,
      hasError,
      isFetching: fetchingQuestion,
      showStartingInterstitial,
      showAvatar,
      assessmentId,
      progressId
    },
    responses,
    reportProcess: { isFetching: fetchingReport, hasError: reportError },
    companyDetails: {
      companyDetails: { suicidalPopup }
    }
  } = state;
  return {
    questionList,
    activeQuestion,
    interstitials,
    responses,
    hasError,
    fetchingQuestion,
    fetchingReport,
    reportError,
    showStartingInterstitial,
    showAvatar,
    assessmentId,
    progressId,
    suicidalPopup
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchQuestionsAndInterstitials: bindActionCreators(
      fetchQuestionsAndInterstitials,
      dispatch
    ),
    setActiveQuestion: bindActionCreators(setActiveQuestion, dispatch),
    addResponse: bindActionCreators(addResponse, dispatch),
    removeResponse: bindActionCreators(removeResponse, dispatch),
    removeLastResponse: bindActionCreators(removeLastResponse, dispatch),
    processReport: bindActionCreators(processReport, dispatch),
    hideStartingInterstitial: bindActionCreators(
      hideStartingInterstitial,
      dispatch
    ),
    showModal: bindActionCreators(showModal, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch),
    fetchProgressId: bindActionCreators(fetchProgressId, dispatch),
    saveAssessmentsProgress: bindActionCreators(
      saveAssessmentsProgress,
      dispatch
    ),
    getCoachStatus: bindActionCreators(getCoachStatus, dispatch)
  }
});

export default withAuth(
  withAvatar(connect(mapStateToProps, mapDispatchToProps)(Assessment))
);
