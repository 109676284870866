/**
 * Reducer to hold the list of message in coach chat
 */

import _ from 'lodash';
import {
  ADD_CHAT_MESSAGE,
  MESSAGE_TYPING,
  MESSAGE_TYPING_DONE,
  SEND_CHAT_MESSAGE
} from '../../actions/types';

const INITIAL_STATE = {
  isTyping: false,
  messages: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_CHAT_MESSAGE: {
      return {
        ...state,
        messages: _.concat(state.messages, action.message)
      };
    }
    case MESSAGE_TYPING: {
      return { ...state, isTyping: true };
    }
    case MESSAGE_TYPING_DONE: {
      return { ...state, isTyping: false };
    }
    case SEND_CHAT_MESSAGE: {
      return { ...state, messages: _.concat(state.messages, action.message) };
    }
    default:
      return state;
  }
};
