import React from 'react';
import helper from 'utils/helpers';
import history from 'utils/history';
import { dispatch } from 'store';
import { currentUserAction } from 'store/actions/coachchat/ActiveUserAction';
import { stringify } from 'tiny-querystring';
import Lang from '../../../../../components/Lang';
import styles from './UserListItem.module.scss';

const { createInitials } = helper;
const {
  nameStyle,
  unreadCount,
  container,
  containerActive,
  containerExpandedStyle,
  listItemStyle,
  listItemCollapsedStyle,
  nameCollapsedContainer,
  nameCollapsedStyle
} = styles;

const UserListItem = ({ isHidden, user, setActiveUser, activeUser }) => {
  const { name, unread, to, from } = user;
  const clientName =
    name.length <= 25 ? (
      name
    ) : (
      <Lang path="clientNameEllipsis" values={{ name: name.substr(0, 22) }} />
    );
  const hasUnread = unread > 0;
  const isActive =
    activeUser && activeUser.to === to && activeUser.from === from;
  const clientInitials = createInitials(name);
  const containerClasses = [container];
  const listItemClasses = [listItemStyle];

  const _setActiveUser = () => {
    const queryParam = stringify({ user: user.to });
    history.push({
      search: queryParam
    });
    dispatch(currentUserAction(user));
    setActiveUser(user);
  };

  if (isActive && !helper.platformInfo.isMobile) {
    containerClasses.push(containerActive);
  }
  if (!isHidden) {
    containerClasses.push(containerExpandedStyle);
  }
  if (isHidden) {
    listItemClasses.push(listItemCollapsedStyle);
  }
  return (
    <button
      type="button"
      title={name}
      className={containerClasses.join(' ')}
      onClick={_setActiveUser}
    >
      <li className={listItemClasses.join(' ')}>
        {isHidden ? (
          <div className={nameCollapsedContainer}>
            <span className={nameCollapsedStyle}>
              {clientInitials}
              {hasUnread && <span className={unreadCount}>{unread}</span>}
            </span>
          </div>
        ) : (
          <span data-testid="coach-chat-client-name" className={nameStyle}>
            {clientName}
            {hasUnread && <span className={unreadCount}>{unread}</span>}
          </span>
        )}
        {/* {!isHidden && false && */}
        {/* <span className={chatNow}>{lastMessage}<span className={dotMenu} /></span> */}
        {/* } */}
      </li>
    </button>
  );
};

export default UserListItem;
