import config from '../config';
import auth from '../utils/auth';

import { initializeApi, httpMethod } from './index';

const PARTNER_API_BASE_URL = config.partner.url;
const PARTNER_KEY = config.partner.key;

const fetchFromApi = initializeApi(PARTNER_API_BASE_URL);

// Api class that contains all our request api endpoints
class PartnerApi {
  getCompanyList = () =>
    fetchFromApi({
      endpoint: `tenant/${config.brand}/connection`,
      method: httpMethod.GET,
      authRequired: false,
      retryRequired: true,
      apiKey: PARTNER_KEY
    });

  getCompanyDetails = async (companyName) => {
    const { locale } = await auth.getUserDetails();
    return fetchFromApi({
      endpoint: `company?company_name=${encodeURIComponent(
        companyName
      )}&tenant=${config.brand}&locale=${encodeURIComponent(locale)}`,
      method: httpMethod.GET,
      retryRequired: true
    });
  };

  getRegistrationFields = (companyName) =>
    fetchFromApi({
      endpoint: `registration-fields?company_name=${companyName}&tenant=${config.brand}`,
      method: httpMethod.GET,
      authRequired: false,
      retryRequired: true,
      apiKey: PARTNER_KEY
    });
}

export default new PartnerApi();
