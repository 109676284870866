/**
 * Profile Actions and action creators
 */

import { initialize } from 'redux-form/immutable';
import api from '../../api/assessment';
import auth from '../../utils/auth';
import { clearConversationalAssessmentData } from './ConversationalAssessmentAction';

import {
  UPDATE_USER_DATA,
  UPDATE_USER_DATA_DONE,
  UPDATE_USER_DATA_ERROR,
  GET_USER_DATA,
  SET_ONBOARD_FLAG,
  SET_ONBOARD_FLAG_DONE,
  SET_ONBOARD_FLAG_ERROR,
  RESET_USER_DATA_STATUS
} from './types';

import logger from '../../utils/logger';
import handleUnexpectedError from '../helper';

const updateUserDataStart = () => ({
  type: UPDATE_USER_DATA
});

const updateUserDataDone = (params) => ({
  type: UPDATE_USER_DATA_DONE,
  payload: params
});

export const resetUserDataStatus = () => ({
  type: RESET_USER_DATA_STATUS
});

const updateUserDataError = () => ({
  type: UPDATE_USER_DATA_ERROR
});

export const updateUserData = (params) => (dispatch) => {
  dispatch(updateUserDataStart());
  dispatch(clearConversationalAssessmentData());
  return api
    .updateProfile(params)
    .then(async (res) => {
      if (res.status === 200) {
        await auth.renewAccessToken();
        dispatch(
          initialize('profile', params, false, { keepSubmitSucceeded: true })
        );
        dispatch(updateUserDataDone(params));
      } else {
        dispatch(updateUserDataError());
        logger.error(
          'An error occurred while updating profile data',
          'ProfileAction.updateUserData',
          { res }
        );
      }
    })
    .catch((err) => {
      dispatch(updateUserDataError());
      handleUnexpectedError(
        'An unexpected error occurred while updating user profile data.',
        'ProfileAction.updateUserData',
        err
      );
    });
};

export const getUserData = () => async (dispatch) =>
  dispatch({
    type: GET_USER_DATA,
    payload: await auth.getUserDetails()
  });

const setOnboardFlagStart = () => ({
  type: SET_ONBOARD_FLAG
});

const setOnboardFlagDone = () => ({
  type: SET_ONBOARD_FLAG_DONE
});

const setOnboardFlagError = () => ({
  type: SET_ONBOARD_FLAG_ERROR
});

export const setOnboardFlag = () => (dispatch) => {
  dispatch(setOnboardFlagStart());
  return api
    .setOnBoardFlag()
    .then((res) => {
      if (res.status === 200) {
        dispatch(setOnboardFlagDone());
      } else {
        dispatch(setOnboardFlagError());
        logger.error(
          'An error occurred while setting onboard flag',
          'ProfileAction.setOnboardFlag',
          { res }
        );
      }
    })
    .catch((err) => {
      dispatch(setOnboardFlagError());
      handleUnexpectedError(
        'An unexpected error occurred while setting onboard flag',
        'ProfileAction.setOnboardFlag',
        err
      );
    });
};
