/**
 * Reducer for managing the state of last chat assessment report
 */

import {
  CHAT_PROCESS_REPORT,
  CHAT_PROCESS_REPORT_ERROR,
  CHAT_PROCESS_REPORT_DONE
} from '../actions/types';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  report: {
    categoryName: null,
    level: null
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHAT_PROCESS_REPORT:
      return {
        ...state,
        isFetching: true
      };
    case CHAT_PROCESS_REPORT_DONE:
      return {
        ...state,
        report: {
          categoryName: action.categoryName.toLowerCase(),
          level: action.result.level
        },
        isFetching: false,
        hasError: false
      };
    case CHAT_PROCESS_REPORT_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true
      };
    default:
      return state;
  }
};
