import { has, get } from 'lodash';

export const nextArrowClick = (instanceRef) => {
  if (has(instanceRef, 'current.next')) {
    instanceRef.current.next();
  }
};

export const backArrowClick = (instanceRef) => {
  if (has(instanceRef, 'current.prev')) {
    instanceRef.current.prev();
  }
};

export const getNumberOfCard = (instanceRef) =>
  get(instanceRef, 'current.track.details.slides.length', 0);

export const moveToCard = (instanceRef, index) => {
  if (has(instanceRef, 'current')) {
    instanceRef.current.moveToIdx(index);
  }
};
