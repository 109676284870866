import config from '../config';

import { initializeApi, httpMethod } from './index';

const REQUEST_API_BASE_URL = config.api.requestUrl;

const fetchFromApi = initializeApi(REQUEST_API_BASE_URL);

// Api class that contains all our request api endpoints
class RequestApi {
  requestCoach = () =>
    fetchFromApi({
      endpoint: 'coach',
      method: httpMethod.POST
    });

  requestVideoTherapy = (params) =>
    fetchFromApi({
      endpoint: 'teletherapy',
      method: httpMethod.POST,
      data: params
    });

  getVideoTherapyStatus = () =>
    fetchFromApi({
      endpoint: 'teletherapy',
      method: httpMethod.GET
    });

  requestInPersonTherapy = (params) =>
    fetchFromApi({
      endpoint: 'inperson-therapy',
      method: httpMethod.POST,
      data: params
    });

  getInPersonTherapyStatus = () =>
    fetchFromApi({
      endpoint: 'inperson-therapy',
      method: httpMethod.GET
    });

  getCoachStatus = () =>
    fetchFromApi({
      endpoint: 'coach',
      method: httpMethod.GET,
      retryRequired: true
    });

  assignCoach = (params) =>
    fetchFromApi({
      endpoint: 'assignment',
      method: httpMethod.POST,
      data: params
    });

  getWorkLifeRequestQuestions = () =>
    fetchFromApi({
      endpoint: 'questions',
      method: httpMethod.GET,
      retryRequired: true
    });

  processWorkLifeRequestQuestions = (params) =>
    fetchFromApi({
      endpoint: 'process',
      method: httpMethod.POST,
      data: params,
      retryRequired: true
    });
}

export default new RequestApi();
