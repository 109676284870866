import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Components
import { Paper } from '@material-ui/core';
import { Divider } from 'antd';
// hocs
import withAuth from 'hoc/withAuth';
// styles
// actions
import { showModal } from 'store/actions/ModalAction';
import { startAutoCoachAssign } from 'store/actions/dashboard/CoachRequestAction';
// utils
import featureService from 'utils/feature-service';
import helpers from 'utils/helpers';
import logger from 'utils/logger';
import styles from './CoachInfo.module.scss';
import Lang from '../Lang';
import { AutoAssignmentLoader, AutoAssignmentError } from '../Coaching';
import SmartAppLink from '../Popups/SmartAppLink';

const { transformBySeverity } = helpers;
const {
  coachCardContainer,
  coachCardHeader,
  coachDetailsContainer,
  divider,
  coachAbout,
  coachBio,
  coachMessage,
  coachTalkButton,
  coachImage,
  coachDesig,
  coachName,
  coachDegree
} = styles;
class CoachInfo extends Component {
  _renderCoachCard = () => {
    const { welcomeMessage } = this.props.details;
    const { header, body } = welcomeMessage || {};
    if (Object.entries(this.props.details).length >= 1) {
      return (
        <Paper className={coachCardContainer}>
          <div data-testid="coach-card-header" className={coachCardHeader}>
            <span>{header}</span>
          </div>
          <div className={coachDetailsContainer}>
            <img
              className={coachImage}
              alt=""
              src={this.props.details.picture}
            />
            <div className={coachDesig}>
              <h2 data-testid="coach-name" className={coachName}>
                {this.props.details.name}
              </h2>
              <h4 data-testid="coach-title" className={coachDegree}>
                {this.props.details.designation}
              </h4>
            </div>
          </div>
          <div className={coachAbout}>
            <p data-testid="coach-bio" className={coachBio}>
              {this.props.details.bio}
            </p>
            <Divider className={divider} />
            <p data-testid="coach-message" className={coachMessage}>
              {body}
            </p>
            <Divider className={divider} />
          </div>
          <button
            data-testid="coach-talk-button"
            type="button"
            className={coachTalkButton}
            onClick={() =>
              this.props.actions.showModal(SmartAppLink, {
                source: 'smart-app-link',
                props: {
                  headerText: (
                    <Lang path="coachRequestCard.assigned.linkHeader" />
                  )
                }
              })
            }
          >
            <Lang path="coachRequestCard.assigned.header" />
          </button>
        </Paper>
      );
    }
    return null;
  };

  _retryCoachAssign = () => {
    logger.info(
      'Retrying auto coach assignment',
      'CoachInfo._retryCoachAssign'
    );
    const categories = this.props.report;
    this.props.actions.startAutoCoachAssign({
      mode: 'auto',
      eapName: this.props.eapName,
      goals: transformBySeverity(categories)
    });
  };

  render() {
    if (this.props.autoCoachInitiated) {
      return <AutoAssignmentLoader />;
    }

    if (this.props.autoCoachError) {
      return (
        <AutoAssignmentError
          retryHandler={this._retryCoachAssign}
          isFetching={this.props.isFetching}
        />
      );
    }
    // If the user has chat on web access then welcome card will be shown in chat window
    return (
      !featureService._hasCoachAccessOnWebFeature() && this._renderCoachCard()
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    showModal: bindActionCreators(showModal, dispatch),
    startAutoCoachAssign: bindActionCreators(startAutoCoachAssign, dispatch)
  }
});

export default withAuth(connect(null, mapDispatchToProps)(CoachInfo));
