export default {
  line: {
    layout: {
      padding: {
        right: 10
      }
    },
    events: ['mousemove', 'mouseout', 'touchmove', 'touchend'],
    scales: {
      x: {
        ticks: {
          maxRotation: 0
        }
      },
      y: {
        min: 0,
        max: 10,
        grid: {
          display: false
        },
        ticks: {
          stepSize: 5
        }
      }
    },
    pointHitRadius: 16,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          display: false
        }
      }
    }
  }
};
