import moment from 'moment';
import 'moment/min/locales';
import locale from '../../../utils/locale';
// Month generator

export const getMonths = () => {
  moment.locale(locale.getActiveLanguage());
  return moment.months().map((label, index) => {
    let value = index + 1;
    value = value < 10 ? `0${value}` : value;
    return { label, value: `${value}` };
  });
};

// Year generator

const minAge = 18;
const maxAge = 80;
const currentYear = new Date().getFullYear();

export const years = Array.from(new Array(maxAge - minAge), (val, index) => ({
  value: (currentYear - (index + minAge)).toString(),
  label: (currentYear - (index + minAge)).toString()
}));

// Day generator

export const days = Array.from(new Array(31), (val, index) => {
  const day = index + 1;
  const dayValue = day < 10 ? `0${day}` : day;
  return { value: `${dayValue}`, label: `${dayValue}` };
});
