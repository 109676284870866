/**
 * Interstitial component that is shown in between questions
 */

// Core
import React from 'react';
// Components
import { Row, Col } from 'antd/lib/index';
// hocs
import Aux from '../../../../components/layout/Aux';
// assets
// styles
import styles from './Interstitial.module.scss';

const Interstitial = ({ avatar, data }) => {
  const { img, quoteContainer, quote, container } = styles;
  const path = avatar ? avatar.path : '';
  const alt = avatar ? avatar.value : '';

  const text = data ? data.text : '';
  return (
    <Aux>
      <Row className={container}>
        <Col
          md={{
            span: 4,
            offset: 6
          }}
          xs={{
            span: 4,
            offset: 4
          }}
        >
          <img className={img} src={path} alt={alt} />
        </Col>
        <Col span={14} className={quoteContainer}>
          <p className={quote}>
            <span>{text}</span>
          </p>
        </Col>
      </Row>
    </Aux>
  );
};

export default Interstitial;
