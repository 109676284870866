/**
 * Avatar Action that sets the active avatar
 */

import { SET_AVATAR } from './types';

export default (avatar) => ({
  type: SET_AVATAR,
  avatar
});
