import React from 'react';
import { connect } from 'react-redux';
import { Divider } from 'antd';
import { Paper } from '@material-ui/core';
import history from 'utils/history';
import helpers from 'utils/helpers';
import analytics from 'utils/analytics/analytics';
import featureService from 'utils/feature-service';
import messageTypes from 'assets/data/chat/messageTypes';
import Lang from '../../../../components/Lang';
import styles from './CoachCard.module.scss';

const {
  container,
  containerCoach,
  coachCardHeader,
  coachDetailsContainer,
  coachImageStyle,
  coachDesig,
  coachNameStyle,
  coachDegree,
  coachAboutContainer,
  coachBio,
  divider,
  coachMessage,
  reviewGoal
} = styles;

const CoachCard = ({
  details: { header, coachDesignation, coachAbout, body },
  coachImage,
  coachName,
  isButtonVisible,
  lastReportId,
  isCoach,
  type
}) => {
  const onClickHandler = () => {
    if (featureService._hasIntelliPlatformFeature()) {
      analytics.track('coach card', 'clicked go to my plan');
      history.push('/my-plan');
    } else {
      analytics.track('coach card', 'clicked review goals');
      history.push(`/report/${lastReportId}`);
    }
  };
  return (
    <Paper className={isCoach ? containerCoach : container}>
      <div data-testid="coach-card-header" className={coachCardHeader}>
        <span>{header}</span>
      </div>
      <div className={coachDetailsContainer}>
        <img className={coachImageStyle} alt="" src={coachImage} />
        <div className={coachDesig}>
          <h2 data-testid="coach-name" className={coachNameStyle}>
            {coachName}
          </h2>
          <h4 data-testid="coach-title" className={coachDegree}>
            {coachDesignation}
          </h4>
        </div>
      </div>
      <div className={coachAboutContainer}>
        <p data-testid="coach-bio" className={coachBio}>
          {coachAbout}
        </p>
      </div>
      {type === messageTypes.coachCard && (
        <React.Fragment>
          <Divider className={divider} />
          <p data-testid="coach-message" className={coachMessage}>
            {body}
          </p>
        </React.Fragment>
      )}
      {isButtonVisible && type === messageTypes.coachCard && (
        <React.Fragment>
          {' '}
          <Divider className={divider} />
          <button
            type="button"
            data-testid="review-goal-button"
            className={reviewGoal}
            onClick={onClickHandler}
          >
            {featureService._hasIntelliPlatformFeature() ? (
              <Lang path="coachCardReviewButtonTextIntelliplatform" />
            ) : (
              <Lang path="coachCardReviewButtonText" />
            )}
          </button>
        </React.Fragment>
      )}
    </Paper>
  );
};

const mapStateToProps = (state) => {
  const {
    reportList: { reports }
  } = state;
  let lastReportId;
  const hasReports = reports.length > 0;
  if (hasReports) {
    lastReportId = reports[0]._id;
  }
  const currentPath = helpers.getActivePath();
  const isButtonVisible =
    hasReports &&
    currentPath !== '/coach/chat' &&
    !/(report|dashboard)\/*/.test(currentPath);
  return {
    isButtonVisible,
    lastReportId
  };
};

export default connect(mapStateToProps)(CoachCard);
