import React from 'react';
import ContentLoader from 'react-content-loader';

export const LoaderLarge = () => (
  <ContentLoader viewBox="0 0 900 183" height={183} width={900}>
    <rect x="0" y="0" rx="0" ry="0" width="235" height="17" />
    <rect x="0" y="30" rx="3" ry="3" width="218" height="166" />
    <rect x="232" y="30" rx="3" ry="3" width="218" height="180" />
    <rect x="464" y="30" rx="3" ry="3" width="218" height="180" />
    <rect x="696" y="30" rx="3" ry="3" width="200" height="180" />
  </ContentLoader>
);

export const LoaderSmall = () => (
  <ContentLoader viewBox="0 0 900 600" height={600} width={900}>
    <rect x="2" y="0" rx="0" ry="0" width="760" height="42" />
    <rect x="0" y="80" rx="3" ry="3" width="485" height="550" />
    <rect x="540" y="80" rx="3" ry="3" width="360" height="550" />
  </ContentLoader>
);
