import { isEmpty, cloneDeep } from 'lodash';
import logger from 'utils/logger';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';

export const getSituationRating = ({ id, progress, ratings }) => {
  let ratingIndex = 0;
  if (!isEmpty(progress)) {
    const situationIndex = progress.findIndex(
      (situation) => situation.id === id
    );
    if (situationIndex > -1) {
      ratingIndex = ratings.findIndex(
        (rating) =>
          rating.id === progress[situationIndex].ratingId ||
          rating.id === progress[situationIndex].value
      );
    }
  }
  return ratingIndex;
};

export const onChangeHandler = ({
  id,
  goalId,
  sessionId,
  screenId,
  progress,
  ratingId,
  ratingValue,
  setProgress
}) => {
  analytics.track(
    eventCategories.SELF_USE,
    `User has selected a rating ${ratingId}.`,
    {
      goalId,
      sessionId,
      screenId,
      customId: id,
      subcategory: 'situation'
    }
  );
  logger.info(
    'User has selected a rating.',
    'SituationScreen.utils.onSelectHandler',
    {
      goalId,
      sessionId,
      screenId,
      situationId: id,
      ratingId
    }
  );

  const progressCopy = cloneDeep(progress);
  const optionIndex = progress.findIndex((situation) => situation.id === id);
  if (optionIndex === -1) {
    progressCopy.push({
      id,
      value: ratingId,
      ratingId,
      ratingValue
    });
  } else {
    progressCopy[optionIndex] = {
      id,
      value: ratingId,
      ratingId,
      ratingValue
    };
  }
  setProgress(progressCopy);
};

export const validateScreenData = ({ screenData, id }) => {
  const isScreenDataEmpty = isEmpty(screenData);
  if (isScreenDataEmpty || isEmpty(id)) {
    logger.error(
      'Data missing to render situation screen.',
      'SituationScreen.utils.validateScreenData',
      {
        id,
        isScreenDataEmpty
      }
    );
    return false;
  }
  return true;
};
