/**
 * hoc that wraps the containers and sets background
 */

// core
import React, { Component } from 'react';
import classNamesInstance from 'classnames/bind';
import { connect } from 'react-redux';
import { IonContent, IonHeader, IonPage } from '@ionic/react';

// containers
import Navigation from 'containers/Navigation';
// utils
import { isLoggedIn } from 'utils/auth';
import profileFieldList from 'assets/data/profileFieldList';
import fieldTypes from 'assets/data/fieldTypes';
import i18n from 'assets/lang';
import config from 'config';
// styles
import styles from './wrapperHoc.module.scss';

const { fallbackLang } = config;

const classNames = classNamesInstance.bind(styles);

const wrapperHoc = (WrappedComponent, hocProps) => {
  class decWithWrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isMenuOpen: false,
        isFieldMissing: false
      };
    }

    componentWillMount() {
      if (isLoggedIn()) {
        const { registrationFields, profile } = this.props;
        const requiredProps = [];
        // To make sure that we do not prompt if the fields sent from back-end can't be mapped to local
        const localProps = {};
        profileFieldList().forEach((item) => {
          if (item.fieldType === fieldTypes.array) {
            item.fields.forEach((arrItem) => {
              localProps[arrItem.name] = true;
            });
          } else {
            localProps[item.name] = true;
          }
        });

        Object.keys(registrationFields).forEach((key) => {
          const isPropRequired =
            registrationFields[key].required === true &&
            registrationFields[key].visible === true &&
            localProps[key];
          if (isPropRequired) {
            requiredProps.push(key);
          }
        });

        for (let i = 0; i < requiredProps.length; i += 1) {
          if (!profile[requiredProps[i]]) {
            this.setState({
              isFieldMissing: true
            });
            i18n.changeLanguage(profile.locale || fallbackLang);
            this.props.history.replace('/account/profile', { source: 'auth' });
            break;
          }
        }
      }
    }

    closeSidebar = () => {
      if (this.state.isMenuOpen) {
        this.setState((prevState) => ({
          ...prevState,
          isMenuOpen: false
        }));
      }
    };

    openSidebar = () => {
      if (!this.state.isMenuOpen) {
        this.setState((prevState) => ({
          ...prevState,
          isMenuOpen: !prevState.isMenuOpen
        }));
      }
    };

    render() {
      const wrapperClass = classNames({
        backgroundStyle: hocProps && hocProps.setBackground
      });
      return (
        <IonPage>
          <IonHeader>
            <Navigation
              openSidebar={this.openSidebar}
              closeSidebar={this.closeSidebar}
              isMenuOpen={this.state.isMenuOpen}
              isFieldMissing={this.state.isFieldMissing}
            />
          </IonHeader>
          <IonContent className={wrapperClass}>
            <WrappedComponent {...this.props} />
          </IonContent>
        </IonPage>
      );
    }
  }

  const mapStateToProps = (state) => {
    const {
      profile: { profile },
      companyDetails: {
        companyDetails: { registrationFields }
      }
    } = state;
    return {
      profile,
      registrationFields
    };
  };

  return connect(mapStateToProps)(decWithWrapper);
};

export default wrapperHoc;
