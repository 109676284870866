import React from 'react';
import styles from './Toast.module.scss';

const {
  container,
  iconContainer,
  textContainer,
  iconContainerError,
  iconContainerSuccess,
  iconContainerWarning,
  buttonWrapper
} = styles;

const _getIconDetails = (type) => {
  switch (type) {
    case 'warning':
      return {
        icon: 'fa-exclamation-triangle',
        cssClass: iconContainerWarning
      };
    case 'success':
      return { icon: 'fa-thumbs-up', cssClass: iconContainerSuccess };
    case 'info':
      return { icon: 'fa-info-circle', cssClass: iconContainer };
    case 'error':
      return { icon: 'fa-exclamation-circle', cssClass: iconContainerError };
    default:
      return { icon: 'fa-info-circle', cssClass: iconContainer };
  }
};
const Toast = ({ message, type, callback }) => {
  const { icon, cssClass } = _getIconDetails(type);
  const handleClick = () => {
    if (typeof callback === 'function') {
      callback();
    }
  };
  return (
    <button type="button" className={buttonWrapper} onClick={handleClick}>
      <div className={container}>
        <div className={`${iconContainer} ${cssClass}`}>
          <i className={`fa ${icon} fa-lg`} />
        </div>
        <div data-testid="toast-message-text" className={textContainer}>
          {message}
        </div>
      </div>
    </button>
  );
};

export default Toast;
