import _ from 'lodash';
import update from 'immutability-helper';
import { queries } from '..';
import handleGraphqlError from '../helper';

const { GET_CHAT_MESSAGES_FOR_CLIENT, GET_CHAT_USER_INFO_FOR_COACH } = queries;
const getChatMessageReceivedOptions = ({
  to,
  from,
  subscription,
  onError
}) => ({
  document: subscription,
  variables: {
    from: to,
    to: from
  },
  updateQuery: (prev, { subscriptionData }) => {
    if (!subscriptionData.data) return prev;
    const newMessage = subscriptionData.data.chatMessageReceived;
    return {
      ...prev,
      getChatMessages: {
        ...prev.getChatMessages,
        messages: _.concat(prev.getChatMessages.messages, newMessage)
      }
    };
  },
  onError: (err) => {
    if (onError) {
      onError();
    }
    handleGraphqlError(
      `Error while subscribing to new messages. Error message is ${err.toString()}`,
      'getChatMessageReceivedOptions',
      err
    );
  }
});

const readFlagSubscriptionOptions = ({
  client,
  subscriptionData: {
    data: {
      chatMessageRead: { id, from, to, readFlag }
    }
  }
}) => {
  // Read the data from our cache for this query.
  const proxyData = client.readQuery({
    query: GET_CHAT_MESSAGES_FOR_CLIENT,
    variables: { to, from }
  });
  const { messages } = proxyData.getChatMessages;
  const messageIndexToUpdate = messages.findIndex(
    (message) => message.id === id
  );
  const data = update(proxyData, {
    getChatMessages: {
      messages: {
        [messageIndexToUpdate]: { $merge: { readFlag } }
      }
    }
  });
  // Update the cache with read flag updated
  client.writeQuery({
    query: GET_CHAT_MESSAGES_FOR_CLIENT,
    variables: { to, from },
    data
  });
};

const _getClientsList = (status, clientList, clientDetails) => {
  switch (status) {
    case 'ASSIGNED':
      return _.concat(clientDetails, clientList);
    case 'UNASSIGNED':
      return _.filter(clientList, (user) => user.to !== clientDetails.to);
    default:
      return clientList;
  }
};

const clientAssignedSubscriptionOptions = (
  {
    client,
    subscriptionData: {
      data: { chatState }
    }
  },
  filters
) => {
  try {
    const { status } = chatState;
    const clientDetails = _.omit(Object.assign({}, chatState), ['status']);
    clientDetails.to = chatState.from;
    clientDetails.from = chatState.to;
    const proxyData = client.readQuery({
      query: GET_CHAT_USER_INFO_FOR_COACH,
      variables: { pageNum: 1, ...filters }
    });
    const {
      getChatUserInfo: { user: clientList }
    } = proxyData;
    const user = _getClientsList(status, clientList, clientDetails);
    const newQuery = {
      query: GET_CHAT_USER_INFO_FOR_COACH,
      variables: { pageNum: 1, ...filters },
      data: {
        ...proxyData,
        getChatUserInfo: {
          ...proxyData.getChatUserInfo,
          user
        }
      }
    };
    //   // Update the cache with read flag updated
    client.writeQuery(newQuery);
    return { status, clientDetails, user };
  } catch (error) {
    handleGraphqlError(
      `An error occurred while handling client assigned subscription. Error message is ${error.toString()}`,
      'clientAssignedSubscriptionOptions',
      error
    );
  }
  return false;
};

const getCoachChatMessageReceivedOptions = ({
  to,
  from,
  subscription,
  coachChatFilters,
  onError,
  client
}) => ({
  document: subscription,
  variables: {
    to: from
  },
  updateQuery: (prev, { subscriptionData }) => {
    if (!subscriptionData.data) return prev;
    const {
      chatMessageReceived: newMessage,
      chatMessageReceived: { from: receivedTo, to: receivedFrom }
    } = subscriptionData.data;
    const isCurrentlySelected = to === receivedTo && from === receivedFrom;

    if (isCurrentlySelected) {
      return {
        ...prev,
        getChatMessages: {
          ...prev.getChatMessages,
          messages: _.concat(prev.getChatMessages.messages, newMessage)
        }
      };
    }
    const proxyData = client.readQuery({
      query: GET_CHAT_USER_INFO_FOR_COACH,
      variables: {
        pageNum: 1,
        ...coachChatFilters
      }
    });
    const { user } = proxyData.getChatUserInfo;
    const userIndexToUpdate = user.findIndex((data) => data.to === receivedTo);
    if (userIndexToUpdate !== -1) {
      const unread = user[userIndexToUpdate].unread + 1;
      const data = update(proxyData, {
        getChatUserInfo: {
          user: {
            [userIndexToUpdate]: { $merge: { unread } }
          }
        }
      });
      // Update the cache with read flag updated
      client.writeQuery({
        query: GET_CHAT_USER_INFO_FOR_COACH,
        variables: {
          pageNum: 1,
          ...coachChatFilters
        },
        data
      });
    }

    return prev;
  },
  onError: (err) => {
    if (onError) {
      onError();
    }
    handleGraphqlError(
      `Error while subscribing to new messages. Error message is ${err.toString()}`,
      'getChatMessageReceivedOptions',
      err
    );
  }
});

export default {
  getChatMessageReceivedOptions,
  readFlagSubscriptionOptions,
  clientAssignedSubscriptionOptions,
  getCoachChatMessageReceivedOptions
};
