// assets
import homeActiveIcon from 'assets/icons/navigation/home-active.svg';
import homeInactiveIcon from 'assets/icons/navigation/home-inactive.svg';
import coachActiveIcon from 'assets/icons/navigation/coach-active.svg';
import coachInactiveIcon from 'assets/icons/navigation/coach-inactive.svg';
import accountActiveIcon from 'assets/icons/navigation/account-active.svg';
import accountInactiveIcon from 'assets/icons/navigation/account-inactive.svg';
import resourcesActiveIcon from 'assets/icons/navigation/resources-active.svg';
import resourcesInactiveIcon from 'assets/icons/navigation/resources-inactive.svg';
import selfUseActiveIcon from 'assets/icons/navigation/self-use-active.svg';
import selfUseInactiveIcon from 'assets/icons/navigation/self-use-inactive.svg';

// assets
import i18n from 'assets/lang';

// utils
import helpers from 'utils/helpers';
import featureService from 'utils/feature-service';
import {
  hasTakenAssessment,
  isCoachChatVisible,
  getCoachRedirectUrl
} from './TabMenu.utils';

export default [
  {
    id: 'my-plan',
    text: i18n.t('navigation.myPlan'),
    activePath: 'my-plan',
    route: () => {
      const { goalId, severity } = helpers.getCurrentGoalAndSeverity();
      if (goalId && severity) {
        return `my-plan/${goalId}/${severity}`;
      }
      return 'my-plan';
    },
    activeIcon: homeActiveIcon,
    inactiveIcon: homeInactiveIcon,
    isVisible: ({ reports }) =>
      featureService._hasIntelliPlatformFeature() &&
      hasTakenAssessment({ reports })
  },
  {
    id: 'goal',
    text: i18n.t('navigation.goal'),
    activePath: 'report',
    route: () => `report/${helpers.getLastReportId()}`,
    activeIcon: homeActiveIcon,
    inactiveIcon: homeInactiveIcon,
    isVisible: () =>
      !featureService._hasIntelliPlatformFeature() && helpers.getLastReportId()
  },
  {
    id: 'coach',
    text: i18n.t('navigation.coach'),
    activePath: 'coach',
    route: ({ coachRequestStatus }) =>
      getCoachRedirectUrl({ coachRequestStatus }),
    activeIcon: coachActiveIcon,
    inactiveIcon: coachInactiveIcon,
    isVisible: ({ coachRequestStatus }) =>
      isCoachChatVisible({ coachRequestStatus })
  },
  {
    id: 'self-use',
    text: i18n.t('navigation.selfUse'),
    activePath: 'self-use',
    route: () => 'self-use',
    activeIcon: selfUseActiveIcon,
    inactiveIcon: selfUseInactiveIcon,
    isVisible: ({ reports }) =>
      hasTakenAssessment({ reports }) && featureService._hasSelfUseFeature()
  },
  {
    id: 'resources',
    text: i18n.t('navigation.resources'),
    activePath: 'resources',
    route: () => 'resources',
    activeIcon: resourcesActiveIcon,
    inactiveIcon: resourcesInactiveIcon,
    isVisible: ({ reports }) =>
      hasTakenAssessment({ reports }) && featureService._hasResourcesFeature()
  },
  {
    id: 'account',
    text: i18n.t('navigation.account'),
    activePath: 'account',
    route: () => 'account',
    activeIcon: accountActiveIcon,
    inactiveIcon: accountInactiveIcon,
    isVisible: () => true
  }
];
