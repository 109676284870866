import React from 'react';
import styles from './CoachSuggestion.module.scss';

const { suggestionContainer } = styles;
const CoachSuggestion = ({ index, text, intentScore, onSuggestionSelect }) => (
  <button
    type="button"
    className={suggestionContainer}
    onClick={() => {
      onSuggestionSelect({ text, intentScore });
    }}
  >
    <span data-testid={`coach-suggestion-text-${index}`}>{text}</span>
  </button>
);

export default CoachSuggestion;
