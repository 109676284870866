/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 *
 */

// Core
import React from 'react';
// Components
import { Row, Col } from 'antd';
import featureService from 'utils/feature-service';
import classNamesInstance from 'classnames/bind';
import RetakeSurveyCard from '../RetakeSurveyCard';
import ActionPlan from '../ActionPlan';
import BreathingExerciseCarousel from '../../../SelfUse/components/BreathingExercise/Carousel';
// utils
import helpers from '../../../../utils/helpers';
// styles
import styles from './HomePage.module.scss';

const classNames = classNamesInstance.bind(styles);

const HomePage = () => (
  <div
    className={classNames({
      container: true,
      containerDesktopPaddingTop: helpers.platformInfo.isDesktop
    })}
  >
    <div data-testid="my-plan-container" className={styles.bgcolor}>
      <ActionPlan />
    </div>
    {featureService._hasBreathingExerciseFeature() && (
      <div className={styles.carouselContainer}>
        <BreathingExerciseCarousel />
      </div>
    )}
    <div className={styles.sectiontwo}>
      <Row>
        <Col md={{ span: 24 }} lg={{ span: 12 }}>
          {featureService._hasRetakeSurveyCardFeature() && <RetakeSurveyCard />}
        </Col>
      </Row>
    </div>
  </div>
);

export default HomePage;
