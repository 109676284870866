import { isEmpty } from 'lodash';
import helpers from 'utils/helpers';
import coachRequestStatusTypes from 'assets/data/dashboard/coachRequestStatusTypes';
import featureService from 'utils/feature-service';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import logger from 'utils/logger';

export const getCurrentTabIcon = ({ activePath, active, inactive }) => {
  if (helpers.isActivePath(activePath)) {
    return active;
  }
  return inactive;
};

export const hasTakenAssessment = ({ reports }) => !isEmpty(reports);

export const isCoachChatVisible = ({ coachRequestStatus }) => {
  if (
    (featureService._hasCoachAccessOnWebFeature() &&
      coachRequestStatus === coachRequestStatusTypes.assigned) ||
    (helpers.isCoach() && featureService._hasCoachChatOnWebFeature())
  ) {
    return true;
  }
  return false;
};

export const getCoachRedirectUrl = ({ coachRequestStatus }) => {
  if (
    featureService._hasCoachAccessOnWebFeature() &&
    coachRequestStatus === coachRequestStatusTypes.assigned
  ) {
    return 'coach';
  }
  return 'coach/chat';
};

export const tabChangeHandler = (event) => {
  if (event) {
    const { tab } = event.detail;
    analytics.track(eventCategories.TAB_MENU, `clicked on ${tab} tab`);
  } else {
    logger.info(
      'Missing bottom tab navigation event.',
      'TabMenu.utils.tabChangeHandler'
    );
  }
};
