import _ from 'lodash';
// eslint-disable-next-line import/no-cycle
import handleGraphqlError from '../helper';
import { FETCH_POLICIES } from '../graphQL-config';

export default ({ client, query, variables }) => {
  client
    .query({
      query,
      variables,
      fetchPolicy: FETCH_POLICIES.networkOnly
    })
    .then((resp) => {
      const chatList = _.get(resp, 'data.getChatUserInfo.user', []);
      if (chatList.length > 0) {
        const dataProxy = client.readQuery({ query, variables });
        const newQuery = {
          query,
          data: {
            ...dataProxy,
            getChatUserInfo: {
              ...dataProxy.getChatUserInfo,
              messages: _.unionBy(
                dataProxy.getChatUserInfo.user,
                chatList,
                'to'
              )
            }
          }
        };
        client.writeQuery(newQuery);
      }
    })
    .catch((error) => {
      handleGraphqlError(
        'An error occurred while syncing chat list',
        'refetchChatList',
        error
      );
    });

  return true;
};
