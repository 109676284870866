/**
 * hoc that gets company name from the url and executes a cb function
 */

// core
import React, { Component } from 'react';
import { parse } from 'tiny-querystring';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import propTypes from 'prop-types';
// components
import FullPageLoader from '../../components/FullPageLoader';
import Lang from '../../components/Lang';
// actions
import { setRedirectUrl } from '../../store/actions/LoginRedirectAction';
// utils
import auth from '../../utils/auth';
import locale from '../../utils/locale';
import logger from '../../utils/logger';

const loginFromUrl = (WrappedComponent) => {
  class decWithLoginFromurl extends Component {
    static getDerivedStateFromProps(props, state) {
      if (state.accessCode && props.companyList.length > 0) {
        for (let i = 0; i < props.companyList.length; i += 1) {
          if (
            props.companyList[i].alias.toLowerCase() ===
            state.accessCode.toLowerCase()
          ) {
            return { ...state, isAccessCodeValid: !!state.accessCode };
          }
        }
        return { ...state, accessCode: null };
      }
      return null;
    }

    constructor(props) {
      super(props);
      let accessCode = null;
      let nextAction = null;
      let redirectTo;
      if (window.location.search) {
        const searchString = parse(window.location.search.slice(1));
        accessCode =
          searchString.company_name || searchString.access_code || null;
        nextAction = searchString.next;
        ({ redirectTo } = searchString);
      }
      this.state = {
        accessCode,
        isAccessCodeValid: false,
        nextAction,
        redirectTo
      };
    }

    async componentDidMount() {
      const { redirectTo, accessCode, isAccessCodeValid, nextAction } =
        this.state;
      const { companyList, actions } = this.props;
      if (redirectTo) {
        actions.setRedirectUrl(redirectTo);
      }
      if (accessCode && isAccessCodeValid) {
        const companyObj = companyList.find(
          (item) => item.alias.toLowerCase() === accessCode.toLowerCase()
        );
        const activeLanguage = locale.getActiveLanguage();
        logger.info('About to login', 'auth._getDetailsAndAuthenticate', {
          accessCode,
          companyObj,
          activeLanguage
        });
        await auth._getDetailsAndAuthenticate({
          accessCode,
          companyObj,
          nextAction,
          activeLanguage
        });
      }
    }

    async componentDidUpdate() {
      if (this.state.accessCode && this.state.isAccessCodeValid) {
        const companyObj = this.props.companyList.find(
          (item) =>
            item.alias.toLowerCase() === this.state.accessCode.toLowerCase()
        );

        const activeLanguage = locale.getActiveLanguage();
        logger.info('About to login', 'auth._getDetailsAndAuthenticate', {
          accessCode: this.state.accessCode,
          companyObj,
          activeLanguage
        });
        await auth._getDetailsAndAuthenticate({
          accessCode: this.state.accessCode,
          companyObj,
          nextAction: this.state.nextAction,
          activeLanguage
        });
      }
    }

    render() {
      if (this.state.accessCode) {
        return (
          <FullPageLoader
            loaderText={
              this.state.nextAction === 'register' ? (
                <Lang path="companySelectionPage.registerRedirectionText" />
              ) : (
                <Lang path="companySelectionPage.loginRedirectionText" />
              )
            }
          />
        );
      }
      return (
        <WrappedComponent
          companyName={this.state.companyName}
          companyFieldDisabled={!!this.state.companyName}
          companyList={this.props.companyList}
          {...this.props}
        />
      );
    }
  }

  const mapStateToProps = (state) => {
    const {
      companyList,
      isLoading: companyListLoading,
      hasError: companyListError
    } = state.companyList;

    return {
      companyList,
      companyListLoading,
      companyListError
    };
  };

  const mapDispatchToProps = (dispatch) => ({
    actions: {
      setRedirectUrl: bindActionCreators(setRedirectUrl, dispatch)
    }
  });

  decWithLoginFromurl.propTypes = {
    companyList: propTypes.arrayOf(propTypes.shape({ alias: propTypes.string }))
      .isRequired,
    actions: propTypes.shape({ setRedirectUrl: propTypes.func }).isRequired
  };

  return connect(mapStateToProps, mapDispatchToProps)(decWithLoginFromurl);
};

export default loginFromUrl;
