import React from 'react';
import PropTypes from 'prop-types';
import styles from './FieldSet.module.scss';

const { fieldset, fieldsetLegend } = styles;

const FieldSet = (props) => (
  <fieldset className={[fieldset, props.className].join(' ')}>
    <legend className={fieldsetLegend}>{props.label}</legend>
    {props.children}
  </fieldset>
);

FieldSet.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
};

FieldSet.defaultProps = {
  className: '',
  label: '',
  children: () => undefined
};

export default FieldSet;
