/**
 * Logdna logging module
 */

// TODO: Fix cyclic dependency
import Logger from '@logdna/logger';
import { v4 as uuidV4 } from 'uuid';
import _ from 'lodash';
import ClientJs from 'clientjs';
import { modifyAndObfuscateLog } from 'logger-customization';
// eslint-disable-next-line import/no-cycle
import config from '../../config';
// eslint-disable-next-line import/no-cycle
import { getAuth0Id } from '../auth';

const clientJs = new ClientJs();

const LOGDNA_KEY = config.logDna.key;
const ENVIRONMENT = config.environment;
const LOGDNA_NAME = config.logDna.appName;

const SESSION_ID_KEY = 'session_id';

let sessionId = window.sessionStorage.getItem(SESSION_ID_KEY);

export const getSessionId = () => {
  if (_.isEmpty(sessionId)) {
    sessionId = uuidV4();
    window.sessionStorage.setItem(SESSION_ID_KEY, sessionId);
  }
  return sessionId;
};

const logLevels = {
  error: 'error',
  fatal: 'fatal',
  debug: 'debug',
  warn: 'warn',
  info: 'info',
  trace: 'trace'
};

const deviceMetadata = {
  softwareVersion: process.env.REACT_APP_BUILD_VER,
  browser: clientJs.getBrowser(),
  os: clientJs.getOS(),
  userAgent: clientJs.getUA()
};

const options = {
  app: LOGDNA_NAME,
  env: ENVIRONMENT,
  indexMeta: true,
  sendUserAgent: false
};

const logToConsole = (level, message, source, data) => {
  if (config.debugLogs) {
    switch (level) {
      case logLevels.error:
      case logLevels.fatal: {
        // eslint-disable-next-line no-console
        console.error({
          level,
          message,
          source,
          data
        });
        break;
      }
      case logLevels.trace: {
        // eslint-disable-next-line no-console
        console.trace({
          level,
          message,
          source,
          data
        });
        break;
      }
      case logLevels.debug: {
        // eslint-disable-next-line no-console
        console.debug({
          level,
          message,
          source,
          data
        });
        break;
      }
      case logLevels.warn: {
        // eslint-disable-next-line no-console
        console.warn({
          level,
          message,
          source,
          data
        });
        break;
      }
      case logLevels.info: {
        // eslint-disable-next-line no-console
        console.info({
          level,
          message,
          source,
          data
        });
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.log(`Unknown level log, please write a case for ${level}`, {
          message,
          source,
          data
        });
      }
    }
  }
};

// Add the meta data as a meta object
const addMetaData = (source, data) => {
  let updatedData = {
    deviceMetadata,
    schemaVersion: 'v2',
    sessionId: getSessionId()
  };

  const auth0Id = getAuth0Id();
  if (auth0Id) {
    updatedData = {
      ...updatedData,
      userMetadata: { auth0Id }
    };
  }
  if (source) {
    updatedData = {
      ...updatedData,
      source
    };
  }

  if (typeof data === 'string' || data instanceof String) {
    updatedData = { ...updatedData, logMetadata: { data } };
  } else if (
    data &&
    !(Object.keys(data).length === 0 && data.constructor === Object)
  ) {
    updatedData = { ...updatedData, logMetadata: modifyAndObfuscateLog(data) };
  }
  return { meta: updatedData };
};
const logDnaLogger = Logger.setupDefaultLogger(LOGDNA_KEY, options);

// Wrap the default logger methods so we can filter and attach our metadata
class LoggingModule {
  trace = (msg, source, data) => {
    logToConsole(logLevels.trace, msg, source, data);
    logDnaLogger.log(msg, addMetaData(source, data));
  };

  info = (msg, source, data) => {
    logToConsole(logLevels.info, msg, source, data);
    logDnaLogger.info(msg, addMetaData(source, data));
  };

  error = (msg, source, data) => {
    logToConsole(logLevels.error, msg, source, data);
    logDnaLogger.error(msg, addMetaData(source, data));
  };

  warn = (msg, source, data) => {
    logToConsole(logLevels.warn, msg, source, data);
    logDnaLogger.warn(msg, addMetaData(source, data));
  };

  debug = (msg, source, data) => {
    logToConsole(logLevels.debug, msg, source, data);
    logDnaLogger.debug(msg, addMetaData(source, data));
  };

  fatal = (msg, source, data) => {
    logToConsole(logLevels.fatal, msg, source, data);
    logDnaLogger.fatal(msg, addMetaData(source, data));
  };
}

export default new LoggingModule();
