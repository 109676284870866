import update from 'immutability-helper';
import _ from 'lodash';
import screenTypes from 'assets/data/self-use/screenTypes';
import { supportedSteps } from 'assets/data/self-use/thoughtRecorder';
import moment from '../../utils/moment';

const getSelectedGoalInfoOptions = ({ id, version, query }) => ({
  variables: {
    id,
    version
  },
  optimisticResponse: {
    __typename: 'Mutation',
    upsertUserGoals: {
      message: 'Successfully updated or inserted goal info',
      __typename: 'UpsertUserGoalsResponse'
    }
  },
  update: (proxy) => {
    // Read the data from our cache for this query.
    const queryObject = { query };
    const proxyData = proxy.readQuery(queryObject);
    const data = update(proxyData, {
      getSelectedGoalInfo: {
        goalId: { $set: id },
        completedSessionsCount: { $set: '-' },
        version: { $set: version }
      }
    });
    queryObject.data = data;
    proxy.writeQuery(queryObject);
  }
});

const transformRequestData = ({
  requestData,
  currentObj,
  screenType,
  path = ''
}) => {
  const currentObjCopy = _.cloneDeep(currentObj);
  if (!_.isEmpty(currentObjCopy)) {
    Object.keys(currentObjCopy).forEach((key) => {
      if (currentObjCopy[key] !== undefined) {
        const currentPath = (path ? `${path}.${key}` : key) || '';

        // Handles typename and reference id for rational response
        if (
          key === '__typename' ||
          (key === 'id' &&
            currentPath.split('.')[0] === supportedSteps.RATIONAL_RESPONSE)
        ) {
          _.unset(requestData, currentPath);
        }

        // Recursively iterate over objects
        if (typeof currentObjCopy[key] === 'object') {
          transformRequestData({
            requestData,
            currentObj: currentObjCopy[key],
            path: currentPath,
            screenType
          });
        }
      }
    });
  }
  return requestData;
};

// Mutation option to optimistically update three queries
const getSessionOptions = ({
  selectedSession,
  sessionList,
  selectedGoalInfo,
  data,
  goalId,
  screenId,
  screenType,
  contentType,
  sessionId,
  source,
  version,
  isSessionCompleted,
  isScreenCompleted
}) => {
  let variables = {
    goalId,
    screenId,
    screenType,
    contentType,
    sessionId,
    source,
    version,
    isSessionCompleted,
    isScreenCompleted
  };
  const optimisticResponse = {
    __typename: 'Mutation',
    upsertSessionProgress: {
      session: {
        goalId,
        screenId,
        screenType,
        contentType,
        sessionId,
        source,
        __typename: 'UserSessionData'
      },
      __typename: 'UpsertSessionProgressResponse'
    }
  };

  // Transformation for Identify symptoms, Behavior and Body sensation screen
  let checklist;
  if (data && data.options) {
    checklist = _.cloneDeep(data.options);
    transformRequestData({
      requestData: checklist,
      currentObj: checklist,
      screenType
    });
  }

  // Transformation for Situation screen
  let situationsData;
  if (data && data.situations) {
    situationsData = _.cloneDeep(data.situations);
    transformRequestData({
      requestData: situationsData,
      currentObj: situationsData,
      screenType
    });
  }

  // Transformation for thought recorder screen
  let stepProgressReqData;
  if (data && data.stepProgress) {
    stepProgressReqData = _.cloneDeep(data.stepProgress);
    transformRequestData({
      requestData: stepProgressReqData,
      currentObj: stepProgressReqData,
      screenType
    });
  }

  // Transformation of textInput screen
  let currentTextInputs;
  if (data && data.textInputs) {
    currentTextInputs = _.cloneDeep(data.textInputs);
    transformRequestData({
      requestData: currentTextInputs,
      currentObj: currentTextInputs,
      screenType
    });
  }

  if (data) {
    variables = {
      ...variables,
      currentDuration: data.currentDuration,
      options: checklist,
      id: data.id,
      trackerValue: data.trackerValue,
      trackerType: data.trackerType,
      stepProgress: stepProgressReqData,
      textInputs: currentTextInputs,
      situations: situationsData
    };
    optimisticResponse.upsertSessionProgress.session.data = {
      ...data,
      __typename: 'SessionProgressData'
    };
    const options = _.get(
      optimisticResponse,
      'upsertSessionProgress.session.data.options',
      []
    );
    const textInputs = _.get(
      optimisticResponse,
      'upsertSessionProgress.session.data.textInputs',
      []
    );

    const situations = _.get(
      optimisticResponse,
      'upsertSessionProgress.session.data.situations',
      []
    );

    if (!_.isEmpty(options)) {
      for (let i = 0; i < options.length; i += 1) {
        options[i] = {
          ...options[i],
          __typename: 'ChecklistOptionProgress'
        };
      }
      optimisticResponse.upsertSessionProgress.session.data.options = options;
    }

    if (!_.isEmpty(situations)) {
      for (let i = 0; i < situations.length; i += 1) {
        situations[i] = {
          ...situations[i],
          __typename: 'SituationProgress'
        };
      }
      optimisticResponse.upsertSessionProgress.session.data.situations =
        situations;
    }

    const stepProgress = _.get(
      optimisticResponse,
      'upsertSessionProgress.session.data.stepProgress',
      {}
    );

    if (!_.isEmpty(stepProgress)) {
      optimisticResponse.upsertSessionProgress.session.data.stepProgress.__typename =
        'StepProgress';
    }

    if (!_.isEmpty(textInputs)) {
      for (let i = 0; i < textInputs.length; i += 1) {
        textInputs[i] = {
          ...textInputs[i],
          __typename: 'TextInputs'
        };
      }
      optimisticResponse.upsertSessionProgress.session.data.textInputs =
        textInputs;
    }
  }
  return {
    variables,
    optimisticResponse,
    update: (proxy) => {
      // Updating the completedSessionsCount and completedScreens array when session is completed
      // to optimistically update the carousal and completedSessionsCount on self-use tab
      if (isSessionCompleted) {
        const sessionListObj = {
          query: sessionList,
          variables: {
            goalId,
            version
          }
        };
        const sessionListData = proxy.readQuery(sessionListObj);

        sessionListObj.data = update(sessionListData, {
          getSession: {
            completedScreens: { $push: [screenId] }
          }
        });

        proxy.writeQuery(sessionListObj);

        const selectedGoalInfoObj = { query: selectedGoalInfo };

        const selectedGoalInfoData = proxy.readQuery(selectedGoalInfoObj);

        selectedGoalInfoObj.data = update(selectedGoalInfoData, {
          getSelectedGoalInfo: {
            completedSessionsCount: {
              $apply(x) {
                return x + 1;
              }
            }
          }
        });

        proxy.writeQuery(selectedGoalInfoObj);
      }

      const selectedSessionObj = {
        query: selectedSession,
        variables: {
          sessionId,
          goalId,
          version
        }
      };

      const selectedSessionData = proxy.readQuery(selectedSessionObj);

      const completedScreens = _.get(
        selectedSessionData,
        'getSession.completedScreens',
        []
      );

      const screens = _.get(
        selectedSessionData,
        'getSession.sessions[0].screens',
        []
      );
      const screenIndex = screens.findIndex((screen) => screen.id === screenId);
      const currentScreenData = screens.find(
        (screen) => screen.id === screenId
      );

      let updatedData = selectedSessionData;
      // Updating completedScreens array when screen is completed
      if (
        isScreenCompleted &&
        Array.isArray(completedScreens) &&
        !completedScreens.includes(screenId)
      ) {
        updatedData = update(selectedSessionData, {
          getSession: {
            completedScreens: { $push: [screenId] }
          }
        });
      }
      selectedSessionObj.data = updatedData;

      // Updating the user current progress
      if (data) {
        const {
          currentDuration,
          trackerValue,
          options,
          textInputs,
          id,
          stepProgress: stepProgressData,
          situations
        } = data;
        let completeTextInput = [...(textInputs || [])];
        if (screenTypes.textInput === screenType) {
          const currentScreenProgress = _.get(
            currentScreenData,
            'progress.textInputs',
            []
          );
          completeTextInput = [...completeTextInput, ...currentScreenProgress];
        }
        let updatedSelectedSessionData = update(updatedData, {
          getSession: {
            sessions: {
              0: {
                screens: {
                  [screenIndex]: {
                    progress: {
                      $set: {
                        currentDuration: currentDuration || 0,
                        trackerValue: trackerValue || null,
                        options: options || null,
                        textInputs: completeTextInput || null,
                        situations: situations || null,
                        __typename: 'SessionProgressData'
                      }
                    }
                  }
                }
              }
            }
          }
        });

        if (screenType === screenTypes.tracker) {
          updatedSelectedSessionData = update(updatedSelectedSessionData, {
            getSession: {
              tools: {
                trackerProgress: {
                  timestamp: { $push: [moment(new Date()).format('MMM DD')] },
                  trackerValue: { $push: [data.trackerValue] }
                }
              }
            }
          });
        }
        if (screenType === screenTypes.thoughtRecorder) {
          const thoughtRecorderList = _.get(
            updatedSelectedSessionData,
            'getSession.tools.thoughtRecorder',
            []
          );

          let thoughtRecorderIndex = 0;
          if (!_.isEmpty(thoughtRecorderList)) {
            const currentIndex = thoughtRecorderList.findIndex(
              (thoughtRecorder) => thoughtRecorder.id === id
            );
            if (currentIndex === -1) {
              thoughtRecorderIndex = thoughtRecorderList.length;
            } else {
              thoughtRecorderIndex = currentIndex;
            }
          }

          if (!_.isEmpty(stepProgressData)) {
            const types = Object.values(supportedSteps);
            stepProgressData.__typename = 'StepProgress';
            for (let i = 0; i < types.length; i += 1) {
              if (_.isEmpty(stepProgressData[types[i]])) {
                const existingProgress = _.get(
                  thoughtRecorderList,
                  `[${thoughtRecorderIndex}].stepProgress.${types[i]}`,
                  []
                );
                if (!_.isEmpty(existingProgress)) {
                  stepProgressData[types[i]] = existingProgress;
                } else {
                  stepProgressData[types[i]] = [];
                }
              } else {
                for (let j = 0; j < stepProgressData[types[i]].length; j += 1) {
                  if (types[i] === 'rationalResponse') {
                    stepProgressData[types[i]][j] = {
                      ...stepProgressData[types[i]][j],
                      __typename: 'RationalResponseContent'
                    };
                  } else {
                    stepProgressData[types[i]][j] = {
                      ...stepProgressData[types[i]][j],
                      __typename: 'StepProgressResponse'
                    };
                  }
                }
              }
            }
            updatedSelectedSessionData = update(updatedSelectedSessionData, {
              getSession: {
                tools: {
                  thoughtRecorder: {
                    [thoughtRecorderIndex]: {
                      $set: {
                        id,
                        stepProgress: stepProgressData,
                        __typename: 'ThoughtRecorderProgress'
                      }
                    }
                  }
                }
              }
            });
          }
        }
        selectedSessionObj.data = updatedSelectedSessionData;
      }
      proxy.writeQuery(selectedSessionObj);
    }
  };
};

export { getSelectedGoalInfoOptions, getSessionOptions };
