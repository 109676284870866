// Core
import React from 'react';

// styles
import styles from './AssessmentHeader.module.scss';

const { assessmentHeaderText } = styles;

const AssessmentHeader = (props) => {
  const classes = [];
  classes.push(styles.assessmentHeader);

  if (props && props.className) {
    classes.push(props.className);
  }

  return (
    <div className={classes.join(' ')}>
      <div className={assessmentHeaderText}>{props.children}</div>
    </div>
  );
};

export default AssessmentHeader;
