// core
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext
} from 'react';
import propTypes from 'prop-types';
import history from 'utils/history';
import {
  useQuery,
  useSubscription,
  useApolloClient
} from '@apollo/react-hooks';
import _ from 'lodash';
import update from 'immutability-helper';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  currentUserAction,
  infoTabAction
} from 'store/actions/coachchat/ActiveUserAction';
import { dispatch } from 'store';
import { stringify } from 'tiny-querystring';
// components
import { InView } from 'react-intersection-observer';
import withAuth from 'hoc/withAuth';
// utils
import helper from 'utils/helpers';
// styles
import filter from 'assets/icons/filter.svg';

import { queries, subscriptions, functions } from 'utils/graphql';
import subscriptionOptions from 'utils/graphql/subscriptions/subscription-options';
import logger from 'utils/logger';
import analytics from 'utils/analytics';
import toast from '../../../../../components/Toast';
import Lang from '../../../../../components/Lang';
import { Spinner } from '../../../../../components/ui/Layout';
import ChatFilter from '../ChatFilter';
import styles from './ChatLeft.module.scss';
import { ChatClientUsersError } from '../Error';
import UserListItem from '../UserListItem';
import CoachChatFiltersContext from '../ChatFilter/CoachChatFiltersContext';

const { GET_CHAT_USER_INFO_FOR_COACH } = queries;
const { CLIENT_ASSIGNED } = subscriptions;
const { clientAssignedSubscriptionOptions } = subscriptionOptions;
const { refetchChatList } = functions;

const {
  container,
  menuContainer,
  chatSearch,
  clientSec,
  listSec,
  noUserFoundContainer,
  noUserFoundTextStyle,
  clientsListEnd,
  filterSec,
  ftrIcon,
  activeFiltersCount
} = styles;

let timer = null;
let pageNum = 1;
let isPaginated = false;

const ChatLeft = ({
  auth0Id,
  isSessionExpired,
  setSelectedUser,
  setChatListError,
  setChatListEmpty,
  setActiveScreens,
  queryParams
}) => {
  const client = useApolloClient();
  const filterRef = useRef(null);
  const clientAssignedSubscriptionHandle = useRef(null);
  const [activeUser, setActiveUser] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isFiltervisible, setFilterVisibility] = useState(false);
  const [selectedMessageFilter, setSelectedMessageFilter] = React.useState('');
  const [selectedActivityFilter, setSelectedActivityFilter] =
    React.useState('');
  const { t } = useTranslation();
  const { coachChatFilters, setCoachChatFilters } = useContext(
    CoachChatFiltersContext
  );
  const currentUser = useSelector((state) => _.get(state, 'currentUser'));

  const {
    error: chatListError,
    data: chatListData,
    loading: chatListLoading,
    refetch: chatListRefetch,
    fetchMore
  } = useQuery(GET_CHAT_USER_INFO_FOR_COACH, {
    variables: { pageNum: 1, ...coachChatFilters }
  });
  const userList = _.get(chatListData, 'getChatUserInfo.user', []);
  const { isDesktop, isMobile } = helper.platformInfo;

  const getUserFromList = ({ list, valueToFind, searchKey }) => {
    let user = null;
    if (!_.isEmpty(valueToFind)) {
      user = _.find(list, (element) => element[searchKey] === valueToFind);
    }
    return user;
  };

  const currentUserCheck = () =>
    getUserFromList({
      list: userList,
      valueToFind: _.get(currentUser, 'to'),
      searchKey: 'to'
    });

  const getUserFromQueryParams = () => {
    let user = null;
    if (_.has(queryParams, 'user')) {
      const userId = queryParams.user;
      user = getUserFromList({
        list: userList,
        valueToFind: userId,
        searchKey: 'to'
      });
    }

    return user;
  };

  const getUserFromStore = () => {
    let user = null;

    if (!_.isEmpty(currentUser)) {
      user = currentUserCheck();
    }
    return user;
  };

  const getDefaultUser = () => {
    let user = null;

    if (!isMobile) {
      [user] = userList;
      logger.info('Default user set.', 'CoachLeft.getDefaultUser');
      dispatch(currentUserAction(user));
    } else {
      logger.info('Reseting user in store to null', 'ChatLeft.getDefaultUser');
      dispatch(currentUserAction(null));
    }

    return user;
  };

  const getUser = () => {
    let user = null;

    user = getUserFromQueryParams();
    if (!_.isEmpty(user)) {
      logger.info('User set from queryParams', 'CoachLeft.getUser');
      return user;
    }

    user = getUserFromStore();
    if (!_.isEmpty(user)) {
      logger.info('User set from store', 'CoachLeft.getUser');
      return user;
    }

    return getDefaultUser();
  };

  if (!activeUser && !chatListError && !chatListLoading && userList.length) {
    const user = getUser();
    if (!_.isEmpty(user)) {
      const search = {
        user: user.to
      };
      if (isDesktop) {
        search.view = 'info';
        dispatch(infoTabAction('info'));
      }
      history.replace({
        search: stringify(search)
      });
      setActiveUser(user);
    }
  }

  const resetPagination = () => {
    pageNum = 1;
  };

  const incrementPageNum = () => {
    pageNum += 1;
  };

  const _handleStateOnline = useCallback(() => {
    resetPagination();
    refetchChatList({
      client,
      query: GET_CHAT_USER_INFO_FOR_COACH,
      variables: { pageNum, ...coachChatFilters }
    });
  }, [client, coachChatFilters]);

  const _notify = ({ status, clientDetails }) => {
    const { name } = clientDetails;
    switch (status) {
      case 'ASSIGNED':
        toast.info(
          <Lang
            path="coachChat.toastMessages.clientAssignedText"
            values={{ name }}
          />
        );
        break;
      case 'UNASSIGNED':
        toast.info(
          <Lang
            path="coachChat.toastMessages.clientUnassignedText"
            values={{ name }}
          />
        );
        break;
      default:
        logger.error(
          `An error occurred while notifying coach about client assigment status. Status: ${status} is unknown`,
          'notifyCoach'
        );
        break;
    }
  };

  useEffect(() => {
    if (
      isSessionExpired &&
      typeof clientAssignedSubscriptionHandle.current === 'function'
    ) {
      clientAssignedSubscriptionHandle.current();
    }
  }, [isSessionExpired]);

  useEffect(() => {
    setSelectedUser(activeUser);
    setActiveScreens();
  }, [activeUser, setActiveScreens, setSelectedUser]);

  useEffect(() => {
    const isChatListEmpty =
      !chatListData || !chatListData.getChatUserInfo.user.length;
    if (!chatListLoading && chatListError) {
      setChatListError(true);
    } else if (!chatListLoading && isChatListEmpty) {
      setChatListEmpty(true);
    } else if (!chatListLoading) {
      setChatListEmpty(false);
      setChatListError(false);
    }
  }, [
    chatListError,
    chatListLoading,
    chatListData,
    setChatListError,
    setChatListEmpty
  ]);

  useEffect(() => {
    if (activeUser && activeUser.to && !chatListLoading) {
      const { to } = activeUser;
      const proxyData = client.readQuery({
        query: GET_CHAT_USER_INFO_FOR_COACH,
        variables: {
          pageNum: 1,
          ...coachChatFilters
        }
      });
      const { user } = proxyData.getChatUserInfo;
      const userIndexToUpdate = user.findIndex((data) => data.to === to);
      if (userIndexToUpdate !== -1) {
        const unread = 0;
        const data = update(proxyData, {
          getChatUserInfo: {
            user: {
              [userIndexToUpdate]: { $merge: { unread } }
            }
          }
        });
        client.writeQuery({
          query: GET_CHAT_USER_INFO_FOR_COACH,
          variables: {
            pageNum: 1,
            ...coachChatFilters
          },
          data
        });
      }
    }
  }, [activeUser, client, coachChatFilters, chatListLoading]);

  useEffect(() => {
    window.addEventListener('online', _handleStateOnline);

    return () => {
      window.removeEventListener('online', _handleStateOnline);
    };
  }, [_handleStateOnline]);

  const handleFilter = () => {
    analytics.track('chat', 'toggle filter');
    setFilterVisibility(!isFiltervisible);
  };

  const handleClickOutsideFilter = useCallback(
    (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setFilterVisibility(false);
      }
    },
    [filterRef]
  );

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutsideFilter);
    return () => {
      window.removeEventListener('mousedown', handleClickOutsideFilter);
    };
  }, [handleClickOutsideFilter]);

  clientAssignedSubscriptionHandle.current = useSubscription(CLIENT_ASSIGNED, {
    skip: !auth0Id,
    variables: { to: auth0Id },
    onSubscriptionData: (data) => {
      const assignedUser = clientAssignedSubscriptionOptions(
        data,
        coachChatFilters
      );
      if (assignedUser) {
        const { status, clientDetails, user } = assignedUser;
        if (
          status === 'UNASSIGNED' &&
          clientDetails.to === activeUser.to &&
          clientDetails.from === activeUser.from
        ) {
          const selectedUser = user.length ? user[0] : false;
          setActiveUser(selectedUser);
        }
        _notify({ status, clientDetails });
      }
    }
  });

  const fetchMoreClients = () => {
    isPaginated = true;
    incrementPageNum();
    fetchMore({
      query: GET_CHAT_USER_INFO_FOR_COACH,
      variables: { pageNum, ...coachChatFilters },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newUser = fetchMoreResult.getChatUserInfo.user;
        const { pagination } = fetchMoreResult.getChatUserInfo;
        return {
          ...prev,
          getChatUserInfo: {
            ...prev.getChatUserInfo,
            user: [...prev.getChatUserInfo.user, ...newUser],
            pagination
          }
        };
      }
    });
  };

  const filtersHandler = (search, message, activity) => {
    isPaginated = false;
    resetPagination();
    setCoachChatFilters({
      search: search.trim(),
      message,
      activity
    });
    const clientsList = document.getElementById('clients-list');
    if (clientsList) {
      clientsList.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };

  const searchFilterHandler = (event) => {
    const text = event.target.value;
    clearInterval(timer);
    setSearchText(text);
    timer = setTimeout(() => {
      filtersHandler(text, selectedMessageFilter, selectedActivityFilter);
    }, 200);
  };

  const onSelectMessageFilterHandler = (messageFilterId) => {
    analytics.track('chat', 'clicked message filter', {
      optionId: messageFilterId
    });
    setSelectedMessageFilter(messageFilterId);
    filtersHandler(searchText, messageFilterId, selectedActivityFilter);
  };

  const onSelectActivityFilterHandler = (activityFilterId) => {
    analytics.track('chat', 'clicked activity filter', {
      optionId: activityFilterId
    });
    setSelectedActivityFilter(activityFilterId);
    filtersHandler(searchText, selectedMessageFilter, activityFilterId);
  };

  const clearFiltersHandler = () => {
    analytics.track('chat', 'clicked clear filter');
    setSelectedMessageFilter('');
    setSelectedActivityFilter('');
    setSearchText('');
    filtersHandler('', '', '');
  };

  const hasNextPage = _.get(
    chatListData,
    'getChatUserInfo.pagination.hasNextPage',
    false
  );

  const sortedUserList = userList.sort(helper.unreadMessageSortFunc);
  return (
    <div className={container} data-testid="chat-left-container">
      <div className={menuContainer}>
        <div className={chatSearch}>
          <input
            value={searchText}
            onChange={searchFilterHandler}
            type="text"
            placeholder={t('coachChat.searchPlaceholder')}
            data-testid="search-input-field"
          />
        </div>
      </div>
      <div className={clientSec} data-testid="client-section">
        <h5>
          <Lang path="coachChat.chatLeftHeader" />
        </h5>
        <div ref={filterRef} className={filterSec}>
          <button
            type="button"
            className={ftrIcon}
            onClick={handleFilter}
            data-tooltip={t('coachChat.filterText')}
            data-testid="filter-button"
          >
            <img src={filter} alt="" />
          </button>
          {(coachChatFilters.message || coachChatFilters.activity) && (
            <span className={activeFiltersCount}>
              {(coachChatFilters.message === '' ? 0 : 1) +
                (coachChatFilters.activity === '' ? 0 : 1)}
            </span>
          )}
          {isFiltervisible && (
            <ChatFilter
              selectedMessageFilter={selectedMessageFilter}
              selectedActivityFilter={selectedActivityFilter}
              onSelectMessageFilterHandler={onSelectMessageFilterHandler}
              onSelectActivityFilterHandler={onSelectActivityFilterHandler}
              clearFiltersHandler={clearFiltersHandler}
            />
          )}
        </div>
      </div>

      <ul className={listSec} data-testid="list-section">
        {chatListLoading && !isPaginated && <Spinner />}
        <div data-testid="clients-list">
          {(!chatListLoading || isPaginated) &&
            !chatListError &&
            sortedUserList &&
            sortedUserList.map((data, index) => (
              <UserListItem
                activeUser={activeUser}
                key={data.to}
                user={data}
                setActiveUser={setActiveUser}
                index={index}
              />
            ))}
        </div>
        {(!chatListLoading || isPaginated) && hasNextPage && <Spinner />}
        {!chatListLoading && !chatListError && sortedUserList && hasNextPage && (
          <InView
            as="div"
            onChange={(inView, entry) => {
              if (
                !chatListLoading &&
                hasNextPage &&
                entry &&
                entry.isIntersecting
              ) {
                fetchMoreClients();
              }
            }}
          />
        )}
        {!chatListLoading &&
          !chatListError &&
          !_.isEmpty(sortedUserList) &&
          !hasNextPage &&
          isPaginated && (
            <p className={clientsListEnd}>
              <Lang path="coachChat.chatUserFinishedLoadingText" />
            </p>
          )}
        {chatListError && <ChatClientUsersError retry={chatListRefetch} />}
        {(selectedActivityFilter ||
          selectedMessageFilter ||
          searchText.length > 0) &&
          sortedUserList.length === 0 &&
          !chatListLoading && (
            <div className={noUserFoundContainer}>
              <span
                data-testid="no-user-found-text"
                className={noUserFoundTextStyle}
              >
                <Lang path="coachChat.chatFilterResultEmptyText" />
              </span>
            </div>
          )}
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    sessionExpired: { isSessionExpired }
  } = state;
  return { isSessionExpired };
};

ChatLeft.propTypes = {
  auth0Id: propTypes.string,
  isSessionExpired: propTypes.bool,
  setSelectedUser: propTypes.func,
  setChatListError: propTypes.func,
  setChatListEmpty: propTypes.func,
  setActiveScreens: propTypes.func,
  queryParams: propTypes.shape({ user: propTypes.string })
};

ChatLeft.defaultProps = {
  auth0Id: '',
  isSessionExpired: false,
  setSelectedUser: () => {},
  setChatListError: () => {},
  setChatListEmpty: () => {},
  setActiveScreens: () => {},
  queryParams: {}
};

export default withAuth(connect(mapStateToProps)(ChatLeft));
