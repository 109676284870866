import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { keyboardShown, keyboardClosed } from 'store/actions/KeyboardAction';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNamesInstance from 'classnames/bind';
import SendIcon from 'assets/icons/send.svg';
import analytics from 'utils/analytics/analytics';
import helpers from 'utils/helpers';
import { getBowserParser } from 'utils/bowser';
import styles from './Input.module.scss';

const { inputStyle, inputContainer, sendButtonStyle } = styles;
const { isMobileWeb, isIos, isBrowser, isAndroid } = helpers.platformInfo;

const classNames = classNamesInstance.bind(styles);

const Input = ({ sendMessage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const textAreaRef = useRef(null);
  const [message, setMessage] = useState('');

  const { isOpen: isKeyboardOpen } = useSelector((state) =>
    get(state, 'keyboard')
  );

  // Auto grow chat input with text
  useEffect(() => {
    textAreaRef.current.style.height = '';
    const maxHeight = 50;
    const { scrollHeight } = textAreaRef.current;
    textAreaRef.current.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
  }, [message]);

  const _handleChange = (e) => {
    const msg = e.target.value;
    setMessage(msg);
  };

  const _handleClick = () => {
    if (message) {
      sendMessage(message.trim());
      setMessage('');
      analytics.track('coach chat window ', 'clicked send button');
    }
  };

  const _handleKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey && !isKeyboardOpen) {
      sendMessage(message.trim());
      setMessage('');
      e.preventDefault();
      analytics.track('coach chat window ', 'clicked enter key');
    }
  };

  // Handle safari mobile web
  useEffect(() => {
    textAreaRef.current.onfocus = () => {
      // To handle chat input position when keyboard is open
      // Only required for safari browser in mobile web
      const {
        parsedResult: { browser }
      } = getBowserParser();
      if (isMobileWeb && isIos && browser.name === 'Safari') {
        // wait for keyboard to be completely visible
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        }, 500);
      }
    };
  });

  // Handle keyboard show/hide events on safari mobile web
  const keyboardHandler = (callback) => {
    const {
      parsedResult: { browser }
    } = getBowserParser();
    if (isMobileWeb && isIos && browser.name === 'Safari') {
      callback();
    }
  };

  const containerClass = classNames({
    container: true,
    touchScreenStyle: !helpers.platformInfo.isDesktop,
    desktopStyle: helpers.platformInfo.isDesktop,
    tabStyle: !isBrowser && isIos && !isKeyboardOpen,
    browserTabStyle: (isBrowser || isAndroid) && !isKeyboardOpen,
    tabWithKeyboardStyle: isKeyboardOpen
  });

  return (
    <div className={containerClass}>
      <div className={inputContainer}>
        <textarea
          data-testid="chat-input"
          ref={textAreaRef}
          wrap="soft"
          rows={1}
          name="message"
          value={message}
          onChange={_handleChange}
          onKeyDown={_handleKeyDown}
          onFocus={() => keyboardHandler(() => dispatch(keyboardShown()))}
          onBlur={() =>
            keyboardHandler(() =>
              setTimeout(() => dispatch(keyboardClosed()), 500)
            )
          }
          className={inputStyle}
          placeholder={t('clientChatInputPlaceholder')}
          autoComplete="off"
        />
        <div>
          <button
            data-testid="send-chat"
            type="button"
            className={sendButtonStyle}
            onClick={_handleClick}
          >
            <img src={SendIcon} alt={t('alternateText.button.send')} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Input;
