/**
 *
 * Textcoaching
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// components
import { maximizeChatWindow } from 'store/actions/ChatWindowAction';
import { startAutoCoachAssign } from 'store/actions/dashboard/CoachRequestAction';
import coachRequestStatusTypes from 'assets/data/dashboard/coachRequestStatusTypes';
import bigCardImg from 'assets/img/dashboard/actionplan/text.jpg';
import smallCardImg from 'assets/img/dashboard/actionplan/textsmall.png';
import Lang from '../../../../components/Lang';
import ActionPlanCardBig from '../ActionPlanCardBig';
import {
  LoaderSmall,
  LoaderLarge
} from '../ActionPlan/components/ActionPlanCardSkeleton';
// actions
// helpers
import { chatNowHandler, requestCoach } from '../../helpers';
import ActionPlanCardSmall from '../ActionPlanCardSmall';
// styles
import styles from './TextCoaching.module.scss';
// assets

const { textStyle } = styles;
class TextCoaching extends Component {
  _handleRequestClick = () => {
    const { goalId, severity, eapName, reports } = this.props;
    requestCoach({
      goalId,
      severity,
      analyticsMessage: 'clicked request coach',
      eapName,
      report: reports[0]
    });
  };

  _handleChatNowClick = () => {
    const { goalId, severity, coachRequestStatus } = this.props;
    chatNowHandler({
      goalId,
      severity,
      analyticsMessage: 'Clicked chat now',
      coachRequestStatus
    });
  };

  render() {
    const {
      type,
      index,
      bigCardText,
      coachRequestStatus,
      isFetchingRequestStatus,
      errorInRequestStatus,
      textSessionCount
    } = this.props;

    const LoaderComponent = type === 'large' ? LoaderLarge : LoaderSmall;

    if (isFetchingRequestStatus) {
      return <LoaderComponent />;
    }

    let onClickHandler = () => {};
    let buttonText = '';
    let buttonId = '';
    if (coachRequestStatus === coachRequestStatusTypes.none) {
      onClickHandler = this._handleRequestClick;
      buttonText = <Lang path="textCoachingCard.buttonRequestText" />;
      buttonId = 'request-a-coach-button';
    } else if (coachRequestStatus === coachRequestStatusTypes.assigned) {
      onClickHandler = this._handleChatNowClick;
      buttonText = <Lang path="textCoachingCard.buttonChatText" />;
      buttonId = 'dashboard-chat-now-btn';
    }

    const componentProps = {
      header: (
        <Lang
          path="textCoachingCard.headerText"
          values={{ index: index + 1 }}
        />
      ),
      categoryCount: textSessionCount,
      categoryUnit: <Lang path="textCoachingCard.unitText" />,
      showButton: !!buttonText,
      onClick: onClickHandler,
      buttonId,
      buttonText,
      showError: errorInRequestStatus,
      isFetching: isFetchingRequestStatus,
      cardId: 'text-coaching'
    };

    if (index > 0) {
      componentProps.buttonType = 'secondary';
    }

    if (coachRequestStatus === coachRequestStatusTypes.requested) {
      componentProps.statusText = (
        <Lang path="textCoachingCard.statusRequestedText" />
      );
    }
    return type === 'large' ? (
      <ActionPlanCardBig
        content={bigCardText}
        imgSrc={bigCardImg}
        {...componentProps}
      />
    ) : (
      <ActionPlanCardSmall imgSrc={smallCardImg} {...componentProps}>
        <p data-testid="text-coaching-description-text" className={textStyle}>
          <Lang path="textCoachingCard.descriptionText" />
        </p>
      </ActionPlanCardSmall>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    requestCoach: {
      coachRequestStatus,
      status: {
        isFetching: isFetchingRequestStatus,
        hasError: errorInRequestStatus
      }
    },
    dashBoard: { goalId, severity, sessionCount },
    reportList: { reports },
    companyDetails: {
      companyDetails: {
        partner: { name: eapName }
      }
    }
  } = state;

  return {
    coachRequestStatus,
    isFetchingRequestStatus,
    errorInRequestStatus,
    goalId,
    severity,
    reports,
    textSessionCount: sessionCount.text[severity],
    eapName
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    maximizeChatWindow: bindActionCreators(maximizeChatWindow, dispatch),
    startAutoCoachAssign: bindActionCreators(startAutoCoachAssign, dispatch)
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TextCoaching);
