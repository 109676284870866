/**
 * Our redux store module
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';

import reduxThunk from 'redux-thunk';

import history from '../utils/history';
// eslint-disable-next-line import/no-cycle
import storage from '../utils/storage';
// eslint-disable-next-line import/no-cycle
import reducersObj from './reducers';
import { RESET_ROOT_STATE } from './actions/types';

// Root reducer to reset the overall state to Initial

const reducers = reducersObj(history);
const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === RESET_ROOT_STATE) {
    Object.keys(state).forEach((key) => {
      storage.removeItem(`persist:${key}`);
    });

    // We save the login Redirect and push data
    // since we don't want to reset it
    const { loginRedirect, pushNotificationData } = state;
    newState = { loginRedirect, pushNotificationData };
  }
  return reducers(newState, action);
};

// List of middlewares
const middleWares = [reduxThunk];

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['form', 'modal', 'media']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Compose enhancers to enable redux dev tools and fallback to compose in case they aren't available
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(...middleWares))
);
export default store;
export const persistor = persistStore(store);
export const { dispatch } = store;
