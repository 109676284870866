/**
 *
 * Request Coach
 *
 */

import React from 'react';
// Components
import { Alert } from 'antd';
import coachImg from 'assets/img/dashboard/coach.png';
import { ActionButton } from '../../../../../components/ui/Buttons';
import Lang from '../../../../../components/Lang';
// styles
import style from './RequestCoach.module.scss';
// assets

const RequestCoach = ({ onClick, isFetching, hasError }) => (
  <div>
    <div className={style.topSection}>
      <h2>
        <Lang path="popups.coachingHeader" />
      </h2>
      <p>
        <Lang path="coachRequestText" />
      </p>
      <div className={style.midImg}>
        <img src={coachImg} alt="" />
      </div>
    </div>
    <div className={style.btmSection}>
      <ActionButton
        onClick={() => onClick()}
        isLoading={isFetching}
        className={style.requestButton}
      >
        <Lang path="coachRequestButtonText" />
      </ActionButton>
      {hasError && (
        <Alert
          className={style.errorMsg}
          type="error"
          showIcon
          message={<Lang path="coachRequestError" />}
        />
      )}
    </div>
  </div>
);

RequestCoach.propTypes = {};

export default RequestCoach;
