import queries from './queries/queries';
import mutations from './mutations/mutations';
import mutationOptions from './mutations/mutation-options';
import subscriptions from './subscriptions/subscriptions';
// eslint-disable-next-line import/no-cycle
import functions from './functions';
import { FETCH_POLICIES } from './graphQL-config';

export {
  queries,
  mutations,
  mutationOptions,
  subscriptions,
  functions,
  FETCH_POLICIES
};
