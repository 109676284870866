// core
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import uuid from 'uuid/v4';
import { isEmpty } from 'lodash';
import propTypes from 'prop-types';
import { Col, Row } from 'antd';
// components
import Checklist from 'components/Checklist';
import CustomInput from 'components/CustomInput';
import RenderHtml from 'components/RenderHtml';
import Tabs from './partials/Tabs';
// styles
import styles from './Behavior.module.scss';
// utils
import {
  onCancelHandler,
  onAddHandler,
  onSelectHandler,
  onSaveCustomOptionHandler,
  behaviorDataHandler,
  validateScreenData,
  validateCustomInput,
  getBehaviorData,
  behaviorTabIconMap,
  onChangeTabHandler
} from './Behavior.utils';

const {
  container,
  subheaderStyle,
  descriptionStyle,
  checklistContainer,
  wrapper
} = styles;

const Behavior = ({
  screenContent,
  renderError,
  setScreenProgress,
  currentSelectedOptions,
  autoScrollRef,
  scrollToTopRef
}) => {
  const { subheader, descriptionV2, checklistScreenId } = screenContent;

  const urlParams = useParams();
  const [currentProgress, setCurrentProgress] = useState([]);
  const [currentBehaviorData, setCurrentBehaviorData] = useState({});

  useEffect(() => {
    const progress = currentSelectedOptions || [];
    setCurrentProgress(progress);
  }, [currentSelectedOptions]);

  useEffect(() => {
    setScreenProgress({ id: checklistScreenId, options: currentProgress });
  }, [currentProgress, setScreenProgress, checklistScreenId]);

  useEffect(() => {
    behaviorDataHandler({
      data: getBehaviorData(screenContent),
      currentSelectedOptions,
      setCurrentBehaviorData
    });
  }, [currentSelectedOptions, screenContent]);

  const renderChecklist = ({
    id,
    behavior,
    customOptionPlaceholder,
    allowCustom,
    options
  }) => (
    <div
      data-testid={`${behavior}-behavior-container`}
      className={checklistContainer}
    >
      <Row>
        {options.map((option, index) => (
          <Col key={option.optionId} md={12} lg={12} xs={24}>
            <Checklist
              index={index}
              id={option.optionId}
              text={option.text}
              isSelected={
                !!(
                  currentProgress &&
                  currentProgress.find(
                    (selectedOptions) =>
                      selectedOptions.optionId === option.optionId &&
                      selectedOptions.isSelected === true
                  )
                )
              }
              onSelect={({ isSelected, optionId, text }) =>
                onSelectHandler({
                  ...urlParams,
                  behavior,
                  isSelected,
                  option: { optionId, text },
                  currentProgress,
                  setCurrentProgress
                })
              }
            />
          </Col>
        ))}
        {allowCustom && (
          <Col md={12} lg={12} xs={24}>
            <CustomInput
              id={id}
              data-testid={id}
              testId={`${behavior}-behavior`}
              customValidator={(text) => validateCustomInput({ text, options })}
              customOptionPlaceholder={customOptionPlaceholder}
              onClickAdd={() =>
                onAddHandler({
                  ...urlParams,
                  behavior
                })
              }
              onClickSave={(text) =>
                onSaveCustomOptionHandler({
                  ...urlParams,
                  behavior,
                  option: { optionId: uuid(), text },
                  currentProgress,
                  currentBehaviorData,
                  setCurrentProgress,
                  setCurrentBehaviorData
                })
              }
              onClickCancel={() =>
                onCancelHandler({
                  ...urlParams,
                  behavior
                })
              }
            />
          </Col>
        )}
      </Row>
      <div ref={autoScrollRef} />
    </div>
  );

  const isValid = validateScreenData({
    screenContent,
    descriptionV2,
    subheader
  });

  if (!isValid) return renderError('selfUse.session.error.screenErrorMessage');
  return (
    <div data-testid="behavior-screen" className={wrapper} ref={scrollToTopRef}>
      <div className={container}>
        <Row>
          <Col md={12} lg={12} xs={24}>
            <h2 data-testid="behavior-subheader" className={subheaderStyle}>
              {subheader}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={24} xs={24}>
            <p data-testid="behavior-description" className={descriptionStyle}>
              <RenderHtml>{descriptionV2.html}</RenderHtml>
            </p>
          </Col>
        </Row>
      </div>
      <Tabs
        onChange={(tab) => onChangeTabHandler({ ...urlParams, tab })}
        data={Object.keys(currentBehaviorData).map((behavior) => {
          if (isEmpty(currentBehaviorData[behavior])) return null;
          const { customOptionPlaceholder, options, allowCustom } =
            currentBehaviorData[behavior];
          const { icons, text } = behaviorTabIconMap[behavior];
          return {
            tabs: {
              activeIcon: <img src={icons.active} alt="" />,
              inactiveIcon: <img src={icons.inactive} alt="" />,
              text
            },
            content: renderChecklist({
              id: checklistScreenId,
              behavior,
              customOptionPlaceholder,
              allowCustom,
              options
            })
          };
        })}
      />
    </div>
  );
};

Behavior.propTypes = {
  screenContent: propTypes.shape({
    checklist: propTypes.arrayOf(propTypes.shape({})),
    subheader: propTypes.string,
    descriptionV2: propTypes.shape({
      html: propTypes.string
    })
  }),
  renderError: propTypes.func,
  setScreenProgress: propTypes.func,
  currentSelectedOptions: propTypes.arrayOf(propTypes.shape({}))
};

Behavior.defaultProps = {
  screenContent: {},
  renderError: null,
  setScreenProgress: () => null,
  currentSelectedOptions: null
};

export default Behavior;
