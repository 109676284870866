/**
 * hoc that shows full screen assessment in case user tries to access my plan
 */

// core
import React, { Component } from 'react';
// utils
import featureService from 'utils/feature-service';
import helpers from '../../utils/helpers';
import logger from '../../utils/logger';

const checkAssessmentCompleted = (WrappedComponent) => {
  class decWithAuth extends Component {
    constructor(props) {
      super(props);
      const lastReportId = helpers.getLastReportId();
      let canAccess = true;
      if (!featureService._hasIntelliPlatformFeature()) {
        canAccess = false;
        logger.info(
          'User does not have intelliPlatform feature. Redirecting to home url',
          'checkAssessmentCompleted.constructor'
        );
        this.props.history.push(helpers.homeUrl());
      } else if (!lastReportId) {
        canAccess = false;
        logger.info(
          'User has not taken the assessment. Redirecting to conversational assessment',
          'checkAssessmentCompleted.constructor'
        );
        this.props.history.push('/assessment/conversational');
      }
      this.state = {
        canAccess
      };
    }

    render() {
      return this.state.canAccess && <WrappedComponent {...this.props} />;
    }
  }

  return decWithAuth;
};

export default checkAssessmentCompleted;
