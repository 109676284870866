/**
 * Reducer to manage the state of fetching the report history list
 */

import _ from 'lodash';
import {
  FETCH_REPORT_LIST,
  FETCH_REPORT_LIST_DONE,
  FETCH_REPORT_LIST_ERROR,
  PROCESS_REPORT_TO_LIST
} from '../actions/types';

const INITIAL_STATE = {
  isFetching: false,
  reports: [],
  hasError: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_REPORT_LIST:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_REPORT_LIST_DONE: {
      const sortedReports = _.orderBy(action.data, ['timestamp'], ['desc']);
      return {
        ...state,
        isFetching: false,
        hasError: false,
        reports: sortedReports
      };
    }
    case FETCH_REPORT_LIST_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true
      };
    case PROCESS_REPORT_TO_LIST: {
      const {
        _id,
        assessmentName,
        assessmentNameLocalized,
        timestamp,
        assessmentVersion,
        loginId,
        categories,
        suicidal
      } = action.data;
      return {
        ...state,
        reports: [
          {
            _id,
            categories,
            assessmentName,
            assessmentNameLocalized,
            timestamp,
            assessmentVersion,
            loginId,
            suicidal
          },
          ...state.reports
        ]
      };
    }
    default:
      return state;
  }
};
