/**
 *
 * FullPageLoader
 *
 */

import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'components/LoadingSpinner';
import helpers from 'utils/helpers';
import config from 'config';
import styles from './FullPageLoader.module.scss';

const { appName } = config;

const { getCompanyLogo } = helpers;
const FullPageLoader = ({ loaderText }) => {
  const { t } = useTranslation();
  const companyDetails = useSelector((state) =>
    _.get(state, 'companyDetails.companyDetails', null)
  );
  return (
    <div className={styles.loaderOuter}>
      <img
        className={styles.imgBox}
        src={getCompanyLogo(companyDetails)}
        alt={t('alternateText.image.logo', { appName })}
      />
      <div className={styles.loaderBox}>
        <LoadingSpinner testId="full-page-loader" />
      </div>
      <p className={styles.loaderText}>{loaderText}</p>
    </div>
  );
};

export default FullPageLoader;
