import React, { useEffect, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import classNamesInstance from 'classnames/bind';
import withAuth from 'hoc/withAuth';
import helpers from 'utils/helpers';
import history from 'utils/history';
import coachRequestStatusTypes from 'assets/data/dashboard/coachRequestStatusTypes';
import CoachInfo from '../../components/CoachInfo/CoachInfo';
import styles from './ClientChat.module.scss';
import ChatWindow from './components/ChatWindow';

const classNames = classNamesInstance.bind(styles);

const ClientChat = ({
  coachRequestStatus,
  eapName,
  reports,
  autoCoachInitiated,
  autoCoachError,
  coachDetails
}) => {
  useEffect(() => {
    if (
      !helpers.canShowCoachChat(coachRequestStatus) &&
      autoCoachInitiated === false &&
      !autoCoachError
    ) {
      const homeUrl = helpers.homeUrl();
      history.push(homeUrl);
    }
  }, [coachRequestStatus, autoCoachInitiated, autoCoachError]);
  const categories = _.get(reports, '[0].categories', {});
  const _renderChat = () => {
    if (coachRequestStatus === coachRequestStatusTypes.assigned) {
      return (
        <div
          data-testid="chat-container"
          className={classNames({
            container: true,
            containerDesktopHeight: helpers.platformInfo.isDesktop
          })}
        >
          <ChatWindow />
        </div>
      );
    }
    return (
      <div
        className={classNames({
          container: true,
          alignCenter: true,
          containerDesktopHeight: helpers.platformInfo.isDesktop
        })}
      >
        <CoachInfo
          details={coachDetails}
          autoCoachInitiated={autoCoachInitiated}
          autoCoachError={autoCoachError}
          eapName={eapName}
          report={categories}
        />
      </div>
    );
  };

  return <Fragment>{_renderChat()}</Fragment>;
};

const mapStateToProps = (state) => {
  const {
    requestCoach: {
      coachRequestStatus,
      autoCoach: { isFetching: autoCoachInitiated, hasError: autoCoachError },
      coachDetails
    },
    companyDetails: {
      companyDetails: {
        partner: { name: eapName }
      }
    },
    reportList: { reports }
  } = state;
  return {
    coachRequestStatus,
    autoCoachInitiated,
    autoCoachError,
    coachDetails,
    eapName,
    reports
  };
};

export default withAuth(connect(mapStateToProps)(ClientChat));
