/**
 * main index file that renders the app
 */
import React from 'react';
import { connect } from 'react-redux';
// components
import Error from 'components/Error';
import Lang from 'components/Lang';
import Navigation from 'containers/Navigation';
import FullPageLoader from 'components/FullPageLoader';
import { ActionButton, OutlineButton } from 'components/ui/Buttons';
// utils
import logger from 'utils/logger';
import history from 'utils/history';
import { isLoggedIn } from 'utils/auth';
// containers
import { cleanUpAndLogout } from 'containers/Logout/Logout.utils';
import { reasons } from 'containers/Logout/Logout.data';

import config from './config';

const { supportEmail } = config;
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorInfo: null, showLoader: false };
  }

  redirectToTarget = () => {
    this.setState({
      errorInfo: null
    });
    history.push('/');
  };

  logoutHandler = () => {
    logger.error(
      'User got stuck on error boundary page, clearing the cache and logging out the user',
      'Errorboundary.redirectToTarget',
      { error: this.state.errorInfo }
    );
    this.setState({
      errorInfo: null,
      showLoader: true
    });
    const reason = {
      actions: reasons.default.action
    };
    const { pushNotificationToken } = this.props;
    cleanUpAndLogout({ reason, reasons, pushNotificationToken });
  };

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      errorInfo
    });
    const location = window.location.href;
    // You can also log error messages to an error reporting service here
    logger.error(`${error.message}`, 'ErrorBoundary.componentDidCatch', {
      error,
      location
    });
  }

  render() {
    const footerContent = [
      <ActionButton
        testId="home-button"
        onClick={() => this.redirectToTarget()}
      >
        <Lang path="errorBoundaryHomeButtonText" />
      </ActionButton>
    ];

    if (isLoggedIn()) {
      footerContent.push(
        <OutlineButton
          testId="logout-button"
          onClick={() => this.logoutHandler()}
        >
          <Lang path="errorBoundaryLogoutButtonText" />
        </OutlineButton>
      );
    }

    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          {this.state.showLoader && (
            <FullPageLoader
              loaderText={<Lang path="logoutReasons.errorText" />}
            />
          )}
          <Navigation />
          <Error
            message={<Lang path="errorBoundaryMessage" />}
            content={
              <React.Fragment>
                <Lang
                  path="errorBoundaryContent"
                  values={{
                    supportEmail
                  }}
                />
              </React.Fragment>
            }
            footer={footerContent}
          />
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
const mapStateToProps = (state) => {
  const {
    pushNotificationData: { token: pushNotificationToken }
  } = state;
  return {
    pushNotificationToken
  };
};
export default connect(mapStateToProps)(ErrorBoundary);
