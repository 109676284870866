import { get, pick, defaults } from 'lodash';

// assets
import screenTypes from 'assets/data/self-use/screenTypes';

export const getTrackerContent = (sessionData) => {
  const screens = get(sessionData, 'getSession.sessions[0].screens', []);
  const trackerScreen = screens.find(
    (screen) => screen.type === screenTypes.tracker
  );
  const trackerContent = get(trackerScreen, 'content[0]', {});
  return defaults(
    pick(trackerContent, [
      'emoticon',
      'subheader',
      'description',
      'noProgressText'
    ]),
    { emoticon: [], subheader: '', description: '', noProgressText: '' }
  );
};
