/**
 * Assessment Actions and Action Creators
 */

import api from '../../api/assessment';

import {
  FETCH_QUESTIONS_AND_INTERSTITIAL,
  FETCH_QUESTIONS_AND_INTERSTITIAL_DONE,
  FETCH_QUESTIONS_AND_INTERSTITIAL_ERROR,
  RESET_ASSESSMENT,
  HIDE_STARTING_INTERSTITIAL,
  SET_ASSESSMENT_PROGRESS_ID
} from './types';

import logger from '../../utils/logger';
import handleUnexpectedError from '../helper';

const fetchQuestionsStart = () => ({ type: FETCH_QUESTIONS_AND_INTERSTITIAL });

const fetchQuestionsDone = (data) => ({
  type: FETCH_QUESTIONS_AND_INTERSTITIAL_DONE,
  data
});

const setProgressId = (val) => ({ type: SET_ASSESSMENT_PROGRESS_ID, val });

const fetchQuestionsError = () => ({
  type: FETCH_QUESTIONS_AND_INTERSTITIAL_ERROR
});

// Takes a value of true or false to toggle the starting interstitial
export const hideStartingInterstitial = (val) => ({
  type: HIDE_STARTING_INTERSTITIAL,
  val
});

export const fetchProgressId = (assessmentId) => (dispatch) =>
  api
    .getProgressId(assessmentId)
    .then((resp) => {
      if (resp.status === 200) {
        dispatch(setProgressId(resp.data.progressId));
      } else {
        logger.error(
          'An unexpected error occurred while fetching the progress id',
          'AssessmentAction.getProgressId',
          { resp }
        );
      }
    })
    .catch((err) => {
      handleUnexpectedError(
        'An error occurred while fetching the progress id',
        'AssessmentAction.getProgressId',
        err
      );
    });

export const saveAssessmentsProgress = (
  responses,
  progressId,
  isCompleted,
  state
) => () =>
  api
    .saveAssessmentsProgress(responses, progressId, isCompleted, state)
    .then((resp) => {
      if (resp.status !== 204) {
        logger.error(
          'An error occurred while saving assessment progress',
          'AssessmentAction.saveAssessmentsProgress',
          { resp }
        );
      }
    })
    .catch((err) => {
      handleUnexpectedError(
        'An unexpected error occurred while saving assessment progress',
        'AssessmentAction.saveAssessmentsProgress',
        err
      );
    });

export default () => (dispatch) => {
  dispatch(fetchQuestionsStart());
  return api
    .fetchQuestions()
    .then((resp) => {
      if (resp.status === 200) {
        dispatch(fetchQuestionsDone(resp.data));
      } else {
        logger.error(
          'An error occurred while fetching the assessment',
          'AssessmentAction.default',
          { resp }
        );
        dispatch(fetchQuestionsError());
      }
    })
    .catch((err) => {
      handleUnexpectedError(
        'An unexpected error occurred while fetching the assessment',
        'AssessmentAction.default',
        err
      );
      dispatch(fetchQuestionsError());
    });
};

// Resets the assessment and the responses for the assessment
export const resetAssessment = () => ({ type: RESET_ASSESSMENT });
