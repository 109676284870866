import api from '../../api/partner';
import logger from '../../utils/logger';
import {
  GET_COMPANY_DETAILS,
  GET_COMPANY_DETAILS_DONE,
  GET_COMPANY_DETAILS_ERROR
} from './types';

const getCompanyDetailsStart = () => ({ type: GET_COMPANY_DETAILS });

const getCompanyDetailsDone = (companyData) => ({
  type: GET_COMPANY_DETAILS_DONE,
  payload: companyData
});

const getCompanyDetailsError = () => ({ type: GET_COMPANY_DETAILS_ERROR });

const getCompanyDetails = (companyName) => (dispatch) => {
  dispatch(getCompanyDetailsStart());
  return api
    .getCompanyDetails(companyName)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getCompanyDetailsDone(res.data));
      } else {
        dispatch(getCompanyDetailsError());
        logger.error(
          'An error occurred while getting company details.',
          'CompanyDetailAction.default',
          { res }
        );
      }
    })
    .catch((err) => {
      dispatch(getCompanyDetailsError());
      logger.error(
        'An unexpected error occurred while get company details.',
        'CompanyDetailAction.default',
        { err }
      );
    });
};

export default getCompanyDetails;
