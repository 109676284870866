// core
import React from 'react';
import PropTypes from 'prop-types';
// styles
// assets
import goalIconList, { goalIcon } from 'assets/data/goalIconList';
// utils
import featureService from 'utils/feature-service';
import styles from './FocusAreaCard.module.scss';
// components
import CoachCard from './components/CoachCard';
import GoalDescription from './components/GoalDescription';

const {
  container,
  categoryContentContainer,
  categoryNameStyle,
  categoryImage
} = styles;

const FocusAreaCard = ({ categoryName, categoryId, bigCardText }) => (
  <div className={container}>
    <div className={categoryContentContainer}>
      <img
        className={categoryImage}
        src={`${goalIconList[categoryId]}` || goalIcon}
        alt={categoryName}
        width="35"
      />
      <h2 data-testid="goal-name" className={categoryNameStyle}>
        {categoryName}
      </h2>
    </div>
    {featureService._hasCoachAccessFeature() ? (
      <CoachCard />
    ) : (
      <GoalDescription bigCardText={bigCardText} />
    )}
  </div>
);
FocusAreaCard.propTypes = {
  categoryName: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  categoryId: PropTypes.string.isRequired
};
export default FocusAreaCard;
