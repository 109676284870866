import React from 'react';
import { useTranslation } from 'react-i18next';
import mdLiveLogo from 'assets/img/dashboard/videotherapy/mdlive_logo.png';
import Lang from '../../../../../components/Lang';

import styles from './ProvidedByMDLive.module.scss';

const { providedByMDLiveContainer, providedByTextStyle, mdLiveLogoStyle } =
  styles;

const ProvidedByMDLive = () => {
  const { t } = useTranslation();
  return (
    <div data-testid="mdlive-logo" className={providedByMDLiveContainer}>
      <span className={providedByTextStyle}>
        <Lang path="therapyRequest.form.providedByText" />:
      </span>
      <img
        src={mdLiveLogo}
        alt={t('alternateText.image.mdLive')}
        className={mdLiveLogoStyle}
      />
    </div>
  );
};

export default ProvidedByMDLive;
