// core
import React, { useState, useEffect } from 'react';
import {
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { useNetworkListener } from 'utils/hooks';

// hocs
import tabs from 'components/TabMenu/TabMenu.data';
import helpers from 'utils/helpers';
import { tabChangeHandler } from 'components/TabMenu/TabMenu.utils';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import classNames from 'classnames';

import routes from './tab-menu-routes';
import styles from './TabMenu.module.scss';

const { ionTabButtonStyle, tabTextStyle, tabTextActiveStyle, tabBarContainer } =
  styles;

const TabMenu = () => {
  const location = useLocation();
  const [isOnline] = useNetworkListener();
  const [showTabs, setShowTabs] = useState(false);
  const coachRequestStatus = useSelector((state) =>
    get(state, 'requestCoach.coachRequestStatus', null)
  );
  const reports = useSelector((state) => get(state, 'reportList.reports', []));

  useEffect(() => {
    setShowTabs(helpers.canShowTabs() && isOnline);
  }, [location, isOnline]);

  // We need nested routes due to multiple home tabs i.e Goal and My plan
  const nestedRoutes = !helpers.platformInfo.isDesktop ? routes : <></>;
  return showTabs ? (
    <IonTabs onIonTabsDidChange={tabChangeHandler}>
      <IonRouterOutlet animated={false}>
        <>{routes}</>
      </IonRouterOutlet>

      <IonTabBar className={tabBarContainer} slot="bottom">
        {tabs.map((tab) => {
          if (!tab.isVisible({ coachRequestStatus, reports })) return null;
          const isActivePath = helpers.isActivePath(tab.activePath);
          const textStyle = classNames({
            [tabTextStyle]: true,
            [tabTextActiveStyle]: isActivePath
          });
          return (
            <IonTabButton
              key={tab.id}
              tab={tab.id}
              href={`/${tab.route({ coachRequestStatus })}`}
              className={ionTabButtonStyle}
            >
              <IonIcon src={isActivePath ? tab.activeIcon : tab.inactiveIcon} />
              <p className={textStyle}>{tab.text}</p>
            </IonTabButton>
          );
        })}
      </IonTabBar>
    </IonTabs>
  ) : (
    nestedRoutes
  );
};

export default TabMenu;
