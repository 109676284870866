// Remove auth0ID for the device on logout
import {
  getDeviceId,
  getNotificationProvider,
  getPlatform
} from 'utils/capacitor';
import { isEmpty } from 'lodash';
import logger from 'utils/logger/logger';
import notification from 'api/notification';
import { getAuth0Id, isLoggedIn } from 'utils/auth';

export const getPushData = ({ pushToken, hasLogout }) => {
  const notificationProvider = getNotificationProvider();
  const platform = getPlatform();
  const deviceId = getDeviceId();

  if (isEmpty(deviceId)) {
    logger.error('Device ID is empty', 'push.getPushData');
    return null;
  }

  if (isEmpty(pushToken)) {
    logger.error(
      'Push notification token is not available',
      'push.getPushData'
    );
    return null;
  }

  if (isEmpty(notificationProvider)) {
    logger.error(
      'Notification provider is not available, unsupported platform',
      'push.getPushData',
      { platform }
    );
    return null;
  }
  const pushData = {
    token: pushToken,
    deviceId: getDeviceId(),
    notificationProvider
  };

  if (isLoggedIn() && !hasLogout) {
    logger.info(
      'Registering device for push notification, User has logged in',
      'push.getPushData'
    );
    pushData.auth0Id = getAuth0Id();
  } else {
    logger.info(
      "Registering device for push notification, User hasn't logged in",
      'push.getPushData'
    );
  }

  return pushData;
};

export const updatePushRegistration = async ({
  pushNotificationToken,
  hasLogout
}) => {
  const pushData = getPushData({ pushToken: pushNotificationToken, hasLogout });
  if (!isEmpty(pushData)) {
    await notification.registerDevice(pushData);
  } else {
    logger.error('Push data is empty', 'push.updatePushRegistration');
  }
};
