import userApi from 'api/user';
import { reset } from 'redux-form';
import { dispatch } from 'store';
import toast from 'components/Toast';
import logger from 'utils/logger';
import history from 'utils/history';
import analytics from 'utils/analytics';
import eventCategories from 'utils/analytics/categories';
import i18n from 'assets/lang';

const errorTypeMap = {
  passwordhistoryerror: i18n.t('account.error.samePasswordError'),
  inexistent_connection: i18n.t('account.error.samlConnectionError'),
  passwordmismatch: i18n.t('account.error.incorrectCurrentPassword')
};

const isValid = ({ newPassword, confirmedPassword }) => {
  if (newPassword.toLowerCase() !== confirmedPassword.toLowerCase()) {
    return false;
  }
  return true;
};

export const backButtonHandler = () => {
  analytics.track(
    eventCategories.PASSWORD,
    'clicked change password back button'
  );
  history.goBack();
};

export const changePasswordHandler = ({
  currentPassword,
  newPassword,
  confirmedPassword,
  setError,
  setIsLoading
}) => {
  analytics.track(
    eventCategories.PASSWORD,
    'clicked on change password button'
  );
  const isValidPassword = isValid({ newPassword, confirmedPassword });
  if (isValidPassword) {
    setIsLoading(true);
    setError('');
    userApi
      .updatePassword({
        currentPassword,
        newPassword: confirmedPassword
      })
      .then((response) => {
        dispatch(reset('password'));
        logger.info(
          'Successfully update the user password.',
          'Password.utils.changePasswordHandler',
          { status: response.status }
        );
        setIsLoading(false);
        toast.dismiss();
        toast.info(i18n.t('passwordChangeSuccess'), null, {
          autoClose: 3000
        });
      })
      .catch((error) => {
        logger.error(
          'Failed to update the user password',
          'Password.utils.changePasswordHandler',
          { error }
        );
        const { response } = error;
        let errorMsg = i18n.t('account.error.changePasswordError');
        setIsLoading(false);
        if (response && response.status === 400) {
          const { message } = response.data;
          const errorType = message.split(':')[0];
          if (errorType && errorTypeMap[errorType.toLowerCase()]) {
            errorMsg = errorTypeMap[errorType.toLowerCase()];
          }
        }
        setError(errorMsg);
      });
  } else {
    setIsLoading(false);
    setError(i18n.t('account.error.passwordMatchError'));
  }
};
