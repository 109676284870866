import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// Components
import { Field, reduxForm } from 'redux-form';
import { Alert, Col, Row } from 'antd';
import validation from 'utils/validation/validation';
import { useScrollToField } from 'utils/hooks';
import {
  TextAreaBlack,
  TextFieldBlack
} from '../../../../../components/ui/Inputs';
import {
  ActionButton,
  UnderlineButton
} from '../../../../../components/ui/Buttons';
import DateTimeField from '../../../../../components/form';
import ProvidedByMDLive from '../ProvidedByMDLive';
import ProvidedByMockVendor from '../ProvidedByMockVendor';
import Lang from '../../../../../components/Lang';
// styles
import styles from './VideoTherapy.module.scss';

const {
  formField,
  requestAppointmentButtonStyle,
  nextButtonContainer,
  notesContainer,
  requestError,
  requestNotesStyle
} = styles;

const VideoTherapyFormSecondPage = ({
  handleSubmit,
  onPrevious,
  isFetching,
  hasError
}) => {
  const accessCode = useSelector((state) =>
    _.get(state, 'companyDetails.companyDetails.aliases[0]')
  );

  const { changeFocusField } = useScrollToField();

  const isAetnaDemo = useMemo(
    () => accessCode === '!!!aetna-demo',
    [accessCode]
  );

  const isAnthemDemo = useMemo(() => accessCode === '114anthem', [accessCode]);

  const isSmallScreen = useMediaQuery('(max-width: 767px)');

  return (
    <form onSubmit={handleSubmit}>
      <h3>
        <Lang path="therapyRequest.form.secondPageHeader" />
      </h3>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 11, offset: 0 }}>
          <Field
            id="appointment-time-1"
            className={formField}
            name="date1"
            component={DateTimeField}
            label={
              <span>
                <Lang
                  path="formCommon.appointmentSlotText"
                  values={{ order: 1 }}
                />
                *
              </span>
            }
            validate={validation.required_day1}
            invalidDateMessage={null}
            disablePast
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ offset: 2, span: 11 }}>
          <Field
            className={formField}
            name="date2"
            component={DateTimeField}
            label={
              <Lang
                path="formCommon.appointmentSlotText"
                values={{ order: 2 }}
              />
            }
            invalidDateMessage={null}
            disablePast
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 11, offset: 0 }}>
          <Field
            className={formField}
            name="date3"
            component={DateTimeField}
            label={
              <Lang
                path="formCommon.appointmentSlotText"
                values={{ order: 3 }}
              />
            }
            invalidDateMessage={null}
            disablePast
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ offset: 2, span: 11 }}>
          <Field
            className={formField}
            name="date4"
            component={DateTimeField}
            label={
              <Lang
                path="formCommon.appointmentSlotText"
                values={{ order: 4 }}
              />
            }
            invalidDateMessage={null}
            disablePast
            onClick={changeFocusField}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Field
            id="healthplan-name"
            name="healthPlanName"
            component={TextFieldBlack}
            type="text"
            className={formField}
            label={<Lang path="therapyRequest.form.healthPlanNameText" />}
            onClick={changeFocusField}
          />
        </Col>
      </Row>
      <Row className={notesContainer}>
        <Col xs={24}>
          <Field
            id="request-notes"
            component={TextAreaBlack}
            label={<Lang path="therapyRequest.form.requestNotesText" />}
            name="notes"
            multiline
            rows="4"
            className={`${formField} ${requestNotesStyle}`}
            onClick={changeFocusField}
          />
        </Col>
      </Row>
      {hasError && (
        <Alert
          className={requestError}
          message={<Lang path="therapyRequest.errorText" />}
          showIcon
          type="error"
        />
      )}
      <Row>
        <Col className={nextButtonContainer}>
          <UnderlineButton onClick={onPrevious}>
            <Lang path="backButtonText" />
          </UnderlineButton>
          {isAetnaDemo && !isSmallScreen && <ProvidedByMDLive />}
          {isAnthemDemo && !isSmallScreen && <ProvidedByMockVendor />}
          <ActionButton
            testId="request-appointment-button"
            isLoading={isFetching}
            className={requestAppointmentButtonStyle}
            onClick={handleSubmit}
          >
            <Lang path="therapyRequest.form.submitButton" />
          </ActionButton>
        </Col>
      </Row>
      {isAetnaDemo && isSmallScreen && (
        <Row>
          <Col>
            <ProvidedByMDLive />
          </Col>
        </Row>
      )}
      {isAnthemDemo && isSmallScreen && (
        <Row>
          <Col>
            <ProvidedByMockVendor />
          </Col>
        </Row>
      )}
    </form>
  );
};

VideoTherapyFormSecondPage.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'videoTherapyRequest',
  destroyOnUnmount: false
})(VideoTherapyFormSecondPage);
