/**
 * Actions and Action Creators related to report processing.
 * These are related to only processing a new report and not for fetching previous reports.
 */

import _ from 'lodash';
import featureService from 'utils/feature-service';
import store from '../index';
import api from '../../api/assessment';
import history from '../../utils/history';

import {
  PROCESS_REPORT,
  PROCESS_REPORT_DONE,
  PROCESS_REPORT_ERROR,
  PROCESS_REPORT_TO_LIST
} from './types';

import logger from '../../utils/logger';

import { resetAssessment } from './AssessmentAction';
import { startAutoCoachAssign } from './dashboard/CoachRequestAction';
import analytics from '../../utils/analytics';
import handleUnexpectedError from '../helper';
import helpers from '../../utils/helpers';
import { minimizeChatWindow } from './ChatWindowAction';

const { transformBySeverity, isCoach } = helpers;

const processReportStart = () => ({ type: PROCESS_REPORT });

const processReportDone = (data) => ({
  type: PROCESS_REPORT_DONE,
  data
});

export const processReportToList = (data) => ({
  type: PROCESS_REPORT_TO_LIST,
  data
});

const processReportError = () => ({ type: PROCESS_REPORT_ERROR });

export default (assessmentId) => (dispatch) => {
  analytics.info('assessment', 'processing started', {
    assessmentId
  });
  const {
    responses,
    requestCoach: { coachRequestStatus }
  } = store.getState();
  dispatch(processReportStart());
  // Convert the response object to match the format required by backend
  const responseObj = _.mapValues(responses, (val) => val.response);

  return api
    .fetchResult(responseObj, assessmentId)
    .then((resp) => {
      if (resp.status === 200) {
        analytics.info('assessment', 'completed', {
          assessmentId
        });

        if (featureService._hasAutoCoachAssignmentOnCompletingSurveyFeature()) {
          logger.info(
            'User has coach_auto_assignment_on_completing_survey feature',
            'ReportProcessAction.default',
            { assessmentId }
          );
          if (!isCoach() && coachRequestStatus === 'NONE') {
            // Call assign coach API
            const {
              companyDetails: { companyDetails }
            } = store.getState();

            dispatch(
              startAutoCoachAssign({
                mode: 'auto',
                eapName: companyDetails.partner.name,
                goals: transformBySeverity(resp.data.categories)
              })
            );
          }

          // Send the data to reducers to add it to list of cached reports
          dispatch(processReportDone(resp.data));
          // Add the newly processed report to the reports list of the user
          dispatch(processReportToList(resp.data));
          dispatch(resetAssessment());
          if (featureService._hasCoachAccessOnWebFeature()) {
            dispatch(minimizeChatWindow());
            history.push('/coach');
            analytics.info(
              'coach chat screen',
              'successfully opened coach chat screen'
            );
          } else {
            history.push(`report/${resp.data._id}`);
          }
        } else {
          // Send the data to reducers to add it to list of cached reports
          dispatch(processReportDone(resp.data));
          // Add the newly processed report to the reports list of the user
          dispatch(processReportToList(resp.data));
          dispatch(resetAssessment());
          // Redirect the user to the report page
          history.push(`report/${resp.data._id}`);
        }
        // and hide the loading indicator
      } else {
        logger.error(
          'An error occurred while processing report',
          'ReportProcessAction.default'
        );
        dispatch(processReportError());
      }
    })
    .catch((err) => {
      handleUnexpectedError(
        'An unexpected error occurred while processing report',
        'ReportProcessAction.default',
        err
      );
      dispatch(processReportError());
    });
};
