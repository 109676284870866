import React from 'react';
import propTypes from 'prop-types';
// components
import Option from '../../StepsType/Option';
// utils
import {
  onAddHandler,
  onCancelHandler,
  onSelectHandler,
  onSaveCustomOptionHandler,
  validateCustomInput
} from './ChecklistContainer.utils';

const ChecklistContainer = ({
  urlParams,
  columns,
  stepId,
  type,
  currentProgress,
  setCurrentProgress,
  stepsData,
  setStepsData
}) => {
  const {
    data: {
      allowCustom,
      customOptionPlaceholder,
      options,
      maxOptions: maxSelectableOptions
    }
  } = stepsData[type];
  return (
    <Option
      columns={columns}
      options={options}
      currentProgress={currentProgress[type]}
      type={type}
      urlParams={urlParams}
      stepId={stepId}
      maxSelectableOptions={maxSelectableOptions}
      allowCustom={allowCustom}
      customOptionPlaceholder={customOptionPlaceholder}
      onAddHandler={() => onAddHandler({ urlParams, type })}
      onCancelHandler={() => onCancelHandler({ urlParams, type })}
      validateCustomInput={({ text }) =>
        validateCustomInput({
          text,
          options,
          maxSelectableOptions,
          type,
          currentProgress
        })
      }
      onSelectHandler={({ isSelected, optionId, text }) =>
        onSelectHandler({
          urlParams,
          stepId,
          isSelected,
          type,
          option: { optionId, text },
          currentProgress,
          setCurrentProgress,
          maxSelectableOptions
        })
      }
      onSaveCustomOptionHandler={({ option }) =>
        onSaveCustomOptionHandler({
          urlParams,
          stepId,
          option,
          currentProgress,
          setCurrentProgress,
          stepsData,
          setStepsData,
          type,
          maxSelectableOptions
        })
      }
    />
  );
};

ChecklistContainer.propTypes = {
  columns: propTypes.number,
  urlParams: propTypes.shape({
    goalId: propTypes.string,
    sessionId: propTypes.string,
    screenId: propTypes.string
  }),
  currentProgress: propTypes.objectOf(
    propTypes.oneOfType([
      propTypes.arrayOf(propTypes.object),
      propTypes.string // __typename
    ])
  ),
  stepsData: propTypes.objectOf(
    propTypes.shape({
      data: propTypes.shape({
        allowCustom: propTypes.bool,
        customOptionPlaceholder: propTypes.string,
        options: propTypes.arrayOf(propTypes.shape({})),
        maxOptions: propTypes.number
      })
    })
  ),
  stepId: propTypes.string,
  type: propTypes.string,
  setCurrentProgress: propTypes.func,
  setStepsData: propTypes.func
};

ChecklistContainer.defaultProps = {
  columns: 24,
  urlParams: {},
  stepId: '',
  type: '',
  currentProgress: {},
  stepsData: {},
  setCurrentProgress: () => null,
  setStepsData: () => null
};

export default ChecklistContainer;
