import { useState, useEffect } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import scrollIntoView from 'scroll-into-view-if-needed';

const scrollFieldIntoView = (focusField, scrollIfNeeded) => {
  const [currentFocusField] = document.getElementsByName(focusField);
  if (currentFocusField) {
    setTimeout(() => {
      scrollIntoView(currentFocusField, {
        scrollMode: scrollIfNeeded ? 'if-needed' : 'always',
        block: 'start',
        behavior: 'smooth'
      });
    }, 1000);
    // waiting for virtual keyboard to open
  }
};

export const useScrollToField = ({ scrollIfNeeded = false } = {}) => {
  const [focusField, setFocusField] = useState('');
  const { isOpen: isKeyboardOpen } = useSelector((state) =>
    get(state, 'keyboard')
  );

  const changeFocusField = (e) => {
    setFocusField(get(e, 'target.name', ''));
  };

  useEffect(() => {
    if (focusField.trim() && isKeyboardOpen) {
      scrollFieldIntoView(focusField, scrollIfNeeded);
    }
  }, [focusField, isKeyboardOpen, scrollIfNeeded]);

  return { changeFocusField };
};
