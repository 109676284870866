import { isEmpty, cloneDeep, debounce } from 'lodash';
import { supportedSteps } from 'assets/data/self-use/thoughtRecorder';
import moment from 'utils/moment';

export const getLastResponse = ({ currentProgress = {} }) => {
  let response = '';
  const responses = currentProgress[supportedSteps.RATIONAL_RESPONSE];
  if (!isEmpty(responses)) {
    response = responses[responses.length - 1].text;
  }
  return response.trim();
};

export const rationalResponseHandler = debounce(
  ({
    stepId,
    referenceId,
    type,
    text,
    currentProgress = {},
    setCurrentProgress
  }) => {
    const progress = cloneDeep(currentProgress) || {};
    const date = moment().locale('en').utc().format();

    if (progress[type]) {
      // Check if there is a record with same reference id
      const responseIndex = progress[type].findIndex(
        (response) => response.id === referenceId
      );
      if (responseIndex === -1) {
        progress[type].push({
          id: referenceId,
          text,
          createdAt: date,
          stepId,
          isCustom: true
        });
      } else {
        progress[type][responseIndex] = {
          ...progress[type][responseIndex],
          text,
          createdAt: date,
          isCustom: true
        };
      }
    } else {
      progress[type] = [
        {
          id: referenceId,
          text,
          createdAt: date,
          stepId,
          isCustom: true
        }
      ];
    }
    setCurrentProgress(progress);
  },
  100
);
