import React from 'react';

import './Ellipsis.scss';

const Ellipsis = () => (
  <div className="lds-ellipsis">
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Ellipsis;
