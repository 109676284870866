import React from 'react';
import propTypes from 'prop-types';

import styles from './FullWidthContainer.module.scss';

const { defaultContainer, defaultContainerTitleStyle } = styles;

const FullWidthContainer = ({
  Component,
  style,
  titleStyle,
  titleId,
  titleText,
  checkFeature,
  refProp,
  containerId,
  ...props
}) =>
  checkFeature() ? (
    <div data-testid={containerId} className={style}>
      {titleText && (
        <div data-testid={titleId} className={titleStyle}>
          {titleText}
        </div>
      )}
      <Component refProp={refProp} {...props} />
    </div>
  ) : null;

FullWidthContainer.propTypes = {
  Component: propTypes.elementType.isRequired,
  style: propTypes.string,
  titleStyle: propTypes.string,
  titleId: propTypes.string,
  titleText: propTypes.oneOfType([propTypes.string, propTypes.element]),
  checkFeature: propTypes.func,
  refProp: propTypes.oneOfType([
    propTypes.shape({
      current: propTypes.oneOfType([propTypes.element, propTypes.oneOf([null])])
    }),
    propTypes.bool
  ]),
  containerId: propTypes.string
  // ignoring typechecking for rest props since ideally
  // child component should typecheck them
};

FullWidthContainer.defaultProps = {
  style: defaultContainer,
  titleStyle: defaultContainerTitleStyle,
  titleId: '',
  titleText: '',
  checkFeature: () => false,
  refProp: false,
  containerId: ''
};

export default FullWidthContainer;
