import React, { Fragment, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';
import { Paper } from '@material-ui/core';
import history from 'utils/history';
import analytics from 'utils/analytics/analytics';
import getWorkLifeRequestQuestions from 'store/actions/dashboard/WorkLifeRequestAction';
import Slider from '../../../../components/Slider';
import { OutlineButton } from '../../../../components/ui/Buttons';
import { LoaderSmall, LoaderLarge } from './WorkLifeRequestCarousel.skeleton';
import topicMap from './topicMap';
import ErrorRetry from '../../../../components/ErrorRetry';
import Lang from '../../../../components/Lang';

// styles
import styles from './WorkLifeRequestCarousel.module.scss';

const {
  cardContainer,
  topicStyle,
  imageStyle,
  buttonStyle,
  topicTextContainer,
  carouselHeaderStyle
} = styles;

const WorkLifeRequestCarousel = () => {
  const dispatch = useDispatch();
  const { workLifeRequest, isError, isFetching } = useSelector(
    (state) => state.workLifeRequest
  );
  const topic = _.get(workLifeRequest, 'questions.topics.replies', []);

  const getQuestions = useCallback(() => {
    dispatch(getWorkLifeRequestQuestions());
  }, [dispatch]);
  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const sliderClass = 'keen-slider__slide';

  const _handleNavigation = useCallback((param) => {
    analytics.track('resources', 'clicked worklife request button', {
      subcategory: param
    });
    history.push({
      pathname: 'resources/work-life-request',
      search: `?type=${param}`
    });
  }, []);
  const LoaderComponent = useMemo(
    () => (isSmallScreen ? LoaderSmall : LoaderLarge),
    [isSmallScreen]
  );

  return isFetching ? (
    <LoaderComponent />
  ) : (
    <>
      <div
        data-testid="work-life-requests-header"
        className={carouselHeaderStyle}
      >
        <Lang path="workLifeRequest.homePageHeaderText" />
      </div>
      {isError && (
        <ErrorRetry
          isFetching={false}
          onClick={getQuestions}
          title={<Lang path="workLifeRequest.carouselErrorText" />}
        />
      )}
      {!_.isEmpty(topic) && !isError && (
        <div data-testid="work-life-requests-carousel">
          <Slider
            loop
            nextArrowId="work-life-requests-carousel-next-arrow-button"
            prevArrowId="work-life-requests-carousel-prev-arrow-button"
            dotsVisible
          >
            {topic.map((value) => {
              const img = topicMap[value[1]];
              const startOfTopicName = value[0].lastIndexOf('</i>');
              const endOfTopicNAme = value[0].length;
              const topicName = value[0]
                .slice(startOfTopicName + 4, endOfTopicNAme)
                .trim();
              const elementId = topicName.toLowerCase().replace(' ', '-');
              return (
                <Fragment key={topicName}>
                  <Paper
                    className={`${cardContainer} ${sliderClass}`}
                    elevation={4}
                  >
                    <img
                      data-testid={`img-${elementId}`}
                      className={imageStyle}
                      src={img}
                      alt={topicName}
                    />
                    <div className={topicTextContainer}>
                      <h3
                        data-testid={`topic-name-${elementId}`}
                        className={topicStyle}
                      >
                        {topicName}
                      </h3>
                      <OutlineButton
                        testId={`request-button-${elementId}`}
                        className={buttonStyle}
                        onClick={() => {
                          _handleNavigation(value[1]);
                        }}
                      >
                        <Lang path="workLifeRequest.requestButton" />
                      </OutlineButton>
                    </div>
                  </Paper>
                </Fragment>
              );
            })}
          </Slider>
        </div>
      )}
    </>
  );
};

export default WorkLifeRequestCarousel;
