/**
 * Wrapper for checkbox for redux-form
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import useStyles from './CheckBoxField.styles';
import { InputError } from '../../Validation';

const CheckBoxField = ({ errorClasses, errorId, ...props }) => {
  const [showError, setShowError] = useState(false);
  const {
    meta: { error, touched }
  } = props;
  useEffect(() => {
    if (touched && error) {
      setShowError(true);
    }
  }, [touched, error]);

  const checkBoxClasses = useStyles();
  return (
    <React.Fragment>
      <FormControlLabel
        style={{ marginRight: 0 }}
        control={
          <Checkbox
            className={checkBoxClasses.root}
            checked={props.input.value || false}
            onChange={(val) => props.input.onChange(val)}
            value={props.input.value}
            checkedIcon={
              <span
                className={clsx(
                  checkBoxClasses.icon,
                  checkBoxClasses.checkedIcon
                )}
              />
            }
            icon={<span className={checkBoxClasses.icon} />}
            {...props}
          />
        }
        label={props.text}
      />
      {showError && (
        <InputError
          classes={errorClasses.join(' ')}
          error={error}
          testId={errorId}
        />
      )}
    </React.Fragment>
  );
};

CheckBoxField.propTypes = {
  meta: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.func]),
  errorClasses: PropTypes.arrayOf(PropTypes.string),
  errorId: PropTypes.string
};

CheckBoxField.defaultProps = {
  meta: {},
  errorClasses: [],
  errorId: null
};

export default CheckBoxField;
