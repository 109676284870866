import axios from 'axios';
import uuid from 'uuid/v1';
import { isEmpty, get, has } from 'lodash';
import adblockerDetection from 'adblock-detect';
import { obfuscateProps } from 'logger-customization';
import logger from '../logger';
import config from '../../config';
import { getAuth0Id } from '../auth';
import moment from '../moment';
import store from '../../store';
import helpers from '../helpers';
import storage from '../storage';
import { isNativePlatform } from '../capacitor';

const {
  analytics: {
    key: ANALYTICS_KEY,
    url: ANALYTICS_API_URL,
    enabled: ENABLED,
    tags: TAGS
  },
  appVersion: APP_VERSION
} = config;
const VERSION = 2;

const sendEvent = (eventData) =>
  axios({
    url: ANALYTICS_API_URL,
    method: 'POST',
    headers: { 'X-API-KEY': ANALYTICS_KEY },
    data: eventData
  }).catch((err) => {
    adblockerDetection((adblockDetected) => {
      if (adblockDetected) {
        logger.info(
          'Unable to send analytics event because adblock is enabled',
          'analytics.sendEvent'
        );
      } else if (get(err, 'message', '') === 'Network Error') {
        logger.info(
          'Unable to send analytics event because of network error',
          'analytics.sendEvent'
        );
      } else {
        logger.error(
          'Error occurred while sending analytics data',
          'analytics.sendEvent',
          {
            err: JSON.parse(JSON.stringify(err))
          }
        );
      }
    });
  });

const removeSessionDetails = async () => {
  await storage.removeItem('analyticsSessionDetails');
};

const getSessionDetails = async () => {
  let analyticsSessionDetails = helpers.getParsedValue(
    await storage.getItem('analyticsSessionDetails')
  );
  if (isEmpty(analyticsSessionDetails)) {
    analyticsSessionDetails = {
      sessionId: uuid(),
      timestamp: Math.floor(Date.now() / 1000)
    };
    await storage.setItem(
      'analyticsSessionDetails',
      isNativePlatform
        ? analyticsSessionDetails
        : JSON.stringify(analyticsSessionDetails)
    );
  }
  return analyticsSessionDetails;
};

const startAnalyticsSession = async () => {
  await removeSessionDetails();
  const { sessionId } = await getSessionDetails();
  return sessionId;
};

const isAnalyticsSessionExpired = async (sessionDetails) => {
  let isExpired = true;
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const analyticsSessionData = sessionDetails;
  if (analyticsSessionData) {
    const timediff = currentTimestamp - analyticsSessionData.timestamp;
    if (timediff < config.sessionTimeout) {
      // Check for 20 minutes session timeout
      isExpired = false;
      analyticsSessionData.timestamp = currentTimestamp;
      await storage.setItem(
        'analyticsSessionDetails',
        isNativePlatform
          ? analyticsSessionData
          : JSON.stringify(analyticsSessionData) // Update expiry time
      );
    }
  }
  return isExpired;
};
class AnalyticsModule {
  static async addEvent(type, category, action, properties) {
    if (ENABLED === 'true') {
      const eventData = {};
      const storeData = store.getState();
      eventData.type = type;
      eventData.category = category;
      eventData.action = action;

      if (!isEmpty(properties)) {
        const modifiedProperties = properties;
        if (has(properties, 'trigger')) {
          eventData.trigger = properties.trigger;
          delete modifiedProperties.trigger;
        }
        eventData.properties = obfuscateProps(modifiedProperties);
      }

      eventData.context = {};

      if (window) {
        if (window.navigator && window.navigator.userAgent) {
          eventData.context.userAgent = window.navigator.userAgent;
        }

        eventData.context.page = {};
        eventData.context.page.path = window.location.pathname;
        eventData.context.page.url = window.location.href;
      }

      const userId = getAuth0Id();
      if (userId) {
        eventData.userId = userId;
        eventData.companyName = storeData.profile.profile.employer_id
          ? storeData.profile.profile.employer_id
          : storeData.profile.profile.company_name;
        eventData.eapName = get(
          storeData,
          'companyDetails.companyDetails.partner.name',
          ''
        );
      } else {
        const { getUserCompanyDetail } = helpers;
        const {
          companyDetail: { name },
          accessCode
        } = await getUserCompanyDetail();
        if (!isEmpty(accessCode)) {
          eventData.accessCode = accessCode;
        }
        if (!isEmpty(name)) {
          eventData.companyName = name;
        }
      }

      eventData.id = uuid();
      eventData.tags = TAGS;
      eventData.context.version = VERSION;
      eventData.context.appVersion = APP_VERSION || null;
      eventData.timestamp = moment().locale('en').utc().format();
      const sessionDetails = await getSessionDetails();
      if (eventData.category !== 'logout') {
        const checkIfAnalyticsSessionExpired = await isAnalyticsSessionExpired(
          sessionDetails
        );
        eventData.sessionId = checkIfAnalyticsSessionExpired
          ? await startAnalyticsSession()
          : sessionDetails.sessionId;
      } else {
        eventData.sessionId = sessionDetails.sessionId;
      }

      await sendEvent(eventData);
    }
  }

  async track(category, action, properties = {}) {
    const type = 'track';
    await this.constructor.addEvent(type, category, action, properties);
  }

  async info(category, action, properties = {}) {
    const type = 'info';
    await this.constructor.addEvent(type, category, action, properties);
  }

  async screen(category, action, properties = {}) {
    const type = 'screen';
    await this.constructor.addEvent(type, category, action, properties);
  }
}

export default new AnalyticsModule();
