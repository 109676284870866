// core
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useMutation } from '@apollo/react-hooks';
// components
import { ListItem } from '@material-ui/core';
// styles
import singleTickIcon from 'assets/icons/coachchat/single-tick.svg';
import clientSingleTickIcon from 'assets/icons/coachchat/client-single-tick.svg';
import doubleTicketIcon from 'assets/icons/coachchat/double-tick.svg';
// util
import logger from 'utils/logger';
import moment from 'utils/moment';

// data
import messageTypes from 'assets/data/chat/messageTypes';

// graphql
import { queries, mutationOptions, mutations } from 'utils/graphql';
import analytics from 'utils/analytics';
import setSessionExpired from 'store/actions/SessionExpiredAction';
import Lang from '../../../../components/Lang';
import CoachCard from '../CoachCard/CoachCard';
import MediaMessage from '../MediaMessage';
import TextMessage from '../TextMessage';
import materialStyles from './Message.styles';
import styles from './Message.module.scss';

const { getUpdateChatMessageReadOptions } = mutationOptions;
const { GET_CHAT_MESSAGES, GET_CHAT_MESSAGES_FOR_CLIENT } = queries;
const { UPDATE_CHAT_MESSAGE_READ } = mutations;

const {
  container,
  userMessageContainer,
  listItem,
  timeStamp,
  iconStyle,
  iconErrorStyle,
  clockIconStyle,
  readIndicatorIconStyle,
  messageFailedText,
  textMessageContainer,
  mediaMessageContainer,
  messageStatusContainer,
  coachCardContainer,
  clientTimeStamp,
  clientMessageSentIcon
} = styles;

const SingleTickIcon = () => (
  <img
    className={`${iconStyle} ${readIndicatorIconStyle}`}
    data-testid="single-tick-icon"
    src={singleTickIcon}
    alt=""
  />
);

const ClientSingleTickIcon = () => (
  <img
    className={`${iconStyle} ${readIndicatorIconStyle} ${clientMessageSentIcon}`}
    data-testid="client-single-tick-icon"
    src={clientSingleTickIcon}
    alt=""
  />
);

const Message = ({
  isSessionExpired,
  message,
  messageTime,
  ref,
  scroll,
  auth0Id,
  message: { id, to, from, createdAt, readFlag },
  isCoach,
  coachName,
  coachImage,
  actions
}) => {
  const [isLocalOnly, setIsLocalOnly] = useState(false);
  const [hasErrorInSending, setHasErrorInSending] = useState(false);
  const [markMessageAsReadMutation] = useMutation(UPDATE_CHAT_MESSAGE_READ);
  const listItemMaterialStyle = materialStyles();

  const isUser = from === auth0Id;
  useEffect(() => {
    scroll();
    let timeoutHandle = null;
    if (id.startsWith('local')) {
      const today = moment();
      const diff = today.diff(createdAt);
      if (diff >= 15000) {
        setIsLocalOnly(true);
      } else {
        timeoutHandle = setTimeout(() => {
          setIsLocalOnly(true);
        }, 5000);
      }
    }
    if (id.startsWith('graphQlError')) {
      setHasErrorInSending(true);
    }
    return () => {
      clearTimeout(timeoutHandle);
    };
  }, [isLocalOnly, id, createdAt, hasErrorInSending, scroll]);

  useEffect(() => {
    if (!isUser && !readFlag && !isSessionExpired) {
      const query = isCoach ? GET_CHAT_MESSAGES_FOR_CLIENT : GET_CHAT_MESSAGES;
      analytics.info('chat', 'message read', { messageId: id });
      markMessageAsReadMutation(
        getUpdateChatMessageReadOptions({
          id,
          query,
          to,
          from,
          isCoach
        })
      ).catch((err) => {
        const statusCode = _.get(err, 'networkError.statusCode', null);
        if (statusCode === 401) {
          actions.setSessionExpired(true);
        }
        logger.error(
          'An error occurred while updating chat message read flag',
          'MessageList.useEffect.markMessageAsReadMutation',
          { err }
        );
      });
    }
  }, [
    id,
    to,
    isUser,
    from,
    markMessageAsReadMutation,
    readFlag,
    isCoach,
    actions,
    isSessionExpired
  ]);

  const isDelivered = () => {
    if (isCoach) {
      return (
        <React.Fragment key="deliveredStatusIcon">
          {isUser &&
            (readFlag ? (
              <img
                className={`${readIndicatorIconStyle}  ${iconStyle}`}
                src={doubleTicketIcon}
                alt=""
                date-testid="double-tick-icon"
              />
            ) : (
              <SingleTickIcon />
            ))}
        </React.Fragment>
      );
    }
    return isUser && <ClientSingleTickIcon key="singleTickIcon" />;
  };

  const _renderMessageStatusIndicator = () => {
    const componentToRender = [
      <span key={messageTime} className={isUser ? clientTimeStamp : timeStamp}>
        {messageTime}
      </span>
    ];
    if (hasErrorInSending) {
      componentToRender.push(
        <i
          data-testid="has-error-in-sending"
          className={`fa fa-exclamation-circle ${iconStyle} ${iconErrorStyle}`}
        />
      );
    } else if (isLocalOnly) {
      componentToRender.push(
        <i
          data-testid="islocal-only"
          className={`fa fa-clock ${iconStyle} ${clockIconStyle}`}
        />
      );
    } else if (!id.startsWith('local')) {
      componentToRender.push(isDelivered());
    }
    return <div className={messageStatusContainer}>{componentToRender}</div>;
  };

  const _renderErrorMessage = () =>
    hasErrorInSending && (
      <span className={messageFailedText}>
        <Lang path="chatErrorInSendingMessageText" />
      </span>
    );

  const _renderMessageComponent = () => {
    if (
      message.type === messageTypes.coachCard ||
      message.type === messageTypes.coachCardV2
    ) {
      return (
        <CoachCard
          isCoach={isCoach}
          key={message.id}
          details={message.message}
          type={message.type}
          coachName={coachName}
          coachImage={coachImage}
        />
      );
    }
    if (message.type === messageTypes.content) {
      const content = _.get(message, 'content', []);
      return (
        <div className={mediaMessageContainer}>
          <MediaMessage
            isCoachMessage={!isUser}
            content={content}
            analyticsMessage={`${
              isCoach ? 'Coach' : 'Client'
            } chat message video`}
          />
          <div className={textMessageContainer}>
            <TextMessage messageId={message.id} isUser={isUser}>
              {message.message.text}
            </TextMessage>
            {_renderMessageStatusIndicator()}
          </div>
        </div>
      );
    }
    return (
      <div key={message.id} className={textMessageContainer}>
        <TextMessage messageId={message.id} isUser={isUser}>
          {message.message.text}
        </TextMessage>
        {_renderMessageStatusIndicator()}
      </div>
    );
  };

  const classNames = [container];
  if (isUser) {
    classNames.push(userMessageContainer);
  }

  if (
    message.type === messageTypes.coachCard ||
    message.type === messageTypes.coachCardV2
  ) {
    classNames.push(coachCardContainer);
  }
  return (
    <div ref={ref} key={id} id={id} data-testid={id}>
      <ListItem
        className={listItem}
        classes={{ root: listItemMaterialStyle.root }}
      >
        <div className={classNames.join(' ')}>{_renderMessageComponent()}</div>
        {_renderErrorMessage()}
      </ListItem>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    sessionExpired: { isSessionExpired }
  } = state;
  return { isSessionExpired };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    setSessionExpired: bindActionCreators(setSessionExpired, dispatch)
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Message);
