import React, { Component } from 'react';
import styles from './SliderScreen.module.scss';

class SliderScreen extends Component {
  _renderSlide = ({ title, text, list, slide }) => {
    const {
      line,
      titleText,
      message,
      textContainerFirstSlide,
      textContainer,
      textContainerCoach,
      flexColumn,
      bottomCenter,
      singleLineText,
      welcomeCenterText,
      welcomeScreenTextTwo,
      welcomeScreenTextThree,
      welcomeScreenCoachScreenText,
      textDark,
      listText,
      listTextMiddle,
      listTextBottom,
      listTextCoach,
      listTextBottomGoalScreen,
      listCheckmark,
      liCheckmark,
      liCheckmarkBlue,
      listCoachMargin,
      listFlex,
      justifyEnd
    } = styles;

    if (slide === 1) {
      return (
        <div className={`${textContainerFirstSlide} ${flexColumn}`}>
          <p className={titleText}>{title}</p>
          <ul className={`${listText} ${listCheckmark}`}>
            {this._renderList(list, liCheckmark)}
          </ul>
          <p
            className={`${message} ${bottomCenter}  ${welcomeCenterText} ${textDark}`}
          >
            {text}
          </p>
        </div>
      );
    }
    if (slide < 6) {
      return (
        <div className={`${textContainer} ${flexColumn}`}>
          <p
            className={`${listTextMiddle}  ${textDark} ${
              slide === 2 ? welcomeScreenTextTwo : welcomeScreenTextThree
            }`}
          >
            {title}
          </p>
          <span className={line} />
          <p className={`${listTextBottom}  ${textDark} ${singleLineText}`}>
            {text}
          </p>
        </div>
      );
    }
    if (slide >= 6) {
      return (
        <div className={`${textContainerCoach} ${flexColumn} ${justifyEnd}`}>
          <p
            className={`${listTextMiddle} ${textDark} ${welcomeScreenCoachScreenText}`}
          >
            {title}
          </p>
          <span className={line} />
          <p
            className={`${listTextBottomGoalScreen}
             ${textDark} ${singleLineText}`}
          >
            {text}
          </p>
          <ul
            className={`${listTextCoach} ${listCoachMargin} ${listCheckmark} ${textDark}`}
          >
            {this._renderList(list, `${liCheckmarkBlue} ${listFlex}`)}
          </ul>
        </div>
      );
    }
    return true;
  };

  _renderList = (data, liCheckmark) =>
    data.map((item, key) => (
      <li key={`${item}${key}`} className={liCheckmark}>
        <span>{item}</span>
      </li>
    ));

  render() {
    const classname = `slide${this.props.slide}`;
    const screen = styles[classname];
    const { slideBackground, slideBackgroundFirstScreen } = styles;

    return (
      <div
        className={`${screen} ${
          this.props.slide === 1 ? slideBackgroundFirstScreen : slideBackground
        }`}
      >
        {this._renderSlide(this.props)}
      </div>
    );
  }
}

export default SliderScreen;
