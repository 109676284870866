/**
 * Question component that returns the proper question type
 * based on the input Type supplied by the questions json
 */

// Core
import React, { Component } from 'react';
// Components
import RadioType from './types/RadioType';
import RatingType from './types/RatingType';
import NumberType from './types/NumberType';

// Input types that can be used for questions
const inputTypes = {
  radio: 'radio',
  number: 'inputNumber',
  rating: 'rating'
};

class Question extends Component {
  _renderQuestion = () => {
    // Check the input Type on the data props and return the appropriate question type
    if (this.props.data) {
      const { inputType } = this.props.data;
      if (inputType === inputTypes.radio) {
        return <RadioType {...this.props} />;
      }
      if (inputType === inputTypes.number) {
        return <NumberType {...this.props} />;
      }
      if (inputType === inputTypes.rating) {
        return <RatingType {...this.props} />;
      }
    }
    return null;
  };

  render() {
    return this._renderQuestion();
  }
}

export default Question;
