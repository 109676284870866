import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { queries } from 'utils/graphql';
import logger from 'utils/logger';
import { Ripple } from '../../../../../../../components/ui/Layout';
import LevelSeparator from '../../../../../../../components/LevelSeparator/LevelSeparator';
import Lang from '../../../../../../../components/Lang';

import styles from './ClientAssessments.module.scss';
import ClientAssessmentsItemHeader from '../ClientAssessmentsItemHeader/ClientAssessmentsItemHeader';
import ClientAssessmentsItemContent from '../ClientAssessmentsItemContent/ClientAssessmentsItemContent';

const {
  infoStyle,
  expansionPanelContainer,
  expansionPanelDetailsContainer,
  shadowStyle,
  categoriesContainer,
  lowColor,
  mediumColor,
  highColor,
  bonusColor
} = styles;

const { GET_USER_REPORTS } = queries;

const levelTypes = {
  low: {
    className: lowColor,
    name: 'low'
  },
  moderate: {
    className: mediumColor,
    name: 'moderate'
  },
  high: {
    className: highColor,
    name: 'high'
  },
  bonus: {
    className: bonusColor,
    name: 'bonus'
  }
};

const ClientAssessments = ({ clientAuth0Id }) => {
  const [activeAssessment, setActiveAssessment] = useState('');

  useEffect(() => {
    setActiveAssessment('');
  }, [clientAuth0Id]);

  const {
    loading,
    error,
    data: reportData
  } = useQuery(GET_USER_REPORTS, {
    variables: {
      clientAuth0Id
    }
  });

  if (loading) {
    return (
      <div className={infoStyle}>
        <Ripple />
      </div>
    );
  }

  if (error) {
    logger.error(`${error.message}`, 'ClientAssessments', { error });
    return (
      <div className={infoStyle} data-testid="chat-client-report-error">
        <Lang path="chatClientReportErrorText" />
      </div>
    );
  }

  const _levelFilterCategories = ({ categories, level }) =>
    categories.filter((category) => category.level === level);

  return (
    <>
      {reportData.getUserReports.assessments ? (
        reportData.getUserReports.assessments.map((assessment, index) => {
          const expanded = activeAssessment === assessment.id;
          return (
            <div className={expansionPanelContainer} key={assessment.id}>
              <Accordion
                onChange={() => {
                  setActiveAssessment(assessment.id);
                }}
                expanded={expanded}
              >
                <AccordionSummary
                  onClick={() => {
                    if (expanded) {
                      setActiveAssessment('');
                    }
                  }}
                  className={shadowStyle}
                  expandIcon={<ExpandMoreIcon data-testid="dropdown" />}
                >
                  <ClientAssessmentsItemHeader
                    timestamp={assessment.timestamp}
                    suicidal={assessment.suicidal}
                    domesticViolence={assessment.domesticViolence}
                  />
                </AccordionSummary>
                {expanded && (
                  <AccordionDetails
                    data-testid={`assessment-detail-${index}`}
                    className={expansionPanelDetailsContainer}
                  >
                    {['high', 'moderate', 'low', 'bonus'].map((level) => {
                      const levelFilteredCategories = _levelFilterCategories({
                        categories: assessment.categories,
                        level
                      });
                      return (
                        !!levelFilteredCategories.length && (
                          <div
                            key={level}
                            data-testid={`severity-${index}`}
                            className={categoriesContainer}
                          >
                            <LevelSeparator
                              text={levelTypes[level].name}
                              bgClass={levelTypes[level].className}
                            />
                            {levelFilteredCategories.map((category) => (
                              <ClientAssessmentsItemContent
                                key={category.category}
                                name={category.name}
                                level={levelTypes[category.level].className}
                                category={category.category}
                              />
                            ))}
                          </div>
                        )
                      );
                    })}
                  </AccordionDetails>
                )}
              </Accordion>
            </div>
          );
        })
      ) : (
        <div className={infoStyle}>
          <Lang path="chatClientReportNoAssessments" />
        </div>
      )}
    </>
  );
};

export default ClientAssessments;
