// assets
import {
  progressStates,
  supportedSteps
} from 'assets/data/self-use/thoughtRecorder';
// components
import ChecklistContainer from '../Steps/ChecklistContainer';
import RationalResponse from '../Steps/RationalResponse';

export const stepsComponent = {
  [supportedSteps.AUTOMATIC_THOUGHTS]: ChecklistContainer,
  [supportedSteps.EMOTIONS]: ChecklistContainer,
  [supportedSteps.CATEGORY]: ChecklistContainer,
  [supportedSteps.CHALLENGE]: ChecklistContainer,
  [supportedSteps.RATIONAL_RESPONSE]: RationalResponse
};

export const getStepsProgress = ({ isDisabled, isActive }) => {
  if (isDisabled) {
    return progressStates.DISABLED;
  }
  if (isActive) {
    return progressStates.CURRENT;
  }
  return progressStates.COMPLETED;
};
