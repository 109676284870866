import gql from 'graphql-tag';

const CHAT_MESSAGE_RECEIVED = gql`
  subscription chatMessageReceived($from: ID!, $to: ID!) {
    chatMessageReceived(from: $from, to: $to) {
      id
      to
      from
      message {
        text
        coachAbout
        coachDesignation
        header
        body
      }
      content {
        id
        type
        duration
        header
        subHeader
        url
        urlHlsv3
        urlCommon
        thumbnailUrl
        tags
      }
      readFlag
      type
      createdAt
    }
  }
`;

const CHAT_MESSAGE_READ = gql`
  subscription chatMessageRead($from: ID!, $to: ID!) {
    chatMessageRead(from: $from, to: $to) {
      id
      to
      from
      readFlag
    }
  }
`;

const CLIENT_ASSIGNED = gql`
  subscription chatState($to: ID!) {
    chatState(to: $to) {
      name
      image
      from
      to
      company
      partner
      unread
      birthDay
      birthMonth
      birthYear
      gender
      status
    }
  }
`;

const CHAT_MESSAGE_RECEIVED_COACH = gql`
  subscription chatMessageReceived($to: ID!) {
    chatMessageReceived(to: $to) {
      id
      to
      from
      message {
        text
        coachAbout
        coachDesignation
        header
        body
      }
      content {
        id
        type
        duration
        header
        subHeader
        url
        urlHlsv3
        urlCommon
        thumbnailUrl
        tags
      }
      readFlag
      type
      createdAt
    }
  }
`;

export default {
  CHAT_MESSAGE_RECEIVED,
  CHAT_MESSAGE_READ,
  CLIENT_ASSIGNED,
  CHAT_MESSAGE_RECEIVED_COACH
};
