/*
List of all platforms supported by @ionic/react package.
Ionic returns a list (array) for the current platform.
For example: App in "iPhone Safari browser" would return ['ios', 'iphone', 'mobileweb'] array.
*/
export default {
  ANDROID: 'android',
  CAPACITOR: 'capacitor',
  CORDOVA: 'cordova',
  DESKTOP: 'desktop',
  ELECTRON: 'electron',
  HYBRID: 'hybrid',
  IOS: 'ios',
  IPAD: 'ipad',
  IPHONE: 'iphone',
  MOBILE: 'mobile',
  MOBILE_WEB: 'mobileweb',
  PHABLET: 'phablet',
  PWA: 'pwa',
  TABLET: 'tablet',
  PHONE: 'phone'
};
