import helpers from 'utils/helpers';

let dimensions = {
  leftChat: { lg: 5, md: 7, sm: 24 },
  centerChat: { lg: 12, md: 13, sm: 24 },
  rightChat: { lg: 7, md: 10, sm: 24 }
};

const getDimensions = (isInfoEnabled) => {
  const { isTab } = helpers.platformInfo;

  let { leftChat, centerChat, rightChat } = { dimensions };

  leftChat = { ...leftChat, md: 8 };
  rightChat = { ...rightChat, md: 11 };

  if (isTab) {
    leftChat = { ...leftChat, lg: 7, sm: 8 };
    rightChat = { ...rightChat, lg: 9 };
    if (isInfoEnabled) {
      centerChat = { lg: 15, md: 13, sm: 14 };
      rightChat = { ...rightChat, sm: 10 };
    } else {
      centerChat = { lg: 17, md: 16, sm: 16 };
    }
  } else if (isInfoEnabled) {
    leftChat = { ...leftChat, lg: 5, sm: 24 };
    centerChat = { ...centerChat, lg: 12, md: 13 };
    rightChat = { ...rightChat, lg: 7, sm: 24 };
  } else {
    leftChat = { ...leftChat, lg: 5, sm: 24 };
    centerChat = { ...centerChat, lg: 19, md: 16 };
    rightChat = { ...rightChat, lg: 7 };
  }

  dimensions = { leftChat, centerChat, rightChat };
  return dimensions;
};

export default getDimensions;
